/**
 * 組織選択用入力パーツ
 * @module app/view/form/SectSelector
 */
define([
    'module',
    'dojo/_base/lang',
    'dojo/_base/declare',
    'dojo/_base/array',
    'dojo/dom-style',
    'dojo/promise/all',
    'dojo/when',
    'dojox/lang/functional/array',
    'app/model/ChronologyShareOrgs',
    'idis/error/InvalidArgumentException',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    'idis/view/form/TreeChecker',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest'
], function(module, lang, declare, array, domStyle, all, when, df, ChronologyShareOrgs,
    InvalidArgumentException, UserInfo, USER_TYPE, TreeChecker, CacheStoreModel, CacheTreeRest) {
    /**
     * 組織選択用パーツ。
     * @class SectSelector
     * @extends module:idis/view/form/TreeChecker~TreeChecker
     */
    return declare(module.id.replace(/\//g, '.'), TreeChecker,
        /** @lends module:idis/view/form/TreeChecker~TreeChecker# */ {
        // 選択ダイアログのタイトル
        title: '自治体/組織選択',

        // ツリー・モデル
        // model: ChronologyShareOrgs,
        model: null,
        postMixInProperties : function() {
            // クロノロジ画面の宛先セレクタの場合（組織またぎの場合の宛先を自治体単位とする）
            if(this.orgControl){
                this.model = new CacheStoreModel({
                    store: new CacheTreeRest({
                        target: '/api/organizations/chronologyShareOrgs4Tree' + '?orgControl=' + '1'
                    })
                });
            } else {
                // 上記以外の場合（掲示板など）
                this.model = ChronologyShareOrgs;
            }
        },
        /**
         * 選択した値の設定
         */
        _setValueAttr: function(value) {
            // 継承した親クラスの処理
            // this.inherited(arguments);
            if (!lang.isArray(value)) {
                var message = '#_setValueAttr: 配列を指定してください: value=' + value;
                throw new InvalidArgumentException(module.id + message);
            }
            // value値を設定
            this._set('value', value);
            if (!value) {
                // value未設定時は空文字設定
                this._set('value', '');
            }
            // 要素の選択状態をリセットする
            this._initTree().then(lang.hitch(this, function() {
                this.tree.set('selectedItem', null);
            }));
            this.dialog.hide();
            // 選択状態を更新
            this._initTree().then(lang.hitch(this, function() {
                // 指定されなかったvalue値に対応する要素のチェックを解除
                return all(df.map(this.tree._checkMap, function(item, id) {
                    if (array.indexOf(value, id) === -1) {
                        return this.tree.setChecked(item, false);
                    }
                }, this));
            })).then(lang.hitch(this, function() {
                return all(array.map(value, function(v) {
                    // 各値を識別子とする要素を取得
                    return when(this.tree.model.store.get(v), lang.hitch(this, function(item) {
                        // チェックされたものをツリーに反映
                        return this.tree.setChecked(item, true);
                    }));
                }, this));
            })).then(lang.hitch(this, function() {
                // チェックされたものを選択表示へ反映
                // 親がチェックされている場合、子は表示しない
                var excludeMap = {};
                // 親がチェックされているものを取得し除外マップに格納する
                df.forIn(this.tree._checkMap, function(item) {
                    if(this.tree.isEveryChildChecked(item)){
                        this.tree.getItemChildren(item).then(lang.hitch(this, function(items) {
                            if(!items){
                                return false;
                            // } else if (items.length !== 0){
                                // 子を登録して親を登録対象外とする場合
                                // 対象に子が存在する場合、親は登録対象外とする
                                // excludeMap[this.tree.model.store.getIdentity(item)] = true;
                                // return false;
                            }
                            array.forEach(items, function(item){
                                // 親を登録して子を登録対象外とする場合
                                // 対象に紐づく子はすべて登録対象外とする
                                excludeMap[this.tree.model.store.getIdentity(item)] = true;
                            }, this);
                        }));
                    }
                }, this);
                // 除外マップに格納されていない項目を取得する
                var ret = df.filter(this.tree._checkMap, function(item) {
                    return !excludeMap[this.tree.model.store.getIdentity(item)];
                }, this);
                if(ret.length > 0) {
                    this.status.innerHTML = array.map(ret, function(item) {
                        if (this.isOrganization(item)) {
                            // 組織情報の場合、自治体名 + 組織名
                            return '' + this.model.getLabel(this.getRegionMunicipalityInfo(item)) +
                                this.model.getLabel(item);
                        } else if (item.id === '1' || item.id === '2') {
                            // IDが1 or 2の場合は全市町村又は全振興局のまとめたグループであり
                            // 値として'市町村'又は'振興局'が設定されている。
                            // そのため、表示情報として'全'を付与して返す
                            return '全' + this.model.getLabel(item);
                        } else if (item.id.length === 3) {
                            // IDが3桁の場合はある振興局管内の全市町村をまとめたグループであり
                            // 値として'xx振興局管内'が設定されている。
                            // そのた表示情報として'xx振興局管内' + '全市町'とする。
                            return this.model.getLabel(item) + '全市町';
                        } else if(UserInfo.getUserType() === USER_TYPE.OTHER_ORGAN &&
                            item.id === '$ROOT$' && ret.length === 1){
                            // 関係機関の場合、宛先は「長崎県」のみのツリーを全選択した状態となるため、表示用名称をセット
                            return '長崎県';
                        } else {
                            // 自治体の場合、自治体名のみ
                            return this.model.getLabel(item);
                        }
                    }, this).join(', ') ||  '全体';
                } else {
                    this.status.innerHTML = '未選択';
                }
                if(this.orgControl){
                    if(UserInfo.getUserType() === USER_TYPE.PREFECTURE || UserInfo.getUserType() === USER_TYPE.REGION){
                        // 配信元が県・振興局ユーザの場合の宛先：県及び全振興局の組織、各市町（組織選択不可）
                        // 数が多いので展開しない
                        this.model.setFilter().then(lang.hitch(this, function(){
                            this.tree.collapseAll();
                        }));
                    } else if (UserInfo.getUserType() === USER_TYPE.MUNICIPALITY || UserInfo.getUserType() === USER_TYPE.OTHER_ORGAN){
                        // 配信元が市町ユーザの場合の宛先：自市町内の組織、長崎県（組織/振興局は選択不可）
                        // 配信元がその他ユーザの場合の宛先：県のみ
                        this.model.setFilter().then(lang.hitch(this, function(){
                            this.tree.expandAll();
                        }));
                    }
                }

            }));
            // this.tree._checkMapの更新処理が終わった後で更新イベントを発行する
            this.emit('change', {value: ''});
            // 以下、当モジュール固有の処理
            // 選択結果が20文字を超えた場合、・・・(三点リーダ)で省略する
            domStyle.set(this.status, {
                'max-width': '20em',
                'white-space': 'nowrap',
                'overflow': 'hidden',
                'text-overflow': 'ellipsis'
            });
        },

        /**
         * tree._checkMapからMunicipalityCdを取得する
         * NOTE: 長崎県案件では自治体/組織を一つのセレクタとするため、当メソッドを実装する。
         */
        getMunicipalityCds: function() {
            var items = df.filter(this.tree._checkMap, function(item) {
                return item.type === 'MMunicipality' ||
                    item.type === 'MRegion' || item.type === 'Prefecture';
            });
            return items;
        },

        /**
         * tree._checkMapからMunicipalityCdを取得し、
         * 取得できなかった場合はtree._halfCheckMapからMunicipalityCdを取得する
         * NOTE: 長崎県案件では自治体/組織を一つのセレクタとするため、当メソッドを実装する。
         */
        getMunicipalityCdsIncludesHalf: function() {
            var items = df.filter(this.tree._checkMap, function(item) {
                return item.type === 'MMunicipality' ||
                    item.type === 'MRegion' || item.type === 'Prefecture';
            });
            if (items.length === 0) {
                items = df.filter(this.tree._halfCheckMap, function(item) {
                    return item.type === 'MMunicipality' ||
                        item.type === 'MRegion' || item.type === 'Prefecture';
                });
            }
            return items;
        },

        /**
         * 組織情報から自治体情報を取得する
         * @param {*} org 自治体情報(storeと同形式オブジェクト)
         */
        getRegionMunicipalityInfo: function(org) {
            if (org.type === 'MDept' || org.type === 'MSect' || org.type === 'MUnit') {
                // 組織情報の場合は親のオブジェクトにて再帰実行
                var ret;
                when(this.tree.model.store.get(org.parentId)).then(lang.hitch(this, function(parent) {
                    ret = this.getRegionMunicipalityInfo(parent);
                }));
                return ret;
            } else {
                return org;
            }
        },

        /**
         * 引数に指定されたオブジェクトが組織情報か判定する
         * @param {*} org 
         */
        isOrganization: function(org) {
            if (org.type === 'MDept' || org.type === 'MSect' || org.type === 'MUnit') {
                return true;
            } else {
                return false;
            }
        }
    });
});
