/**
 * 帳票ディスプレイ用モジュール。
 *
 * @module app/formDisplay/formDisplayAdminPage
 */
define(['module',
        'dojo/_base/declare',
        'dojo/_base/lang',
        'idis/control/Router',
        'dojo/text!./templates/formDisplayAdminPage.html',
        'idis/store/IdisRest',
        'idis/view/Loader',
        'idis/view/page/_PageBase',
        'idis/model/UserInfo',
        'idis/consts/ACL',
        'idis/view/dialog/DialogChain',
        'app/formDisplay/_formDisplayDialogBase',
        // 以下、変数で受けないモジュール
        'dijit/Dialog',
        'dijit/form/Form',
        'dijit/layout/BorderContainer',
        'dijit/layout/ContentPane',
        'idis/view/form/AclButton',
        'app/formDisplay/formAdminPageGrid',
        'app/formDisplay/formDisplayDialog'],
    function(module, declare, lang, Router, template,
        IdisRest, Loader, _PageBase, UserInfo, ACL, DialogChain, _formDisplayDialogBase) {
        /**
         *
         * @class formDisplayAdminPage
         * @extends module:idis/view/page/_PageBase~_PageBase
         */
        return declare(module.id.replace(/\//g, '.'), _formDisplayDialogBase,
        /** @lends module:app/view/page/formDisplayAdminPage~formDisplayAdminPage# */
        {
            // テンプレート文字列
            templateString: template,
            
            store: null,
            
            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--formDisplay',
            
            /**
             * 変数初期化メソッド
             */
            constructor: function() {
                // グループ管理タブ用データ格納用オブジェクト
                this.store = new IdisRest({
                    idProperty:'screenId',
                    target: '/api/formDisplay',
                    sortParam: 'sort',
                    rangeStartParam: 'offset',
                    rangeCountParam: 'count'
                });
                // ダイアログ連鎖を登録
                this.chain = DialogChain.get(this);
            },
            
            /**
             * DOMノードを生成するためのメソッド
             */
            buildRendering: function() {
                this.inherited(arguments);
                this.own(this.innerFormDisplayDialog);
            },
            
            startup: function() {
                this.inherited(arguments);

                this.initGrid();
                this.initPage();
            },

            /**
             * Gridを初期化する。
             */
            initGrid: function() {
                // gridに一覧を設定する
                this.formAdminPageGrid.set('collection', this.store);
                // グリッドの詳細ボタンクリック時の動作を設定する
                // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
                this.formAdminPageGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                    this.showDetaiDialog(evt.item);
                }));
            },

            initPage: function() {
        	    // 詳細ダイアログを初期化
        	    this.initDetailDialog();
            },

            /**
             * @param item gridのstore1行分
             * 詳細画面に遷移する。
             */
            showDetaiDialog: function(item) {
                console.debug('[詳細]ボタンがクリックされました。');
                this.innerFormDisplayDialog.initDialog(item);
                this.detailDialog.show();
            },

            /**
             * 詳細ダイアログを初期化する。
             */
            initDetailDialog: function() {
                var dialog = this.detailDialog;
                var page = dialog.getChildren()[0];

                // 詳細画面のremoveイベントを受け取る
                page.on('remove', lang.hitch(this, function(evt) {
                    console.debug('removeイベント');
                    this.chain.confirmDel(function(chain) {
                        var promise = this.store.remove(evt.value.screenId + '?version=' + evt.value.version);
                        Loader.wait(promise).then(lang.hitch(this, function() {
                            // 登録フォーム・ダイアログを閉じる
                            dialog.hide();
                            chain.infoComplete();
                            // 一覧を取得しなおす
                            this.updateGridQuery();
                        }), function(err) {
                            // 失敗時
                            chain.infoError(err);
                        });
                    });
                }));

                // 詳細画面のupdateイベントを受け取る
                page.on('update', lang.hitch(this, function(evt) {
                    console.debug('updateイベント');
                    // 追加確認ダイアログを表示
                    this.chain.confirmPut(function(chain) {
                        var form = evt.value;
                        // 追加処理と共にローディング表示
                        Loader.wait(this.store.put(form)).then(lang.hitch(this, function() {
                            // 成功時（POST結果はグリッドが自動的に反映）
                            // 登録ダイアログを閉じる
                            dialog.hide();
                            // 完了ダイアログを表示
                            chain.infoComplete();
                            // 一覧を取得しなおす
                            this.updateGridQuery();
                        }), function(err) {
                            // 失敗時
                            chain.infoError(err);
                        });
                    });
                }));
            },

            /**
             * ウィンドウの幅に合わせてBorderContainerをリサイズし、タブ内のレイアウトを整理する。
             * (startup同様、画面描画時に呼ばれる)
             * @param changeSize 
             * @param resultSize
             */
            resize: function(changeSize, resultSize) {
                // 中のContentPaneも追随してリサイズするため、レイアウト崩れを防止できる。
                this.borderContainer.resize(changeSize, resultSize);
            },

            updateGridQuery: function() {
                var filter = new this.store.Filter();
                // var value = this.form.get('value');
                // console.log(value);
                // filterに対応するcollectionを取得
                var collection = this.store.filter(filter);

                // collectionをグリッドにセットする（サーバーにリクエストされる）
                this.formAdminPageGrid.set('collection', collection);
            }
        });
    });
