/**
 * 配信情報テンプレート登録画面用モジュール。
 * @module app/sending/SendingTemplateRegisterDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/SendingTemplateRegisterDialog.html',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/InfoDialog',
    'idis/view/page/_PageBase',
    'idis/view/Loader',
    './_SendingPageBase',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    '../config',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'idis/view/form/Button',
    'app/view/form/CustomizableMunicipalitySelector',
    'dijit/form/Select',
    'dijit/form/ValidationTextBox',
    'idis/view/form/WordCountTextarea'
], function(module, declare, lang, template, Requester, DialogChain, InfoDialog, _PageBase, Loader,
    _SendingPageBase, UserInfo , USER_TYPE, config) {
    /**
     * 配信情報テンプレート登録画面用モジュール。
     * @class SendingTemplateRegisterDialog
     * @extends module:app/sending/_SendingPageBase~_SendingPageBase
     */

    return declare(module.id.replace(/\//g, '.'), _SendingPageBase,
                   /** @lends module:app/view/page/SendingTemplateRegisterDialog~SendingTemplateRegisterDialog# */ {
        // テンプレート文字列
        templateString: template,

        baseClass: 'idis-Page idis-Page--sending',

        _fileId: null,

        infoDialog : null,

        municipalityCd : null,

        constructor: function() {
            // ベースクラスのコンストラクタを呼ぶ
            this.inherited(arguments);
            this.chain = DialogChain.get(this);
        },

        buildRendering: function() {
            this.inherited(arguments);

            //ダイアログ内の市町村セレクターをセットを行う
            //初期値
            this.municipalitySelectorNode.set('value', this.municipalityCd);
            //市町村ユーザーの場合(政令指定都市を除く)編集不可
            if(UserInfo.getUserType() === USER_TYPE.MUNICIPALITY){
                if(UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd){
                    this.municipalitySelectorNode.set('noAllButton', true);
                    this.municipalitySelectorNode.set('value', UserInfo.getMunicipalityCd());
                } else {
                    //政令指定都市ユーザーの場合市内を選択
                    this.municipalitySelectorNode.set('prefFlg', '0');
                    this.municipalitySelectorNode.set('value', UserInfo.getMunicipalityCd());
                }
            } else if(UserInfo.getUserType() === USER_TYPE.PREFECTURE) {
                //県ユーザーの場合、県を選択
                this.municipalitySelectorNode.set('value', config.municInfo.prefMunicCd);
            }
            this.municipalityCd = UserInfo.getMunicipalityCd();
        },

        resize: function(changeSize, resultSize) {
            // ウィジェット内最上位要素のresizeを呼ぶことで、ダイアログ内のサイズ調整を行う。
            // このウィジェットはダイアログ内最上位のウィジェットであるため、
            // ダイアログを開く際にresizeメソッドが無いとサイズ調整が行われない
            this.form.resize(changeSize, resultSize);
        },

        /**
         * ダイアログ内容を初期化する。
         */
        initContent: function(item) {
            this.inherited(arguments);
            this.form.reset();
            this.form.set('value', item);
            this.onChangeLalertCategorySelect();
        },

        onRegister: function(){
            var data = this.form.get('value');
            if (!data.name) {
                InfoDialog.show('入力エラー', 'テンプレート名を入力してください。');
                return false;
            }
            if (!data.municipalityCd) {
                InfoDialog.show('入力エラー', '市町を入力してください。');
                return false;
            }
            if (!data.subject) {
                InfoDialog.show('入力エラー', '件名を入力してください。');
                return false;
            }
            if (!data.bodyText) {
                InfoDialog.show('入力エラー', '本文を入力してください。');
                return false;
            }
            this.chain.confirmAdd(lang.hitch(this, function(chain) {

                var jsonStr = data;
                if (jsonStr.lalertCategory){
                    jsonStr.lalertCategory = jsonStr.sendingDetailType;
                }
                delete jsonStr.sendingDetailType;
                var promise = Requester.post('/api/sending/template/', {
                    data: jsonStr,
                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                    handleAs: 'json',
                    preventCache : true
                }).then(lang.hitch(this, function() {
                    console.debug('success register Sending');
                    chain.infoComplete(function() {
                        this.chain.hide();
                        this.getParent().hide();
                        this.emit('update', {
                            value: this.form.get('value')
                        });
                    });
                }), lang.hitch(this, function(error) {
                    console.log('error register damagereport');
                    chain.infoError(error);
                    console.log(error);
                }));
                //ローダーの表示
                Loader.wait(promise);
            }));
        }
    });
});
