/**
 * 避難基準超過情報一覧画面用モジュール。
 * @module app/evacstdlimitover/EvacStdLimitOverPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/control/Router',
    'dojo/text!./templates/EvacStdLimitOverPage.html',
    'idis/view/page/_PageBase',
    'dojo/dom',
    'dijit/registry',
    'dijit/Dialog',
    'dstore/Memory',
    'idis/view/dialog/DialogChain',
    'idis/model/UserInfo',
    'idis/store/IdisRest',
    'app/config',
    // 以下、変数で受けないモジュール
    'dstore/Rest',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/TextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/layout/TabContainer',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'idis/view/form/AclButton',
    'idis/consts/ACL',
    '../view/form/MunicipalitySelector',
    './EvacStdLimitOverGrid'
], function(module, declare, lang, Router, template, _PageBase, dom, registry,
		Dialog, Memory, DialogChain, UserInfo, IdisRest, config/*, Rest*/) {
    /**
     * 避難基準超過情報一覧画面
     * @class EvacStdLimitOverPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/evacstdlimitover/EvacStdLimitOverPage~EvacStdLimitOverPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--evacstdlimitover',

        /**
         * データ格納用オブジェクト
         */
        store: null,

        /**
         * 市町村コード
         */
        _municipalityCd: null,

        /**
         * 選択されたレコード格納用オブジェクト
         */
        _seletedItems: null,

        /**
         * constructor
         */
        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'evacOrderOverLmtId',
                target: '/api/evac-order-over-lmts',
                sortParam: 'sort',
                rangeStartParam: 'offset',
                rangeCountParam: 'count'
            });

            this.chain = DialogChain.get(this);

            this._seletedItems = [];
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
            // 初期表示時にはユーザーの市町を設定
            this._municipalityCd = UserInfo.getMunicipalityCds()[0];
            this.municipalityCd.set('value',this._municipalityCd);
            var now = new Date();
            this.overLmtTimestampTo.set('value', now);
            this.overLmtTimestampFrom.set('value', now.setMinutes(now.getMinutes()-10));
        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);
            this.initGrid();
        },

        /**
         * グリッドを初期化する。
         */
        initGrid: function() {
            var self = this;

            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
//            this.evacStdLimitOverGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
//                this.onDetailButtonClick(evt.item);
//            }));

            this.evacStdLimitOverGrid.on('dgrid-select', function (event) {
                console.debug(event.rows);

                for (var i = 0; i < event.rows.length; i++) {
                    var existFlg = false;
                    var row = event.rows[i];
                    for (var j = 0; j < self._seletedItems.length; j++) {
                        var item = self._seletedItems[j];
                        if(item.evacOrderOverLmtId === row.data.evacOrderOverLmtId) {
                            existFlg = true;
                            break;
                        }
                    }

                    if(!existFlg) {
                        self._seletedItems.push(row.data);
                    }
                }
            });

            this.evacStdLimitOverGrid.on('dgrid-deselect', function (event) {
                console.debug(event.rows);

                for (var i = 0; i < event.rows.length; i++) {
                    var row = event.rows[i];
                    for (var j = 0; j < self._seletedItems.length; j++) {
                        var item = self._seletedItems[j];
                        if(item.evacOrderOverLmtId === row.data.evacOrderOverLmtId) {
                            self._seletedItems.splice(j, 1);
                            break;
                        }
                    }
                }
            });


            this.updateGridQuery();
        },

        /**
         * グリッドの検索条件を指定された値で更新する。
         * @param {Object} value name属性と値のマッピング
         */
        updateGridQuery: function() {
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();
            var value = this.form.get('value');

            if (value.municipalityCd) {
                filter = filter.eq('municipalityCd', value.municipalityCd);
            }

            if (value.evacOrderType) {
                filter = filter.eq('evacOrderType', value.evacOrderType);
            }

            if (value.overLmtTimestampFrom) {
                var overLmtTimestampFrom = new Date(value.overLmtTimestampFrom).getTime();
                filter = filter.eq('overLmtTimestampFrom', overLmtTimestampFrom);
            }

            if (value.overLmtTimestampTo) {
                var overLmtTimestampTo = new Date(value.overLmtTimestampTo).getTime();
                filter = filter.eq('overLmtTimestampTo', overLmtTimestampTo);
            }

            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);


            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.evacStdLimitOverGrid.set('collection', collection);

            this._seletedItems.length = 0;
        },

        /**
         * 避難基準超過を検索する
         */
        onSubmit: function() {
            console.debug('[検索]ボタンがクリックされました。');
            try {
                if (this.form.isValid()) {
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery();
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

//        /**
//         * 避難状況登録画面へ遷移する。
//         */
//        onEvacOrderRegisterButtonClick: function(evt) {
//        	console.log('[新規登録]ボタンがクリックされました。');
//
//            if(!this.validateSeletedItems()) {
//                return false;
//            }
//
//            var municipalityCd = null;
//            var districtCds = [];
//            for (var i = 0; i < this._seletedItems.length; i++) {
//                var item = this._seletedItems[i];
//                if(municipalityCd === null) {
//                    municipalityCd = item.municipalityCd;
//                }
//
//                var districts = item.evacStdLimitOverDistricts;
//
//                for (var j = 0; j < districts.length; j++) {
//                    var existFlg = false;
//                    var district = districts[j];
//                    for (var k = 0; k < districtCds.length; k++) {
//                        var districtCd = districtCds[k];
//                        if(district.districtCd === districtCd) {
//                            existFlg = true;
//                            break;
//                        }
//                    }
//
//                    if(!existFlg) {
//                        districtCds.push(district.districtCd);
//                    }
//                }
//            }
//
//            console.debug('municipalityCd = ' + municipalityCd);
//            console.debug('districtCds = ' + districtCds);
//
//            // ブラウザーの遷移処理をキャンセル
//            evt.preventDefault();
//            // 避難状況登録登録画面へ遷移
//            Router.moveTo('evacorder/register',
//                {
//                    municipalityCd: municipalityCd,
//                    districtCds: districtCds
//                }
//            );
//        },

//        /**
//         * 詳細画面へ遷移する。
//         */
//        onDetailButtonClick: function(object) {
//        	console.log('[詳細]ボタンがクリックされました。' + object);
//        	window.open(object.url);
//        },

        filterByType: function(value) {
            alert(value);
        },

        /**
         * 選択されたレコードの妥当性を検証する。
         */
        validateSeletedItems: function() {

            if(this._seletedItems.length === 0) {
                this.chain.info('避難基準超過情報を選択してください。', '入力エラー');
                return false;
            }

            var municipalityCd = null;
            for (var i = 0; i < this._seletedItems.length; i++) {
                var item = this._seletedItems[i];
                if(municipalityCd === null) {
                    municipalityCd = item.municipalityCd;
                } else {
                    if(municipalityCd !== item.municipalityCd) {
                        this.chain.info('異なる市/行政区の避難基準超過情報を選択することはできません。', '入力エラー');
                        return false;
                    }
                }
            }
            // ユーザーの市町村情報をセット
            var _userMunicipalityCds = UserInfo.getMunicipalityCds();
            var checkFlg = false;
            _userMunicipalityCds.forEach(lang.hitch(this,function(value){
        		if(value === this._seletedItems[0].municipalityCd || value === config.municInfo.prefMunicCd){
                    checkFlg = true;
        		}
        	}));
            if (!checkFlg) {
                this.chain.info('選択された市/行政区の避難情報を登録する権限がありません。', '入力エラー');
                return false;
            }

            return true;
        }
    });
});
