/**
 * 気象情報詳細画面用モジュール
 * @module app/weatherinfo/view/WeatherInfoDetailPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/array',
    'dojo/_base/lang',
    'dojo/date/locale',
    'dojo/text!./templates/WeatherInfoDetailPage.html',
    'dojox/lang/functional/object',
    'idis/store/IdisRest',
    'idis/service/Requester',
    'idis/control/Router',
    'idis/control/Locator',
    'idis/error/InvalidArgumentException',
    'idis/view/_IdisWidgetBase'
], function(module, declare, array, lang, locale, template, object, IdisRest,
        Requester, Router, Locator, InvalidArgumentException, _IdisWidgetBase) {
    /**
     * 気象情報詳細画面用UIウィジェット
     * @class WeatherInfoDetailPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _IdisWidgetBase,
        /** @lends app/weatherinfo/view/WeatherInfoDetailPage~WeatherInfoDetailPage# */ {
        // テンプレート文字列
        templateString: template,

        // 気象データ格納用ストア
        store: null,

        // DOMを構築する
        buildRendering: function() {
            this.inherited(arguments);
            // コンテンツの中身を生成する
            this.setContent();
        },

        /**
         * コンテンツの中身を生成する
         */
        setContent: function() {
            Requester.get('/api/WeatherInformations/detail').then(lang.hitch(this, function(data) {
                console.debug(data);
            }));
        },

        /**
         * 'yyyy-MM-dd HH:mm:ss' を表示形式に変換
         */
        formatDateTime: function(datetime) {
            var reportDateTime = new Date(datetime.replace(/-/g, '/'));
                var dateLabel = locale.format(reportDateTime, {
                    selector: 'date',
                    datePattern: 'MM月dd日'
                });
                var timeLabel = locale.format(reportDateTime, {
                    selector: 'time',
                    timePattern: 'HH時mm分'
                });
            return dateLabel + '&nbsp;' + timeLabel;
        },

        isEmpty: function(str) {
            if (str === void 0) {
                return true;
            }
            if (str === null) {
                return true;
            }
            return false;
        },

        /**
         * コンテンツのHTMLテキストを返す
         */
        getContent: function() {
            // HTMLタグ間に改行コードが入り、セット先のダイアログモジュールで<br>に変換されるため外しておく
            // テキスト内の改行はそのままにしたいため、「HTML終了タグ + 改行コード」にマッチする部分を
            // 「HTML終了タグ」のみにする
            // さらに、最初と最後の改行コードを外す
            return this.contentNode.innerHTML.replace(/>\r?\n/g, '>').replace(/^\r?\n/g, '').replace(/\r?\n$/g, '');
        },

        onReturnButtonClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            // 気象情報一覧画面へ遷移
            Router.moveTo('weatherinfo');
        }

    });
});
