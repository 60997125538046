/**
 * 市民への情報配信履歴 一覧画面用モジュール。
 * @module app/citizenInfoHistory/CitizenInfoHistoryAdminPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/control/Router',
    'dojo/text!./templates/CitizenInfoHistoryAdminPage.html',
    'idis/view/page/_PageBase',
    'dojo/dom',
    'dojo/dom-style',
    'dojo/dom-construct',
    'dijit/registry',
    'dstore/Memory',
    'dstore/Rest',
    'idis/consts/ACL',
    'idis/model/UserInfo',
    'app/model/DisasterInfo',
    'idis/store/IdisRest',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'idis/view/form/AclButton',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/TextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/layout/TabContainer',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    './CitizenInfoHistoryAdminGrid',
    './CitizenInfoHistoryRegisterDialog',
    './CitizenInfoHistoryDetailDialog',
    '../common/view/StatementDialog',
    '../view/form/MunicipalityMultiSelectorNoPref',
    '../view/form/OrganizationSelector'
], function(module, declare, lang, Router, template, _PageBase, dom, domStyle, domConstruct,
    registry, Memory, Rest, ACL, UserInfo, DisasterInfo, IdisRest, IdisGrid, helper,
    AclButton, DialogChain, Loader) {

    /**
    * 検索条件格納用オブジェクト
    * @type {Object}
    */
    var citizenInfoHistoryFilterStore = {};

    /**
     *  市民への情報配信履歴 一覧画面
     *  CitizenInfoHistoryAdminPage
     *  module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/citizenInfoHistory/CitizenInfoHistoryAdminPage~CitizenInfoHistoryAdminPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--citizenInfoHistory',

        /**
         * データ格納用オブジェクト
         * @type {module:dstore/Store}
         */
        store: null,

        /**
         * データ登録用オブジェクト
         * @type {module:dstore/Store}
         */
        registerStore: null,

        /**
         * データ更新用オブジェクト
         * @type {module:dstore/Store}
         */
        updateStore: null,

        /**
         * データ削除用オブジェクト
         * @type {module:dstore/Store}
         */
        deleteStore: null,

        /**
         * 災害ID
         * @private
         */
        _disasterId: null,

        /**
         * constructor
         */
        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'citizenInfoId',
                target: '/api/citizenInfoHistory',
                rangeStartParam: 'offset',
                rangeCountParam: 'count'
            });
            // データ登録用オブジェクト
            this.registerStore = new IdisRest({
                idProperty: 'citizenInfoId',
                target: '/api/citizenInfoHistory/register'
            });
            // 連鎖ダイアログを登録
            this.chain = DialogChain.get(this);

            // 災害IDをDisasterInfoから取得
            this._disasterId = DisasterInfo.getDisasterId();
            if(!this._disasterId) {
                this._disasterId = 1;
                console.warn('災害IDが設定させていません。');
            }

            console.debug('現在のログインユーザID：' + UserInfo.getId());
            console.debug('現在の災害ID：' + DisasterInfo.getDisasterId());
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
            this.own(this.registerDialog);
            this.own(this.detailDialog);
        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);
            // グリッドを初期化する
            this.initGrid();
            this.initRegisterPage();
            this.initDetailPage();
        },

        /**
         * グリッドを初期化する。
         */
        initGrid: function() {
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.citizenInfoHistoryAdminGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                this.showDetailPage(evt.value);
            }));
            this.setFilterData();
            this.updateGridQuery();
        },

        /**
         * グリットを更新する。
         */
        updateGridQuery: function() {
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();
            var value = this.form.get('value');

            // 災害ID
            filter = filter.eq('disasterId', this._disasterId);

            // 配信日時
            if (value.pubStartTimestamp) {
                var pubStartTime = new Date(value.pubStartTimestamp).getTime();
                filter = filter.eq('pubStartTimestamp', pubStartTime);
            }
            // ログインユーザの組織コードを取得する
            var orgCd = (UserInfo.getOrganization().unitCd ? 'U' + UserInfo.getOrganization().unitCd :
            UserInfo.getOrganization().sectCd ? 'S' + UserInfo.getOrganization().sectCd :
            UserInfo.getOrganization().deptCd ? 'D' + UserInfo.getOrganization().deptCd : '');


            filter = filter.eq('destinationId', orgCd);

            var collection = this.store.filter(filter);

            this.citizenInfoHistoryAdminGrid.set('collection', collection);

        },

        /**
         * 検索条件を保管する
         */
        setFilterStore: function() {
            //初期化する
            citizenInfoHistoryFilterStore = {};
            var value = this.form.get('value');

            // 配信日時
            if (value.registerTimestamp) {
                citizenInfoHistoryFilterStore.registerTimestamp = value.registerTimestamp;
            }
        },

        /**
         * 保管した検索条件をformにセットする
         */
        setFilterData: function() {

            // 配信日時
            if(citizenInfoHistoryFilterStore.registerTimestamp) {
                this.registerTimestamp.set('value', citizenInfoHistoryFilterStore.registerTimestamp);
            }
        },

        /**
         * 市民への情報配信履歴を検索する。
         */
        onSubmit: function() {
            console.debug('[検索]ボタンがクリックされました。');
            try {
                if (this.form.isValid()) {
                    this.setFilterStore();
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery();
                } else {
                    console.debug('エラーあり');
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * 新規登録ダイアログを表示する。
         * テンプレートHTMLでdata-dojo-attach-eventプロパティーを用い、
         * 新規登録ボタンのクリックイベントにこのメソッドを紐付けている。
         */
        showRegisterDialog: function() {
            // 登録ダイアログの最初の子要素が登録画面
            var dialog = this.registerDialog;
            var page = dialog.getChildren()[0];

            // 検索条件を保管する。
            // this.saveFilterData();
            // this.innerDialog.clearPreview();

            this.innerDialog.initDialog();
            this.registerDialog.show();

            // 配信区無しで登録後、非表示の状態なので、表示
            domStyle.set(page.municipalityCds, 'display', '');
        },

        /**
         * 検索条件を保管する。
         */
        /* saveFilterData: function() {
            citizenInfoHistoryFilterStore = this.form.get('value') || {};
        }, */

        /**
         * 新規登録ダイアログを初期化する。
         */
        initRegisterPage: function() {
            // 登録ダイアログの最初の子要素が登録画面
            var dialog = this.registerDialog;
            var page = dialog.getChildren()[0];

            // 登録ダイアログのsendイベントを受け取る
            page.on('send', lang.hitch(this, function(evt) {
                this.chain.confirmAdd(function(chain) {
                    // OKが押された場合
                    var sendData = evt.value;

                    // 登録処理と共にローディング表示
                    Loader.wait(this.registerStore.add(sendData)).then(lang.hitch(this, function() {
                        // 成功時
                        chain.infoComplete(lang.hitch(this, function() {
                            this._buttonFlg = false;
                            dialog.hide();
                            chain.hide();
                            this.updateGridQuery(this.form.get('value'));
                        }));
                    }), function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));
        },

        /**
         * 詳細ダイアログを初期化する。
         */
        initDetailPage: function() {
            var dialog = this.detailDialog;
            var page = dialog.getChildren()[0];

            // 詳細ダイアログのupdateSendイベントを受け取る
            page.on('update', lang.hitch(this, function(evt) {
                this.chain.confirmPut(function(chain) {
                    // OKが押された場合

                    // データ更新用オブジェクト
                    this.updateStore = new IdisRest({
                        idProperty: 'citizenInfoId',
                        target: '/api/citizenInfoHistory/update'
                    });
                    // 更新処理と共にローディング表示
                    Loader.wait(this.updateStore.put(evt.value)).then(lang.hitch(this, function() {
                        // 成功時
                        chain.infoComplete(lang.hitch(this, function() {
                            this._buttonFlg = false;
                            dialog.hide();
                            chain.hide();
                            this.updateGridQuery(this.form.get('value'));
                        }));
                    }), function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));

            // 詳細ダイアログのremoveイベントを受け取る
            page.on('remove', lang.hitch(this, function(evt) {
                this.chain.confirmDel(function(chain) {
                    // OKが押された場合

                    // データ削除用オブジェクト
                    this.deleteStore = new IdisRest({
                        target: '/api/citizenInfoHistory/delete'
                    });
                    // 削除処理と共にローディング表示
                    Loader.wait(this.deleteStore.remove(evt.value.citizenInfoId)).then(lang.hitch(this, function() {
                        // 成功時
                        chain.infoComplete(lang.hitch(this, function() {
                            this._buttonFlg = false;
                            dialog.hide();
                            chain.hide();
                            this.updateGridQuery(this.form.get('value'));
                        }));
                    }, function(err) {
                        // 失敗時
                        chain.infoError(err);
                    }));
                });
            }));
        },

        /**
         * 詳細ダイアログを表示する。
         */
        showDetailPage: function(value) {

            // 検索条件を保管する。
            // this.saveFilterData();

            // ダイアログの最初の子要素が詳細画面
            var page = this.detailDialog.getChildren()[0];

            // 画面上のフォームをリセット
            page.form.reset();

            // 組織コードの頭1文字を重ねてセレクターが読み取れる値にする
            var orgCd = value.transmitOrganizationCd.substr(0, 1);
            var orgCdNum = value.transmitOrganizationCd.substr(1, 1);
            if (orgCd === 'D' && !isNaN(orgCdNum) ) {
                value.transmitOrganizationCd = 'D' +  value.transmitOrganizationCd;
            }else if (orgCd === 'S' && !isNaN(orgCdNum)) {
                value.transmitOrganizationCd = 'S' +  value.transmitOrganizationCd;
            }else if (orgCd === 'U' && !isNaN(orgCdNum)) {
                value.transmitOrganizationCd = 'U' +  value.transmitOrganizationCd;
            }

            // 子テーブルの配列の中のmunicipalityCdだけとって配列にセット
            if(value.citizenInfoHistoryAreas.length > 0) {
                var municipalityCds = [];
                for(var i=0; i < value.citizenInfoHistoryAreas.length; i++) {
                    // 区コードの先頭に「_」を追加してセレクターが読み取れる値にする
                    municipalityCds.push('_' + value.citizenInfoHistoryAreas[i].municipalityCd);
                    value.municipalityCdList = municipalityCds;
                }
            } else {
                value.municipalityCdList = '';
            }

            // 内容をフォームに反映
            var item = lang.mixin(null, value);
            page.form.set('value', item);

            // 詳細ダイアログを表示
            this.innerDetailDialog.initDialog(value);
            this.detailDialog.show();
        }
    });
});
