/**
 * 定時集計 一覧 閲覧画面用モジュール。
 * @module app/scheduledReport/ScheduledReportListViewPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/text!./templates/ScheduledReportListViewPage.html',
    'idis/control/Router',
    'app/scheduledReport/ScheduledReportListPage',
    // 以下、変数として受け取らないモジュール
], function (module, declare, template, Router, ScheduledReportListPage) {

    /**
     * 定時集計 一覧 閲覧ページ
     * @class ScheduledReportListViewPage
     * @extends module:app/scheduledReport/ScheduledReportListPage#~ScheduledReportListPage
     */
    return declare(module.id.replace(/\//g, '.'), ScheduledReportListPage,
        /** @lends module:app/scheduledReport/ScheduledReportListViewPage# */ {
            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--scheduledreport',

            // DOMノードを生成するためのメソッド
            buildRendering: function () {
                this.inherited(arguments);
            },

            // ページ初期表示にmodelがnullだとエラーが出るためmodelを生成
            constructor: function () {
            },

            startup: function () {
                this.inherited(arguments);
            },

            /**
             * 詳細画面を表示
             */
            onDetailButtonClick: function (object) {
                //検索条件の保管
                this.setFilterStore();

                // 被害状況・報告詳細画面へ遷移
                Router.moveTo('scheduledReport/detail/view', {
                    scheduledReportId: object.scheduledReportId
                });
            },
            onDamageReportPageLinkClick: function (evt) {
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                // 被害一覧画面へ遷移
                Router.moveTo('report/view');
            },

            /**
             * 各区の一覧表示
             */
            onMunicListButtonClick: function (item) {
                //検索条件の保管
                this.setFilterStore();

                // 各区・局の画面へ遷移
                Router.moveTo('scheduledReport/munic/view', {
                    admNum: item.admNum
                });
            },
        });
});

