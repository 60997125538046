/**
 * <モジュールの説明>
 * @module app/view/page/<ウィジェット名>
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/json',
    'dojo/text!./templates/broadnotifyOutPutDialog.html', // テンプレート文字列
    'idis/view/page/_PageBase', // 共通基底クラス
    'idis/view/dialog/InfoDialog',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    'dojo/_base/array',
    // 以下、変数で受けないモジュール
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'dijit/form/RadioButton',
    'app/view/form/BroadnotifyTerminalMultiSelector',
    'dijit/form/Form'
], function(module, declare, lang, Deferred, JSON, template, _PageBase,
    InfoDialog, DialogChain, Loader, array) {
    /**
     * <クラスの説明>
     * @class <ウィジェット名>
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/<ウィジェット名>~<ウィジェット名># */ {
        // テンプレート文字列
        templateString: template,

        OSAKA: '270008',
        MUNICIPALITY: '271004',
        REGION: '270150',
        constructor: function(){
            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        startup:function(){
            this.inherited(arguments);
            this.receiptTypeOptions();
        },

        initDialog: function(args) {
            this.notifyType.set('value', args.notifyType);
            this.receiptType.set('value', args.receiptType);
            this.senderName.set('value', args.senderName);
            this.broadnotifyDateTimeFrom.set('value', args.broadnotifyDateTimeFrom);
            this.broadnotifyDateTimeTo.set('value', args.broadnotifyDateTimeTo);
        },

        onOutputExcelButtonClick : function() {
            var fileType = 'excel';
            this.onOutputButtonClick(fileType);
        },

        /**
         * 検索条件を初期化する
         */
         receiptTypeOptions: function() {
            var select = this.receiptType;
            var optionText = ['全表示','要','不要'];

            array.forEach(optionText, function(item) {
                var option = document.createElement('option');
                console.debug(item);
                option.text = item;
                option.value = item;
                select.addOption(option);
            });
        },

        // 帳票出力を行う前に入力チェックを行う
        onOutputButtonClick : function(fileType) {

            if (!this.form.validate()) {
                return false;
            }

            var notifyType = this.form.get('value').notifyType;
            var receiptType = this.form.get('value').receiptType;
            var senderName = this.form.get('value').senderName;
            // var broadnotifyDateTimeFrom = this.form.get('value').broadnotifyDateTimeFrom;
            // var broadnotifyDateTimeTo = this.form.get('value').broadnotifyDateTimeTo;

            var broadnotifyDateTimeFromDate = this.broadnotifyDateTimeFrom._date.displayedValue;
            var broadnotifyDateTimeFromTime = this.broadnotifyDateTimeFrom._time.displayedValue;
            var broadnotifyDateTimeToDate = this.broadnotifyDateTimeTo._date.displayedValue;
            var broadnotifyDateTimeToTime = this.broadnotifyDateTimeTo._time.displayedValue;

            if (!this.broadnotifyDateTimeFrom.validate() || !this.broadnotifyDateTimeTo.validate() || 
            broadnotifyDateTimeFromDate && !broadnotifyDateTimeFromTime || 
            !broadnotifyDateTimeFromDate && broadnotifyDateTimeFromTime ||
            broadnotifyDateTimeToDate && !broadnotifyDateTimeToTime || 
            !broadnotifyDateTimeToDate && broadnotifyDateTimeToTime) {
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '日時が選択されていません'
                    });
                }
                this.infoDialog.show();
                this.infoDialog = null;
            } else {
                // YYYYMMDD形式に変換
                broadnotifyDateTimeFromDate = broadnotifyDateTimeFromDate.replace(/\//g,'');
                broadnotifyDateTimeToDate = broadnotifyDateTimeToDate.replace(/\//g,'');
                // hhmm形式に変換
                broadnotifyDateTimeFromTime = broadnotifyDateTimeFromTime.replace(':', '');
                broadnotifyDateTimeToTime = broadnotifyDateTimeToTime.replace(':', '');
                // YYYYMMDDhhmm形式に変換
                var sendDatetimeFrom = broadnotifyDateTimeFromDate + broadnotifyDateTimeFromTime;
                var sendDatetimeTo = broadnotifyDateTimeToDate + broadnotifyDateTimeToTime;

                this.downloadFile(notifyType, receiptType, senderName, sendDatetimeFrom, sendDatetimeTo, fileType);
            }

        },

        // 帳票出力
        downloadFile : function(notifyType, receiptType, senderName, sendDatetimeFrom, sendDatetimeTo, fileType) {
            var reqid = 'BROADNOTIFY_HIST_LIST_EX';
            var fileName = 'BroadnotifyHistList';
            var paramList = [];
            if (notifyType !== '' && notifyType !== '00') {
                paramList.push({key: 'notifyType', value: notifyType});
            }
            if (receiptType !== '') {
                switch (receiptType) {
                    case '不要': 
                        paramList.push({key: 'receiptType', value: '0'});
                        break;
    
                    case '要':
                        paramList.push({key: 'receiptType', value: '1'});
                        break;
    
                    // xoblos側に持っていくと、検索条件として設定されるので一旦コメントアウト
                    // case '全表示':
                    //     paramList.push({key: 'receiptType', value: ''});
                    //     break;

                    default:
                        break;
                }
            }
            // 送信者未選択、もしくは「端末全て」を選択時は処理に入らない
            if (senderName !== '' && senderName !== this.OSAKA) {
                // 「各局・室」を選択時
                if (senderName === this.MUNICIPALITY) {
                    paramList.push({key: 'municipalityCd', value: senderName});
                // 「各区役所」を選択時
                } else if (senderName === this.REGION) {
                    paramList.push({key: 'regionCd', value: senderName});
                // 「各局・室」「各区役所」配下の組織選択時
                } else if (senderName.substr(0,1) === 'D') {
                    paramList.push({key: 'organizationCd', value: senderName});
                // ユーザ単体を選択時
                } else {
                    paramList.push({key: 'senderName', value: senderName});
                }
            }
            if (sendDatetimeFrom !== '') {
                paramList.push({key: 'sendDatetimeFrom', value: sendDatetimeFrom});
            }
            if (sendDatetimeTo !== '') {
                paramList.push({key: 'sendDatetimeTo', value: sendDatetimeTo});
            }

            fileName = fileType === 'excel' ? fileName + '.xlsx' :
                                              fileName + '.pdf';

            var data4xoblosDownload = {
                fileName: fileName,
                reqid: reqid,
                fileType: fileType,
                paramList: paramList
            };

            var promise = this.download(data4xoblosDownload).then(lang.hitch(this, function() {
                this.infoDialog = new InfoDialog({
                    title : 'ダウンロード完了',
                    content : 'ダウンロードが完了しました。'
                });
                this.infoDialog.show();

            }), lang.hitch(this, function(error) {
                console.log(error);
                this.infoDialog = new InfoDialog({
                    title : 'エラー',
                    content : 'エラーが発生しました。管理者にお問い合わせください。'
                });
                this.infoDialog.show();
            }));

            Loader.wait(promise);
        },

        // 帳票のダウンロード
        download: function(data) {
            var deferred = new Deferred();

            var xhr = new XMLHttpRequest();
            xhr.open('POST', '/api/xoblos/download', true);
            xhr.responseType = 'arraybuffer';
            xhr.setRequestHeader( 'Content-Type', 'application/json' );
            xhr.onload = function() {

                // エラー時は処理を止める
                if(xhr.status !== 200){
                    deferred.reject('status error:'+ xhr.status);
                    return;
                }

                var arrayBuffer = this.response;
                var blob = new Blob([arrayBuffer], {type: 'application/octet-stream'});
                var baseFileName = '緊急通知一覧.xlsx';
                var fileName = baseFileName.replace(/^.*"(.*)"$/, '$1');

                // IE10+
                if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, fileName);
                } else {
                    // 擬似的にAタグを作成
                    var link = document.createElement('a');
                    link.style = 'display: none';
                    document.body.appendChild(link);

                    // AタグのURLにバイナリデータをセット
                    var url = window.URL.createObjectURL(blob);
                    link.href = url;

                    // ファイル名をセット
                    link.download = fileName;

                    // 擬似的にリンクをクリック
                    link.click();
                    // 参照を解放
                    window.URL.revokeObjectURL(url);
                    link.remove();
                }

                deferred.resolve();

            };
            xhr.send(JSON.stringify(data));

            return deferred.promise;
        },

        /**
         * キャンセルボタン押下
         */
        onCancel:function(){
            this.getParent().hide();
        }
    });
});
