/**
 * テンプレート新規登録ダイアログ用モジュール。
 * @module app/view/SafetyMailTempRegisterDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/SafetyMailTempRegisterDialog.html',
    'dstore/Memory',
    'idis/service/Requester',
    'idis/view/page/_PageBase',
    'idis/view/dialog/DialogChain',
    // 以下、変数から参照されないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/form/Form',
    'dijit/form/SimpleTextarea',
    'dijit/form/ValidationTextBox',
    'idis/view/form/Button',
    'idis/view/form/CheckGroup',
    'idis/view/form/RadioGroup',
    'app/safety/auth/view/form/AclButton'
], function(module, declare, lang, template, Memory, Requester, _PageBase, DialogChain) {
    /**
     * テンプレート詳細ダイアログ。
     * @class SafetyMailTempRegisterDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), [_PageBase],
        /** @lends module:idis/view/page/SafetyMailTempRegisterDialog~SafetyMailTempRegisterDialog# */ {
        // テンプレート文字列
        templateString: template,
        
        store: null,
        
        /**
         * 変数を初期化する。
         */
        constructor: function() {
            // エラーメッセージ出力のため、DialogChainを取得
            this.chain = DialogChain.get(this);
            // 質問格納用オブジェクト
            this.store = new Memory({
                'idProperty': 'categoryCd',
                'data': []
            });
        },
        
        /**
         * ウィジェットが画面に追加された後に呼ばれる関数。
         */
        startup: function() {
            // 継承元モジュールで定義された処理を実施
            this.inherited(arguments);
            // 以下、当モジュール固有処理(オーバライド部分)
            this._setCategories();
        },

        /**
         * 質問カテゴリの一覧をDBから取得し、optionとしてセレクトボックスに追加する。
         */
        _setCategories: function() {
            // サーバサイドへのリクエストを発行
            Requester.get('/api/safety/questions').then(lang.hitch(this, function(data) {
                Object.keys(data).forEach(lang.hitch(this, function(key) {
                    // カテゴリ選択用のoptionを設定する
                    var cd = data[key].code;
                    if (cd.substr(0,1) === '1' || cd.substr(0,1) === '2') {
                        this.categorySelector.addOption({
                            value: cd,
                            label: data[key].name
                        });
                        // 質問と回答は、別の関数で使えるようにstoreに保管する。
                        this.store.addSync({
                            categoryCd: cd,
                            questions:  data[key].questions
                        });
                    }
                }));
            }), lang.hitch(this, function(err) {
                // リクエスト失敗時
                console.error(err);
                this.chain.info('質問カテゴリの取得に失敗しました。', 'エラー');
            }));
        },
        
        /**
         *  新規登録ボタンが押された際の処理。
         */
        onSubmit: function() {
            try {
                var value = this.form.get('value');
                // 共通部品の必須チェックと、当モジュールでの必須チェックを行う。
                if (this.form.validate() && this.validateForm(value)) {
                    // 実際の登録処理はPage側でregisterイベントを監視しているメソッドが実施
                    this.emit('register', {value: value});
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * カテゴリセレクトボックスが選択されたときの挙動。
         */
        onCategorySelected: function() {
            // 質問カテゴリコードをキーに質問を検索する
            var cd = this.categorySelector.value;
            var item = this.store.getSync(cd);

            // 初期化
            this.q1.set('value', '');
            this.q2.set('value', '');
            this.q3.set('value', '');

            // 質問の個数分ループ処理
            item.questions.forEach(lang.hitch(this, function(data) {
                // 質問の番号で設定する箇所を選ぶ
                switch(data.no) {
                case 1 : 
                    this.q1.set('value', data.distributionQContents);
                    break;
                case 2 : 
                    this.q2.set('value', data.distributionQContents);
                    break;
                case 3 : 
                    this.q3.set('value', data.distributionQContents);
                    break;
                default: break;
                }
            }));
        },
        
        /**
         * @param value formの情報
         * formの値の妥当性を判定する。
         */
        validateForm: function(value) {
            if (!value.distributionBody) {
                this.chain.info('内容を入力してください。', '入力エラー');
                return false;
            }

            return true;
        }
    });
});
