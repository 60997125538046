/**
 * 市民への情報配信履歴 新規登録ダイアログ用モジュール。
 * @module app/citizenInfoHistory/CitizenInfoHistoryRegisterDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/text!./templates/CitizenInfoHistoryRegisterDialog.html',
    'idis/view/dialog/InfoDialog',
    'idis/view/dialog/DialogChain',
    'idis/view/page/_PageBase',
    'idis/view/Loader',
    'idis/model/UserInfo',
    'idis/store/IdisRest',
    'app/model/DisasterInfo',
    'dojo/dom-class',
    'dojo/dom-construct',
    'dijit/popup',
    'dijit/TooltipDialog',
    'dojo/on',
    'idis/service/Requester',
    // 以下、変数から参照されないモジュール
    'dojox/form/Uploader',
    'dijit/form/ValidationTextBox',
    'dijit/form/Textarea',
    'dijit/form/TextBox',
    'dijit/form/DateTextBox',
    'dijit/form/NumberTextBox',
    'idis/view/form/AclButton',
    'idis/view/form/CheckGroup',
    'idis/view/form/DateTimeInput',
    'idis/view/form/RadioGroup',
    'dijit/TitlePane'
], function(module, declare, lang, domStyle, template, InfoDialog, DialogChain, _PageBase, Loader, UserInfo, IdisRest,
    DisasterInfo, domClass, domConstruct, popup, TooltipDialog, on, Requester) {
    /**
     * 市民への情報配信履歴 新規登録ダイアログ
     *
     *  CitizenInfoHistoryRegisterPage
     *  module:app/citizenInfoHistory/_CitizenInfoHistoryPageBase~_CitizenInfoHistoryPageBase
     * @class DisasterAdminRegisterPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/citizenInfoHistory/CitizenInfoHistoryRegisterPage~CitizenInfoHistoryRegisterPage# */
        {
            // テンプレート文字列
            templateString: template,

            /**
             * constructor
             */
            constructor: function() {
                
                // ダイアログ連鎖を登録
                // 引数に与えたウィジェットのthis.ownを呼び出し、
                // ウィジェットが破棄された際にダイアログ連鎖が破棄されるようになる
                this.chain = DialogChain.get(this);
                
                // 災害IDをDisasterInfoから取得
                this._disasterId = DisasterInfo.getDisasterId();
                if (!this._disasterId) {
                    this._disasterId = 1;
                    console.warn('災害IDが設定されていません。');
                }
            },

            /**
             * buildRendering
             */
            buildRendering: function() {
                this.inherited(arguments);
            },

            // DOM要素構築後に呼ばれる
            postCreate: function() {
            },

            // 防災行政無線の値が変更された時の処理
            onChangeEmergencyRadioRegistFlg: function (evt) {
                if(evt.target.value === '0') {
                    // this.municipalityCd.reset();
                    domStyle.set(this.municipalityCds, 'display', 'none');
                }else {
                    domStyle.set(this.municipalityCds, 'display', '');
                }
            },

            // 登録ボタン押下時
            onSubmit: function() {
                try {

                    var value = this.form.get('value');
                    value.disasterId = this._disasterId;

                    // フォームのバリデーションを実施
                    if (!this.form.validate()) {
                        return false;
                    }
                    // フォームのバリデーションを実施（独自チェック）
                    if (!value.transmitOrganizationCd || value.transmitOrganizationCd === '') {
                        this.chain.info('配信組織を選択してください。', '入力エラー');
                    return false;
                    }
                    if (value.emergencyRadioFlg === '1' && 
                        (!value.municipalityCd || value.municipalityCd.length === 0 )) {
                        this.chain.info('配信区を選択してください。', '入力エラー');
                    return false;
                    }

                    // 配信区が設定された場合の処理
                    if(value.municipalityCd.length > 0) {
                        var citizenInfoHistoryAreas = [];
                        for(var i=0; i < value.municipalityCd.length; i++) {
                            // jsonの地区の並び順を強制的にid順に変更されないように先頭に「_」をつけて文字列と認識させているので
                            // 先頭の一文字目を取り除く
                            citizenInfoHistoryAreas.push(value.municipalityCd[i].slice(1));
                        }
                        value.citizenInfoHistoryAreas = citizenInfoHistoryAreas.join(',');
                    } else {
                        value.citizenInfoHistoryAreas = '';
                    }
                    delete value.municipalityCd;

                    console.log(UserInfo);
                    console.log(this);
                    console.log(UserInfo._userInfo.userId);
                    console.log(Requester);

                    this.emit('send', {value: value});
                } catch (e) {
                    console.error(e);
                }
            },

            /**
             * ダイアログを初期化する。（新規登録時）
             */
            initDialog: function() {
                this.form.reset();
            },

            /**
             * キャンセルボタン押下時処理
             */
            cancel: function() {
            	// ダイアログを閉じる
            	this.leave();
            }
        });
});
