/**
 * 利用者登録用ダイアログモジュール。
 * @module app/broadnotify/TerminalRegisterDialog
 */
define([
    'module',
    'leaflet',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/text!./templates/TerminalRegsiterDialog.html',
    'idis/view/Loader',
    'idis/service/Requester',
    'idis/store/IdisRest',
    'app/config',
    './_TerminalDialogBase',
    // 以下、変数で受けないモジュール
    'app/view/form/BroadnotifyUserMultiChecker',
], function(module, leaflet, declare, lang, domStyle, template, Loader,
    Requester, IdisRest, config, _TerminalDialogBase) {

    return declare(module.id.replace(/\//g, '.'), _TerminalDialogBase,
    {
        // テンプレート文字列
        templateString: template,

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * ユーザを新規登録する。
         */
        onSubmit: function() {
            try {
                console.debug('[登録]ボタンがクリックされました。');

                // フォームのバリデーションを実施
                if (!this.form.validate()) {
                    return false;
                }

                // 固有のバリデーションを実施
                if(!this.validate()) {
                    return false;
                }

                var value = this.form.get('value');

                this.emit('register', {value: value});

            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * ダイアログを初期化する。（新規登録時）
         */
        initDialog: function() {
            this.reset();
        },
    });
});
