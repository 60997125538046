/**
 * 流域平均雨量情報の詳細ページ
 * @module app/riverBasin/RiverBasinRainfallDetailPage
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-class',
    'dojo/dom-style',
    'dojo/text!./templates/RiverBasinRainfallDetailPage.html',
    'dojo/topic',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/service/Requester',
    'idis/store/IdisRest',
    'idis/view/page/_PageBase',
    'idis/view/Loader',
    'app/observation/view/form/DateTimeSelect',
    'app/observationstation/model/DataKind',
    'app/riverBasin/chart/RiverBasinRainfallHistoryChart',
    //
    'dijit/form/Select',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'app/riverBasin/grid/RainfallHistoryGrid',
    'app/riverBasin/grid/RelatedObservatoryGrid'
], function(
    module, array, declare, lang, domClass, domStyle, template, topic,
    Locator, Router, Requester, IdisRest, _PageBase, Loader,
    DateTimeSelect, DataKind,  HistoryChart) {

    var _apiUrlPrefix = '/api/riverBasinRainfall';

    /**
     * 流域平均雨量情報一覧画面
     * @class RiverBasinRainfallPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    	/** @lends module:app/riverBasin/RiverBasinRainfallPage~RiverBasinRainfallPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--riverBasinRainfall',

        // 雨量水位グリッド用ストア
        historyStore: null,

        // 関連雨量観測局グリッド用ストア
        observatoryStore: null,

        /**
         * 時間モード
         * {string} '10': 10分 '60': 正時
         */
        mode: Locator.getQuery().timeMode || '10',

        /**
         * 前回のデータ取得日時
         * {string} 'yyyy-MM-dd HH:mm'
         */
        lastRequestDateTime: '',

        constructor: function() {
            this.inherited(arguments);

            this.historyStore = new IdisRest({
                idProperty: 'basinAverageRainfall10MinId',
                target: _apiUrlPrefix + '/detail'
            });

            this.observatoryStore = new IdisRest({
                idProperty: 'rainfallObsId',
                target: _apiUrlPrefix + '/related'
            });
        },

        buildRendering: function() {
            this.inherited(arguments);

            this.setLatestDatetime();
        },

        /**
         * DOM構築後に呼ばれる
         */
        postCreate: function() {
            this.inherited(arguments);

            // 日時の変更を監視
            this.own(topic.subscribe(DateTimeSelect.TOPIC.CHANGE_DATE_TIME, lang.hitch(this, this.onChangeDateTime)));

            // 最新ボタンのクリックが押されたら
            this.own(topic.subscribe(DateTimeSelect.TOPIC.CLICK_LATEST, lang.hitch(this, this.onClickLatest)));

            // 流域をセット
            this.initSelectBox();

            // グリッドのカラムをセットする
            this.setGridColumn();
            // 凡例を切り替える
            this.setLegend();

            // グラフエリアを作る
            this.chart = new HistoryChart();
            this.chartContainer.on('show', lang.hitch(this, function() {
                this.chart.create(this.chartNode, this.legendNode);
            }));
        },

        /**
         * ロード時に最新時刻を取得して時刻選択パーツにセット
         * @param {*} value
         */
        setLatestDatetime: function() {
            // 日付セレクトボックスを生成・設置する
            // 初期表示は、時間モード10分で一覧で指定したデータか最新の時間データ
            var dateQuery = Number(Locator.getQuery().date);
            Requester.get(_apiUrlPrefix + '/latestDataTime?timeMode=' + this.mode)
            .then(lang.hitch(this, function(date) {
                this.dateTimeSelect = new DateTimeSelect({
                    to: dateQuery || date
                });
                this.dateTimeSelect.placeAt(this.dateTimeSelectNode, 'only');
                this.borderContainer.resize();
            }), lang.hitch(this, function() {
                this.dateTimeSelect = new DateTimeSelect({
                    to: dateQuery
                });
                this.dateTimeSelect.placeAt(this.dateTimeSelectNode, 'only');
            }));
        },

        /**
         * 時刻が変更された時の動作
         * @param {*} datetime
         */
        onChangeDateTime: function(datetime) {
            // 1回の操作で日付と時間が同時に変更される場合があり, このとき同じ日時で2回続けて変更イベントが発行される
            // 同じ日時で連続でデータ取得のリクエストが飛ぶことを避けるため, 前回の日時を保持し、異なる場合のみデータを取得するようにする
            if (this.lastRequestDateTime === (datetime.date + ' ' + datetime.time)) {
                return;
            }

            // IEでDate生成時にエラーとなるのを回避
            var dateStr = datetime.date.replace(/-/g, '/') + ' ' + datetime.time;
            var date = new Date(dateStr).getTime();

            // 雨量水位グリッドの更新
            var historyFilter = new this.historyStore.Filter();
            historyFilter = historyFilter.eq('date', date);
            this.updateHistoryGrid(historyFilter);

            // 関連雨量観測所グリッドの更新
            var observatoryFilter = new this.observatoryStore.Filter();
            observatoryFilter = historyFilter.eq('date', date);
            this.updateObservatoryGrid(observatoryFilter);

            this.lastRequestDateTime = datetime.date + ' ' + datetime.time;
        },

        // セレクトボックスの初期化を行う
		initSelectBox: function(){
			// 観測局セレクトボックスの初期化
			// デフォルトでは県全域の観測局を表示
			var promise =
				Requester.get('/api/observationStation/selectBox/?dataKind='+ DataKind.RIVER_BASIN);
			Loader.wait(promise).then(lang.hitch(this, function(data) {
				this.observationStationsSelectBox.options = data;
                this.observationStationsSelectBox.set('value', Locator.getQuery().riverBasinId);

				// 時間モードの初期化
				var timeModeQuery = Locator.getQuery().timeMode;
				if(timeModeQuery === '60'){
					this.timeModeSelect.set('value',timeModeQuery);
				}
            }));
		},

		/**
		 * [観測局]を変更し、表の更新を行う。
		 */
		onObservationStationChange: function(){
			console.debug('[観測局]ボックスが変更されました。');
			if(this.changeFlg !== 0){
                this.updateGrid();
			}
			this.changeFlg++;
		},

		// 一つ前の観測局を取得する
		onPreviousButtonClick: function() {
			console.debug('[<]ボタンがクリックされました。');
			var value = this.observationStationsSelectBox.get('value');
			var options = this.observationStationsSelectBox.options;
			// 最初の観測局の場合はなにもしない
			if(value !== options[0].value){
				array.forEach(this.observationStationsSelectBox.options, lang.hitch(this, function(option, i){
					if(option.value===value){
						this.observationStationsSelectBox.set('value', options[i - 1].value);
					}
				}));
			}
		},

		// 一つ後の観測局を取得する
		onNextButtonClick: function() {
			console.debug('[>]ボタンがクリックされました。');

            var value = this.observationStationsSelectBox.get('value');
			var options = this.observationStationsSelectBox.options;
			// 最後の観測局の場合はなにもしない
			if(value !== options[options.length - 1].value){
				array.forEach(this.observationStationsSelectBox.options, lang.hitch(this, function(option, i){
					if(option.value===value){
						this.observationStationsSelectBox.set('value', options[i + 1].value);
					}
				}));
			}
		},

        /**
         * 最新ボタンが押された時の動作
         */
        onClickLatest: function() {
            Requester.get(_apiUrlPrefix + '/latestDataTime?timeMode=' + this.mode)
            .then(lang.hitch(this, function(date) {
                this.dateTimeSelect.rebuild(date);

            }), lang.hitch(this, function() {
                this.dateTimeSelect.rebuild(new Date());
            }));
        },

        /**
         * 時間モードが変更された際に呼ばれる
         */
        onChangeModeSelect: function(value) {
            this.mode = value;
            // 観測時間選択の表示を切り替える
            this.dateTimeSelect.changeMode(value);

            this.updateGrid();
        },

        /**
         * 雨量推移グリッド及び関連観測局グリッドの更新
         */
        updateGrid: function(){
            // 新しい[時間モード]で雨量推移グリッドの更新
            var historyFilter = new this.historyStore.Filter();
            // 観測日時をフィルターにセット
            var date = new Date(this.lastRequestDateTime).getTime();
            historyFilter = historyFilter.eq('date', date);
            // サーバーにリクエストする
            this.updateHistoryGrid(historyFilter);

            // 新しい[時間モード]で関連観測局グリッドの更新
            var observatoryFilter = new this.observatoryStore.Filter();
            // 観測日時をフィルターにセット
            observatoryFilter = observatoryFilter.eq('date', date);
            // サーバーにリクエストする
            this.updateObservatoryGrid(observatoryFilter);

            // グリッドのカラムをセットする
            this.setGridColumn();
            // 凡例を切り替える
            this.setLegend();
        },

        /**
         * グラフを作成する
         * @param data 検索結果の観測データ
         */
        initChart: function(data){
            // 潮位グラフ
            this.chart.create(this.chartNode, this.legendNode,
                {mode:this.mode, data:data});
        },

		/**
		* 10分と正時でグリッドのカラム表示・非表示項目を切り替える
		*/
		setGridColumn: function() {
			if (this.mode === '60') {
				// 正時の場合、
                // 「時間雨量」カラムを表示、「10分雨量」「60分雨量」を非表示
                domClass.remove(this.historyGrid.domNode, 'is-10minMode');
                domClass.add(this.historyGrid.domNode, 'is-60minMode');
                domClass.remove(this.observatoryGrid.domNode, 'is-10minMode');
                domClass.add(this.observatoryGrid.domNode, 'is-60minMode');
			} else {
				// 10分の場合、
				// 「時間雨量」カラムを非表示、「10分雨量」「60分雨量」を表示
                domClass.add(this.historyGrid.domNode, 'is-10minMode');
                domClass.remove(this.historyGrid.domNode, 'is-60minMode');
                domClass.add(this.observatoryGrid.domNode, 'is-10minMode');
                domClass.remove(this.observatoryGrid.domNode, 'is-60minMode');
			}
		},

        /**
         * 雨量推移グリッド更新
         * @param {*} filter storeに対するfilterオブジェクト
         */
        updateHistoryGrid: function(filter) {
            // 時間モードをフィルターにセット
            filter = filter.eq('timeMode', this.mode);
            // 流域をフィルターにセット
            filter = filter.eq('riverBasinId', this.observationStationsSelectBox.get('value'));
            var collection = this.historyStore.filter(filter);
            this.historyGrid.set('collection', collection);
            collection.fetch().then(lang.hitch(this, function(data){
                this.initChart(data);
            }));
        },

        /**
         * 関連観測局グリッド更新
         * @param {*} filter storeに対するfilterオブジェクト
         */
        updateObservatoryGrid: function(filter) {
            // 時間モードをフィルターにセット
            filter = filter.eq('timeMode', this.mode);
            // 流域をフィルターにセット
            filter = filter.eq('riverBasinId', this.observationStationsSelectBox.get('value'));
            var collection = this.observatoryStore.filter(filter);
            this.observatoryGrid.set('collection', collection);
        },

		onRiverBasinLinkClick: function(evt) {
			// ブラウザーの遷移処理をキャンセル
			evt.preventDefault();
			Router.moveTo('observation/basin');
		},

        /**
         * 時間モードによって、凡例を切り替える
         */
        setLegend: function(){
            if (this.mode === '60') {
                // 正時の場合、
                // 「時間雨量」の凡例を表示
                // 「10分雨量」の凡例を非表示
                domStyle.set(this.hourlyLegend, 'display', '');
                domStyle.set(this.minLegend, 'display', 'none');
            } else {
                // 10分の場合、
                // 「10分雨量」の凡例を表示
                // 「時間雨量」の凡例を非表示
                domStyle.set(this.minLegend, 'display', '');
                domStyle.set(this.hourlyLegend, 'display', 'none');
            }
        }
    });
});
