/**
 * <緊急通知通知グループ一覧画面>
 *
 * @module app/broadnotify/BroadnotifyGroupListPage.js
 */
define(['module', // モジュールのパスを返す
    'dojo',
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/text!./templates/BroadnotifyGroupListPage.html', // テンプレート文字列
    'idis/view/page/_PageBase', // 共通基底クラス
    'idis/store/IdisRest',
    'dojo/dom',
    'dojo/dom-style',
    'idis/control/Router',
    'idis/model/UserInfo',
    'app/config',
    'idis/consts/USER_TYPE',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/form/Form',
    'dijit/layout/ContentPane',
    'idis/view/form/Button',
    '../broadnotify/BroadnotifyGroupListGrid'
], function(module, dojo, declare, lang, template, _PageBase, IdisRest, dom, domStyle,
    Router, UserInfo, config, USER_TYPE) {
    /**
     * 検索条件格納用オブジェクト
     * @type {Object}
     */
      var filterStore = {};
    /**
     * 緊急通知通知グループ一覧画面
     *
     * @class BroadnotifyGroupListPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/broadnotify/BroadnotifyGroupListPage~BroadnotifyGroupListPage# */
    {
        // テンプレート文字列
        templateString: template,

        store: null,
        
        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--broadnotify',

        // イベント格納
        _events: [],

        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'groupId',
                target: '/api/broadnotify/group'
            });
        },

        // DOMノードを生成するためのメソッド
        buildRendering: function() {
            this.inherited(arguments);
        },

        startup: function() {
            this.inherited(arguments);
            this.initBroadnotifyGroupGrid();
            this.borderContainer.resize();
            if(UserInfo.getUserType() === USER_TYPE.MUNICIPALITY &&
                UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd){
                domStyle.set(this.overviewLabel, 'display', 'none');
            }
        },
        /**
         * 検索条件を保管する
         */
        setFilterStore: function() {
          //初期化する
          filterStore = {};
          var value = this.form.get('value');
          if(value.keyword) {
              filterStore.keyword = value.keyword;
          }

        },

        /**
         * 保管した検索条件をフォームにセットする
         */
        setFilterData: function() {
            //保管されていれば値をセット
            if(filterStore.keyword) {
                this.keyword.set('value', filterStore.keyword);
            }

        },

        initBroadnotifyGroupGrid: function() {
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.broadnotifyGroupGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                this.onDetailButtonClick(evt.item);
            }));
            // 初回生成時は一覧から選択された市町村を初期表示する
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();

            // 保管した検索条件をセットする
            this.setFilterData();
            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);
            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.broadnotifyGroupGrid.set('collection', collection);
        },

        /**
         * グリッドの検索条件を指定された値で更新する。
         * @param {Object} value name属性と値のマッピング
         */
        updateGridQuery: function() {
        	var value = this.form.get('value');
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();

            if (value.keyword) {
                filter = filter.eq('keyword', value.keyword);
            }

            var collection = this.store.filter(filter);
            this.broadnotifyGroupGrid.set('collection', collection);
            
        },
        
        /**
         * 検索ボタンが押されたときに呼び出される。
         * テンプレートHTML内のFormウィジェットに対するdata-dojo-attach-eventでこのイベントを紐付けている。
         * また、同Formウィジェットに対しdata-dojo-attach-pointを指定し、this.formからアクセス出来るようにしている。
         */
        onSubmit: function() {
            try {
                if (this.form.isValid()) {
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery();
                }
                this._events.push(this.endTimeEvent);
            } catch (e) {
                console.error(e);
            } finally {
                return false;
            }
        },

        /**
         * ページ遷移時に格納していたイベントを削除する。
         */
        _removeEvents: function(){
            this._events.forEach(function(event){
                event.remove();
            });
        },

        //新規登録ボタンを押下したときの挙動
        onRegisterButtonClick: function() {
            // 避難所情報新規登録画面へ遷移
            Router.moveTo('broadnotify/group/register');
        },

        //詳細ボタンを押下したときの挙動
        onDetailButtonClick: function(object) {
            // 避難所情報詳細画面へ遷移
            Router.moveTo('broadnotify/group/detail', {
                id : object.broadnotifyGroupId
            });
        }
    }
    );
});
