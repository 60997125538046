/**
 * ユーザーに権限のある県・県民局・市町村選択用入力パーツ
 * @module app/sending/MunicipalitySelector
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/consts/USER_TYPE',
    'idis/model/UserInfo',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest',
    '../config',
    'app/view/form/MunicipalityListMultiSelector'
], function(module, declare, USER_TYPE, UserInfo, CacheStoreModel, CacheTreeRest, config, MRMSelector) {
    /**
     * 県/県民局/市町村選択用入力パーツ
     * @class TestSelector
     * @extends module:idis/view/form/MunicipalityListMultiSelector~MunicipalityListMultiSelector
     */

    return declare(module.id.replace(/\//g, '.'), MRMSelector,
        /** @lends module:app/sending/MunicipalitySelector~idis/view/form/ManageRegionMunicipalitySelector# */ {
        constructor : function() {
            var param = '';
            var municipalityCds = UserInfo.getMunicipalityCds();
            if(municipalityCds) {
                var firstFlg = true;
                for (var i = 0; i < municipalityCds.length; i++) {
                    if(firstFlg) {
                        param += '?';
                        firstFlg = false;
                    } else {
                        param += '&';
                    }
                    param += 'array[]=' + municipalityCds[i];
                }
            }

            console.debug('param = ' + param);

            this.model = new CacheStoreModel({
                store: new CacheTreeRest({
                    target: '/api/municipalities/municipality4Tree' + param
                })
            });
        },

        startup: function() {
            // 所属自治体を初期値とする
            switch (UserInfo.getUserType()) {
                case USER_TYPE.MUNICIPALITY:
                    this.set('value', [UserInfo.getMunicipalityCd()]);
                    this.set('noAllButton', true);
                    break;
            }
        }
    });
});
