/**
 * 災害情報詳細/更新画面用モジュール。
 * @module app/timeline/TimelineDetailRegisterDialog
 */
define([
    'module',
    'dojo/json',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/topic',
    'dojo/text!./templates/TimelineDetailRegisterDialog.html',
    'idis/view/dialog/InfoDialog',
    'idis/view/page/_PageBase',
    // 以下、変数から参照されないモジュール
    'dijit/form/Form',
    'dijit/form/TextBox',
    'dijit/form/ValidationTextBox',
    'dijit/form/Textarea',
    'dijit/form/CheckBox',
    'dijit/form/DateTextBox',
    'dijit/form/Select',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/CheckGroup',
    'idis/view/form/RadioGroup',
    'idis/service/Requester',
    'idis/view/form/DateTimeInput'
], function(module, json, declare, lang, topic, array, template, InfoDialog, _PageBase) {
    /**
     * タイムライン詳細情報登録画面。
     * @class TimelineDetailRegisterDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/timeline/TimelineDetailRegister~TimelineDetailRegister# */ {
        // テンプレート文字列
        templateString: template,

        item: null,

        store: null,
        
        constructor: function(){
            this.inherited(arguments);
        },

         /**
         * DOM生成
         */
        buildRendering: function() {
            this.inherited(arguments);
            // グリッドを初期化
          //  this.initGrid();
        },
        
        /**
         * 画面生成完了
         */
        startup: function() {
            // this.inherited(arguments);
        },

        onSubmit: function() {
            try {
                    // data-dojo-propsを利用した必須チェック
                    if (!this.form.validate()) {
                        console.log('入力違反');
                        return false;
                    }
                    // モジュール固有の入力チェック
                    if (!this.validate()) {
                        return false;
                    }
                    //if (!this.validateNotForm()){
                      //  return false;
                    //}
                    var organization = this.setOrganizationFromTreeItem
                        (this.hldOrganization.tree._itemNodesMap, this.hldOrganization.get('value'));
                    var deptCd = organization.deptCd !== null ? organization.deptCd : null;
                    var sectCd = organization.sectCd !== null ? organization.sectCd : null;
                    var unitCd = organization.unitCd !== null ? organization.unitCd : null;
                    

                    var data = {
                        estimatedTime: this.estimatedTime.get('value').getTime(),
                     // organization: organizationCd,
                        deptCd: deptCd,
                        sectCd: sectCd,
                        unitCd: unitCd,
                        category: this.categoryCd.get('value'),
                        timelineRivers: this.timelineRivers.get('value'),
                        comment: this.comment.get('value')
                    };
                    this.emit('send', {
                        value: data
                    });
            } catch (e) {
                console.error(e);
            }
            return false;
        }, 
        setOrganizationFromTreeItem: function(treeObj, value){
            var organization = {};
            if(value.substr(0,2) === 'UU'){
            var parentId = treeObj[value][0].item.parentId;
                organization.unitCd = treeObj[value][0].item.id.substr(1,6);
                organization.sectCd = treeObj[value][0].item.parentId.substr(1,6);
                organization.deptCd = treeObj[parentId][0].item.parentId.substr(1,6);
                // FIXME: deptCdの取得をする
            } else if (value.substr(0,2) === 'SS'){
                organization.unitCd = null;
                organization.sectCd = treeObj[value][0].item.id.substr(1,6);
                organization.deptCd = treeObj[value][0].item.parentId.substr(1,6);
            } else if (value.substr(0,2) === 'DD'){
                organization.unitCd = null;
                organization.sectCd = null;
                organization.deptCd = treeObj[value][0].item.id.substr(1,6);
            }
            organization.name = treeObj[value][0].item.name;
            return organization;
        },
        validate: function() {
            if (this.hldOrganization.get('value') === '' ||
            this.hldOrganization.get('value') === null ||
            this.hldOrganization.get('value') === void 0) {
                this.infoDialog = new InfoDialog({
                    title : '入力エラー',
                    content : '対応課を選択してください。'
                });
                this.infoDialog.show();
                return false;
            }
            else if (this.categoryCd.get('value') === '00') {
                this.infoDialog = new InfoDialog({
                    title : '入力エラー',
                    content : 'カテゴリを選択してください。'
                });
                this.infoDialog.show();
                return false;
            }
            return true;
        }
    });
});
