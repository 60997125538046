/**
 * 避難基準超過情報パネル
 * @module app/monitor/EvacStdLimitOverPanel
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/json',
    'dojo/topic',
    'dojo/text!./templates/EvacStdLimitOverPanel.html',
    'idis/control/Router',
    'idis/model/UserInfo',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    'idis/view/page/_PageBase',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane'
], function(module, declare, lang, JSON, topic, template, Router, UserInfo, Requester,
    DialogChain, Loader, _PageBase) {
    /**
     * 避難基準超過情報パネル
     * @class EvacStdLimitOverPanel
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/monitor/EvacStdLimitOverPanel~EvacStdLimitOverPanel# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--monitor',

        /**
         * 最大取得件数
         */
        _maxCount: 2,

        /**
         * 市町村コード
         */
        _municipalityCd: null,

        /**
         * constructor
         */
        constructor: function() {
            this.chain = DialogChain.get(this);
            this._municipalityCd = UserInfo.getSelectedMunicipalityCd();
            console.debug('避難基準超過情報表示対象の市町村コード：' + this._municipalityCd);
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);
            this.initTable();

            // 市町村切替時に避難基準超過情報を更新
            this.own(topic.subscribe('idis/view/form/MunicipalitySelectForm::selected',
                lang.hitch(this, function(payload) {
                    this._municipalityCd = payload.municipalityCd;
                    this.initTable();
            })));
        },


        /**
         * テーブルを初期化します
         */
        initTable: function() {
            var self = this;
            var param = '?count=' + this._maxCount;

            if(this._municipalityCd) {
                param = param + '&municipalityCd=' + this._municipalityCd;
            }

            var promise = Requester.get('/api/evac-order-over-lmts/summary' + param, {
                headers: {'Content-Type': 'application/json; charset=utf-8'},
                handleAs: 'json',
                preventCache : true
            }).then(function(data) {
                console.debug('避難基準超過情報（' + self._municipalityCd + '）：' +
                    JSON.stringify(data));
                var items = data.items;

                var html = [''];
                if(items.length === 0) {
                    html.push('<tr>');
                    html.push('<td colspan="2" style="text-align: left">現在、避難基準超過は発生していません。</td>');
                    html.push('</tr>');
                } else {
                    topic.publish('app/view/Header::alert', items);
                    items.forEach(function(item) {
                        html.push('<tr>');
                        html.push('<td style="width: 35%;">');
                        html.push(item.overLmtTimestamp);
                        html.push('</td>');
                        html.push('<td style="text-align: left">');
                        html.push(item.observatoryName + ' で ' + self.getEvacOrderLmtType(item));
                        html.push('</td>');
                        html.push('</tr>');
                    });
                }
                html.push('</tr>');

                if(self.evacStdLimitOverTable) {
                    // 画面遷移してない場合のみ注
                    self.evacStdLimitOverTable.innerHTML = html.join('');
                }

            }, function(error) {
                console.log(error);
                self.chain.info('情報の取得に失敗しました。', 'エラー');
            });
            //ローダーの表示
            Loader.wait(promise);
        },

        /**
         * 避難基準超過種別を取得します
         */
        getEvacOrderLmtType: function(item) {
            var type = null;
            switch(item.evacOrderLmtType) {
               case '0101':
                   type = '大雨注意報基準超過';
                   break;
               case '0102':
                   type = '大雨警報(土砂災害)基準超過';
                   break;
               case '0103':
                   type = '3時間後に基準値超過';
                   break;
               case '0104':
                   type = '2時間後に基準値超過';
                   break;
               case '0105':
                   type = '1時間後に基準値超過';
                   break;
               case '0106':
                   type = '実況で基準値超過';
                   break;
               case '0201':
                   type = '雨量基準値超過';
                   break;
               case '0301':
                   type = '水防団待機水位超過';
                   break;
               case '0302':
                   type = '氾濫注意水位超過';
                   break;
               case '0303':
                   type = '避難判断水位超過';
                   break;
               case '0304':
                   type = '氾濫危険水位超過';
                   break;
               case '0401':
                   type = '警報基準潮位超過';
                   break;
               case '0402':
                   type = '注意基準潮位超過';
                   break;
           }

           return type;
       },

        onEvacStdLimitOverLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            // 時系列情報管理へ遷移
            Router.moveTo('evacstdlimitover');
        }
    });
});
