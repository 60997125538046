/**
 * 防災ポータル情報配信テンプレート詳細ダイアログモジュール。
 * @module app/prefsitesending/PrefSiteSendingTemplateDetailDialog
 */
define([
    'module',
    'dojo/dom-style',
    'dojo/_base/declare',
    'dojo/text!./templates/PrefSiteSendingTemplateDetailDialog.html',
    'idis/service/Requester',
    'idis/model/UserInfo',
    'idis/consts/ACL',
    'idis/view/dialog/DialogChain',
    './_PrefSiteSendingTemplateDialogBase'
    // 以下、変数で受けないモジュール
    // 変数で受けないモジュールなし
], function(module, domStyle, declare, template, requester, UserInfo, ACL, 
        DialogChain, _PrefSiteSendingTemplateDialogBase) {
    /**
     * 防災ポータル情報配信テンプレート詳細ダイアログ
     * @class PrefSiteSendingTemplateDetailDialog
     * @extends module:idis/view/page/_PrefSiteSendingTemplateDialogBase~_PrefSiteSendingTemplateDialogBase
     */
    return declare(module.id.replace(/\//g, '.'), _PrefSiteSendingTemplateDialogBase, {
        // テンプレート文字列
        templateString: template,

        // バージョン管理
        _version: null,

        constructor: function() {
            // ダイアログ連鎖を登録
            // 引数に与えたウィジェットのthis.ownを呼び出し、
            // ウィジェットが破棄された際にダイアログ連鎖が破棄されるようになる
            this.chain = DialogChain.get(this);
        },

        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * ダイアログの初期化
         */
        initDialog: function(obj) {
            this.setButtonStatus();
            this.initForm(obj);
        },

        /**
         * ボタンの表示非表示
         */
        setButtonStatus: function(){
            var role = UserInfo.getRoleCd();
            if (role === ACL.OTHER_USER) { // TODO 要確認。とりあえずその他ユーザを除外。
                domStyle.set(this.deleteButton.domNode, 'display', 'none');
                domStyle.set(this.updateButton.domNode, 'display', 'none');
                this.attachFile.set('disabled', true);
            }
        },

        /**
         * フォームの初期化
         */
        initForm: function(object) {
            this.form.reset();
            this.sendingTmplId = object.sendingTmplId;
            var self = this;
            requester.get('/api/prefsitesending/template/' + this.sendingTmplId).then(function(data) {
                self.form.set('value', data);
                self._version = data.version;
            });
        },

        /**
         * 更新
         */
        onSubmit: function(){
            try {
                console.debug('[更新]ボタンがクリックされました。');

                // フォームのバリデーションを実施
                if (!this.form.validate()) {
                    return false;
                }

                // 固有のバリデーションを実施
                if(!this.validate()) {
                    return false;
                }

                var value = this.form.get('value');
                value.sendingTmplId = this.sendingTmplId;
                value.version = this._version;
                this.emit('update', {value: value});
            } catch (e) {
                console.error(e);
                this.chain.infoError(e);
            }
            return false;
        },

        /**
         * 削除
         */
        onDelete: function(){
            console.debug('[削除]ボタンがクリックされました。');
            var value = {};
            value.sendingTmplId = this.sendingTmplId;
            value.version = this._version;
            this.emit('remove', {value: value});
        }
    });
});
