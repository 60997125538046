/**
 * テンプレート選択ダイアログ画面用モジュール。
 * @module app/prefsitesending/PrefSiteSendingSelectorDialog
 */
define([
	'module',
	'dojo/_base/declare',
	'dojo/_base/lang',
	'dojo/text!./templates/PrefSiteSendingSelectorDialog.html',
	'idis/view/dialog/InfoDialog',
	'idis/store/IdisRest',
	'idis/view/page/_PageBase',
    'dijit/registry',
	// 以下、変数で受けないモジュール
	'dijit/layout/ContentPane',
	'idis/view/form/Button',
	'app/prefsitesending/PrefSiteSendingSelectorGrid'
], function(module, declare, lang, template, InfoDialog, IdisRest, WidgetBase, registry) {

    /**
     * テンプレート選択ダイアログ
     * @class PrefSiteSendingSelectorDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), WidgetBase,
        /** @lends app/view/Header~Header# */ {

        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        // baseClass: 'parentprefsitesending-selector',
		baseClass: 'idis-Page idis-Page--prefsitesending',

        /**
         * データ格納用オブジェクト
         * @type {module:dstore/Store}
         */
        store: null,

        detailObj: null,
        registerObj: null,

        /**
         * コンストラクタ
         */
        constructor: function() {
            this.inherited(arguments);
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'sendingTmplId',
                target: '/api/prefsitesending/template'
            });
        },

        /**
         * DOM生成
         */
        buildRendering: function() {
            this.inherited(arguments);
            // グリッドを初期化
            this.initGrid();
        },

        /**
         * 画面生成完了
         */
        startup: function() {
            this.inherited(arguments);
			this.gridPane.resize();
        },

        /**
         * 画面を再表示
         */
        refresh: function() {
            //this.container.resize();
            this.item = null;
            this.registerObj = null;
            this.detailObj = null;
            this.grid.refresh();
        },

		resize: function(){
			this.inherited(arguments);
			this.gridPane.resize();
		},

        /**
         * グリッド初期化
         */
        initGrid: function() {
            var filter = new this.store.Filter();
            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);
            this.grid.set('collection', collection);

            this.grid.on('applyButtonClick', lang.hitch(this, function(evt) {
                this.item = evt.item;
                this.onSubmit();
            }));
        },

        /**
         * テンプレート変更実行
         */
        onSubmit: function() {
            if (!this.item) {
                InfoDialog.show('入力チェック', 'テンプレートが選択されていません');
                return false;
            }
            if (this.detailObj) {
                this.detailObj.subject.set('value', this.item.tmplSubject);
                this.detailObj.bodyText.set('value', this.item.tmplBody);
                this.detailObj = null;
                this.leave();
            } else {
                this.registerObj.subject.set('value', this.item.tmplSubject);
                this.registerObj.bodyText.set('value', this.item.tmplBody);
                this.registerObj = null;
                var dialog = registry.byId('outerRegisterSelectorDialog');
                dialog.hide();
            }
        }
    });
});
