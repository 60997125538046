/**
 * 通行規制情報一覧画面の実装用モジュール
 * @module app/traffic/view/TrafficRegulationPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/json',
    'dojo/text!./templates/TrafficRegulationPage.html',
    'app/model/LayerStore',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/store/IdisRest',
    'idis/model/UserInfo',
    'idis/view/grid/IdisGrid',
    'idis/view/page/_PageBase',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    // 以下、変数として受け取らないモジュール
    './TrafficRegulationGrid',
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/Textarea',
    'dijit/form/TextBox',
    'dijit/form/CheckBox',
    'dijit/layout/BorderContainer',
    'idis/view/form/Button',
    'idis/view/form/AclButton',
    'idis/view/form/CheckGroup',
    'idis/view/form/DateTimeInput',
    '../../view/form/DisasterSelector',
    '../../view/form/OrganizationSelector',
    'app/traffic/view/TrafficFormDialog'
], function(module, declare, lang, arrayUtil, json, template, LayerStore, Locator, Router,
    IdisRest, UserInfo, IdisGrid, _PageBase, Requester, DialogChain, Loader) {

    /**
    * 検索条件格納用オブジェクト
    * @type {Object}
    */
    var trafficFilterStore = {};

    /**
     * 通行規制情報一覧画面
     * @class TrafficRegulationPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/traffic/view/TrafficRegulationGridPage~TrafficRegulationPage# */
        {

            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--traffic',

            /**
             * データ格納用オブジェクト
             * @type {module:dstore/Store}
             */
            store: null,

            constructor: function() {
                // 連鎖ダイアログを登録
                this.chain = DialogChain.get(this);

                // データ格納用オブジェクト
                this.store = new IdisRest({
                    idProperty: 'trafficRegulationId',
                    target: '/api/traffic/regulations'
                });

                // ユーザ情報取得サンプル
                console.debug('現在のユーザのID: ' + UserInfo.getId());
                console.debug('****現在のユーザのACL****');
                console.debug(UserInfo.getAcl());
                console.debug('****************');
            },

            /**
             * グリッドを初期化する。
             */
            initTrafficRegulationGrid: function() {
                console.debug('initTrafficRegulationGrid');

                // 保管した検索条件をセットする
                this.setFilterData();

                // グリッドの検索条件を更新
                this.updateGridQuery(this.form.get('value'));

                // グリッドの詳細ボタンクリック時の動作を設定する
                // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
                this.trafficRegulationGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                    // helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
                    this.showDetailPage(evt.item);
                }));
            },

            /**
             * 検索ボタンが押されたときに呼び出される。
             * テンプレートHTML内のFormウィジェットに対するdata-dojo-attach-eventでこのイベントを紐付けている。
             * また、同Formウィジェットに対しdata-dojo-attach-pointを指定し、this.formからアクセス出来るようにしている。
             */
            onSubmit: function() {

                try {
                    if (this.form.validate()) {
                        // 入力値が正常ならグリッドの検索条件を更新
                        this.updateGridQuery(this.form.get('value'));
                    }
                } catch (e) {
                    console.error(e);
                } finally {
                    return false;
                }
            },

            /**
             * グリッドの検索条件を指定された値で更新する。
             * @param {Object} value name属性と値のマッピング
             */
            updateGridQuery: function(value) {
                console.debug('updateGridQuery');
                // 入力値を元にグリッド用フィルターを作成
                var filter = new this.store.Filter();

                // 規制要因CD
                if (value.regReasonCd) {
                    filter = filter.eq('regReasonCd', value.regReasonCd);
                }

                // 振興局
                if (value.regionCd) {
                    filter = filter.eq('regionCd', value.regionCd);
                }

                // 規制開始日時from
                if (value.regStartTimestampFrom) {
                    // 日付をlong値にフォーマットしてからフィルターにセットする
                    var regStartTimestampFromLong = new Date(value.regStartTimestampFrom).getTime();
                    filter = filter.eq('regStartTimestampFrom', regStartTimestampFromLong);
                }

                // 規制開始日時to
                if (value.regStartTimestampTo) {
                    // 日付をlong値にフォーマットしてからフィルターにセットする
                    var regStartTimestampToLong = new Date(value.regStartTimestampTo).getTime();
                    filter = filter.eq('regStartTimestampTo', regStartTimestampToLong);
                }

                // 規制終了（予定）日時FROM
                if (value.regEndTimestampFrom) {
                    // 日付をlong値にフォーマットしてからフィルターにセットする
                    var regEndTimestampFromLong = new Date(value.regEndTimestampFrom).getTime();
                    filter = filter.eq('regEndTimestampFrom', regEndTimestampFromLong);
                }

                // 規制終了（予定）日時TO
                if (value.regEndTimestampTo) {
                    // 日付をlong値にフォーマットしてからフィルターにセットする
                    var regEndTimestampToLong = new Date(value.regEndTimestampTo).getTime();
                    filter = filter.eq('regEndTimestampTo', regEndTimestampToLong);
                }

                // 最新フラグ
                // チェックボックスの配列の長さで判定する
                // チェックされている場合（配列の長さが0ではない）は
                // 最新と過去報両方表示するためフィルターをセットしない
                // チェックされていない場合（配列の長さが0）は
                // 最新のみを表示するためフィルターをセットする
                if (value.activeFlg.length === 0) {
                    filter = filter.eq('activeFlg', '1');
                }

                // 災害ID
                if (value.disasterId) {
                    filter = filter.eq('disasterId', value.disasterId);
                }

                // 規制終了情報表示
                if (value.regEndFlg.length === 0) {
                    filter = filter.eq('regEndFlg', '0');
                } else {
                    filter = filter.eq('regEndFlg', '1');
                }

                // 公開状況
                if (value.releaseFlg) {
                    filter = filter.eq('releaseFlg', value.releaseFlg);
                }

                // 削除フラグ
                if (value.deleteFlg.length === 0) {
                    filter = filter.eq('deleteFlg', '0');
                }

                // filterに対応するcollectionを取得
                var collection = this.store.filter(filter);
                // collectionをグリッドにセットする（サーバーにリクエストされる）
                this.trafficRegulationGrid.set('collection', collection);
            },

            /**
             * 新規登録ボタンが押されたときに呼び出される。
             * 新規登録画面に遷移する。
             */
            showRegisterPage: function() {
                // 検索条件の保管
                this.setFilterStore();
                Router.moveTo('traffic/register');
            },

            /**
             * 一括公開が押されたときに呼び出される。
             * 選択された通行規制情報を公開する。
             */
            bulkRelease: function() {
                // 検索条件の保管
                this.setFilterStore();

                // 選択状態を取得
                var selection = this.trafficRegulationGrid.selection;

                // 選択されたものを格納する配列
                var array = [];

                // 選択状態がtrueのものを取り出して配列に追加
                var keys = Object.keys(selection);
                for (var i = 0, len = keys.length; i < len; i++) {
                    if (selection[keys[i]]) {
                        array.push(keys[i]);
                    }
                }

                // 選択されていない場合はエラーダイアログを表示
                if (array.length === 0) {
                    this.chain.info('一括公開対象が選択されていません。', 'エラー');
                    return false;
                }

                var trafficRegulationId = array.reverse().join(',');

                console.debug(trafficRegulationId);

                // 確認ダイアログを表示
                this.chain.confirm('選択された通行規制情報を一括で公開します。<br>' +
                    'よろしいですか？', function(chain) {
                    console.debug('通行規制情報の一括公開を行います');
                    var promise = Requester.put('/api/traffic/bulk/' + trafficRegulationId);
                    // ローダーの表示
                    Loader.wait(promise).then(function(data) {
                        console.debug(data);
                        chain.infoComplete(function() {
                            // 再検索を実施
                            this.onSubmit();
                            // 確認ダイアログを閉じる
                            chain.hide();
                        });
                    }, function(error) {
                        console.debug(error);
                        chain.infoError(error);
                        chain.info('一括公開に失敗しました。', 'エラー', function() {
                            // 確認ダイアログを閉じる
                            chain.hide();
                        });
                    });
                });

            },

            /**
             * 詳細ボタンが押されたときに呼び出される。
             * 詳細画面に遷移する。
             */
            showDetailPage: function(object) {
                // 検索条件の保管
                this.setFilterStore();
                Router.moveTo('traffic/detail', {
                    id: object.trafficRegulationId
                });
            },

            /**
             * 帳票出力対象選択ボタンが押されたときに呼び出される。
             * 帳票出力対象選択画面に遷移する。
             */
            showSelectorPage: function() {
                Router.moveTo('traffic/selector');
            },

            // DOMノードを生成するためのメソッド
            buildRendering: function() {
                this.inherited(arguments);
                // 自分が削除された時は関連ダイアログも削除する
                // （ダイアログは初期化時にbody直下へ移動するため、明示的に指定しないと消えずに残ってしまう）
                // this.own(this.dialog);
                // this.own(this.detailDialog);

            },

            // HTML上にウィジェットが設置されてから呼ばれる
            startup: function() {
                this.inherited(arguments);

                // 管理者は削除済みを検索可能とする
                if (UserInfo.getAdminFlg()) {
                    this.deleteFlgArea.style.display = '';
                } else {
                    this.deleteFlgArea.style.display = 'none';
                }

                // グリッドを初期化する
                this.initTrafficRegulationGrid();

            },

            // 検索条件を保管する
            setFilterStore: function() {
                // 初期化する
                trafficFilterStore = {};
                var value = this.form.get('value');
                // 規制要因
                if (value.regReasonCd) {
                    trafficFilterStore.regReasonCd = value.regReasonCd;
                }
                // 振興局
                if (value.regionCd) {
                    trafficFilterStore.regionCd = value.regionCd;
                }
                // 規制開始日時FROM
                if (value.regStartTimestampFrom) {
                    trafficFilterStore.regStartTimestampFrom = value.regStartTimestampFrom;
                }
                // 規制開始日時TO
                if (value.regStartTimestampTo) {
                    trafficFilterStore.regStartTimestampTo = value.regStartTimestampTo;
                }
                // 規制終了（予定）日時FROM
                if (value.regEndTimestampFrom) {
                    trafficFilterStore.regEndTimestampFrom = value.regEndTimestampFrom;
                }
                // 規制終了（予定）日時TO
                if (value.regEndTimestampTo) {
                    trafficFilterStore.regEndTimestampTo = value.regEndTimestampTo;
                }
                // 過去報表示フラグ
                if (value.activeFlg.length > 0) {
                    trafficFilterStore.activeFlg = 'checked';
                }
                // 災害ID
                if (value.disasterId) {
                    trafficFilterStore.disasterId = value.disasterId;
                }
                // 規制終了情報表示フラグ
                if (value.regEndFlg.length > 0) {
                    trafficFilterStore.regEndFlg = 'checked';
                }
                // 公開状況
                if (value.releaseFlg) {
                    trafficFilterStore.releaseFlg = value.releaseFlg;
                }
                // 削除された情報を表示
                if (value.deleteFlg.length > 0) {
                    trafficFilterStore.deleteFlg = 'checked';
                }
            },

            showFormDialog: function() {
                console.debug('[帳票出力]ボタンがクリックされました');
                this.formDialog.show();
          	},

            // 保管した検索条件をformにセットする
            setFilterData: function() {
                // 保管されていれば値をセット
                if (trafficFilterStore.regReasonCd) {
                    this.regReasonCd.set('value', trafficFilterStore.regReasonCd);
                }
                if (trafficFilterStore.regionCd) {
                    this.regionCd.set('value', trafficFilterStore.regionCd);
                }
                if (trafficFilterStore.regStartTimestampFrom) {
                    this.regStartTimestampFrom.set('value', trafficFilterStore.regStartTimestampFrom);
                }
                if (trafficFilterStore.regStartTimestampTo) {
                    this.regStartTimestampTo.set('value', trafficFilterStore.regStartTimestampTo);
                }
                if (trafficFilterStore.regEndTimestampFrom) {
                    this.regEndTimestampFrom.set('value', trafficFilterStore.regEndTimestampFrom);
                }
                if (trafficFilterStore.regEndTimestampTo) {
                    this.regEndTimestampTo.set('value', trafficFilterStore.regEndTimestampTo);
                }
                if (trafficFilterStore.activeFlg) {
                    this.activeFlg.set('checked', 'checked');
                }
                if (trafficFilterStore.disasterId) {
                    this.disasterId.set('value', trafficFilterStore.disasterId);
                }
                if (trafficFilterStore.regEndFlg) {
                    this.regEndFlg.set('checked', 'checked');
                }
                if (trafficFilterStore.releaseFlg) {
                    this.form.set('value', { releaseFlg: trafficFilterStore.releaseFlg });
                }
                if (trafficFilterStore.deleteFlg) {
                    this.deleteFlg.set('checked', 'checked');
                }
            }

        });
});
