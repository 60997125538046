/**
 * 道路交通規制情報取込情報一覧画面
 * @module app/traffic/import/TrafficImportInfoListPage
 */
define(['module',
    'dojo/Deferred',
    'dojo/dom-attr',
    'dstore/Memory',
    'dojo/query',
    'dojo/text!./templates/TrafficImportInfoListPage.html',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojox/xml/parser',
    'idis/control/Router',
    'idis/service/Requester',
    'idis/view/page/_PageBase',
    // 以下、変数から参照されないモジュール
    './TrafficImportInfoListGrid'
], function(module,
    Deferred, domAttr, Memory, query, template, array, declare, lang, xml,
    Router, Requester, _PageBase) {
    /**
     * 道路交通規制情報取込情報一覧画面
     * @class TrafficImportInfoListPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/traffic/importTrafficImportInfoListPage~TrafficImportInfoListPage# */
    {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--trafficImport',

        store: null,

        constructor: function() {
            // データを格納する
            this.store = new Memory({
                idProperty: 'id'
            });
        },

        /**
         * DOM構築後に呼ばれる
         */
        postCreate: function() {
            // 道路規制情報XMLを読み込む
            Requester.get('/api/traffic/import').then(lang.hitch(this, function(items){
                // グリッド用のstoreにデータを追加
                array.forEach(items, function(item){
                    this.store.addSync(item);
                }, this);
                this.updateGridQuery();
            }));

            // グリッドの取込ボタンクリック時の動作を設定する
            this.grid.on('registerDamageReportButtonClick', lang.hitch(this, function(evt) {
                this.onRegisterDamageReportClick(evt.item);
            }));
        },

        /**
         * グリッドの更新
         * @param kind 規制種別
         */
        updateGridQuery: function(kind){
            var filter = new this.store.Filter();
            if(kind){
                filter = filter.eq('kind', kind);
            }
            this.grid.set('collection', this.store.filter(filter));
        },

        /**
         * 規制種別変更イベント
         */
        onChangeKind: function(value){
            // グリッド更新
            this.updateGridQuery(value);
        },

        /**
         * グリッドの取込ボタンクリック時の動作
         * @param data 行データ
         */
        onRegisterDamageReportClick: function(data){
            // 新規登録画面へ遷移
            Router.moveTo('report/register', data);
        },

        /**
         * 道路交通規制情報を取得
         *
         * XMLファイルを読み込み、中のタグ要素を読み取るメソッド。
         * 現在は使っていないが、今後別の用途で流用する可能性もあるので、コードを残しておく。
         *
         */
        xmlRead: function(){
            var deferred = new Deferred();
            var xhr = new XMLHttpRequest();
            xhr.open('GET', this.XML_PATH, true);
            xhr.responseType = 'arraybuffer';
            var store = this.store;
            xhr.onload = function() {
                var blob = new Blob([this.response], {type: 'text/xml'});
                var reader = new FileReader();
                reader.onload = function(){
                    var content = xml.parse(reader.result);
                    query('regulations', content).forEach(function(node) {
                        query('regulation', node).forEach(function(cell) {
                            var data = {};
                            data.id = domAttr.get(cell, 'id');
                            data.category = domAttr.get(cell, 'category');
                            data.road = domAttr.get(cell, 'road');
                            data.roadDivision = domAttr.get(cell, 'road_division');
                            data.roadName = domAttr.get(cell, 'road_name');
                            data.lat = domAttr.get(cell, 'lat');
                            data.lng = domAttr.get(cell, 'lng');
                            data.kind = domAttr.get(cell, 'kind');
                            data.kindName = domAttr.get(cell, 'kind_name');
                            data.addressStart = domAttr.get(cell, 'address_start');
                            data.addressEnd = domAttr.get(cell, 'address_end');
                            data.reason = domAttr.get(cell, 'reason');
                            data.dateStart = domAttr.get(cell, 'date_start');
                            data.dateEnd = domAttr.get(cell, 'date_end');
                            data.timeStart = domAttr.get(cell, 'time_start');
                            data.timeEnd = domAttr.get(cell, 'time_end');
                            data.management = domAttr.get(cell, 'management');
                            data.note = domAttr.get(cell, 'note');
                            data.area = domAttr.get(cell, 'area');
                            store.addSync(data);
                        });
                        deferred.resolve();
                    });
                };
                reader.readAsText(blob);
            };
            xhr.send();
            return deferred.promise;
        }
    });
});