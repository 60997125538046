/**
 * 係新規登録ダイアログ用モジュール。　
 * @module app/organization/UnitRegisterDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/text!./templates/UnitRegisterDialog.html',
    './_UnitDialogBase',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    'app/config',
    'app/model/Region',
    // 以下、変数で受けないモジュール
    'app/view/form/MunicipalitySelector',
    'app/view/form/RegionSelector',
    'app/view/form/MunicRegionOrganizationSelector',
    'dijit/form/ValidationTextBox',
    'idis/view/form/WordCountTextarea'
], function(module, declare, lang, domStyle, template, _UnitDialogBase, UserInfo, USER_TYPE, config, Region) {
    /**
     * 係新規登録ダイアログ
     * @class UnitRegisterDialog
     * @extends module:app/organization/_UnitDialogBase~_UnitDialogBase
     */
    return declare(module.id.replace(/\//g, '.'), _UnitDialogBase,
    /** @lends module:app/view/page/UnitRegisterDialog~UnitRegisterDialog# */ {
        // テンプレート文字列
        templateString: template,

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * 係を新規登録する。
         */
        onSubmit: function() {
            try {
                console.debug('係新規登録ダイアログの[登録]ボタンがクリックされました。');

                // フォームのバリデーションを実施
                if (!this.form.validate()) {
                    return false;
                }

                // 固有のバリデーションを実施
                if(!this.validate()) {
                    return false;
                }

                var value = this.form.get('value');
                // TODO 以下のログ書き出しを消去し、コメントアウトを外す

                this.emit('register', {value: value});
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        // 選択したコードがregionCdなのかmunicipalityCdなのか確かめる。
        // regionCdの場合はtrueが帰る
        isRegion: function(cd){
            return Region.store.get(cd).then(lang.hitch(this, function(region){
                return region ? true : false;
            }));
        },

        /**
         * ダイアログを初期化する。（新規登録時）
         */
        initDialog: function() {
            this.reset();
            // セレクタのリフレッシュ処理を動かすためフラグをtrueにセット
            this.organizationSelector._municipalityCdChanged = true;
            this.organizationSelector.restoreRefresh();

            // ユーザー種別を判定する
            switch (UserInfo.getUserType()) {
            case USER_TYPE.PREFECTURE:
                this.municipalityCd.set('value', config.municInfo.prefCd);
                break;
            case USER_TYPE.REGION:
                this.municipalityCd.set('value', UserInfo.getRegionCd());
                domStyle.set(this.municipalityCd.clearButton.domNode, 'display', 'none');
                break;
            case USER_TYPE.MUNICIPALITY:
                if (UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd){
                    //　市町村ユーザの場合のみ、自治体選択ボタンを非活性にする
                    this.municipalityCd.set('noAllButton', true);
                }
                this.municipalityCd.set('value', UserInfo.getMunicipalityCd());
                break;
            case  USER_TYPE.OTHER_ORGAN:
                this.municipalityCd.set('value', config.municInfo.prefCd);
                break;
            }
        },

        // 市町村セレクタの値が変更するときの動作
        onMunicipalityCdChanged: function(municipalityCd) {
            this.setButtonStatus();
            this.isRegion(municipalityCd.value).then(lang.hitch(this, function(isRegion){
                if(isRegion){
                    this.organizationSelector.setRegionCd(municipalityCd.value);
                    this.organizationSelector.setMunicipalityCd(null);
                } else if(municipalityCd.value && municipalityCd.value !== config.municInfo.prefCd){
                    this.organizationSelector.setMunicipalityCd(municipalityCd.value);
                    this.organizationSelectorForUnit.setRegionCd(null);
                } else if (municipalityCd.value && municipalityCd.value === config.municInfo.prefCd) {
                    this.organizationSelector.setMunicipalityCd(config.municInfo.prefMunicCd);
                    this.organizationSelector.setRegionCd(null);
                }
            }));
        },

        // 組織セレクタの活性・非活性判定
        setButtonStatus: function () {
            if (this.municipalityCd.value === '') {
                this.organizationSelector.set('disabled', true);
                this.organizationSelector.set('value', '');
            } else {
                this.organizationSelector.set('disabled', false);
            }
        }

        // 振興局セレクタの値が変更するときの動作
        /*onRegionCdChanged: function(regionCd) {
            this.organizationSelector.setRegionCd(regionCd.value);
        }*/
    });
});
