/**
 * 安否確認 職員詳細ダイアログ用モジュール。
 * @module app/safety/employee/SafetyEmployeeDetailDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/text!./templates/SafetyEmployeeDetailDialog.html',
    'dojox/validate/web',
    'app/safety/auth/model/SafetyUserInfo',
    'idis/view/page/_PageBase',
    'idis/view/dialog/InfoDialog',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/Textarea',
    'dijit/form/TextBox',
    'dijit/form/CheckBox',
    'idis/view/form/Button',
    'app/safety/auth/view/form/AclButton',
    'app/view/form/ItemSelector',
    'app/view/form/EmployeeDivisionSelector'
], function(module, declare, lang, domStyle, template, validate, UserInfo, _PageBase, InfoDialog) {
    /**
     * 職員詳細ダイアログ
     * @class SafetyEmployeeDetailDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
                   /** @lends module:app/view/page/DamageReportPage~DamageReportPage# */ {
        // テンプレート文字列
        templateString: template,

        baseClass: 'idis-Page--safety-dialog',

        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * ページ初期化
         */
        initPage: function(item) {
            this.item = item;
            // 最初に全てのボタンを活性化する
            this.name.set('disabled', false);
            this.nameKana.set('disabled', false);
            this.password.set('disabled', false);
            this.confirmPassword.set('disabled', false);
            this.address.set('disabled', false);
            this.employeeCd.set('disabled', false);
            this.jobName.set('disabled', false);
            this.roleType.set('disabled', false);
            this.divisionCd.set('disabled', false);
            this.chargeName.set('disabled', false);
            this.divisionCd02.set('disabled', false);
            this.chargeName02.set('disabled', false);
            this.divisionCd03.set('disabled', false);
            this.chargeName03.set('disabled', false);
            this.note.set('disabled', false);
            this.submitButton.set('disabled', false);
            // 職員番号は編集できない
            this.employeeCd.set('disabled', true);
            // システム管理者以外は編集できない項目
            if (!UserInfo.getAcl().CHIEF_ADMIN) {
                this.employeeCd.set('disabled', true);
                this.jobName.set('disabled', true);
                this.roleType.set('disabled', true);
                this.divisionCd.set('disabled', true);
                this.chargeName.set('disabled', true);
                this.divisionCd02.set('disabled', true);
                this.chargeName02.set('disabled', true);
                this.divisionCd03.set('disabled', true);
                this.chargeName03.set('disabled', true);
                this.note.set('disabled', true);
            }
            // 管理職員の場合、自分以外の職員は編集できない
            if (!UserInfo.getAcl().CHIEF_ADMIN && UserInfo.getId() !== item.employeeCd) {
                this.name.set('disabled', true);
                this.nameKana.set('disabled', true);
                this.password.set('disabled', true);
                this.confirmPassword.set('disabled', true);
                this.address.set('disabled', true);
                this.employeeCd.set('disabled', true);
                this.jobName.set('disabled', true);
                this.roleType.set('disabled', true);
                this.divisionCd.set('disabled', true);
                this.chargeName.set('disabled', true);
                this.divisionCd02.set('disabled', true);
                this.chargeName02.set('disabled', true);
                this.divisionCd03.set('disabled', true);
                this.chargeName03.set('disabled', true);
                this.note.set('disabled', true);
                this.submitButton.set('disabled', true);
            }
            // 本人以外はメールアドレスを編集できない
            if (UserInfo.getId() !== item.employeeCd) {
                this.emailAddress01.set('disabled', true);
                this.emailAddress02.set('disabled', true);
            } else {
                this.emailAddress01.set('disabled', false);
                this.emailAddress02.set('disabled', false);
            }
        },

        /**
         * 更新ボタンが押された際の動き。
         */
        onSubmit: function() {
            try {
                if(this.validateFormData()) {
                    var value = this.form.get('value');
                    // パスワード（確認）を削除
                    delete value.confirmPassword;
                    // 元データを入力内容で上書き
                    lang.mixin(this.item, value);
                    // 実際の登録処理はSafetyAdminPage側でupdateイベントを監視しているメソッドが実施
                    this.emit('update', {value: this.item});
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * 削除ボタンが押された際の動き。
         */
        onDeleteButton: function() {
            try {
                var value = this.form.get('value');
                // 実際の登録処理はSafetyAdminPage側でdeleteイベントを監視しているメソッドが実施
                this.emit('delete', {employeeCd: value.employeeCd});
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * 入力された値の妥当性をチェックする。
         */
        validateFormData: function() {
            var formData = this.form.get('value');

            // newEmployeeCd
            if (formData.employeeCd === '') {
                InfoDialog.show('入力チェック', '職員番号が入力されていません');
                return false;
            }

            if (!/^[0-9a-zA-Z]*$/.test(formData.employeeCd)) {
                InfoDialog.show('入力チェックエラー', '職員番号は半角英数字で入力してください');
                return false;
            }

            // emailAddress01
            // if (formData.emailAddress01 === '') {
            //     InfoDialog.show('入力チェック', 'メールアドレスが入力されていません');
            //     return false;
            // }

            if (formData.emailAddress01 &&
                !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(formData.emailAddress01)) {
                InfoDialog.show('入力チェックエラー', '不正な形式のメールアドレスです<br>' + formData.emailAddress01);
                return false;
            }

            // emailAddress02
            // if (formData.emailAddress02 && !validate.isEmailAddress(formData.emailAddress02)) {
            if (formData.emailAddress02 &&
                !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(formData.emailAddress02)) {
                InfoDialog.show('入力チェックエラー', '不正な形式のメールアドレスです<br>' + formData.emailAddress02);
                return false;
            }

            // divisionCd
            if (formData.divisionCd === '') {
                InfoDialog.show('部署が選択されていません');
                return false;
            }
            // groupName
            if (formData.groupName === '') {
                InfoDialog.show('入力チェック', 'グループ名が入力されていません');
                return false;
            }
            // employeeCd
            if (formData.employeeCd === '') {
                InfoDialog.show('入力チェック', '職員番号が入力されていません');
                return false;
            }
            // name
            if (formData.name === '') {
                InfoDialog.show('入力チェック', '氏名(漢字)が入力されていません');
                return false;
            }
            // roleType
            if (formData.roleType === '') {
                InfoDialog.show('入力チェック', '役割が選択されていません');
                return false;
            }
            // password
            if (formData.password !== formData.confirmPassword) {
                InfoDialog.show('入力チェック', '入力されたパスワードが一致しません');
                return false;
            }
            // password invalid check
            if (formData.password !== '' && !this.isValidPassword(formData.password)) {
                InfoDialog.show('入力チェック', '入力されたパスワードが規則を満たしません。半角の英数および記号で８文字以上、２０文字以内で入力ください');
                return false;
            }
            // divisionCd
            if (formData.divisionCd === '') {
                InfoDialog.show('入力チェック', '部署名が選択されていません');
                return false;
            }
            return true;
        },

        /**
         * @param password 画面から入力されたパスワード
         * 画面から入力されたパスワードが、規定に合致しているかチェックする。
         *
         */
        isValidPassword: function(password) {
            // 長さ
            if (password.length < 8 || password.length > 20) {
                return false;
            }

            // アルファベットが含まれているか
            if (/[^\x21-\x7e]/.test(password)) {
                return false;
            }

            return true;
        }
    });
});
