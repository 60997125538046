/**
 * 新規登録ダイアログ用モジュール。
 * @module app/disasterInfoShare/DisInfoShareRegisterDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/text!./templates/DisInfoShareRegisterDialog.html',
    'idis/view/dialog/InfoDialog',
    'idis/view/page/_PageBase',
    'idis/view/Loader',
    'idis/model/UserInfo',
    'idis/control/Locator',
    'idis/store/IdisRest',
    'app/model/DisasterInfo',
    'idis/service/iframe',
    'idis/util/FilesUtils',
    'dojo/dom-class',
    'dojo/dom-construct',
    'exif-js/exif',
    'dijit/Menu',
    'dijit/MenuItem',
    'dijit/popup',
    'dijit/TooltipDialog',
    'dojo/on',
    'idis/service/Requester',
    // 以下、変数から参照されないモジュール
    'dojox/form/Uploader',
    'dijit/form/ValidationTextBox',
    'dijit/form/Textarea',
    'dijit/form/TextBox',
    'dijit/form/DateTextBox',
    'dijit/form/NumberTextBox',
    'idis/view/form/AclButton',
    'idis/view/form/CheckGroup',
    'idis/view/form/DateTimeInput',
    'idis/view/form/RadioGroup',
    'dijit/TitlePane',
    'app/view/form/DisInfoShareDestinationSelector',
    'dijit/form/CheckBox'
], function(module,
    declare, lang, domStyle, template, InfoDialog, _PageBase, Loader, UserInfo, Locator, IdisRest, DisasterInfo,
    iframe, FilesUtils, domClass, domConstruct, exif, Menu, MenuItem, popup, TooltipDialog, on, Requester) {
    /**
     * 新規登録ダイアログ用
     *
     *  DisInfoShareRegisterDialog
     * @class DisInfoShareRegisterDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:disasterInfoShare/bbs/DisInfoShareRegisterDialog~DisInfoShareRegisterDialog# */
        {
            // テンプレート文字列
            templateString: template,

            attachFileList: [],

            delStore: [],

            /**
             * 本部ID
             * @private
             */
            _deptId: null,

            /**
             * constructor
             */
            constructor: function() {
                // 災害IDをDisasterInfoから取得
                this._disasterId = DisasterInfo.getDisasterId();
                if (!this._disasterId) {
                    this._disasterId = 1;
                    console.warn('災害IDが設定されていません。');
                }
            },

            /**
             * buildRendering
             */
            buildRendering: function() {
                this.inherited(arguments);
                this._deptId = Locator.getQuery().deptId;
            },

            // DOM要素構築後に呼ばれる
            postCreate: function() {
            },

            // 危機管理部の場合のみ、緊急情報の公開チェックボックスと通知種別プルダウンを表示する
            hideEmergencyNewsSetting: function() {
                this.emergencyNewsFlgSetting.hidden = true;
                this.notifyTypeSetting.hidden = true;
            },

            // 登録ボタン押下時
            onSubmit: function() {
                try {

                    var value = this.form.get('value');

                    // フォームのバリデーションを実施
                    if (!this.form.validate()) {
                        return false;
                    }

                    // 掲載対象が選択中の災害の場合、災害IDをセット
                    if(value.allDisasterFlg==='0'){
                        value.disasterId = this._disasterId;
                    }
                    delete value.allDisasterFlg;

                    // 新着情報で公開するフラグ
                    if (value.newInformationFlg && value.newInformationFlg.length !== 0) {
                        value.newInformationFlg = '1';
                    } else {
                        value.newInformationFlg = '0';
                    }

                    // 緊急情報で公開するフラグ
                    if (value.emergencyNewsFlg && value.emergencyNewsFlg.length !== 0) {
                        value.emergencyNewsFlg = '1';
                    } else {
                        value.emergencyNewsFlg = '0';
                    }

                    if(this.attachFileList.length > 0) {
                        var attachFileIds = [];
                        for(var i=0; i<this.attachFileList.length; i++) {
                            attachFileIds.push(this.attachFileList[i].messageAtcFileId);
                        }
                        value.attachFileIds = attachFileIds.join(',');
                    } else {
                        value.attachFileIds = '';
                    }
                    delete value.attachFile;

                    // FIXME 登録者が特定物資拠点・避難所のケースは一旦考えない。

                    // value.sender = UserInfo.getPublicOrgCd();
                    console.log(UserInfo);
                    console.log(this);
                    console.log(UserInfo._userInfo.userId);

                    console.log(Requester);

                    // Requester.get('/api/users/org/?userId=' + UserInfo._userInfo.userId).then(
                    //     lang.hitch(this, function(data) {
                    //     console.log(data);
                    //     value.sender = data.organizationName;
                    // }));

                    // Requester.get('/api/users/org/').then(
                    //     lang.hitch(this, function(data) {
                    //     console.log(data);
                    //     value.sender = data.organizationName;
                    // }));


                    // ログインユーザの組織コードを取得する
                    // value.sender = (UserInfo.getOrganization().unitCd ? 'U' + UserInfo.getOrganization().unitCd :
                    // UserInfo.getOrganization().sectCd ? 'S' + UserInfo.getOrganization().sectCd :
                    // UserInfo.getOrganization().deptCd ? 'D' + UserInfo.getOrganization().deptCd : '');

                    value.sender = UserInfo._userInfo.userId;
                    value.senderOrganizationCd = this._deptId;
                    // value.sender = UserInfo.getSelectedMunicipalityCd();
                    // value.sender = UserInfo.organizationName;
                    // value.sender = this.destinationId.get('value');
                    this.emit('send', {
                        value: value
                    });

                } catch (e) {
                    console.error(e);
                }
            },

            /**
             * 添付ファイルをアップロードする。
             */
            loadAttachFile: function() {
                // ファイルが空の場合は処理を中断＆不正なファイルの場合、メッセージ表示して処理を中断
                if (this.attachFile._files.length === 0 ||
                    !FilesUtils.isAttachFile(this.attachFile)) {
                    return;
                }

                console.log('file change');
                var self = this;

                this.attachFile.set('disabled', false);

                // ファイルがonloadされたときにサーバーに一時保存する
                var promise = iframe.post('/api/bbses/uploadFile/message', {
                    form: this.form.id,
                    handleAs: 'json'
                }).then(function(data) {
                    console.log(data);
                    // uploaderをリセット
                    self.attachFile.reset();
                    self.attachFileList.push(data);
                    self.showPreview(data, true);
                }, function(error) {
                    console.log(error);
                    // uploaderをリセット
                    self.attachFile.reset();
                    self.chain.infoError(error);
                });
                // ローダーの表示
                Loader.wait(promise);
            },

            /**
             * 添付ファイルのプレビューを表示する。
             */
            showPreview: function(data, exifFlg) {

                var dataUri = data.attachFilePath.replace('out/', 'data/');
                var fileName = data.attachFileName;
                var fileId = data.facilityAtcFileId;
                var self = this;

                console.log(data);

                console.log(exifFlg);


                // 画像ファイルの場合
                if (fileName.indexOf('.jpg') !== -1 || fileName.indexOf('.jpeg') !== -1 ||
                    fileName.indexOf('.png') !== -1 || fileName.indexOf('.JPG') !== -1 ||
                    fileName.indexOf('.JPEG') !== -1 || fileName.indexOf('.PNG') !== -1 ||
                    fileName.indexOf('.gif') !== -1) {
                    var image = new Image();

                    // JPEGファイルの場合、EXIFデータの取得を実行する
                    if (fileName.indexOf('.jpg') !== -1 || fileName.indexOf('.jpeg') !== -1 ||
                        fileName.indexOf('.JPG') !== -1 || fileName.indexOf('.JPEG') !== -1) {
                        var img = null;
                        this.own(on(image, 'load', lang.hitch(this, function(e) {
                            // console.log(e);
                            img = e.target;

                            if (exifFlg) {
                                this.getExifData(img, this);
                            }
                        })));
                    }
                    image.src = dataUri;
                    domClass.add(image, 'is-showPreview');
                    domConstruct.place(image, this.preview);
                    // console.log(image);
                    // console.log(dataUri);
                    // console.log(fileName);
                    // console.log(fileId);

                    // メニューの作成
                    this.createMenu(image, dataUri, fileName, fileId, self);

                } else if (fileName.indexOf('.xls') !== -1 || fileName.indexOf('.xlsx') !== -1) {
                    var excel = new Image();
                    excel.src = 'images/excelicon.png';
                    domClass.add(excel, 'is-showPreview');
                    domConstruct.place(excel, this.preview);
                    // メニューの作成
                    this.createMenu(excel, dataUri, fileName, fileId, self);
                } else if (fileName.indexOf('.pdf') !== -1) {
                    var pdf = new Image();
                    pdf.src = 'images/pdficon.png';
                    domClass.add(pdf, 'is-showPreview');
                    domConstruct.place(pdf, this.preview);
                    // メニューの作成
                    this.createMenu(pdf, dataUri, fileName, fileId, self);
                } else if (fileName.indexOf('.doc') !== -1 || fileName.indexOf('.docx') !== -1) {
                    var word = new Image();
                    word.src = 'images/wordicon.png';
                    domClass.add(word, 'is-showPreview');
                    domConstruct.place(word, this.preview);
                    // メニューの作成
                    this.createMenu(word, dataUri, fileName, fileId, self);
                } else {
                    var other = new Image();
                    other.src = 'images/othericon.png';
                    domClass.add(other, 'is-showPreview');
                    domConstruct.place(other, this.preview);
                    // メニューの作成
                    this.createMenu(other, dataUri, fileName, fileId, self);
                }
            },

            /**
             * JPEGファイルの位置情報を取得する
             */
            getExifData: function(img, self) {
                console.log('getting exif data start');
                exif.getData(img, function() {

                    var latitude = exif.getTag(this, 'GPSLatitude');
                    var longitude = exif.getTag(this, 'GPSLongitude');

                    if (typeof latitude === 'undefined' || typeof longitude === 'undefined') {
                        console.log('GPS data is unavailable.');
                    } else {
                        console.log('GPS data is available.');
                        var f = function(number) {
                            return number[0].numerator + number[1].numerator / (60 * number[1].denominator) +
                                number[2].numerator / (3600 * number[2].denominator);
                        };
                        self.chain.confirm('この画像の位置情報を使用しますか？', function(chain) {
                            // 位置情報を設定する
                            self.pointLat = f(latitude);
                            self.pointLng = f(longitude);
                            // 地図にマークして中心に移動する
                            self.addMark(self.pointLat, self.pointLng, self);
                            self.map.setView([self.pointLat, self.pointLng], 11);
                            // ダイアログを閉じる
                            chain.hide();
                        });
                    }
                });
            },

            /**
             * 添付ファイルのサムネイル上にメニューを作る
             */
            createMenu: function(newNode, uri, fileName, id, self) {
                var menu = new Menu({
                    targetNodeId: newNode
                });
                menu.set('style', {
                    'border': 'none',
                    'box-shadow': 'none'
                });

                // ダウンロード操作用
                var download = null;
                var userAgent = window.navigator.userAgent.toLowerCase();
                if (userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)) {
                    var url = location.protocol + '//' + location.hostname + ':' + location.port + '/' + uri;
                    // IEの場合、download属性が効かないため、右クリック保存などaタグ機能を無効化.
                    // ダウンロード関数により元ファイル名でのダウンロードを可能にする.
                    download = domConstruct.create('a', {
                        href: '#'
                    });
                    // クリックでファイル取得処理に入る
                    download.onclick = function() {
                        self.downloadFile(url, fileName);
                    };
                } else {
                    console.log(location);

                    // FF, Chromeの場合、download属性でファイルダウンロード
                    download = domConstruct.create('a', {
                        // href: uri,
                        href: location.protocol + '//' + location.hostname + ':' + location.port + '/' + uri,
                        download: fileName
                    });
                }

                // ファイル名とメニューとの境界線をセット
                var contentNode = domConstruct.create('div');
                contentNode.innerHTML = fileName;
                domConstruct.place('<hr color=#b0c4de>', contentNode);
                // メニューをセット
                domConstruct.place(menu.domNode, contentNode);
                var tooltip = new TooltipDialog({
                    content: contentNode
                });
                //
                tooltip.containerNode.onmouseleave = function() {
                    popup.close(tooltip);
                };

                // 画像ファイルの場合のみ'開く'をメニューに追加する
                if (fileName.indexOf('.jpg') !== -1 || fileName.indexOf('.jpeg') !== -1 ||
                    fileName.indexOf('.png') !== -1 || fileName.indexOf('.JPG') !== -1 ||
                    fileName.indexOf('.JPEG') !== -1 || fileName.indexOf('.PNG') !== -1 ||
                    fileName.indexOf('.gif') !== -1) {
                    menu.addChild(new MenuItem({
                        label: '開く',
                        iconClass: 'dijitEditorIcon dijitEditorIconInsertImage',
                        onClick: function() {
                            console.log('file open');
                            window.open(uri);
                        }
                    }));
                }

                menu.addChild(new MenuItem({
                    label: 'ダウンロード',
                    iconClass: 'dijitIconSave',
                    onClick: function(e) {
                        console.log('file download');
                        console.log(e);
                        // IE対策
                        if (userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)) {
                            download.onclick();
                        } else {
                            download.click();
                        }
                    }
                }));

                menu.addChild(new MenuItem({
                    label: '削除',
                    iconClass: 'dijitIconDelete',
                    onClick: function() {
                        console.log('file delete');

                        // 該当ファイルを削除
                        for (var i = 0; i < self.attachFileList.length; i++) {
                            if (self.attachFileList[i].facilityAtcFileId === id) {
                                self.attachFileList.splice(i, 1); // id:3の要素を削除
                            }
                        }

                        // サムネイルとメニューを削除
                        domConstruct.destroy(newNode);
                        popup.close(tooltip);
                    }
                }));

                menu.startup();
                // メニュー表示処理
                this.own(on(newNode, 'mouseover', lang.hitch(this, function() {
                    popup.open({
                        popup: tooltip,
                        around: newNode,
                        orient: ['below-centered']
                    });
                })));
                // 画面破棄時に一緒に破棄する
                this.own(tooltip);
            },

            /**
             * 添付ファイルをダウンロードする。
             */
            downloadFile: function(url, name) {
                // Dojoのrequestor(dojo/request/xhr)のresponseType（handleAsプロパティ）で、
                // バイナリ（arraybuffer, blob）が利用できなかったためネイティブJSを利用している.
                var xhr = new XMLHttpRequest();
                xhr.open('GET', url, true);
                xhr.responseType = 'arraybuffer';
                xhr.onload = function() {

                    var arrayBuffer = this.response;

                    var blob = new Blob([arrayBuffer], {
                        type: 'application/octet-stream'
                    });

                    // IE10+
                    if (window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob, name);
                    }

                };
                xhr.send();

                return false;

            },

            /**
             * プレビューをクリアする
             */
            clearPreview: function() {

                var length = this.preview.childNodes.length;
                for (var i = 0; i < length; i++) {
                    domConstruct.destroy(this.preview.childNodes[0]);
                }

                this.attachFileList.length = 0;

                //if (this._attachFileList) {
                //    this._attachFileList.splice(0, this._attachFileList.length);
                //}
            },
            /**
             * ダイアログを初期化する。（新規登録時）
             */
            initDialog: function() {
                this.form.reset();
            },

            /**
             * アップロードファイルを削除(未登録時)
             */
            cancel: function() {
            	if(this.attachFileList.length > 0) {
            		var param = '';
            		var firstFlg = true;
            		for(var i = 0; i < this.attachFileList.length; i++) {
            			if(firstFlg) {
            				param += '?';
            				firstFlg = false;
            			} else {
            				param += '&';
            			}
            			param += 'array[]=' + this.attachFileList[i].messageAtcFileId;
            		}
            		console.log('param' + param);

            		// データ削除用オブジェクト
            		this.delStore = new IdisRest({
            			target: '/api/bbses/deleteAtcFile'
            		});
            		// 削除処理
            		Loader.wait(this.delStore.remove(param)).then(function() {},
            		    function(err) {
            		        console.log(err);
            		});
            	}
            	// ダイアログを閉じる
            	this.leave();
            }
        });
});
