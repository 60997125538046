/**
 * 火山情報画面用モジュール。
 * @module app/volcano/view/VolcanoPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/control/Router',
    'dojo/text!./templates/VolcanoPage.html',
    'idis/store/IdisRest',
    'idis/view/page/_PageBase',
    'idis/service/Requester',
    'dojo/_base/array',
    // './VolcanoDetailContent',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/TextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'app/common/view/StatementDialog',
    './VolcanoGrid',
    'dijit/form/Select'
], function(module, declare, lang, Router, template, IdisRest, _PageBase, Requester, array) {
    /**
     * 検索条件格納用オブジェクト
     * @type {Object}
     */
    var filterStore = {};

    /**
     * 火山情報画面
     * @class VolcanoPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/volcano/view/VolcanoPage~VolcanoPage# */ {
            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--volcano',

            /**
             * データ格納用オブジェクト
             * @type {module:dstore/Store}
             */
            store: null,
            constructor: function() {
                // データ格納用オブジェクト
                this.inherited(arguments);
                this.volcanoCommonId = 1;
                this.store = new IdisRest({
                    idProperty: 'volcanoCommonId',
                    target: '/api/volcanos?volcanoCommonId=' + this.volcanoCommonId
                });
            },

            // DOMノードを生成するためのメソッド
            buildRendering: function() {
                this.inherited(arguments);
                // 自分が削除された時は関連ダイアログも削除する
                this.own(this.statementDialog);
                // 検索時刻を初期化する
                this.initTime();
                // 情報種別セレクタを初期化する
                this.generateOptions();
                //フィルタのデータをセットする。(データの取得と非同期処理が必要)
                this.setFilterData();
                // グリッドを初期化する
                this.initGrid();
            },

            //pulldownの内容を生成して入れる
            generateOptions: function() {
                var select = this.XMLtypeSelect;
                var optionText = ['全件表示', '噴火・降灰速報', '噴火警報・予報', '降灰予報'];

                array.forEach(optionText, function(item) {
                    var option = document.createElement('option');
                    option.text = item;
                    option.value = item;
                    select.addOption(option);
                });
            },

            /**
             * グリッドを初期化する。
             */
            initGrid: function() {
                // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
                // グリッドの詳細ボタンクリック時の動作を設定する
                this.volcanoGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                    // helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
                    this.showDetailPage(evt.item);
                }));

                // 初期セットの値で検索
                this.updateGridQuery(this.form.get('value'));
            },

            /**
             * グリッドの検索条件を指定された値で更新する。
             * @param {Object} value name属性と値のマッピング
             */
            updateGridQuery: function(value) {

                // 入力値を元にグリッド用フィルターを作成
                var filter = new this.store.Filter();

                if (value.reportDatetimeFrom) {
                    var reportDatetimeFrom = new Date(value.reportDatetimeFrom).getTime();
                    filter = filter.eq('reportDatetimeFrom', reportDatetimeFrom);
                }
                if (value.reportDatetimeTo) {
                    var reportDatetimeTo = new Date(value.reportDatetimeTo).getTime();
                    filter = filter.eq('reportDatetimeTo', reportDatetimeTo);
                }
                switch (value.XMLtypeSelect) {
                    case '降灰予報': 
                        filter = filter.eq('infoType', 'ash');
                        break;

                    case '噴火警報・予報':
                        filter = filter.eq('infoType', 'vol');
                        break;

                    case '噴火・降灰速報':
                        filter = filter.eq('infoType', 'breaking');
                        break;    

                    case '全件表示':
                        filter = filter.eq('infoType', '');
                        break;     
                    
                    default:
                        break;
                }

                // filterに対応するcollectionを取得
                var collection = this.store.filter(filter);

                // collectionをグリッドにセットする（サーバーにリクエストされる）
                this.volcanoGrid.set('collection', collection);
            },

            onSubmit: function() {
                try {
                    if (this.form.isValid()) {
                        // 入力値が正常ならグリッドの検索条件を更新
                        this.updateGridQuery(this.form.get('value'));
                    }
                } catch (e) {
                    console.error(e);
                } finally {
                    return false;
                }
            },

            /* 
             * 詳細ボタンが押されたときに呼び出される。
             * 噴火警報詳細画面に遷移する。
            */
            showDetailPage: function(object) {
                // 検索条件の保管
                this.setFilterStore();
                // object.volcanoEventIdがnull/undefined/0だったら対象外
                if (object.volcanoEventId && object.volcanoEventId > 0) {
                    Router.moveTo('volcano/detail', {
                        volcanoEventId: object.volcanoEventId
                    });
                } else if (object.ashForecastId && object.ashForecastId > 0) {
                    Router.moveTo('volcano/ash', {
                        ashForecastId: object.ashForecastId
                    });
                } else if (object.ashBreakingNewsId && object.ashBreakingNewsId > 0) {
                    Router.moveTo('volcano/ashBreaking', {
                        ashBreakingNewsId: object.ashBreakingNewsId
                    });
                }
            },

            // 検索時刻の初期値をセット
            initTime: function () {
                var endDate = new Date();
                var startDate = new Date();
                startDate.setDate(startDate.getDate() - 7);
                this.reportDatetimeTo._setValueAttr(endDate);
                this.reportDatetimeFrom._setValueAttr(startDate);
            },

            /**
             * 保管した検索条件をフォームにセットする
             */
            setFilterData: function() {
                //保管されていれば値をセット
                this.form.set('value', filterStore);
            },

            /**
             * 検索条件を保管する
             */
            setFilterStore: function() {
                //初期化する
                filterStore = {};
                var value = this.form.get('value');
                filterStore = value;
            }
        });
});
