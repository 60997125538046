/**
 * <モジュールの説明>
 * @module app/view/page/<ウィジェット名>
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/text!./templates/SimulationScenarioPlayHistoryDialog.html', // テンプレート文字列
    'idis/view/page/_PageBase', // 共通基底クラス
    'idis/view/dialog/DialogChain',
    'idis/control/Router',
    'idis/store/IdisRest',
    'idis/service/Requester',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'app/simulation/SimulationScenarioPlayHistoryGrid'
], function(module, declare, lang, template, _PageBase,
    DialogChain, Router, IdisRest, Requester) {
    /**
     * <クラスの説明>
     * @class <ウィジェット名>
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/<ウィジェット名>~<ウィジェット名># */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--simulation',

        scenarioStore: null,

        historyStore: null,

        constructor: function(){
            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
        },

        startup:function(){
            this.inherited(arguments);

            this.grid.on('detailButtonClick', lang.hitch(this, function(evt) {
                this.onDetailButtonClick(evt);
            }));
            // this.initDialog();
        },

        /**
        * ダイアログを初期化する。
        */
        initDialog: function(scenarioId) {
            this.historyStore = new IdisRest({
                idProperty: 'scenarioId',
                target: '/api/simulations/history/' + scenarioId
            });
            this.grid.set('collection', this.historyStore.filter());
            this.initContent(scenarioId);
            this.container.resize();
        },

        /**
         * ダイアログ内容を初期化する。
         */
        initContent: function(scenarioId) {
            Requester.get('/api/simulations/' + scenarioId, {
                headers: {'Content-Type': 'application/json; charset=utf-8'},
                handleAs: 'json',
                preventCache : true
            }).then(lang.hitch(this, function(data) {
                this.scenarioName.innerHTML = data.scenarioName;
                this.comment.innerHTML = data.comment;
                this.crtTimestamp.innerHTML = data.crtTimestamp;
            }));
        },

        onDetailButtonClick : function(evt) {
            this.getParent().hide();
            Router.moveTo('simulation/history', {
                simulationId: evt.item.simulationId,
                status: evt.item.status
            });
        }
    });
});
