/**
 * 施設情報マスタ一覧画面
 * @module app/facilityMaster/FacilityMasterListPage
 */
define([
	'module',
	'dojo/_base/declare',
	'dojo/_base/array',
	'dojo/_base/lang',
	'dojo/text!./templates/FacilityMasterListPage.html',
	'idis/control/Router',
	'idis/model/UserInfo',
	'idis/store/IdisRest',
	'idis/view/Loader',
	'idis/view/dialog/DialogChain',
	'idis/view/page/_PageBase',
	// 以下、変数として受け取らないモジュール
	'dijit/Dialog',
	'dijit/form/Form',
	'dijit/form/Select',
	'dijit/form/Textarea',
	'dijit/form/TextBox',
	'dijit/layout/BorderContainer',
	'dijit/layout/ContentPane',
	'idis/view/form/CheckGroup',
	'idis/view/form/DateInput',
	'idis/view/form/DateTimeInput',
	'idis/view/form/TimeInput',
	'idis/view/form/AclButton',
	'idis/view/form/Button',
	'app/facilityMaster/FacilityMasterDeptSelector',
	'app/facilityMaster/FacilityMasterGrid',
	'app/facilityMaster/FacilityMasterFormDialog',
	'app/facilityMaster/FacilityMasterFileUploadDialog'
], function (module, declare, array, lang, template, Router,
	UserInfo, IdisRest, Loader, DialogChain, _PageBase) {
	/**
	 * 検索条件格納用オブジェクト
	 * @type {Object}
	 * */
	var filterStore = {};

	/**
	 * 施設情報マスタ
	 * @class FacilityMasterListPage
	 * @extends module:idis/view/page/_PageBase~_PageBase
	 */
	return declare(module.id.replace(/\//g, '.'), _PageBase,
		{
			// テンプレート文字列
			templateString: template,

			// ルート要素に付与されるCSS
			baseClass: 'idis-Page idis-Page--facility-master',

			/**
			 * データ格納用オブジェクト
			 * @type {module:dstore/Store}
			 */
			store: null,

			constructor: function () {
				// データ格納用オブジェクト
				this.store = new IdisRest({
					target: '/api/facilityMaster',
					idProperty: 'facilityId'
				});

			},
			startup: function () {
				this.inherited(arguments);
			},
			// DOMノードを生成するためのメソッド
			buildRendering: function () {
				this.inherited(arguments);

				this.deptCd.set('value', UserInfo.getDeptCd());

				// グリッドを初期化する
				this.initGrid();
				this.setFilterStore();

				if (UserInfo.getApprovalType() === "2") {
					this.deptCd.set('value', UserInfo.getDeptCd());
				}
			},
			/**
			 * グリッドを初期化する。
			 */
			initGrid: function () {
				this.updateGridQuery();
				// グリッドの詳細ボタンクリック時の動作を設定する
				// helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
				this.grid.on('detailButtonClick', lang.hitch(this, function (evt) {
					this.onDetailButtonClick(evt.item);
				}));
			},

			/**
			 * グリッドの検索条件を指定された値で更新する。
			 * @param {Object} value name属性と値のマッピング
			 */
			updateGridQuery: function () {
				// 入力値を元にグリッド用フィルターを作成
				var filter = new this.store.Filter();
				if (this.deptCd.get('value')) {
					filter = filter.eq('deptCd', this.deptCd.get('value'));
				}
				if (this.facilityName.get('value')) {
					filter = filter.eq('facilityName', this.facilityName.get('value'));
				}
				if (this.address.get('value')) {
					filter = filter.eq('address', this.address.get('value'));
				}
				// filterに対応するcollectionを取得
				var collection = this.store.filter(filter);
				// collectionをグリッドにセットする（サーバーにリクエストされる）
				this.grid.set('collection', collection);
			},

			/**
			 * 検索条件を保管する
			 */
			setFilterStore: function () {
				//初期化する
				filterStore = {};
				var value = this.form.get('value');

				// 市町村
				if (value.municipalityCd) {
					filterStore.municipalityCd = value.municipalityCd;
				}
				// 施設名
				if (value.facilityName) {
					filterStore.facilityName = value.facilityName;
				}
				// 住所
				if (value.address) {
					filterStore.address = value.address;
				}
			},

			/**
			 * 保管した検索条件をフォームにセットする
			 */
			setFilterData: function () {
				//保管されていれば値をセット
				// 市町村
				if (filterStore.municipalityCd) {
					this.municipalityCd.set('value', filterStore.municipalityCd);
				}
				// 施設名
				if (filterStore.facilityName) {
					this.facilityName.set('value', filterStore.facilityName);
				}
				// 住所
				if (filterStore.address) {
					this.address.set('value', filterStore.address);
				}
			},
			/**
			 * 検索ボタンが押されたときに呼び出される。
			 * テンプレートHTML内のFormウィジェットに対するdata-dojo-attach-eventでこのイベントを紐付けている。
			 * また、同Formウィジェットに対しdata-dojo-attach-pointを指定し、this.formからアクセス出来るようにしている。
			 */
			onSubmit: function () {
				try {
					if (this.form.validate()) {
						// 入力値が正常ならグリッドの検索条件を更新
						this.updateGridQuery(this.form.get('value'));
					}
				} catch (e) {
					console.error(e);
				}
				return false;
			},

			onRegisterButtonClick: function (evt) {
				// ブラウザーの遷移処理をキャンセル
				evt.preventDefault();
				// 施設登録画面へ遷移
				Router.moveTo('facilityMaster/register', {
					type: ''
				});
			},

			/**
			 * 詳細画面を表示
			 */
			onDetailButtonClick: function (object) {
				//検索条件の保管
				this.setFilterStore();

				// 被害状況・報告詳細画面へ遷移
				Router.moveTo('facilityMaster/detail', {
					facilityId: object.facilityId
				});
			},

			// 帳票出力ボタン押下時の挙動
			showFormDialog: function () {
				this.formDialog.show();
			},

			// ファイル読み込みボタン押下時の挙動
			showFileUploadDialog: function () {
				this.fileUploadDialog.show();
			},

		});
});

