/**
 * 再配信確認ダイアログ画面用モジュール。
 * @module app/sending/SendingReConfirmDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/text!./templates/SpecteeFavoriteDialog.html',
    'idis/view/page/_PageBase',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/Button'
], function(module, declare, template, WidgetBase) {

    var _SpecteeItem = null;
    
    /**
     * 配信確認ダイアログ
     * @class SendingConfirmDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), WidgetBase,
        /** @lends app/view/Header~Header# */ {

        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--sending',


        /**
         * コンストラクタ
         */
        constructor: function() {
            this.inherited(arguments);
        },

        /**
         * DOM生成
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * 画面生成完了
         */
        startup: function() {
            this.inherited(arguments);
            this.form.resize();
        },

        /**
         * 画面の初期表示
         */
        initDialog: function(object) {

            _SpecteeItem = object;

            // 内容
            var reportNo = '';
            if (object.reportNo) {
                reportNo = '[第' + object.reportNo + '報] ';
            }
            this.title.innerHTML = reportNo + object.title;

            // 場所
            var locationData = null;
            if (object && object.locationInfo[0]) {
                // Specteeから情報を取得する場合
                locationData = object.locationInfo[0];
            }
            if (locationData) {
                var address = '';
                address = locationData.prefecture ? address + locationData.prefecture : address;
                address = locationData.city ? address + locationData.city : address;
                address = locationData.town ? address + locationData.town : address;
            }
            this.locationInfo.innerHTML = address;

            // 情報更新日時
            this.updDate.innerHTML = object.updDate;
            this.form.reset();
            this.form.resize();
        },

        /**
         * 保存
         */
        onSubmit: function() {
            var formValue = this.form.get('value');
            this.emit('register', {value: formValue, item:_SpecteeItem});
        },

        /**
         * キャンセルボタン
         */
        onCancelButton: function() {
            // ダイアログを閉じる
            this.emit('cancel');
            this.leave();
        }
    });
});
