/**
* 定時集計 登録/詳細 画面用モジュール。
* @module app/scheduleReport/ScheduledReportMunicDetail
 */
define([
	'module',
	'dojo/_base/declare',
	'dojo/_base/lang',
	'dojo/dom-style',
	'dojo/date/locale',
	'dojo/text!./templates/ScheduledReportMunicDetailPage.html',
	'idis/view/page/_PageBase',
	'idis/control/Locator',
	'idis/control/Router',
	'idis/service/Requester',
	'idis/view/Loader',
	'idis/view/dialog/DialogChain',
	'app/config',
	'app/model/DisasterInfo',
	// 以下、変数から参照されないモジュール
	'dijit/form/Form',
	'dijit/form/Select',
	'dijit/form/Textarea',
	'idis/view/form/Button',
	'idis/view/form/RadioGroup',
	'idis/view/form/DateTimeInput'
], function (module, declare, lang, domStyle, locale, template, _PageBase,
	Locator, Router, Requester, Loader, DialogChain, config, DisasterInfo) {
	/**
	 * 定時集計 詳細 画面実装。
	 * @class ScheduledReportMunicDetail
	 * @extends module:idis/view/page/_PageBase~_PageBase
	 */
	return declare(module.id.replace(/\//g, '.'), _PageBase, {
		// テンプレート文字列
		templateString: template,
		// 定時状況報告ID
		_scheduledReportId: null,
		// 定時集計の管理番号
		_admNum: null,
		// 市町コード
		_municipalityCd: null,

		constructor: function () {
			this.chain = DialogChain.get(this);

			// URLから定時状況IDを取得
			if ('scheduledReportId' in Locator.getQuery()) {
				this._scheduledReportId = Locator.getQuery().scheduledReportId;
			}
		},

		/**
		 * DOM生成
		 */
		// DOMノードを生成するためのメソッド
		buildRendering: function () {
			this.inherited(arguments);
		},

		/**
		 * 画面生成完了
		 */
		startup: function () {
			this.inherited(arguments);
			this.initData();
		},

		initData: function () {
			var promise = Requester.get('/api/scheduledReport/' + this._scheduledReportId, {
				preventCache: false
			}).then(lang.hitch(this, function (data) {
				console.log(data);
				Object.keys(data).forEach(lang.hitch(this, function (key) {
					if (key in this) {
						this[key].innerText = data[key];
					}
				}));
				this._municipalityCd = data.municipalityCd;
				this._admNum = data.admNum;
			}), lang.hitch(this, function (err) {
				console.error(err);
			}));

			Loader.wait(promise);
		},

		/**
		 * 被害一覧を表示
		 */
		onDamageReportPageLinkClick: function (evt) {
			// ブラウザーの遷移処理をキャンセル
			evt.preventDefault();
			// 被害一覧画面へ遷移
			Router.moveTo('report');
		},

		/**
		 * 定時集計一覧を表示
		 */
		onListPageLinkClick: function (evt) {
			// ブラウザーの遷移処理をキャンセル
			evt.preventDefault();
			// 被害一覧画面へ遷移
			Router.moveTo('scheduledReport');
		},

		/**
		 * 定時集計一覧を表示
		 */
		onMunicPageLinkClick: function (evt) {
			// ブラウザーの遷移処理をキャンセル
			evt.preventDefault();
			// 被害一覧画面へ遷移
			this.leave();
		},


		/**
		 * 定時集計 被害一覧を表示
		 */
		onDamageListPageLinkClick: function (evt) {
			// 災害IDをDisasterInfoから取得
			this._disasterId = DisasterInfo.getDisasterId();
			// ブラウザーの遷移処理をキャンセル
			evt.preventDefault();
			// 被害一覧画面へ遷移
			Router.moveTo('scheduledReport/damage', {
				disasterId: DisasterInfo.getDisasterId(),
				admNum: this._admNum,
				scheduledReportId: this._scheduledReportId,
				municipalityCd: this._municipalityCd,
				isMunic: true,
			});
		},

		/**
		 * 'yyyy-MM-dd HH:mm:ss' 形式に変換
		 */
		formatDateTime: function (val) {
			if (!val) {
				return '';
			}
			var timestamp = new Date(val);
			var dateLabel = locale.format(timestamp, {
				selector: 'date',
				datePattern: 'yyyy/MM/dd'
			});
			var timeLabel = locale.format(timestamp, {
				selector: 'time',
				timePattern: 'HH:mm:ss'
			});
			return dateLabel + '&nbsp;' + timeLabel;
		},
	});
});

