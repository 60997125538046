/**
 * Lアラートお知らせ配信ダイアログ。
 * @module app/provide/ProvideGeneralRegisterDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/ProvideGeneralRegisterDialog.html',
    'app/provide/_ProvideDialogBase',
    'app/model/DisasterInfo',
    'idis/store/IdisRest',
    './model/GeneralInfoCategoryGroup',
    './model/GeneralInfoCategory',
    './model/ProvideUrgencyType',
    // 以下、変数から参照されないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/ValidationTextBox',
    'idis/view/form/WordCountTextarea',
    'idis/view/form/DateTimeInput',
    'idis/view/form/Button',
    'app/view/form/MunicipalitySelector',
    './ProvideTempInsertGrid'
], function(module, declare, lang, template,
    _ProvideDialogBase, DisasterInfo, IdisRest, GeneralInfoCategoryGroup, GeneralInfoCategory, ProvideUrgencyType) {
    /**
     * Lアラートお知らせ登録ダイアログ画面。
     * @class ProvideGeneralRegisterDialog
     * @extends module:app/provide/_ProvideDialogBase~_ProvideDialogBase
     */
    return declare(module.id.replace(/\//g, '.'), [_ProvideDialogBase],
        /** @lends module:app/provide/_ProvideDialogBase~_ProvideDialogBase# */ {
        // テンプレート文字列
        templateString: template,

        // 登録用ストア
        registerStore: null,

        // 更新種別
        _distributionType: null,

        // ベースクラスのコンストラクタでStoreを生成。
        constructor: function() {
            this.inherited(arguments);
            // 登録用ストア
            this.registerStore = new IdisRest({
                target: '/api/lalert/send'
            });
        },

        buildRendering: function() {
            this.inherited(arguments);
            // TODO テンプレート機能は未実装
            // // 04: お知らせのテンプレートに関する機能
            // this.own(this.templatePrefInsertDialog);
            // this.initPrefTemplateProvGrid();
            // 緊急度にモデルをセットして『通常』を初期値として選択
            this.urgencyType.set('store', ProvideUrgencyType);
            this.urgencyType.onLoadDeferred.then(lang.hitch(this.urgencyType, 'set', 'value', '01'));
            // 配信カテゴリーにモデルをセット
            this.generalInfoCategoryGroup.set('store', GeneralInfoCategoryGroup);
            this.generalInfoCategory.set('store', GeneralInfoCategory);
        },

        // DOM要素構築後に呼ばれる
        postCreate: function() {
            this.inherited(arguments);
            // カテゴリー・グループ選択の際、そのグループに紐付いたカテゴリーのみ選択可能にする
            this.own(this.generalInfoCategoryGroup.on('change', lang.hitch(this, function(value) {
                this.generalInfoCategory.set('query', function(item) {
                    // code先頭がグループのcodeと一致する場合のみ選択肢に表示
                    return item && item.code.indexOf(value) === 0;
                });
            })));
            // 初期値として『広報』を選択
            this.generalInfoCategoryGroup.set('value', '4');
        },

        // ベースクラスをオーバーライド
        // 画面から渡された値で初期表示設定を行う。
        initDialog: function(obj){
            // 市町村コードをセットする。
            this.municipalityCd._setValueAttr(obj.municipalityCd);
            // 配信種別をセットする。
            this.sendCategoryNode.innerHTML = this.getCategoryName(obj.sendCategory);
            this._distributionType = obj.distributionType;
            // 更新種別
            if(this._distributionType === '01') {
                this.errataDescriptionUpdateNode.style.display = 'none';
                this.errataDescriptionDeleteNode.style.display = 'none';
            }else if(this._distributionType === '02') {
                this.errataDescriptionDeleteNode.style.display = 'none';
            }else if(this._distributionType === '03') {
                this.errataDescriptionUpdateNode.style.display = 'none';
            }
            // タイトルの初期値
            this.subjectInput.set('value', 'お知らせ発信');
        },

        // テンプレートHTML内でformに対し、data-dojo-attach-event="onSubmit:onSubmit"でこのメソッドを紐付け
        // 登録ボタンをtype="submit"にしているので、ボタン押下時にフォームがsubmitされて呼ばれる
        onSubmit: function() {
            // 訂正確認画面ダイアログを表示する。
            var form = this.regForm.get('value');

            // 災害IDをformにセットする。
            form.disasterId = DisasterInfo.getDisasterId();

            var errataDescription = null;
            // 更新種別
            if(this._distributionType === '02') {
                errataDescription = this.errataDescriptionUpdate.displayedValue;
                form.errataDescription = errataDescription;
            }else if(this._distributionType === '03') {
                errataDescription = this.errataDescriptionDelete.displayedValue;
                form.errataDescription = errataDescription;
            }

            form.distributionType = this._distributionType;

            // 配信情報種別をformにセットする。
            form.sendCategory = this.GENERAL_CONTENT_TYPE;

            // 現在の時刻を配信日時としてformにセットする。
            form.sendTimestamp = new Date();

            try{
                if (this.regForm.validate()){
                    // baseクラスでリクエストを投げる
                    this._postRequest(this.registerStore, form);
                }
            } catch(e) {
                console.error('入力チェックでエラーが発生しました。', e);
            }
            // デフォルトのonSubmitイベントをキャンセルする。
            return false;
        }

       // TODO テンプレート機能は未実装
       //  // 04:お知らせ情報のテンプレート選択に関する機能
       //  onTemplatePrefInsertButtonClick: function() {
       //      this.templatePrefInsertDialog.show();
       //  },
       //  initPrefTemplateProvGrid: function() {
       //     this.prefGrid.set('collection', this.store.filter());
       //     this.prefGrid.on('insertButtonClick', lang.hitch(this, function(evt) {
       //         // helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
       //         this.onTemplatePrefInsert(evt.item);
       //     }));
       // },
       //  /**
       //   * リンク挿入内容確定時に呼ばれる。
       //   */
       //  onTemplatePrefInsert: function(item) {
       //      this.templatePrefInsertDialog.hide();
       //      this.regForm.set('value',item);
       //  }

    });
});
