/**
 * 利用者管理画面用モジュール。
 * @module app/user/UserAdminPage
 */
define([
	'module',
	'dojo/_base/declare',
	'dojo/_base/lang',
	'dojo/json',
	'dojo/text!./templates/UserAdminPage.html',
	'idis/view/page/_PageBase',
	'dojo/dom',
	'dojo/dom-style',
	'dijit/registry',
	'dijit/Dialog',
	'dstore/Memory',
	'dstore/Rest',
	'app/config',
	'idis/consts/ACL',
	'idis/view/grid/IdisGrid',
	'idis/view/form/AclButton',
	'idis/store/IdisRest',
	'idis/view/Loader',
	'idis/view/dialog/DialogChain',
	'idis/service/Requester',
	'idis/model/UserInfo',
	// 以下、変数で受けないモジュール
	'dijit/Dialog',
	'dijit/form/Form',
	'dijit/form/Select',
	'dijit/form/TextBox',
	'dijit/layout/BorderContainer',
	'dijit/layout/ContentPane',
	'dijit/layout/TabContainer',
    'app/view/form/MunicipalitySelector',
    'app/view/form/RegionSelector',
    'app/view/form/MunicRegionOrganizationSelector',
	'./UserAdminGrid',
	'./UserRegisterDialog',
	'./UserDetailDialog'
], function (module, declare, lang, json, template, _PageBase, dom, domStyle, registry,
	Dialog, Memory, Rest, config, ACL, IdisGrid, AclButton, IdisRest, Loader, DialogChain, Requester, UserInfo) {
	/**
	 * 利用者管理画面
	 * @class UserAdminPage
	 * @extends module:idis/view/page/_PageBase~_PageBase
	 */
	return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/user/UserAdminPage~UserAdminPage# */ {
			// テンプレート文字列
			templateString: template,

			// ルート要素に付与されるCSS
			baseClass: 'idis-Page idis-Page--user',

			/**
			 * ストア
			 */
			store: null,

			/**
			 * constructor
			 */
			constructor: function () {
				// データ格納用オブジェクト
				this.store = new IdisRest({
					idProperty: 'userId',
					target: '/api/users',
					sortParam: 'sort',
					rangeStartParam: 'offset',
					rangeCountParam: 'count'
				});
				// ダイアログ連鎖を登録
				this.chain = DialogChain.get(this);
			},

			/**
			 * buildRendering
			 */
			buildRendering: function () {
				this.inherited(arguments);
				// 自分が削除された時は関連ダイアログも削除する
				this.own(this.innerUserRegisterDialog);
				this.own(this.innerUserDetailDialog);
				this.organizationSelector.restoreRefresh();
			},

			/**
			 * startup
			 */
			startup: function () {
				this.inherited(arguments);

				this.organizationSelector._setReadOnlyAttr(true);

				this.initPage();
				this.initGrid();
			},

			/**
			 * グリッドを初期化する。
			 */
			initGrid: function () {
				// グリッドの詳細ボタンクリック時の動作を設定する
				// helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
				this.userAdminGrid.on('detailButtonClick', lang.hitch(this, function (evt) {
					this.showUpdateDialog(evt.item);
				}));

				this.updateGridQuery();
			},

			/**
			 * 利用者管理画面を初期化する。
			 */
			initPage: function () {
				// 役割セレクトボックスの初期化
				this.initSelectBox();

				// 新規登録ダイアログを初期化
				this.initRegisterDialog();

				// 詳細ダイアログを初期化
				this.initDetailDialog();
			},

			/**
			 * 新規登録ダイアログを初期化する。
			 */
			initRegisterDialog: function () {
				var dialog = this.userRegisterDialog;
				var page = dialog.getChildren()[0];

				// 登録画面のregisterイベントを受け取る
				page.on('register', lang.hitch(this, function (evt) {
					console.debug('registerイベント');
					// 追加確認ダイアログを表示
					this.chain.confirmAdd(function (chain) {
						// OKが押された場合
						// 追加処理と共にローディング表示
						Loader.wait(this.store.add(evt.value)).then(lang.hitch(this, function () {
							// 成功時（POST結果はグリッドが自動的に反映）
							// 登録ダイアログを閉じる
							dialog.hide();
							// 完了ダイアログを表示
							chain.infoComplete();
							// 一覧を取得しなおす
							this.updateGridQuery();
						}), function (err) {
							if (err.response.data) {
								var jsonData = json.parse(err.response.data);
								// ユーザIDが重複していた場合はDBエラー（E2001）
								if (jsonData.code && jsonData.code === 'E2001') {
									chain.info('ユーザIDが重複しています。', 'エラー');
									return;
								}
							}

							// 失敗時
							chain.infoError(err);
						});
					});
				}));
			},

			/**
			 * 詳細ダイアログを初期化する。
			 */
			initDetailDialog: function () {
				var dialog = this.userDetailDialog;
				var page = dialog.getChildren()[0];

				// 詳細画面のremoveイベントを受け取る
				page.on('remove', lang.hitch(this, function (evt) {
					console.debug('removeイベント');
					this.chain.confirmDel(function (chain) {
						var promise = this.store.remove(evt.value.userId + '?version=' + evt.value.version);
						Loader.wait(promise).then(lang.hitch(this, function () {
							// 登録フォーム・ダイアログを閉じる
							dialog.hide();
							chain.infoComplete();
							// 一覧を取得しなおす
							this.updateGridQuery();
						}), function (err) {
							// 失敗時
							chain.infoError(err);
						});
					});
				}));

				// 詳細画面のupdateイベントを受け取る
				page.on('update', lang.hitch(this, function (evt) {
					console.debug('updateイベント');
					// 追加確認ダイアログを表示
					this.chain.confirmPut(function (chain) {
						var form = evt.value;
						// 追加処理と共にローディング表示
						Loader.wait(this.store.put(form)).then(lang.hitch(this, function () {
							// 成功時（POST結果はグリッドが自動的に反映）
							// 登録ダイアログを閉じる
							dialog.hide();
							// 完了ダイアログを表示
							chain.infoComplete();
							// 一覧を取得しなおす
							this.updateGridQuery();
						}), function (err) {
							// 失敗時
							chain.infoError(err);
						});
					});
				}));
			},

			// 役割セレクトボックスの初期化を行う
			initSelectBox: function () {
				var promise = Requester.get('/api/roles');
				Loader.wait(promise).then(lang.hitch(this, function (data) {
					// セレクトボックスに設定するデータの配列を作成
					var newOptions = [];
					// サーバーレスポンスの各要素をselectへ追加
					if (UserInfo.getRoleCd() === ACL.ADMIN_USER){
						newOptions = [{
							label: '&nbsp;',
							value: ''
						}];
						data.items.forEach(function (object) {
							newOptions.push({
								label: object.roleName,
								value: object.roleCd
							});
						});
					} else {
						// システム管理者以外の場合は、操作ユーザと同じuser_typeを選択肢にセット
						data.items.forEach(function (object) {
							if(object.userType === UserInfo.getUserType() &&
								object.roleCd !== ACL.ADMIN_USER){
								newOptions.push({
									label: object.roleName,
									value: object.roleCd
								});
							}
						});
					}
					this.roleCdSelect.set('options', newOptions);
					if(UserInfo.getRoleCd() !== ACL.ADMIN_USER){
						this.roleCdSelect.set('value', UserInfo.getRoleCd());
						this.municipalityCd.set('value', UserInfo.getMunicipalityCd());
						this.regionCd.set('value', UserInfo.getRegionCd());
						this.municipalityCd._setReadOnlyAttr(true);
						this.regionCd._setReadOnlyAttr(true);
						this.organizationSelector._setReadOnlyAttr(false);
						this.updateGridQuery();
					}
				}));
			},

			// 役割セレクタの値が変更するときの動作
			onRoleCdChanged: function (roleCd) {
				this.organizationSelector.restoreRefresh();
				if(UserInfo.getRoleCd() === ACL.ADMIN_USER){
					this.municipalityCd.set('value', '');
					this.regionCd.set('value', '');
					this.organizationSelector.set('value', '');
					if (roleCd === '') {
						this.municipalityCd._setReadOnlyAttr(false);
						this.regionCd._setReadOnlyAttr(false);
						this.organizationSelector._setReadOnlyAttr(true);
					}
					else if (roleCd.match(/^R01[0-9]*/)) {
						this.municipalityCd.set('value', config.municInfo.prefMunicCd);
						this.municipalityCd._setReadOnlyAttr(true);
						this.regionCd._setReadOnlyAttr(true);
						this.organizationSelector._setReadOnlyAttr(false);
					}
					else if (roleCd.match(/^R02[0-9]*/)) {
						this.municipalityCd._setReadOnlyAttr(true);
						this.regionCd._setReadOnlyAttr(false);
						this.organizationSelector._setReadOnlyAttr(true);
					}
					else if (roleCd.match(/^R03[0-9]*/)) {
						this.municipalityCd._setReadOnlyAttr(false);
						this.regionCd._setReadOnlyAttr(true);
						this.organizationSelector._setReadOnlyAttr(true);
					}
					else if (roleCd.match(/^R04[0-9]*/)) {
						// （仮）関係機関のmunicipalityCdを設定
						this.municipalityCd.set('value', config.municInfo.prefMunicCd);
						this.municipalityCd._setReadOnlyAttr(true);
						this.regionCd._setReadOnlyAttr(true);
						this.organizationSelector._setReadOnlyAttr(false);
					}
				}
			},
			// 市町村セレクタの値が変更するときの動作
			onMunicipalityCdChanged: function (municipalityCd){
				this.organizationSelector.set('value', '');
				this.organizationSelector._setReadOnlyAttr(false);
				this.organizationSelector.setMunicipalityCd(municipalityCd.value);
			},			// 振興局セレクタの値が変更するときの動作
			onRegionCdChanged: function(regionCd) {
				this.organizationSelector.set('value', '');
				this.organizationSelector._setReadOnlyAttr(false);
				this.organizationSelector.setRegionCd(regionCd.value);
			},
			/**
			 * 利用者を検索する。
			 */
			onSubmit: function () {
				console.debug('[検索]ボタンがクリックされました。');
				try {
					if (this.form.isValid()) {
						// 入力値が正常ならグリッドの検索条件を更新
						this.updateGridQuery();
					}
				} catch (e) {
					console.error(e);
				}
				return false;
			},

			/**
			 * 利用者新規登録ダイアロを表示する。
			 */
			showRegisterDialog: function () {
				console.debug('[新規登録]ボタンがクリックされました。');
				this.innerUserRegisterDialog.initDialog();
				this.userRegisterDialog.show();
			},

			/**
			 * 利用者詳細ダイアログを表示する。
			 */
			showUpdateDialog: function (object) {
				console.debug('[詳細]ボタンがクリックされました。');
				this.innerUserDetailDialog.initDialog(object);
				this.userDetailDialog.show();
			},

			filterByType: function (value) {
				alert(value);
			},

			/**
			 * グリットを更新する。
			 */
			updateGridQuery: function () {
				var filter = new this.store.Filter();
				var value = this.form.get('value');
				console.log(value);

				// アカウント種類による検索
				if (value.roleCd && value.roleCd !== '') {
					// 市町ユーザの場合、役割不問で検索する
					//（市町管理者による自市町所属ユーザの更新を可能とするため）
					// if (UserInfo.getUserType() !== '03') {
						filter = filter.eq('roleCd', value.roleCd);
					// }
				}

				// 市町村による検索
				if (value.municipalityCd && value.municipalityCd !== '') {
					filter = filter.eq('municipalityCd', value.municipalityCd);
				}

				// 振興局による検索
				if (value.regionCd && value.regionCd !== '') {
					filter = filter.eq('regionCd', value.regionCd);
				}

				// 組織による検索
				if (value.organization && value.organization !== '') {
					filter = filter.eq('organization', value.organization);
				}

				// filterに対応するcollectionを取得
				var collection = this.store.filter(filter);

				// collectionをグリッドにセットする（サーバーにリクエストされる）
				this.userAdminGrid.set('collection', collection);
			}
		});
});
