/**
 * 帳票ディスプレイ一覧グリッド
 * @module app/formDisplay/formAdminPageGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/view/grid/IdisGrid',
    'idis/util/DateUtils',
    'idis/view/grid/helper'
], function(module, declare, IdisGrid, DateUtils, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid,
        /** @lends module:idis/view/page/ConvoTempInsertGrid~ConvoTempInsertGrid# */ {

        /**
         * 各列の定義
         * @type {Object[]}
         */
        collection: this.store,
        columns: [
            helper.buttonColumn('detail', '詳細'),
            {field: 'screenId',label: '画面ID', sortable: true},
            {field: 'displayTypeName',label: 'ディスプレイ種別', sortable: false},
            {field: 'remarks',label: '備考', sortable: false},
            {field: 'updTimestamp',label: '最終更新日時', sortable: true,
            formatter: function(updTimestamp){
                return updTimestamp ? DateUtils.format(updTimestamp) : '-';
            }}
        ]
    });
});
