/**
 * 通行規制情報の詳細画面用モジュール。
 * @module app/traffic/view/TrafficRegulationDetailPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/json',
    'dojo/dom-style',
    'dojo/topic',
    'dojo/Deferred',
    'dijit/registry',
    'app/model/LayerStore',
    'app/traffic/consts/parentLayerIdCfg',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/model/UserInfo',
    'idis/store/IdisRest',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    'leaflet',
    'leaflet-geometryutil',
    './_TrafficRegulationDetailPageBase'
], function(module, declare, lang, json, domStyle, topic, Deferred, registry, LayerStore, parentLayerIdCfg, Locator, 
    Router, UserInfo, IdisRest, Requester, DialogChain, Loader, leaflet, geometryutil, 
    _TrafficRegulationDetailPageBase) {
    /**
     * 通行規制情報の詳細画面。
     * @class TrafficRegulationDetailPage
     * @extends module:app/traffic/view/_TrafficRegulationDetailPageBase~_TrafficRegulationDetailPageBase
     */
    return declare(module.id.replace(/\//g, '.'), _TrafficRegulationDetailPageBase,
        /** @lends module:app/traffic/view/TrafficRegulationDetailPage~TrafficRegulationDetailPage# */
        {

            // drawpanelとのpubsub用
            DRAW_BY_JSON: '/app/draw/DrawPanel::drawGeoJSONToLayer',
            REMOVE_LAYER: '/app/draw/DrawPanel::removeLayer',

            /**
             * Excel帳票（個票）を出力する。
             */
            outputDetailExcel: function() {
                console.debug('outputDetailExcel');
                this.inherited(arguments);

                // URLから通行規制情報IDを取得
                var trafficRegulationId = Locator.getQuery().id;

                // xoblos連携のAPIのベースURL
                var baseUrl = '/corabo/api/getreport';

                // クエリーパラメータを追加
                var url = baseUrl + '?reportid=1&trafficregulationid=' + trafficRegulationId;

                console.debug(url);

                // 確認ダイアログを表示
                this.chain.confirm('帳票を出力します。<br>よろしいですか？', function(chain) {
                    // 確認ダイアログでOKを押した場合
                    chain.hide();
                    // URLを遷移する
                    window.location.href=url;
                });
            },

            /**
             * 通行規制情報を訂正する。
             */
            updateTrafficRegulation: function() {
                console.debug('updateTrafficRegulation');
                this.inherited(arguments);

                // フォームのバリデーションを行う（共通部品）
                if (!this.form.validate()) {
                    return false;
                }
                // フォームのバリデーションを行う（独自チェック）
                if (!this.validateForm()) {
                    return false;
                }

                // URLから通行規制情報IDを取得
                var trafficRegulationId = Locator.getQuery().id;

                // フォームデータを取得
                var formData = this.form.get('value');

                // 広島以降のパッケージではpubStatusの概念がないため、常に4(全体公開)をセットする。
                formData.pubStatus = '4';

                // 通行規制情報IDをフォームに設定
                formData.trafficRegulationId = trafficRegulationId;

                // 報告番号はdisabledのため値がセットされていないので直接セットする
                formData.seqNum = this.seqNum.value;

                // 災害でない場合の親レイヤーの規定値
                if (!formData.parentLayerId) {
                    formData.parentLayerId = parentLayerIdCfg.DEFALUT_PARENT_LAYER_ID;
                }
                // ユーザの組織コードを取得する
                var orgCd = (UserInfo.getOrganization().unitCd ? 'U' + UserInfo.getOrganization().unitCd :
                    UserInfo.getOrganization().sectCd ? 'S' + UserInfo.getOrganization().sectCd :
                    UserInfo.getOrganization().deptCd ? 'D' + UserInfo.getOrganization().deptCd : '');
                formData.organization = orgCd;

                // 道路種別がその他の場合はその他入力欄から路線名を取得
                // その他以外の場合はセレクトボックスのvalue（valueもdisplayedValueも路線名）が路線名になる
                if (this.roadTypeCd.get('value') === '99') {
                    formData.roadName = this.roadNameOther.get('value');
                }

                // 路線名その他入力欄は送信しない（使う場合は路線名として送信）
                delete formData.roadNameOther;

                // 規制要因によって区間入力内容をクリア
                this.clearSectionInput(formData);
                // 規制解除予定によって規制解除予定日時をクリア
                this.clearRegPlanedEndTimestampInput(formData);
                // 孤立集落によって戸数をクリア
                this.clearIsolatedHouseCountInput(formData);
                // 人的被害によって被害人数をクリア
                this.clearHumanDmgCountInput(formData);

                // 対応状況を格納する配列
                var trafficActions = [];
                // 対応状況をメモリーストアから取得して配列に格納
                this.trafficActionStore.fetch().forEach(function(object) {
                    // 不要なプロパティーを削除
                    delete object.id;
                    // 配列に格納
                    trafficActions.push(object);
                });
                // 対応状況をフォームデータにセット
                formData.trafficActions = trafficActions;

                // 添付ファイルは送信しない
                delete formData.attachFile;

                // 添付ファイルのIDのリストを送る
                var trafficAttachmentIds = [];
                for (var i = 0; i < this.trafficAttachments.length; i++) {
                    trafficAttachmentIds.push(this.trafficAttachments[i].trafficAttachmentId);
                }
                formData.trafficAttachmentIds = trafficAttachmentIds.join(',');

                // 作図、選択道路データ
                this.getGeoJSON().then(lang.hitch(this, function(geojson) {

                    // 作図データに追加するfeatureの配列
                    var addgeojson = [];
                    // 規制区間を足す
                    this.regulatedLayers.forEach(lang.hitch(this, function(layer) {
                        addgeojson.push(this.regulatedLayerToGeoJSON(layer));
                    }));
                    // 被災箇所を足す
                    addgeojson.push(this.damagedLayerToGeoJSON(this.damagedLayer));
                    this.addDamagedLayers.forEach(lang.hitch(this, function(layer) {
                        addgeojson.push(this.addDamagedLayerToGeoJSON(layer));
                    }));
                    // 工事区間を足す
                    addgeojson.push(this.constructedLayerToGeoJSON(this.constructedLayer));

                    // 規制区間・被災箇所・工事区間が空でなかったら要素を足す
                    geojson.features = addgeojson.filter(function(o) {
                        return o;
                    }).concat(geojson.features);

                    // 規制解除日時が入力されている場合は、各featureに規制解除済みを示すプロパティーをセットする
                    // 入力されていない場合は解除済みを示すプロパティーを削除する
                    if (this.regEndTimestamp.get('value')) {
                        geojson.features.forEach(lang.hitch(this, function(feature) {
                            feature.properties.isRegEnd = true;
                        }));
                    } else {
                        geojson.features.forEach(lang.hitch(this, function(feature) {
                            delete feature.properties.isRegEnd;
                        }));
                    }

                    formData.drawJson = json.stringify(geojson);

                    // 更新確認ダイアログを表示
                    this.chain.confirmPut(function(chain) {
                        console.debug('通行規制情報の更新を行います');
                        // デバッグ用
                        var jsonStr = json.stringify(formData);
                        console.debug(jsonStr);

                        var promise = Requester.put('/api/traffic/regulations/' + trafficRegulationId, {
                            data: formData
                        });
                        // ローダーの表示
                        Loader.wait(promise).then(function(data) {
                            console.debug(data);
                            // レイヤーをリフレッシュする
                            console.debug('レイヤーをリフレッシュします (layerId=' + data.layerId + ')');
                            LayerStore.refresh(data.layerId);
                            console.debug('親レイヤーをリフレッシュします (parentLayerId=' + data.parentLayerId + ')');
                            LayerStore.refresh(data.parentLayerId);
                        
                            chain.infoComplete(function() {
                                // 一覧画面に移動
                                Router.moveTo('traffic');
                            });
                        }, function(error) {
                            console.debug(error);
                            chain.infoError(error, function() {
                                // 一覧画面に移動
                                Router.moveTo('traffic');
                            });
                        });
                    });
                }));
            },

            /**
             * 通行規制情報を更新する。
             */
            createFollowUpTrafficRegulation: function() {
                console.debug('copyCreateTrafficRegulation');
                this.inherited(arguments);

                // フォームのバリデーションを行う（共通部品）
                if (!this.form.validate()) {
                    return false;
                }
                // フォームのバリデーションを行う（独自チェック）
                if (!this.validateForm()) {
                    return false;
                }

                // URLから通行規制情報IDを取得
                var trafficRegulationId = Locator.getQuery().id;

                // フォームデータを取得
                var formData = this.form.get('value');

                // 広島以降のパッケージではpubStatusの概念がないため、常に4(全体公開)をセットする。
                formData.pubStatus = '4';

                // 通行規制情報IDをフォームに設定
                formData.trafficRegulationId = trafficRegulationId;

                // 報告番号はdisabledのため値がセットされていないので直接セットする
                // 続報番号をインクリメント
                formData.seqNum = this.seqNum.value + 1;

                // 災害でない場合の親レイヤーの規定値
                if (!formData.parentLayerId) {
                    formData.parentLayerId = parentLayerIdCfg.DEFALUT_PARENT_LAYER_ID;
                }
                // ユーザの組織コードを取得する
                var orgCd = (UserInfo.getOrganization().unitCd ? 'U' + UserInfo.getOrganization().unitCd :
                    UserInfo.getOrganization().sectCd ? 'S' + UserInfo.getOrganization().sectCd :
                    UserInfo.getOrganization().deptCd ? 'D' + UserInfo.getOrganization().deptCd : '');
                formData.organization = orgCd;

                // 道路種別がその他の場合はその他入力欄から路線名を取得
                // その他以外の場合はセレクトボックスのvalue（valueもdisplayedValueも路線名）が路線名になる
                if (this.roadTypeCd.get('value') === '99') {
                    formData.roadName = this.roadNameOther.get('value');
                }

                // 路線名その他入力欄は送信しない（使う場合は路線名として送信）
                delete formData.roadNameOther;

                // 規制要因によって区間入力内容をクリア
                this.clearSectionInput(formData);
                // 規制解除予定によって規制解除予定日時をクリア
                this.clearRegPlanedEndTimestampInput(formData);
                // 孤立集落によって戸数をクリア
                this.clearIsolatedHouseCountInput(formData);
                // 人的被害によって被害人数をクリア
                this.clearHumanDmgCountInput(formData);

                // 対応状況を格納する配列
                var trafficActions = [];
                // 対応状況をメモリーストアから取得して配列に格納
                this.trafficActionStore.fetch().forEach(function(object) {
                    // 不要なプロパティーを削除
                    delete object.id;
                    delete object.trafficActionId;
                    // 配列に格納
                    trafficActions.push(object);
                });
                // 対応状況をフォームデータにセット
                formData.trafficActions = trafficActions;

                // 添付ファイルは送信しない
                delete formData.attachFile;

                // 添付ファイルのIDのリストを送る
                var trafficAttachmentIds = [];
                for (var i = 0; i < this.trafficAttachments.length; i++) {
                    trafficAttachmentIds.push(this.trafficAttachments[i].trafficAttachmentId);
                }
                formData.trafficAttachmentIds = trafficAttachmentIds.join(',');

                // 作図、選択道路データ
                this.getGeoJSON().then(lang.hitch(this, function(geojson) {

                    // 作図データに追加するfeatureの配列
                    var addgeojson = [];
                    // 規制区間を足す
                    this.regulatedLayers.forEach(lang.hitch(this, function(layer) {
                        addgeojson.push(this.regulatedLayerToGeoJSON(layer));
                    }));
                    // 被災箇所を足す
                    addgeojson.push(this.damagedLayerToGeoJSON(this.damagedLayer));
                    this.addDamagedLayers.forEach(lang.hitch(this, function(layer) {
                        addgeojson.push(this.addDamagedLayerToGeoJSON(layer));
                    }));
                    // 工事区間を足す
                    addgeojson.push(this.constructedLayerToGeoJSON(this.constructedLayer));

                    // 規制区間・被災箇所・工事区間が空でなかったら要素を足す
                    geojson.features = addgeojson.filter(function(o) {
                        return o;
                    }).concat(geojson.features);

                    // 規制解除日時が入力されている場合は、各featureに規制解除済みを示すプロパティーをセットする
                    // 入力されていない場合は解除済みを示すプロパティーを削除する
                    if (this.regEndTimestamp.get('value')) {
                        geojson.features.forEach(lang.hitch(this, function(feature) {
                            feature.properties.isRegEnd = true;
                        }));
                    } else {
                        geojson.features.forEach(lang.hitch(this, function(feature) {
                            delete feature.properties.isRegEnd;
                        }));
                    }

                    formData.drawJson = json.stringify(geojson);

                    // 更新確認ダイアログを表示
                    this.chain.confirmAdd(function(chain) {
                        console.debug('通行規制情報の続報登録を行います');
                        // デバッグ用
                        var jsonStr = json.stringify(formData);
                        console.debug(jsonStr);

                        var promise = Requester.post('/api/traffic/regulations', {
                                data: formData
                        });
                        // ローダーの表示
                        Loader.wait(promise).then(function(data) {
                            console.debug(data);
                            // レイヤーをリフレッシュする
                            // LayerStore.refresh(data.parentLayerId);
                            // 続報登録では複数のレイヤーが更新されるの可能性があるので全てリフレッシュする
                            console.debug('全レイヤーをリフレッシュします');
                            LayerStore.refreshAll();
                            chain.infoComplete(function() {
                                // 一覧画面に移動
                                Router.moveTo('traffic');
                            });
                        }, function(error) {
                            chain.infoError(error, function() {
                                // 一覧画面に移動
                                Router.moveTo('traffic');
                            });
                    });
                    });
                }));
            },

            /**
             * 通行規制情報を削除する。
             */
            deleteTrafficRegulation: function() {
                console.debug('deleteTrafficRegulation');
                this.inherited(arguments);

                // フォームデータを取得
                var formData = this.form.get('value');

                // URLから通行規制情報IDを取得
                var trafficRegulationId = Locator.getQuery().id;

                // 削除確認ダイアログを表示
                this.chain.confirmDel(function(chain) {
                    console.debug('通行規制情報の削除を行います');

                    var promise = Requester.del('/api/traffic/regulations/' + trafficRegulationId, {
                        query:{
                            version: formData.version
                        }
                    });
                    // ローダーの表示
                    Loader.wait(promise).then(function(data) {
                        console.debug(data);
                        // レイヤーをリフレッシュする
                        // LayerStore.refresh(data.parentLayerId);
                        // 続報登録では複数のレイヤーが更新されるの可能性があるので全てリフレッシュする
                        console.debug('全レイヤーをリフレッシュします');
                        LayerStore.refreshAll();
                        chain.infoComplete(function() {
                            // 一覧画面に移動
                            Router.moveTo('traffic');
                        });
                    }, function(error) {
                        console.debug(error);
                        chain.infoError(error, function() {
                            // 一覧画面に移動
                            Router.moveTo('traffic');
                        });
                    });
                });

            },

            /**
             * 通行規制情報を復元する。
             */
            restoreTrafficRegulation: function() {
                console.debug('restoreTrafficRegulation');
                this.inherited(arguments);

                // フォームデータを取得
                var formData = this.form.get('value');

                // URLから通行規制情報IDを取得
                var trafficRegulationId = Locator.getQuery().id;

                // 復元確認ダイアログを表示
                this.chain.confirm(function(chain) {
                    console.debug('通行規制情報の復元を行います');

                    var promise = Requester.put('/api/traffic/restore/' + trafficRegulationId, {
                        query:{
                            version: formData.version
                        }
                    });
                    // ローダーの表示
                    Loader.wait(promise).then(function(data) {
                        console.debug(data);
                        // レイヤーをリフレッシュする
                        // LayerStore.refresh(data.parentLayerId);
                        // 続報登録では複数のレイヤーが更新されるの可能性があるので全てリフレッシュする
                        console.debug('全レイヤーをリフレッシュします');
                        LayerStore.refreshAll();
                        chain.infoComplete(function() {
                            // 一覧画面に移動
                            Router.moveTo('traffic');
                        });
                    }, function(error) {
                        console.debug(error);
                        chain.infoError(error, function() {
                            // 一覧画面に移動
                            Router.moveTo('traffic');
                        });
                    });
                });

            },

            /**
             * 画面の初期表示項目を設定する。
             * 詳細画面。
             */
            initDetailPage: function() {
                console.debug('initDetailPage');
                this.inherited(arguments);

                var dfd = new Deferred();

                this.form.reset();
                this.trafficAttachments = [];

                // URLから通行規制情報IDを取得
                var trafficRegulationId = Locator.getQuery().id;

                // ページのタイトルを設定する
                this.pageTitle.innerHTML = '詳細';

                // 登録ボタンを表示しない
                this.registerTrafficRegulationButton.set('style', {display:'none'});

                // 詳細画面では格納先は変更不可なのでボタンを非表示にする
                this.regionCd.set('noAllButton', true);
                // 詳細画面では災害は変更不可なのでボタンを非表示にする
                this.disasterId.set('noAllButton', true);

                // 参照用ユーザは添付ファイルのアップローダを表示しない
                if (UserInfo.getAcl().WRITE) {
                    domStyle.set(this.attachFileArea, 'display', '');
                } else {
                    domStyle.set(this.attachFileArea, 'display', 'none');
                }

                // サーバーから通行規制情報を取得する
                Requester.get('/api/traffic/regulations/' + trafficRegulationId).then(lang.hitch(this, function(item) {

                    // 規制要因が設定されている場合
                    if (item.regReasonCd) {
                        this.regReasonCd.set('value', item.regReasonCd);
                    }
                    // 道路種別が設定されている場合
                    if (item.roadTypeCd) {
                        // 道路種別をセットする
                        this.roadTypeCd.set('value', item.roadTypeCd);
                        // 道路種別がその他の場合はその他入力欄に路線名を設定
                        if (this.roadTypeCd.get('value') === '99') {
                            // 路線名セレクトボックスを非表示
                            this.roadName.set('style', {
                                display: 'none'
                            });
                            // 路線名その他入力欄を表示
                            this.roadNameOther.set('style', {
                                display: ''
                            });
                            // その他入力欄に路線名を設定
                            this.roadNameOther.set('value', item.roadName);
                            // 道路種別、路線名は入力したので削除
                            delete item.roadTypeCd;
                            delete item.roadName;
                            // その他の通行規制情報を画面上のフォームにセットする
                            this.form.set('value', item);
                            // 地図情報を読み込んで書く
                            this.getLayerJsonAndDraw(item).then(lang.hitch(this, function() {
                                dfd.resolve();
                            }));
                        // 道路種別がその他以外の場合
                        } else {
                            // 路線名セレクトボックスを表示
                            this.roadName.set('style', {
                                display: ''
                            });
                            // 路線名その他入力欄を非表示
                            this.roadNameOther.set('style', {
                                display: 'none'
                            });
                            // 路線名のoptionsを作る
                            this.initRoadNameList(item.roadTypeCd).then(lang.hitch(this, function() {
                                // 路線名をセットする
                                this.roadName.set('value', item.roadName);
                                // 地図上に路線を表示する
                                this.drawRoadByName(item.roadName).then(lang.hitch(this, function() {
                                    // 道路種別、路線名は入力したので削除
                                    delete item.roadTypeCd;
                                    delete item.roadName;
                                    // その他の通行規制情報を画面上のフォームにセットする
                                    this.form.set('value', item);
                                    // 地図情報を読み込んで書く
                                    this.getLayerJsonAndDraw(item).then(lang.hitch(this, function() {
                                        dfd.resolve();
                                   }));
                                }));
                            }));
                            this.form.set('value', item);
                        }
                    } else {
                        // 通行規制情報を画面上のフォームにセットする
                        this.form.set('value', item);
                        // 地図情報を読み込んで書く
                        this.getLayerJsonAndDraw(item).then(lang.hitch(this, function() {
                            dfd.resolve();
                        }));
                    }

                    // 続報番号
                    this.seqNumStr.innerHTML = item.seqNum;

                    // 対応状況をactionTimestampでソートしてからメモリーストアにセットする
                    if(item.trafficActions){
                        item.trafficActions.sort(function(a, b) {
                            if (a.actionTimestamp < b.actionTimestamp) {
                                return -1;
                            }
                            if (a.actionTimestamp > b.actionTimestamp) {
                                return 1;
                            }
                            return 0;
                        }).forEach(function(object) {
                            // 更新の場合は削除されているものも送る
                            this.trafficActionStore.add(object);
                        }, this);
                    }

                    // 過去報の場合は更新ボタンを非表示
                    if (item.activeFlg === '0') {
                        this.createFollowUpTrafficRegulationButton.set('style', {display: 'none'});
                    }

                    if (item.delFlg === '1') {
                        // 削除されている場合、講師、複製、訂正、削除ボタンを非表示
                        this.updateTrafficRegulationButton.set('style', {display:'none'});
                        this.deleteTrafficRegulationButton.set('style', {display: 'none'});
                        this.copyCreateTrafficRegulationButton.set('style', {display: 'none'});
                        this.createFollowUpTrafficRegulationButton.set('style', {display: 'none'});
                        if (!UserInfo.getAdminFlg()) {
                            // 管理者でない場合、復元ボタンを非表示
                            this.restoreTrafficRegulationButton.set('style', {display: 'none'});
                        }
                    } else {
                        // 削除されていない場合、復元ボタンを非表示
                        this.restoreTrafficRegulationButton.set('style', {display: 'none'});
                    }

                    // 公開/非公開
                    switch (item.releaseFlg) {
                        case '0':
                            domStyle.set(this.reportedStatus0, 'display', '');
                            domStyle.set(this.reportedStatus1, 'display', 'none');
                            break;
                        case '1':
                            domStyle.set(this.reportedStatus0, 'display', 'none');
                            domStyle.set(this.reportedStatus1, 'display', '');
                            break;
                    }

                    // 添付ファイルを設定
                    if (item.trafficAttachments && item.trafficAttachments.length !== 0) {
                        item.trafficAttachments.forEach(function(object) {
                            // 削除済みでない場合のみセットする
                            if (object.delFlg !== '1') {
                                // 添付ファイルの配列に追加
                                this.trafficAttachments.push(object);
                                // プレビューを表示
                                this.showPreview(object);
                            }
                        }, this);
                    } else {
                        // 添付ファイルがない場合で、参照用ユーザの場合は、メッセージを表示
                        if (UserInfo.getAcl().WRITE) {
                            domStyle.set(this.attachFileAreaDummy, 'display', 'none');
                        } else {
                            domStyle.set(this.attachFileAreaDummy, 'display', '');
                        }
                    }

                }), function(error) {
                    console.debug(error);
                    dfd.reject();
                });

                return dfd;
            },

            // HTML上にウィジェットが設置されてから呼ばれる
            startup: function() {
                console.debug('startup');
                this.inherited(arguments);

                // 初期ロード中フラグをtrueにする
                this.isInStartup = true;
                console.debug('isInStartup=' + this.isInStartup);
                // 画面の初期表示
                this.initDetailPage().then(lang.hitch(this, function() {
                    // 詳細画面で利用するTipsをセットする
                    // this.setTipsPopups();
                    // 規制要因によって入力項目を変更
                    this.updateSectionInput();
                    // 規制区間がある場合は地図を移動
                    if (this.regulatedLayers.length !== 0) {
                        var group = new leaflet.featureGroup(this.regulatedLayers);
                        this.map.fitBounds(group.getBounds(), {padding: [50, 50]});
                    // 規制区間がなく被災箇所がある場合は地図を移動
                    } else if (this.damagedLayer) {
                        this.map.panTo(this.damagedLayer.getLatLng());
                    // 規制区間がなく工事区間がある場合は地図を移動
                    } else if (this.constructedLayer) {
                        this.map.fitBounds(this.constructedLayer, {padding: [50, 50]});
                    }
                    // 地図上の通行規制関連ボタンの表示状態を更新する
                    this.updateTrafficButtons();
                    // 災害名を設定する
                    this.updateDisasterInputDetail();
                    // 規制解除予定日時の入力可/不可を設定
                    this.updateRegPlanedEndTimestampInput();
                    // 戸数の入力可/不可を設定
                    this.updateIsolatedHouseCountInput();
                    // 被害人数の入力可/不可を設定
                    this.updateHumanDmgCountInput();
                    // 処理が完了したら、遅延を入れてから、初期ロードフラグをfalseにする
                    setTimeout(lang.hitch(this, function(){
                        this.isInStartup = false;
                        console.debug('isInStartup=' + this.isInStartup);
                    }), 1000);
                }));

                // レイアウト崩れ対策のためリサイズを実施
                this.borderContainer.resize();

            }
        });
});
