/**
 * 職員安否機能トップ画面用モジュール。
 *
 * @module app/safety/SafetyTopPage
 */
define(['module',
        'dojo/_base/declare',
        'dojo/text!./templates/SafetyTopPage.html',
        'idis/view/page/_PageBase',
        // 以下、変数で受けないモジュール
        'dijit/layout/BorderContainer',
        'dijit/layout/ContentPane',
        'dijit/layout/TabContainer',
        'app/safety/mail/SafetyMailAdminPage',
        'app/safety/employee/SafetyEmployeeAdminPage',
        'app/safety/group/SafetyGroupAdminPage'],
    function(module, declare, template, _PageBase) {
        /**
         * 職員安否トップ画面
         *
         * @class SafetyTopPage
         * @extends module:idis/view/page/_PageBase~_PageBase
         */
        return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app_safety/SafetyTopPage~SafetyTopPage# */
        {

            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--safety'

        });
    });
