/**
 * 送信メールテンプレートグリッド
 * @module app/safety/mail/SafetyMailTempGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/view/grid/IdisGrid',
    'dojo/_base/lang',
    'idis/control/Router',
    'idis/view/grid/helper'
], function(module, declare, IdisGrid, lang, Router, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {
        /**
         * 各列の定義
         * @type {Object[]}
         */
        collection: this.store,
        columns: [
            helper.buttonColumn('detail', '詳細'),
            helper.column('distributionTitle', 'タイトル'),
            helper.column('distributionBody', '本文', {
                formatter: lang.hitch(this, function(item){
                    if (!item) {
                        // 値を持たないヘッダ行に対しては処理を実施しない。
                        return item;
                    }
                    if (item.indexOf('¥n') !== -1) {
                        // 改行コード(¥n)を含む場合は、<br>に変換
                        return item.replace(/¥n/g, '<br>');                        
                    }
                    // 改行コード(\n)を含む場合は、<br>に変換
                    // 改行コードを含んでいない場合は処理を実施せず空振り
                    return item.replace(/\\n/g, '<br>');
                })
            })
        ]
    });
});
