/**
 * 観測地域モデル
 * @module app/model/ObservationRegion
 */
define([
    'dojo/store/Memory'
    // 以下、変数で受けないモジュール
], function(Memory) {
    // シングルトンを返す
    return new Memory({
        idProperty: 'value',
        data: [
            {
                'value': '420000',
                'label': '長崎県全域',
                'selected': true,
                'regionFlg': ''
            },
            {
                'value': '',
                'label': '------地域選択------',
                'disabled': 'true',
                'regionFlg': '1'
            },
            {
                'value': '420001',
                'label': '長崎振興局',
                'regionFlg': '1'
            },
            {
                'value': '420002',
                'label': '県央振興局',
                'regionFlg': '1'
            },
            {
                'value': '420003',
                'label': '島原振興局',
                'regionFlg': '1'
            },
            {
                'value': '420004',
                'label': '県北振興局',
                'regionFlg': '1'
            },
            {
                'value': '420005',
                'label': '五島振興局',
                'regionFlg': '1'
            },
            {
                'value':'420006',
                'label':'壱岐振興局',
                'regionFlg': '1'
            },
            {
                'value':'420007',
                'label':'対馬振興局',
                'regionFlg': '1'
            }
        ]
    });
});
