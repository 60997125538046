/**
 * 河川水位詳細情報のグラフ
 * @module app/river/chart/RiverLevelCrossSectionChart
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/aspect',
    'dojo/dom-style',
    'dojo/dom-geometry',
    'dojox/charting/plot2d/Areas',
    'dojox/charting/plot2d/Lines',
    'dojox/charting/action2d/Tooltip',
    'dojox/charting/action2d/Magnify',
    'app/view/chart/ChartConfig',
    'app/view/chart/ChartCommon',
    './ChartContainer',
    './RiverImageInfo'
    // 以下、変数で受けないモジュール
], function(module, array, declare, aspect, domStyle, domGeom, Areas, Lines, Tooltip, Magnify,
    ChartConfig, ChartCommon, ChartContainer, RiverImageInfo) {
    /**
     * 河川水位詳細情報グラフ
     * @class RiverLevelChart
     */
    return declare(module.id.replace(/\//g, '.'), ChartContainer,
        /** @lends module:app/river/chart/RiverLevelChart~RiverLevelChart# */
    {
        title: '河川水位',

        drawWidth: 0,

        drawHeight: 0,

        // DOMを構築する
        buildRendering: function() {
            this.inherited(arguments);
            // Charのrenderが呼ばれた直後に画像の描画処理を差し込むようにする
            this.own(aspect.around(this.chart, 'render', function(orgMethod) {
                return function() {
                    // 元のrenderメソッドを呼び出す
                    var orgResult = orgMethod.apply(this, arguments);
                    // 画像がある場合は描画
                    if (this._riverImageInfo) {
                        // _calcYAxisRangeメソッドでY軸の高さをダム画像上端・下端に合わせているので
                        // 描画領域の高さをそのまま画像描画時の高さとして設定する
                        var drawHeight = this.plotArea.height;
                        // 幅は高さに合わせてアスペクト比が変わらないよう調整する
                        var drawWidth = drawHeight * this._riverImageInfo.width / this._riverImageInfo.height;
                        this.surface.createImage({
                            // 左端に寄せる
                            x: this.offsets.l + 0.5,
                            y: this.offsets.t,
                            width: this.plotArea.width,
                            height: this.plotArea.height,
                            src: this._riverImageInfo.src
                        }).moveToBack();
                        // 軸線と面グラフを背面に移動
                        this.surface.children[3].moveToBack();
                        this.surface.children[3].moveToBack();
                        this.surface.children[3].moveToBack();
                    	// サイズ保存
                        this.drawWidth = drawWidth;
                        this.drawHeight = drawHeight;
                    }
                    // 呼び出し元にrenderメソッドの結果を返す
                    return orgResult;
                };
            }));
            // 水位用折れ線グラフ
            this.chart.addPlot('quantities', {
                type: Lines,
                markers: true,
                labels: true,
                vAxis: 'y'
            });
            // 基準水位用折れ線グラフ
            this.chart.addPlot('Level', {
                type: Lines,
                markers: false,
                labels: true,
                vAxis: 'y'
            });
            // 河川水位用面グラフ
            this.chart.addPlot('riverLevelChart', {
                type: Areas,
                fill: '#00FFFF'
            });
        },
    	
        /**
         * 設定されたデータを元にY軸の上端・下端位置を計算して返す。
         * @returns {Object} Y軸の上端・下端位置を格納したオブジェクト
         */
        _calcYAxisRange: function() {
            var riverImageInfo = this.chart._riverImageInfo;
            if (riverImageInfo) {
                // 対応する断面図画像情報がある場合は画像に合わせて設定する
                // 1ピクセル当たり何メートル変動するかを計算(最大水位 / 画像上端～0m線ピクセル))
                var meterPerPixels = riverImageInfo.maxMeter / riverImageInfo.zeroPixel;
                // 画像の下端～0線までの長さ(m)を計算する。（0 - ピクセル当たりのメートル数 * (画像高さ - (画像上端～0m線 + 0m線太さ))）
                var minMeter = 0 - meterPerPixels * (riverImageInfo.height - (riverImageInfo.zeroPixel + 1));
                return {
                    min: Number(minMeter.toFixed(2)),
                    max: Number(riverImageInfo.maxMeter.toFixed(2))
                };
            } else {
                // 対応する画像情報が無い場合表示データによって動的に変える
                // デフォルトセット
                var max = 4;
                var min = 1;
                // 各水位の最大値がデフォルト以上の場合更新
                max = ChartCommon.getMaxData(this.data, 'riverLevel', max);
                max = ChartCommon.getMaxData(this.data, 'riverLevelDanger', max);
                max = ChartCommon.getMaxData(this.data, 'riverLevelCaution', max);
                max = ChartCommon.getMaxData(this.data, 'riverLevelEvacuate', max);
                max = ChartCommon.getMaxData(this.data, 'riverLevelStandby', max);
                // 各水位の最小値がデフォルト未満の場合更新
                min = ChartCommon.getMinData(this.data, 'riverLevel', min);
                return {
                    min: min - 1,
                    max: max + 1
                };
            }
        },

        applyData: function() {
            // データに対応する画像情報を取得
            this.chart._riverImageInfo = RiverImageInfo.RIVER_IMAGE_INFO_MAP[this.data[0].observatoryId];
            var yAxisRange = this._calcYAxisRange();
            this.chart.addAxis('y', {
                min: yAxisRange.min,
                max: yAxisRange.max,
                vertical: true,
                fixLower: 'minor',
                fixUpper: 'minor'
            });
            this.chart.addAxis('x', {
                majorTickStep: 1,
                natural: true
            });
            // 時間軸
            ChartCommon.addDataTimestampXAxis(this.chart, this.dataAll);
            // X軸のラベルを設定
            // this.chart.axes.x.opt.labels = this.getChartLabelData();

            // 各データの描画
            // 河川水位
            if (this.data[0].riverLevel) {
                this.chart.addSeries('河川水位',
                    this.getChartData('riverLevel'), {
                    plot: 'riverLevelChart',
                    stroke: {
                        color: '#00FFFF'
                    }
                });
            } else {
                this.chart.removeSeries('河川水位');
            }
        	// 水位
            this.chart.addSeries('水位',
                this.getChartData2('riverLevel'), {
                    plot: 'quantities',
                    marker: ChartConfig.MARKER_SHAPE,
                    stroke: {color: ChartConfig.LINE_COLOR},
                    fill: ChartConfig.LINE_COLOR
            });
            //チェックによる非表示、Gridとの対応
            this.riverLevelTooltip = new Tooltip(this.chart, 'quantities');
            this.riverLevelMagnify = new Magnify(this.chart, 'quantities');
            // 氾濫危険水位データがある場合に追加
            if (this.data[0].riverLevelDanger) {
                this.chart.addSeries('氾濫危険水位',
                    this.getChartData('riverLevelDanger'), {
                    plot: 'Level',
                    stroke: {
                        color: '#AA00AA'
                    }
                });
            } else {
                this.chart.removeSeries('氾濫危険水位');
            }
            // 避難判断水位データがある場合に追加
            if (this.data[0].riverLevelEvacuate) {
                this.chart.addSeries('避難判断水位',
                    this.getChartData('riverLevelEvacuate'), {
                    plot: 'Level',
                    stroke: {
                        color: '#FF2800'
                    }
                });
            } else {
                this.chart.removeSeries('避難判断水位');
            }
            // 氾濫注意水位データがある場合に追加
            if (this.data[0].riverLevelCaution) {
                this.chart.addSeries('氾濫注意水位',
                    this.getChartData('riverLevelCaution'), {
                    plot: 'Level',
                    stroke: {
                        color: '#FFC000'
                    }
                });
            } else {
                this.chart.removeSeries('氾濫注意水位');
            }
            // 水防団待機水位データがある場合に追加
            if (this.data[0].riverLevelStandby) {
                this.chart.addSeries('水防団待機水位',
                    this.getChartData('riverLevelStandby'), {
                    plot: 'Level',
                    stroke: {
                        color: '#FFFB00'
                    }
                });
            } else {
                this.chart.removeSeries('水防団待機水位');
            }
        },

        /**
         * グラフのデータを配列にして返す
         * @param   keyName グラフデータのプロパティ名
         * @returns グラフデータ用配列
         */
        getChartData: function(keyName) {
            if (!this.data) {
                return [null, null];
            }
            var chatData = [];
            array.forEach(this.data, function(item, i){
                var value = null;
                if(item[keyName] && parseInt(item[keyName], 10) >= 0){
                    value = isFinite(item[keyName]) ? item[keyName] : null;
                }
                chatData.push({
                    x: i + 1,
                    y: value
                });
            });
            return chatData;
        },
    	
        /**
         * グラフデータを返す
         * @param   keyName グラフデータのプロパティ名
         * @returns グラフデータ用配列
         */
        getChartData2: function(keyName) {
            if(!this.dataAll){
                return [null, null];
            }
            var chatData = [];
            array.forEach(this.dataAll, function(item, i){
                var value = null;
                if(item[keyName] && parseInt(item[keyName], 10) >= 0){
                    value = isFinite(item[keyName]) ? item[keyName] : null;
                }
                chatData.push({
                    x: i + 1,
                    y: value
                });
            });
            return chatData;
        },

        /**
         * x軸のラベルデータを返す
         */
        getChartLabelData: function() {
            if(!this.dataAll){
                return [null, null];
            }
            var xLabel = [];
            array.forEach(this.dataAll, function(item, i){
                // スペースを改行にする
                var label = {
                    value: i + 1,
                    text: item.dataTimestamp.substr(5, 11).replace(/-/g, '/').replace(/ /g, '<br>')
                };
                xLabel.push(label);
            });
            return xLabel;
        },

        // set('mode', ...) 経由で呼ばれる
        _setModeAttr: function(mode) {
            this._set('mode', mode);
            this.set('title', {
                '10' : '10分',
                '30' : '30分',
                '60' : '時間'
            }[this.mode] + '水位');
        },

        // set('dataAll', ...) 経由で呼ばれる
        _setDataAllAttr: function(dataAll) {
            this._set('dataAll', dataAll);
        }
    });
});
