/**
 * お知らせ情報グリッド
 * @module app/notice/NoticeGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    '../NoticeFormatter',
    './NoticeTypeColumn'
], function(module, declare, IdisGrid, helper, NoticeFormatter, NoticeTypeColumn) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {
        // IdisGrid拡張：行自体にCSSクラスを追加する場合に指定する
        rowClassName: function(item) {
            return item && !item.pubFlg ? 'is-private' : '';
        },

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            helper.buttonColumn('detail', '詳細'),
            helper.column('noticeId', 'ID'),
            helper.column('content', '内容', {formatter: NoticeFormatter.format}),
            NoticeTypeColumn,
            helper.column('pubFlg', '公開状況', {formatMap: {'false': '非表示', 'true': '表示'}}),
            helper.timestampColumn('noticeTimestamp', '通知日時'),
            helper.timestampColumn('crtTimestamp', '登録日時')
        ]
    });
});
