/**
 * 「災害対応共有サイト（市災害対策本部）」画面用のモジュール
 * @module app/disasterInfoShare/DisasterInfoShareListPage
 */
 define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/DisasterInfoShareListPage.html',
    'app/model/RelatedLink',
    'idis/store/IdisRest',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/model/UserInfo',
    'app/model/DisasterInfo',
    'idis/control/Router',
    'idis/view/Loader',
    'app/config',
    'idis/view/page/_PageBase',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/AclButton',
    './DisasterInfoShareDetailGrid',
    './DisasterInfoShareNewsGrid',
    './DisasterInfoShareEmergencyNewsGrid',
    './DisInfoShareDetailDialog',
    'app/model/DisInfoShareDestination'
], function(module, declare, lang, template, relatedLink, IdisRest, Requester, DialogChain, UserInfo, DisasterInfo, Router, Loader, config, _PageBase) {

    return declare(module.id.replace(/\//g, '.'), _PageBase, {
        // 基本クラス
        baseClass: 'idis-Page idis-Page--disasterInfoShare',

        // テンプレート文字列
        templateString: template,

        /**
        * データ格納用オブジェクト
        * @type {module:dstore/Store}
        */
        store: null,

        /**
         * データ登録用オブジェクト
         * @type {module:dstore/Store}
         */
        registerStore: null,

        /**
         * データ更新用オブジェクト
         * @type {module:dstore/Store}
         */
        updateStore: null,

        /**
         * データ削除用オブジェクト
         * @type {module:dstore/Store}
         */
        deleteStore: null,

        /**
         * 災害ID
         * @private
         */
        _disasterId: null,

        /**
         * @type {Object}
         */
        timer: null,

        /**
         * constructor
         */
        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'messageId',
                target: '/api/bbses',
                rangeStartParam: 'offset',
                rangeCountParam: 'count'
            });
            // データ登録用オブジェクト
            this.registerStore = new IdisRest({
                idProperty: 'messageId',
                target: '/api/bbses/register'
            });
            // 連鎖ダイアログを登録
            this.chain = DialogChain.get(this);

            // 災害IDをDisasterInfoから取得
            this._disasterId = DisasterInfo.getDisasterId();
            if(!this._disasterId) {
                this._disasterId = 1;
                console.warn('災害IDが設定させていません。');
            }

            console.debug('現在のログインユーザID：' + UserInfo.getId());
            console.debug('現在の災害ID：' + DisasterInfo.getDisasterId());
        },

        // DOMノードを構築する
        buildRendering: function() {
            this.inherited(arguments);
            this.initNewsGrid();
            this.initEmergencyNewsGrid();
            this.initDetailDialog();
            this.initCityHeadquarterList();
            this.initWardHeadquarterList();
            this.initRelatedLink();
        },

        postCreate: function() {
            this.inherited(arguments);

            this.on('.headquarter-link:click', lang.hitch(this, function (e) {
                var deptId = e.target.id;
                this.onDetailPageLinkClick(deptId);
            }));
        },

        /**
         * 各所属本部画面へのリンクがクリックされた際に呼ばれる。
         */
        onDetailPageLinkClick: function (deptId) {
            // ブラウザーの遷移処理をキャンセル
            // evt.preventDefault();
            // ページへ遷移
            Router.moveTo('disasterInfoShareDetail', {deptId: deptId});
        },

        /**
         * 市災害対策本部一覧リンクを初期化する。
         */
        initCityHeadquarterList: function() {
            var self = this;
            var promise = Requester.get('/api/organizations/depts/dept4DisInfoShare').then(function(data) {
                console.debug('success get dept4DisInfoShare');
                console.log(data);

                var cityHeadquarterListDom = [];
                for (var i=0; i < data.items.length; i++) {
                    if (data.items[i].name.slice(-3) !== '区本部') {
                        cityHeadquarterListDom.push('<li class="four-column-list"><a href="javascript:void(0);" class="headquarter-link" id="' + data.items[i].id + '">' + data.items[i].name + '</a></li>');
                    }
                }
                self.cityHeadquarterList.innerHTML = cityHeadquarterListDom.join('');

            }, function(error) {
                console.log('error get dept4DisInfoShare');
                console.debug(error);
            });
            //ローダーの表示
            Loader.wait(promise);
        },

        /**
         * 区災害対策本部一覧リンクを初期化する。
         */
        initWardHeadquarterList: function() {
            var self = this;
            var promise = Requester.get('/api/organizations/depts/dept4DisInfoShare').then(function(data) {
                console.debug('success get dept4DisInfoShare');
                console.log(data);

                var wardHeadquarterListDom = [];
                for (var i=0; i < data.items.length; i++) {
                    if (data.items[i].name.slice(-3) === '区本部') {
                        wardHeadquarterListDom.push('<li class="four-column-list"><a href="javascript:void(0);" class="headquarter-link" id="' + data.items[i].id + '">' + data.items[i].name + '</a></li>');
                    }
                }
                self.wardHeadquarterList.innerHTML = wardHeadquarterListDom.join('');

            }, function(error) {
                console.log('error get dept4DisInfoShare');
                console.debug(error);
            });
            //ローダーの表示
            Loader.wait(promise);
        },

        /**
         * 関連リンクを初期化する。
         */
        initRelatedLink: function() {
            var relatedLinkDom = [];
            for (var i=0; i < relatedLink.length; i++) {
                relatedLinkDom.push('<li class="four-column-list"><a href="' + relatedLink[i].url + '">' + relatedLink[i].name + '</a></li>');
            }
            this.relatedLink.innerHTML = relatedLinkDom.join('');
        },

        /**
         * 緊急情報グリッドを初期化する。
         */
        initEmergencyNewsGrid: function() {
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名

            // 編集ダイアログを開く
            this.emergencyNewsGrid.on('a.title-link:click', lang.hitch(this, function(evt) {
                var messageId = evt.target.id.split('--')[1];
                this.showNewsDetailPage(messageId);
            }));

            // this.emergencyNewsGrid.on('fileDownloadButtonClick', lang.hitch(this, function(evt) {
            //     this.onFileDownload(evt.item);
            // }));

            this.updateEmergencyNewsGridQuery();
        },

        /**
         * 緊急情報グリットを更新する。
         */
        updateEmergencyNewsGridQuery: function() {
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();

            // 災害ID
            filter = filter.eq('disasterId', this._disasterId);

            // ログインユーザの組織コードを取得する
            // var orgCd = (UserInfo.getOrganization().unitCd ? 'U' + UserInfo.getOrganization().unitCd :
            // UserInfo.getOrganization().sectCd ? 'S' + UserInfo.getOrganization().sectCd :
            // UserInfo.getOrganization().deptCd ? 'D' + UserInfo.getOrganization().deptCd : '');

            // filter = filter.eq('destinationId', orgCd);

            filter = filter.eq('emergencyNewsFlg', '1');

            var collection = this.store.filter(filter);

            this.emergencyNewsGrid.set('collection', collection);

            this.emergencyNewsGrid.on('attachFileLinkClick', lang.hitch(this, function(evt) {
                var attachFilePath = evt.item.attachFilePath.replace('out', 'data');
                var url = location.protocol + '//' + location.hostname + ':' + location.port + '/' + attachFilePath;
                this.downloadFile(url, evt.item.attachFileName);
            }));

            //定期更新処理
            this.timer = setTimeout(lang.hitch(this, function() {
                this.updateEmergencyNewsGridQuery();
            }),600*1000);
        },

        /**
         * 新着情報グリッドを初期化する。
         */
         initNewsGrid: function() {
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名

            // 編集ダイアログを開く
            // this.newsGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
            //     this.showDetailPage(evt.item);
            // }));
            this.newsGrid.on('a.title-link:click', lang.hitch(this, function(evt) {
                var messageId = evt.target.id.split('--')[1];
                this.showNewsDetailPage(messageId);
            }));

            this.newsGrid.on('fileDownloadButtonClick', lang.hitch(this, function(evt) {
                this.onFileDownload(evt.item);
            }));

            this.updateNewsGridQuery();
        },

        /**
         * 新着情報グリットを更新する。
         */
        updateNewsGridQuery: function() {
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();

            // 災害ID
            filter = filter.eq('disasterId', this._disasterId);

            // ログインユーザの組織コードを取得する
            // var orgCd = (UserInfo.getOrganization().unitCd ? 'U' + UserInfo.getOrganization().unitCd :
            // UserInfo.getOrganization().sectCd ? 'S' + UserInfo.getOrganization().sectCd :
            // UserInfo.getOrganization().deptCd ? 'D' + UserInfo.getOrganization().deptCd : '');

            // filter = filter.eq('destinationId', orgCd);

            filter = filter.eq('newInformationFlg', '1');

            var collection = this.store.filter(filter);

            this.newsGrid.set('collection', collection);

            this.newsGrid.on('attachFileLinkClick', lang.hitch(this, function(evt) {
                var attachFilePath = evt.item.attachFilePath.replace('out', 'data');
                var url = location.protocol + '//' + location.hostname + ':' + location.port + '/' + attachFilePath;
                this.downloadFile(url, evt.item.attachFileName);
            }));

            //定期更新処理
            this.timer = setTimeout(lang.hitch(this, function() {
                this.updateNewsGridQuery();
            }),600*1000);
        },

        /**
         * 詳細ダイアログを初期化する
         */
         initDetailDialog: function() {
            var dialog = this.detailDialog;
            var page = dialog.getChildren()[0];

            // 登録ダイアログのsendイベントを受け取る
            page.on('send', lang.hitch(this, function(evt) {
                this.chain.confirmAdd(function(chain) {
                    // OKが押された場合
                    var sendData = evt.value;

                    // 登録処理と共にローディング表示
                    Loader.wait(this.registerStore.add(sendData)).then(lang.hitch(this, function() {
                        // 成功時
                        chain.infoComplete(lang.hitch(this, function() {
                            this._buttonFlg = false;
                            dialog.hide();
                            chain.hide();
                            this.updateNewsGridQuery();
                            this.updateEmergencyNewsGridQuery();
                        }));
                    }, function(err) {
                        // 失敗時
                        chain.infoError(err);
                    }));
                });
            }));

            // 詳細ダイアログのupdateSendイベントを受け取る
            page.on('update', lang.hitch(this, function(evt) {
                this.chain.confirmPut(lang.hitch(this, function(chain) {
                    // OKが押された場合

                    // データ更新用オブジェクト
                    this.updateStore = new IdisRest({
                        idProperty: 'messageId',
                        target: '/api/bbses/update'
                    });
                    // 更新処理と共にローディング表示
                    Loader.wait(this.updateStore.put(evt.value)).then(function() {
                        // 成功時
                        chain.infoComplete(lang.hitch(this, function() {
                            this._buttonFlg = false;
                            dialog.hide();
                            chain.hide();
                            this.updateNewsGridQuery();
                            this.updateEmergencyNewsGridQuery();
                        }));
                    }, function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                }));
            }));

            // 詳細ダイアログのremoveイベントを受け取る
            page.on('remove', lang.hitch(this, function(evt) {
                this.chain.confirmDel(lang.hitch(this, function(chain) {
                    // OKが押された場合

                    // データ削除用オブジェクト
                    this.deleteStore = new IdisRest({
                        target: '/api/bbses/delete'
                    });
                    // 削除処理と共にローディング表示
                    Loader.wait(this.deleteStore.remove(evt.value.messageId)).then(function() {
                        // 成功時
                        chain.infoComplete(lang.hitch(this, function() {
                            this._buttonFlg = false;
                            dialog.hide();
                            chain.hide();
                            this.updateNewsGridQuery();
                            this.updateEmergencyNewsGridQuery();
                        }));
                    }, function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                }));
            }));

            page.on('cancel', lang.hitch(this, function() {
                this.updateNewsGridQuery();
                this.updateEmergencyNewsGridQuery();
            }));
        },

        /**
         * 新着情報詳細ダイアログを開く
         */
         showNewsDetailPage: function(messageId) {
            // ストアからデータを取得し、詳細ダイアログを表示する
            var fil = this.storeMessage = new IdisRest({
                target: '/api/bbses/message/' + messageId
            });

            fil.fetch().then(lang.hitch(this, function(formItem){
                this.innerDetailDialog.setContent(formItem);
            }));

            // this.innerDetailDialog.updateDialog(item);
            this.detailDialog.show();
            // 新着情報の詳細ダイアログの場合、編集リンクを非表示とする
            this.innerDetailDialog.hideEditButton();
        },

        /**
         * ファイル共有サイトへ新規タブで遷移する。
         */
        onFileShareClick: function() {
            window.open(config.fileShare.url, '_blank');
        }
    });
});
