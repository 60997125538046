define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function(module, declare, lang, locale, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--broadnotify',

        /**
         * 各列の定義
         * @type {Object[]}
         */

        columns: [
            // ボタン
            helper.buttonColumn('detail', '詳細'),
            helper.column('senderName', '送信者'),
            {field: 'sendTimestamp', label: '通知日時', sortable: true,
                formatter: lang.hitch(this, function(item){
                    if(item) {
                        return locale.format(new Date(item));
                    }
                    return '';
                })
            },
            helper.column('notifyTypeName', '通知種別'),
            helper.column('title', 'タイトル'),  
            {field: 'necessityResponse', label: '受確応答要否', sortable: false,
                formatter: lang.hitch(this, function(item){
                 var type = null;
                 switch(item) {
                     case '0':
                        type = '不要';
                        break;
                     case '1':
                        type = '要';
                        break;
                 }
                 return type;
            })},
            helper.column('broadnotifyShareTotal', '通知先', {sortable: false}),
            {field: 'broadnotifyShareSuccess', label: '通知完了', sortable: false,
                className: function(item) {
                    if(!item){
                        return;
                    }
                    var isfaild = item.broadnotifyShareTotal !== item.broadnotifyShareSuccess;
                    return  isfaild ? 'is-failed': '';
                },
                formatter: function(value) {
                    return value;
                }
            },
            helper.column('responseStatusTotal', '受信確認済', {sortable: false})
        ]
    }
    );
});
