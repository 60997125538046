/**
 * お知らせ登録ダイアログ内用。
 * @module app/view/NoticeRegisterPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/text!./templates/NoticeRegisterPage.html',
    'idis/view/page/_PageBase',
    './_LinkInsertMixin',
    // 以下、変数から参照されないモジュール
    'dijit/form/Form',
    'dijit/form/SimpleTextarea',
    'dijit/form/ValidationTextBox',
    'idis/view/form/Button',
    'idis/view/form/CheckGroup',
    'idis/view/form/RadioGroup',
    'idis/view/form/DateTimeInput',
    './form/NoticeTypeSelect'
], function(module, declare, template, _PageBase, _LinkInsertMixin) {
    /**
     * お知らせ登録ダイアログ内の画面。
     * @class NoticeRegisterPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), [_PageBase, _LinkInsertMixin],
        /** @lends module:idis/view/page/NoticeRegisterPage~NoticeRegisterPage# */ {
        // テンプレート文字列
        templateString: template,

        // テンプレートHTML内内でformに対し、data-dojo-attach-event="onSubmit:onSubmit"でこのメソッドを紐付け
        // 登録ボタンをtype="submit"にしているので、ボタン押下時にフォームがsubmitされて呼ばれる
        onSubmit: function() {
            try {
                if (this.form.validate()) {
                    var value = this.form.get('value');
                    // 公開フラグはチェック状態（CheckGroupの配列長が1）かどうかで判断
                    value.pubFlg = !!value.pubFlg.length;
                    // 実際の登録処理はNoticePage側でregisterイベントを監視しているメソッドが実施
                    this.emit('register', {value: value});
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        }
    });
});

