/**
 * 組織選択用入力パーツ
 * @module idis/view/form/OrganizationSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/string',
    'dijit/form/TextBox',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest',
    'idis/view/form/TreeSelector',
], function (module, declare, lang, domStyle, dStr, TextBox, CacheStoreModel, CacheTreeRest, TreeSelector) {
    /**
     * 組織選択用パーツ。
     * @class OrganizationSelector
     * @extends module:idis/view/form/TreeSelector~TreeSelector
     */
    return declare(module.id.replace(/\//g, '.'), TreeSelector,
        /** @lends module:idis/view/tree/OrganizationSelector~OrganizationSelector# */ {
            // 選択ダイアログのタイトル
            title: '組織選択',
            // ツリーの取得レベル（MUnit, MSect, MDeptのどちか）
            level: 'MDept',

            // ツリー・モデル
            model: null,

            // リフレッシュができるかどうか
            _canRefresh: false,

            // ページ初期表示にmodelがnullだとエラーが出るためmodelを生成
            constructor: function () {
                this._initModel();
            },

            _initModel: function () {
                this.inherited(arguments);
                var query = '?level=' + this.level;
                this.model = new CacheStoreModel({
                    store: new CacheTreeRest({
                        target: '/api/organizations/selectLevel' + query
                    })
                });
            },

            // DOMを構築する
            buildRendering: function () {
                this.inherited(arguments);
                // 検索用入力要素を設置
                this.filterInput = new TextBox({
                    placeHolder: this.placeHolder,
                    maxLength: 128
                });
                this.filterInput.placeAt(this.searchControlNode);
                this.own(this.filterInput.on('keyup', lang.hitch(this, 'onFilterInputChange')));
            },

            /**
            * 選択した値の設定
            */
            _setValueAttr: function () {
                // TreeChecker本来の処理
                this.inherited(arguments);
                // 文字列省略をオーバーライド
                domStyle.set(this.status, {
                    'max-width': '20em',
                    'white-space': 'nowrap',
                    'overflow': 'hidden',
                    'text-overflow': 'ellipsis'
                });

            },

            /**
             * 検索用入力要素入力時。
             */
            onFilterInputChange: function () {
                // 入力値を取得
                var value = dStr.trim(this.filterInput.get('value'));
                if (value) {
                    // ラベル名が入力値を含むものでフィルターし、ツリーを展開する
                    this.model.setFilter(function (item, isLeaf, model) {
                        return isLeaf && (model.getLabel(item) || '').indexOf(value) !== -1;
                    }).then(lang.hitch(this, function () {
                        this.tree.expandAll();
                    }));
                } else {
                    // 入力が空の場合は全要素を表示してツリーを閉じる
                    this.model.setFilter().then(lang.hitch(this, function () {
                        this.tree.collapseAll();
                    }));
                }
            }
        });
});

