/**
 * 安否確認メール管理画面用モジュール。
 *
 * @module app/safety/mail/SafetyMailAdminPage
 */
define(['module',
        'dojo/_base/declare',
        'dojo/_base/lang',
        'dojo/text!./templates/SafetyMailAdminPage.html',
        'idis/control/Router',
        'idis/view/page/_PageBase',
        'idis/view/Loader',
        'idis/view/dialog/DialogChain',
        'idis/store/IdisRest',
        'idis/service/Requester',
        // 以下、変数で受けないモジュール
        'dijit/Dialog',
        'dijit/form/Form',
        'dijit/form/Select',
        'dijit/form/TextBox',
        'dijit/form/CheckBox',
        'dijit/layout/BorderContainer',
        'dijit/layout/ContentPane',
        'idis/view/form/Button',
        'idis/view/form/DateInput',
        'idis/view/form/DateTimeInput',
        'app/safety/auth/view/form/AclButton',
        'app/view/form/DistributionGroupSelector',
        'app/safety/mail/SafetySendMailDialog',
        'app/safety/mail/SafetySendMailDialog',
        'app/safety/mail/SafetyMailAdminGrid'],
    function(module, declare, lang, template, Router, _PageBase, Loader, DialogChain, IdisRest, Requester) {
        /**
         * 安否確認メール管理画面
         *
         * @class SafetyMailAdminPage
         * @extends module:idis/view/page/_PageBase~_PageBase
         */
        return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app_safety/mail/SafetyMailAdminPage~SafetyMailAdminPage# */
        {

            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--safety',

            // グリッド用データ格納オブジェクト
            store: null,

            /**
             * 変数初期化メソッド
             */
            constructor: function() {
                // 安否確認メール管理タブ用データ格納用オブジェクト
                this.store = new IdisRest({
                    idProperty: 'distributionId',
                    target: '/api/safety/mails'
                });
                // ダイアログ連鎖を登録
                // 引数に与えたウィジェットのthis.ownを呼び出し、
                // ウィジェットが破棄された際にダイアログ連鎖が破棄されるようになる
                this.chain = DialogChain.get(this);
            },

            /**
             * DOMノードを生成するためのメソッド
             */
            buildRendering: function() {
                this.inherited(arguments);
                // 自分が削除された時は関連ダイアログも削除する
                this.initMailDialog();
                this.own(this.mailDialog);
                this.initSafetySummaryGrid();
            },

            /**
             * 安否確認メールタブ用
             * 登録ダイアログのformが投稿された際の動作を設定する。
             */
            initMailDialog: function() {
                // 登録ダイアログの最初の子要素が登録画面
                var dialog = this.mailDialog;
                var page = dialog.getChildren()[0];
                page.on('register', lang.hitch(this, function(evt) {
                    // 追加確認ダイアログを表示
                    this.chain.confirmAdd(function(chain) {
                      // OKが押された場合
                      // 追加処理と共にローディング表示
                      var promise = this.store.add(evt.value).then(function() {
                        // 成功時（POST結果はグリッドが自動的に反映）
                        // 登録ダイアログを閉じる
                        dialog.hide();
                        // 完了ダイアログを表示
                        chain.infoComplete();
                      }, function(err) {
                        // 失敗時
                        chain.infoError(err);
                      });
                      Loader.wait(promise);
                  });
                }));
            },

            /**
             * 安否確認メール管理タブ用
             * グリッドを初期化する。
             */
            initSafetySummaryGrid: function() {
                this.safetySummaryGrid.set('collection',this.store.filter());
                // グリッドの一覧ボタンクリック時の動作を設定する
                // helper.buttonColumnでフィールド名に指定した'list'と'ButtonClick'の結合がボタンクリック時のイベント名
                this.safetySummaryGrid.on('overviewButtonClick', lang.hitch(this, function(evt){
                    // 安否確認状況概況画面に遷移する
                    this.moveStatusOverviewPage(evt.item);
                }));
                this.safetySummaryGrid.on('deleteButtonClick', lang.hitch(this, function(evt){
                    // helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
                    this.chain.confirmDel('送信履歴', function(chain) {
                        // 削除リクエストを実施
                        Loader.wait(Requester.del('/api/safety/mails/delete/' +
                            evt.item.distributionId)).then(lang.hitch(this, function() {
                            // 削除成功時は完了ダイアログを表示
                            chain.infoComplete(lang.hitch(this, function() {
                                chain.hide().then(lang.hitch(this, function() {
                                    // 一覧を更新する
                                    this.initSafetySummaryGrid();
                                }));
                            }));
                        }, function(err) {
                            // 削除失敗時
                            chain.infoError(err);
                        }));
                    },function(chain) {
                        chain.hide();
                    });  
                }));
            },

            /**
             * 検索条件に合致する配信情報を表示する。
             */
            onMailSearch: function() {
                try {
                    if (this.form.validate()) {
                        // 入力値が正常ならグリッドの検索条件を更新
                        this.updateGridQuery(this.form.get('value'));
                    }
                } catch (e) {
                    console.error(e);
                }
                return false;
            },

            /**
             * 安否確認メール管理タブ用の検索
             */
            updateGridQuery: function(value) {
                // 入力値を元にグリッド用フィルターを作成
                var filter = new this.store.Filter();
                // 各検索条件に値があればfilterにその値を設定する
                // グループ（セレクタよりグループコードを取得）
                if(value.distGroup){
                    filter = filter.eq('groupCd',value.distGroup);
                }
                // 通知日時
                if (value.fromDate) {
                    filter = filter.eq('safetyMailFrom', value.fromDate.getTime());
                }
                if (value.toDate) {
                    filter = filter.eq('safetyMailTo', value.toDate.getTime());
                }
                // filterに対応するcollectionを取得
                var collection = this.store.filter(filter);
                // collectionをグリッドにセットする（サーバーにリクエストされる）
                this.safetySummaryGrid.set('collection', collection);
            },

            /**
             * メール送信ダイアログを表示する。
             */
            showSendMailDialog: function() {
                this.mailDialog.show();
            },

            /**
             * テンプレート用ダイアログを表示する。
             */
            showMailTempDialog: function() {
                this.mailTempDialog.show();
            },

            //テンプレート管理ボタンを押下したときの挙動
            moveMailTempPage: function() {
                console.log('[テンプレート管理]ボタンがクリックされました。');
                // テンプレート管理画面へ遷移
                Router.moveTo('safety/mailTemp');
            },

            //招集状況管理の概況ボタンを押下したときの挙動
            moveStatusOverviewPage: function(item) {
                console.log('[概況]ボタンがクリックされました。');
                // 概況画面へ遷移
                Router.moveTo('safety/statusOverview',{
                    mid : item.distributionId,
                    gcd : item.groupCd
                });
            },

            /**
             * ウィンドウの幅に合わせてBorderContainerをリサイズし、タブ内のレイアウトを整理する。
             * (startup同様、画面描画時に呼ばれる)
             * @param changeSize
             * @param resultSize
             */
            resize: function(changeSize, resultSize) {
                // 中のContentPaneも追随してリサイズするため、レイアウト崩れを防止できる。
                this.borderContainer.resize(changeSize, resultSize);
            },

            /**
             * 帳票出力
             */
            outputListExcel: function() {
                console.log('[帳票出力]ボタンがクリックされました。');
            }
        });
    });
