/**
 * 安否確認ログイン画面用モジュール
 * @module app/safety/SafetyLoginPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/dom-style',
    'dojo/io-query',
    'dojo/text!./templates/SafetyLoginPage.html',
    'idis/control/Locator',
    'idis/view/page/_PageBase',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/form/Form',
    'dijit/form/ValidationTextBox',
    'idis/view/form/Button'
], function(module, declare, domStyle, ioQuery, template, Locator, _PageBase) {
    /**
     * ログイン画面用ウィジェット
     * @class SafetyLoginPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/login/view/SafetyLoginPage~SafetyLoginPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--login',

        buildRendering: function() {
            this.inherited(arguments);
            // 戻り先を設定（現在のURLのpath部分）
            var backPath = Locator.getHref().split('?')[0];
            this.backPathInput.set('value', backPath);
            // URLにログイン失敗フラグが含まれている場合はエラー・メッセージを表示する
            if (Locator.getQuery().f) {
                domStyle.set(this.errorMessageNode, 'display', '');
            }
        },

        /**
         * TODO:ログイン時にID・passのチェックを行うように修正
         *
         * フォーム投稿時に呼び出される。
         * @returns {boolean} formイベントをキャンセルする場合は明示的にfalseを返す
         **/
        onSubmit: function() {
            // 入力チェックが成功した場合だけ通す
            if (!this.form.validate()) {
                return false;
            }
            // 開いているのがログイン画面以外のURLなら戻り先情報として渡す
            var query = Locator.getQuery();
            if (query.p !== 'login') {
                // 失敗フラグがあれば除去
                delete query.f;
                // hiddenフィールドへ設定
                this.backQueryInput.set('value', ioQuery.objectToQuery(query));
            }
        }
    });
});
