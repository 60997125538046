define([
    'module',
    'dojo/_base/declare',
    'dojo/date/locale',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function(module, declare, locale, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            // helper.column('id', 'id'),
            // helper.column('trafficActionId', 'trafficActionId'),
            // helper.column('delFlg', 'delFlg'),
            helper.column('actionTimestamp', '日時', {
                formatter: function(value, item) {
                    var date = new Date(item.actionTimestamp);
                    return locale.format(date);
                }
            }),
            helper.column('dmgSummary', '被災状況の概要'),
            helper.column('actionDone', 'これまでとった措置'),
            helper.column('actionPlan', '今後の対応予定'),
            // ボタン列はbuttonColumnメソッドで生成、グリッドのdetailButtonClickイベントが発行されるようになる
            helper.buttonColumn('detail', '編集')
        ]
    });
});
