/**
 * 避難所 ファイル読み込みダイアログ用モジュール。
 * @module app/safety/employee/FileUploadDialog
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/request/iframe',
    'dojo/text!./templates/ShelterFileUploadDialog.html',
    'dstore/RequestMemory',
    'idis/control/Router',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/InfoDialog',
    'idis/view/Loader',
    'idis/view/page/_PageBase',
    'app/model/DisasterInfo',
    // 以下、変数で受けないモジュール
    'dojox/form/Uploader',
    'dijit/form/Form',
    'idis/view/form/Button',
    'app/view/page/ErrorMessageDialogForXoblos'
], function(module, array, declare, lang, iframe, template, RequestMemory, Router, Requester,
    DialogChain, InfoDialog, Loader, _PageBase, DisasterInfo) {
    /**
     * 避難所ファイルアップロードダイアログ
     * @class ShelterFileUploadDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
            /** @lends module:app/view/page/DamageReportPage~DamageReportPage# */ {

        // テンプレート文字列
        templateString: template,

        baseClass: 'idis-Page--safety-dialog',

        _fileId: null,

        infoDialog: null,

        _disasterId: null,

        SHELTER_REPORT_REQID: 'SHELTER_LIST_IN',

        constructor: function() {
            // 入力帳票の各種パスを取得する。
            this.storepath = new RequestMemory({
                idProperty: 'reqid',
                target: '/data/report/xoblos-config.json'
            });
            // 連鎖ダイアログを登録
            this.chain = DialogChain.get(this);
            // 災害IDを取得
            this._disasterId = DisasterInfo.getDisasterId();
            if(!this._disasterId) {
                this._disasterId = 1;
                console.warn('災害IDが設定させていません。');
            }
        },

        buildRendering: function() {
            this.inherited(arguments);
            this.own(this.errorMessageDialogForXoblos);
        },

        /**
         * ファイル読み込み実行
         */
        onSubmit: function() {
            if (!this.validate()) {
                return false;
            }
            var reqid = this.SHELTER_REPORT_REQID;

            var reqParams = {};
            this.storepath.fetch().then(lang.hitch(this, function(data) {
                data.some(function(data) {
                    if (reqid === data.reqid) {
                        reqParams = data;
                        return true; //break;
                    }
                });
            })).then(lang.hitch(this, function() {
                // ファイルをサーバーにアップロードするAPIのURL
                var url = '/api/xoblos/uploadReport' +
                        '?reqid=' + reqid +
                        '&disid=' + this._disasterId +
                        '&path=' + reqParams.pathweb;

                iframe.post(url, {
                    form: this.form.id,
                    handleAs: 'json'
                }).then(lang.hitch(this, function(ret) {
                    // アップロードしたファイルパスとエラーメッセージjson、
                    // エラー箇所を強調表示したファイルの出力先を指定してxoBlosをコールする。
                    var promise = Requester.post('/api/xoblos/upload',{
                        data: {
                            reqid: reqid,
                            disid: this._disasterId,
                            filename: ret.fileName
                        }
                    })
                    // // ローカル検証用
                    // var promise = Requester.get('/data/xoblos/shelter_list.json')
                        .then(lang.hitch(this, function(data) {
                        // ResultCode=0以外（xoBlosエラー）の場合にはエラーをスローする。
                        // エラー処理の中で、xoBlosエラーダイアログを表示する。
                        if (data.ResultCode !== 0){
                            var err = new Error('xoblos failed');
                            err.data = data;
                            throw err;
                        } else {
                            data.data.disasterId = this._disasterId;
                            var regapi = reqParams.regapi;
                            return  Requester.post(regapi, {
                                data:{
                                    // 返ってきたJSONにdisasterIdをセット
                                    disid: this._disasterId,
                                    // ResultCodeが0の場合は、帳票に含まれる避難所の一覧がレスポンスのdataに入っている。
                                    items: data.data
                                }
                            });
                        }
                    })).then(lang.hitch(this, function(data) {
                        // 成功した場合は完了ダイアログを表示する。
                        if(data.nonExistentShelter.length === 0){
                            this.chain.infoComplete(function() {
                                // 確認ダイアログを閉じる
                                this.chain.hide();
                                // 親のダイアログを閉じる。
                                this.getParent().hide();
                                this.emit('upload');
                            });
                        } else {
                            var message = '';
                            message += 'ファイル読み込みを完了しました。<br>';
                            message += 'ただし下記は、入力情報に誤りがあるため、登録を行いませんでした。<br><br>';
                            message += '<table style="margin-left: auto;margin-right: auto">';
                            message += '<tr><th style="text-align:center"><b>避難所</b></th><tr>';
                            array.forEach(data.nonExistentShelter, function(errShelterList){
                                message += '<tr><td>' + errShelterList;
                                message += '</td></tr>';
                            });
                            message += '</table>';

                            var title = '完了';
                            // ダイアログにメッセージを表示
                            this.chain.info(message, title, function(){
                                // 確認ダイアログを閉じる
                                this.chain.hide();
                                // 親のダイアログを閉じる。
                                this.getParent().hide();
                                this.emit('upload');
                            });
                        }
                    }), lang.hitch(this, function(error) {

                        // 失敗の場合、引数にデータがあれば、xoBlos用のエラーダイアログを表示する。
                        if (error.data) {
                            // エラーダイアログに結果コードを渡す
                            var errPage = this.errorMessageDialogForXoblos.getChildren()[0];
                            errPage.initErrorMessageDialogForXoblos(
                                error.data.ResultCode,
                                reqParams.errpathweb + this._disasterId + '/' + ret.fileName);
                            this.errorMessageDialogForXoblos.show();
                            this.attachFile.reset();
                        } else {
                            // 引数にエラーがなければ、通常のエラーダイアログを表示する。
                            this.chain.infoError(error, function () {
                                this.chain.hide();
                                // 親のダイアログを閉じる。
                                this.getParent().hide();
                                this.attachFile.reset();
                                Router.moveTo('shelter');
                            });
                        }
                    }));
                    // ローダーの表示
                    Loader.wait(promise);
                }));
            }));
        },

        uploadError: function(ret, error) {
            // 失敗の場合、引数にデータがあれば、xoBlos用のエラーダイアログを表示する。
            if(error.data) {
                // エラーダイアログに結果コードを渡す
                var errPage = this.errorMessageDialogForXoblos.getChildren()[0];
                errPage.initErrorMessageDialogForXoblos(
                    error.data.ResultCode,
                    this.SHELTER_REPORT_ERRPATHWEB + this.SHELTER_REPORT_REQID + '/' +
                            this._disasterId + '/' + ret.fileName,
                    ret.fileName);
                this.errorMessageDialogForXoblos.show();
                this.attachFile.reset();
            } else {
                // 引数にエラーがなければ、通常のエラーダイアログを表示する。
                this.chain.infoError(function() {
                    this.chain.hide();
                    // 親のダイアログを閉じる。
                    this.getParent().hide();
                    Router.moveTo('shelter');
                });
            }
        },

        /**
        * 入力チェック
        */
        validate: function() {
            //ファイルが空の場合は処理を中断
            if(this.attachFile.getFileList().length === 0) {
            InfoDialog.show('入力チェック', 'ファイルが選択されていません');
            return false;
            }
            return true;
        },

        /**
        *報告書読み込みで呼ばれる
            **/
        loadFile: function() {
                //ファイルが空の場合は処理を中断
                if(this.attachFile._files.length === 0) {
                    return;
                }

                // エクセルファイル以外の場合はエラー
                var file = this.attachFile._files[0];
                if(file.name.indexOf('.xls') === -1 && file.name.indexOf('.xlsx') === -1 &&
                file.name.indexOf('.xlsm') === -1) {
                    if (!this.infoDialog) {
                        this.infoDialog = new InfoDialog({
                            title : 'エラー',
                            content : 'エクセルファイルを選択してください。'
                        });
                    }
                    this.infoDialog.show();
                    this.infoDialog=null;
                    this.attachFile.reset();
                    return;
                }

                var form = new FormData();
                form.append('report', this.attachFile._files[0]);

            // ファイル名をyyyymmddhhssに設定。このファイル名はimport用APIをｊコールするときにも使う。
            var now = new Date();
            this._fileId = now.getFullYear() + this.padZero(now.getMonth() + 1) +
                this.padZero(now.getDate()) + this.padZero(now.getHours()) +
                this.padZero(now.getMinutes()) + this.padZero(now.getSeconds());

                // ファイルがonloadされたときにサーバーに一時保存する
                var promise = iframe.post('/api/upload/uploadFile?fileId=' +
                this._fileId , {
                    form: this.form.id,
                    handleAs: 'json'
                });
                //ローダーの表示
                Loader.wait(promise);
            },

        // 日付をゼロパディング
        padZero : function(num){
            var result;
            if (num < 10) {
                result = '0' + num;
            } else {
                result = '' + num;
            }
            return result;
        }
    });
});
