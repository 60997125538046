/**
 * 安否確認メール概況グリッド
 * @module app/safety/mail/SafetyMailOverviewlGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom',
    'dijit/registry',
    'dijit/form/CheckBox',
    'dgrid/Selection',
    'dgrid/Selector',
    'dgrid/Keyboard',
    'dstore/Memory',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function(module, declare, lang, dom, registry, CheckBox, Selection, Selector, Keyboard, Memory, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), [IdisGrid, Selection, Selector, Keyboard], {

        /**
         * 各列の定義
         * @type {Object[]}
         */

        selectionMode: 'none',
        allowSelectAll: true,
        collection: this.store,
        // SelectorのallowSelectAllオプションがエラーとなるので、
        // 仮の値としてメモリーストアをおく
        constructor: function() {
            this.collection = new Memory();
        },
        columns: [
            helper.buttonColumn('list', '一覧'),
                {
                    label: '部署',
                    field: 'department',
                    children: [{
                        field: 'parentName',
                        label: '部局',
                        sortable: false
                    }, {
                        field: 'divisionName',
                        label: '課',
                        sortable: false
                    }, {
                        field: 'total',
                        label: 'グループ内職員総数'
                    }]},
                    {
                        label: '安否状況',
                        field: 'safetyStatus',
                        children: [
                            helper.column('notYet', '返信無',
                          {
                               className: function(item){
                                   // ヘッダーの場合はclassNameを返さない
                                   if(!item){
                                     return;
                                   }
                                   var className = '';
                                   className = (item.notYet > 0 ? 'is-no-reply' : '');
                                   return className;
                               }
                            }),
                        {
                            field: 'replied',
                            label: '返信有'
                        }, {
                            field: 'confirmed',
                            label: '確認済'
                        }]}
        ]
    });
});
