/**
 * テンプレート新規登録ダイアログ用。
 * @module app/provide/ProvideTempRegisterDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/ProvideTempRegisterDialog.html',
    'idis/view/page/_PageBase',
    // 以下、変数から参照されないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/form/Form',
    'dijit/form/SimpleTextarea',
    'dijit/form/ValidationTextBox',
    'idis/view/form/DateTimeInput',
    'idis/view/form/Button',
    'idis/view/form/CheckGroup',
    'idis/view/form/RadioGroup'

], function(module, declare, lang, template, _PageBase) {
    /**
     * お知らせ登録ダイアログ内の画面。
     * @class ProvideTempRegisterDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), [_PageBase],
        /** @lends module:idis/view/page/ProvideTempRegisterDialog~ProvideTempRegisterDialog# */ {
        // テンプレート文字列
        templateString: template,

        // テンプレートHTML内内でformに対し、data-dojo-attach-event="onSubmit:onSubmit"でこのメソッドを紐付け
        // 登録ボタンをtype="submit"にしているので、ボタン押下時にフォームがsubmitされて呼ばれる
        onSubmit: function() {
            try {
                if (this.form.validate()) {
                    var value = this.form.get('value');
                    // 実際の登録処理はProvideTemplatePage側でregisterイベントを監視しているメソッドが実施
                    this.emit('register', {value: value});
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        resize: function(changeSize, resultSize) {
            this.borderContainer.resize(changeSize, resultSize);
        }

    });
});
