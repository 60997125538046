/**
 * 水位情報一覧画面用モジュール。
 * @module app/river/RiverLevelObservationPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/RiverLevelObservationPage.html',
    'dojo/topic',
    'idis/view/page/_PageBase',
    'idis/control/Router',
    'idis/view/dialog/DialogChain',
    'idis/store/IdisRest',
    'idis/service/Requester',
    'app/observation/view/form/DateTimeSelect',
    'app/observationstation/model/DataKind',
    // 以下、変数で受けないモジュール
    'app/view/form/RegionMuni4ObservatorySelector',
    'app/view/page/StatisticsDataDialog',
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/TextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/layout/TabContainer',
    'idis/view/form/Button',
    'idis/view/form/AclButton',
    'idis/view/form/DateTimeInput',
    './RiverLegend',
    './grid/RiverLevelObservationPageGrid'
], function(module, declare, lang, template, topic,
    _PageBase, Router, DialogChain, IdisRest, Requester,
    DateTimeSelect, DataKind)  {
    /**
     * 水位情報一覧画面
     * @class RiverLevelObservationPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/river/RiverLevelObservationPage~RiverLevelObservationPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--riverlevelobs',

        // ストア
        store: null,

        /**
         * 時間モード
         * {string} '10': 10分 '60': 正時
         */
        mode: '10',

        /**
         * 前回のデータ取得日時
         * {string} 'yyyy-MM-dd HH:mm'
         */
        lastRequestDateTime: '',

        //コンストラクタ
        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'observatoryId',
                target: '/api/river'
            });
            // 連鎖ダイアログを登録
            this.chain = DialogChain.get(this);
        },

        // DOMノードを生成するためのメソッド
        buildRendering: function() {
            this.inherited(arguments);
            // 自分が削除された時は関連ダイアログも削除する
            this.own(this.statisticsDataDialog);

            // 初期表示は、時間モード10分で最新の時間データ
            // 最新の観測情報を取得する
            Requester.get('/api/river/latestDataTime?timeMode=' + this.mode).then(lang.hitch(this, function(data){
                // 日付セレクトボックスを生成・設置する
                this.dateTimeSelect = new DateTimeSelect({
                    to: data
                });

                this.dateTimeSelect.placeAt(this.dateTimeSelectNode, 'only');
                // グリッドを初期化する
                this.initGrid();
                this.borderContainer.resize();
            }));
        },

        /**
         * DOM構築後に呼ばれる
         */
        postCreate: function() {
            this.inherited(arguments);
            // 日時の変更を監視
            this.own(topic.subscribe(DateTimeSelect.TOPIC.CHANGE_DATE_TIME, lang.hitch(this, function(datetime) {
                // 1回の操作で日付と時間が同時に変更される場合があり、このとき同じ日時で2回続けて変更イベントが発行される
                // 同じ日時で連続でデータ取得のリクエストが飛ぶことを避けるため、前回の日時を保持し、異なる場合のみデータを取得するようにする
                if (this.lastRequestDateTime !== (datetime.date + ' ' + datetime.time)) {
                    // 地域が選択されている場合があるためフィルターを設定し、グリッドにセット
                    var filter = this.updateFilter(this.areaSelector.get('value'), 0);
                    // 観測日時をフィルターにセット
                    // IEでDate生成時にエラーとなるのを回避
                    var dateStr = datetime.date.replace(/-/g, '/') + ' ' + datetime.time;
                    var date = new Date(dateStr).getTime();
                    filter = filter.eq('date', date);
                    this.grid.set('collection', this.store.filter(filter));
                }
                this.lastRequestDateTime = datetime.date + ' ' + datetime.time;
            })));
            // 最新ボタンクリックを監視
            this.own(topic.subscribe(DateTimeSelect.TOPIC.CLICK_LATEST, lang.hitch(this, function() {
                // 最新の観測情報を取得
                Requester.get('/api/river/latestDataTime?timeMode=' + this.mode).then(
                    lang.hitch(this, function(data){
                        // 観測日時セレクトボックスを再構築
                        // セレクトボックスの値が変更されれば、変更トピックが発火されて上記の「日時の変更を監視」でキャッチされ、対応するデータが取得される
                        this.dateTimeSelect.rebuild(data);
                    }));
            })));
        },

        startup: function() {
            this.inherited(arguments);
        },

        /**
         * グリッドを初期化する。
         */
        initGrid: function() {
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.grid.on('detailButtonClick', lang.hitch(this, function(evt) {
                this.onDetailButtonClick(evt.item);
            }));
            this.grid.changeMode(this.mode);
        },

        /**
         * 詳細ボタンを押下したときの挙動を設定する。
         */
        onDetailButtonClick: function(object) {
            console.debug('[詳細]ボタンがクリックされました。');
            console.debug('object=' + object);
            //var selectedDate = new Date(this.form.get('value').date).getTime();
            // 水位情報状況詳細画面へ遷移
            var date = new Date(this.lastRequestDateTime).getTime();
            // 全域検索→選択行の地域 地域/流域検索中→検索中のコード
            var obsRegionCd = this.areaSelector.getAreaType()==='REGION' ?
                this.areaSelector.get('value') : object.regionCd;

            Router.moveTo('observation/river/detail', {
                observatoryId : object.observatoryId,
                regionCd : obsRegionCd,
                date : date,
                timeMode : this.mode,
                observatoryName : this.observatoryName
            });
        },

        /**
         * 時間モードが変更された際に呼ばれる。
         * @param {string} value 新しい時間モード
         */
        onChangeModeSelect: function(value) {
            this.mode = value;
            // 観測時間選択の表示を切り替える
            this.dateTimeSelect.changeMode(value);
            this.grid.changeMode(this.mode);
            var filter = this.updateFilter(this.areaSelector.get('value'), 1);
            this.grid.set('collection', this.store.filter(filter));
        },

        /**
         * 地域セレクトボックスが変更された際に呼ばれる
         */
        onChangeRegionSelect: function(evt) {
            var filter = this.updateFilter(evt.value,1);
            this.grid.set('collection', this.store.filter(filter));
        },

        /**
         * ストアのフィルターを生成する
         * @param {string} 地域コード または 流域コード. 指定されない場合は全選択.
         * @param {string} 時間変更フラグ. 1の場合は時間が変更されていないため、最後に選択された時刻でRequest.
         * @returns {dstore.Filter}
         */
        updateFilter: function(value,flg) {
            var filter = new this.store.Filter();
            // 時間モード
            filter = filter.eq('timeMode', this.timeModeSelect.get('value'));

            // 地域/市町村セレクタ
            switch (this.areaSelector.getAreaType()) {
                case 'REGION':          //地域を選択
                    filter = filter.eq('regionCd', value);
                    break;
                case 'MUNICIPALITY':    // 市町村を選択
                    filter = filter.eq('municipalityCd', value);
                    break;
            }
            if(flg === 1){
                // 観測日時をフィルターにセット
                var date = new Date(this.lastRequestDateTime).getTime();
                filter = filter.eq('date', date);
            }
            return filter;
        },
        onEvacOrderRegisterButtonClick: function() {
            console.debug('[新規登録]ボタンがクリックされました。');
            // 避難状況登録登録画面へ遷移
            Router.moveTo('evacorder/register');
        },

        onRiverLevelStatisticsDataDialogButtonClick: function() {
            console.debug('[統計情報ダウンロード]ボタンがクリックされました。');
            // ダイアログの最初の子要素が登録画面
            var dialog = this.statisticsDataDialog.getChildren()[0];
            dialog.setInitKind(DataKind.RIVER_LEVEL);
            this.statisticsDataDialog.set({title: '統計情報'});
            this.statisticsDataDialog.show();
        }

    });
});
