/**
 * 配信情報Lアラート内容確認ダイアログ（被害情報）
 * @module app/sendingHistory/Dialog/DamageDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/text!./templates/DamageReportNumberDialog.html',
    'idis/view/dialog/DialogChain',
    'dojo/date/locale',
    'dojo/dom-style',
    'idis/view/page/_PageBase',
    // 以下、変数から参照されないモジュール
    'app/provide/form/DistributionTypeInput',
    'idis/view/form/ReadOnlyInput',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/Button'
], function (module, declare, template, DialogChain, locale, domStyle, _PageBase) {
    /**
     * 配信情報Lアラート内容確認ダイアログ（被害情報）
     * @class DamageDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/sending/DamageDialog~DamageDialog# */ {
            // テンプレート文字列
            templateString: template,

            constructor: function () {
                this.chain = DialogChain.get(this);
            },

            initDialog: function (obj) {
                console.log(obj);
                this.form.set('value', obj);
                // 配信情報種別
                this.distributionType.set('value', obj.distributionType);

            },
            onOKButton: function () {
                this.getParent().hide();
            }
        });
});
