/**
* 定時集計画面用モジュール。
* @module app/scheduledReport/ScheduledReportListPage
*/
define([
	'module',
	'dojo/_base/declare',
	'dojo/_base/lang',
	'dojo/dom-style',
	'dojo/text!./templates/ScheduledReportListPage.html',
	'idis/view/page/_PageBase',
	'idis/control/Router',
	'idis/store/IdisRest',
	'idis/view/dialog/DialogChain',
	'app/model/DisasterInfo',
	// 以下、変数として受け取らないモジュール
	'dijit/Dialog',
	'dijit/form/Form',
	'dijit/layout/BorderContainer',
	'dijit/layout/ContentPane',
	'idis/view/form/AclButton',
	'idis/view/form/Button',
	'idis/view/form/DateTimeInput',
	'./ScheduledReportFormDialog',
	'./ScheduledReportListGrid'
], function (module, declare, lang, domStyle, template, _PageBase,
	Router, IdisRest, DialogChain, DisasterInfo) {

	/**
	* 検索条件格納用オブジェクト
	* @type {Object}
	*/
	var reportFilterStore = {};

	/**
	* 定時集計画面。
	* @class ScheduledReportPage
	* @extends module:idis/view/page/_PageBase~_PageBase
	*/
	return declare(module.id.replace(/\//g, '.'), _PageBase,
		{
			// テンプレート文字列
			templateString: template,

			// ルート要素に付与されるCSS
			baseClass: 'idis-Page idis-Page--scheduledreport',

			/**
			 * データ格納用オブジェクト
			 * @type {module:dstore/Store}
			 */
			store: null,
			/**
			 * コンストラクタ
			 */
			constructor: function () {
				// 災害IDをDisasterInfoから取得
				this._disasterId = DisasterInfo.getDisasterId();

				// データ格納用オブジェクト
				this.store = new IdisRest({
					idProperty: 'scheduledReportId',
					target: '/api/scheduledReport',
					rangeStartParam: 'offset',
					rangeCountParam: 'count'
				});
				this.chain = DialogChain.get(this);
			},

			/**
			 * DOM生成
			 */
			// DOMノードを生成するためのメソッド
			buildRendering: function () {
				this.inherited(arguments);
			},

			/**
			 * 画面生成完了
			 */
			startup: function () {
				this.inherited(arguments);

				// グリッドを初期化する
				this.initGrid();
			},

			/**
			 * グリッドを初期化する。
			 */
			initGrid: function () {
				// グリッドの詳細ボタンクリック時の動作を設定する
				// helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
				this.grid.on('detailButtonClick', lang.hitch(this, function (evt) {
					this.onDetailButtonClick(evt.item);
				}));
				this.grid.on('municListButtonClick', lang.hitch(this, function (evt) {
					this.onMunicListButtonClick(evt.item);
				}));

				// 保管した検索条件をセットする
				this.setFilterData();

				this.updateGridQuery();
			},

			/**
			 * 検索
			 */
			onSubmit: function () {
				try {
					if (this.form.validate()) {
						// 入力値が正常ならグリッドの検索条件を更新
						this.updateGridQuery();
					}
				} catch (e) {
					console.error(e);
				}
				return false;
			},

			/**
			 * 検索パラメーターの設定
			 */
			updateGridQuery: function () {

				this.setFilterStore();

				// filterに対応するcollectionを取得
				var collection = this.store.filter(reportFilterStore);

				// collectionをグリッドにセットする（サーバーにリクエストされる）
				this.grid.set('collection', collection);
			},

			/**
			 * 検索条件を保管する
			 */
			setFilterStore: function (value) {
				// 入力値を元にグリッド用フィルターを作成
				reportFilterStore = this.store.Filter();

				var value = this.form.get('value');
				// 災害ID
				reportFilterStore = reportFilterStore.eq('disasterId', this._disasterId);

				// 集計時点FROM
				if (value.aggrDateTimeFrom) {
					reportFilterStore = reportFilterStore.eq('aggrDateTimeFrom', value.aggrDateTimeFrom.getTime());
				}

				// 集計時点TO
				if (value.aggrDateTimeTo) {
					reportFilterStore = reportFilterStore.eq('aggrDateTimeTo', value.aggrDateTimeTo.getTime());
				}
			},

			/**
			 * 保管した検索条件をフォームにセットする
			 */
			setFilterData: function () {

				// 報告日時FROM
				if (reportFilterStore.aggrDateTimeFrom) {
					this.aggrDateTimeFrom.set('value', reportFilterStore.aggrDateTimeFrom);
				}

				// 報告日時TO
				if (reportFilterStore.aggrDateTimeTo) {
					this.aggrDateTimeTo.set('value', reportFilterStore.aggrDateTimeTo);
				}

			},



			/**
			 * 定時集計 新規登録画面を表示
			 */
			onRegistButtonClick: function () {
				//検索条件の保管
				this.setFilterStore();

				// 被害状況・報告定時 集計新規登録画面へ遷移
				Router.moveTo('scheduledReport/regist');
			},

			/**
			 * 詳細画面を表示
			 */
			onDetailButtonClick: function (object) {
				//検索条件の保管
				this.setFilterStore();

				// 被害状況・報告詳細画面へ遷移
				Router.moveTo('scheduledReport/detail', {
					scheduledReportId: object.scheduledReportId
				});
			},
			/**
			 * 各区の一覧表示
			 */
			onMunicListButtonClick: function (item) {
				//検索条件の保管
				this.setFilterStore();

				// 各区・局の画面へ遷移
				Router.moveTo('scheduledReport/munic', {
					admNum: item.admNum
				});
			},
			/**
			 * 被害一覧を表示
			 */
			onDamageReportPageLinkClick: function (evt) {
				//検索条件の保管
				this.setFilterStore();
				// ブラウザーの遷移処理をキャンセル
				evt.preventDefault();

				// 被害一覧画面へ遷移
				Router.moveTo('report');
			},

			/**
			 * 帳票出力
			 */
			showReportForm: function () {
				this.reportFormDialog.show();
			}

		});
});
