define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/array',
    'dojo/_base/lang',
    'dojo/string',
    'dgrid/Selection',
    'dgrid/Selector',
    'dgrid/Keyboard',
    'dstore/Memory',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function(module, declare, array, lang, dstr, Selection, Selector, Keyboard,
		Memory, IdisGrid, helper) {
	return declare(module.id.replace(/\//g, '.'), [IdisGrid, Selection, Selector, Keyboard], {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--evacstdlimitover',

        /**
         * セレクションモード
         */
        selectionMode: 'none',

        /**
         * セレクト許可フラグ
         */
        allowSelectAll: true,


        allowTextSelection: false,

        /**
         * constructor
         */
        constructor: function() {
            // SelectorのallowSelectAllオプションがエラーとなるので、
            // 仮の値としてメモリーストアをおく
            this.collection = new Memory();
        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);
        },

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            // チェックボックス
            {
                field: 'checkbox',
                label: '',
                selector: 'checkbox'
            },
            helper.column('municipalityName', '市/行政区'),
            helper.column('observatoryName', '観測局'),
            helper.column('evacOrderType', '種別', {
                formatMap: {
                    '01': '土砂災害危険度',
                    '02': '雨量',
                    '03': '水位',
                    '04': '潮位'
                }
            }),
            helper.column('evacOrderLmtType', '超過基準', {
                classMap: {
                    ''    : 'is-normal',
                    '0101': 'is-caution-sadiment',
                    '0102': 'is-evac-sadiment',
                    '0103': 'is-3h-after',
                    '0104': 'is-2h-after',
                    '0105': 'is-1h-after',
                    '0106': 'is-now',
                    '0201': 'is-rainfall',
                    '0301': 'is-wait',
                    '0302': 'is-caution',
                    '0303': 'is-judgment',
                    '0304': 'is-crisis',
                    '0401': 'is-evac-tide',
                    '0402': 'is-caution-tide'
                },
                formatMap: {
                    '0101': '大雨注意報基準超過',
                    '0102': '大雨警報(土砂災害)基準超過',
                    '0103': '3時間後に基準値超過',
                    '0104': '2時間後に基準値超過',
                    '0105': '1時間後に基準値超過',
                    '0106': '実況で基準値超過',
                    '0201': '雨量基準値超過',
                    '0301': '水防団待機水位超過',
                    '0302': '氾濫注意水位超過',
                    '0303': '避難判断水位超過',
                    '0304': '氾濫危険水位超過',
                    '0401': '警報基準潮位超過',
                    '0402': '注意基準潮位超過'
                }
            }),
            lang.mixin(helper.column('evacStdLimitOverMunis', '対象市/行政区', {
                // 地区名を"、"で繋げる
                formatter: function(items) {
                    return array.map(items, function(item) {
                        return dstr.trim(item.municipalityName);
                    }).join('、');
                }
            }),
            {sortable: false}),
            helper.column('overLmtTimestamp', '日時')
            // ボタン
//            helper.buttonColumn('detail', '詳細')
        ]
    });
});
