/**
 * 配信情報一覧画面用モジュール。
 *
 * @module app/provide/ProvideInformationPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/text!./templates/ProvideInformationPage.html',
    'dojo/topic',
    'idis/view/dialog/DialogChain',
    'idis/view/page/_PageBase',
    'idis/store/IdisRest',
    'idis/control/Router',
    'idis/service/Requester',
    'idis/model/UserInfo',
    'idis/consts/ACL',
    'app/model/DisasterInfo',
    // 以下、変数として受け取らないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/DateTimeInput',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'app/view/form/MunicipalitySelector',
    './ProvideEvacOrderDetailDialog',
    './ProvideShelterDetailDialog',
    './ProvideGeneralRegisterDialog',
    './ProvideGeneralDetailDialog',
    './ProvidePreventionDetailDialog',
    './ProvideUrgentMailRegisterDialog',
    './ProvideUrgentMailDetailDialog',
    './ProvideInformationGrid'
], function(module, declare, lang, domStyle, template, topic, DialogChain, _PageBase,
            IdisRest, Router, Requester, UserInfo, ACL, DisasterInfo) {
    /**
     * グリッド画面のサンプル実装。
     *
     * @class ProvideInformationPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:idis/view/page/ProvideInformationPage~ProvideInformationPage# */
    {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--provide',

        EVAC_ORDER_CONTENT_TYPE : '01',
        SHELTER_CONTENT_TYPE : '02',
        PREVENTION_CONTENT_TYPE : '03',
        GENERAL_CONTENT_TYPE : '04',
        URGENT_MAIL_CONTENT_TYPE : '05',

        /**
         * データ格納用オブジェクト
         *
         * @type {module:dstore/Store}
         */
        store: null,

        // 緊急配信メールの送信権限
        _urgentMailStatus: false,

        // 画面で初期設定する市町村コード
        _municipalityCd: null,

        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'lalertSendHistId',
                target: '/api/provide'
            });
            this._disasterId = DisasterInfo.getDisasterId();
            // ユーザのもつ市町村コードを取得する。
            // ユーザは1~複数の市町村を持っている。複数の市町村を持っている場合は[0]の市町村を取得する。
            this._municipalityCd = UserInfo.getMunicipalityCds()[0];
            // ダイアログ連鎖を登録
            // 引数に与えたウィジェットのthis.ownを呼び出し、
            // ウィジェットが破棄された際にダイアログ連鎖が破棄されるようになる
            this.chain = DialogChain.get(this);
        },

        // DOMノードを生成するためのメソッド
        buildRendering: function() {
            this.inherited(arguments);
            // 自分が削除された時は関連ダイアログも削除する
            // （ダイアログは初期化時にbody直下へ移動するため、明示的に指定しないと消えずに残ってしまう）
            this.own(this.generalRegisterDialog);
            this.own(this.urgentMailRegisterDialog);
            this.own(this.generalDetailDialog);
            this.own(this.urgentMailDetailDialog);
            this.own(this.evacOrderDetailDialog);
            this.own(this.shelterDetailDialog);
            this.own(this.preventionDetailDialog);

            // 初期画面ではログインユーザの市町を検索条件にセットする。
            this.form._setValueAttr({municipalityCd: this._municipalityCd});
            // 初期画面では現在時刻から一週間前までを検索条件にセットする。
            var date = new Date();
            var pastDate = new Date();
            pastDate.setDate(pastDate.getDate() - 7);
            this.sendTimestampTo.set('value', date);
            this.sendTimestampFrom.set('value', pastDate);

            // 緊急速報メール利用有無をチェックし緊急速報メールボタンの活性を切り替える
            var param = '';
            var municipalityCds = UserInfo.getMunicipalityCds();
            if(municipalityCds) {
                var firstFlg = true;
                for (var i = 0; i < municipalityCds.length; i++) {
                    if(firstFlg) {
                        param += '?';
                        firstFlg = false;
                    } else {
                        param += '&';
                    }
                    param += 'array[]=' + municipalityCds[i];
                }
            }
            Requester.get('/api/municipalities/municipality4Tree/urgentMail'+param)
            .then(lang.hitch(this, function(info) {
                if(info.items.length > 0) {
                    domStyle.set(this.test, 'display', '');
                    this._urgentMailStatus = true;
                }
            }));

            // グリッドを初期化する
            this.initGrid();
        },

        startup: function() {
            this.inherited(arguments);

            this.setButtonStatus();

            // 登録された場合Gridを更新する
            this.own(topic.subscribe('app/provide/_ProvideDialogBase::added', lang.hitch(this, function() {
                this.initGrid();
            })));
            this.own(topic.subscribe('app/provide/ProvideShelterRegisterDialog::added', lang.hitch(this, function() {
                this.initGrid();
            })));
            this.own(topic.subscribe('app/provide/ProvideUrgentMailRegisterDialog::added', lang.hitch(this, function() {
                this.initGrid();
            })));
        },

        /**
         * グリッドの検索条件を指定された値で更新する。
         *
         * @param {Object}
         *                value name属性と値のマッピング
         */
        updateGridQuery: function(value) {
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();
            // 災害ID
            filter = filter.eq('disasterId', this._disasterId);
            // 情報種別
            if (value.category) {
                filter = filter.eq('sendCategory', value.category);
            }
            // お知らせ日時From
            if (value.sendTimestampFrom) {
                filter = filter.eq('sendTimestampFrom', value.sendTimestampFrom.getTime());
            }
            // お知らせ日時To
            if (value.sendTimestampTo) {
                filter = filter.eq('sendTimestampTo', value.sendTimestampTo.getTime());
            }
            // 市町村
            if (value.municipalityCd) {
                filter = filter.eq('municipalityCd', value.municipalityCd);
            }
            // 配信状況
            if (value.sendStatus) {
                filter = filter.eq('sendStatus', value.sendStatus);
            }

            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);

            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.grid.set('collection', collection);
        },

        /**
         * 検索ボタンが押されたときに呼び出される。 テンプレートHTML内のFormウィジェットに対するdata-dojo-attach-eventでこのイベントを紐付けている。
         * また、同Formウィジェットに対しdata-dojo-attach-pointを指定し、this.formからアクセス出来るようにしている。
         */
        onSubmit: function() {
            // TODO 入力チェック処理について確認。FROMの未来日及び、FROM-TOの整合性チェック
            // ここでtry-catchする意味はあるか？ validateがfalseの場合にダイアログを出すべきでは？
            try {
                if (this.form.validate()) {
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery(this.form.get('value'));
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * 配信情報(お知らせ)ダイアログを表示する。
         * テンプレートHTMLでdata-dojo-attach-eventプロパティーを用い、 新規登録ボタンのクリックイベントにこのメソッドを紐付けている。
         */
        showGeneralDialog: function() {
            var args = {
                municipalityCd : this._municipalityCd,
                sendCategory : this.GENERAL_CONTENT_TYPE,
                // 更新種別 '01':新規, '02':更新・訂正, '03':取消
                distributionType: '01',
                // 運用種別 '01':本番, '02':訓練, '03':テスト
                distributionStatus:'02'
            };
            // ダイアログの最初の子要素が登録画面
            var dialog = this.generalRegisterDialog.getChildren()[0];
            // ダイアログの画面を初期化する。
            dialog.initDialog(args);
            // ダイアログを表示する。
            this.generalRegisterDialog.show();
        },

        /**
         * 新規登録ダイアログ(市町職員向け)を表示する。
         * テンプレートHTMLでdata-dojo-attach-eventプロパティーを用い、 新規登録ボタンのクリックイベントにこのメソッドを紐付けている。
         */
        showUrgentMailDialog: function() {
            var evacOrderData = {
                municipalityName : null,
                organizationName : null,
                // 情報配信画面から緊急速報メールダイアログを開く場合対象市町は未選択の状態にする
                municipalityCd : null,
                evacOrderType : null,
                evacTimestamp : new Date(),
                areaList : []
            };
            var shelterNameList = [];

            var args = {
                municipalityCd : null,
                sendCategory   : this.URGENT_MAIL_CONTENT_TYPE,
                evacOrderData  : evacOrderData,
                shelterNameList: shelterNameList,
                // 更新種別 '01':新規, '02':更新・訂正, '03':取消
                distributionType: '01',
                // 運用種別 '01':本番, '02':訓練, '03':テスト
                distributionStatus:'02'
            };


            // ダイアログの最初の子要素が登録画面
            var dialog = this.urgentMailRegisterDialog.getChildren()[0];
            // ダイアログの画面を初期化する。
            dialog.initDialog(args);
            // ダイアログを表示する。
            this.urgentMailRegisterDialog.show();
        },

        /**
         * 詳細ダイアログを表示する。
         *
         * @param {Object} item 参照する行のデータ
         * @param {Object} item.sendCategory 配信種別
         * @param {Object} item.lalertSendHistId 配信履歴ID
         */
        showDetailDialog: function(item) {
            // 配信種別に応じてAPIを選択
            var api = {
                '01': '/api/provide/detail/evacOrder/',
                '02': '/api/provide/detail/shelter/',
                '03': '/api/provide/detail/prevention/',
                '04': '/api/provide/detail/general/',
                '05': '/api/provide/detail/urgentMail/'
            }[item.sendCategory];
            if (!api) {
                console.error('不明なカテゴリー・コード: ' + item.sendCategory);
                return;
            }
            // 配信履歴を取得
            Requester.get(api + item.lalertSendHistId).then(lang.hitch(this, function(info) {
                console.debug('取得した配信情報履歴:', info);
                // 配信種別に応じてダイアログを選択
                var dialog = {
                    '01': this.evacOrderDetailDialog,
                    '02': this.shelterDetailDialog,
                    '03': this.preventionDetailDialog,
                    '04': this.generalDetailDialog,
                    '05': this.urgentMailDetailDialog
                }[item.sendCategory];
                // ダイアログを初期化
                dialog.getChildren()[0].initDialog(info);
                // ダイアログを表示
                dialog.show();
            }));
        },

        /**
         * グリッドを初期化する。
         */
        initGrid: function() {
            // 全件表示用のcollectionをグリッドにセットする
            // （テンプレートHTML内のdata-dojo-attach-point="grid"でグリッドをthis.gridに紐付け済み）
            // this.grid.set('collection', this.store.filter());
            this.updateGridQuery(this.form.get('value'));
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.grid.on('detailButtonClick', lang.hitch(this, function(evt) {
                // helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
                this.showDetailDialog(evt.item);
            }));
        },

        onTempButtonClick: function() {
            Router.moveTo('provideinformation/temp');
        },

        // ユーザー権限が「その他ユーザ」の場合はお知らせメール配信、緊急速報メール配信ボタンを非活性に
        setButtonStatus: function(){
            var role = UserInfo.getRoleCd();
            if (role === ACL.OTHER_USER) {
                this.showGeneralDialogButton.setDisabled(true);
                this.showUrgentMailDialogButton.setDisabled(true);
            }
        }
    });
});
