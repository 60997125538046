/**
 * 帳票ディスプレイ画面用モジュール。
 * @module app/form/moveDiplayPage
 */
 define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/array',
    'dojo/_base/lang',
    'dojo/text!./templates/moveDisplayPage.html',
    'dojo/dom-style',
    'idis/model/UserInfo',
    'idis/view/page/_PageBase',
    // 以下、変数で受けないモジュール
    'idis/view/form/Button'
], function(module, declare, array, lang, template, domStyle, UserInfo, _PageBase) {
    /**
     * マニュアルダウンロード画面
     * @class moveDisplayPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    	/** @lends module:app/view/page/stationsPage~stationsPage# */ {

        // テンプレート文字列
        templateString: template,

        // 基本クラス
        baseClass: 'idis-Page idis-Page--link',

        /**
         * DOMノードを生成する前の処理。
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * 緊急通知ボタンがクリックされた時の挙動。
         */
         onBroadnotifyButton: function() {
            // 別タブで開く
            window.open('http://103.231.194.122/data/display/13p-emergency-notification.html');
        },

        /**
         * 市民への情報発信状況ボタンがクリックされた時の挙動。
         */
         oncivilInfoButton: function() {
            // 別タブで開く
            window.open('http://103.231.194.122/data/display/12p-transmited-list.html');
        },

        /**
         * 被害件数ボタンがクリックされた時の挙動。
         */
         onDamageNumButton: function() {
            // 別タブで開く
            window.open('http://103.231.194.122/data/display/11p-damage-report.html');
        },

        /**
         * 地震概要ボタンがクリックされた時の挙動。
         */
         onEarthquakeButton: function() {
            // 別タブで開く
            window.open('http://103.231.194.122/data/display/9p-earthquake-info.html');
        },

        /**
         * 災害対応状況ボタンがクリックされた時の挙動。
         */
         onDisasterResponseButton: function() {
            // 別タブで開く
            window.open('http://103.231.194.122/data/display/10p-disaster-overview.html');
        },

        /**
         * 被害状況（区）ボタンがクリックされた時の挙動。
         */
         onDistrictDamageButton: function() {
            // 別タブで開く
            window.open('http://103.231.194.122/data/display/19p-ward-damage-summary.html');
        },

        /**
         * 人的被害ボタンがクリックされた時の挙動。
         */
         onHumanDamageButton: function() {
            // 別タブで開く
            window.open('http://103.231.194.122/data/display/16p-human-damages.html');
        },

        /**
         * ライフライン状況ボタンがクリックされた時の挙動。
         */
         onLifelineButton: function() {
            // 別タブで開く
            window.open('http://103.231.194.122/data/display/17p-lifelines.html');
        },

        /**
         * 被害状況（局）ボタンがクリックされた時の挙動。
         */
         onStationDamageButton: function() {
            // 別タブで開く
            window.open('http://103.231.194.122/data/display/18p-dept-damage-summary.html');
        },

        /**
         * 関係機関情報１ボタンがクリックされた時の挙動。
         */
         onRelatedOrganOneButton: function() {
            // 別タブで開く
            window.open('http://103.231.194.122/data/display/14p-organizations1.html');
        },

        /**
         * 避難所開設状況一覧ボタンがクリックされた時の挙動。
         */
         onOpenshelterButton: function() {
            // 別タブで開く
            window.open('http://103.231.194.122/data/display/20p-shelter-list-150.html');
        },

        /**
         * 河川水位情報ボタンがクリックされた時の挙動。
         */
         onRiverButton: function() {
            // 別タブで開く
            // window.open('http://103.231.194.122/data/display/22p_riverwarterlevel.html');
            window.open('../../data/display/Unit3-4.html');
        },

        /**
         * 関係機関情報２ボタンがクリックされた時の挙動。
         */
         onRelatedOrganTwoButton: function() {
            // 別タブで開く
            window.open('http://103.231.194.122/data/display/15p-organizations2.html');
        },

        /**
         * 全避難所開設状況一覧ボタンがクリックされた時の挙動。
         */
         onOpenshelterAllButton: function() {
            // 別タブで開く
            window.open('http://103.231.194.122/data/display/21p-shelter-list-300.html');
        }

    });
});