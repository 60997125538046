/**
 * 火山情報パネル
 * @module app/monitor/VolcanoPanel
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'dojo/dom-construct',
    'dojo/dom-style',
    'dojo/json',
    'dojo/text!./templates/VolcanoPanel.html',
    'dojo/topic',
    'dojo',
    'idis/control/Router',
    'idis/model/UserInfo',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    'idis/view/page/_PageBase',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane'
], function(module, array, declare, lang, locale, domConstruct, domStyle, JSON, template, topic,
    dojo, Router, UserInfo, Requester, DialogChain, Loader, _PageBase) {
    /**
     * 火山情報パネル
     * @class EarthquakePanel
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/monitor/VolcanoPanel~VolcanoPanel# */ {
        // テンプレート文字列
        templateString: template,

        /**
         * 最大表示件数
         */
        MAX_COUNT: 5,

        /**
         * 市町村コード
         */
        _municipalityCd: null,

        /**
         * constructor
         */
        constructor: function() {
            this.chain = DialogChain.get(this);
            this._municipalityCd = UserInfo.getSelectedMunicipalityCd();
            console.debug('火山情報表示対象の市町村コード：' + this._municipalityCd);
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);
            this.initTable();

            // 市町村切替時に気象情報を更新
            this.own(topic.subscribe('idis/view/form/MunicipalitySelectForm::selected',
                lang.hitch(this, function(payload) {
                    this._municipalityCd = payload.municipalityCd;
                    this.initTable();
            })));
        },


        /**
         * テーブルを初期化します
         */
        initTable: function() {
            // 火山情報を設定
            this.setVolcanoListInfo();
        },

        /**
         * 火山情報を設定します
         */
        setVolcanoListInfo: function() {
            var self = this;

            var promise = Requester.get('/data/volcano/summary/volcano.json', {
                headers: {'Content-Type': 'application/json; charset=utf-8'},
                handleAs: 'json',
                preventCache : true
            }).then(function(item) {
                console.debug('火山情報（' + this._municipalityCd + '）：' +
                    JSON.stringify(item));

                // 火山情報を設定
                self.setVolcanoListview(self, item);

            }, function(error) {
                console.log('火山情報が見つかりません。初期値をセットします。' + (error.message ? ('('+error.message+')') : ''));
                if (error.response.status === 404) {
                    var item = {items:[]};
                    self.setVolcanoListview(self, item);
                } else {
                    self.chain.info('情報の取得に失敗しました。', 'エラー');
                }
            });
            //ローダーの表示
            Loader.wait(promise);
        },

        /**
         * 火山情報を設定します
         */
        setVolcanoListview: function(self, data) {
            var volcanoPanelListTbody = dojo.byId('volcanoPanelListTbody');
            domConstruct.empty(volcanoPanelListTbody);

            var html = '';
            if (data.items.length === 0) {
                html += '<tr>';
                html += '<td colspan="3" style="text-align: left;">現在、火山情報はありません。</td>';
                html += '</tr>';
            } else {
                // 先頭5件のみ表示
                if (data.items.length > this.MAX_COUNT) {
                    data.items.length = this.MAX_COUNT;
                }

                array.forEach(data.items, function(item){
                    var datetimeString = self.formatDateTime(item.reportDatetime);
                    html += '<tr>';
                    html += '<td>' + datetimeString + '</td>';
                    html += '<td>' + item.infoKind + '</td>';
                    html += '<td>' + item.volcanoName + '</td>';
                    html += '</tr>';
                });
            }

            var dom = domConstruct.toDom(html);
            domConstruct.place(dom, volcanoPanelListTbody);
        },

        /**
         * 'yyyy-MM-dd HH:mm' 形式に変換
         */
        formatDateTime: function(val) {
            var timestamp = new Date(val);
  		  	var dateLabel = locale.format(timestamp, {
  		  		selector: 'date',
  		  		datePattern: 'yyyy/MM/dd'
  		  	});
  		  	var timeLabel = locale.format(timestamp, {
  		  		selector: 'time',
  		  		timePattern: 'HH:mm'
  		  	});
        	return dateLabel + '&nbsp;' + timeLabel;
        },

        /**
         * 火山情報一覧ページに遷移
         */
        onVolcanoLinkClick: function(evt){
            evt.preventDefault();
            Router.moveTo('volcano');
        }

    });
});
