/**
 * 配信情報Lアラート内容確認ダイアログ（被害情報）
 * @module app/sendingHistory/Dialog/ChronologyDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/text!./templates/ChronologyDialog.html',
    'idis/view/dialog/DialogChain',
    'dojo/date/locale',
    'dstore/Memory',
    'dojo/dom-style',
    'idis/view/page/_PageBase',
    // 以下、変数から参照されないモジュール
    'app/sendingHistory/dialog/ChronologyCrspndGrid',
    'app/sendingHistory/dialog/ChronologyMemoGrid',
    'app/sendingHistory/dialog/ChronologyReqGrid',
    'app/provide/form/DistributionTypeInput',
    'idis/view/form/ReadOnlyInput',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/Button'
], function (module, declare, template, DialogChain, locale, Memory, domStyle, _PageBase) {
    /**
     * 配信情報Lアラート内容確認ダイアログ（被害情報）
     * @class ChronologyDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(
        module.id.replace(/\//g, '.'),
        _PageBase,
        /** @lends module:app/sending/ChronologyDialog~ChronologyDialog# */ {
            // テンプレート文字列
            templateString: template,

            constructor: function () {
                this.chain = DialogChain.get(this);
            },

            initDialog: function (obj) {
                console.log(obj);
                this.distributionType.set('value', obj.chronology.editType);
                this.municipalityName.set('value', obj.municipalityName);
                this.form.set('value', obj.chronology);
                if (obj.chronology.priority === '0') {
                    this.priority.set('value', '-');
                }
                if (obj.chronology.priority === '1') {
                    this.priority.set('value', '低');
                }
                if (obj.chronology.priority === '2') {
                    this.priority.set('value', '中');
                }
                if (obj.chronology.priority === '3') {
                    this.priority.set('value', '高');
                }
                this.initGrid(obj);
            },
            initGrid: function (obj) {
                this.initMemoGrid(obj);
                this.initCrspndGrid(obj);
                this.initReqGrid(obj);
            },
            initMemoGrid: function (obj) {
                // データ格納用オブジェクト
                this.memoStore = new Memory({
                    idProperty: 'tChronologyMemoSendingHistId'
                });
                if (Array.isArray(obj.chronology.memoList)) {
                    for (let i = 0; i < obj.chronology.memoList.length; i++) {
                        let item = obj.chronology.memoList[i];
                        item.tChronologyMemoSendingHistId = i;
                        this.memoStore.addSync(item);
                    }
                }
                this.memoGrid.set('collection', this.memoStore);
                this.memoGrid.contentNode.parentNode.style.marginTop = '24.5px';
                this.memoGrid.contentNode.parentNode.style.marginBottom = '25px';
            },
            initCrspndGrid: function (obj) {
                // データ格納用オブジェクト
                this.crspndGridStore = new Memory({
                    idProperty: 'tChronologyCorrespondenceDetailSendingHistId'
                });
                if (Array.isArray(obj.chronology.correspondenceList)) {
                    for (let i = 0; i < obj.chronology.correspondenceList.length; i++) {
                        let item = obj.chronology.correspondenceList[i];
                        item.tChronologyCorrespondenceDetailSendingHistId = i;
                        this.crspndGridStore.addSync(item);
                    }
                }
                this.crspndGrid.set('collection', this.crspndGridStore);
                this.crspndGrid.contentNode.parentNode.style.marginTop = '24.5px';
                this.crspndGrid.contentNode.parentNode.style.marginBottom = '25px';
            },

            initReqGrid: function (obj) {
                // データ格納用オブジェクト
                this.reqGridStore = new Memory({
                    idProperty: 'tChronologyRequestSendingHistId'
                });
                if (Array.isArray(obj.chronology.requestList)) {
                    for (let i = 0; i < obj.chronology.requestList.length; i++) {
                        let item = obj.chronology.requestList[i];
                        item.tChronologyRequestSendingHistId = i;
                        this.reqGridStore.addSync(item);
                    }
                }
                this.reqGrid.set('collection', this.reqGridStore);
                this.reqGrid.contentNode.parentNode.style.marginTop = '24.5px';
                this.reqGrid.contentNode.parentNode.style.marginBottom = '25px';
            },
            onOKButton: function () {
                this.getParent().hide();
            }
        }
    );
});
