/**
 * 安否確認機能 グループ管理画面用グリッド。
 * @module app/safety/group/SafetyGroupGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function(module, declare, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid,
        /** @lends module:idis/view/page/SafetyGroupGrid~SafetyGroupGrid# */ {

        /**
         * 各列の定義
         * @type {Object[]}
         */
        collection: this.store,
        columns: [
            helper.buttonColumn('groupDetail', '詳細'),
            helper.column('groupName','グループ'),
            helper.column('count','登録人数')
        ]
    });
});
