/**
 * 職員選択用入力パーツ
 * @module app/view/form/SafetyEmployeeSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/array',
    'dojo/dom-style',
    'idis/view/form/TreeChecker',
    '../../model/SafetyEmployee'
], function(module, declare, array, domStyle, TreeChecker, SafetyEmployee) {
    /**
     * 職員選択用パーツ。
     * @class SafetyEmployeeSelector
     * @extends module:idis/view/form/TreeChecker~TreeChecker
     */
    return declare(module.id.replace(/\//g, '.'), TreeChecker,
        /** @lends module:idis/view/tree/SafetyEmployeeSelector~SafetyEmployeeSelector# */ {
        // 選択ダイアログのタイトル
        title: '職員選択',
        // ツリー・モデル
        model: SafetyEmployee,
        // 選択結果を出力する際、親要素(課)は出力しない
        fullName: false,
        
        /**
         * 選択した値の設定
         */
        _setValueAttr: function() {
            // 継承した親クラスの処理
            this.inherited(arguments);
            // 以下、当モジュール固有の処理
            // 選択結果が20文字を超えた場合、・・・(三点リーダ)で省略する
            domStyle.set(this.status, {
                'max-width': '20em',
                'white-space': 'nowrap',
                'overflow': 'hidden',
                'text-overflow': 'ellipsis'
            });
        },

        /**
         * ツリー選択されている要素をこのウィジェットのvalue値として設定する。
         * 要素が選択されていない場合は何もしない。
         * TreeCheckerのメソッドをオーバーライド
         */
        applySelectedItem: function() {
            // チェックされたものの中から職員のみに絞る
            var employees = array.filter(this.tree.getCheckedLeafs(), function(item) {
                return item.type === 'MEmployee';
            }, this);

            this.set('value', array.map(employees, function(item) {
                return this.tree.model.store.getIdentity(item);
            }, this));
        }
    });
});
