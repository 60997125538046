/**
 * 防災ポータル情報配信更新用ダイアログモジュール。
 * @module app/emergency/PrefSiteSendingDetailDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/text!./templates/PrefSiteSendingDetailDialog.html',
    'dijit/registry',
    'idis/service/Requester',
    'idis/model/UserInfo',
    'idis/consts/ACL',
    'idis/view/dialog/DialogChain',
    './_PrefSiteSendingDialogBase'
    // 以下、変数で受けないモジュール
    // 変数で受けないモジュールなし
], function(module, declare, lang, domStyle, template, registry, requester,
        UserInfo, ACL, DialogChain, _PrefSiteSendingDialogBase) {
    return declare(module.id.replace(/\//g, '.'), _PrefSiteSendingDialogBase,
    {
        // テンプレート文字列
        templateString: template,

        // 添付ファイル
        attachFileList: [],

        // バージョン管理
        _version: null,

        constructor: function() {
            // ダイアログ連鎖を登録
            // 引数に与えたウィジェットのthis.ownを呼び出し、
            // ウィジェットが破棄された際にダイアログ連鎖が破棄されるようになる
            this.chain = DialogChain.get(this);
        },

        buildRendering: function() {
            var obj = registry.byId('detailSelectorDialog');
            if (obj) {
                registry.remove('detailSelectorDialog');
            }
            this.inherited(arguments);
        },

        // DOM要素構築後に呼ばれる
        postCreate: function() {
            this.inherited(arguments);
            // レベルの選択肢によって表示優先度の表示を切り替える
            this.level.on('change', lang.hitch(this, function(value) {
                if (value === '5') {
                    domStyle.set(this.priorityLevel, 'display', 'inline-block');
                } else {
                    domStyle.set(this.priorityLevel, 'display', 'none');
                }
            }));
        },

        /**
         * ダイアログの初期化
         */
        initDialog: function(obj) {
            this.setButtonStatus();
            this.initForm(obj);
        },

        /**
         * ボタンの表示非表示
         */
        setButtonStatus: function(){
            var role = UserInfo.getRoleCd();
            if (role === ACL.OTHER_USER) { // TODO 要確認。とりあえずその他ユーザを除外。
                domStyle.set(this.deleteButton.domNode, 'display', 'none');
                domStyle.set(this.updateButton.domNode, 'display', 'none');
                this.attachFile.set('disabled', true);
            }
        },

        /**
         * フォームの初期化
         */
        initForm: function(object) {
            this.form.reset();
            this.clearPreview();
            this.prefSiteSendingId = object.prefSiteSendingId;
            requester.get('/api/prefsitesending/' + this.prefSiteSendingId)
                .then(lang.hitch(this, function(data) {
                    this.form.set('value', data);
                    this.organizationName.innerHTML = data.organizationName || '-';

                    // 添付ファイルを設定
                    if(data.attachFiles) {
                        for(var i = 0; i < data.attachFiles.length; i++) {
                            this.attachFileList.push(data.attachFiles[i]);
                            this.showPreview(data.attachFiles[i], false);
                        }
                    }

                    this._version = data.version;
                }));
        },

        /**
         * 更新
         */
        onSubmit: function() {
            try {
                console.debug('[更新]ボタンがクリックされました。');

                // フォームのバリデーションを実施
                if (!this.form.validate()) {
                    return false;
                }

                // 固有のバリデーションを実施
                if(!this.validate()) {
                    return false;
                }

                var value = this.form.get('value');
                value.prefSiteSendingId = this.prefSiteSendingId;
                value.attachFileList = this.attachFileList;
                value.version = this._version;

                this.emit('update', {value: value});
            } catch (e) {
                console.error(e);
                this.chain.infoError(e);
            }
            return false;
        },

        /**
         * 削除
         */
        onDelete:function(){
            console.debug('[削除]ボタンがクリックされました。');
            var value = {};
            value.prefSiteSendingId = this.prefSiteSendingId;
            value.version = this._version;
            this.emit('remove', {value: value});
        },

        /**
         * テンプレート選択ダイアログの表示
         */
        showTemplateDialog: function() {
            var selectorDialog = registry.byId('detailSelectorDialog');
            selectorDialog.detailObj = this;
            this.dialog.show();
        }
    });
});
