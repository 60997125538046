/**
 * <土砂災害危険度情報一覧画面>
 *
 * @module app/sedimentrisk/SedimentRiskObservationGrid.js
 */
define(['module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/dom',
    'dijit/registry',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer'
], function(module, declare, lang, dom, registry, IdisGrid, helper) {
    /**
     * <クラスの説明>
     *
     * @class <ウィジェット名>
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'),IdisGrid,
        /** @lends module:app/sedimentrisk/SedimentRiskObservationGrid~SedimentRiskObservationGrid# */
        {
        columns: [
					helper.column('regionName', '地域'),
					helper.column('dosyaCityName', '市/行政区名'),
	                                helper.column('dosyaAreaName', '地区名'),
	                  {
	                      field: '_item',
	                      label: '土砂災害危険度',
                              sortable : false,
	                      formatter: lang.hitch(this, function(item){
	                          // ヘッダーの場合はclassNameを返さない
	                          if(!item){
	                            return;
	                          }
	                      	if(item.level === 1){
	                      		return '大雨注意報基準超過';
	                      	}
	                      	else if(item.level === 2){
	                      		return '大雨警報(土砂災害)基準超過';
	                      	}
	                      	else if(item.level === 3){
	                      		return '3時間後に基準値超過';
	                      	}
	                      	else if(item.level === 4){
	                      		return '2時間後に基準値超過';
	                      	}
	                      	else if(item.level === 5){
	                      		return '1時間後に基準値超過';
	                      	}
	                      	else if(item.level === 6){
	                      		return '現況で基準値超過過';
							}
							else if(item.level === 7){
								return '大雨特別警報(土砂災害)基準超過';
							}else{
	                      		return '';
	                      	}
	                      }),
	                      className: lang.hitch(this, function(item){
	                          // ヘッダーの場合はclassNameを返さない
	                          if(!item){
	                            return;
	                          }
	                      	if(item.level === 1){
	                      		return 'is-heavyRainCaution';
	                      	}
	                      	else if(item.level === 2){
	                      		return 'is-heavyRainAlarm';
	                      	}
	                      	else if(item.level === 3){
	                      		return 'is-sedimentLimitOver3h';
	                      	}
	                      	else if(item.level === 4){
	                      		return 'is-sedimentLimitOver2h';
	                      	}
	                      	else if(item.level === 5){
	                      		return 'is-sedimentLimitOver1h';
	                      	}
	                      	else if(item.level === 6){
	                      		return 'is-sedimentLimitOverNow';
							}
							else if(item.level === 7){
								return 'is-specialHeavyRainAlarm';
							}else{
	                      		return 'is-normal';
	                      	}
	                      })
	                      }
              ]
     });
});
