/**
 * 開設理由入力パーツ(複数選択可)
 * @module app/view/form/ShelterTypeMultiSelector
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/dom-style',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest',
    'idis/view/form/TreeChecker'
], function(module, array, declare, domStyle, CacheStoreModel, CacheTreeRest, TreeChecker) {
    /**
     * 開設理由入力パーツ
     * @class ShelterTypeMultiSelector
     * @extends module:idis/view/form/TreeSelector~TreeSelector
     */
    return declare(module.id.replace(/\//g, '.'), TreeChecker,
        /** @lends module:app/view/tree/ShelterTypeMultiSelector~ShelterTypeMultiSelector# */ {
        // 選択ダイアログのタイトル
        title: '開設理由',

        // ツリー・モデル
        model: null,

        constructor : function() {
            this.model = new CacheStoreModel({
                store: new CacheTreeRest({
                    target: 'dojo/app/shelter/shelterOpeningReason.json'
                })
            });
        },

        /**
         * 選択した値の設定
         */
        _setValueAttr: function() {
            // 継承した親クラスの処理
            this.inherited(arguments);
        }
    });
});
