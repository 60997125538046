define([
    // ここに書く
    'app/safety/SafetyLoginPage',
    'app/safety/SafetyTopPage',
    'app/safety/mail/SafetyMailDetailPage',
    'app/safety/mail/SafetyMailListPage',
    'app/safety/mail/SafetyMailOverviewPage',
    'app/safety/mail/SafetyMailTempPage',
    'app/safety/mail/SafetyStatusRegisterPage',
    // ここまで
], function () { });