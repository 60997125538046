/**
 * ユーザの新規登録・詳細ダイアログ用の基底モジュール。
 * @module app/user/_UserDialogBase
 */
define([
    'module',
    'leaflet',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/dom-style',
    'idis/model/UserInfo',
    'idis/service/Requester',
    'idis/view/Loader',
    'idis/view/page/_PageBase',
    'idis/view/dialog/InfoDialog',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/Textarea',
    'dijit/form/TextBox',
    'idis/view/form/AclButton',
    'idis/view/form/Button'
], function (module, leaflet, declare, lang, array, domStyle, UserInfo,
    Requester, Loader, _PageBase, InfoDialog) {

    return declare(module.id.replace(/\//g, '.'), _PageBase,
        {
            /**
             * ユーザID
             */
            _userId: null,

            /**
             * 緯度経度
             */
            _lat: null,
            _lng: null,

            /**
             * 住所
             */
            _address: null,

            /**
             * 施設種別
             */
            _facilityTypeId: null,

            /**
             * 市区町村
             */
            _municipalityCd: null,

            /**
             * アカウント種別
             */
            _roleCd: null,

            /**
             * 施設
             */
            _facilityId: null,

            /**
             * constructor
             */
            constructor: function () {
            },

            /**
             * buildRendering
             */
            buildRendering: function () {
                this.inherited(arguments);
            },

            /**
             * フォームをリセットする。
             */
            reset: function () {
                this.form.reset();
            },

            /**
             * 入力値の妥当性を検証する。
             */
            validate: function () {
                var formData = this.form.get('value');

                // accountType selection
                if (formData.roleCd === '') {
                    InfoDialog.show('アカウント種類が選択されていません');
                    return false;
                }
                // 更新処理で、アカウント種類（役割）のSelect要素が非活性の場合
                // 画面から値を取得できないため、初期表示時に格納しておいた値をセット
                if(!formData.roleCd && this._roleCd){
                    formData.roleCd = this._roleCd;
                }
                if (formData.roleCd.match(/^R01[0-9]*/)){
                    // 県ユーザ処理
                    if (formData.organization === '') {
                        InfoDialog.show('組織が選択されていません');
                        return false;
                    }
                } else if (formData.roleCd.match(/^R02[0-9]*/)) {
                    // 振興局ユーザ処理
                    // 振興局必須チェック
                    if (formData.regionCd === '') {
                        InfoDialog.show('振興局が選択されていません');
                        return false;
                    }
                    if (formData.organization === '') {
                        InfoDialog.show('組織が選択されていません');
                        return false;
                    }        

                } else if (formData.roleCd.match(/^R03[0-9]*/)) {
                    // 市町村ユーザ処理
                    // 市町村必須チェック
                    if (formData.municipalityCd === '') {
                        InfoDialog.show('市町が選択されていません');
                        return false;
                    }
                    if (formData.organization === '') {
                        InfoDialog.show('組織が選択されていません');
                        return false;
                    }
                } else if (formData.roleCd.match(/^R04[0-9]*/)) {
                    // 関係機関ユーザ処理
                    if (formData.organization === '') {
                        InfoDialog.show('組織が選択されていません');
                        return false;
                    }
                }
                // 市町村必須チェック
                // if (formData.municipalityCd === '') {
                //     InfoDialog.show('市町が選択されていません');
                //     return false;
                // }
                // organization
                if (formData.organization === '') {
                    InfoDialog.show('入力チェック', '組織が選択されていません');
                    return false;
                }
                // account name
                if (formData.userId === '') {
                    InfoDialog.show('入力チェック', 'ユーザIDが入力されていません');
                    return false;
                }
                if (formData.userName === '') {
                    InfoDialog.show('入力チェック', 'ユーザ名が入力されていません');
                    return false;
                }
                // password
                if (this.title.innerHTML === '利用者情報新規登録' && formData.userPassword === '') {
                    InfoDialog.show('入力チェック', 'パスワードが入力されていません');
                    return false;
                }
                if (formData.userPassword !== formData.passwordConfirm) {
                    InfoDialog.show('入力チェック', '入力されたパスワードが一致しません');
                    return false;
                }
                if (formData.userPassword !== '' && !this.isValidPassword(formData.userPassword)) {
                    InfoDialog.show('入力チェック', '入力されたパスワードが規則を満たしません。半角の英数および記号で１２文字以上、２０文字以内で入力ください');
                    return false;
                }
                var lat = formData.latitude;
                var lng = formData.longitude;
                if (lat) {
                    if (27 > lat || lat > 35) {
                        InfoDialog.show('入力チェック', '長崎県内の緯度を入力ください');
                        return false;
                    }
                    var latnum = String(lat).split('.');
                    var latlength = (latnum[1]) ? latnum[1].length : 0;
                    if (latlength < 1) {
                        InfoDialog.show('入力チェック', '緯度は少数点で入力ください');
                        return false;
                    }
                    else if (latlength > 15) {
                        InfoDialog.show('入力チェック', '緯度は少数第十五位以下で入力ください');
                        return false;
                    }
                }
                if (lng) {
                    if (139 > lng || lng > 142) {
                        InfoDialog.show('入力チェック', '長崎県内の経度を入力ください');
                        return false;
                    }
                    var lngnum = String(lng).split('.');
                    var lnglength = (lngnum[1]) ? lngnum[1].length : 0;
                    if (lnglength < 1) {
                        InfoDialog.show('入力チェック', '経度は少数点で入力ください');
                        return false;
                    }
                    else if (lnglength > 15) {
                        InfoDialog.show('入力チェック', '経度は少数第十五位以下で入力ください');
                        return false;
                    }
                }
                return true;
            },

            /**
             * パスワードの妥当性を検証する。
             */
            isValidPassword: function (userPassword) {
                // 長さ
                if (userPassword.length < 12 || userPassword.length > 20) {
                    return false;
                }

                // アルファベットが含まれているか
                if (/[^\x21-\x7e]/.test(userPassword)) {
                    return false;
                }

                return true;
            },

            /**
             * 施設名称一覧の生成
             */
            buildFacilityOption: function () {
                this.facilitySelect.set('disabled', true);
                if (!this._facilityTypeId) {
                    return;
                }
                if (!this._municipalityCd) {
                    return;
                }

                if (!this._roleCd) {
                    return;
                }
                var facilityList = [];
                var promise = Requester.get('./api/facility/tokyo' +
                    '?facilityTypeId=' + this._facilityTypeId +
                    '&municipalityCd=' + this._municipalityCd +
                    '&roleCd=' + this._roleCd);

                return Loader.wait(promise)
                    .then(lang.hitch(this, function (result) {
                        console.log(result);
                        facilityList.push({
                            label: '&nbsp;',
                            value: null
                        });
                        array.forEach(result.items, function (item) {
                            facilityList.push({
                                label: item.facilityName,
                                value: item.facilityId
                            });
                        });
                        this.facilitySelect.set('options', facilityList);
                        this.facilitySelect.set('disabled', false);
                    }));
            }
        });
});
