/**
 * パスワードリセット用ダイアログモジュール。
 * @module app/login/view/PasswordResetDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/PasswordResetDialog.html',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    'idis/view/page/_PageBase',
    'app/config',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'dijit/form/ValidationTextBox',
    'idis/view/form/Button'
], function (module, declare, lang, template, Requester, DialogChain, Loader, _PageBase, config) {
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        {
            // テンプレート文字列
            templateString: template,

            constructor: function () {
                // ベースクラスのコンストラクタを呼ぶ
                this.inherited(arguments);
                this.chain = DialogChain.get(this);
            },

            initDialog: function () {
				this.form.reset();
                this.tel.innerHTML = config.helpdesk.tel;
                this.mail.innerHTML = config.helpdesk.mail;
			},

            // テンプレートHTML内内でformに対し、data-dojo-attach-event="onSubmit:onSubmit"でこのメソッドを紐付け
            // 更新ボタンをtype="submit"にしているので、ボタン押下時にフォームがsubmitされて呼ばれる
            onSubmit: function () {
                try {
                    var sendData = this.form.get('value');
                    // 入力チェック
                    if (this.form.validate()) {
                        // ユーザIDよりユーザ情報を取得
                        Loader.wait(Requester.get('/api/password/' + sendData.userId)
                            .then(lang.hitch(this, function (user) {
                            // ユーザIDが存在しない場合
                            if(!user){
                                this.chain.info('入力されたユーザIDは存在しません。', 'エラー');
                                return false;
                            } else if(!user.officialMailAddress && !user.personalMailAddress){
                                // メールアドレスが登録されていない場合
                                var msg = 'メールアドレスが未登録のため、パスワードリセット用URLを送信できません。<br>' +
                                    'ヘルプデスクにお問い合わせください。<br><br>'+
                                    '【ヘルプデスク】<br>'+
                                    '（電話）' + config.helpdesk.tel + '<br>'+
                                    '（メール）' + config.helpdesk.mail;
                                this.chain.info(msg, 'エラー');
                                return false;
                            } else {
                                // パスワードリセット用URLの送信処理を呼び出す
                                Loader.wait(Requester.get('/api/password/sendUrl/' + sendData.userId)
                                    .then(lang.hitch(this, function () {
                                        var msg = 'ユーザID： <b>' + sendData.userId + '</b> の登録メールアドレスに<br>';
                                            msg += 'パスワードリセット用のURLを送信しました。';
                                        this.chain.info(msg,'送信完了');
                                    }, function (error) {
                                        console.log('error Send PasswordResetURL');
                                        this.chain.infoError(error);
                                    }
                                )));
                                // パスワードリセットダイアログを閉じる
                                this.getParent().hide();
                            }
                        })));
                    }
                } catch (e) {
                    console.error(e);
                }
                return false;
            }
        }
    );
});