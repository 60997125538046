/**
 * グループ管理画面用モジュール。
 *
 * @module app/safety/group/SafetyGroupAdminPage
 */
define(['module',
        'dojo/_base/declare',
        'dojo/_base/lang',
        'dojo/text!./templates/SafetyGroupAdminPage.html',
        'idis/view/page/_PageBase',
        'idis/service/Requester',
        'idis/store/IdisRest',
        'idis/view/Loader',
        'idis/view/dialog/DialogChain',
        // 以下、変数で受けないモジュール
        'dijit/Dialog',
        'dijit/form/Form',
        'dijit/form/Select',
        'dijit/form/TextBox',
        'dijit/form/CheckBox',
        'dijit/layout/BorderContainer',
        'dijit/layout/ContentPane',
        'idis/view/form/Button',
        'idis/view/form/DateInput',
        'idis/view/form/DateTimeInput',
        'app/view/form/GroupDivisionSelector',
        'app/view/form/SafetyEmployeeSelector',
        'app/safety/group/SafetyGroupRegisterDialog',
        'app/safety/group/SafetyGroupDetailDialog',
        'app/safety/group/SafetyGroupGrid'],
    function(module, declare, lang, template, _PageBase, Requester, IdisRest, Loader, DialogChain) {
        /**
         * グループ管理画面
         *
         * @class SafetyGroupAdminPage
         * @extends module:idis/view/page/_PageBase~_PageBase
         */
        return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app_safety/group/SafetyGroupAdminPage~SafetyGroupAdminPage# */
        {

            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--safety',

            // グリッド用データ格納オブジェクト
            grpStore: null,
            
            // グループコード
            groupCd: '',

            /**
             * 変数初期化メソッド
             */
            constructor: function() {
                // グループ管理タブ用データ格納用オブジェクト
                this.grpStore = new IdisRest({
                    idProperty: 'groupCd',
                    target: '/api/safety/groups'
                });

                // ダイアログ連鎖を登録
                // 引数に与えたウィジェットのthis.ownを呼び出し、
                // ウィジェットが破棄された際にダイアログ連鎖が破棄されるようになる
                this.chain = DialogChain.get(this);

            },

            /**
             * DOMノードを生成するためのメソッド
             */
            buildRendering: function() {
                this.inherited(arguments);
                this.initGroupRegisterDialog();
                this.initGroupDetailDialog();
                this.initSafetyGroupGrid();
                
                this.own(this.safetyGroupRegisterDialog);
                this.own(this.safetyGroupDetailDialog);
            },

            /**
             * グループ管理タブ用
             * 登録ダイアログのformが投稿された際の動作を設定する。
             */
            initGroupRegisterDialog: function() {
                // 登録ダイアログの最初の子要素が登録画面
                var dialog = this.safetyGroupRegisterDialog;
                var page = dialog.getChildren()[0];
                page.on('register', lang.hitch(this, function(evt) {
                    // 追加確認ダイアログを表示
                    this.chain.confirmAdd(function(chain) {
                      // OKが押された場合
                      // 追加処理と共にローディング表示
                      this.grpStore.add(evt.value).then(function() {
                        // 成功時（POST結果はグリッドが自動的に反映）
                        // 登録ダイアログを閉じる
                        dialog.hide();
                        // 完了ダイアログを表示
                        chain.infoComplete();
                      }, function(err) {
                        // 失敗時
                        chain.infoError(err);
                      });
                  });
                }));
            },

            /**
             * グループ管理タブ用
             * 詳細ダイアログのformが投稿された際の動作を設定する。
             */
            initGroupDetailDialog: function() {
                // 詳細ダイアログの最初の子要素が詳細画面
                var dialog = this.safetyGroupDetailDialog;
                var page = dialog.getChildren()[0];
                // 更新ボタンが押された際の動作
                page.on('update', lang.hitch(this, function(evt) {
                    // 更新確認ダイアログを表示
                    this.chain.confirmPut(function(chain) {
                      // OKが押された場合
                      // ダイアログから渡されたform情報にグループコードを追加
                      evt.value.groupCd = this.groupCd;
                      // 追加処理と共にローディング表示
                      Loader.wait(this.grpStore.put(evt.value)).then(function() {
                        // 成功時（POST結果はグリッドが自動的に反映）
                        // 登録ダイアログを閉じる
                        dialog.hide();
                        // 完了ダイアログを表示
                        chain.infoComplete();
                      }, function(err) {
                        // 失敗時
                        chain.infoError(err);
                     });
                   });
                }));
                // 削除ボタンが押された際の動作
                page.on('delete', lang.hitch(this, function() {
                    // 削除確認ダイアログを表示
                    this.chain.confirmDel(lang.hitch(this, function(chain) {
                      // OKが押された場合
                      // 追加処理と共にローディング表示
                      Loader.wait(this.grpStore.remove(this.groupCd)).then(function() {
                        // 成功時（結果はグリッドが自動的に反映）
                        // ダイアログを閉じる
                        dialog.hide();
                        // 完了ダイアログを表示
                        chain.infoComplete();
                      }, function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                  }));
                }));
            },

            /**
             * グループ管理タブ用
             * グリッドを初期化する。
             */
            initSafetyGroupGrid: function() {
                this.safetyGroupGrid.set('collection',this.grpStore.filter());
                // グリッドの一覧ボタンクリック時の動作を設定する
                // helper.buttonColumnでフィールド名に指定した'list'と'ButtonClick'の結合がボタンクリック時のイベント名
                this.safetyGroupGrid.on('groupDetailButtonClick', lang.hitch(this, function(evt){
                    // 詳細ダイアログを表示する
                    this.showGroupDetailDialog(evt.item);
                }));
            },

            /**
             * 検索条件に合致するグループ情報を表示する。
             */
            onGroupSearch: function() {
                try {
                    if (this.groupForm.validate()) {
                        // 入力値が正常ならグリッドの検索条件を更新
                        this.updateGroupGridQuery(this.groupForm.get('value'));
                    }
                } catch (e) {
                    console.error(e);
                }
                return false;
            },

            /**
             * グループ管理タブ用の検索
             * @param value groupFormの情報
             */
            updateGroupGridQuery: function(value) {
                // 入力値を元にグリッド用フィルターを作成
                var groupFilter = new this.grpStore.Filter();
                if (value.groupName) {
                    groupFilter = groupFilter.eq('groupName',value.groupName);
                }
                // filterに対応するcollectionを取得
                var collection = this.grpStore.filter(groupFilter);
                // collectionをグリッドにセットする（サーバーにリクエストされる）
                this.safetyGroupGrid.set('collection', collection);
            },

            /**
             * グループ管理タブ用
             * 新規登録ダイアログを表示する。
             */
            showGroupRegisterDialog:function(){
                // 詳細ダイアログの最初の子要素が詳細画面
                var dialog = this.safetyGroupRegisterDialog;
                var page = dialog.getChildren()[0];
                page.form.reset();

                this.safetyGroupRegisterDialog.show();
            },

            /**
             * グループ管理タブ用
             * 詳細ダイアログを表示する。
             */
            showGroupDetailDialog: function(item) {
                // 詳細ダイアログの最初の子要素が詳細画面
                var dialog = this.safetyGroupDetailDialog;
                var page = dialog.getChildren()[0];
                page.form.reset();

                var url = '/api/safety/groups/' + item.groupCd;
                var self = this;
                // IDをキーにグループ情報を再度サーバから取得し、ダイアログのformに設定
                Requester.get(url).then(function(data) {
                    self.setDetailDialogForm(data);
                }, lang.hitch(this, function(error) {
                    // 取得に失敗した場合は、ログ出力とメッセージのダイアログ表示を行う。
                    console.log(error);
                    this.chain.info('グループ情報の取得に失敗しました。', 'エラー');
                }));
                // 選択されたグループコードを設定
                this.groupCd = item.groupCd;
                // ダイアログを表示
                this.safetyGroupDetailDialog.show();
            },
            
            /**
             * @param data DBから取得したデータ
             * 詳細ダイアログを表示する前に、ダイアログのform情報を設定する。
             */
            setDetailDialogForm: function(data) {
                var page = this.safetyGroupDetailDialog.getChildren()[0];
                // 画面上のフォームをリセット
                // （ダイアログ内画面のテンプレートHTMLでdata-dojo-attach-point="form"指定済み）
                page.form.reset();
                page.groupName.set('value', data.groupName);
                // セレクタに[]を渡すとエラーが発生するのでvalueがある時のみsetする
                // 取得した課コードをダイアログのセレクタに設定
                if (data.divisionCds[0]) {
                    page.divisionCds.set('value', data.divisionCds); 
                }
                if (data.employeeCds[0]) {
                    // 取得した職員コードをダイアログのセレクタに設定
                    page.employeeCds.set('value', data.employeeCds);                    
                }
                page.note.set('value', data.note);
            },

            /**
             * ウィンドウの幅に合わせてBorderContainerをリサイズし、タブ内のレイアウトを整理する。
             * (startup同様、画面描画時に呼ばれる)
             * @param changeSize
             * @param resultSize
             */
            resize: function(changeSize, resultSize) {
                // 中のContentPaneも追随してリサイズするため、レイアウト崩れを防止できる。
                this.borderContainer.resize(changeSize, resultSize);
            },

            /**
             * 帳票出力
             */
            outputListExcel: function() {
                //TODO 帳票出力はサーバのAPIを直接コールするよう修正
                console.log('[帳票出力]ボタンがクリックされました。');
            }
        });
    });
