/**
 * <モジュールの説明>
 * @module app/view/page/<ウィジェット名>
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/json',
    'dojo/dom-style',
    'dojo/text!./templates/TrafficFormDialog.html', // テンプレート文字列
    'idis/view/page/_PageBase', // 共通基底クラス
    'idis/view/dialog/InfoDialog',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    // 以下、変数で受けないモジュール
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'dijit/form/RadioButton',
    'app/view/form/MunicipalitySelector',
    'dijit/form/Form'
], function(module, declare, lang, Deferred, JSON, domStyle, template, _PageBase, InfoDialog, DialogChain, Loader) {
    /**
     * <クラスの説明>
     * @class <ウィジェット名>
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/<ウィジェット名>~<ウィジェット名># */ {
        // テンプレート文字列
        templateString: template,

        constructor: function(){
            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
        },

        startup:function(){
            this.inherited(arguments);
        },

        // 出力帳票の種類を変更したときに「規制開始日時」の入力可否を変更する
        // selectFlg 0:交通規制情報一覧 1:県様式
        onSelectFlgChange: function() {
            console.debug('規制開始日時の入力可否を変更します。');
            if (this.form.get('value').selectFlg === '0') {
                domStyle.set(this.regStartTimestampArea, 'display', '');
            } else {
                domStyle.set(this.regStartTimestampArea, 'display', 'none');
            }
        },

        // 帳票出力を行う前に入力チェックを行う
        onOutputButtonClick: function() {

            var repdatefrom = this.regStartTimestampFrom._date.displayedValue;
            var reptimefrom = this.regStartTimestampFrom._time.displayedValue;
            var repdateto = this.regStartTimestampTo._date.displayedValue;
            var reptimeto = this.regStartTimestampTo._time.displayedValue;

            if (this.form.get('value').selectFlg === '0') {
                if (repdatefrom === '' || reptimefrom === '' ||
                        repdateto === '' || reptimeto === '') {
                    console.debug('日時が選択されていません');
                    if (!this.infoDialog) {
                        this.infoDialog = new InfoDialog({
                            title : 'エラー',
                            content : '日時が選択されていません'
                        });
                    }
                    this.infoDialog.show();
                    this.infoDialog = null;
                    return;
                }
            }

            this.downloadExcel(repdatefrom, reptimefrom, repdateto, reptimeto);
        },

        //帳票出力
        downloadExcel : function(repdatefrom, reptimefrom, repdateto, reptimeto) {
            //repdateをYYYYMMDD形式に変換
            repdatefrom = repdatefrom.replace(/\//g,'');
            repdateto = repdateto.replace(/\//g,'');
            //reptimeをhhss形式に変換
            reptimefrom = reptimefrom.replace(':', '');
            reptimeto = reptimeto.replace(':', '');

            var selectId = this.form.get('value').selectFlg;
            var reqid = null;
            var fileName = null;
            var paramList = [];
            if (selectId === '0') {
                reqid = 'TRAFFIC_REG_LIST_EX';
                fileName = 'TrafficRegList.xlsx';
                paramList = [
                    {key: 'repdatefrom', value: repdatefrom},
                    {key: 'repdateto', value: repdateto},
                    {key: 'reptimefrom', value: reptimefrom},
                    {key: 'reptimeto', value: reptimeto}
                ];
            } else if (selectId === '1') {
                reqid = 'TRAFFIC_REG_PREF_NO10STYLE_EX';
                fileName = 'TrafficRegPrefNo10style.xlsx';
            }

            var data4xoblosDownload = {
                fileName: fileName,
                reqid: reqid,
                paramList: paramList
            };

            var promise = this.download(data4xoblosDownload).then(lang.hitch(this, function() {
                this.infoDialog = new InfoDialog({
                    title : 'ダウンロード完了',
                    content : 'ダウンロードが完了しました。'
                });
                this.infoDialog.show();

            }), lang.hitch(this, function(error) {
                console.error(error);
                this.infoDialog = new InfoDialog({
                    title : 'エラー',
                    content : 'エラーが発生しました。管理者にお問い合わせください。'
                });
                this.infoDialog.show();
            }));

            Loader.wait(promise);
        },

        // 帳票のダウンロード
        download: function(data) {
            var deferred = new Deferred();

            var xhr = new XMLHttpRequest();
            xhr.open('POST', '/api/xoblos/download', true);
            xhr.responseType = 'arraybuffer';
            xhr.setRequestHeader( 'Content-Type', 'application/json' );
            xhr.onload = function() {

                // エラー時は処理を止める
                if(xhr.status !== 200){
                    deferred.reject('status error:'+ xhr.status);
                    return;
                }
                // ファイル名をレスポンスヘッダーから取り出す
                var contentDisposition = this.getResponseHeader('content-disposition');
                var inputFileName = contentDisposition.replace(/^.*"(.*)"$/, '$1');

                var arrayBuffer = this.response;
                var blob = new Blob([arrayBuffer], {type: 'application/octet-stream'});
                var baseFileName = '';
                switch (inputFileName) {
                    case 'TrafficRegList.xlsx':
                        baseFileName = '道路通行規制情報一覧.xlsx';
                        break;
                    case 'TrafficRegPrefNo10style.xlsx':
                        baseFileName = '道路規制（積雪・凍結に伴う道路情報）.xlsx';
                        break;
                }
                var fileName = baseFileName.replace(/^.*"(.*)"$/, '$1');

                // IE10+
                if(window.navigator.msSaveOrOpenBlob){
                    window.navigator.msSaveOrOpenBlob(blob, fileName);
                } else {
                    // 擬似的にAタグを作成
                    var link = document.createElement('a');
                    link.style = 'display: none';
                    document.body.appendChild(link);

                    // AタグのURLにバイナリデータをセット
                    var url = window.URL.createObjectURL(blob);
                    link.href = url;

                    // ファイル名をセット
                    link.download = fileName;

                    // 擬似的にリンクをクリック
                    link.click();
                    // 参照を解放
                    window.URL.revokeObjectURL(url);
                    link.remove();
                }

                deferred.resolve();

            };
            xhr.send(JSON.stringify(data));

            return deferred.promise;
        },

        /**
         * 押された帳票出力ボタンのIDをセットする
         */
        setSelectId : function(id) {
            this.form.set('value', {selectId:id});
        },

        /**
         * 月や日付を2桁にゼロpaddingする
         */
        _zeroPadding: function(month) {
            return ('00' + month).slice(-2);
        }
    });
});
