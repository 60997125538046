/**
* 降灰情報一覧画面と噴火降灰速報画面用モジュール。
* @module app/volcano/AshDetailPage
* @module app/volcano/AshBreakingNewsDetailPage
*/

define([
    'module',
    'dojo/_base/declare',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    // 以下、変数として受け取らないモジュール
    'dojox/lang/functional/array'
], function(module, declare, IdisGrid, helper) {

    return declare(module.id.replace(/\//g, '.'), IdisGrid, {
        // IdisGrid拡張：行自体にCSSクラスを追加する場合に指定する
        rowClassName: function(item) {
            return item && item.pubStatus === 1 ? 'is-public' : '';
        },

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            helper.column('vwName', '警報・予報種別', {
                formatter: function(item) {
                    if (item === '多量の降灰') {
                        return '多量';
                    }
                    else if (item === 'やや多量の降灰') {
                        return 'やや多量';
                    }
                    else if (item === '少量の降灰') {
                        return '少量';
                    }
                    return item;
                }
            }),
            helper.column('plumeDirection', '流向'),
            helper.column('sizeTypeThickness', '降灰の厚さ/噴石の大きさ', {
                formatter: function(item) {
                    if (item.thickness >= 1) {
                        return '1mm 以上';
                    }
                    else if (item.thickness >= 0.1 && item.thickness < 1) {
                        return '0.1mm<厚さ<1mm';
                    }
                    else if (item.thickness < 0.1 && item.thickness !== 0) {
                        return '0.1mm 未満';
                    }
                    else if (item.rock !== 0) {
                        return item.rock;
                    }
                    return item.amount;
                }
            }),
            helper.column('areaIName', '対象市町村')
        ]
    });
});
