/**
 * 組織情報出力用モジュール
 * @module app/organization/OrganizationFormDialog
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/json',
    'dojo/string',
    'dojo/text!./templates/OrganizationFormDialog.html', // テンプレート文字列
    'idis/view/page/_PageBase', // 共通基底クラス
    'idis/view/dialog/InfoDialog',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    'idis/control/Router',
    'app/model/Region',
    '../config',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    'dojo/dom-style',
    // 以下、変数で受けないモジュール
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'dijit/form/RadioButton',
    'app/view/form/CustomizableMunicipalitySelector',
    'dijit/form/Form'
], function(module, declare, lang, Deferred, JSON, dStr, template, _PageBase, InfoDialog,
        DialogChain, Loader, Router, Region, config, UserInfo, USER_TYPE, domStyle) {
    /**
     * <クラスの説明>
     * @class <ウィジェット名>
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/<ウィジェット名>~<ウィジェット名># */ {
        // テンプレート文字列
        templateString: template,
        
        constructor: function(){
            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
        },
        
        initDialog: function() {
            if (UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd &&
                    UserInfo.getUserType() === USER_TYPE.MUNICIPALITY){
                // 市町村ユーザの場合のみ、選択ボタンを非活性にする
                this.municipalitySelectorNode.set('noAllButton', false);
                this.municipalitySelectorNode.set('disabled', true);
            }
            if (UserInfo.getUserType() === USER_TYPE.REGION) {
                domStyle.set(this.municipalitySelectorNode.clearButton.domNode, 'display', 'none');
            }
        },

        validate: function() {
            if (!this.municipalitySelectorNode || this.municipalitySelectorNode.value === '') {
                InfoDialog.show('入力チェック', '自治体が選択されていません');
                return false;
            }
            
            return true;
        },
        
        //組織情報の帳票出力
        onOutputOrganizationListExcel : function() {
            
            if (this.validate()) {
                var data4xoblosDownload;
                this.isRegion(this.municipalitySelectorNode.value).then(lang.hitch(this, function(isRegion){

                    var municipalityCd = null;
                    var regionCd = null;
                    var reportTime = new Date();

                    // 帳票対象の道・市町村・振興局のコードを取得する
                    if(isRegion){
                        regionCd = this.municipalitySelectorNode.value;                    
                    } else if(this.municipalitySelectorNode.value && 
                            this.municipalitySelectorNode.value !== config.municInfo.prefCd){
                        municipalityCd = this.municipalitySelectorNode.value;
                    } else if (this.municipalitySelectorNode.value &&
                            this.municipalitySelectorNode.value === config.municInfo.prefCd) {
                        municipalityCd = config.municInfo.prefMunicCd;
                    }

                    // xoBlos形式で報告日時を取得する
                    reportTime = this._getTimestampStr(reportTime);

                    data4xoblosDownload = {
                            fileName: 'OrganizationInfoList.xlsx',
                            reqid: 'ADIS_M_ORGANIZATION_EX',
                            paramList: [
                                {key: 'municipalityCd', value: municipalityCd},
                                {key: 'regionCd', value: regionCd},
                                {key: 'reportTime', value: reportTime}]
                    };
                }));
                var promise = this.download(data4xoblosDownload).then(lang.hitch(this, function() {
                    this.infoDialog = new InfoDialog({
                        title : 'ダウンロード完了',
                        content : 'ダウンロードが完了しました。'
                    });
                    this.infoDialog.show();
                    Router.moveTo('organization');

                }), lang.hitch(this, function(error) {
                    console.error(error);
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : 'エラーが発生しました。管理者にお問い合わせください。'
                    });
                    this.infoDialog.show();
                }));

                Loader.wait(promise);
            }
        },
        
        // 選択したコードがregionCdなのかmunicipalityCdなのか確かめる。
        // regionCdの場合はtrueが帰る
        isRegion: function(cd){
            return Region.store.get(cd).then(lang.hitch(this, function(region){
                return region ? true : false;
            }));
        },
     
         // Date形式のtimestampをxoBlosインターフェイス仕様のtimestamp文字列に置き換える。
        _getTimestampStr: function(reportTime){
            
            if(!reportTime){
                return '';
            }
            var year = dStr.pad(reportTime.getFullYear(), 4);
            var month = dStr.pad(reportTime.getMonth() + 1, 2);
            var date = dStr.pad(reportTime.getDate(), 2);
            var hour = (reportTime.getHours()) ? dStr.pad(reportTime.getHours(), 2) : '00';
            var min = (reportTime.getMinutes()) ? dStr.pad(reportTime.getMinutes(), 2) : '00';

            return year + '/' + month + '/'  + date + ' '  + hour + ':'  + min;
        },

        // 帳票のダウンロード
        download: function(data) {
            var deferred = new Deferred();

            var xhr = new XMLHttpRequest();
            xhr.open('POST', '/api/xoblos/download', true);
            xhr.responseType = 'arraybuffer';
            xhr.setRequestHeader( 'Content-Type', 'application/json' );
            xhr.onload = function() {

                // エラー時は処理を止める
                if(xhr.status !== 200){
                    deferred.reject();
                    return;
                }
                // ファイル名をレスポンスヘッダーから取り出す
                var contentDisposition = this.getResponseHeader('content-disposition');
                var fileName = contentDisposition.replace(/^.*"(.*)"$/, '$1');

                // バイナリデータを取得
                var arrayBuffer = this.response;
                var blob = new Blob([arrayBuffer], {type: 'application/octet-stream'});
                var baseFileNameJp = '';
                switch (fileName) {
                    case 'OrganizationInfoList.xlsx':
                        baseFileNameJp = '組織情報一覧.xlsx';
                        break;
                }
                fileName = baseFileNameJp.replace(/^.*"(.*)"$/, '$1');

                // IE10
                if(window.navigator.msSaveOrOpenBlob){
                    window.navigator.msSaveOrOpenBlob(blob, fileName);
                } else {
                    // 擬似的にAタグを作成
                    var link = document.createElement('a');
                    link.style = 'display: none';
                    document.body.appendChild(link);

                    // AタグのURLにバイナリデータをセット
                    var url = window.URL.createObjectURL(blob);
                    link.href = url;

                    // ファイル名をセット
                    link.download = fileName;

                    // 擬似的にリンクをクリック
                    link.click();
                    // 参照を解放
                    window.URL.revokeObjectURL(url);
                    link.remove();
                }

                deferred.resolve();

            };
            xhr.send(JSON.stringify(data));

            return deferred.promise;
        }
    });
});
