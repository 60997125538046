/**
 * 開設理由入力パーツ(複数選択可)
 * @module app/timeline/TimelineRiverMultiSelector
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/dom-style',
    'dojo/_base/lang',
    'dojo/topic',
    'idis/service/Requester',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest',
    'idis/view/form/TreeChecker'
], function(module, array, declare, domStyle, lang, topic, Requester, CacheStoreModel, CacheTreeRest, TreeChecker) {
    /**
     * 開設理由入力パーツ
     * @class ShelterTypeMultiSelector
     * @extends module:idis/view/form/TreeSelector~TreeSelector
     */
    return declare(module.id.replace(/\//g, '.'), TreeChecker,
        /** @lends module:app/timeline/TimelineRiverMultiSelector~TimelineRiverMultiSelector# */ {
        // 選択ダイアログのタイトル
        title: '対象河川',

        // ツリー・モデル
        model: null,

        // 初期化状況
        isInit: false,
        // 災害情報
        disaster: null,

        constructor : function() {


            this.model = new CacheStoreModel({
                store: new CacheTreeRest({
                    target: 'dojo/app/timeline/TimelineRiver.json'
                })
            });
        },

        /**
         * 選択した値の設定
         */
        _setValueAttr: function() {
            // 継承した親クラスの処理
            this.inherited(arguments);
            if (this.isInit) {
                // 更新された対象河川をセットする
                this.disaster.targetRivers = arguments[0];
                Requester.put('/api/timeline/info/' + this.disaster.disasterId, {
                    data: this.disaster,
                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                    handleAs: 'json',
                    preventCache : true
                }).then(lang.hitch(this, function(data) {
                    console.log(data);
                    // リフレッシュ方法は要検討
                    topic.publish(module.id + '::updated');
                }));
            }
        },
        _setInitFlag: function() {
            this.isInit = true;
        },
        _setDisastr: function(Disaster) {
            this.disaster = Disaster;
        }
    });
});
