/**
* Spectee管理画面用モジュール。
* @module app/spectee/SpecteeAdminPage
*/
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'dojo/dom-style',
    'dojo/dom-class',
    'dojo/text!./templates/SpecteeAdminPage.html',
    'dojo/topic',
    'idis/view/page/_PageBase',
    'idis/view/Loader',
    'idis/control/Router',
    'idis/service/GeoService',
    'idis/service/Requester',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    'leaflet',
    'app/model/DisasterInfo',
    'idis/view/dialog/DialogChain',
    './_SpecteeAdminPageBase',
    '../config',
    // 以下、変数として受け取らないモジュール
    'dijit/form/Form',
    'dijit/form/CheckBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    '../view/form/DisasterSelector',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    './SpecteeAdminGrid',
    './SpecteeFavoriteDialog',
    './NoneAdressIconPanel',
    './SpecteeNotifier'
], function (module, array, declare, lang, locale, domStyle, domClass, template, topic, _PageBase,
    Loader, Router, GeoService, Requester, UserInfo, UserType, leaflet, DisasterInfo, DialogChain, _SpecteeAdminPageBase, config) {

    /**
    * 検索条件格納用オブジェクト
    * @type {Object}
    */
    var specteeyFilterStore = {};

    // GeoServiceを初期化
    var _geoService = new GeoService({ url: config.geocode && config.geocode.url });
    /**
    * Spectee情報画面。
    * @class SpecteeAdminPage
    * @extends module:idis/view/page/_PageBase~_PageBase
    */
    return declare(module.id.replace(/\//g, '.'), _SpecteeAdminPageBase,
    /** @lends module:app/spectee/SpecteeAdminPage~SpecteeAdminPage# */ {
            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--spectee',

            /**
            * データ格納用オブジェクト
            * @type {module:dstore/Store}
            */
            store: null,
            /**
             * 災害ID
             */
            _disasterId: null,
            /**
             * 市町コード
             */
            _municipalityCd: null,
            /**
             * 振興局コード
             */
            _regionCd: null,
            /**
             * 位置情報
             * @type {Object}
             */
            _latlng: null,
            /**
             * 国外情報有無
             * @type {Object}
             */
            _overseas: null,
            /**
             * 住所情報なしカウント
             * @type {Object}
             */
            _count: 0,
            /**
             * page種別
             * @type {String}
             */
            pageType: null,
            /**
             * page種別(管理画面)
             * @type {String}
             */
            PAGE_TYPE: 'ADMIN_PAGE',
            /**
             * 使用するgrid名
             * @type {String}
             */
            GRID_NAME: 'grid',
            /**
             * デフォルトの緯度経度
             */
            INIT_LATLNG: { lat: config.map.latitude, lng: config.map.longitude },
            /**
             * お気に入り情報
             * @type {String}
             */
            IS_FAVORITE: '1',
            /**
             * 処理開始フラグ
             * @type {String}
             */
            FETCH_SPECTEE_INFO_STARTED: false,
            /**
             * 位置情報確定レベル(緯度経度・住所なし)
             * @type {String}
             */
            LATLNG_COMFIRM_LEVEL_NONE: '0',
            /**
             * specteeお気に入り情報の登録・解除権限
             * @type {String}
             */
            SPECTEE_UPDATE_AUTH: '01',
            /**
             * 地図を動的に描画・操作するために発行するトピック
             */
            DRAW_ICON: 'app/spectee/SpecteeAdminGrid' + '::drawIcon',

            /**
             * constructor
             */
            constructor: function () {
                this.chain = DialogChain.get(this);

                // 災害IDをDisasterInfoから取得
                this._disasterId = DisasterInfo.getDisasterId();
                if (!this._disasterId) {
                    console.warn('災害IDが設定させていません。');
                }
                // 市町コード取得
                this._municipalityCd = UserInfo.getMunicipalityCd();
                // 振興局コード取得
                this._regionCd = UserInfo.getRegionCd();
                console.debug('現在のログインユーザID：' + UserInfo.getId());
                console.debug('現在の災害ID：' + DisasterInfo.getDisasterId());

                // ユーザ情報を取得し、ロールによりgrid名を決める
                var specteeAuth;
                if (UserInfo && UserInfo._userInfo && UserInfo._userInfo.authzs) {
                    specteeAuth = UserInfo._userInfo.authzs.F06008;
                    // 市町ユーザの場合は、ユーザ情報に紐づく市町コードを取得しておく
                    if (UserInfo.getUserType() === UserType.MUNICIPALITY) {
                        this._municipalityCd = UserInfo.getMunicipalityCd();
                    }
                    // 振興局ユーザの場合は、ユーザ情報に紐づく振興局コードを取得しておく
                    if (UserInfo.getUserType() === UserType.REGION) {
                        this._regionCd = UserInfo.getRegionCd();
                    }
                }
            },

            /**
             * buildRendering
             * DOMノードを生成するためのメソッド
             */
            buildRendering: function () {
                this.inherited(arguments);
                // 自分が削除された時は関連ダイアログも削除する
                this.own(this.favoriteDialog);
                this.own(this.legendDialog);
                this.own(this.specteeNotifier);
            },

            /**
             * postCreate
             * DOM構築後に呼ばれる
             */
            postCreate: function () {
                console.debug('postCreate');
                this.inherited(arguments);
            },

            /**
             * startup
             */
            startup: function () {
                this.inherited(arguments);
                // 使用するgridを出し分ける
                this.setGridStyle();
                // 保存済み情報表示ボタン
                this.initFavoriteItems();
                // 検索詳細条件入力エリアを非表示
                domStyle.set(this.filterPane.domNode, 'display', 'none');
                // 検索詳細条件入力エリアを非表示
                this.changeSearchItems();
                // 地図を初期化する
                // this.initMap(this.INIT_LATLNG.lat, this.INIT_LATLNG.lng);
                // 所属自治体が初期表示されるようにする。
                if(UserInfo.getMunicipalityCd()) {
                    Requester.get('/api/municipalities/' + UserInfo.getMunicipalityCd(), {
                        headers: {'Content-Type': 'application/json; charset=utf-8'},
                        handleAs: 'json',
                        preventCache : true
                    }).then(lang.hitch(this, function(item) {
                        var zoom = 11;
                        if (UserInfo.getMunicipalityCd() === config.municInfo.prefMunicCd){
                            // 長崎県全体を表示する場合、ズーム値は9
                            zoom = 9;
                        }
                        // 地図を初期化
                        this.initMap(item.latitude, item.longitude, zoom);
                    }), lang.hitch(this, function(error) {
                        console.log(error);
                        this.initMap(config.map.latitude, config.map.longitude, 11);
                        this.chain.info('情報の取得に失敗しました。', 'エラー');
                    }));
                } else if(UserInfo.getRegionCd()) {
                    /* Requester.get('/api/regions/' + UserInfo.getRegionCd(), {
                        headers: {'Content-Type': 'application/json; charset=utf-8'},
                        handleAs: 'json',
                        preventCache : true
                    }).then(lang.hitch(this, function(item) {
                        // 地図を初期化
                        // 振興局を表示する場合、ズーム値は9
                        this.initMap(item.latitude, item.longitude, 9);
                    }), lang.hitch(this, function(error) {
                        console.log(error);
                        this.initMap(config.map.latitude, config.map.longitude);
                        this.chain.info('情報の取得に失敗しました。', 'エラー');
                    })); */
                    // 地図を初期化
                    // 振興局を表示する場合、ズーム値は9
                    this.initMap(UserInfo.getLatitude(), UserInfo.getLongitude(), 9);
                } else {
                    // 地図を初期化
                    this.initMap(config.map.latitude, config.map.longitude, 11);
                }
                // グリッドが描画された際に地図にも位置情報を描画する
                var drawIconEvent = topic.subscribe(this.DRAW_ICON, lang.hitch(this, function (arg) {
                    this.addMarker(arg);
                }));
                this._events.push(drawIconEvent);
                this.own(drawIconEvent);

                // グリッドを初期化する
                this.initGrid(this.PAGE_TYPE);

                // フォームに初期値をセットする
                this.initFormValue();
                // 検索対象期間、DateTimeInputのイベントを定義する
                this.setDateTimeInputEvent();
                // 初期表示用にSpecteeAPIにリクエストする
                this.searchSpecteeInfo();
                // 定期処理を開始する
                /* 必ず解除すること */
                this.startTimer();

                // 周辺被害情報表示
                if (UserInfo.getUserType() === UserType.MUNICIPALITY) {
                    domStyle.set(this.showMarkersButton, 'display','');
                }
                this.showMarkersFlg = false;
                if (this._municipalityCd) {
                    this.fetchDamageReports();
                    this.showDamageReportMarkers();
                }
            },

            /**
             * 日時入力フォームのイベントを定義する。
             */
            setDateTimeInputEvent: function () {
                // 検索対象期間の4つの入力フォーム(日付、日時は別物)のイベントを定義する
                var formArray = [
                    ['postDateTimeFrom', '_date'],
                    ['postDateTimeFrom', '_time'],
                    ['postDateTimeTo', '_date'],
                    ['postDateTimeTo', '_time']
                ];
                array.forEach(formArray, lang.hitch(this, function (form) {
                    // 検索対象期間のフォームイベントを定義する
                    this.own(this[form[0]][form[1]].on('change', lang.hitch(this, function () {
                        // イベント定義時に自動的にSpecteeへのリクエスト処理が走るため
                        // ページ初期表示時のリクエスト処理以降の処理であるかを判定する
                        if (this.FETCH_SPECTEE_INFO_STARTED) {
                            this.searchSpecteeInfo();
                        }
                    })));
                    this[form[0]][form[1]].startup();
                }));
            },

            /**
             * フォームに初期値をセットする。
             */
            initFormValue: function () {
                // ページ遷移する際に検索条件を保管していたかを判定する
                if (Object.keys(specteeyFilterStore).length) {
                    // ページ遷移前に保管した検索条件がある場合

                    // 保管した検索条件をArrayに変換する
                    var queryArray =
                        Object.keys(specteeyFilterStore).map(function (key) { return specteeyFilterStore[key]; });

                    // 保管した検索条件をフォームにセットする
                    Object.keys(specteeyFilterStore).forEach(lang.hitch(this, function (value, idx) {
                        if (value) {
                            var form = {};
                            form[value] = queryArray[idx];
                            this.form.set('value', form);
                        }
                    }));
                } else {
                    // ページ遷移前に保管した検索条件がない場合

                    // 都道府県にチェックを付ける
                    var prefFormObj = {};
                    // form名、valueを組み立てる(form名とvalueは同値)
                    var prefFormValue = 'pref' + config.municInfo.prePrefCd;
                    // 都道府県form用オブジェクトに追加する
                    prefFormObj[prefFormValue] = prefFormValue;
                    // 都道府県formのセット処理
                    this.form.set('value', prefFormObj);

                    // 現在より1時間前の時刻を取得し、検索対象期間(開始)にセットする
                    var time = new Date();
                    time.setDate(time.getDate() - 1);
                    this.postDateTimeFrom.set('value', time);
                }
            },

            /**
             * Specteeへの定期取得処理を開始する。
             */
            startTimer: function () {
                //関数fetchSpecteeInfo()を60000ミリ秒間隔で呼び出す
                this.specteeTimer =
                    setInterval(lang.hitch(this, function () {
                        if (this._isFavorite !== '1') {
                            // お気に入り情報に絞っている場合は処理しない
                            var query = this.buildSpecteeQuery();
                            this.fetchSpecteeInfo(query);
                        }
                    }), 60000);
            },

            /**
             * Spectee/お気に入り情報の検索を開始する。
             */
            searchSpecteeInfo: function () {
                // 検索条件保管用storeを初期化する
                specteeyFilterStore = {};
                var formValue = this.form.get('value');
                // 検索条件を保管する
                specteeyFilterStore = lang.mixin(null, formValue);

                // 情報取得を開始する
                if (this._isFavorite === '1') {
                    this.fetchFavoriteInfo();
                } else {
                    var query = this.buildSpecteeQuery();
                    this.fetchSpecteeInfo(query);
                }
            },

            /**
             * 検索条件に合致するSpectee情報を取得する。
             */
            fetchSpecteeInfo: function (query) {
                Requester.get('/api/spectee/', {
                    query: query
                }).then(lang.hitch(this, function (data) {
                    console.log('SpecteeよりSNS情報を取得');
                    // APIから返却されるレスポンスコードを元に処理する
                    if (data.status === '200') {
                        this.specteeNotifier.processSpectee(data.data);
                        this.lastFetchTime = data.fetchTime;
                        // 住所情報なしゾーンを空にする
                        this.noneAdressIconPanel.initNoneAdressIcon();
                        this.updateGrid(data.data);
                    } else {
                        this.chain.info('SpecteeよりSNS情報を取得できませんでした。一分後に情報を自動取得します。', 'エラー');
                    }

                    // 画面初期表示時の処理が完了した段階でSpectee処理の開始フラグを有効にする
                    // この処理により、画面初期表示時に日時フォームのchangeイベントにより複数回リクエストが飛ぶことを防いでいる
                    if (!this.FETCH_SPECTEE_INFO_STARTED) {
                        this.FETCH_SPECTEE_INFO_STARTED = true;
                    }
                }), lang.hitch(this, function (error) {
                    this.chain.infoError(error);
                }));
            },

            /**
             * Spectee情報取得用のqueryを組み立てる。
             */
            buildSpecteeQuery: function () {
                var formValue = this.form.get('value');

                // フォームの入力値をArrayに変換する
                var formValueArray = Object.keys(formValue).map(function (key) { return formValue[key]; });
                var query = {};
                query.prefIds = null;
                query.eventIds = null;
                query.targetIds = null;
                query.cityIds = null;

                // 都道府県IDと対象・事象IDをカンマ区切りの文字列にする
                array.forEach(formValueArray, function (value) {
                    if (/^(pref)/.test(value)) {
                        var prefValStr = value[0].replace(/pref/, '');
                        query.prefIds = query.prefIds === null ? prefValStr : query.prefIds + ',' + prefValStr;
                    } else if (/^(event)/.test(value)) {
                        var eveValStr = value[0].replace(/event/, '');
                        query.eventIds = query.eventIds === null ? eveValStr : query.eventIds + ',' + eveValStr;
                    } else if (/^(target)/.test(value)) {
                        var targValStr = value[0].replace(/target/, '');
                        query.targetIds = query.targetIds === null ? targValStr : query.targetIds + ',' + targValStr;
                    } else if (/^(city)/.test(value)) {
                        var cityValStr = value[0].replace(/city/, '');
                        query.cityIds = query.cityIds === null ? cityValStr : query.cityIds + ',' + cityValStr;
                    }
                });
                query.keyword = this.form.get('value').keyword;

                // 国外情報を選択している時、queryに追加する
                if (this._overseas){
                    query.categoryIds = '2';
                    query.prefIds = null;
                    query.cityIds = null;
                }

                // 期間検索のqueryを追加する
                var afterDate =
                    this.form.get('value').postDateTimeFrom ? this.form.get('value').postDateTimeFrom.getTime() : 0;
                var beforeDate =
                    this.form.get('value').postDateTimeTo ? this.form.get('value').postDateTimeTo.getTime() : 0;

                // SpecteeAPIでは検索対象期間(開始)に現在時刻以降を指定できないため
                // 現在時刻を超えた場合はアラートを表示し、強制的に現在時刻に変更する
                var now = new Date();
                if (this.form.get('value').postDateTimeFrom > now) {
                    afterDate = now.getTime();
                }
                if (afterDate) {
                    query.afterDate = locale.format(new Date(afterDate), {
                        selector: 'date',
                        datePattern: 'yyyy-MM-dd HH:mm:00'
                    });
                }

                // Spectee APIの仕様上2018年2月27日午前0時0分以前は指定できないため、
                // フォームの値がそれ以前の日時の場合はqueryに追加しない
                var limitDateTime = new Date(2018, 1, 27, 0, 0, 0);
                if (this.form.get('value').postDateTimeTo <= limitDateTime) {
                    beforeDate = now.getTime();
                }
                if (beforeDate) {
                    query.beforeDate = locale.format(new Date(beforeDate), {
                        selector: 'date',
                        datePattern: 'yyyy-MM-dd HH:mm:00'
                    });
                }

                // 最終情報取得日時をセットする
                // この日時と次回情報取得日時までの間に取得された情報が新着情報となる
                query.lastFetchTime = this.lastFetchTime;
                // 災害IDをセットする お気に入り情報に登録済みであるかを判定する際に使用する
                query.disasterId = this._disasterId;
                return query;
            },

            /**
             * お気に入り情報を取得する。
             */
            fetchFavoriteInfo: function () {
                // 災害名が選択されていない場合は処理しない
                if (!this._disasterId) {
                    this.chain.info('災害名を選択してください', 'エラー');
                    return;
                }
                Requester.get('/api/spectee/favoriteCase/', {
                    query: { disasterId: this._disasterId }
                }).then(lang.hitch(this, function (data) {
                    console.log('お気に入り情報を取得');
                    // 配列に詰め直し
                    var list = [];
                    array.forEach(data.items, lang.hitch(this, function (items) {
                        array.forEach(items.data, lang.hitch(this, function (data) {
                            list.push(data);
                        }));
                    }));
                    this.updateGrid(list);
                    // イベント定義時に自動的にSpecteeへのリクエスト処理が走るため
                    // ページ初期表示時のリクエスト処理以降の処理であるかを判定する
                    if (!this.FETCH_SPECTEE_INFO_STARTED) {
                        this.FETCH_SPECTEE_INFO_STARTED = true;
                    }
                }), lang.hitch(this, function (error) {
                    this.chain.infoError(error);
                }));
            },

            /**
             * メモ入力ダイアログを表示する。
             */
            openFavoriteDialog: function (object) {
                this.favoriteDialog.show();
                // ダイアログ中のページウィジェットを取得
                var page = this.favoriteDialog.getChildren()[0];
                page.on('register', lang.hitch(this, function (form) {
                    this.registerFavorite(form);
                }));
                // ダイアログの画面を初期表示する
                page.initDialog(object);
            },

            /**
             * お気に入り情報を登録する。
             */
            registerFavorite: function (form) {
                // 災害名が選択されていない場合は処理しない
                if (!this._disasterId) {
                    this.chain.info('災害名を選択してください', 'エラー');
                    return;
                }
                var msg = '登録します。よろしいですか？';
                this.chain.confirm(msg, lang.hitch(this, function (chain) {
                    // 市町コード取得
                    this._municipalityCd = UserInfo.getMunicipalityCd();
                    // 振興局コード取得
                    this._regionCd = UserInfo.getRegionCd();
                    // お気に入り登録フォーム作成
                    var value = this._toSendValue(form, this._disasterId, this._municipalityCd, this._regionCd);
                    Loader.wait(
                        // 取消処理を実行して取消後の返り値を保持する。
                        Requester.post('/api/spectee/favoriteCase/', {
                            data: value
                        })).then(lang.hitch(this, function () {
                            chain.infoComplete();
                            this.searchSpecteeInfo();
                            this.favoriteDialog.hide();
                        }), function (err) {
                            // 登録失敗
                            chain.infoError(err);
                        });
                }));
            },

            /**
             * お気に入り情報を登録解除する。
             */
            removeFavorite: function (value) {
                // 災害名が選択されていない場合は処理しない
                if (!this._disasterId) {
                    this.chain.info('災害名を選択してください', 'エラー');
                    return;
                }
                var message = 'お気に入り登録を解除します。<br>' + 'よろしいですか？';
                this.chain.confirm(message, lang.hitch(this, function (chain) {
                    Loader.wait(
                        // 取消処理を実行して取消後の返り値を保持する。
                        Requester.del('/api/spectee/favoriteCase/' + value.item.caseId + '/' + this._disasterId, {
                        })).then(lang.hitch(this, function () {
                            chain.infoComplete();
                            this.searchSpecteeInfo();
                        }), function (err) {
                            // 削除失敗
                            chain.infoError(err);
                        });
                }));
            },

            /**
             * Spectee情報のマーカーを生成する
             */
            makeSpecteeMarker: function(item){
                var lat = item.locationInfo[0].geocode.latitude;
                var lng = item.locationInfo[0].geocode.longitude;

                // 位置情報が無い時はマーカーを生成しない
                if(!lat || !lng){
                    return false;
                }

                // 事象別アイコン
                var html = [];
                var leftPosition = -12;
                var specteeEvent = 'other';
                if(!this.isEmpty(item.eventIds)){
                    if(item.eventIds.indexOf('200100002')!==-1 || item.eventIds.indexOf('200100003')!==-1 || item.eventIds.indexOf('200100007')!==-1 || item.eventIds.indexOf('200100009')!==-1){
                        specteeEvent = 'railway';
                    } else if (item.eventIds.indexOf('100100')!==-1){
                        specteeEvent = 'weather';
                    } else if (item.eventIds.indexOf('100200')!==-1){
                        specteeEvent = 'fire';
                    } else if (item.eventIds.indexOf('100300')!==-1){
                        specteeEvent = 'accident';
                    } else if (item.eventIds.indexOf('100400')!==-1){
                        specteeEvent = 'incident';
                    } else if (item.eventIds.indexOf('100500')!==-1){
                        specteeEvent = 'lifeline';
                    } else if (item.eventIds.indexOf('100600')!==-1){
                        specteeEvent = 'other';
                    }
                }
                var specteeEventIcon = '/images/spectee/event_' + specteeEvent + '.png';
                html += '<img src="' + specteeEventIcon + '" height="30px" width="30px"';
                html += ' style="position:absolute;top:-15px;left:' + leftPosition + 'px;">';

    
                var divIcon = leaflet.divIcon({
                    html: html,
                    className: 'damage-point',
                    iconSize: [0, 0],
                    iconAnchor: [3, 3]
                });
    
                var marker = leaflet.marker([lat, lng],{
                    icon: divIcon
                });
    
                return marker;
            },

            /**
             * 詳細画面に遷移する。
             */
            moveToDetailPage: function (evt) {
                if (this.marker) {
                    this.removeMark();
                }
                // 事案IDを渡す
                if (evt && evt.item && evt.item.caseId) {
                    var caseId = evt.item.caseId;
                    var favoriteFlg = evt.item.favoriteFlg;
                    // ブラウザーの遷移処理をキャンセル
                    evt.preventDefault();
                    Router.moveTo('spectee/detail', { 
                        caseId: caseId,
                        favoriteFlg: favoriteFlg });
                }
            },

            /**
             * 保存済み情報表示ボタンが押下された際の挙動。
             */
            onSearchFavoriteButton: function() {
                // ステータスの変更
                this._isFavorite = !this._isFavorite ? '1' : '';
                // 画面上部のフィールドへ変更内容を反映
                this.initFavoriteItems();
                // 検索実行
                this.searchSpecteeInfo();
            },

            /**
             * 保存済み情報表示ステータスを画面上部のフィールドに反映する。
             */
            initFavoriteItems :function() {
                // 保存済み情報ステータスが'1'の場合、「保存済み情報表示中」
                if (this._isFavorite === '1') {
                    this.favoriteButton.setLabel('保存済み情報表示中');
                    domStyle.set(this.showQueryLabel, 'display','none');
                    domStyle.set(this.hideQueryLabel, 'display','none');
                    domStyle.set(this.filterPane.domNode, 'display', 'none');
                    // コンテナをリサイズ
                    this.containerResize();
                    // mapNodeのリサイズ
                    this.map.invalidateSize();
                } else {
                    // 保存済み情報ステータスが'1'でなければ、「全ての情報を表示」
                    this.favoriteButton.setLabel('保存済み情報のみ表示');
                    domStyle.set(this.showQueryLabel, 'display','');
                }
            },

            /**
             * グリッド一覧の表示/非表示
             **/
            onShowGridButtonClick: function(){
                // ラベルの表示/非表示
                domStyle.set(this.showGridButton.domNode, 'display', 'none');
                domStyle.set(this.hideGridButton.domNode, 'display', '');
                // グリッドの表示/非表示
                domStyle.set(this.gridArea.domNode, 'display', '');
                this.form.resize();
                // mapNodeのリサイズ
                this.map.invalidateSize();
            },

            onHideGridButtonClick: function(){
                // ラベルの表示/非表示
                domStyle.set(this.showGridButton.domNode, 'display', '');
                domStyle.set(this.hideGridButton.domNode, 'display', 'none');
                // グリッドの表示/非表示
                domStyle.set(this.gridArea.domNode, 'display', 'none');
                this.form.resize();
                // mapNodeのリサイズ
                this.map.invalidateSize();
            },

            showGrid:function(){
                // ラベルの表示/非表示
                domStyle.set(this.showGridButton.domNode, 'display', 'none');
                domStyle.set(this.hideGridButton.domNode, 'display', '');
                // グリッドの表示/非表示
                domStyle.set(this.gridArea.domNode, 'display', '');
                this.form.resize();
                // mapNodeのリサイズ
                this.map.invalidateSize();
            },

            /**
             * 詳細検索条件欄の表示/非表示
             **/
            onShowQueryButtonClick: function(){
                domStyle.set(this.hideQueryLabel, 'display', '');
                domStyle.set(this.showQueryLabel, 'display', 'none');
                domStyle.set(this.filterPane.domNode, 'display', '');
                domClass.replace(this.hideGrid, 'gridButtonName2');
                domClass.replace(this.showGrid, 'gridButtonName2');
                // コンテナをリサイズ
                this.containerResize();
                // mapNodeのリサイズ
                this.map.invalidateSize();
            },

            onHideQueryButtonClick: function(){
                domStyle.set(this.hideQueryLabel, 'display', 'none');
                domStyle.set(this.showQueryLabel, 'display', '');
                domStyle.set(this.filterPane.domNode, 'display', 'none');
                domClass.replace(this.hideGrid, 'gridButtonName1');
                domClass.replace(this.showGrid, 'gridButtonName1');
                // コンテナをリサイズ
                this.containerResize();
                // mapNodeのリサイズ
                this.map.invalidateSize();
            },

            /**
             * 検索対象地域によって検索条件項目を変更する。（県内情報/国内情報/国外情報）
             */
            changeSearchItems: function(){
                if(this.searchRegionPref.get('checked')){
                    // 「国外情報」から「県内情報」変更した場合は再検索
                    if (this._overseas){
                        this._overseas = false;
                        this.searchSpecteeInfo();
                    }
                    domStyle.set(this.cityChecks, 'display', 'block');
                    domStyle.set(this.prefChecks, 'display', 'none');
                    // コンテナをリサイズ
                    this.containerResize();
                } else if (this.searchRegionDomes.get('checked')){
                    // 「国外情報」から「国内情報」へ変更した場合は再検索
                    if (this._overseas){
                        this._overseas = false;
                        this.searchSpecteeInfo();
                    }
                    domStyle.set(this.cityChecks, 'display', 'none');
                    domStyle.set(this.prefChecks, 'display', 'block');
                    // コンテナをリサイズ
                    this.containerResize();
                    this.searchSpecteeInfo();
                } else if (this.searchRegionInter.get('checked')){
                    this._overseas =true;
                    domStyle.set(this.cityChecks, 'display', 'none');
                    domStyle.set(this.prefChecks, 'display', 'none');
                    // コンテナをリサイズ
                    this.containerResize();
                    this.searchSpecteeInfo();
                }
            },

            /**
             * コンテナをリサイズする。
             */
            containerResize: function(){
                // フォームの高さ固定を一旦解除
                this.form.set('style', {
                    height: null
                });
                // 検索条件ペインの高さ固定を一旦解除
                this.filterPane.set('style', {
                    height: null
                });
                this.mapPane.set('style', {
                    height: null
                });
                // コンテナをリサイズ
                this.container.resize();
                this.mapPane.resize();
            }
        });
});