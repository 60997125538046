define([
    'app/damage/DamageReportPage',
    'app/damage/DamageReportRegisterPage',
    'app/damage/DamageReportRegisterPage',
    'app/damage/DamageReportDetailPage',
    'app/damage/DamageReportAdminPage',
    'app/damage/ScheduledReportPage',
    'app/damage/ScheduledReportDetailPage',
    'app/damage/ScheduledReportPrefPage',
    'app/damage/ScheduledReportPrefDetailPage',
    'app/damage/FDMAReportPage',
    'app/damage/FDMAReportRegisterPage',
    'app/damage/FDMAReportDetailPage',
], function () { });