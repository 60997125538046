define([
    'module',
    'dojo/_base/declare',
    'dojo/json',
    'dojo/text!./templates/UpdatePasswordPage.html',
    'idis/model/UserInfo',

    'idis/service/Requester',
    'idis/control/Router',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    'idis/view/page/_PageBase',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'dijit/form/ValidationTextBox',
    'idis/view/form/Button'
], function (module, declare, json, template, UserInfo, Requester, Router, DialogChain, Loader, _PageBase) {
    /**
     * パスワード変更画面
     * @class UpdatePasswordPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/password/UpdatePassowrdPage~UpdatePassowrdPage# */ {
            // テンプレート文字列
            templateString: template,

            constructor: function () {

                // ベースクラスのコンストラクタを呼ぶ
                this.inherited(arguments);
                this.chain = DialogChain.get(this);
                console.debug('現在のログインユーザID：' + UserInfo.getId());
            },

            buildRendering: function () {
                this.inherited(arguments);
            },

            startup: function () {
                this.userId.set('value', UserInfo.getId());
                this.inherited(arguments);
            },
            // テンプレートHTML内内でformに対し、data-dojo-attach-event="onSubmit:onSubmit"でこのメソッドを紐付け
            // 更新ボタンをtype="submit"にしているので、ボタン押下時にフォームがsubmitされて呼ばれる
            onSubmit: function () {
                try {
                    var sendData = this.form.get('value');
                    sendData.userId = this.userId.get('value');
                    if (this.form.validate()) {
                        if (sendData.userPassword !== sendData.passwordConfirm) {
                            this.chain.info('パスワードが一致しません', 'エラー');
                            return false;
                        }
                        console.log(sendData);
                        // 入力チェックが成功したらsendイベントを発行
                        var jsonStr = json.stringify(sendData);
                        console.log('send data : ' + sendData);
                        this.chain.confirm('登録します。よろしいですか。', function (chain) {
                            var promise = Requester.post('/api/password/update', {
                                data: jsonStr,
                                headers: { 'Content-Type': 'application/json; charset=utf-8' },
                                handleAs: 'json',
                                preventCache: true
                            }).then(function () {
                                console.debug('success register UpdatePassword');
                                //chain.infoComplete(function() {
                                chain.info('完了しました。', '完了', function () {
                                    // 一覧画面に移動
                                    Router.moveTo('/');
                                });

                            }, function (error) {
                                console.log('error register UpdatePassword');
                                chain.infoError(error);
                            });
                            //ローダーの表示
                            Loader.wait(promise);
                        });
                    }
                } catch (e) {
                    console.error(e);
                }
                return false;
            }
        });
});

