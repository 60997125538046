/**
 * <雨量情報情報詳細画面>
 *
 * @module app/observation/rain/RainfallObservationDetailPageGrid
 */
define(['module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/dom',
    'dijit/registry',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    './RainfallObservationCommonColumn',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer'
], function(module, declare, lang, dom, registry, IdisGrid, helper, RainCommonColum) {
    /**
     * 雨量情報情報詳細画面
     *
     * @class RainfallObservationDetailPageGrid
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'),IdisGrid,
        /** @lends module:app/observation/rain/RainfallObservationPageGrid~RainfallObservationPageGrid# */
        {
		columns: [
			helper.column('days', '月日'),
			helper.column('time', '時刻'),
			RainCommonColum.rainfall10Min,
			RainCommonColum.rainfall60Min,
			RainCommonColum.rainfall24Hour,
			RainCommonColum.rainfallCumulative
			// {
			// 	field: 'rainfallEffective90Min',
			// 	label: '半減期1.5時間\n実効雨量[mm]',
			// 	formatter: lang.hitch(this, function(item){
			// 		if (item || item === 0) {
			// 			return item.toFixed(1);
			// 		} else {
			// 			return '-';
			// 		}
			// 	})
			// },
			// {
			// 	field: 'rainfallEffective72Hour',
			// 	label: '半減期72時間\n実効雨量[mm]',
			// 	formatter: lang.hitch(this, function(item){
			// 		if (item || item === 0) {
			// 			return item.toFixed(1);
			// 		} else {
			// 			return '-';
			// 		}
			// 	})
			// }
			// helper.buttonColumn('correct', '修正')
		]
	});
});
