/**
 * 避難状況・報告概況画面用モジュール。
 * @module app/view/page/EvacOrderAdminPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/json',
    'idis/control/Router',
    'idis/store/IdisRest',
    'idis/service/Requester',
    'dojo/text!./templates/SimulationScenarioListPage.html',
    'idis/view/page/_PageBase',
    'idis/view/Loader',
    'idis/view/dialog/DialogChain',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/Button',
    'app/simulation/SimulationScenarioListGrid',
    'app/simulation/SimulationScenarioRegisterDialog',
    'app/simulation/SimulationScenarioPlayHistoryDialog'
], function(module, declare, lang, domStyle, json, Router, IdisRest, Requester,
    template, _PageBase, Loader, DialogChain){
    /**
     * 災害シミュレーションシナリオ一覧画面
     * @class SimulationScenarioListPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/view/page/SimulationScenarioListPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--simulation',

        /**
        * データ格納用オブジェクト
        * @type {module:dstore/Store}
        */
        store: null,
        historyStore: null,

        constructor: function(){
            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
        },

        // DOMノードを生成するためのメソッド
        buildRendering: function() {
            this.inherited(arguments);
            this.initRegisterDialog();
        },

        startup: function() {
            this.inherited(arguments);

            // グリッドを初期化する
            this.initGrid();

        },

        /**
        * グリッドを初期化する。
        */
        initGrid: function() {
            // グリッドの一覧ボタンクリック時の動作を設定する
            this.grid.on('listButtonClick', lang.hitch(this, function(evt) {
                this.onListButtonClick(evt);
            }));
            // グリッドの再生履歴ボタンクリック時の動作を設定する
            this.grid.on('playHistoryButtonClick', lang.hitch(this, function(evt) {
                this.onPlayHistoryButtonClick(evt);
            }));
            this.store = new IdisRest({
                target: '/api/simulations'
            });

            this.grid.set('collection', this.store.filter());
        },

        /**
         * 新規登録ダイアログのformが投稿された際の挙動を設定する。
         */
        initRegisterDialog: function() {
            // 新規登録ダイアログの最初の子要素が画面の情報
            var dialog = this.registerDialog;
            var page = dialog.getChildren()[0];
            var self = this;
            // 新規登録ダイアログからのregisterイベントを取得
            page.on('register', lang.hitch(this, function(env) {
                self.regiaterScenario(env.value);
            }));
        },

        regiaterScenario: function(sendData) {
            //登録確認ダイアログを表示
            this.chain.confirm(lang.hitch(this, function(chain) {
                var jsonStr = json.stringify(sendData);
                var promise =  Requester.post('/api/simulations', {
                    data: jsonStr,
                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                    handleAs: 'json',
                    preventCache : true
                }).then(lang.hitch(this, function(result) {
                    console.log(result);
                    var compMessage = '登録が完了しました。';
                    chain.info(compMessage, '完了', lang.hitch(this, function(){
                        this.chain.hide();
                        this.registerDialog.hide();
                        this.initGrid();
                    }));

                }), function(error) {
                    // 失敗時
                    console.error(error);
                    chain.info(error);
                });
                Loader.wait(promise);
            }));
        },

        /**
         * 検索
         */
        onSubmit: function() {
             try {
                　// 入力値を元にグリッド用フィルターを作成
                　var filter = new this.store.Filter();
                　filter = filter.eq('scenarioName', this.scenarioName.value);

                　// filterに対応するcollectionを取得
                　var collection = this.store.filter(filter);
                　
                　// collectionをグリッドにセットする（サーバーにリクエストされる）
                　this.grid.set('collection', collection);

             } catch (e) {
                 console.error(e);
             }
             return false;
        },

        /**
        *一覧ボタン押下時
        */
        onListButtonClick: function(evt) {
            // イベント一覧へ遷移
            Router.moveTo('simulation/event', {scenarioId: evt.item.scenarioId});
        },

        /**
        *再生履歴ボタン押下時
        */
        onPlayHistoryButtonClick: function(evt) {
            this.showPlayHistoryDialog.show();
            this.playHistoryDialog.initDialog(evt.item.scenarioId);
        },

        showRegisterDialog: function() {
            this.registerDialog.show();
        }
    });
});
