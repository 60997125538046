/**
 * ユーザーを扱うモデル。
 * @module app/model/SafetyEmployee
 */
define([
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest'
], function(CacheStoreModel, CacheTreeRest) {
    /**
     * シングルトンを返す。
     */
    return new CacheStoreModel({
        store: new CacheTreeRest({
            target: '/api/safety/groups/employees4tree'
        })
    });
});
