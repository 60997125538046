/**
 * 安否状況回答画面用モジュール。
 * @module app/notice/view/SafetyStatusRegisterPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/text!./templates/SafetyStatusRegisterPage.html',
    'dijit/form/Select',
    'idis/control/Locator',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    'idis/service/Requester',
    'idis/view/page/_PageBase',
    // 以下、変数から参照されないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/form/Form',
    'idis/view/form/WordCountTextarea',
    'idis/view/form/Button'
], function(module, declare, lang, domStyle, template, Select,
    Locator, DialogChain, Loader, Requester, _PageBase) {
    /**
     * 安否状況回答画面。
     * @class SafetyStatusRegisterPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/SafetyStatusRegisterPage~SafetyStatusRegisterPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--safety',

        /**
         * 変数の初期化。
         */
        constructor: function() {
            // &key=以降に入っている、安否状況回答者を特定する文字列※をURLから取得
            // ※取得する文字列は職員番号と配信IDをサーバサイドで暗号化したもの
            this.key = Locator.getQuery().key;
            // 問３があるか否かのフラグ
            this.isQ3 = false;
            this.chain = DialogChain.get(this);
        },

        /**
         * DOMノードを生成するためのメソッド
         */
        buildRendering: function() {
            this.inherited(arguments);
            // 質問と回答をサーバサイドから取得しform情報を初期化する
            this.initForm();
        },

        /**
         * メールの内容に応じて回答セレクタを生成する。
         */
        initForm: function() {
            var self = this;
            // 質問のリストを取得する
            // （回答オブジェクトは各質問オブジェクトの中に格納されている）
            Requester.get('/api/safety/question?key=' + this.key, {
            }).then(function(data) {
                // 質問リストから質問を取得
                data.questions.forEach(function(question) {
                    // リスト内の番号によってどのラベルに設定するかを判断する
                    switch (question.no) {
                    case 1 : self.a1Label.innerHTML = question.question +':';
                             self.setSelectOptions(question.selections, self.a1);
                             break;
                    case 2 : self.a2Label.innerHTML = question.question +':';
                             self.setSelectOptions(question.selections, self.a2);
                             break;
                    case 3 : self.a3Label.innerHTML = question.question +':';
                             self.setSelectOptions(question.selections, self.a3);
                             self.isQ3 = true;
                             break;
                    default : break;
                    }
                });
            }, function(error) {
                console.log(error);
            });
            // 問３がない場合は、3つ目のセレクトボックスを非表示にする
            if (!this.isQ3) {
                domStyle.set(this.a3Division, 'display', 'none');
                // domStyle.set(this.a3.domNode, 'display', 'none');
            }
        },

        /**
         * @param answers 回答リスト
         * @param selectbox optionを設定する対象となるセレクトボックス
         * 回答をもとにセレクトボックスを設定する。
         */
        setSelectOptions: function(answers, selectbox) {
            // オプションの配列
            var optionList = [];
            // 回答を配列にセット
            // labelは「(回答番号):(回答内容)」の形で表示される
            answers.forEach(function(answer) {
                optionList.push({
                    label: answer.no + ':' + answer.content,
                    value: answer.no
                });
            });
            // 作成した配列をセレクトボックスのoptionとして設定
            selectbox.set('options', optionList);
        },

        /**
         * 検索ボタンが押下された際に呼ばれるメソッド。
         */
        onSubmit :function() {
            // formに渡された検索条件
            var formData = this.form.get('value');
            var arr = [formData.a1, formData.a2, formData.a3];
            var value = {
                answers: arr,
                note: formData.note
            };
            //更新確認ダイアログを表示
            this.chain.confirm('安否状況を登録します。よろしいですか？', function(chain) {
                var promise = Requester.post('/api/safety/question?key=' + this.key, {
                    data: value
                }).then(function() {
                    // 成功時は完了ダイアログを表示
                    chain.infoComplete();
                }, function(error) {
                    // 失敗時はエラーログ出力、エラーダイアログを表示
                    console.log(error);
                    chain.infoError(error);
                });
                //ローダーの表示
                Loader.wait(promise);
            });
        }
    });
});
