/* 開発環境用設定ファイル */
define({
    '01': {
        name: '高速自動車国道',
        key: 'ROAD_NUMBER',
        nameKey: 'N10_004',
        prefix: '',
        suffix: ''
    },
    '02': {
        name: '都市高速道路',
        key: 'ROAD_NUMBER',
        nameKey: 'N10_004',
        prefix: '',
        suffix: ''
    },
    '03': {
        name: '一般国道',
        key: 'ROAD_NUMBER',
        nameKey: 'N10_004',
        prefix: '',
        suffix: ''
    },
    '04': {
        name: '主要地方道（都道府県道）',
        key: 'ROAD_NUMBER',
        nameKey: 'N10_004',
        prefix: '',
        suffix: ''
    },
    '05': {
        name: '主要地方道（指定市市道）',
        key: 'ROAD_NUMBER',
        nameKey: 'N10_004',
        prefix: '',
        suffix: ''
    },
    '06': {
        name: '一般都道府県道',
        key: 'ROAD_NUMBER',
        nameKey: 'N10_004',
        prefix: '',
        suffix: ''
    },
    '07': {
        name: '市道（認定道路）',
        key: 'ROAD_NUMBER',
        nameKey: 'N10_004',
        prefix: '',
        suffix: ''
    },
    '99': {
        name: 'その他道路',
        key: 'ROAD_NUMBER',
        nameKey: 'N10_004',
        prefix: '',
        suffix: ''
    }
});
