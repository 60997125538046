define([
    'module',
    'dojo/_base/declare',
    'dojo/dom-style',
    'dojo/text!./templates/MobileNoneAdressIconPanel.html',
    'dojo/topic',
    'idis/view/_IdisWidgetBase'
    // 以下、変数で受けないモジュール
], function(module, declare, domStyle, template, topic, _IdisWidgetBase) {
    return declare(module.id.replace(/\//g, '.'), _IdisWidgetBase, {
        // テンプレート文字列
        templateString: template,

        // ウィジェットのルート要素に付与されるCSSクラス
        baseClass: '.map-NoneAddressIconPanel',

        // DOMを構築する
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * 住所情報なしパネルの初期表示をセット。
         */
        initNoneAdressIcon: function() {
            domStyle.set(this.iconPanel, 'display','none');
            domStyle.set(this.event_railway, 'display','none');
            domStyle.set(this.event_weather, 'display','none');
            domStyle.set(this.event_fire, 'display','none');
            domStyle.set(this.event_accident, 'display','none');
            domStyle.set(this.event_incident, 'display','none');
            domStyle.set(this.event_lifeline, 'display','none');
            domStyle.set(this.event_other, 'display','none');
            domStyle.set(this.count, 'display','none');
        },

        /**
         * localInfoが「県」までの場合、住所情報なしパネルにアイコンを表示する。
         */
        noneAdressIcon: function(item) {

            domStyle.set(this.iconPanel, 'display','');
            if(!this.isEmpty(item.eventIds)){
                if(item.eventIds.indexOf('200100002')!==-1 || item.eventIds.indexOf('200100003')!==-1 || item.eventIds.indexOf('200100007')!==-1 || item.eventIds.indexOf('200100009')!==-1){
                    domStyle.set(this.event_railway, 'display','');
                } else if (item.eventIds.indexOf('100100')!==-1){
                    domStyle.set(this.event_weather, 'display','');
                } else if (item.eventIds.indexOf('100200')!==-1){
                    domStyle.set(this.event_fire, 'display','');
                } else if (item.eventIds.indexOf('100300')!==-1){
                    domStyle.set(this.event_accident, 'display','');
                } else if (item.eventIds.indexOf('100400')!==-1){
                    domStyle.set(this.event_incident, 'display','');
                } else if (item.eventIds.indexOf('100500')!==-1){
                    domStyle.set(this.event_lifeline, 'display','');
                } else if (item.eventIds.indexOf('100600')!==-1){
                    domStyle.set(this.event_other, 'display','');
                }
            }
        },

        noneAdressCount: function(count) {
            domStyle.set(this.count, 'display','');
            this.count.innerHTML = '全' + count + '件';
        },

        isEmpty: function(str) {
            if (str === void 0) {
                return true;
            }
            if (str === null) {
                return true;
            }
            if(str === ''){
                return true;
            }
            if(str === '0'){
                return true;
            }
            return false;
        }
    });
});
