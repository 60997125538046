/**
 * 安否確認メール一覧グリッド
 * @module app/safety/mail/SafetyMailListGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'dojo/dom',
    'dijit/registry',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function(module, declare, lang, locale, dom, registry, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        /**
         * 各列の定義
         * @type {Object[]}
         */

        collection: this.store,

        columns: [
            helper.buttonColumn('detail', '詳細'),
            helper.buttonColumn('confirm', '確認'),
            helper.column('distributeTimeStamp', '最終呼出時刻', {
                formatter: function(value) {
                    if (typeof value !== 'undefined' && value !== null) {
                        // valueがnullかundefinedではない場合フォーマットを変える
                        var date = locale.parse(value, {
                            datePattern: 'yyyy-MM-dd',
                            timePattern: 'HH:mm:ss'
                        });
                        return locale.format(date, {
                            datePattern: 'yyyy/MM/dd',
                            timePattern: 'HH:mm:ss'
                        });
                    } else {
                        return '';
                    }
                }
            }),
            helper.column('distTime', '呼出回数'),
                {
                    label: '職員情報',
                    field: 'staffInfo',
                    children: [{
                        field: 'chargeName',
                        label: 'グループ・係名',
                        sortable: false
                    }, {
                        field: 'jobName',
                        label: '職名',
                        sortable: false
                    }, {
                        field: 'employeeCd',
                        label: '職員番号',
                        sortable: false
                    }, {
                        field: 'name',
                        label: '氏名',
                        sortable: false
                    }]},
            {field: '_item', label:'返信状況',
                formatter: function(item) {
                    // ヘッダーの場合は戻り値なし
                    if (!item) {
                        return;
                    }
                    if (item.replyStatus === '03') {
                        return '返信有';
                    }
                    if (item.replyStatus === '02') {
                        return '確認済';
                    }
                    return '未返信';
                },
                className: function(item) {
                    // ヘッダーの場合は戻り値なし
                    if (!item) {
                        return;
                    }
                    if (item.replyStatus === '03') {
                        return 'is-reply';
                    }
                    if (item.replyStatus === '02') {
                        return 'is-confirm';
                    }
                    return 'is-noReply';
                }
            },
            {field: '_item', label:'送信状況',
                formatter: function(item) {
                    // ヘッダーの場合は戻り値なし
                    if (!item) {
                        return;
                    }
                    if (item.sendStatus === '03') {
                        return '送信済';
                    }
                    if (item.sendStatus === '02') {
                        return '未確認';
                    }
                    return '送信失敗';
                },
                className: function(item) {
                    // ヘッダーの場合は戻り値なし
                    if (!item) {
                        return;
                    }
                    if (item.sendStatus === '03') {
                        return 'is-sent';
                    }
                    if (item.sendStatus === '02') {
                        return 'is-notConfirmed';
                    }
                    return 'is-notSent';
                }
            },            
            helper.column('distA01', '回答1'),
            helper.column('distA02', '回答2'),
            helper.column('distA03', '回答3'),
            helper.column('memo', 'メモ'),
            helper.column('replyTimestamp', '受信時刻', {
                formatter: function(value) {
                    if (typeof value !== 'undefined' && value !== null) {
                        // valueがnullかundefinedではない場合フォーマットを変える
                        var date = locale.parse(value, {
                            datePattern: 'yyyy-MM-dd',
                            timePattern: 'HH:mm:ss'
                        });
                        return locale.format(date, {
                            datePattern: 'yyyy/MM/dd',
                            timePattern: 'HH:mm:ss'
                        });
                    } else {
                        return '';
                    }
                }
            }),
            helper.column('confirmTimestamp', '確認時刻', {
                formatter: function(value) {
                    if (typeof value !== 'undefined' && value !== null) {
                        // valueがnullかundefinedではない場合フォーマットを変える
                        var date = locale.parse(value, {
                            datePattern: 'yyyy-MM-dd',
                            timePattern: 'HH:mm:ss'
                        });
                        return locale.format(date, {
                            datePattern: 'yyyy/MM/dd',
                            timePattern: 'HH:mm:ss'
                        });
                    } else {
                        return '';
                    }
                }
            })
        ]
    });
});
