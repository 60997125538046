/**
 * 安否確認状況概況用モジュール。
 *
 * @module app/safety/mail/SafetyMailOverviewPage
 */
define(['module',
        'dojo/_base/declare',
        'dojo/_base/lang',
        'dojo/date/locale',
        'dojo/dom-style',
        'dojo/text!./templates/SafetyMailOverviewPage.html',
        'dstore/Memory',
        'idis/view/dialog/DialogChain',
        'idis/view/page/_PageBase',
        'idis/view/dialog/InfoDialog',
        'idis/control/Router',
        'idis/store/IdisRest',
        'idis/service/Requester',
        'idis/control/Locator',
        // 以下、変数で受けないモジュール
        'dijit/layout/BorderContainer',
        'dijit/layout/ContentPane',
        'dijit/form/Form',
        'dijit/form/Select',
        'dijit/form/TextBox',
        'app/safety/auth/view/form/AclButton',
        'dijit/form/Textarea',
        'dijit/form/SimpleTextarea',
        'idis/view/form/Button',
        'idis/view/form/DateTimeInput',
        'app/safety/mail/SafetyMailOverviewGrid'],
        function(module, declare, lang, locale, domStyle, template, Memory, DialogChain, _PageBase, InfoDialog,
                 Router, IdisRest, Requester, Locator) {
    /**
     * 安否確認状況概況画面。
     *
     * @class SafetyMailOverviewPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/safety/mail/SafetyMailOverviewPage# */
    {
        // テンプレート文字列
        templateString: template,

        // 格納するオブジェクト
        store: null,

        // 確認ダイアログ
        confirmDialog: null,

        // 配信ID
        safetyMailId: null,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--safety',

        /**
         * DOMノードを生成する。
         */
        buildRendering: function() {
            this.inherited(arguments);
            // 件名、本文、配信時刻を設定する
            this.initForm();
            this.initSafetyStatusOverviewGrid();
        },

        /**
         * 変数の初期化を行う。
         */
        constructor: function() {
            this.distributionId = Locator.getQuery().mid;
            this.distributionGroupCd = Locator.getQuery().gcd;
            // データ格納用オブジェクト
           this.store = new IdisRest({
               idProperty: 'distributionId',
               target: '/api/safety/mails/overview/'+ this.distributionId
           });
            // 連鎖ダイアログを登録
            this.chain = DialogChain.get(this);
        },

        /**
         * DBから取得した値をformに設定する。
         */
        initForm: function() {
            Requester.get('/api/safety/mails/forms/' + this.distributionId)
            .then(lang.hitch(this,function(data) {
                // 本文に改行コード(¥n)が含まれる場合は変換
                var text = data.bodyText.replace(/¥n/g, '\n');
                this.title.innerHTML = data.title;
                // テーブルとの改行コード差異回避のためreplaceを実施
                // (テーブル上\nでも表示する際は¥nに化ける)
                this.text.innerHTML = text.replace(/\\n/g, '<br>');
                if (typeof data.distributeTimeStamp !== 'undefined' && data.distributeTimeStamp !== null) {
                    // distributeTimeStampがnullかundefinedではない場合フォーマットを変える
                    var date = locale.parse(data.distributeTimeStamp, {
                        datePattern: 'yyyy-MM-dd',
                        timePattern: 'HH:mm:ss'
                    });
                    this.distributeTimeStamp.innerText = locale.format(date, {
                        datePattern: 'yyyy/MM/dd',
                        timePattern: 'HH:mm:ss'
                    });
                } else {
                    this.distributeTimeStamp.innerText = '-';
                }
                this.groupName.innerHTML = data.groupName;
                this.container.resize();
            }));
        },

        /**
         * 画面内のGridを初期化する。
         */
        initSafetyStatusOverviewGrid: function() {
            this.safetyStatusOverviewGrid.set('collection', this.store);
            // グリッドの一覧ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.safetyStatusOverviewGrid.on('listButtonClick', lang.hitch(this, function(evt) {
                // helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
                this.moveSafetyListPage(evt.item);
            }));
        },

        /**
         * @param item Gridの行データ
         * 一覧画面へ遷移する
         */
        moveSafetyListPage: function(item) {
            // 課コードをURLに追加し、詳細ページへ遷移
            var safetyMailId = this.distributionId;
            var groupCd = this.distributionGroupCd;
            Router.moveTo('safety/statusList',
                {
                mid : safetyMailId,
                gcd : groupCd,
                dcd : item.divisionCd
                });
        },

        /**
         * @param evt ボタンクリックイベント
         * パンくずリストが押下されたとき、管理画面に遷移する。
         */
        onSafetyStatusPageLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('safety/mail');
        },

        /**
         * Gridの内容をcsvとして出力する。
         */
        outputListExcel: function() {
            console.log('[帳票出力]ボタンがクリックされました。');

            var url = '/api/safety/mails/overview.csv?distributionId=' + this.distributionId;

            // 確認ダイアログを表示
            this.chain.confirm('一覧に表示されているデータを出力します。<br>' +
                'よろしいですか？', function(chain) {
                // 確認ダイアログでOKを押した場合
                chain.hide();
                // URLを遷移する
                window.location.href = url;
            });
        }
    });
});
