/**
 * お知らせ種別編集関連UI用モジュール。
 * @module app/notice/view/NoticeTypePage
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-class',
    'dojo/text!./templates/NoticeTypePage.html',
    'dijit/_Container',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/view/_IdisWidgetBase',
    'idis/view/Loader',
    '../NoticeTypeModel',
    './NoticeTypeItem',
    // 以下、変数として受け取らないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'idis/view/form/AclButton',
    'idis/view/form/Button'
], function(module, array, declare, lang, domClass, template, _Container,
    Requester, DialogChain, _IdisWidgetBase, Loader, NoticeTypeModel, NoticeTypeItem) {
    /**
     * お知らせ種別編集ボタンと編集ダイアログ。
     * @class NoticeTypePage
     * @extends module:idis/view/_IdisWidgetBase~_IdisWidgetBase
     */
    return declare(module.id.replace(/\//g, '.'), [_IdisWidgetBase, _Container],
        /** @lends module:idis/view/page/NoticeTypePage~NoticeTypePage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素のCSSクラス
        baseClass: 'notice-NoticeTypePage',

        constructor: function() {
            // ダイアログ連鎖を登録
            // 引数に与えたウィジェットのthis.ownを呼び出し、
            // ウィジェットが破棄された際にダイアログ連鎖が破棄されるようになる
            this.chain = DialogChain.get(this);
        },

        // DOMノードを生成するためのメソッド
        buildRendering: function() {
            this.inherited(arguments);
            // 自分が削除された時は関連ダイアログも削除する
            this.own(this.dialog);
            NoticeTypeModel.load().then(lang.hitch(this, function(res) {
                // 表示に反映
                this.applyResponse(res);
                // 今後内容が変わった場合は設定し直す
                this.own(NoticeTypeModel.on('load', lang.hitch(this, 'applyResponse')));
            }));
        },

        /**
         * お知らせ種別一覧を削除する。
         */
        clearNoticeTypeList: function() {
            // containerNode内を全て破棄
            array.forEach(this.getChildren(), function(child) {
                child.destroyRecursive();
            });
        },

        /**
         * サーバーから取得した内容を反映する。
         */
        applyResponse: function(res) {
            // 一覧をクリア
            this.clearNoticeTypeList();
            var items = res.items;
            for (var i = 0; i < items.length; i++) {
                var beforeItem = items[i - 1];
                var afterItem = items[i + 2];
                this.addChild(new NoticeTypeItem({
                    item: items[i],
                    isUpAllowed: i > 0,
                    isDownAllowed: i < items.length - 1,
                    beforeIdForUp: beforeItem && beforeItem.noticeTypeId,
                    beforeIdForDown: afterItem && afterItem.noticeTypeId
                }));
            }
        },

        /**
         * 種別編集ボタンが押された際に呼ばれる。
         * @param {MouseEvent} evt クリック・イベント
         */
        onFormButtonClick: function(/* evt */) {
            // 編集画面ダイアログを開く
            this.dialog.show();
        },

        /**
         * 新規登録ボタンが押された際に呼ばれる。
         * @param {MouseEvent} evt クリック・イベント
         */
        onRegisterButtonClick: function(/* evt */) {
            this.form.reset();
            // 追加ボタンを表示して追加用UIを隠す
            domClass.add(this.dialog.domNode, 'is-add');
            this.nameInput.focus();
        },

        /**
         * キャンセル・ボタンが押された際に呼ばれる。
         * @param {MouseEvent} evt クリック・イベント
         */
        onCancelButtonClick: function(/* evt */) {
            // 追加用UIを隠して追加ボタンを表示
            domClass.remove(this.dialog.domNode, 'is-add');
        },

        /**
         * 検索ボタンが押されたときに呼び出される。
         * テンプレートHTML内のFormウィジェットに対するdata-dojo-attach-eventでこのイベントを紐付けている。
         * また、同Formウィジェットに対しdata-dojo-attach-pointを指定し、this.formからアクセス出来るようにしている。
         */
        onRegisterSubmit: function() {
            try {
                if (this.form.validate()) {
                    var value = this.form.get('value');
                    // 登録ダイアログを表示
                    this.chain.confirmAdd(function(chain) {
                        // 登録リクエストを実施
                        Loader.wait(Requester.post('/api/notices/types/', {
                            data: value
                        })).then(lang.hitch(this, function() {
                            // 登録成功時は完了ダイアログを表示
                            chain.infoComplete(lang.hitch(this, function() {
                                chain.hide().then(lang.hitch(this, function() {
                                    // 追加用UIを隠して追加ボタンを表示
                                    domClass.remove(this.dialog.domNode, 'is-add');
                                    // 最新情報を取得
                                    Loader.wait(NoticeTypeModel.load(true));
                                }));
                            }));
                        }), function(err) {
                            // 登録失敗時
                            chain.infoError(err);
                        });
                    });
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        }
    });
});

