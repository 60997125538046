/**
 * 職員選択用入力パーツ
 * @module app/view/form/EmployeeSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/view/form/TreeSelector',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest'
], function(module, declare, TreeSelector, CacheStoreModel, CacheTreeRest) {
    /**
     * 職員選択用パーツ。
     * @class EmployeeSelector
     * @extends module:idis/view/form/TreeSelector~TreeSelector
     */
    return declare(module.id.replace(/\//g, '.'), TreeSelector,
        /** @lends module:idis/view/tree/EmployeeSelector~EmployeeSelector# */ {
        // 選択ダイアログのタイトル
        title: '職員選択',
        // ツリー・モデル
        model: null,

        constructor: function() {
            this._initModel();
        },

        _initModel: function() {
            // 設置されるたびにモデルを新規生成する
            this.model = new CacheStoreModel({
                store: new CacheTreeRest({
                    target: '/api/convoMails/groups/employees4Tree' 
                })
            });
        },

        /**
         * ツリー選択されている要素をこのウィジェットのvalue値として設定する。
         * 要素が選択されていない場合は何もしない。
         */
        applySelectedItem: function() {
            var item = this.tree.selectedItem;
            // 職員の場合のみ値をセット
            if (item && item.type === 'MDpEmployee') {
                this.set('value', this.tree.model.store.getIdentity(item));
            }
        },

        // ダイアログを表示する度にmodelを呼び出すことで施設種別テーブルの変更点をツリーに反映
        showDialog: function() {
            if(this.treeParentNode && this.tree){
                // this.treeNode = this.tree.domNode;
                this.tree.destroyRecursive();
                this.treeParentNode.appendChild(this.treeNode);
                delete this.tree;
                delete this.model;
                this._initModel();
            } else {
                // 2回目の表示以降、treeを削除する際にtreeNodeが削除されてしまうので
                // 初期表示の段階で、ツリー表示時のtreeNode.parentNodeを記憶しておく
                this.treeParentNode = this.treeNode.parentNode;
            }
            return this.inherited(arguments);
        }
    });
});

