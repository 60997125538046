/**
 * 安否確認メール送信ダイアログ用モジュール。
 * @module app/safety/mail/SafetySendMailDialog
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/text!./templates/SafetySendMailDialog.html', // テンプレート文字列
    'dojo/_base/lang',
    'dstore/Memory',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/view/page/_PageBase', // 共通基底クラス
    'idis/store/IdisRest',
    'idis/view/dialog/InfoDialog',
    // 以下、変数から参照されないモジュール
    'dijit/Dialog',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/form/Form',
    'dijit/form/TextBox',
    'dijit/form/CheckBox',
    'idis/view/form/Button',
    'idis/view/form/WordCountTextarea',
    'app/view/form/DistributionGroupSelector',
    'app/safety/mail/SafetyTempInsertGrid'
], function(module, declare, template, lang, Memory, Requester, DialogChain, _PageBase, IdisRest, InfoDialog) {
    /**
     * 安否確認メール送信ダイアログ。
     * @class SafetySendMailDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/SafetySendMailDialog~SafetySendMailDialog# */
        {
        // テンプレート文字列
        templateString: template,

        store: null,

        qcatStore: null,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--safety-mailsend',

        // 質問カテゴリコード
        questionCategory: null,

        // テンプレートコード
        templateCd: null,

        /**
         * 変数を初期化。
         */
        constructor: function(){
            // 安否確認メールテンプレートデータ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'distributionTmplId',
                target: '/api/safety/temps'
            });

            // 質問格納用オブジェクト
            this.qcatStore = new Memory({
                'data': []
            });

            // サーバサイドへのリクエストを発行
            Requester.get('/api/safety/questions').then(lang.hitch(this, function(data) {
                // 成功時
                Object.keys(data).forEach(lang.hitch(this, function(key) {
                    // カテゴリコードと質問（回答が紐づいている）の連想配列を設定する
                    this.qcatStore.addSync({id: data[key].code,
                                            questions: data[key].questions});
                }));
            }), lang.hitch(this, function(err) {
                // リクエスト失敗時
                console.error(err);
                this.chain.info('質問カテゴリの取得に失敗しました。', 'エラー');
            }));

            this.chain = DialogChain.get(this);
        },

        /**
         * DOMノードを生成する。
         */
        buildRendering: function() {
            this.inherited(arguments);
            this.own(this.templateInsertDialog);
            this.initTemplateProvGrid();
        },

        /**
         * 送信完了ダイアログを表示する
         */
        onSendMailButtonClick: function() {
          try {
              if(this.templateCd === null) {
                  console.debug('テンプレートを選択してください');
                  if (!this.infoDialog) {
                      this.infoDialog = new InfoDialog({
                          title : 'エラー',
                          content : 'テンプレートを選択してください'
                      });
                  }
                  this.infoDialog.show();
                  this.infoDialog=null;
                  return false;
              }
              if(this.validate()) {
                  var formData = this.form.get('value');
                  var value = {distributionGroupCd: formData.distributionGroupCd,
                               title: formData.title,
                               body: formData.body,
                               autoFlg: '0',
                               templateCd: this.templateCd,
                               questionCategory: this.questionCategory};
                  // 実際の登録処理はPage側でregisterイベントを監視しているメソッドが実施
                  this.emit('register', {value: value});
              }
          } catch (e) {
              console.error(e);
          }
        },

        /**
         * テンプレートの挿入ボタンが押下された際の処理。
         */
        tempInsertButtonClick: function(){
            // テンプレートの挿入ダイアログを表示する。
            this.templateInsertDialog.show();
        },

        /**
         * テンプレートダイアログ内グリッドの初期化
         */
        initTemplateProvGrid: function() {
            this.grid.set('collection', this.store.filter());
            this.grid.on('insertButtonClick', lang.hitch(this, function(evt) {
                // helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
                this.onTemplateInsert(evt.item);
            }));
        },

        /**
         * @param item メールテンプレート
         * テンプレートの情報を、当ダイアログのform情報に設定する。
         */
        onTemplateInsert: function(item) {
            // テンプレートコードを設定
            this.templateCd = item.distributionTmplCd;
            // 質問カテゴリコードを設定
            this.questionCategory = item.categoryCd;
            // タイトルと本文をダイアログのform情報に設定
            this.form.set('value',
                {title: item.distributionTitle,
                 body: item.distributionBody
                });
            // 質問と回答のformへの設定は別の関数にて実施
            this.setQandA();

            // テンプレートの挿入ダイアログを閉じる
            this.templateInsertDialog.hide();
        },

        /**
         * 質問と回答をformに設定する。
         */
        setQandA: function() {
            // 最初に値をクリア
            this.q1.set('value', '');
            this.a1.set('value', '');
            this.q2.set('value', '');
            this.a2.set('value', '');
            this.q3.set('value', '');
            this.a3.set('value', '');
            
            // 質問カテゴリコードに合致する質問群を配列として取得
            if (this.questionCategory !== null) {
                var qstore = this.qcatStore.getSync(this.questionCategory);
                var qlist = qstore.questions;
                // 質問とそれに紐づく回答を設定する
                qlist.forEach(lang.hitch(this, function(data) {
                    // 質問の番号で設定する箇所を選ぶ
                    switch(data.no) {
                    case 1 :
                        this.q1.set('value', data.distributionQContents);
                        this.a1.set('value', this.getJoinedAnswer(data.answers));
                        break;
                    case 2 :
                        this.q2.set('value', data.distributionQContents);
                        this.a2.set('value', this.getJoinedAnswer(data.answers));
                        break;
                    case 3 :
                        this.q3.set('value', data.distributionQContents);
                        this.a3.set('value', this.getJoinedAnswer(data.answers));
                        break;
                    default: break;
                    }
                }));
            }
        },

        /**
         * @param answers 質問に対する回答群(連想配列)
         * @returns joinedAnswer カンマ区切りされた回答
         * 回答をformに設定する。
         *
         */
        getJoinedAnswer: function(answers) {
            var joinedAnswer = [];
            // 回答内容を配列に格納
            answers.forEach(function(ans) {
                // 「番号．内容」の形に整形
                //TODO distributionAContents -> distributionAContents
                var answer = ans.no + '.' + ans.distributionAContents;
                // 整形後の情報を配列に設定
                joinedAnswer.push(answer);
            });
            // カンマ区切りの文字列にして変換
            return joinedAnswer.join(', ');
        },

        /**
         * 入力された値の妥当性をチェックする。
         */
        validate: function() {
            var formData = this.form.value;
            // 送信先のnullチェック
            if (formData.distributionGroupCd === '') {
                this.chain.info('送信先を設定してください', '入力エラー');
                return false;
            }
            // 件名のnullチェック
            if (formData.title === '') {
                this.chain.info('件名を設定してください', '入力エラー');
                return false;
            }
            // 本文のnullチェック
            if (formData.body === '') {
                this.chain.info('本文を設定してください', '入力エラー');
                return false;
            }
            // テンプレート未選択(質問コード未設定)
            if (this.questionCategory === '') {
                var msg = 'テンプレートを選択していないため質問が設定されていません。<br>' +
                'テンプレートを選択してください。';
                this.chain.info(msg, '入力エラー');
                return false;
            }

            return true;
        }
    });
});
