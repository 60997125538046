/**
 * 降灰情報詳細画面
 * @module app/volcano/AshBreakingNewsDetailPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/AshBreakingNewsDetailPage.html',
    './_DetailPaneBase',
    'app/config',
    'idis/control/Router',
    'idis/control/Locator',
    'idis/service/Requester',
    'dojo/_base/array',
    'idis/util/DateUtils',
    'dstore/Memory',
    // 以下、変数として受け取らないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/layout/TabContainer',
    'dijit/form/Select',
    './AshDetailPageGrid'
], function(module, declare, lang, template,
    _PageBase,
    config, Router, Locator, Requester, array, DateUtils, Memory) {

    /**
     * 降灰情報詳細画面
     * @class AshBreakingNewsDetailPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/volcano/AshBreakingNewsDetailPage~AshBreakingNewsDetailPage# */
        {
            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--Ash',

            // テンプレート文字列
            templateString: template,
            response: null,

            constructor: function() {
                this.inherited(arguments);
                this.ashBreakingNewsId = Locator.getQuery().ashBreakingNewsId;
            },

            // DOMノードを生成するためのメソッド
            buildRendering: function() {
                this.inherited(arguments);
            },

            startup: function() {
                this.inherited(arguments);
                this.table = document.getElementById('leftTbl');
                this.hourSelect.on('change', lang.hitch(this, this.onPulldownChange));
                this.setContent();
                this.initMap();
                this.pulldown();
            },

            pulldown: function() {
                Requester.get('/api/volcanos/ashBreakingDetail?ashBreakingNewsId=' + this.ashBreakingNewsId)
                    .then(lang.hitch(this, function(response) {
                        this.response = response;
                        this.generateOptions(response);
                        // 最初に表示する要素を決める
                        var ascendingByForecastTime = response.items.sort(function(a, b) {
                            return a.endTime - b.endTime;
                        });
                        var initialOption = ascendingByForecastTime[ascendingByForecastTime.length - 1].ashInfoType;
                        this.hourSelect.set('value', initialOption);
                    }));
            },

            // APIのresponseからoptionのselect一覧を作る
            generateOptions: function(response) {
                var select = this.hourSelect;
                array.forEach(response.items, function(item) {
                    if (select.getOptions(item.ashInfoType)) {
                        return;
                    }

                    select.addOption({
                        label: item.ashInfoType,
                        value: item.ashInfoType,
                        endTime: item.endTime
                    });
                });
                //予報時間を降順に揃える
                select.options.sort(function(a, b) {
                    return a.endTime - b.endTime;
                });
            },

            // pulldownの選択が変わった時に呼ばれる
            onPulldownChange: function() {

                var value = this.hourSelect.get('value');

                var responseFiltered = this.response.items.filter(function(detail) {
                    return (detail.ashInfoType === value);
                });

                //vwCode(降順)毎にareaICode（小さい数字→大きい数字）を降順にする 噴石が一番上
                responseFiltered.sort(function(a, b) {
                    return b.vwCode - a.vwCode || a.areaICode - b.areaICode;
                });

                //重複している市町村(areaICode)は最高レベルの警報のものだけにfilter
                var values = [];
                var responseFilteredWODup = responseFiltered.filter(function(c) {
                    if (values.indexOf(c.areaICode) === -1) {
                        values.push(c.areaICode);
                        return c;
                    }
                });

                //sizeTypeThicknessの中ににsizeTypeRockとsizeTypeThicknessとsizeTypeAmountを内包する（gridで出すのに選択肢渡すため）
                array.forEach(responseFilteredWODup, function(item) {
                    item.sizeTypeThickness = ({
                        thickness: item.sizeTypeThickness,
                        rock: item.sizeTypeRock + item.sizeUnit,
                        amount: item.sizeTypeAmount
                    });
                });

                //IDを振る
                array.forEach(responseFilteredWODup, lang.hitch(this, function(row, idx) {
                    row.id = idx;
                }));

                //gridに入れる内容を案件に絞る
                var responseUsedInGrid = responseFilteredWODup.filter(function(item) {
                    var strAreaCode = String(item.areaICode);
                    return strAreaCode.length > 2 &&
                        strAreaCode.substring(0, 2) === config.municInfo.prePrefCd;
                });

                //Grid用のstoreを作りつつ、gridにset
                this.ashBreakingGrid.set('collection', new Memory({
                    data: responseUsedInGrid,
                    idProperty: 'id'
                }));

                var rangeForForecastHrs = document.getElementById('rangeForForecastHrs');
                rangeForForecastHrs.innerHTML =
                    DateUtils.format(responseFiltered[0].startTime) + ' ~ ' +
                    DateUtils.format(responseFiltered[0].endTime);

                // レイヤー表示
                this.toggleAshLayer(responseUsedInGrid);

            },

            /**
            * コンテンツの中身を表示する
            */
            setContent: function() {
                var url = '/api/volcanos/ashBreakingDetail/ashBreakingDtlContent' +
                    '?ashBreakingNewsId=' + this.ashBreakingNewsId;

                Requester.get(url)
                    .then(lang.hitch(this, function(response) {
                        // volcano
                        var rowsHeaderTopBriefInfo = document.getElementById('rowsHeaderTopBriefInfo');
                        rowsHeaderTopBriefInfo.style.backgroundColor = '#999999';
                        rowsHeaderTopBriefInfo.innerHTML = '<b>&lt;噴火・降灰速報&gt;</b>';

                        var rowsEventDateTime = document.getElementById('rowsEventDateTime');
                        rowsEventDateTime.style.backgroundColor = 'lightgrey';
                        rowsEventDateTime.innerHTML = '&lt;発現時刻&gt;';
                        var eventDateTime = document.getElementById('eventDateTime');
                        if (response.eventDateTime) {
                            eventDateTime.innerHTML = DateUtils.format(response.eventDateTime);
                        } else {
                            eventDateTime.innerHTML = '-';
                        }
                        var rowVolName = document.getElementById('rowVolName');
                        rowVolName.style.backgroundColor = 'lightgrey';
                        rowVolName.innerHTML = '&lt;火山名&gt;';
                        var volName = document.getElementById('volName');
                        volName.innerHTML = response.pvName;

                        var rowEdOffice = document.getElementById('rowEdOffice');
                        rowEdOffice.style.backgroundColor = 'lightgrey';
                        rowEdOffice.innerHTML = '&lt;編集官署&gt;';
                        var edOffice = document.getElementById('edOffice');
                        edOffice.innerHTML = response.edOffice;

                        var overview = document.getElementById('overview');
                        overview.style.backgroundColor = '#AAAAAA';
                        overview.innerHTML = '&lt;発表概要&gt;';

                        var rowHeadLine = document.getElementById('rowAshHeadLine');
                        rowHeadLine.style.backgroundColor = 'lightgrey';
                        rowHeadLine.innerHTML = '&lt;見出し&gt;';
                        var headLine = document.getElementById('ashHeadLine');
                        headLine.innerHTML = response.ashHeadline.replace(/\r?\n/g, '<br>').trim();

                        var rowAshActivity = document.getElementById('rowAshActivity');
                        rowAshActivity.style.backgroundColor = 'lightgrey';
                        rowAshActivity.innerHTML = '&lt;詳細&gt;';
                        var ashActivity = document.getElementById('ashActivity');
                        ashActivity.innerHTML = response.ashActivity.replace(/\r?\n/g, '<br>');

                        var rowAshPrevention = document.getElementById('rowAshPrevention');
                        rowAshPrevention.style.backgroundColor = 'lightgrey';
                        rowAshPrevention.innerHTML = '&lt;防災上の注意&gt;';
                        var ashPrevention = document.getElementById('ashPrevention');

                        if (response.ashPrevention) {
                            ashPrevention.innerHTML = response.ashPrevention.trim();
                        }

                        this.layout();
                    }));
            },
    
            /**
             * 凡例ダイアログを表示する。
             */
            toggleLegendDialog: function() {
                this._toggleLegendDialog([this.ASH_LAYER_ID]);
            }
        });
});
