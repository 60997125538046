define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/array',
    'dojo/_base/lang',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'dojo/topic',
    'dgrid/Selector',
    'idis/util/DateUtils'
], function (module, declare, array, lang, IdisGrid, helper, topic, Selector, DateUtils) {
    return declare(module.id.replace(/\//g, '.'), [IdisGrid, Selector], {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--rescue',

        // 活動エリアの描画用
        DRAW_ACTIVE_AREA  : 'app.rescue.RescuePage' + '::drawActiveArea',

        /**
         * 各列の定義
         * @type {Object[]}
         */


        columns: [
            // ボタン
            helper.buttonColumn('detail', '詳細'),
            { field: 'admNum', label: '管理番号' },
            { field: 'seqNum', label: '報告連番', sortable: false },
            helper.column('rescueType', '部隊種別', {
                formatMap: {
                    '1': '東京消防庁',
                    '2': '警視庁',
                    '3': '自衛隊',
                    '4': '海上保安庁',
                    '5': '東京ＤＭＡＴ'
                }
            }, false),
            {
                field: 'reportTimestamp', label: '報告日時',
                formatter: lang.hitch(this, function (item) {
                    if (!item) {
                        return '-';
                    } else {
                        return DateUtils.format(item);
                    }
                })
            },
            {
                field: 'activityStart', label: '活動開始',
                formatter: lang.hitch(this, function (item) {
                    if (!item) {
                        return '-';
                    } else {
                        return DateUtils.format(item);
                    }
                })
            },
            {
                field: 'activityEnd', label: '活動終了',
                formatter: lang.hitch(this, function (item) {
                    if (!item) {
                        return '-';
                    } else {
                        return DateUtils.format(item);
                    }
                })
            },
            { field: 'activityArea', label: '活動場所', sortable: false },
            { field: 'peopleNum', label: '活動人数' },
            { field: 'activitySummary', label: '活動概要', sortable: false },
            {
                field: 'activityStatus', label: '活動状況', sortable: false,
                formatter: lang.hitch(this, function (item, value) {
                    console.log(item); //エラー対策用
                    var dateActivityStart = new Date(value.activityStart.replace(/-/g, '/'));
                    var now = new Date();
                    //活動開始、活動終了と現在時刻から活動状況を判定する
                    if (dateActivityStart.getTime() > now.getTime()) {
                        return '活動予定';
                    } else if (value.activityEnd) {
                        var dateActivityEnd = new Date(value.activityEnd.replace(/-/g, '/'));
                        if (dateActivityEnd.getTime() < now.getTime()) {
                            return '活動終了';
                        }
                        return '活動中';
                    } else {
                        return '活動中';
                    }
                })
            }
        ],

        /**
         * 指定された地区コードを含む計画を選択状態にする
         * @param {object} properties layer.feature.propertiesオブジェクト
         */
        _selectByAreaCode: function (properties) {
            // "plans" に計画情報があるので, reportNumがIDのrowをselectするとチェックが入る
            array.forEach(properties.plans, lang.hitch(this, function (plan) {
                this.select(plan.reportNum);
            }));
        },

		// 活動エリアの描画用
		renderRow: function(item) {
            var div = this.inherited(arguments);
            topic.publish(this.DRAW_ACTIVE_AREA, item);
            return div;
		}
    }
    );
});
