/**
 * <ライフライン登録画面>
 *
 * @module app/lifeline/LifelineRegisterPage.js
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/text!./templates/LifelineRegisterPage.html',
    'dojo/json',
    'dojo/dom-style',
    'idis/view/dialog/InfoDialog',
    'dojo/_base/lang',
    'dojo/promise/all',
    'idis/control/Router',
    'idis/consts/STORAGE_KEY',
    'idis/service/GeoService',
    'idis/store/IdisRest',
    'idis/control/Locator',
    'idis/view/Loader',
    'idis/view/dialog/DialogChain',
    'idis/view/draw/_DrawUtil',
    'app/model/DisasterInfo',
    'idis/model/UserInfo',
    'idis/service/Requester',
    'idis/map/IdisMap',
    'dojo/on',
    'dojo/topic',
    'app/config',
    'leaflet',
    'idis/view/dialog/IdisDialog',
    'idis/util/storage/LocalStorage',
    'app/map/baselayer/BaseLayerPane',
    'idis/view/page/_PageBase',
    'app/draw/DrawPanel',
    // 以下、変数で受けないモジュール
    'dijit/layout/TabContainer',
    'dijit/layout/BorderContainer',
    'dijit/form/RadioButton',
    'dijit/form/Select',
    'dijit/form/Form',
    'dijit/form/Textarea',
    'dijit/form/NumberTextBox',
    'dijit/form/ValidationTextBox',
    'dijit/form/CheckBox',
    'dijit/layout/AccordionContainer',
    'dojox/form/Uploader',
    'app/view/form/OrganizationSelector',
    'dijit/TitlePane',
    'idis/view/form/TimeInput',
    'idis/view/form/DateTimeInput',
    'idis/consts/ACL',
    'idis/view/form/AclButton',
    'idis/view/form/WordCountTextarea'
], function (module, declare, template, json, domStyle, InfoDialog, lang, all, Router, STORAGE_KEY, GeoService,
    IdisRest, Locator, Loader, DialogChain, DrawUtil, DisasterInfo, UserInfo, Requester, IdisMap, on, topic,
    config, leaflet, IdisDialog, LocalStorage, BaseLayerPane, _PageBase, DrawPanel) {
    // GeoServiceを初期化
    var _geoService = new GeoService({ url: config.geocode && config.geocode.url });

    /**
     * ライフライン新規登録画面
     *
     * @class LifelineRegisterPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/shelter/LifelineRegisterPage~LifelineRegisterPage# */ {

            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--lifeline',

            /**
             * 災害ID
             */
            _disasterId: null,

            /**
             * クエリ用市町村コード
             */
            _municipalityCd: null,

            /**
             * formデータ
             */
            _sendData: null,

            /**
             * 背景地図ダイアログに対する参照
             * @type {module:idis/view/dialog/IdisDialog~IdisDialog}
             * @private
             */
            _baseLayerDialog: null,

            /**
             * 作図ダイアログ
             */
            drawPanel: null,
            DRAW_INIT_ID: '/app/draw/DrawPanel::SettingDrawEvents',
            JSONIZE_RQST: '/app/draw/DrawPanel::drawnDataJsonizeRequest',
            JSONIZE_DONE: '/app/draw/DrawPanel::drawnDataJsonizeResponse',
            DRAW_BY_JSON: '/app/draw/DrawPanel::drawGeoJSONToLayer',
            REMOVE_ALL: '/app/draw/DrawPanel::removeAllLayers',
            //イベントは破棄しない、全作図モードをOFFにする
            DISABLE_DRAW: '/app/draw/DrawPanel::hideAndDisableDraw',
            //mapに取り付けた全てのイベントを破棄する。
            DRAW_EVT_OFF: '/app/draw/DrawPanel::removeDrawAllEvent',
            // forIE anchorにOnclickでPublishをして、msSaveへ情報を渡す。
            DOWNLOAD_4IE: '/app/damage/MapPage::download4IE',

            /**
             * メッセージ表示用ダイアログ
             */
            infoDialog: null,

            constructor: function () {
                // ダイアログ連鎖を登録
                // 引数に与えたウィジェットのthis.ownを呼び出し、
                // ウィジェットが破棄された際にダイアログ連鎖が破棄されるようになる
                this.chain = DialogChain.get(this);

                // 災害IDをDisasterInfoから取得
                this._disasterId = DisasterInfo.getDisasterId();
                if (!this._disasterId) {
                    this._disasterId = 1;
                    console.warn('災害IDが設定されていません。');
                }
                console.debug('現在のログインユーザID：' + UserInfo.getId());
                console.debug('現在の災害ID：' + DisasterInfo.getDisasterId());
            },

            // DOMノードを生成するためのメソッド
            buildRendering: function () {
                this.inherited(arguments);
            },

            // HTML上にウィジェットが設置されてから呼ばれる
            startup: function () {
                console.debug('LifelineRegisterPage#startup()');
                this.inherited(arguments);
                //地図を初期化
                this.initMap();
                // 画面を初期化
                this.initPage();
            },

            /**
             * マップを初期化する。
             */
            initMap: function (lat, lng) {
                console.debug('LifelineRegisterPage#initMap()');
                //中心アイコンを非表示にする
                LocalStorage.set(STORAGE_KEY.CENTER_MARK, '');
                // マップの生成
                var latlng = null;
                if (lat && lng) {
                    latlng = [lat, lng];
                } else {
                    latlng = [config.map.latitude, config.map.longitude];
                }
                this.map = new IdisMap(this.mapNode, {
                    config: config.map,
                    keyboard: false,
                    touchExtend: false,
                    minZoom: 9,
                    maxZoom: 18,
                    drawControlTooltips: false
                }
                ).setView(latlng, 14);
                // destroy時にmapを破棄するよう設定
                this.own(this.map);
                this.own(on(this.map, 'click', lang.hitch(this, function (e) {
                    if (this.drawPanel._ActiveMode === null) {
                        this.pointLat = e.latlng.lat;
                        this.pointLng = e.latlng.lng;
                        this.addMark(this.pointLat, this.pointLng, this);
                    }
                })));
                // 初期化
                this.pointLat = '';
                this.pointLng = '';
                this.marker = '';

                // 作図パネルを生成
                this.createDrawPanel();
                topic.publish(this.DRAW_INIT_ID, this.map);
                topic.subscribe(this.JSONIZE_DONE, lang.hitch(this, function (args) {
                    this.drawJson = args;
                }));
                // IE対応
                // イベントを管理する人は各Mapに必要。
                // TODO pub/subの方がよいか？
                if (DrawUtil._isIE()) { DrawUtil._setPopupEvtForMap(this); }
                this.borderContainer.resize();
            },

            /**
             * 画面を初期化する。
             */
            initPage: function () {
                console.debug('LifelineRegisterPage#initPage()');
            },

            onSubmit: function () {
                // 登録ボタンを押下したときの挙動
                console.debug('[登録]ボタンがクリックされました。');
                this.resisterLifeline();
            },

            resisterLifeline: function () {
                //作図をJSON化
                topic.publish(this.JSONIZE_RQST);
                var sendData = this.form.get('value');
                // 入力チェック
                if (!this.validateForm(sendData)) {
                    return false;
                }
                // 送信データの整形・補完
                sendData.disasterId = this._disasterId;
                sendData.latitude = this.pointLat;
                sendData.longitude = this.pointLng;
                // 登録リクエスト
                var jsonStr = json.stringify(sendData);
                var confirmMessage = '登録します。よろしいですか？';
                var self = this;
                this.chain.confirm(confirmMessage, function (chain) {
                    var promise = Requester.post('/api/lifeline', {
                        data: jsonStr
                    }).then(function (data) {
                        console.debug('success register Lifeline');
                        var completeMessage = '完了しました。';
                        // 作図情報を登録
                        var param = '?lifelineId=' + data.lifelineId;
                        return Requester.post('/api/lifeline/uploadjson' + param, {
                            data: self.drawJson,
                            headers: { 'Content-Type': 'application/json; charset=utf-8' },
                            handleAs: 'json',
                            preventCache: true
                        }).then(function () {
                            console.debug('success upload json');
                            chain.info(completeMessage, '完了', function () {
                                // 一覧画面に移動
                                Router.moveTo('lifeline');
                            });
                        }, function (err) {
                            console.log(err);
                            chain.info('作図ファイルの登録に失敗しました。', 'エラー', function () {
                                // 一覧画面に移動
                                Router.moveTo('report', { municipalityCd: this._municipalityCd });
                            });
                        });
                    }, function (error) {
                        console.log('error register Lifeline');
                        console.debug(error);
                        chain.info('登録に失敗しました。', 'エラー');
                    });
                    //ローダーの表示
                    Loader.wait(promise);
                });
            },

            validateForm: function (data) {
                // フォームのバリデーションを行う（共通部品）
                if (!this.form.validate()) {
                    return false;
                }
                // フォームのバリデーションを行う（独自チェック）
                if (!data.lifelineKindCd || data.lifelineKindCd === '') {
                    this.chain.info('ライフライン種別を選択してください。', '入力エラー');
                    return false;
                }
                if (!data.reportTime || data.reportTime === '') {
                    this.chain.info('報告日時を入力してください。', '入力エラー');
                    return false;
                }
                return true;
            },

            // パンくずリストのボタンを押下したときの挙動
            onLifelineListPageLinkClick: function (evt) {
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                Router.moveTo('lifeline');
            },

            /**
             * 地図上でポイントされている位置の住所を設定します。
             */
            mapToAddress: function () {
                if (this.pointLat === '' || this.pointLng === '') {
                    console.debug('not pointed map');
                    this.chain.info('地図が選択されていません。', 'エラー');
                    return;
                }
                console.debug('start reverse geocoding');

                _geoService.reverseGeocode(leaflet.latLng({
                    lat: this.pointLat,
                    lng: this.pointLng
                })).then(lang.hitch(this, function (res) {
                    this.addMark(this.pointLat, this.pointLng);
                    this.address.set('value', res.address.Address);
                    this._addressLat = this.pointLat;
                    this._addressLng = this.pointLng;
                }), lang.hitch(this, function () {
                    this.chain.info('住所を取得できませんでした。', 'エラー');
                }));

                console.debug('end reverse geocoding (address: ' +
                    this.address.value + ')');
            },

            /**
             * 住所の位置を地図上にポイントします。
             */
            addressToMap: function () {
                if (!this.address.value) {
                    this.chain.info('住所を入力してください。', 'エラー');
                    return;
                }
                console.debug('start geocoding');
                //住所欄に県名が記載されていない場合、追加して検索する。
                var address = this.address.value;
                if (this.address.value.indexOf(config.municInfo.prefName) !== 0) {
                    address = config.municInfo.prefName + address;
                }
                _geoService.geocode(address).then(lang.hitch(this, function (results) {
                    if (results.length > 0) {
                        var latlng = [results[0].latlng.lat, results[0].latlng.lng];
                        this.pointLat = results[0].latlng.lat;
                        this.pointLng = results[0].latlng.lng;
                        this.addMark(this.pointLat, this.pointLng);
                        this.map.setView(latlng, 14);
                        this._addressLat = results[0].latlng.lat;
                        this._addressLng = results[0].latlng.lng;
                    } else {
                        console.debug('address is not correct');
                        this.chain.info('住所から位置情報を取得できませんでした。', 'エラー');
                    }
                }));
            },

            /**
             * マーカーを追加する。
             */
            addMark: function (lat, lng) {
                this.removeMark();
                this.marker = leaflet.marker([lat, lng]).addTo(this.map);
            },

            /**
             * マーカーを削除する。
             */
            removeMark: function () {
                if (this.marker) {
                    this.map.removeLayer(this.marker);
                }
            },

            /**
             * 背景地図ダイアログを表示する。
             */
            showBaseLayerDialog: function () {
                if (!this._baseLayerDialog) {
                    // 初回呼び出し時にインスタンス生成
                    this._baseLayerDialog = new IdisDialog({
                        noUnderlay: true,
                        title: '背景地図',
                        content: new BaseLayerPane({ map: this.map })
                    });
                    // 画面が破棄された際に連れて行く
                    this.own(this._baseLayerDialog);
                }
                this._baseLayerDialog.show();
            },

            /**
             * 作図ペインを生成する。
             */
            createDrawPanel: function () {
                if (this.drawPanel === null) {
                    this.own(
                        this.drawPanel = new DrawPanel({
                            map: this.map,
                            'class': 'drawPanel-NonModal',
                            dispType: 'damage'
                        }));
                }
            },

            /**
             * 作図ダイアログを表示する。
             */
            showDrawPanelDialog: function () {
                this.drawPanel.show();
            }
        });
});
