/**
 * テンプレート管理用グリッド
 * @module app/prefsitesending/PrefSietSendingSelectorGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function(module, declare, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid,
        /** @lends module:app/prefsitesending/PrefSietSendingSelectorGrid~ProvideTemplatePage# */ {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--prefsitesending',

        collection: this.store,
        columns: [
            helper.buttonColumn('apply', '適用'),
            {field: 'tmplSubject', label: '件名', sortable: false},
            {field: 'tmplBody', label: '本文', sortable: false}
        ]
    });
});
