/**
 * 安否確認メールテンプレート管理画面用。
 * @module app/notice/view/SafetyMailTempPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/SafetyMailTempPage.html',
    'idis/view/dialog/DialogChain',
    'idis/control/Router',
    'idis/service/Requester',
    'idis/store/IdisRest',
    'idis/view/Loader',
    'idis/view/page/_PageBase',
    // 以下、変数から参照されないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/form/Form',
    'app/safety/auth/view/form/AclButton',
    'app/safety/mail/SafetyMailTempGrid',
    'app/safety/mail/SafetyMailTempRegisterDialog',
    'app/safety/mail/SafetyMailTempDetailDialog'
], function(module, declare, lang, template, DialogChain, Router, Requester, IdisRest, Loader, _PageBase) {
    /**
     * テンプレート管理画面。
     * @class SafetyMailTempPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/SafetyMailTempPage~SafetyMailTempPage# */ {
        // テンプレート文字列
        templateString: template,

        store: null,
        
        // テンプレートコード
        tmplCd: '',

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--safety',

        // APIコール時のURL共通部分
        requestUrl: '/api/safety/temps',

        /**
         * 変数の初期化。
         */
        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'distributionTmplCd',
                target: this.requestUrl
            });
            // ダイアログ連鎖を登録
            // 引数に与えたウィジェットのthis.ownを呼び出し、
            // ウィジェットが破棄された際にダイアログ連鎖が破棄されるようになる
            this.chain = DialogChain.get(this);
        },

        /**
         * DOMノードを生成するためのメソッド
         */
        buildRendering: function() {
            this.inherited(arguments);
            // 自分が削除された時は関連ダイアログも削除する
            this.initRegisterPage();
            this.initDetailPage();
            this.initSafetyMailTempGrid();
        },

        /*
         * パンくずリストのボタンを押下したときの挙動
         */
        onSafetyMailTempLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('safety/mail');
        },

        /**
         * テンプレート詳細ダイアログを表示する。
         */
        showSafetyMailTempDetailDialog: function(item){
            console.log('[詳細]ボタンがクリックされました');
            var page = this.safetyMailTempDetailDialog.getChildren()[0];
            var listBox = page.categorySelector;

            // アクセス用URL
            var url = this.requestUrl + '/' + item.distributionTmplCd;
            
            // IDをキーにテンプレート情報を再度サーバから取得し、ダイアログのformに設定
            Requester.get(url).then(function(data) {
                // 改行コードへの対応
                var body = data.distributionBody;
                // 改行コード(¥n)を含む場合は、\nに変換
                body = body.replace(/¥n/g, '\n');
                // 質問カテゴリコードが変わったら質問・回答をリセット
                if (listBox.value!== data.categoryCd) {
                    page.form.reset();
                }
                // form情報の設定
                page.form.set('value', {
                    distributionTmplCd: data.distributionTmplCd,
                    distributionTitle: data.distributionTitle,
                    distributionBody: body
                });

                // 質問カテゴリを設定
                listBox.set('value', data.categoryCd);

            }, lang.hitch(this, function(error) {
                // 取得に失敗した場合は、ログ出力とメッセージのダイアログ表示を行う。
                console.log(error);
                this.chain.info('テンプレート情報の取得に失敗しました。', 'エラー');
            }));
            
            // 更新・削除に備え、テンプレートコードを保持
            this.tmplCd = item.distributionTmplCd;
            
            // ダイアログを表示
            this.safetyMailTempDetailDialog.show();

        },

        /**
         * 新規登録ダイアログを表示する。
         */
        showSafetyMailTempRegisterDialog: function(){
            console.log('[新規登録]ボタンがクリックされました');
            var page = this.safetyMailTempRegisterDialog.getChildren()[0];
            // 画面上のフォームをリセット
            // （ダイアログ内画面のテンプレートHTMLでdata-dojo-attach-point="form"指定済み）
            page.form.reset();
            // ダイアログを表示
            this.safetyMailTempRegisterDialog.show();

        },

        /**
         * 登録画面のフォームが投稿された際の動作を設定する。
         */
        initRegisterPage: function() {
            // 登録ダイアログの最初の子要素が登録画面
            var dialog = this.safetyMailTempRegisterDialog;
            var page = dialog.getChildren()[0];

            // 登録画面のregisterイベントを受け取る
            page.on('register', lang.hitch(this, function(evt) {
            // 追加確認ダイアログを表示
            this.chain.confirmAdd(function(chain) {
                // OKが押された場合
                // 追加処理と共にローディング表示
                Loader.wait(this.store.add(evt.value)).then(function() {
                        // 成功時（POST結果はグリッドが自動的に反映）
                        // 登録ダイアログを閉じる
                        dialog.hide();
                        // 完了ダイアログを表示
                        chain.infoComplete();
                    }, function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));
        },

        /**
         * 詳細ダイアログのフォームが投稿された際の動作を設定する。
         */
        initDetailPage: function() {
            // 登録ダイアログの最初の子要素が登録画面
            var dialog = this.safetyMailTempDetailDialog;
            var page = dialog.getChildren()[0];

            //対象のテンプレートを更新する
            page.on('update', lang.hitch(this, function(evt) {
                // ダイアログから受けたform情報にテンプレートコードを付与
                evt.value.distributionTmplCd = this.tmplCd;
                // 更新確認ダイアログを表示
                this.chain.confirmPut(function(chain) {
                 // OKが押された場合
                 // 追加処理と共にローディング表示
                    Loader.wait(this.store.put(evt.value)).then(function() {
                        // 成功時（POST結果はグリッドが自動的に反映）
                        // 登録ダイアログを閉じる
                        dialog.hide();
                        // 完了ダイアログを表示
                        chain.infoComplete();
                    }, function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));

            //対象のテンプレートを削除する
            page.on('delete', lang.hitch(this, function() {
                // 削除確認ダイアログを表示
                this.chain.confirmDel(lang.hitch(this, function(chain) {
                  // OKが押された場合
                  // 削除処理と共にローディング表示
                  Loader.wait(this.store.remove(this.tmplCd)).then(function() {
                    // 成功時（POST結果はグリッドが自動的に反映）
                    // 登録ダイアログを閉じる
                    dialog.hide();
                    // 完了ダイアログを表示
                    chain.infoComplete();
                  }, function(err) {
                    // 失敗時
                    chain.infoError(err);
                  });
               }));
            }));
        },

        /**
         * テンプレート用Gridの初期化。
         */
        initSafetyMailTempGrid: function() {
            this.safetyMailTempGrid.set('collection', this.store);
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.safetyMailTempGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                // helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
                console.log('safetyMailtTempGridButton');
                this.showSafetyMailTempDetailDialog(evt.item);
            }));
        },

        /**
         * 検索ボタンが押下された際に呼ばれるメソッド。
         */
        onSubmit :function() {
            // formに渡された検索条件
            var value = this.form.get('value');

            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();
            try {
                // 画面からタイトルが入力されていれば、タイトルを検索条件として設定
                if (value.title) {
                    filter = filter.eq('title',value.title);
               }
                // filterに対応するcollectionを取得
                var collection = this.store.filter(filter);
                // collectionをグリッドにセットする（サーバーにリクエストされる）
                this.safetyMailTempGrid.set('collection', collection);
            } catch(e) {
                // 検索時にエラーを検出した場合、エラーログを出力
                console.error(e);
            }

            return false;
        }
    });
});
