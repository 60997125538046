/**
 * 配信情報登録画面用モジュール。
 * @module app/sendingHistory/SendingHistoryRegisterPage
 */
define([
    'module',
    'app/model/DisasterInfo',
    'dojo',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/dom-style',
    'dojo/json',
    'dojo/text!./templates/SendingHistoryRegisterPage.html',
    'dojo/topic',
    'dojo/when',
    'dojo/Deferred',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/model/UserInfo',
    'idis/service/Requester',
    'idis/view/dialog/InfoDialog',
    'idis/view/Loader',
    './_SendingHistoryPageBase',
    'app/model/Organization',
    'app/provide/ProvideUtil',
    'idis/consts/USER_TYPE',
    'app/config',
    // 以下、変数として受け取らないモジュール
    'dijit/form/CheckBox',
    'dijit/form/Select',
    'idis/view/form/WordCountTextarea',
    'idis/view/form/DateTimeInput',
    'app/view/form/OrganizationSelector',
    'app/sendingHistory/SendingHistoryConfirmDialog'
], function (
    module,
    DisasterInfo,
    dojo,
    declare,
    lang,
    array,
    domStyle,
    json,
    template,
    topic,
    when,
    Deferred,
    Locator,
    Router,
    UserInfo,
    Requester,
    InfoDialog,
    Loader,
    _SendingPageBase,
    Organization,
    ProvideUtil,
    USER_TYPE,
    config
) {
    /**
     * 配信情報新規登録画面。
     * @class SendingHistoryRegisterPage
     * @extends module:app/sendingHisotry/_SendingHistoryPageBase~_SendingHistoryPageBase
     */
    return declare(
        module.id.replace(/\//g, '.'),
        _SendingPageBase,
        /** @lends module:app/sendingHistory/SendingHistoryRegisterPage~SendingHistoryRegisterPage# */ {
            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--sending',

            _isEmergency: null, // true or false
            _deptCd: null,
            _sectCd: null,
            _unitCd: null,
            // ツイッター文字数カウント用
            _tweetNum: null,

            TWITTER_MAX_LENGTH: 140,

            // 配信先市町村の設定情報
            _sendingSetting: null,

            _municipalityCd: null,

            constructor: function () {
                this.inherited(arguments);
            },

            startup: function () {
                this.inherited(arguments);

                this._isEmergency = Locator.getQuery().emergency === '0' ? false : true;
                this._deptCd = UserInfo.getOrganization().deptCd;
                this._sectCd = UserInfo.getOrganization().sectCd;
                this._unitCd = UserInfo.getOrganization().unitCd;
                this.senderName.set('value', UserInfo.getName());
                this.lalert.set('checked', true);
                if (this._isEmergency) {
                    this.emergencyMail.set('checked', true);
                    domStyle.set(this.dispEmergencyMailCheckBox, 'display', 'inline');
                } else {
                    this.emergencyMail.set('checked', false);
                    domStyle.set(this.dispEmergencyMailCheckBox, 'display', 'none');
                }

                // 配信課
                // 報告部署にログインユーザの組織情報を設定
                var orgCd = this._unitCd
                    ? 'U' + this._unitCd
                    : this._sectCd
                        ? 'S' + this._sectCd
                        : this._deptCd
                            ? 'D' + this._deptCd
                            : '';
                Organization.store.get(orgCd).then(
                    lang.hitch(this, function (item) {
                        var _sendOrganizationName = item ? item.name : '取得失敗';

                        this.sendOrganizationName.set('value', _sendOrganizationName);
                    })
                );

                this._municipalityCd =
                    UserInfo.getUserType() === USER_TYPE.MUNICIPALITY
                        ? UserInfo.getMunicipalityCd() || UserInfo.getMunicipalityCds()[0]
                        : null;

                this._setSendingSetting(config.municInfo.cityMunicCd);
            },

            /**
             * サーバから配信先市町村の設定を取得する
             * コールバック関数の引数には成功時は設定の値、失敗時はnullが入る
             * メンバ変数_sendingSettingに設定を代入する
             *
             * 呼ぶときは when(this._setSendingSetting(municipalityCd), function())
             */
            _setSendingSetting: function (municipalityCd) {
                var deferred = new Deferred();
                this._sendingSetting = null;
                if (!municipalityCd) {
                    return deferred.promise;
                }
                municipalityCd = this._convertPrefectureCd(municipalityCd);
                var promise = Requester.get('/api/municipalities/sendingsetting/', {
                    query: {
                        municipalityCds: municipalityCd
                    }
                });
                Loader.wait(promise).then(
                    lang.hitch(this, function (sendingSettings) {
                        if (sendingSettings.length === 0) {
                            deferred.resolve(null);
                        }
                        this._sendingSetting = sendingSettings[0];
                        deferred.resolve(this._sendingSetting);
                    }),
                    function () {
                        // error
                        deferred.resolve(null);
                    }
                );
                return deferred.promise;
            },

            /*
             * チャネル別詳細設定を表示
             */
            showChannelDetails: function () {
                domStyle.set(this.inputGuide, 'display', 'none');
                var isChecked = false;

                // Lアラート
                if (this.lalert.checked) {
                    isChecked = true;
                    domStyle.set(this.inputLalert, 'display', 'block');
                    this.lalertInputBody.set('value', this.bodyText.displayedValue);
                    this.onChangeLalertCategorySelect();
                } else {
                    domStyle.set(this.inputLalert, 'display', 'none');
                }

                // Twitter
                if (this.twitter.checked) {
                    isChecked = true;
                    domStyle.set(this.inputTwitter, 'display', 'block');
                    var twitterBodyText = '【' + this.subject.displayedValue + '】' + this.bodyText.displayedValue;

                    // tweetを全て削除
                    var element = dojo.byId('twitterOuterFrame');
                    while (element.firstChild) {
                        element.removeChild(element.firstChild);
                    }

                    // tweet必要枠の計算
                    this._tweetNum = Math.ceil(this.getLen(twitterBodyText) / this.TWITTER_MAX_LENGTH);
                    var connectWordLengthFirst = 4;
                    var connectWordLengthLast = 3;
                    var connectAllLength = connectWordLengthFirst + connectWordLengthLast;
                    if (this._tweetNum > 1) {
                        // 「（続く）」分の文字数を考慮して枠を追加
                        this._tweetNum = Math.ceil(
                            ((this._tweetNum - 1) * connectAllLength + this.getLen(twitterBodyText)) /
                            this.TWITTER_MAX_LENGTH
                        );
                    }
                    var words = 0;
                    for (var i = 0; i < this._tweetNum; i++) {
                        var oneTweetContentLength = this.TWITTER_MAX_LENGTH - connectWordLengthLast - 1;
                        if (i !== 0) {
                            oneTweetContentLength -= connectWordLengthFirst;
                        }
                        var oneTweetText = twitterBodyText.substr(words, oneTweetContentLength);
                        this.addTweet(oneTweetText, i + 1);
                        words += oneTweetContentLength;
                    }
                } else {
                    domStyle.set(this.inputTwitter, 'display', 'none');
                }

                // 府民向けのお知らせ
                if (this.newsForCitizens.checked) {
                    isChecked = true;
                    domStyle.set(this.inputNewsForCitizens, 'display', 'block');
                    this.newsForCitizensPublishDatetime.set('value', this.sendTimestamp.get('value'));
                    var newsForCitizensBodyText =
                        '【' + this.subject.displayedValue + '】' + this.bodyText.displayedValue;
                    this.newsForCitizensBody.set('value', newsForCitizensBodyText);
                } else {
                    domStyle.set(this.inputNewsForCitizens, 'display', 'none');
                }

                // チェックが付いていなければガイドを表示
                if (!isChecked) {
                    domStyle.set(this.channelSelectWarning, 'display', 'block');
                } else {
                    domStyle.set(this.channelSelectWarning, 'display', 'none');
                }
            },

            addTweet: function (oneTweetText, i) {
                // +ボタンで追加した場合
                if (oneTweetText.type === 'click') {
                    oneTweetText = '';
                    this._tweetNum += 1;
                    i = this._tweetNum;
                } else {
                    // 最終ツイート以外に「続く」を入れる
                    if (i !== this._tweetNum) {
                        oneTweetText = oneTweetText + '(続く)';
                    }
                }
                // 最初ツイート以外に「続き」を入れる
                if (i !== 1) {
                    oneTweetText = '(続き)' + oneTweetText;
                }
                this.createTweetElements(oneTweetText, i);
            },

            /**
             * 配信情報一覧画面へ遷移する。
             */
            onSendingHistoryPageLinkClick: function (evt) {
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                Router.moveTo('sendingHistory');
            },

            /**
             * 未定義か地域コードの場合はメッセージを表示してtrueを返す
             */
            _invalidMunicipalityCd: function (municipalityCd, targetName) {
                if (!municipalityCd) {
                    if (targetName) {
                        InfoDialog.show('', targetName + 'の市/行政区を選択してください。');
                    } else {
                        InfoDialog.show('', '市/行政区を選択してください。');
                    }
                    return true;
                }
                if (
                    municipalityCd !== config.municInfo.prefCd &&
                    municipalityCd.slice(0, 4) === config.municInfo.prePrefCd + '00'
                ) {
                    if (targetName) {
                        InfoDialog.show('', targetName + 'では地域は選択できません。');
                    } else {
                        InfoDialog.show('', '地域は選択できません。');
                    }
                    return true;
                }
                return false;
            },

            /**
             * 県のコードを変換する。
             *
             * ManageRegionMunicipalitySelectorは県のコードとしてAreaCode.propertiesの
             * PREF_FULL_CODEの値を使用しているが、配信情報が登録される
             * T_SENDING_HEADERテーブルのMUNICIPALITY_CD列はM_MUNICIPALITYテーブルの
             * MUNICIPALITY_CD列との間に参照整合性制約があるため、県は市町村コードでなければ登録できない。
             */
            _convertPrefectureCd: function (municipalityCd) {
                if (municipalityCd === config.municInfo.prefCd) {
                    return config.municInfo.prefMunicCd;
                }
                return municipalityCd;
            },

            /**
             * 配信確認ダイアログを表示する
             */
            showConfirmDialog: function () {
                // // 市町村
                // if (this._invalidMunicipalityCd(this.municipalityCd.value, null)) {
                //     return;
                // }

                // 配信先を選択しているかチェック
                if (
                    !(
                        this.emergencyMail.checked ||
                        this.lalert.checked ||
                        this.twitter.checked ||
                        this.newsForCitizens.checked
                    )
                ) {
                    InfoDialog.show('', '配信先チャンネルを選択してください。');
                    return;
                }

                // チェックボックスとチャネル詳細が合致しているかチェック
                if (this._invalidChannelDetailDisplay()) {
                    InfoDialog.show('', '「チャンネル別詳細設定ボタン」を<br>押して詳細を確認してください。');
                    return;
                }

                // Lアラートの内容チェック
                if (this.lalert.checked) {
                    // Lアラートの本文チェック
                    if (this.lalertInputBody.value === '') {
                        InfoDialog.show('入力チェック', 'Lアラートの本文が入力されていません');
                        return;
                    }
                }

                //twitter
                if (this.twitter.checked) {
                    // テキストエリアの残り文字数計算
                    var element = dojo.byId('twitterOuterFrame');
                    var elements = element.getElementsByTagName('textarea');
                    // 配信内容が空の時にエラーメッセージの表示
                    if (this.getLen(elements[0].value) === 0) {
                        InfoDialog.show('', 'Twitterの配信内容が空です。配信内容を記載してください。');
                        return;
                    }
                    // 文字数制限を超えている時にエラーメッセージの表示
                    for (var i = 0; i < elements.length; i++) {
                        var text = elements[i].value;
                        var words = this.TWITTER_MAX_LENGTH - this.getLen(text);
                        if (words < 0) {
                            InfoDialog.show(
                                '',
                                'Twitterの文字数制限を超えています。各記入枠の文字数を制限内に抑えてください。'
                            );
                            return;
                        }
                    }
                }

                // 府民向けのお知らせ
                if (this.newsForCitizens.checked) {
                    if (!this.newsForCitizensPublishDatetime.get('value')) {
                        InfoDialog.show('入力チェック', '府民向けのお知らせの掲載開始日時を設定してください。');
                        return;
                    }
                    if (!this.newsForCitizensCloseDatetime.get('value')) {
                        InfoDialog.show('入力チェック', '府民向けのお知らせの掲載終了日時を設定してください。');
                        return;
                    }
                    if (this.newsForCitizensBody.value === '') {
                        InfoDialog.show(
                            '入力チェック',
                            '府民向けのお知らせの配信内容が空です。配信内容を記載してください。'
                        );
                        return;
                    }
                    if (this.subject.value.length > 20) {
                        InfoDialog.show(
                            '入力チェック',
                            '府民向けのお知らせの件名は【20文字以内】です。'
                        );
                        return;
                    }
                }

                // フォームの入力チェック
                if (!this.form.validate()) {
                    return;
                }

                // ダイアログを表示
                this.confirmDialog.show();
                // ダイアログ中のページウィジェットを取得
                var page = this.confirmDialog.getChildren()[0];
                // ダイアログ側で「一括配信」ボタンが押された際のイベント
                page.on(
                    'register',
                    lang.hitch(this, function (data) {
                        // 登録
                        return this.register(data.sendTimestamp);
                    })
                );
                page.refresh(this);
            },

            /**
             * テスト送信ダイアログを表示する
             */
            showTestConfirmDialog: function () {
                // 配信先を選択しているかチェック
                if (!this.emergencyPush.checked) {
                    InfoDialog.show('', '配信先チャンネルを選択してください。');
                    return;
                }

                // チェックボックスとチャネル詳細が合致しているかチェック
                if (this._invalidChannelDetailDisplay()) {
                    InfoDialog.show('', '「チャンネル別詳細設定ボタン」を<br>押して詳細を確認してください。');
                    return;
                }

                // 緊急情報配信（防災アプリ）
                if (this.emergencyPush.checked) {
                    if (this.emergencyPushSubject.value === '') {
                        InfoDialog.show('入力チェック', '緊急情報配信（防災アプリ）の件名が入力されていません');
                        return;
                    }
                    if (this.emergencyPushBody.value === '') {
                        InfoDialog.show('入力チェック', '緊急情報配信（防災アプリ）の本文が入力されていません');
                        return;
                    }
                }

                // フォームの入力チェック
                if (!this.form.validate()) {
                    return;
                }

                // ダイアログを表示
                this.confirmTestDialog.show();
                // ダイアログ中のページウィジェットを取得
                var page = this.confirmTestDialog.getChildren()[0];
                // ダイアログ側で「テスト配信」ボタンが押された際のイベント
                page.on(
                    'register',
                    lang.hitch(this, function (data) {
                        // テスト送信
                        return this.testSend(data.sendTimestamp);
                    })
                );
                page.refresh(this);
            },

            /**
             * チェックボックスとチャネル詳細が合致していないことをチェックする
             * 合致していない場合trueを返す
             */
            _invalidChannelDetailDisplay: function () {
                var objs = [
                    { display: this.inputLalert.style.display, checked: this.lalert.checked },
                    { display: this.inputTwitter.style.display, checked: this.twitter.checked },
                    { display: this.inputNewsForCitizens.style.display, checked: this.newsForCitizens.checked }
                ];
                for (var i = 0; i < objs.length; i++) {
                    var dispState = objs[i].display !== 'block' ? false : true;
                    if (dispState !== objs[i].checked) {
                        return true;
                    }
                }
                return false;
            },

            /**
             * 配信情報を登録する。
             */
            register: function (sendTimestamp) {
                console.debug('[登録]ボタンがクリックされました。');
                //var sendData = this.form.get('value');
                var sendData = {};

                /* 共通部分 */
                sendData.disasterId = DisasterInfo.getDisasterId();
                sendData.infoType = '10'; // '10'「お知らせ」決め打ち
                sendData.deptCd = this._deptCd;
                sendData.sectCd = this._sectCd;
                sendData.unitCd = this._unitCd;
                sendData.sendOrganizationName = this.sendOrganizationName.get('value');
                sendData.sendTimestamp = sendTimestamp;
                // var municipalityCd = this.municipalityCd.get('value');
                // sendData.municipalityCd = this._convertPrefectureCd(municipalityCd);
                sendData.municipalityCd = config.municInfo.cityMunicCd;
                sendData.subject = this.subject.get('value');
                sendData.bodyText = this.bodyText.get('value');
                sendData.senderName = this.senderName.get('value');

                /* チャネル別詳細設定 */

                // Lアラート
                if (this.lalert.checked) {
                    sendData.lalertFlg = '1';
                    sendData.publisher = this.publisher.get('value');
                    sendData.sendCategory = '04'; // '04'「お知らせ」決め打ち
                    sendData.lalertUrgency = this.lalertUrgency.get('value');
                    sendData.lalertCategory = this.lalertCategory.get('value');
                    sendData.sendingDetailType = this.sendingDetailType.get('value');
                    sendData.lalertChangeType = null;
                    sendData.lalertReason = null;
                    sendData.lalertSendHistId = null;
                    sendData.lalertSubject = null;
                    sendData.lalertBodyText = this.lalertInputBody.get('value');
                } else {
                    sendData.lalertFlg = '0';
                }

                // Twitter
                if (this.twitter.checked) {
                    sendData.twitterFlg = '1';
                    var tweetData = this.twitterOuterFrame.getElementsByTagName('textarea');
                    var tweetList = [];
                    array.forEach(tweetData, function (data) {
                        tweetList.push(data.value);
                    });
                    sendData.tweetList = tweetList;
                } else {
                    sendData.twitterFlg = '0';
                }

                // 府民向けのお知らせ
                if (this.newsForCitizens.checked) {
                    sendData.newsForCitizensFlg = '1';
                    sendData.newsForCitizensPublishDatetime = this.newsForCitizensPublishDatetime.get('value');
                    sendData.newsForCitizensCloseDatetime = this.newsForCitizensCloseDatetime.get('value');
                    sendData.newsForCitizensBody = this.newsForCitizensBody.get('value');
                } else {
                    sendData.newsForCitizensFlg = '0';
                }

                console.dir(sendData);

                // 最終確認を行う際のメッセージ文字列
                var lastConfirmMsg = '';
                lastConfirmMsg += this.lalert.checked ? '・Lアラート' : '';
                lastConfirmMsg += this.twitter.checked ? '・Twitter' : '';
                lastConfirmMsg += this.newsForCitizens.checked ? '・府民向けのお知らせ' : '';
                lastConfirmMsg += 'に連携します。<br>よろしいですか？';
                lastConfirmMsg = lastConfirmMsg.slice(1);
                lastConfirmMsg = '<div style="text-align:center;">' + lastConfirmMsg + '</div>';

                // 登録確認ダイアログを表示
                return this.chain.confirm(lastConfirmMsg, function (chain) {
                    var promise = Requester.post('/api/sending', {
                        data: json.stringify(sendData),
                        headers: { 'Content-Type': 'application/json; charset=utf-8' },
                        handleAs: 'json',
                        preventCache: true
                    }).then(
                        lang.hitch(this, function (data) {
                            console.debug('success register Sending');
                            console.debug(data);
                            chain.infoComplete(function () {
                                this.confirmDialog.hide();
                                // 一覧画面に移動
                                Router.moveTo('sendingHistory');
                            });
                        }),
                        lang.hitch(this, function (error) {
                            console.log('error register Sending');
                            chain.infoError(error);
                            this.confirmDialog.hide();
                        })
                    );
                    // ローダーの表示
                    Loader.wait(promise);
                });
            },

            /**
             * テスト配信情報を登録する。
             */
            testSend: function (sendTimestamp) {
                console.debug('[テスト配信]ボタンがクリックされました。');
                var sendData = {};

                /* 共通部分 */
                sendData.disasterId = DisasterInfo.getDisasterId();
                sendData.infoType = '10'; // '10'「お知らせ」決め打ち
                sendData.deptCd = this._deptCd;
                sendData.sectCd = this._sectCd;
                sendData.unitCd = this._unitCd;
                sendData.sendOrganizationName = this.sendOrganizationName.get('value');
                sendData.sendTimestamp = sendTimestamp;
                sendData.municipalityCd = config.municInfo.cityMunicCd;
                sendData.subject = this.subject.get('value');
                sendData.bodyText = this.bodyText.get('value');
                sendData.senderName = this.senderName.get('value');

                console.dir(sendData);

                // 最終確認を行う際のメッセージ文字列
                var lastConfirmMsg = '';
                lastConfirmMsg += '緊急情報配信（防災アプリ）へのテスト配信を行います。';
                lastConfirmMsg += '<br>よろしいですか？';
                lastConfirmMsg = '<div style="text-align:center;">' + lastConfirmMsg + '</div>';

                // 登録確認ダイアログを表示
                return this.chain.confirm(lastConfirmMsg, function (chain) {
                    var promise = Requester.post('/api/sending/test', {
                        data: json.stringify(sendData),
                        headers: { 'Content-Type': 'application/json; charset=utf-8' },
                        handleAs: 'json',
                        preventCache: true
                    }).then(
                        lang.hitch(this, function (data) {
                            console.debug('success test Sending');
                            console.debug(data);
                            chain.infoComplete(function () {
                                this.confirmTestDialog.hide();
                                this.chain.hide();
                                this.getParent().hide();
                            });
                        }),
                        lang.hitch(this, function (error) {
                            console.log('error test Sending');
                            chain.infoError(error);
                            this.confirmTestDialog.hide();
                        })
                    );
                    // ローダーの表示
                    Loader.wait(promise);
                });
            },

            /**
             * テンプレートを変更する
             */
            _changeTemplate: function (data) {
                // 選択されたテンプレートを画面へセット
                this.subject.set('value', data.subject);
                this.bodyText.set('value', data.bodyText);

                // 選択しているテンプレートIDを設定する。
                topic.publish(module.id + '::selected', data.admNum);
            },

            /**
             * メンバ変数_sendingSettingからtwitterIdとして表示する文字列を返す
             */
            getTwitterIdStr: function () {
                // return this._sendingSetting.municipalityName + ' @' + this._sendingSetting.twitterId;
                return config.municInfo.cityMunicName + ' ' + this._sendingSetting.twitterId;
            },

            /**
             * 選択可能な全てのチャネルを選択する
             */
            checkAllChannels: function () {
                if (UserInfo.hasAuthz('F06005')) {
                    this.lalert.set('checked', true);
                }
                if (UserInfo.hasAuthz('F06003')) {
                    this.twitter.set('checked', true);
                    this.newsForCitizens.set('checked', true);
                }
            },

            /**
             * 全てのチャネルを未選択にする
             */
            uncheckAllChannels: function () {
                this.lalert.set('checked', false);
                this.twitter.set('checked', false);
                this.newsForCitizens.set('checked', false);
            }
        }
    );
});
