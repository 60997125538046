/**
 * チャートと凡例を表示するためのモジュール。
 * @module app/observation/river/chart/ChartContainer
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/debounce',
    'dojo/dom-geometry',
    'dojo/on',
    'dojo/window',
    'dijit/_WidgetBase',
    'dojox/charting/Chart',
    'dojox/charting/themes/Bahamation',
    'dojox/charting/widget/SelectableLegend',
    // 以下、変数で受けないモジュール
    'dojox/charting/axis2d/Default'
], function(module, declare, lang, debounce, domGeometry, on, win, _WidgetBase,
    Chart, Bahamation, SelectableLegend) {
    return declare(module.id.replace(/\//g, '.'), _WidgetBase, {
        /**
         * グラフ上部に出すタイトル文字列
         * @type {string}
         */
        title: null,

        /**
         * グラフのテーマ
         * @type {dojox/charging/theme}
         * @defualt
         */
        theme: Bahamation,

        /**
         * グラフ描画オブジェクト
         * @type {dojox/charting/Chart}
         */
        chart: null,

        /**
         * @type {
         */
        data: null,

        /**
         * グラフのサイズを表示領域に合わせて調整する。
         * 処理として重いため、呼び出し回数をdebounceで間引く。
         * @type {function}
         */
        _fitChart: null,

        constructor: function() {
            // 直接debounceの返り値をメソッドに設定すると
            // インスタンス間でdebounceの状態が共有されてしまうため、
            // インスタンスごとに都度関数を生成する
            this._fitChart = debounce(function() {
                if (this.chart) {
                    var domBox = domGeometry.getMarginBox(this.domNode);
                    var legendBox = domGeometry.getMarginBox(this.legendNode);
                    domBox.h -= legendBox.h;
                    this.chart.resize(domBox);
                }
            }, 10);
        },

        // DOMを構築する
        buildRendering: function() {
            this.inherited(arguments);
            // チャート要素の設置
            this.chartNode = document.createElement('div');
            this.chartNode.style.width = '100%';
            this.chartNode.style.height = '100%';
            this.domNode.appendChild(this.chartNode);
            var chartArgs = {};
            if (this.title) {
                chartArgs.title = this.title;
                chartArgs.titlePos = 'top';
                chartArgs.titleGap = 10;
            }
            this.chart = new Chart(this.chartNode, chartArgs);
            this.chart.setTheme(this.theme);

            // 凡例要素の設置
            // スタイル指定がキャンセルされるため、
            this.legendNode = document.createElement('div');
            this.domNode.appendChild(this.legendNode);
            this.legend = new SelectableLegend({
                style: {
                    marginTop: '.5em'
                },
                chart: this.chart
            }, this.legendNode);
            this.legendNode = this.legend.domNode;
            this.legend.refresh();
        },

        // DOM構築後に呼ばれる
        postCreate: function() {
            this.inherited(arguments);
            // PC版は外側のウィジェットからresizeメソッドが呼ばれるが、
            // モバイル版は呼ばれないので、ウィンドウ・サイズの変化に反応させる。
            // PC版では両方で呼ばれることになるが、debounceで間引きされる。
            this.own(on(win.get(this.ownerDocument), 'resize', lang.hitch(this, '_fitChart')));
        },

        // ウィジェット破棄の際に呼ばれる
        destroy: function() {
            if (this.chart) {
                this.chart.destroy();
            }
            this.inherited(arguments);
        },

        // 画面サイズが変わった場合等に外側から呼ばれる
        resize: function(changeSize) {
            // DOM要素のサイズを調整
            domGeometry.setMarginBox(this.domNode, changeSize);
            // 一定時間待ってからグラフを合わせる
            this._fitChart();
        },

        // set('title', ...) 経由で呼ばれる
        _setTitleAttr: function(title) {
            this._set('title', title);
            if (this.chart) {
                this.chart.title = this.title;
                // タイトル再描画のためfullRenderを誘発させる
                this.chart.dirty = true;
                this.chart.render();
            }
        },

        // set('data', ...) 経由で呼ばれる
        _setDataAttr: function(data) {
            this._set('data', data);
            this.applyData();
            this.chart.render();
            this.legend.refresh();
            this._fitChart();
        },

        /**
         * 現在設定されているデータを反映する。
         */
        applyData: function() {
            console.log(this.data);
        }
    });
});