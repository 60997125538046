/**
 * 緊急情報一覧用モジュール
 * @module app/disasterInfoShare/DisasterInfoShareEmergencyNewsGrid
 */
 define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'dojo/dom-construct',
    'dojo/on',
    'app/util/DateFormatter'
    // 以下、変数として受け取らないモジュール
], function(module, declare, lang, locale, IdisGrid, helper, domConstruct, on, DateFormatter) {

    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        // ルート要素に付与されるCSS
        baseClass: '',

        /**
         * 各列の定義
         * @type {Object[]}
         */
         columns: [
            // 詳細
            // helper.buttonColumn('detail', '詳細', '詳細'),
            // レベル
            helper.column('notifyType', '通知種別', {
                sortable: false,
                formatter: function(value) {
                    return '<div class="message-type notify-type">' + value + '</div>';
                }
            }),
            // 発表日時
            {field: 'pubStartTimestamp', label: '発表日時',
                formatter: lang.hitch(this, function(item){
                    var res = DateFormatter.jpDateTime(new Date(item), true);
                    var now = DateFormatter.jpDateTime(new Date(), true);

                    if (res > now) {
                        // return DateFormatter.jpDateTime(new Date(item.replace(/-/g, '/')), true);
                        return res + ' (発表前)';
                    } else {
                        return res;
                    }
                })
            },
            // タイトル
            {field: 'title', label: 'タイトル',
                sortable: false,
                formatter: function(value, item) {
                    console.log(item);
                    return '<a href="javascript:void(0);" class="title-link" id="messageId--' + item.messageId + '">' + value + '</a>';
                }
            }
        ]
    });
});
