/**
 * テンプレート管理画面用モジュール。
 * @module app/notice/view/ConvoMailTempPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/ConvoMailTempPage.html',
    'idis/view/dialog/DialogChain',
    'idis/store/IdisRest',
    'idis/view/Loader',
    'idis/control/Router',
    'idis/view/page/_PageBase',
    // 以下、変数から参照されないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/form/Form',
    'idis/view/form/AclButton',
    './ConvoMailTempGrid',
    './ConvoMailTempRegisterDialog',
    './ConvoMailTempDetailDialog'
], function(module, declare, lang, template, DialogChain, IdisRest, Loader, Router, _PageBase) {
    /**
     * テンプレート管理画面。
     * @class ConvoMailTempPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/ConvoMailTempDialog~ConvoMailTempDialog# */ {
        // テンプレート文字列
        templateString: template,
        
        store: null,
        
        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--convocation',
        
        // テンプレートコード
        distributionTmplCd: null,

        /**
         * 変数の初期化。
         */
        constructor: function() {
            // データ格納用オブジェクト
             this.store = new IdisRest({
                 idProperty: 'distributionTmplCd',
                 target: '/api/convoMails/temps'
             });
            // ダイアログ連鎖を登録
            // 引数に与えたウィジェットのthis.ownを呼び出し、
            // ウィジェットが破棄された際にダイアログ連鎖が破棄されるようになる
            this.chain = DialogChain.get(this);
        },
        
        /**
         * DOMノードを生成するためのメソッド。
         */
        buildRendering: function() {
            this.inherited(arguments);
            // 自分が削除された時は関連ダイアログも削除する
            this.initRegisterDialog();
            this.initDetailDialog();
            this.initConvoMailTempGrid();
        },
        
        /**
         * 新規登録ダイアログのformが投稿された際の挙動を設定する。
         */
        initRegisterDialog: function() {
            // 新規登録ダイアログの最初の子要素が画面の情報
            var dialog = this.convoMailTempRegisterDialog;
            var page = dialog.getChildren()[0];
            
            // 新規登録ダイアログからのregisterイベントを取得
            page.on('register', lang.hitch(this, function(evt) {
                // 登録確認ダイアログの表示
                this.chain.confirmAdd(function(chain) {
                    // 登録処理完了まではローディング画面を表示
                    Loader.wait(this.store.add(evt.value)).then(function() {
                        // 登録処理成功時
                        // 新規登録ダイアログを閉じる
                        dialog.hide();
                        // 完了ダイアログの表示
                        chain.infoComplete();
                    }, function(error) {
                        // 登録処理失敗時
                        // エラーダイアログの表示
                        chain.infoError(error);
                    });
                });
            }));
        },
        
        /**
         * 詳細ダイアログのformが投稿された際の挙動を設定する。
         */
        initDetailDialog: function() {
            // 詳細ダイアログの最初の子要素が画面の情報
            var dialog = this.convoMailTempDetailDialog;
            var page = dialog.getChildren()[0];
            
            // 詳細ダイアログからのupdateイベントを取得
            page.on('update', lang.hitch(this, function(evt) {
                // ダイアログのform情報に当モジュールで保持しているテンプレートコードを追加
                evt.value.distributionTmplCd = this.distributionTmplCd;
                // 更新確認ダイアログの表示
                this.chain.confirmPut(function(chain) {
                    // 更新処理完了まではローディング画面を表示
                    Loader.wait(this.store.put(evt.value)).then(function() {
                        // 更新処理成功時
                        // 詳細ダイアログを閉じる
                        dialog.hide();
                        // 完了ダイアログの表示
                        chain.infoComplete();
                    }, function(error) {
                        // 更新処理失敗時
                        // エラーダイアログの表示
                        chain.infoError(error);
                    });
                });
            }));

            // 詳細ダイアログからのdeleteイベントを取得
            page.on('delete', lang.hitch(this, function() {
                // 削除確認ダイアログの表示
                this.chain.confirmDel(function(chain) {
                    // 削除処理完了まではローディング画面を表示
                    Loader.wait(this.store.remove(this.distributionTmplCd))
                    .then(function() {
                        // 削除処理成功時
                        // 詳細ダイアログを閉じる
                        dialog.hide();
                        // 完了ダイアログの表示
                        chain.infoComplete();
                    }, function(error) {
                        // 削除処理失敗時
                        // エラーダイアログの表示
                        chain.infoError(error);
                    });
                });
            }));
        },

        /**
         * 検索ボタンが押下された際の挙動。
         */
        onSubmit: function() {
            var value = this.form.get('value');
            this.updateGrid(value);
            return false;
        },
        
        /**
         * パンくずリストのボタンを押下したときの挙動。
         */
        onConvoMailTempLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('convocation/mail');
        },

        /**
         * テンプレート詳細ダイアログを表示する。
         */
        showConvoMailTempDetailDialog: function(item){
            console.log('[詳細]ボタンがクリックされました');
            var page = this.convoMailTempDetailDialog.getChildren()[0];
            // 画面上のフォームをリセット
            // （ダイアログ内画面のテンプレートHTMLでdata-dojo-attach-point="form"指定済み）
            page.form.reset();
            // テンプレートコードを保持
            this.distributionTmplCd = item.distributionTmplCd;
            // ダイアログを表示
            this.convoMailTempDetailDialog.show();
            
            page.form.set('value',item);
        },

        /**
         * 新規登録ダイアログを表示する。
         */
        showConvoMailTempRegisterDialog: function(item){
            console.log('[新規登録]ボタンがクリックされました');
            var page = this.convoMailTempRegisterDialog.getChildren()[0];
            // 画面上のフォームをリセット
            // （ダイアログ内画面のテンプレートHTMLでdata-dojo-attach-point="form"指定済み）
            page.form.reset();
            // ダイアログを表示
            this.convoMailTempRegisterDialog.show();
            
            page.form.set('value',item);
        },

        /**
         * gridの初期化。
         */
        initConvoMailTempGrid: function() {
            // テンプレート情報をgridにセット
            this.convoMailTempGrid.set('collection', this.store);
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.convoMailTempGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                // helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
                console.log('convoMailtTempGridButton');
                this.showConvoMailTempDetailDialog(evt.item);
            }));
        },

        /**
         * @param value フォームから取得した情報
         * 入力された条件で検索して一覧を更新する
         */
        updateGrid: function(value) {
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();
            // タイトル(前方一致)
            if (value.title) {
                filter = filter.eq('title', value.title);
            }                
            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);
            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.convoMailTempGrid.set('collection', collection);
        },
        onConvocationMailAdminPageLinkClick : function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('convocation/mail');
        }  
    });
});

