/**
 * 災害選択用入力パーツ
 * @module app/view/form/DistributionGroupSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest',
    'idis/view/form/TreeSelector'
], function(module, declare, CacheStoreModel, CacheTreeRest, TreeSelector) {
    /**
     * 部局・課選択用パーツ。
     * @class DistributionGroupSelector
     * @extends module:idis/view/form/TreeSelector~TreeSelector
     */
    return declare(module.id.replace(/\//g, '.'), TreeSelector,
        /** @lends module:app/view/tree/DistributionGroupSelector~DistributionGroupSelector# */ {
        // 選択ダイアログのタイトル
        title: '配信対象グループ選択',

        // ツリー・モデル
        model: null,

        constructor: function() {
            // 設置されるたびにモデルを新規生成する
            this.model = new CacheStoreModel({
                store: new CacheTreeRest({
                    target: '/api/safety/groups/groups4tree'
                })
            });
        }
    });
});
