/**
 * 再配信確認ダイアログ画面用モジュール。
 * @module app/sending/SendingReConfirmDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/text!./templates/SpecteeMobileDateToDialog.html',
    'idis/view/page/_PageBase',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/Button'
], function(module, declare, template, WidgetBase) {
    
    /**
     * 配信確認ダイアログ
     * @class SendingConfirmDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), WidgetBase,
        /** @lends app/view/Header~Header# */ {

        // テンプレート文字列
        templateString: template,

        /**
         * コンストラクタ
         */
        constructor: function() {
            this.inherited(arguments);
        },

        /**
         * DOM生成
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * 画面生成完了
         */
        startup: function() {
            this.inherited(arguments);
        },

        /**
         * フォームに値をセットする。
         */
        setValue: function (arg) {
            this.dateTo.set('value', arg);
            this.timeTo.set('value', arg);
        },

        /**
         * 「設定する」ボタンが押された時の挙動。
         */
        onSubmit: function () {
            try {
                // 送信パラメータを設定
                var value = this.form.get('value');
                this.emit('dateto', { value: value });
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * 「クリア」ボタンが押された時の挙動。
         */
        onClear: function () {
            this.dateTo.set('value', null);
            this.timeTo.set('value', null);
        },

        /**
         * 「現在時刻」ボタンが押された時の挙動。
         */
        onCurrentTime: function () {
            var currentTime = new Date();
            this.dateTo.set('value', currentTime);
            this.timeTo.set('value', currentTime);
        }
    });
});
