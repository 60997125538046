/**
 * Lアラート避難情報配信ダイアログ。
 * @module app/provide/ProvideEvacOrderRegisterDialog
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'dojo/dom-style',
    'dojo/Deferred',
    'dojo/text!./templates/ProvideEvacOrderRegisterDialog.html',
    './_ProvideDialogBase',
    'app/model/DisasterInfo',
    'dstore/Memory',
    'idis/util/DateUtils',
    'idis/model/UserInfo',
    'idis/view/dialog/InfoDialog',
    'app/evacorder/EvacOrderType',
    'app/evacorder/EvacOrderUtil',
    'app/evacorder/DistributionType',
    './consts/DeliveredStatus',
    './ProvideUtil',
    // 以下、変数から参照されないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/form/Form',
    'dijit/form/ValidationTextBox',
    'idis/view/form/WordCountTextarea',
    'idis/view/form/DateTimeInput',
    'idis/view/form/Button',
    'app/view/form/ManageRegionMunicipalitySelector',
    'app/view/form/CustomizableMunicipalitySelector',
    'app/view/form/DisasterSelector',
    './form/DistributionTypeInput',
    './ProvideShelterRegisterDialog',
    './ProvideUrgentMailRegisterDialog',
    './ProvideEvacOrderGrid'
], function(module, array, declare, lang, locale, domStyle, Deferred, template, _ProvideDialogBase,
    DisasterInfo, Memory, DateUtils, UserInfo, InfoDialog,
    EvacOrderType, EvacOrderUtil, DistributionType, DeliveredStatus, ProvideUtil) {
    /**
     * Lアラート避難情報登録ダイアログ画面。
     * @class ProvideEvacOrderRegisterDialog
     * @extends module:app/provide/_ProvideDialogBase~_ProvideDialogBase
     */
    return declare(module.id.replace(/\//g, '.'), _ProvideDialogBase,
        /** @lends module:app/provide/_ProvideDialogBase~_ProvideDialogBase# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--provide',

        // ダイアログで保持する市町村コード
        _municipalityCd: null,

        // 災害ID
        _disasterId: null,

        /**
         * 引数
         */
        // 引数
        _args: null,
        // 発令かどうか（true:発令、false:解除）
        _isIssue: false,
        // 避難情報
        _evacOrderArgs: null,

        // 訂正・取消理由が必須かどうか
        _errata: false,

        _shelterInfo: null,

        _evacOrderInfo: null,

        // 避難理由の文字列表現
        _evacuateReason: null,

        // 緊急速報メール利用有無判別フラグ
        _urgentMailFlg: false,

        // Twitter利用有無判別フラグ
        _twitterFlg: false,

        // ベースクラスのコンストラクタでStoreを生成。
        constructor: function() {
            // 管理対象市町リストの一つ目を設定する
            this._municipalityCd = UserInfo.getMunicipalityCds()[0];
            this._disasterId =  DisasterInfo.getDisasterId();
            this._shelterInfo = {};
            this._evacOrderInfo = {};
        },

        buildRendering: function() {
            this.inherited(arguments);
            this.own(this.shelterRegisterDialog);
            // this.own(this.urgentMailRegisterDialog);
        },

        /**
         * ベースクラスをオーバーライド
         * 画面から渡された値で初期表示設定を行う。
         * @param {{
         *  evacOrderArgs: {
         *      correctCancelReason: string,
         *      distributionType: string,
         *      evacGuidunce: string,
         *      evacHouseholdNum: number,
         *      evacOrderType: string,
         *      evacTimestamp: number,
         *      evacuateReason: string,
         *      evaqOrderData: {*},
         *      evaqueeNum: number,
         *      isFix: boolean // 訂正・取消であるかどうか
         *      issueReasonType: string,
         *      lalertSendHistId: number,
         *      lastDistributionType: string,
         *      municipalityCd: string,
         *      note: string,
         *      sendCategory: '01', // 避難勧告・指示
         *      districtList: {*},
         *      evacOrderArgs: {
         *          municipalityCd: string,
         *          evacTimestamp: number,
         *          evacOrderType: string,
         *          issueReasonType: string,
         *          evacuateReason: string,
         *          evacGuidunce: string,
         *      },
         *      shelterArgs: {evaqueeData: [{
         *          evacShelterName: string,
         *          designatedEvacShFlg: string,
         *          edesignatedEvacShFlg: string,
         *          secondaryEvacShFlg: string,
         *          plannedNotSiteEvacShFlg: string,
         *          welfareEvacShFlg: string,
         *          wardsEvacShFlg: string,
         *          tamaAndLslandsEvacShFlg: string,
         *          compmentPrelinEvacShFlg: string,
         *          edesignatedPlaceEvacShFl: string,
         *      }]},
         *      urgentMailArgs: {
         *          districtList: {districtList: []},
         *      },
         *  },
         *  isIssue: boolean    // 発令かどうか（false = 解除）
         * }} args
         * @returns
         */
        initDialog: function(args) {
            var deferred = new Deferred();
            // 次のダイアログ(緊急速報メール等)に渡す値を保持する
            this._args = args;
            this._isIssue = args.isIssue;
            this._evacOrderArgs = args.evacOrderArgs;
            this._errata = this._evacOrderArgs.isFix;

            /* ヘッダー */
            // 配信種別をセットする。
            this.sendCategoryNode.innerHTML = this.getCategoryName(this._evacOrderArgs.sendCategory);
            // 更新種別 '01':新規, '02':更新・訂正, '03':取消
            //   明確に指定されている場合はその値、無ければ前回の履歴で判定
            //   「前回配信履歴がある＆前回のdistributionTypeが取消(03)以外」＝更新(02)、それ以外＝新規(01)
            var distType = this._evacOrderArgs.distributionType ||
                            ( (this._evacOrderArgs.lalertSendHistId &&
                                this._evacOrderArgs.lastDistributionType !== DistributionType.CANCEL ) ?
                                    DistributionType.CORRECT : DistributionType.NEW);
            this.distributionTypeInput.set('value', distType);
            // 発行元に市町村コードをセットする。
            this.municipalityCd._setValueAttr(this._evacOrderArgs.municipalityCd);

            /* Lアラート以外の外部連携情報 */
            // 公開先エリア（緊急速報メール内容含む）セット
            this.setPublishArea().then(function() {
                return deferred.resolve();
            });

            /* Lアラート */
            // 災害名をセットする。
            this.disasterNameNode.set('value',this._disasterId);
            // 避難情報発令日時をセットする。
            this.sendTimestamp = new Date(this._evacOrderArgs.evacTimestamp);
            this.evacTimestampNode.innerHTML = DateUtils.format(this.sendTimestamp);
            // 発令理由をセットする。
            this.evacuateReasonNode.innerHTML = this._evacuateReason = this._evacOrderArgs.evacuateReason;
            // 対象世帯数をセットする。
            this.evacHouseholdNum.set('value', this._evacOrderArgs.evacHouseholdNum);
            // 対象人数をセットする。
            this.evaqueeNum.set('value', this._evacOrderArgs.evaqueeNum);
            // 更新種別が '03':取消の場合 には避難人数、世帯数を変更させない。
            if (distType === DistributionType.CANCEL) {
                this.evacHouseholdNum.set('disabled', 'disabled');
                this.evaqueeNum.set('disabled', 'disabled');
            }
            // 避難行動指針（入力がなければ項目ごと非表示）
            if(this._evacOrderArgs.evacGuidunce !== '') {
                this.evacuateGuidelineNode.innerHTML = this._evacOrderArgs.evacGuidunce;
                domStyle.set(this.evacuateGuidelineArea, 'display', '');
            }
            // 補足情報
            this.complementaryInfo.set('value', this._evacOrderArgs.note);
            // 訂正・取消理由欄(訂正/取消ボタン押下時のみ表示 初期状態ではnone)
            domStyle.set(this.errataDescriptionItemNode, 'display', 'none');
            if (this._errata) {
                this.errataDescription.set('value', this._evacOrderArgs.correctCancelReason);
                domStyle.set(this.errataDescriptionItemNode, 'display', '');
            }

            // 対象地区に関する情報はグリッドで表示する。
            this.initGrid();

            // 初期表示状態のボタン表示状態を変更
            this.initButtons();
            return deferred.promise;
        },

        /**
         * 公開先エリア(Lアラート以外の外部連携情報)セット
         */
        setPublishArea: function(){
            var deferred = new Deferred();
            // 初期化（全て非表示）
            domStyle.set(this.urgentMailFlgArea, 'display', 'none');
            domStyle.set(this.urgentMailArea.domNode, 'display', 'none');
            domStyle.set(this.hideBodyTextAreaLabel4Lalert, 'display', 'none');
            this.urgentMailFlg.set('checked', false);
            domStyle.set(this.snsArea, 'display', 'none');
            this.twitterFlg.set('checked', false);
            domStyle.set(this.publishArea, 'display', 'none');
            domStyle.set(this.publishAreaHideMessage, 'display', '');

            // 訂正・取消時はLアラート以外を配信しないので、処理終了
            if (this._errata) {
                this.borderContainer.resize();
                return deferred.resolve();
            }

            // 配信設定を取得する
            this.getSendingSetting(this._evacOrderArgs.municipalityCd).then(lang.hitch(this, function() {
                // 外部連携対象があるか
                var hazExtDistTarget = false;
                /**
                 * 緊急速報メール（デフォルトチェックなし）
                 *   配信するのは、以下2条件の全てを満たすときのみ。
                 *    １、緊急速報メールの利用市町であること（ this._urgentMailFlg: M_SENDING_SETTING.MAIL_FLG="1" ）
                 *    ２、発令画面から、解除ではない情報を発令するとき
                 */
                if (this._urgentMailFlg && this._isIssue){
                    hazExtDistTarget = true;
                    domStyle.set(this.urgentMailFlgArea, 'display', '');
                    // 緊急速報メールの文面を生成・セットする（ただし、デフォルトチェックなしのため非表示）
                    var urgentMailArgs = this.createUrgentMailInfo();
                    this.subjectInput.set('value', this.createUrgentMailSubject(urgentMailArgs));
                    this.urgentMailBodyText._setValueAttr(this.createUrgentMailBodyText(urgentMailArgs));
                }
                /**
                 * Twitter
                 *   配信するのは、以下2条件の全てを満たすときのみ。
                 *    １、Twitterの利用市町であること（ this._twitterFlg: M_SENDING_SETTING.TWITTER_FLG="1" ）
                 */
                if (this._twitterFlg){  // 解除も連携
                    hazExtDistTarget = true;
                    domStyle.set(this.snsArea, 'display', '');
                    this.twitterFlg.set('checked', true);
                }

                // 外部連携ありの場合、公開先欄を表示して、外部連携なしメッセージを非表示
                if (hazExtDistTarget) {
                    domStyle.set(this.publishArea, 'display', '');
                    domStyle.set(this.publishAreaHideMessage, 'display', 'none');
                }
                this.borderContainer.resize();
                deferred.resolve();
            }), lang.hitch(this, function(error){
                console.error(error);
                this.chain.infoError(error);
                deferred.reject(error);
            }));
            return deferred.promise;
        },

        /**
         * 緊急速報メールチェックボックスクリック時、メール内容の表示切り替え
         */
        togglePublishFlg: function(){
            var sendMailFlg = this.urgentMailFlg.getValue() === 'on';
            // 緊急速報メールを送る場合は、緊急速報メール用のペインを表示
            domStyle.set(this.urgentMailArea.domNode, 'display', sendMailFlg ? '' : 'none');
            domStyle.set(this.hideBodyTextAreaLabel4Lalert, 'display', sendMailFlg ? '' : 'none');
            this.borderContainer.resize();
        },

        /**
         * Lアラート欄を拡大する
         */
        hideBodyTextArea: function(){
            // domStyle.set(this.hideBodyTextAreaLabel, 'display', 'none');
            domStyle.set(this.hideBodyTextAreaLabel4Lalert, 'display', 'none');
            domStyle.set(this.showBodyTextAreaLabel, 'display', '');
            // domStyle.set(this.showBodyTextAreaLabel4Lalert, 'display', '');
            domStyle.set(this.mailBodyTextArea, 'display', 'none');
            this.borderContainer.resize();
        },

        /**
         * 配信本文入力欄を表示する
         */
        showBodyTextArea: function(){
            // domStyle.set(this.hideBodyTextAreaLabel, 'display', '');
            domStyle.set(this.hideBodyTextAreaLabel4Lalert, 'display', '');
            domStyle.set(this.showBodyTextAreaLabel, 'display', 'none');
            // domStyle.set(this.showBodyTextAreaLabel4Lalert, 'display', 'none');
            domStyle.set(this.mailBodyTextArea, 'display', '');
            this.borderContainer.resize();
        },


        // 避難対象地区グリッドを初期表示する。
        initGrid: function() {
            this.evacOrderGrid.set('collection', new Memory({
                // グリッド格納時にIDが付与されてしまうので、元データを変更させないようコピーを格納する
                data: array.map(this._evacOrderArgs.evaqOrderData, function(item, i) {
                    return lang.mixin({id: i}, item);
                })
            }));
        },

        /**
         * 初期表示状態のボタン表示状態を変更
         */
        initButtons: function() {
            if(!this._args.shelterArgs){
                // 避難所情報がない場合は、ただの配信ボタンのみを表示
                domStyle.set(this.evacShelRegisterButton.domNode, 'display', 'none');
                domStyle.set(this.showShelterButton.domNode, 'display', 'none');
                domStyle.set(this.evacRegisterButton.domNode, 'display', '');
            } else {
                // 避難所情報がある場合は、避難所を確認するボタンのみを表示
                domStyle.set(this.evacShelRegisterButton.domNode, 'display', 'none');
                domStyle.set(this.showShelterButton.domNode, 'display', '');
                domStyle.set(this.evacRegisterButton.domNode, 'display', 'none');
            }
        },

        resize: function(changeSize, resultSize) {
            this.borderContainer.resize(changeSize, resultSize);
        },

        // 画面の「登録」ボタンを受け取る
        sendAndRegister: function() {

            // エラーチェック
            if(!this.validateForm()){
                return false;
            }

            var subject = this._isIssue ?
                EvacOrderType.get(this._evacOrderArgs.evacOrderType).simpleLabel + '発令情報' :
                '避難指示等解除情報';

            var isIssueIncluded = false;
            array.forEach(this._evacOrderArgs.evaqOrderData, function(area){
                if(area.evacuateIssue === '発令'){
                    isIssueIncluded = true;
                }
            });


            // formタグを使うとダイアログ上でグリッドのカラム名が表示されないため使用しない
            var lalertForm = {
                disasterId           : this._disasterId,
                bodyText             : ' ',
                municipalityCd       : this.municipalityCd.get('value'),
                complementaryInfo    : this.complementaryInfo.value,
                distributionType     : this.distributionTypeInput.get('value'),
                errataDescription    : this._evacOrderArgs.isFix ? this.errataDescription.get('value') : null,
                sendCategory         : this.EVAC_ORDER_CONTENT_TYPE,
                issueReasonType      : this._evacOrderArgs.issueReasonType,
                sendTimestamp        : this.sendTimestamp,
                subject              : subject,
                evacuateReason       : this._evacuateReason,
                evacuateGuideline    : this.evacuateGuidelineNode.innerHTML,
                abstEvacHouseholdNum : isIssueIncluded ? this.evacHouseholdNum.get('value') : null,
                abstEvaqueeNum       : isIssueIncluded ? this.evaqueeNum.get('value') : null,
                evacOrderAreaList    : this._evacOrderArgs.evaqOrderData,
                lalertSendHistId     : this._evacOrderArgs.lalertSendHistId
            };
            this._evacOrderInfo.lalertForm = lalertForm;

            var urgentMailForm;
            // if((this.prefMailFlg.getValue() === 'on') ||
            //     (this.twitterFlg.getValue() === 'on') ||
            //     (this.urgentMailFlg.getValue() === 'on' && this._urgentMailFlg)){
            if(this.urgentMailFlg.getValue() === 'on' && this._urgentMailFlg){
                // 本文がある場合は、文面をチェックする
                if(!this.validateUrgentMailBodyText()){
                    return false;
                }
            }

                this._evacOrderInfo.twitterFlg = this.twitterFlg.getValue() === 'on';
            // if(this.twitterFlg.getValue() === 'on'){
            //     this._evacOrderInfo.twitterFlg = true;
            //     this._evacOrderInfo.twitterMessage = this.urgentMailBodyText.get('value');
            // } else {
            //     this._evacOrderInfo.twitterFlg = false;
            // }

            // if(this.prefMailFlg.getValue() === 'on'){
            //     this._evacOrderInfo.prefMailFlg = true;
            //     this._evacOrderInfo.prefMailBodyText = this.urgentMailBodyText.get('value');
            //     if(!this.validateUrgentMailSubject()){
            //         return false;
            //     }
            //     this._evacOrderInfo.prefMailSubject = this.subjectInput.get('value');
            // } else {
                this._evacOrderInfo.prefMailFlg = false;
            // }

            // 緊急速報メールを送る場合、緊急速報メールのフォームを作成する
            if(this._isIssue && this.urgentMailFlg.getValue() === 'on'){
                if(!this.validateUrgentMailSubject()){
                    return false;
                }
                urgentMailForm = {};
                // 災害IDをformにセットする。
                urgentMailForm.disasterId = DisasterInfo.getDisasterId();

                // 対象市町をformにセットする。
                var target = {'targetMunicipalityCd' : this.municipalityCd.get('value')};
                var tLalertSendTargetList = [target];
                urgentMailForm.tLalertSendTargetList = tLalertSendTargetList;

                // 配信情報種別をformにセットする。
                urgentMailForm.sendCategory = this.URGENT_MAIL_CONTENT_TYPE;
                // 緊急速報メールは、distributionTypeが常に01。
                urgentMailForm.distributionType = '01';

                urgentMailForm.municipalityCd = this.municipalityCd.get('value');
                urgentMailForm.subject = this.subjectInput.get('value');
                urgentMailForm.bodyText = this.urgentMailBodyText.get('value');

                // 現在の時刻を配信日時としてformにセットする。
                urgentMailForm.sendTimestamp = new Date();

            }

            this.emit('sendandregister', {
                evacOrderInfo: this._evacOrderInfo,
                shelterInfo: this._shelterInfo,
                urgentMailInfo: urgentMailForm
            });
            return false;
        },

        validateForm: function(){

            if(!this.evaqueeNum.validate() || !this.evacHouseholdNum.validate()){
                return this._showErrDialog('対象世帯数・対象人数は半角数字で入力してください。');
            }

            if(!this.complementaryInfo.validate()){
                return this._showErrDialog('補足情報について、' + this.complementaryInfo.invalidMessage);
            }

            if(this._errata){
                if (this.errataDescription.get('value').trim() === '') {
                    return this._showErrDialog('訂正・取消理由が入力されていません。');
                } else if(!this.errataDescription.validate()){
                    return this._showErrDialog('訂正・取消理由について、' + this.errataDescription.invalidMessage);
                }
            }

            return true;
        },

        /**
         * 配信情報(開設避難所情報)ダイアログを表示する。
         */
        showShelterDialog: function() {
            var args = this._args;

            // ダイアログの最初の子要素が登録画面
            var dialog = this.shelterRegisterDialog.getChildren()[0];

            // 登録画面のsendイベントを受け取る
            dialog.on('send', lang.hitch(this, function(evt) {
                // 避難所ダイアログで整理した情報を保管しておく
                this._shelterInfo.lalertForm = evt.value.lalertForm;
                this._shelterInfo.twitterFlg = evt.value.twitterFlg;
                this._shelterInfo.yahooFlg = evt.value.yahooFlg;
                this.sendAndRegister();
            }));

            // 登録画面のcheckEvacOrderイベントを受け取る
            dialog.on('hideshelter', lang.hitch(this, function(evt) {
                // 避難所ダイアログで整理した情報を保管しておく
                this._shelterInfo = evt.value;
                // 一度避難所ダイアログを確認したので、、このまま配信できる状態にする
                domStyle.set(this.evacShelRegisterButton.domNode, 'display', '');
            }));

            // 登録画面のcancelイベントを受け取る
            dialog.on('cancel', lang.hitch(this, function() {
                this._closeDialog();
            }));

            // ダイアログの画面を初期化する。
            dialog.initDialog(args, false, false, false).then(lang.hitch(this, function(){

                // ダイアログを破棄できないように設定する。
                this.shelterRegisterDialog.set('closable', false);
                // ダイアログを表示する。
                this.shelterRegisterDialog.show();
            }));

        },

        closeDialog: function(){
            this._closeDialog();
            this.shelterRegisterDialog.getChildren()[0].closeDialog();
        },

        /////////////以下はLアラート以外の配信先用のロジック////////////////

        /**
         * 緊急ニュース件名
         */
        createUrgentMailSubject: function(info) {
            return info.evacOrderTypeLabelWithLevel;
        },

        /**
         * 緊急ニュース本文
         * @returns
         */
        createUrgentMailBodyText: function() {
            /**
             *  画面の項目を以下のフォーマットに当てはめます。
             * ただし、地区数により最大文字数(200文字)を超過する可能性があるため、自動生成した文面は画面から編集可能とします。
             *  ※市町システムからの情報連携時には自動連携しません。
             *      [発令内容] 
             *      [発令日時] [市町] に、[発令内容] を発令しました。
             *
             *      発令地区は以下の通りです。
             *      [地区名]
             *
             *      （例）
             *      【警戒レベル4】避難指示、【警戒レベル3】高齢者等避難
             *      2022/09/06 01:27　長崎市に、避難指示、高齢者等避難を発令しました。
             *
             *      発令地区は以下の通りです。
             *      避難指示：小ケ倉、仁田
             *      高齢者等避難：桜町、三原
             */
            var data = this.createUrgentMailInfo();
            var str = '';
            // var districtList = data.districtList; //全域判定のない、生の地区一覧
            var districtListWholeArea = data.districtListWholeArea; //全域判定された地区名

            // [発令内容]
            str += data.evacOrderTypeLabelWithLevel + '\n';
            // [発令日時]
            str += data.evacTimestamp + '　';
            // [市町] に、
            str += data.municipalityName + 'に、';
            // [発令内容] を発令しました。
            str += data.evacOrderTypeLabel + 'を発令しました。\n\n';
            // 発令地区は以下の通りです。
            str += '発令地区は以下の通りです。\n';
            // [地区名]
            if (data.isBulk) {
                array.forEach(Object.keys(data.evacOrderTypeMap), function(evacOrderTypeLabel) {
                    str += evacOrderTypeLabel + '：' + data.evacOrderTypeMap[evacOrderTypeLabel].join('、') + '\n';
                });
            } else {
                var areaNames = array.map(districtListWholeArea, function (area) {
                    return area.districtFreeName;
                });
                str += areaNames.join('、');
            }

            // 避難所名のリストが0件でない場合は本文を生成する。
            if(data.shelterList.length !==0) {
                str += '\n\n';
                str += '開設された避難所は以下の通りです。\n';
                // 避難所名
                var shelterNames = array.map(data.shelterList, function (shelter) {
                    return shelter.shelterName;
                });
                str += shelterNames.join('、');
            }

            return str;
        },

        createUrgentMailInfo: function() {
            var obj = this._args;
            // 発令内容ごとのマップを作成
            var evacOrderTypeMap = {};
            // 発令内容（レベル付き）リスト取得
            var labelWithLevelList = [];
            array.forEach(array.filter(obj.evacOrderArgs.evaqOrderData, function (evacOrderArea) {
                // 未配信、発令に絞る
                return evacOrderArea.deliveredStatus === DeliveredStatus.UN_DELIVERED &&
                    evacOrderArea.evacuateIssue === '発令';
            }), function(data) {
                var evacOrderType = array.filter(EvacOrderType.data, function(item) {
                    return item.label4Lalert === data.evacuateSort;
                })[0];
                var items = evacOrderTypeMap[evacOrderType.label4Lalert] || [];
                items.push(data.areaName);
                evacOrderTypeMap[evacOrderType.label4Lalert] = items;
                labelWithLevelList.push(evacOrderType.labelWithLevel);
            });
            // 発令内容（レベル付き）
            var evacOrderTypeLabelWithLevel =
                // 直接配信の場合、発令した内容のみ取得
                !obj.isBulk ? EvacOrderType.get(obj.evacOrderArgs.evacOrderType).labelWithLevel :
                // 一括配信の場合、配信する発令内容のリストを重複削除し、結合した文字列を取得
                array.filter(labelWithLevelList, function(l, i) { return labelWithLevelList.indexOf(l) === i; }).join('、');
            // 発令内容（レベルなし）
            var evacOrderTypeLabel =
                // 直接配信の場合、発令した内容のみ取得
                !obj.isBulk ? EvacOrderType.get(obj.evacOrderArgs.evacOrderType).label4Lalert :
                // 一括配信の場合、配信する発令内容のリストを結合した文字列を取得
                Object.keys(evacOrderTypeMap).join('、');

            // shelterListは、開設された避難所名と避難所区分をプロパティに持つオブジェクトのリスト
            var shelterList = [];
            if(obj.shelterArgs) {
                obj.shelterArgs.evaqueeData.forEach(function(evaqueeData){
                    var shelter = {
                        shelterName: evaqueeData.evacShelterName,
                        designatedEvacShFlg: evaqueeData.designatedEvacShFlg,
                        edesignatedEvacShFlg: evaqueeData.edesignatedEvacShFlg,
                        secondaryEvacShFlg: evaqueeData.secondaryEvacShFlg,
                        plannedNotSiteEvacShFlg: evaqueeData.plannedNotSiteEvacShFlg,
                        welfareEvacShFlg: evaqueeData.welfareEvacShFlg,
                        wardsEvacShFlg: evaqueeData.wardsEvacShFlg,
                        tamaAndLslandsEvacShFlg: evaqueeData.tamaAndLslandsEvacShFlg,
                        compmentPrelinEvacShFlg: evaqueeData.compmentPrelinEvacShFlg,
                        edesignatedPlaceEvacShFlg: evaqueeData.edesignatedPlaceEvacShFlg
                    };
                    shelterList.push(shelter);
                });
            }

            var args = {
                // 配信対象発令内容と地区のマッピング
                evacOrderTypeMap: evacOrderTypeMap,
                // 発令内容（レベル付き）
                evacOrderTypeLabelWithLevel: evacOrderTypeLabelWithLevel,
                // 発令内容（レベルなし）
                evacOrderTypeLabel: evacOrderTypeLabel,
                // 最新発令日時
                evacTimestamp: locale.format(obj.evacOrderArgs.evacTimestamp, {
                    selector: 'date',
                    datePattern: 'yyyy/MM/dd HH:mm'
                }),
                // 最新発令地区一覧（全域判定済）
                districtListWholeArea : obj.isBulk || obj.urgentMailArgs.districtList.districtList,
                // 最新発令地区一覧
                districtList : obj.districtList,
                // 市町名
                municipalityName: obj.urgentMailArgs.municipalityName,
                // 避難所情報一覧
                shelterList: shelterList,
                // 一括配信かどうか
                isBulk: obj.isBulk
            };

            return args;
        },

        // 配信内容にURL、電話番号、メールアドレスが含まれていないかをチェック
        // bodyTextValidate: function(text) {
        //     //URL正規表現パターン
        //     var chkURL = /(https?|ftp)(:\/\/[-_.!~*\'()a-zA-Z0-9;\/?:\@&=+\$,%#]+)/;
        //     // メールアドレス正規表現パターン({1}@{2} のような形式)
        //     var chkMail = /([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+/;
        //     // 電話番号正規表現パターン1(最初１−５桁)
        //     var chkTel1 = /\d{1,5}-\d{1,4}-\d{4}/;
        //     // 電話番号正規表現パターン2(最初２桁)
        //     var chkTel2 = /0\d-\d{4}-\d{4}/;
        //     // 電話番号正規表現パターン3(携帯電話)
        //     var chkTel3 = /(050|070|080|090)-\d{4}-\d{4}/;
        //     // 電話番号正規表現パターン4(フリーダイヤル)
        //     var chkTel4 = /0120-\d{3}-\d{3}/;
        //
        //     // URLが含まれているかチェック
        //     if (text.match(chkURL)) {
        //         console.debug('配信内容にURLが含まれています');
        //         return false;
        //     }
        //     // メールアドレスが含まれているかチェック
        //     else if (text.match(chkMail)) {
        //         console.debug('配信内容にメールアドレスが含まれています');
        //         return false;
        //     }
        //     // 電話番号が含まれているかチェック(4種類)
        //     else if (text.match(chkTel1) ||
        //         text.match(chkTel2) ||
        //         text.match(chkTel3) ||
        //         text.match(chkTel4)) {
        //         console.debug('配信内容に電話番号が含まれています');
        //         return false;
        //     } else {
        //         return true;
        //     }
        // },

        // 文面が空白のみで作られているかどうかチェックする。
        isTextFilled: function(text) {
            if (!text)  {
                return false;
            }

            text = text.replace(/\s/g, '');
            if (text.length > 0) {
                return true;
            } else {
                return false;
            }
        },
        // 文字数をカウントする。ただし改行は二文字とカウントする。
        countText: function(text) {
            text = text.replace(/\n/g, '\n\n');
            return text.length;
        },

        // createEvacOrderTwitterBody: function(data){
        //     console.log(data);
        // },

        onBodyTextChange: function(){
            setTimeout(lang.hitch(this, this.countBodyText), 10);
        },

        countBodyText: function(){
            var bodyText = this.urgentMailBodyText.get('value');
            var textLength = this.countText(bodyText);
            this.textLength.innerHTML = textLength;
        },

        validateUrgentMailSubject: function(){
            if (!this.isTextFilled(this.subjectInput.get('value'))) {
                return this._showErrDialog('タイトルが入力されていません');
            }
            if (!ProvideUtil.urgentMailValidate(this.subjectInput.get('value'))) {
                return this._showErrDialog('タイトルに電話番号・URL・メールアドレスを含むことはできません');
            }
            if (this.countText(this.subjectInput.get('value')) > 15) {
                return this._showErrDialog('タイトルは15文字以内で入力してください。(現在' +
                    this.countText(this.subjectInput.get('value')) + '文字)');
            }
            return true;
        },

        validateUrgentMailBodyText: function(){
            if (!this.isTextFilled(this.urgentMailBodyText.get('value'))) {
                return this._showErrDialog('本文が入力されていません');
            }
            if (!ProvideUtil.urgentMailValidate(this.urgentMailBodyText.get('value'))) {
                return this._showErrDialog('配信内容に電話番号・URL・メールアドレスを含むことはできません');
            }
            if (this.countText(this.urgentMailBodyText.get('value')) > 200) {
                return this._showErrDialog('本文は200文字以内で入力してください。(現在' +
                    this.countText(this.urgentMailBodyText.get('value')) + '文字)');
            }
            return true;
        },

        _showErrDialog: function(msg) {
            if (!this.infoDialog) {
                this.infoDialog = new InfoDialog({
                    title : 'エラー',
                    content : msg
                });
            }
            this.infoDialog.show();
            this.infoDialog = null;
            return false;
        }
    });
});
