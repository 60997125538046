/**
* 定時集計 登録/詳細 画面用モジュール。
* @module app/scheduleReport/ScheduledReportPage
 */
define([
	'module',
	'dojo/_base/declare',
	'dojo/_base/lang',
	'dojo/dom-style',
	'dojo/json',
	'dojo/date/locale',
	'dojo/text!./templates/ScheduledReportPage.html',
	'idis/view/page/_PageBase',
	'idis/control/Locator',
	'idis/control/Router',
	'idis/service/Requester',
	'idis/view/Loader',
	'idis/store/IdisRest',
	'idis/view/dialog/DialogChain',
	'idis/view/dialog/InfoDialog',
	'app/config',
	'app/model/DisasterInfo',
	// 以下、変数から参照されないモジュール
	'dijit/form/Form',
	'dijit/form/Select',
	'dijit/form/Textarea',
	'idis/view/form/WordCountTextarea',
	'idis/view/form/Button',
	'idis/view/form/RadioGroup',
	'idis/view/form/DateTimeInput'
], function (module, declare, lang, domStyle, json, locale, template, _PageBase,
	Locator, Router, Requester, Loader, IdisRest, DialogChain, InfoDialog, config, DisasterInfo) {
	/**
	 * 定時集計 登録/詳細 画面実装。
	 * @class ScheduledReportPage
	 * @extends module:idis/view/page/_PageBase~_PageBase
	 */
	return declare(module.id.replace(/\//g, '.'), _PageBase, {
		// テンプレート文字列
		templateString: template,
		// 定時状況報告ID
		_scheduledReportId: null,
		// 集計時刻
		_aggrTimestamp: 0,

		// 削除用オブジェクト
		deleteStore: null,

		constructor: function () {
			// 災害IDをDisasterInfoから取得
			this._disasterId = DisasterInfo.getDisasterId();

			this.chain = DialogChain.get(this);

			// URLから定時状況IDを取得
			if ('scheduledReportId' in Locator.getQuery()) {
				this._scheduledReportId = Locator.getQuery().scheduledReportId;
			}
			// データ削除用オブジェクト
			this.deleteStore = new IdisRest({
				target: '/api/scheduledReport/delete'
			});

		},

		/**
		 * DOM生成
		 */
		// DOMノードを生成するためのメソッド
		buildRendering: function () {
			this.inherited(arguments);
			// タイトル設定
			this.initTitleStyle();
			this.initAggrTimeStyle();
			this.initBtnSetStyle();
			this.initNoteStyle();

			// 定時集計IDがある場合、データを初期表示する
			if (this._scheduledReportId) {
				this.initData();
			}
		},

		/**
		 * 画面生成完了
		 */
		startup: function () {
			this.inherited(arguments);

		},

		/**
		 * タイトル表示制御
		 */
		initTitleStyle: function () {
			// 登録を非表示
			domStyle.set(this.titleRegist, 'display', 'none');
			// 詳細を非表示
			domStyle.set(this.titleDetail, 'display', 'none');

			// URL が登録の場合
			if (Locator.getQuery().p.indexOf('regist') !== -1) {
				// 登録を表示
				domStyle.set(this.titleRegist, 'display', '');
			}

			// URL が詳細の場合
			if (Locator.getQuery().p.indexOf('detail') !== -1) {
				// 詳細を表示
				domStyle.set(this.titleDetail, 'display', '');
			}
		},

		initAggrTimeStyle: function () {
			domStyle.set(this.registAggrTime, 'display', 'none');
			domStyle.set(this.detailAggrTime, 'display', 'none');
			domStyle.set(this.damageListLink, 'display', 'none');
			// URL が登録の場合
			if (Locator.getQuery().p.indexOf('regist') !== -1) {
				if (this._aggrTimestamp === 0) {
					// 登録を表示
					domStyle.set(this.registAggrTime, 'display', '');
				} else {
					domStyle.set(this.detailAggrTime, 'display', '');
				}
			}

			// URL が詳細の場合
			if (Locator.getQuery().p.indexOf('detail') !== -1) {
				// 詳細を表示
				domStyle.set(this.detailAggrTime, 'display', '');
				domStyle.set(this.damageListLink, 'display', '');
			}
		},

		initBtnSetStyle: function () {
			domStyle.set(this.registBtnSet, 'display', 'none');
			domStyle.set(this.detailBtnSet, 'display', 'none');

			// URL が登録の場合
			if (Locator.getQuery().p.indexOf('regist') !== -1) {
				// 集計済みの場合
				if (this._aggrTimestamp !== 0) {
					// 登録を表示
					domStyle.set(this.registBtnSet, 'display', '');
				}
			}

			// URL が詳細の場合
			if (Locator.getQuery().p.indexOf('detail') !== -1) {
				// 詳細を表示
				domStyle.set(this.detailBtnSet, 'display', '');
			}
		},
		// 備考欄
		initNoteStyle: function () {
			// URL が詳細の場合
			if (Locator.getQuery().p.indexOf('detail') !== -1) {
				// 詳細を表示
				//this.note.set('disabled', true);
			}
		},
		initData: function () {
			var promise = Requester.get('/api/scheduledReport/' + this._scheduledReportId, {
				preventCache: false
			}).then(lang.hitch(this, function (data) {
				console.log(data);
				this._admNum = data.admNum;
				this._version = data.version;

				Object.keys(data).forEach(lang.hitch(this, function (key) {
					if (key === "note" || key === "correctCancelReason") {
						this[key].set('value', data[key]);
					}
					if (key in this) {
						this[key].innerText = data[key];
					}
				}));

				if (data.reportedFlg !== "0") {
					this.registReportBtn.set('style', { display: 'none' });
					this.reasonContainer.style.display = '';
					this.updateReportBtn.set('style', { display: 'inline' });

				}
				this.borderContainer.resize();
			}), lang.hitch(this, function (err) {
				console.error(err);
			}));

			Loader.wait(promise);
		},
		onAggrReport: function () {
			if (!this.aggregateDateTime.get('value')) {
				InfoDialog.show('エラー', '集計時点の日時を入力してください。');
				return;
			}

			if (this.aggregateDateTime.get('value').getTime() > new Date().getTime()) {
				InfoDialog.show('エラー', '集計時点の日時は現在より過去の日時を入力してください。');
				return;
			}
			var note = this.note.get('value');

			var promise = Requester.get('/api/scheduledReport/prepare?' +
				'disasterId=' + this._disasterId +
				'&municipalityCd=' + this.municipalityCd.value +
				'&aggrTimestamp=' + this.aggregateDateTime.get('value').getTime(), {
				preventCache: false
			}).then(lang.hitch(this, function (data) {
				Object.keys(data).forEach(lang.hitch(this, function (key) {
					if (key === "note" || key === "correctCancelReason") {
						this[key].set('value', data[key]);
					}
					if (key in this) {
						this[key].innerText = data[key];
					}
				}));
				this._aggrTimestamp = data.aggrTimestamp;
				this.initAggrTimeStyle();
				this.initBtnSetStyle();
				this.borderContainer.resize();
				this.note.set('value', note);
				if (data.municipalityCd === '271004') {
					this.submitBtn.set('style', { display: '' });
				} else {
					this.submitBtn.set('style', { display: 'none' });
				}

			}), lang.hitch(this, function (err) {
				console.error(err);
			}));

			Loader.wait(promise);
		},
		onSubmit: function () {
			try {
				if (this.form.validate()) {
					var jsonStr = json.stringify({
						disasterId: this._disasterId,
						aggrTimestamp: this.aggregateDateTime.get('value').getTime(),
						note: this.note.get('value')
					});

					var confirmMessage = '長崎県連携を登録します。よろしいですか？';
					this.chain.confirm(confirmMessage, function (chain) {
						var promise = Requester.post('/api/scheduledReport/regist', {
							data: jsonStr
						}).then(function () {
							console.debug('success register scheduleReport');
							var completeMessage = '完了しました。';
							chain.info(completeMessage, '完了', function () {
								// 前の移動
								this.leave();
							});
						}, function (error) {
							console.log('error register scheduleReport');
							console.debug(error);
							chain.info('登録に失敗しました。', 'エラー');
						});
						//ローダーの表示
						Loader.wait(promise);
					});
				}
			} catch (e) {
				console.error(e);
			}
			return false;
		},
		onRegistReport: function () {
			try {
				var jsonStr = json.stringify({
					scheduledReportId: this._scheduledReportId,
					note: this.note.get('value'),
					correctCancelReason: this.correctCancelReason.get('value'),
				});

				var confirmMessage = '長崎県連携を登録します。よろしいですか？';
				this.chain.confirm(confirmMessage, function (chain) {
					Loader.wait(
						Requester.post('/api/scheduledReport/publish', {
							data: jsonStr
						}).then(function () {
							console.debug('success delete scheduleReport');
							var completeMessage = '完了しました。';
							chain.info(completeMessage, '完了', function () {
								// 前の画面に移動
								this.leave();
							});
						}, function (error) {
							console.log('error delete scheduleReport');
							console.debug(error);
							chain.info('登録に失敗しました。', 'エラー');
						})
					);
					//ローダーの表示
				});
			} catch (e) {
				console.error(e);
			}
			return false;
		},
		onUpdeteReport: function () {
			if (!this.correctCancelReason.get('value')) {
				InfoDialog.show('エラー', '訂正取消理由を入力してください。');
				return;
			}
			try {
				var jsonStr = json.stringify({
					scheduledReportId: this._scheduledReportId,
					note: this.note.get('value'),
					correctCancelReason: this.correctCancelReason.get('value'),
				});

				var confirmMessage = '長崎県連携を更新します。よろしいですか？';
				this.chain.confirm(confirmMessage, function (chain) {
					Loader.wait(
						Requester.post('/api/scheduledReport/update', {
							data: jsonStr
						}).then(function () {
							console.debug('success delete scheduleReport');
							var completeMessage = '完了しました。';
							chain.info(completeMessage, '完了', function () {
								// 前の画面に移動
								this.leave();
							});
						}, function (error) {
							console.log('error delete scheduleReport');
							console.debug(error);
							chain.info('更新に失敗しました。', 'エラー');
						})
					);
					//ローダーの表示
				});
			} catch (e) {
				console.error(e);
			}
			return false;
		},

		onDeleteReport: function () {
			if (!this.correctCancelReason.get('value')) {
				InfoDialog.show('エラー', '訂正取消理由を入力してください。');
				return;
			}
			try {
				var confirmMessage = '削除します。よろしいですか？';
				this.chain.confirm(confirmMessage, function (chain) {
					Loader.wait(
						this.deleteStore.put({
							scheduledReportId: this._scheduledReportId,
							disasterId: this._disasterId,
							admNum: this._admNum,
							note: this.note.get('value'),
							correctCancelReason: this.correctCancelReason.get('value'),
							version: this._version
						})
							.then(function () {
								console.debug('success delete scheduleReport');
								var completeMessage = '完了しました。';
								chain.info(completeMessage, '完了', function () {
									// 前の画面に移動
									this.leave();
								});
							}, function (error) {
								console.log('error delete scheduleReport');
								console.debug(error);
								chain.info('削除に失敗しました。', 'エラー');
							})
					);
					//ローダーの表示
				});
			} catch (e) {
				console.error(e);
			}
			return false;
		},

		/**
		 * 被害一覧を表示
		 */
		onDamageReportPageLinkClick: function (evt) {
			// ブラウザーの遷移処理をキャンセル
			evt.preventDefault();
			// 被害一覧画面へ遷移
			Router.moveTo('report');
		},
		/**
		 * 定時集計一覧を表示
		 */
		onScheduledReportListPageLinkClick: function (evt) {
			// ブラウザーの遷移処理をキャンセル
			evt.preventDefault();
			// 被害一覧画面へ遷移
			Router.moveTo('scheduledReport');
		},

		/**
		 * 定時集計 被害一覧を表示
		 */
		onDamageListPageLinkClick: function (evt) {
			// 災害IDをDisasterInfoから取得
			this._disasterId = DisasterInfo.getDisasterId();
			// ブラウザーの遷移処理をキャンセル
			evt.preventDefault();
			// 被害一覧画面へ遷移
			Router.moveTo('scheduledReport/damage', {
				disasterId: DisasterInfo.getDisasterId(),
				scheduledReportId: this._scheduledReportId,
				municipalityCd: config.municInfo.cityMunicCd
			});
		},
		/**
		 * 'yyyy-MM-dd HH:mm:ss' 形式に変換
		 */
		formatDateTime: function (val) {
			if (!val) {
				return '';
			}
			var timestamp = new Date(val);
			var dateLabel = locale.format(timestamp, {
				selector: 'date',
				datePattern: 'yyyy/MM/dd'
			});
			var timeLabel = locale.format(timestamp, {
				selector: 'time',
				timePattern: 'HH:mm:ss'
			});
			return dateLabel + '&nbsp;' + timeLabel;
		},

		/**
		 * 帳票出力
		 */
		showReportForm: function () {
			this.reportFormDialog.show();
		}
	});
});

