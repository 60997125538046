/**
 * Main画面用の通知制御
 * @module app/spectee/SpecteeNotifier
 */

define([
    'module',
    'dojo/_base/declare',
    'app/common/notifier/Notifier'
], function (module, declare, Notifier) {
    return declare(module.id.replace(/\//g, '.'), Notifier, {
        SHOW_DURATION: 30, // 通知を表示させておく時間(s)

        /**
         * widget開始時に呼ばれるもの, 情報取得などやる
         */
        startup: function () {
            this.inherited(arguments);
        },

        /**
         * DOM生成
         */
        buildRendering: function () {
            this.inherited(arguments);
        },
        
        /**
         * Specteeの情報を取得し、トーストを表示
         */
        processSpectee: function (items) {
            if (items.length > 0) {
                for(var i=0; i<items.length; i++) {
                    if (items[i].newFlg){
                        if (items[i].title.length > 35){
                            var title = items[i].title + '...';
                        } else {
                            var title = items[i].title;
                        }
                        this.notify({
                            title: '【新着情報】',
                            level: 'high',
                            message: '[第' + items[i].reportNo + '報]　' + title ,
                            page: 'spectee/detail',
                            detailId: items[i].caseId,
                            timeout: this.SHOW_DURATION * 1000
                        });
                    } else {
                        break;
                    }
                }
            }
        }
    });
});
