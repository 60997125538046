
define([
    './bbs',
    './beginner',
    './broadnotify',
    './chat',
    './chronology',
    './citizenInfoHistory',
    './convocation',
    './dam',
    './damage',
    './disaster',
    './disasterprevention',
    './earthquake',
    './employgathering',
    './evacorder',
    './evacrecommend',
    './evacstdlimitover',
    './facility',
    './facilityMaster',
    './floodforecast',
    './folder',
    './formDisplay',
    './geofile',
    './geoplot',
    './lalert',
    './lifeline',
    './login',
    './notice',
    './observationmap',
    './observationstation',
    './organization',
    './password',
    './prefsitesending',
    './provide',
    './rain',
    './rescue',
    './river',
    './rivercamera',
    './riverBasin',
    './safety',
    './sample',
    './scheduledReport',
    './sedimentrisk',
    './sending',
    './sendingHistory',
    './shelter',
    './simulation',
    './spectee',
    './sip4dCkan',
    './tide',
    './timeline',
    './traffic',
    './tsunami',
    './user',
    './view',
    './volcano',
    './weatherinfo',
], function () {
});