/**
 * 体制・報告 詳細/更新画面用モジュール。
 * @module app/employgathering/EmployGatheringStatusDetailDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/EmployGatheringStatusDetailDialog.html',
    './_EmployGatheringStatusPageBase',
    'idis/model/UserInfo',
    'idis/consts/ACL',
    'idis/view/dialog/InfoDialog',
    // 以下、変数から参照されないモジュール
    'dijit/form/ValidationTextBox',
    'dijit/form/NumberTextBox',
    'dijit/form/CheckBox',
    'dijit/form/DateTextBox',
    'idis/view/form/CheckGroup',
    'idis/view/form/RadioGroup',
    './EmployGatheringOrganizationSelector'
], function(module, declare, lang, template, _PageBase, UserInfo, ACL, InfoDialog) {
    /**
     * 災害情報登録画面。
     * @class DisasterPreventionDetailPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/view/page/DisasterAdmintPage~DisasterAdminPage# */ {

        // テンプレート文字列
        templateString: template,

        // 職員参集状況ID
        empGatherId: null,

        /**
         * フォームを初期化する。
         */
        initForm: function(obj) {
            this.form.set('value', obj);
            this.reportTimestamp.set('value', obj.reportTimestampMS);
            this.EmployGatheringOrganizationSelector.set('value', obj.empCategory);
        },


        // テンプレートHTML内内でformに対し、data-dojo-attach-event="onSubmit:onSubmit"でこのメソッドを紐付け
        // 登録ボタンをtype="submit"にしているので、ボタン押下時にフォームがsubmitされて呼ばれる
        onSubmit: function() {
            try {
                // フォームのバリデーションを実施
                if (!this.form.validate()) {
                    return false;
                }

                // 固有のバリデーションを実施
                if(!this.validate()) {
                    return false;
                }
                if(!this.reportTimestamp.validate() || !this.reportTimestamp._date.displayedValue ||
                        !this.reportTimestamp._time.displayedValue) {
                    console.debug('報告日時が入力されていません');
                    if (!this.infoDialog) {
                        this.infoDialog = new InfoDialog({
                            title : 'エラー',
                            content : '報告日時が入力されていません'
                        });
                    }
                    this.infoDialog.show();
                    this.infoDialog = null;
                } else if (!this.gatheringDisaster.validate() ||
                        (!this.gatheringDisaster.value&&this.gatheringDisaster.value!==0)) {
                    console.debug('参集人数が入力されていません');
                    if (!this.infoDialog) {
                        this.infoDialog = new InfoDialog({
                            title : 'エラー',
                            content : '参集人数が入力されていません'
                        });
                    }
                    this.infoDialog.show();
                    this.infoDialog = null;
                } else {
                    var value = this.form.get('value');
                    value.empGatherId = this.empGatherId;
                    this.emit('send', {value: value});
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * ダイアログを初期化する。（更新時）
         */
        initDialog: function(parent, obj) {
            this.form.reset();
            this.reportTimestamp._date.displayedValue = null;
            this.reportTimestamp._time.displayedValue = null;

            this._parent = parent;
            this.empGatherId = obj.empGatherId;

            this.initForm(obj);
        },

        /**
         * 変更した組織に紐づくデータにform値を入れ替える
         * （組織に値を入れなおすと、onChangeがループするので、
         *   this.form.setは使用しない。）
         */
        onChangeOrgSelector: function(obj) {
            this.empGatherId = obj.empGatherId; 
            this.reportTimestamp.set('value', obj.reportTimestampMS);
            this.gatheringDisaster.set('value', obj.gatherDisaster);
            this.comment.set('value', obj.comment);
        }
    });
});
