/**
 * <潮位実況情報情報詳細画面>
 *
 * @module app/tide/grid/TideLevelObservationDetailPageGrid
 */
define(['module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'idis/view/grid/IdisGrid',
	'idis/view/grid/helper',
    'app/view/grid/CommonColumn',
    './TideLevelObservationCommonColumns',
	// 以下、変数で受けないモジュール
	'dijit/layout/BorderContainer'
	], function(module, declare, IdisGrid, helper, CommonColumn, TideCommonColumns) {
	/**
	 * 潮位実況情報情報詳細画面用Grid
	 *
	 * @class TideLevelObservationDetailPageGrid
	 * @extends module:idis/view/page/_PageBase~_PageBase
	 */
	return declare(module.id.replace(/\//g, '.'),IdisGrid,
		/** @lends module:app/tide/TideLevelObservationHourlyPageGrid~RainfallObservationDetailPageGrid# */
		{
		columns: [
			helper.column('days', '月日'),
			helper.column('time', '時刻'),
            TideCommonColumns.tideLevel,
            TideCommonColumns.tideLevelTp,
            TideCommonColumns.tideLevelAstronomical,
            TideCommonColumns.tideLevelAstronomicalTp,
            CommonColumn.threshold('tideLevelDeviation', '潮位偏差[m]', 2)
            // helper.column('windDirection', '風向', {
            //     formatter: function(value, item) {
            //         return CommonColumn.formatObservationData(item, 'windDirection');
            //     },
            //     className : function(item) {
            //         // ヘッダーの場合はclassNameを返さない
            //         if (!item) {
            //             return;
            //         }
            //         return CommonColumn.getClassNameExcess(item, 'windDirection');
            //     }
            // }),
            // helper.column('windSpeed', '風速[m/s]', {
            //     formatter: function(value, item) {
            //         return CommonColumn.formatObservationData(item, 'windSpeed');
            //     },
            //     className : function(item) {
            //         // ヘッダーの場合はclassNameを返さない
            //         if (!item) {
            //             return;
            //         }
            //         // 0:通常   1:注意   2:警報
            //         var excess = 0;
            //         if(item.windSpeed>=10){
            //             excess = item.windSpeed<20 ? 1 : 2;
            //         }
            //         return CommonColumn.getClassNameExcess(item, 'windSpeed', excess);
            //     }
	        //   })
        ]
    });
});
