/**
 * 安否確認機能 職員管理画面用グリッド。
 * @module app/safety/employee/SafetyEmployeeGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/dom-class',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function(module, declare, domClass, IdisGrid, helper) {

/**
 * メールアドレスステータスのセルを描画する。
 * @param {Object} item 行データ
 * @param {String} value 項目値
 * @param {Object} node DOMノード
 */
var renderEmailAdressStatus = function(item, value, node) {
    var className = '';
    var text = '-';
    switch(value){
        case '01':
            className = 'is-tempReg';
            text = '本登録待ち';
            break;
        case '02':
            className = 'is-registerd';
            text = '正常';
            break;
        case '03':
            className = 'is-fault';
            text = '異常';
            break;
    }
    domClass.add(node, className);
    node.innerText = text;
};

return declare(module.id.replace(/\//g, '.'), IdisGrid,
/** @lends module:idis/view/page/ProvideTemplatePage~ProvideTemplatePage# */ {

/**
 * 各列の定義
 * @type {Object[]}
 */
collection: this.store,
columns: [
    helper.buttonColumn('userDetail', '詳細'),
    {
        label: '組織',
        field: 'organizationName',
        children: [{
            field: 'parentName',
            label: '部局',
            sortable: false
        }, {
            field: 'divisionName',
                label: '課',
                sortable: false
            }]
        },
        helper.column('employeeCd','職員番号'),
        helper.column('name','氏名'),
        {
            label: 'メールアドレス',
            field: 'emailAddress',
            children: [{
                field: 'emailAddressStatus01',
                label: '登録状況１',
                sortable: false,
                renderCell: renderEmailAdressStatus
            }, {
                field: 'emailAddressStatus02',
                label: '登録状況２',
                sortable: false,
                renderCell: renderEmailAdressStatus
            }]},
            helper.column('emailAddress01', 'メールアドレス1', {
                formatter: function(value) {
                    return value ? value : '-';
                }
            }),
            helper.column('emailAddress02', 'メールアドレス2', {
                formatter: function(value) {
                    return value ? value : '-';
                }
            }),
            helper.column('groupName', 'グループ・係名'),
            helper.column('jobName', '職名'),
            helper.column('roleType', '権限', {
                formatMap: {
                    '01': 'システム管理者',
                    '02': '管理者',
                    '03': '一般職員'
                }
            })
        ]
    });
});
