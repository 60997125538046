/**
 * 「雨量情報」画面用のモジュール
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/text!./templates/RainfallObservationPage.html',
    'dojo/topic',
    'dstore/RequestMemory',
    'idis/view/page/_PageBase',
    'idis/control/Router',
    'app/observation/view/form/DateTimeSelect',
    'app/observationstation/model/DataKind',
    'idis/service/Requester',
    'idis/store/IdisRest',
    'idis/control/Locator',
    'app/consts/APP_QUERY',
    // 以下、変数で受けないモジュール,
    'app/view/form/RegionMuni4ObservatorySelector',
    'app/view/page/StatisticsDataDialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/TextBox',
    'dijit/layout/ContentPane',
    'idis/view/form/DateTimeInput',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    './RainfallLegend',
    './grid/RainfallObservationPageGrid'
], function(module, declare, lang, domStyle, template, topic, RequestMemory,
		_PageBase, Router, DateTimeSelect, DataKind, Requester, IdisRest, Locator, APP_QUERY) {

    return declare(module.id.replace(/\//g, '.'), _PageBase, {
        // テンプレート文字列
        templateString: template,

		// ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--rainfallobs',

        // 雨量データ
        store: null,

        /**
         * 最新の観測情報オブジェクト
         */
        latestData: null,

        /**
         * 時間モード
         * {string} '10': 10分 '60': 正時
         */
        mode: '10',

        /**
         * 前回のデータ取得日時
         * {string} 'yyyy-MM-dd HH:mm'
         */
        lastRequestDateTime: '',


        buildRendering: function() {
            this.inherited(arguments);

            // 初期表示は、時間モード10分で最新の時間データ
            // 最新の観測情報を取得する
            Requester.get('/api/rain/10min/latestDataTime').then(lang.hitch(this, function(data){
                // 雨量データ格納用オブジェクト
                this.store = new IdisRest({
                    idProperty: 'rainId',
                    target: '/api/rain/10min'
                });
                // 日付セレクトボックスを生成・設置する
                this.dateTimeSelect = new DateTimeSelect({
                    to: data
                });

                this.dateTimeSelect.placeAt(this.dateTimeSelectNode, 'only');
                // グリッドを初期化する
                this.initGrid();
            }));

            // // 凡例を切り替える
            // this.setLegend();

        },

        /**
         * DOM構築後に呼ばれる
         */
        postCreate: function() {
            this.inherited(arguments);
            // 日時の変更を監視
            this.own(topic.subscribe(DateTimeSelect.TOPIC.CHANGE_DATE_TIME, lang.hitch(this, function(datetime) {
                // 1回の操作で日付と時間が同時に変更される場合があり、このとき同じ日時で2回続けて変更イベントが発行される
                // 同じ日時で連続でデータ取得のリクエストが飛ぶことを避けるため、前回の日時を保持し、異なる場合のみデータを取得するようにする
                if (this.lastRequestDateTime !== (datetime.date.replace(/-/g, '/') + ' ' + datetime.time)) {
                    var timeMode = '10min';
                    // // 時間モードの切り替え時にはmode自体に数値を代入するため、どちらの場合にも対応する
                    // if(this.mode.value === '60' || this.mode === '60'){
                    //     timeMode = 'hourly';
                    // }
                    // データを取得する
                    this.store = new IdisRest({
                        idProperty: 'rainId',
                        target: '/api/rain/' + timeMode
                    });
                    // 地域が選択されている場合があるためフィルターを設定し、グリッドにセット
                    var filter = this.updateFilter(this.areaSelector.value, 0);
                    // 観測日時をフィルターにセット
                    // IEでDate生成時にエラーとなるのを回避
                    var dateStr = datetime.date.replace(/-/g, '/') + ' ' + datetime.time;
                    var date = new Date(dateStr).getTime();
                    filter = filter.eq('date', date);
                    this.grid.set('collection', this.store.filter(filter));
                }
                this.lastRequestDateTime = datetime.date + ' ' + datetime.time;
            })));
            // 最新ボタンクリックを監視
            this.own(topic.subscribe(DateTimeSelect.TOPIC.CLICK_LATEST, lang.hitch(this, function() {
                var timeMode = '10min';
                // // 時間モードの切り替え時にはmode自体に数値を代入するため、どちらの場合にも対応する
                // if(this.mode.value === '60' || this.mode === '60'){
                //     timeMode = 'hourly';
                // }
                // 最新の観測情報を取得
                Requester.get('/api/rain/'+ timeMode + '/latestDataTime').then(lang.hitch(this, function(data){
                    // 観測日時セレクトボックスを再構築
                    // セレクトボックスの値が変更されれば、変更トピックが発火されて上記の「日時の変更を監視」でキャッチされ、対応するデータが取得される
                    this.dateTimeSelect.rebuild(data);
                }));
            })));
        },

        initGrid: function() {
            // // グリッドのカラムをセット
            // this.setGridColumn();

            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.grid.on('detailButtonClick', lang.hitch(this, function(evt) {
                this.onDetailButtonClick(evt.item);
            }));

            // （テンプレートHTML内のdata-dojo-attach-point="grid"でグリッドをthis.gridに紐付け済み）
            this.grid.set('collection', this.store);
            this.borderContainer.resize();
        },

        /**
         * 10分と正時でグリッドのカラム表示・非表示項目を切り替える
         */
        setGridColumn: function() {
            if (this.mode === '60') {
                // 正時の場合、
                // 「時間雨量」カラムを表示、「10分雨量」「60分雨量」を非表示
                this.grid.styleColumn('0-5', 'display: table-cell;');
                this.grid.styleColumn('0-6', 'display: none;');
                this.grid.styleColumn('0-7', 'display: none;');
            } else {
                // 10分の場合、
                // 「時間雨量」カラムを非表示、「10分雨量」「60分雨量」を表示
                this.grid.styleColumn('0-5', 'display: none;');
                this.grid.styleColumn('0-6', 'display: table-cell;');
                this.grid.styleColumn('0-7', 'display: table-cell;');
            }
        },

        onDetailButtonClick: function(object) {
            var date = new Date(this.lastRequestDateTime).getTime();
            // 雨量情報詳細画面へ遷移
            Router.moveTo('observation/rainfall/detail', {
                observatoryId : object.observatoryId,
                regionCd : object.regionCd,
                date : date,
                timeMode : this.mode
            });
        },

        /**
         * 時間モードが変更された際に呼ばれる
         */
        onChangeModeSelect: function(value) {
            this.mode = value;
            // // 観測時間選択の表示を切り替える
            // this.dateTimeSelect.changeMode(value);
            // // 時間モードによってリクエスト先のAPIを切り替える
            var timeMode = '10min';
            this.store.target = '/api/rain/' + timeMode;
            // サーバーにリクエストする
            var filter = this.updateFilter(this.areaSelector.value,1);
            this.grid.set('collection', this.store.filter(filter));
            // 時間セレクタの変更
            if(value === '60'){
                timeMode = 'hourly';
            }
            this.dateTimeSelect.changeMode(timeMode);
            Locator.replaceState(APP_QUERY.MODE, timeMode);
            // // グリッドのカラム表示を切り替える
            // this.setGridColumn();
            // // 凡例を切り替える
            // this.setLegend();
        },

        /**
         * 地域セレクトボックスが変更された際に呼ばれる
         */
        onChangeRegionSelect: function(evt) {
            var filter = this.updateFilter(evt.value,1);
            this.grid.set('collection', this.store.filter(filter));
        },

        /**
         * ストアのフィルターを生成する
         * @param {string} 地域コード または 流域コード. 指定されない場合は全選択.
         * @param {string} 時間変更フラグ. 1の場合は時間が変更されていないため、最後に選択された時刻でRequest.
         * @returns {dstore.Filter}
         */
        updateFilter: function(value,flg) {
            var filter = new this.store.Filter();

            // 地域/市町村セレクタ
            switch (this.areaSelector.getAreaType()) {
                case 'REGION':          //地域を選択
                    filter = filter.eq('regionCd', value);
                    break;
                case 'MUNICIPALITY':    // 市町村を選択
                    filter = filter.eq('municipalityCd', value);
                    break;
            }
            // 時間モード
            filter = filter.eq('timeMode', this.mode);
            if(flg === 1){
                // 観測日時をフィルターにセット
                var date = new Date(this.lastRequestDateTime).getTime();
                filter = filter.eq('date', date);
            }
            return filter;
        },
        onEvacOrderRegisterButtonClick: function(evt) {
            console.debug('[避難状況新規登録]ボタンがクリックされました。');
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            // 避難状況登録登録画面へ遷移
            Router.moveTo('evacorder/register');
        },

        onRainfallStatisticsDataDialogButtonClick: function() {
            console.debug('[統計情報ダウンロード]ボタンがクリックされました。');
            // ダイアログの最初の子要素が登録画面
            var dialog = this.statisticsDataDialog.getChildren()[0];
            dialog.setInitKind(DataKind.RAINFALL);
            this.statisticsDataDialog.set({title: '統計情報'});
            this.statisticsDataDialog.show();
        },

        onRainfallRankingButtonClick: function() {
            console.debug('[雨量ランキング]ボタンがクリックされました。');
            // 避難状況登録登録画面へ遷移
            Router.moveTo('observation/rainfall/ranking');
        },

        /**
         * 時間モードによって、凡例を切り替える
         */
        setLegend: function(){
            if (this.mode === '60') {
                // 正時の場合、
                // 「時間雨量」の凡例を表示
                // 「10分雨量」の凡例を非表示
                domStyle.set(this.hourlyLegend, 'display', '');
                domStyle.set(this.minLegend, 'display', 'none');
            } else {
                // 10分の場合、
                // 「10分雨量」の凡例を表示
                // 「時間雨量」の凡例を非表示
                domStyle.set(this.minLegend, 'display', '');
                domStyle.set(this.hourlyLegend, 'display', 'none');
            }
        }

    });
});
