/**
 * 安否確認 ファイル読み込みダイアログ用モジュール。
 * @module app/safety/employee/FileUploadDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/FileUploadDialog.html',
    'idis/view/dialog/InfoDialog',
    'idis/view/page/_PageBase',
    // 以下、変数で受けないモジュール
    'dojox/form/Uploader',
    'dijit/form/Form',
    'idis/view/form/Button',
    'app/safety/auth/view/form/AclButton'
], function(module, declare, lang, template, InfoDialog, _PageBase) {
    /**
     * 職員詳細ダイアログ
     * @class SafetyEmployeeDetailDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
                   /** @lends module:app/view/page/DamageReportPage~DamageReportPage# */ {
        // テンプレート文字列
        templateString: template,
        
        baseClass: 'idis-Page--safety-dialog',

        /**
         * ファイル読み込み実行
         */
        onSubmit: function() {
          if (this.validate()) {
            this.emit('upload', {
              attachFile: this.attachFile,
              formId: this.form.id
            });
          }
        },

        /**
         * 入力チェック
         */
        validate: function() {
          //ファイルが空の場合は処理を中断
          if(this.attachFile.getFileList().length === 0) {
            InfoDialog.show('入力チェック', 'ファイルが選択されていません');
            return false;
          }
          return true;
        },

        /**
         * 選択されたファイルの拡張子をチェック
         */
        loadFile: function() {
          if (this.attachFile.getFileList().length === 0) {
            return;
          }
          // 選択されたファイル名を取得
          var fileName = this.attachFile.getFileList()[0].name;
          
          // CSVファイルで無い場合はエラー
          if (!/.csv$/.test(fileName)) {
            var msg = '選択したファイルが適切ではありません。<br><br>';
            msg += '拡張子が.csvのファイルを選択してください。';
            InfoDialog.show('エラー', msg);
            return;
          }
        }

    });
});
