/**
 * 道路交通規制情報取込情報一覧グリッド
 *
 * @module app/traffic/import/TrafficImportInfoListGrid
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'app/view/grid/CommonColumn'
    // 以下、変数で受けないモジュール
], function(module, declare, IdisGrid, helper, CommonColumn) {
    /**
     * 道路交通規制情報取込情報一覧グリッド
     *
     * @class TideLevelObservationPageGrid
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), IdisGrid,
        /** @lends module:app/traffic/import/TrafficImportInfoListGrid~TrafficImportInfoListGrid# */ {
        columns: [
            helper.buttonColumn('registerDamageReport', '取込'),
            helper.column('roadName', '路線名', {sortable: false}),
            helper.column('address', '規制区間'),
            helper.column('kindName', '規制種別', {sortable: false}),
            helper.column('reason', '原因', {sortable: false}),
            helper.column('period', '規制期間', {
                children : [
                    helper.column('dateStart', '開始'),
                    CommonColumn.optionColumn('dateEnd', '終了')
                ]
            }),
            helper.column('time', '規制時間', {
                children : [
                    helper.column('timeStart', '開始時刻'),
                    CommonColumn.optionColumn('timeEnd', '終了時刻')
                ]
            }),
            helper.column('management', '管理者等', {sortable: false}),
            helper.column('note', '備考', {sortable: false})
        ]
    });
});
