/**
* 被害種別選択用grid
* @module app/formDisplay/damagetype/DamageTypeGrid
*/
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/dom-style',
    'dijit/form/RadioButton',
    'dijit/form/CheckBox',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function (module, declare, lang, array, domStyle, RadioButton, CheckBox,
    IdisGrid, helper) {

    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--damage-list',

        // radiobutton/checkbox制御用
        //        radioButtons: {},
        checkboxes: {},

        defaultKeyList: [],
        authList: [],

        loaded: false.valueOf,

        // 親子のID
        //        mainTypeId: null,
        mainTypeIds: [],


        constructor: function () {
            /**
             * 各列の定義
             * @type {Object[]}
             */
            this.columns = [
                {
                    field: 'main', label: '選択', sortable: false,

                    renderCell: lang.hitch(this, function (item) {
                        this.checkboxes[item.key] = new CheckBox({
                            onClick: lang.hitch(this, function () {
                                this._onCheckBox(item);
                            })
                        });
                        if (!this.loaded) {
                            this.defaultKeyList.forEach(lang.hitch(this, function (key) {
                                this.checkboxes[key].set('checked', true);
                            }));
                            this.loaded = true;
                        }
                        if (this.authList.indexOf(item.key) === -1) {
                            this.checkboxes[item.key].set('disabled', true);
                            item.disabled = true;
                        }
                        // HTMLとしてウィジェットに紐付くDOMノードを返す
                        return this.checkboxes[item.key].domNode;
                    })
                },
                helper.column('value', '被害の種類')
            ];

            this.inherited(arguments);
        },
        renderRow: function (item) {
            var div = this.inherited(arguments);
            if (item.disabled) {
                domStyle.set(div, 'background-color', '#CCC');
            }
            return div;
        },


        reset: function () {
            this.inherited(arguments);

            //            this.radioButtons = {};
            this.checkboxes = {};
            //            this.mainTypeId = null;
            this.mainTypeIds = [];
        },

        /**
         *  ラジオボタンを押した時
         *  押されたものが親, それ以外を全て子供にチェックを入れるようにしている
         * @param {*} item 押された行の内容
         */
        //        _onRadioButton: function(item) {
        //            this.mainTypeId = item.damageTypeId;
        //            console.log('main: ' + this.mainTypeId);
        //
        //            // 全部のcheckboxをenableにする。
        //            for (var key in this.checkboxes) {
        //                if (this.checkboxes.hasOwnProperty(key)) {
        //                    this.checkboxes[key].set('disabled', false);
        //                }
        //            }
        //
        //            // 今回親にした行のチェックボックスをdisableに
        //            this.checkboxes[item.damageTypeId].set('disabled', 'disabled');
        //            this.checkboxes[item.damageTypeId].set('checked', false);
        //            this._onCheckBox(item);
        //
        //        },

        _onCheckBox: function (item) {
            if (this.checkboxes[item.key].get('checked')) {
                // チェック状態である => 今回追加されたのでmainTypeIdsに入れる
                this.mainTypeIds.push(item.key);
            } else {
                // チェック状態でない => mainTypeIdsにあったら削除
                var index = array.indexOf(this.mainTypeIds, item.key);
                if (index !== -1) {
                    this.mainTypeIds.splice(index, 1);
                }
            }

            console.log(this.mainTypeIds);
        }
    });
});
