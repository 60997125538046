/**
 * ヘッダー用モジュール。
 * @module app/password/CheckPasswordExpired
 */
define([
    // 'dojo/_base/lang',
    // 'idis/control/Router',
    // 'idis/model/UserInfo',
    // 'idis/service/Requester',
    // 'idis/view/dialog/ConfirmDialog',
    // 'idis/control/Locator'
    // ], function (lang, Router, UserInfo, Requester, ConfirmDialog, Locator) {
    ], function () {
    // // 履歴の監視開始
    // Locator.start();
    // UserInfo.load().then(function () {

    //     if (!Locator.getQuery()['p']) {
    //         Requester.get('/api/password/checkExpired')
    //             .then(function (data) {
    //                 if (data === 1) {
    //                     new ConfirmDialog({
    //                         title: '期限切れ',
    //                         content: 'パスワードの有効期限が過ぎました。<br>パスワードを更新してください。',
    //                         showCancel: false,
    //                         onOK: function () {
    //                             this.hide();
    //                             Router.moveTo('updatePassword');
    //                         }
    //                     }).show();
    //                 }
    //             });
    //     }
    // });


});