/**
 * 配信情報テンプレート画面詳細ダイアログ
 * @module app/provide/ProvideTempDetailDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/text!./templates/ProvideTempDetailDialog.html',
    'idis/view/page/_PageBase',
    // 以下、変数から参照されないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/form/Form',
    'dijit/layout/TabContainer',
    'dijit/form/SimpleTextarea',
    'dijit/form/ValidationTextBox',
    'idis/view/form/Button',
    'idis/view/form/CheckGroup',
    'idis/view/form/RadioGroup',
    'idis/view/form/DateTimeInput'
//    ,
//    './form/NoticeTypeSelect'
], function(module, declare, template, _PageBase) {
    /**
     * お知らせ登録ダイアログ内の画面。
     * @class ProvideTempDetailDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), [_PageBase],
        /** @lends module:idis/view/page/ProvideTempDetailDialog~ProvideTempDetailDialog# */ {
        // テンプレート文字列
        templateString: template,

        // テンプレートHTML内内でformに対し、data-dojo-attach-event="onSubmit:onSubmit"でこのメソッドを紐付け
        // 登録ボタンをtype="submit"にしているので、ボタン押下時にフォームがsubmitされて呼ばれる
        onSubmit: function() {
            try {
                if (this.form.validate()) {
                    var value = this.form.get('value');
                    // 実際の登録処理はNoticePage側でregisterイベントを監視しているメソッドが実施
                    this.emit('update', {value: value});
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },
        
        onDeleteButton: function(){
            try {
                if (this.form.validate()) {
                    var value = this.form.get('value');
                    // 実際の登録処理はNoticePage側でregisterイベントを監視しているメソッドが実施
                    this.emit('delete', {value: value});
                }
            } catch (e) {
                console.error(e);
            }
            return false;            
        },
        
        resize: function(changeSize, resultSize) {
            this.borderContainer.resize(changeSize, resultSize);
        }        
    });
});

