/**
 * リンク挿入機能の抽出。
 * @module app/notice/view/_LinkInsertMixin
 */
define([
    'module',
    'dojo/_base/declare'
    // 以下、変数から参照されないモジュール
], function(module, declare) {
    /**
     * お知らせ登録ダイアログ内の画面。
     * @class NoticeRegisterPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), null,
        /** @lends module:idis/view/page/_LinkInsertMixin~_LinkInsertMixin# */ {

        /**
         * リンク挿入位置
         * @type {number}
         */
        _insertPoint: null,

        // DOM構築時に呼ばれる
        buildRendering: function() {
            this.inherited(arguments);
            this.own(this.linkInsertDialog);
        },

        /**
         * リンク挿入ボタンが押された際に呼ばれる。
         * @param {MouseEvent} evt クリック・イベント
         */
        onLinkInsertButtonClick: function(/* evt */) {
            // 内容のカーソル位置を記憶する
            var point = this.contentTextarea.domNode.selectionStart;
            if (!point && point !== 0) {
                // 取得出来なかった場合は末尾に設定
                point = this.contentTextarea.get('value').length;
            }
            this._insertPoint = point;
            this.linkInsertForm.reset();
            this.linkInsertDialog.show();
        },

        /**
         * リンク挿入内容確定時に呼ばれる。
         * @param {Event} evt イベント
         */
        onLinkInsertOK: function(/* evt */) {
            try {
                if (this.linkInsertForm.validate()) {
                    // リンク文字列を生成
                    var value = this.linkInsertForm.get('value');
                    var linkText = '[' + value.title + '](' + value.url + ')';
                    // 内容のカーソル位置か末尾へリンク文字列を挿入
                    var content = this.contentTextarea.get('value');
                    var newContent = content.slice(0, this._insertPoint) + linkText + content.slice(this._insertPoint);
                    this.contentTextarea.set('value', newContent);
                    // ダイアログを閉じる
                    this.linkInsertDialog.hide();
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        }
    });
});

