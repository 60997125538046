define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function(module, declare, lang, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--simulation',

        /**
         * 各列の定義
         * @type {Object[]}
         */
		columns: [
          helper.buttonColumn('detail', '詳細'),
          helper.column('simulationId', '再生履歴ID', {sortable: false}),
          helper.column('disasterName', '災害名', {sortable: false}),
          helper.column('updTimestamp', '最終更新日時', {sortable: false}),
          {field: 'status', label: 'ステータス',
              sortable: false,
              formatter: lang.hitch(this, function(value){
                  var text = '<span>';
                  text += value === '1' ? '再生中' : '-';
                  return text + '</span>';
              })
          }
  	    ]
    });
});
