/**
 * <モジュールの説明>
 * @module app/view/page/<ウィジェット名>
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/text!./templates/TideLevelCorrectDialog.html', // テンプレート文字列
    'idis/view/page/_PageBase', // 共通基底クラス
    'idis/view/dialog/ConfirmDialog',
    'idis/view/dialog/InfoDialog',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    'idis/control/Router',
    'idis/store/IdisRest',
    // 以下、変数で受けないモジュール
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
	'dijit/form/NumberTextBox',
    'dijit/form/RadioButton',
    'app/view/form/MunicipalitySelector',
    'dijit/form/Form'
], function(module, declare, template, _PageBase, ConfirmDialog, InfoDialog, DialogChain, Loader, Router, IdisRest) {
    /**
     * <クラスの説明>
     * @class <ウィジェット名>
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/<ウィジェット名>~<ウィジェット名># */ {
        // テンプレート文字列
        templateString: template,
        
        // 潮位ID
        _tideLevelId: null,
        
        // 観測所ID
        _observatoryId: null,
        
        //観測日時
        _date: null,
        
        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'tideLevelId',
                target: '/api/tide'
            });
            
            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
        },
        
        initDialog: function(item, observatoryId, date) {
        	this._tideLevelId = item.tideLevelId;
        	this._observatoryId = observatoryId;
        	this._date = date;
        	this.timestamp.innerHTML = item.days + item.time;
            this.form.reset();
        },
        
        // 観測状況を変更したときの挙動
        onChangeFlg: function() {
          console.debug('観測状況が変更されました');
          if(this.form.get('value').tideLevelFlg === '0'){
            this.tideLevel.set('disabled',false);
          }else{
            this.tideLevel.set('disabled',true);
          }
        },
        // 更新ボタンを押下したときの挙動
        updateButtonClick: function() {
        	if(!this.tideLevel.value && this.tideLevelFlg.get('value') === '0') {
        		console.debug('数値を入力してください');
				if (!this.infoDialog) {
					this.infoDialog = new InfoDialog({
						title : 'エラー',
						content : '数値を入力してください'
					});
				}
				this.infoDialog.show();
				this.infoDialog = null;
        	}
        	else{
        		this.chain.confirmPut(function(chain) {
                	var sendData = this.form.get('value');
                	console.debug(sendData);
                	sendData.tideLevelId = this._tideLevelId;
        			var promise = this.store.put(sendData);
        			Loader.wait(promise).then(function() {
                        chain.infoComplete(function() {
                            console.debug('観測情報の更新に成功しました。');
                            var date = new Date(this._date).getTime();
                            // チャート・グラフを再生成するため、ページをリフレッシュ
                			Router.moveTo('observation/tide/detail', {
                        		observatoryId : this._observatoryId,
                        		date : date
                			});
                            location.reload();
                        });
        			}, function(err) {
        				// 失敗時
        				chain.infoError(err);
        			});
        		});
        	}
        }
    });
});