/**
 * 職員安否状況管理画面用モジュール。
 *
 * @module app/safety/employee/SafetyEmployeeAdminPage
 */
define(['module',
        'dojo/_base/declare',
        'dojo/_base/lang',
        'dojo/json',
        'dojo/dom-style',
        'dojo/request/iframe',
        'dojo/text!./templates/SafetyEmployeeAdminPage.html',
        'dojo/text!./templates/FileUploadResult.html',
        'dojox/dtl',
        'dojox/dtl/Context',
        'idis/view/page/_PageBase',
        'idis/consts/ACL',
        'idis/service/Requester',
        'idis/view/Loader',
        'idis/view/dialog/DialogChain',
        'idis/view/dialog/InfoDialog',
        'idis/store/IdisRest',
        // 以下、変数で受けないモジュール
        'dijit/Dialog',
        'dijit/form/Form',
        'dijit/form/Select',
        'dijit/form/TextBox',
        'dijit/form/CheckBox',
        'dijit/layout/BorderContainer',
        'dijit/layout/ContentPane',
        'idis/view/form/Button',
        'idis/view/form/DateInput',
        'idis/view/form/DateTimeInput',
        'app/safety/auth/view/form/AclButton',
        'app/view/form/EmployeeDivisionSelector',
        'app/safety/employee/SafetyEmployeeRegisterDialog',
        'app/safety/employee/SafetyEmployeeDetailDialog',
		'app/safety/employee/FileUploadDialog',
        'app/safety/employee/SafetyEmployeeGrid'],
    function(module, declare, lang, JSON, domStyle, iframe, template, resultTemplate, dtl, Context,
        _PageBase, ACL, Requester, Loader, DialogChain, InfoDialog, IdisRest) {
        /**
         * 職員安否 職員管理タブ
         *
         * @class SafetyEmployeeAdminPage
         * @extends module:idis/view/page/_PageBase~_PageBase
         */
        return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app_safety/employee/SafetyEmployeeAdminPage~SafetyEmployeeAdminPage# */
        {

            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--safety',
            
            // 職員コード
            empCd: '',

            // グリッド用データ格納オブジェクト
            empStore: null,

            /**
             * 変数初期化メソッド
             */
            constructor: function() {
                // 職員情報タブ用データ格納用オブジェクト
                this.empStore = new IdisRest({
                    idProperty: 'employeeCd',
                    target: '/api/safety/employees'
                });

                // ダイアログ連鎖を登録
                // 引数に与えたウィジェットのthis.ownを呼び出し、
                // ウィジェットが破棄された際にダイアログ連鎖が破棄されるようになる
                this.chain = DialogChain.get(this);
            },

            /**
             * DOMノードを生成するためのメソッド
             */
            buildRendering: function() {
                this.inherited(arguments);
                // 自分が削除された時は関連ダイアログも削除する
                this.own(this.safetyEmployeeRegisterDialog);
                this.own(this.safetyEmployeeDetailDialog);
                this.initEmployeeRegisterDialog();
                this.initEmployeeDetailDialog();
                this.initFileUploadDialog();
                this.initSafetyEmployeeGrid();

                // メールアドレスのカラムを非表示
                // TODO: システム管理者で
                //       DETERRENCE_TYPE:02(職員安否メールアドレス表示)
                //       の時のみ表示するよう修正
                var mail01Style = this.safetyEmployeeGrid.styleColumn('0-7');
                mail01Style.set('display', 'none');
                var mail02Style = this.safetyEmployeeGrid.styleColumn('0-8');
                mail02Style.set('display', 'none');
            },

            /**
             * 職員情報タブ用
             * 登録ダイアログのformが投稿された際の動作を設定する。
             */
            initEmployeeRegisterDialog: function() {
                // 登録ダイアログの最初の子要素が登録画面
                var dialog = this.safetyEmployeeRegisterDialog;
                var page = dialog.getChildren()[0];
                page.on('register', lang.hitch(this, function(evt) {
                    // 追加確認ダイアログを表示
                    this.chain.confirmAdd(function(chain) {
                        // OKが押された場合
                        // 追加処理と共にローディング表示
                        var promise = this.empStore.add(evt.value).then(lang.hitch(this, function() {
                            // 成功時
                            // グリッドをリフレッシュ
                            this.safetyEmployeeGrid.refresh();
                            // 登録ダイアログを閉じる
                            dialog.hide();
                            // 完了ダイアログを表示
                            chain.infoComplete();
                        }), function(err) {
                            if (err.response.status === 400) {
                                chain.hide();
                                InfoDialog.show('登録エラー', '入力された職員番号は既に存在しています');
                            } else {
                                // 失敗時
                                chain.infoError(err);
                            }
                        });
                        Loader.wait(promise);
                    });
                }));
            },

            /**
             * 職員情報タブ用
             * 詳細ダイアログのformが投稿された際の動作を設定する。
             */
            initEmployeeDetailDialog: function() {
                // 詳細ダイアログの最初の子要素が詳細画面
                var dialog = this.safetyEmployeeDetailDialog;
                var page = dialog.getChildren()[0];
                // 更新ボタンが押された際の動作
                page.on('update', lang.hitch(this, function(evt) {
                    // 職員コードをformに追加
                    evt.value.employeeCd = this.empCd;
                    // 更新確認ダイアログを表示
                    this.chain.confirmPut(function(chain) {
                        // OKが押された場合
                        // 追加処理と共にローディング表示
                        var promise = this.empStore.put(evt.value).then(lang.hitch(this, function() {
                            // グリッドをリフレッシュ
                            this.safetyEmployeeGrid.refresh();
                            // 成功時（POST結果はグリッドが自動的に反映）
                            // 登録ダイアログを閉じる
                            dialog.hide();
                            // 完了ダイアログを表示
                            chain.infoComplete();
                        
                        }), function(err) {
                            // 失敗時
                            chain.infoError(err);
                        });
                        Loader.wait(promise);
                   });
                }));
                // 削除ボタンが押された際の動作
                page.on('delete', lang.hitch(this, function() {
                    // 削除確認ダイアログを表示
                    this.chain.confirmDel(lang.hitch(this, function(chain) {
                      // OKが押された場合
                      // 追加処理と共にローディング表示
                      Loader.wait(this.empStore.remove(this.empCd)).then(function() {
                        // 成功時（結果はグリッドが自動的に反映）
                        // ダイアログを閉じる
                        dialog.hide();
                        // 完了ダイアログを表示
                        chain.infoComplete();
                      }, function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                  }));
                }));
            },

            /**
             * 職員情報
             * ファイルアプロードダイアログ初期化処理
             */
            initFileUploadDialog: function() {
                // 詳細ダイアログの最初の子要素が詳細画面
                var dialog = this.fileUploadDialog;
                var page = dialog.getChildren()[0];
                // 更新ボタンが押された際の動作
                page.on('upload', lang.hitch(this, function(evt) {
                    console.log(evt.attachFile._files);
                    //ファイルが空の場合は処理を中断
                    if(evt.attachFile._files.length === 0) {
                        return;
                    }

                    var url = '/api/safety/employees/upload';
                    // ファイルをサーバーにPOSTする
                    var promise = iframe.post(url, {
                        form: evt.formId,
                        handleAs: 'json',
                        // 60分間はタイムアウトしないようにする
                        timeout: 60*60*1000
                    }).then(lang.hitch(this, function(data) {
                        // ファイルアップローダー部品をリセットする
                        evt.attachFile.reset();
                        // ダイアログを非表示
                        dialog.hide();
                        // 結果表示用のテンプレートを生成
                        var template = new dtl.Template(resultTemplate);
                        var context = new Context({
                            total: '' + data.total,
                            success: '' + data.success,
                            skipped: data.skipped.length === 0 ? 'なし' : '' + data.skipped,
                            deleted: '' + data.deleted,
                            headers: data.headers === 0 ? 'なし' : '' + data.headers,
                            message: data.message
                        });
                        // 結果をダイアログで表示
                        InfoDialog.show(template.render(context));
                        // 入力値が正常ならグリッドの検索条件を更新
                        this.updateEmpGridQuery(this.empForm.get('value'));
                    }), lang.hitch(this, function(error) {
                        console.log(error);
                        evt.attachFile.reset();
                        this.chain.infoError(error);
                    }));
                    // ローダーの表示
                    Loader.wait(promise);
                }));
            },

            /**
             * 職員管理タブ用
             * グリッドを初期化する。
             */
            initSafetyEmployeeGrid: function() {
                this.safetyEmployeeGrid.set('collection',this.empStore.filter());
                // グリッドの一覧ボタンクリック時の動作を設定する
                // helper.buttonColumnでフィールド名に指定した'list'と'ButtonClick'の結合がボタンクリック時のイベント名
                this.safetyEmployeeGrid.on('userDetailButtonClick', lang.hitch(this, function(evt){
                    // 職員情報詳細ダイアログを表示する
                    this.showEmployeeDetailDialog(evt.item);
                }));
            },

            /**
             * 検索条件に合致する職員情報を表示する。
             */
            onEmpSearch: function() {
                try {
                    if (this.empForm.validate()) {
                        // 入力値が正常ならグリッドの検索条件を更新
                        this.updateEmpGridQuery(this.empForm.get('value'));
                    }
                } catch (e) {
                    console.error(e);
                }
                return false;
            },

            /**
             * 職員管理タブ用の検索
             */
            updateEmpGridQuery: function(value) {
                // 入力値を元にグリッド用フィルターを作成
                var empFilter = new this.empStore.Filter();
                // グループ
                if (value.distGroup) {
                    empFilter = empFilter.eq('divisionCd',value.distGroup);
                }
                // 職員番号
                if (value.employeeCd) {
                    empFilter = empFilter.eq('employeeCd',value.employeeCd);
                }
                // 氏名
                if (value.name) {
                    empFilter = empFilter.eq('name',value.name);
                }
                // filterに対応するcollectionを取得
                var collection = this.empStore.filter(empFilter);
                // collectionをグリッドにセットする（サーバーにリクエストされる）
                this.safetyEmployeeGrid.set('collection', collection);
            },

            /**
             * 職員管理タブ新規登録ダイアログを表示する。
             */
            showEmployeeRegisterDialog: function() {
                var page = this.safetyEmployeeRegisterDialog.getChildren()[0];
                page.form.reset();
                this.safetyEmployeeRegisterDialog.show();
            },

            /**
             * 職員管理タブ詳細ダイアログを表示する。
             */
            showEmployeeDetailDialog: function(item) {
                var page = this.safetyEmployeeDetailDialog.getChildren()[0];
                var self = this;

                var url = '/api/safety/employees/' + item.employeeCd;
                // IDをキーにグループ情報を再度サーバから取得し、ダイアログのformに設定
                Requester.get(url).then(function(data) {
                    // formの画面項目にサーバから取得したデータを設定する。
                    page.form.reset();
                    page.form.set('value', data);
                    // ページを初期化
                    page.initPage(data);
                    // 部署欄にサーバから取得した課コードを設定する。
                    page.divisionCd.set('value', data.divisionCd);
                    // メールアドレスの登録状況欄
                    self._setMailStatus(data.emailAddressStatus01, page.emailAddressStatus01);
                    self._setMailStatus(data.emailAddressStatus02, page.emailAddressStatus02);
                    }, lang.hitch(this, function(error) {
                        // 取得に失敗した場合は、ログ出力とメッセージのダイアログ表示を行う。
                        console.log(error);
                        this.chain.info('グループ情報の取得に失敗しました。', 'エラー');
                    })
                );

                // 更新・削除に備え職員コードを保持
                this.empCd = item.employeeCd;
                // 役割セレクトボックスの表示・非表示をコントロール
                this.divisionType(page);
                // 詳細ダイアログを表示する
                this.safetyEmployeeDetailDialog.show();
            },

            /**
             * 職員管理ファイルアップロードダイアログを表示する。
             */
            showFileUploadDialog: function() {
                this.fileUploadDialog.show();
            },

            /**
             * @param statusFlg テーブルから取得したメール登録ステータス
             * @param statusNode ダイアログの登録ステータス表示欄
             * テーブルから取得した値によって、ダイアログ内の登録ステータス欄の表示を切り替える。
             */
            _setMailStatus: function(statusFlg, statusNode) {
                switch(statusFlg) {
                case '01':
                    domStyle.set(statusNode, {
                        'backgroundColor': '#feffcd',
                        'font-size': 'medium'
                    });
                    statusNode.innerHTML = '本登録待ち';
                    break;
                case '02':
                    domStyle.set(statusNode, {
                        'backgroundColor': '#D6F3CE',
                        'font-size': 'medium'
                    });
                    statusNode.innerHTML = '正常';
                    break;
                case '03':
                    domStyle.set(statusNode, {
                        'backgroundColor': '#fbd4cf',
                        'font-size': 'medium'
                    });
                    statusNode.innerHTML = '異常';
                    break;
                default:
                    domStyle.set(statusNode, {
                        'backgroundColor': 'none',
                        'font-size': 'medium'
                    });
                    statusNode.innerHTML = '';
                }

            },

            /**
             * 役割セレクトボックスの表示・非表示、valueのコントロール
             */
            divisionType: function(page) {
                //TODO 安否確認用のセッション情報(UserInfo)を作成し、役割を取得・判定する仕組みに変える。
//                if(!UserInfo.getAcl().CHIEF_ADMIN){
//                    //一般職員閲覧時は固定値にして役割セレクトボックスを非表示にする
//                    domStyle.set(page.roleTypeDiv, 'display', 'none');
//                    page.hiddenRoleType.set('value','03');
//                }else if(!UserInfo.getAcl().ADMIN){
//                    //管理職員は固定値にして変更不可(disabled)にする
//                    page.roleType.set('value','02');
//                    domStyle.set(page.roleType, 'disabled', 'disabled');
//                    //disabledでは送信できないため、hidden項目に同様の値をsetする。
//                    page.hiddenRoleType.set('value','02');
//                }
                //FIXME
                console.log('役割セレクトボックスはシステム管理者以外非表示or固定値' + page);
            },

            /**
             * 職員管理タブ詳細ダイアログを表示する。
             */
            showGroupDetailDialog: function(item) {
                var page = this.safetyGroupDetailDialog.getChildren()[0];
                page.form.set('value',item);
                this.safetyGroupDetailDialog.show();
            },

            /**
             * ウィンドウの幅に合わせてBorderContainerをリサイズし、タブ内のレイアウトを整理する。
             * (startup同様、画面描画時に呼ばれる)
             * @param changeSize
             * @param resultSize
             */
            resize: function(changeSize, resultSize) {
                // 中のContentPaneも追随してリサイズするため、レイアウト崩れを防止できる。
                this.borderContainer.resize(changeSize, resultSize);
            },

            /**
             * 帳票出力
             */
            outputListExcel: function() {
                
                var url = '/api/safety/employees/employees.csv';
                var msg = '一覧帳票を出力します。<br>よろしいですか？';
    
                // 確認ダイアログを表示
                this.chain.confirm(msg, function(chain) {
                    // 確認ダイアログでOKを押した場合
                    chain.hide();
                    // URLを遷移する
                    window.location.href = url;
                });
            }
        });
    });
