/**
 * 物資選択用入力パーツ
 * @module app/view/form/ItemSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/view/form/TreeSelector',
    'app/model/Item'
], function(module, declare, TreeSelector, Item) {
    /**
     * 物資選択用パーツ。
     * @class ItemSelector
     * @extends module:idis/view/form/TreeSelector~TreeSelector
     */
    return declare(module.id.replace(/\//g, '.'), TreeSelector,
        /** @lends module:app/view/form/ItemSelector~ItemSelector# */ {
        // 選択ダイアログのタイトル
        title: '物資',

        // ツリー・モデル
        model: Item
        /*
        selectedItemValue: null,
        
        applySelectedItem: function() {
            this.inherited(arguments);
            this.selectedItemValue = Item._getValueAttr();
        }
        */
    });
});
