/**
 * 所属選択入力パーツ
 * @module idis/view/form/DeptSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/string',
    'dijit/form/TextBox',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest',
    'app/view/form/DeptSelector'
], function (module, declare, lang, dStr, TextBox, CacheStoreModel, CacheTreeRest, DeptSelector) {
    /**
     * 所属選択パーツ。
     * @class DistrictSelector
     * @extends module:idis/view/form/TreeSelector~TreeSelector
     */
    return declare(module.id.replace(/\//g, '.'), DeptSelector,
        /** @lends module:idis/view/tree/DistrictSelector~DistrictSelector# */ {
            // 選択ダイアログのタイトル
            title: '管理所属',

            // ツリー・モデル
            model: null,

            // ページ初期表示にmodelがnullだとエラーが出るためmodelを生成
            constructor: function () {
                this._initModel();
            },

            _initModel: function () {
                this.model = new CacheStoreModel({
                    store: new CacheTreeRest({
                        target: '/api/facilityMaster/dept4Tree'
                    })
                });
            },

        });
});
