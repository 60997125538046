/**
 * グループ管理新規登録ダイアログ。
 * @module app/safety/employee/SafetyEmployeeRegisterDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/text!./templates/SafetyEmployeeRegisterDialog.html',
    'dojox/validate/web',
    'idis/view/page/_PageBase',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/InfoDialog',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/Textarea',
    'dijit/form/TextBox',
    'dijit/form/ValidationTextBox',
    'dijit/form/CheckBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'app/safety/auth/view/form/AclButton',
    'idis/view/form/Button',
    'app/view/form/ItemSelector',
    'app/view/form/EmployeeDivisionSelector'
], function(module, declare, lang, domStyle, template, validate, _PageBase, DialogChain, InfoDialog) {
    /**
     * グループ管理新規登録ダイアログ。
     * @class SafetyEmployeeRegisterDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), [_PageBase],
                   /** @lends module:app/view/page/SafetyEmployeeRegisterDialog~SafetyEmployeeRegisterDialog# */ {
        // テンプレート文字列
        templateString: template,

        baseClass: 'idis-Page--safety-dialog',

        buildRendering: function() {
            this.inherited(arguments);
            this.emailAddress01.set('disabled', true);
            this.emailAddress02.set('disabled', true);
        },

        /**
         * 新規登録ボタンが押下された場合の処理。
         */
        onSubmit: function() {
            try {
                if(this.validateFormData()) {
                    var value = this.form.get('value');
                    delete value.confirmPassword;
                    // 実際の登録処理はSafetyAdminPage側でupdateイベントを監視しているメソッドが実施
                    this.emit('register', {value: value});
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * 入力された値の妥当性をチェックする。
         */
        validateFormData: function() {
            var formData = this.form.get('value');

            // newEmployeeCd
            if (formData.newEmployeeCd === '') {
                InfoDialog.show('入力チェック', '職員番号が入力されていません');
                return false;
            }

            if (!/^[0-9a-zA-Z]*$/.test(formData.newEmployeeCd)) {
                InfoDialog.show('入力チェックエラー', '職員番号は半角英数字で入力してください');
                return false;
            }

            // emailAddress01
            // if (formData.emailAddress01 === '') {
            //     InfoDialog.show('入力チェック', 'メールアドレスが入力されていません');
            //     return false;
            // }

            if (formData.emailAddress01 &&
                !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(formData.emailAddress01)) {
                InfoDialog.show('入力チェックエラー', '不正な形式のメールアドレスです<br>' + formData.emailAddress01);
                return false;
            }

            // emailAddress02
            if (formData.emailAddress02 &&
                !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(formData.emailAddress02)) {
                InfoDialog.show('入力チェックエラー', '不正な形式のメールアドレスです<br>' + formData.emailAddress02);
                return false;
            }

            // department
            if (formData.department === '') {
                InfoDialog.show('入力チェック', '部署が選択されていません');
                return false;
            }
            // groupName
            if (formData.groupName === '') {
                InfoDialog.show('入力チェック', 'グループ名が入力されていません');
                return false;
            }
            // employeeCd
            if (formData.employeeCd === '') {
                InfoDialog.show('入力チェック', '職員番号が入力されていません');
                return false;
            }
            if (formData.name === '') {
                InfoDialog.show('入力チェック', '氏名(漢字)が入力されていません');
                return false;
            }
            if (formData.roleType === '') {
                InfoDialog.show('入力チェック', '役割が選択されていません');
                return false;
            }
            // password
            if (formData.password !== formData.confirmPassword) {
                InfoDialog.show('入力チェック', '入力されたパスワードが一致しません');
                return false;
            }
            if (formData.password !== '' && !this.isValidPassword(formData.password)) {
                InfoDialog.show('入力チェック', '入力されたパスワードが規則を満たしません。半角の英数および記号で８文字以上、２０文字以内で入力ください');
                return false;
            }
            // divisionCd
            if (formData.divisionCd === '') {
                InfoDialog.show('入力チェック', '部署名が選択されていません');
                return false;
            }
            return true;
        },

        /**
         * 入力されたパスワードの妥当性をチェックする。
         */
        isValidPassword: function(password) {
            // 長さ
            if (password.length < 8 || password.length > 20) {
                return false;
            }

            // アルファベットが含まれているか
            if (/[^\x21-\x7e]/.test(password)) {
                return false;
            }

            return true;
        }
    });
});
