/**
 * 定時集計 被害内訳一覧 閲覧画面用モジュール。
 * @module app/scheduledReport/ScheduledReportDamageListViewPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/text!./templates/ScheduledReportDamageListViewPage.html',
    'idis/control/Router',
    'app/scheduledReport/ScheduledReportDamageListPage',
    // 以下、変数として受け取らないモジュール
], function (module, declare, template, Router, ScheduledReportDamageListPage) {

    /**
     * 定時集計 被害内訳一覧 閲覧ページ
     * @class ScheduledReportDamageListViewPage
     * @extends module:app/scheduledReport/ScheduledReportDamageListPage#~ScheduledReportDamageListPage
     */
    return declare(module.id.replace(/\//g, '.'), ScheduledReportDamageListPage,
        /** @lends module:app/scheduledReport/ScheduledReportDamageListViewPage# */ {
            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--scheduledreport',

            // DOMノードを生成するためのメソッド
            buildRendering: function () {
                this.inherited(arguments);
            },

            // ページ初期表示にmodelがnullだとエラーが出るためmodelを生成
            constructor: function () {
            },

            startup: function () {
                this.inherited(arguments);
            },

            /**
             * 定時集計詳細を表示
             */
            onDetailButtonClick: function (item) {
                var param = {
                    damageReportId: item.damageReportId,
                    scheduledReportId: this._scheduledReportId
                };
                if (this._isMunic) {
                    param.isMunic = this._isMunic;
                }
                if (this._admNum) {
                    param.admNum = this._admNum;
                }
                // 被害一覧画面へ遷移
                Router.moveTo('scheduledReport/damage/detail/view', param);
            },

            /**
             * 被害一覧を表示
             */
            onDamageReportPageLinkClick: function (evt) {
                //検索条件の保管
                this.setFilterStore();
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();

                // 被害一覧画面へ遷移
                Router.moveTo('report/view');
            },
            /**
             * 定時集計一覧を表示
             */
            onListPageLinkClick: function (evt) {
                //検索条件の保管
                this.setFilterStore();
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                // 被害一覧画面へ遷移
                Router.moveTo('scheduledReport/view');
            },
            /**
             * 定時集計 詳細を表示
             */
            onDetailPageLinkClick: function (evt) {
                //検索条件の保管
                this.setFilterStore();
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                // 詳細画面に戻る
                this.leave();

            },
            /**
             * 定時集計 各区一覧
             */
            onMunicPageLinkClick: function (evt) {
                //検索条件の保管
                this.setFilterStore();
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();

                // 各区の画面へ遷移
                Router.moveTo('scheduledReport/munic/view', {
                    admNum: this._admNum
                });
            },
            /**
             * 定時集計 各区詳細
             */
            onMunicDetailLinkClick: function (evt) {
                //検索条件の保管
                this.setFilterStore();
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                // 詳細画面に戻る
                this.leave();
            }
        });
});

