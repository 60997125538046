/**
 * 定時集計 被害内訳詳細 閲覧画面用モジュール。
 * @module app/scheduledReport/ScheduledReportDamageDetailViewPage
 */
define([
	'module',
	'dojo/_base/declare',
	'dojo/text!./templates/ScheduledReportDamageDetailViewPage.html',
	'idis/control/Router',
	'app/scheduledReport/ScheduledReportDamageDetailPage',
	// 以下、変数として受け取らないモジュール
], function (module, declare, template, Router, ScheduledReportDamageDetailPage) {

	/**
	 * 定時集計 被害内訳詳細 閲覧ページ
	 * @class ScheduledReportDamageDetailViewPage
	 * @extends module:app/scheduledReport/ScheduledReportDamageDetailPage#~ScheduledReportDamageDetailPage
	 */
	return declare(module.id.replace(/\//g, '.'), ScheduledReportDamageDetailPage,
        /** @lends module:app/scheduledReport/ScheduledReportDamageDetailViewPage# */ {
			// テンプレート文字列
			templateString: template,

			// ルート要素に付与されるCSS
			baseClass: 'idis-Page idis-Page--scheduledreport',

			// DOMノードを生成するためのメソッド
			buildRendering: function () {
				this.inherited(arguments);
			},

			// ページ初期表示にmodelがnullだとエラーが出るためmodelを生成
			constructor: function () {
			},

			startup: function () {
				this.inherited(arguments);
			},

			/**
			 * 被害一覧を表示
			 */
			onDamageReportPageLinkClick: function (evt) {
				// ブラウザーの遷移処理をキャンセル
				evt.preventDefault();
				// 被害一覧画面へ遷移
				Router.moveTo('report/view');
			},

			/**
			 * 定時集計一覧を表示
			 */
			onListPageLinkClick: function (evt) {
				// ブラウザーの遷移処理をキャンセル
				evt.preventDefault();
				// 被害一覧画面へ遷移
				Router.moveTo('scheduledReport/view');
			},

			/**
			 * 定時集計 詳細を表示
			 */
			onDetailPageLinkClick: function (evt) {
				// ブラウザーの遷移処理をキャンセル
				evt.preventDefault();
				// 詳細画面に戻る
				this.leave();

			},
			/**
			 * 定時集計 各区一覧
			 */
			onMunicPageLinkClick: function (evt) {
				// ブラウザーの遷移処理をキャンセル
				evt.preventDefault();

				// 各区の画面へ遷移
				Router.moveTo('scheduledReport/munic/view', {
					admNum: this._admNum
				});
			},

			/**
			 * 定時集計 各区詳細
			 */
			onMunicDetailLinkClick: function (evt) {
				// ブラウザーの遷移処理をキャンセル
				evt.preventDefault();
				// 詳細画面に戻る

				// 被害一覧画面へ遷移
				Router.moveTo('scheduledReport/munic/detail/view', {
					scheduledReportId: this._scheduledReportId,
					admNum: this._admNum
				});
			},
			/**
			 * 定時集計 被害一覧を表示
			 */
			onDamagePageLinkClick: function (evt) {
				// ブラウザーの遷移処理をキャンセル
				evt.preventDefault();
				// 被害一覧画面へ遷移
				this.leave();
			},

		});
});

