/**
 * 災害選択用入力パーツ
 * @module app/view/form/GroupDivisionSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/dom-style',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest',
    'idis/view/form/TreeChecker'
], function(module, declare, domStyle, CacheStoreModel, CacheTreeRest, TreeChecker) {
    /**
     * 部局・課選択用パーツ。
     * @class DivisionSelector
     * @extends module:idis/view/form/TreeSelector~TreeSelector
     */
    return declare(module.id.replace(/\//g, '.'), TreeChecker,
        /** @lends module:app/view/tree/GroupDivisionSelector~GroupDivisionSelector# */ {
        // 選択ダイアログのタイトル
        title: '部局・課選択',

        // ツリー・モデル
        model: null,

        constructor: function() {
            // 設置されるたびにモデルを新規生成する
            this.model = new CacheStoreModel({
                store: new CacheTreeRest({
                    target: '/api/safety/mails/divisions4tree'
                })
            });
        },
        
        /**
         * 選択した値の設定
         */
        _setValueAttr: function() {
            // 継承した親クラスの処理
            this.inherited(arguments);
            // 以下、当モジュール固有の処理
            // 選択結果が20文字を超えた場合、・・・(三点リーダ)で省略する
            domStyle.set(this.status, {
                'max-width': '20em',
                'white-space': 'nowrap',
                'overflow': 'hidden',
                'text-overflow': 'ellipsis'
            });
        }
    });
});
