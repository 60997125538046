/**
 * 警報・注意報のカラム定義一覧。
 * @module app/timeline/AlertCell
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/topic',
    // 'dojo/_base/array',
    'idis/util/DateUtils',
    'idis/view/grid/helper',
    'dojo/text!./templates/AlertCell.html',
    'idis/view/_IdisWidgetBase',
    'idis/view/Loader',
    // 以下、変数で受けないモジュール
    'dijit/form/CheckBox',
    'dijit/form/ValidationTextBox',
    'idis/view/form/DateTimeInput',
    'idis/view/form/Button',
    'dojox/lang/functional/fold'
], function(module, declare, lang, domStyle, topic, DateUtils, helper, template, WidgetBase, Loader) {

    return declare(module.id.replace(/\//g, '.'), WidgetBase,
        /** @lends app/timeline/AlertCell~AlertCell# */ {
        // テンプレート文字列
        templateString: template,

        baseClass: 'timeline-alertCell',

        item: null,

        store: null,

        buildRendering: function(){
            this.inherited(arguments);

            this.setCellStatus(this.item.noActionFlg);

            // if(this.item.reporter && this.item.completeTimestamp){
            if(this.item.completeTimestamp){
                this.alertFlg.set('disabled', true);
            }
            if (this.item.disabled) {
                this.alertFlg.set('disabled',true);
            }
        },

        constructor: function(){
            this.inherited(arguments);
            // console.log('constructor');
        },

        /* アラート欄ではなく、対象外欄となったので、対象外フラグを見る。
         * 対象外フラグが立っている場合はチェック、立っていない場合はチェックをしないこと 
         */
        setCellStatus: function(noActionFlg){
            if(noActionFlg === '1'){
                this.alertFlg.set('checked', true);
                domStyle.set(this.label, 'display', 'none');
            } else {
                this.alertFlg.set('checked', false);
                domStyle.set(this.label, 'display', '');
            }
        },

        /**
         * 画面生成完了
         */
        startup: function() {
            this.inherited(arguments);
        },

        changeAlertFlg: function(){
            console.log('changeAlertFlg');
            var data = {
                timelineDetailId: this.item.timelineDetailId,
                //reporter: this.item.reporter,
                completeTimestamp: this.item.completeTimestamp,
                alertFlg: this.alertFlg.get('value') ? '1' : '0',
                noActionFlg: this.alertFlg.get('value') ? '1' : '0'
            };
            var promise = this.store.put(data);
            return Loader.wait(promise).then(lang.hitch(this, function(){
                console.log('アラートフラグを更新できました');
                topic.publish(module.id + '::updated');

            }, function(err) {
                console.error('エラーが発生しました', err);
                topic.publish(module.id + '::updated');
                // this.setCellStatus(this.item.alertFlg);
            }));
        }
    });
});
