/**
 * <潮位実況情報情報一覧画面（10分)>
 *
 * @module app/tide/grid/TideLevelObservationPageGrid
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'app/view/grid/CommonColumn',
    './TideLevelObservationCommonColumns'
    // 以下、変数で受けないモジュール
], function(module, declare, IdisGrid, helper, CommonColumn, TideCommonColumns) {


    /**
     * 潮位実況情報情報一覧画面（10分)
     *
     * @class TideLevelObservationPageGrid
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), IdisGrid,
        /** @lends module:app/tide/TideLevelObservationPageGrid~TideLevelObservationPageGrid# */ {
        minRowsPerPage: -1,
        columns: [
			helper.column('regionName', '地域'),
            CommonColumn.municipalityCd,
            CommonColumn.observatoryName,
            helper.buttonColumn('detail', '詳細'),
            TideCommonColumns.tideLevel,
            TideCommonColumns.tideLevelTp,
            TideCommonColumns.tideLevelAstronomical,
            helper.column('limitLevel', '基準値', {
                children : [
                    // CommonColumn.threshold('tideLevelSpecialAlert', '特別警戒潮位\n[m]', 2),
                    CommonColumn.threshold('tideLevelAlert', '警戒潮位[m]', 2),
                    CommonColumn.threshold('tideLevelReport', '通報潮位[m]', 2)
                ]
            // }),
            // helper.column('windDirection', '風向', {
            //     formatter: function(value, item) {
            //         return CommonColumn.formatObservationData(item, 'windDirection');
            //     },
            //     className : function(item) {
            //         // ヘッダーの場合はclassNameを返さない
            //         if (!item) {
            //             return;
            //         }
            //         return CommonColumn.getClassNameExcess(item, 'windDirection');
            //     }
            // }),
            // helper.column('windSpeed', '風速[m/s]', {
            //     formatter: function(value, item) {
            //         return CommonColumn.formatObservationData(item, 'windSpeed');
            //     },
            //     className : function(item) {
            //         // ヘッダーの場合はclassNameを返さない
            //         if (!item) {
            //             return;
            //         }
            //         // 0:通常   1:注意   2:警報
            //         var excess = 0;
            //         if(item.windSpeed>=10){
            //             excess = item.windSpeed<20 ? 1 : 2;
            //         }
            //         return CommonColumn.getClassNameExcess(item, 'windSpeed', excess);
            //     }
            // }),
            // helper.column('pressure', '気圧\n[hPa]', {
            //     formatter: function(value, item) {
            //         return CommonColumn.formatObservationData(item, 'pressure');
            //     },
            //     className : function(item) {
            //         // ヘッダーの場合はclassNameを返さない
            //         if (!item) {
            //             return;
            //         }
            //         return CommonColumn.getClassNameExcess(item, 'pressure');
            //     }
            // }),
            // helper.column('maxWaveHeight', '最大波高\n[m]', {
            //     formatter: function(value, item) {
            //         return CommonColumn.formatObservationData(item, 'maxWaveHeight');
            //     },
            //     className : function(item) {
            //         // ヘッダーの場合はclassNameを返さない
            //         if (!item) {
            //             return;
            //         }
            //         return CommonColumn.getClassNameExcess(item, 'maxWaveHeight');
            //     }
            // }),
            // helper.column('maxWavePeriod', '最大波周期\n[sec]', {
            //     formatter: function(value, item) {
            //         return CommonColumn.formatObservationData(item, 'maxWavePeriod');
            //     },
            //     className : function(item) {
            //         // ヘッダーの場合はclassNameを返さない
            //         if (!item) {
            //             return;
            //         }
            //         return CommonColumn.getClassNameExcess(item, 'maxWavePeriod');
            //     }
            // }),
            // helper.column('averageWaveHeight', '平均波高\n[m]', {
            //     formatter: function(value, item) {
            //         return CommonColumn.formatObservationData(item, 'averageWaveHeight');
            //     },
            //     className : function(item) {
            //         // ヘッダーの場合はclassNameを返さない
            //         if (!item) {
            //             return;
            //         }
            //         return CommonColumn.getClassNameExcess(item, 'averageWaveHeight');
            //     }
            // }),
            // helper.column('significantWaveHeight', '有義波高\n[m]', {
            //     formatter: function(value, item) {
            //         return CommonColumn.formatObservationData(item, 'significantWaveHeight');
            //     },
            //     className : function(item) {
            //         // ヘッダーの場合はclassNameを返さない
            //         if (!item) {
            //             return;
            //         }
            //         return CommonColumn.getClassNameExcess(item, 'significantWaveHeight');
            //     }
            // }),
            // helper.column('significantWavePeriod', '有義波周期\n[sec]', {
            //     formatter: function(value, item) {
            //         return CommonColumn.formatObservationData(item, 'significantWavePeriod');
            //     },
            //     className : function(item) {
            //         // ヘッダーの場合はclassNameを返さない
            //         if (!item) {
            //             return;
            //         }
            //         return CommonColumn.getClassNameExcess(item, 'significantWavePeriod');
            //     }
            // }),
            // helper.column('outerPortTideLevel', '外港潮位\n[m]', {
            //     formatter: function(value, item) {
            //         return CommonColumn.formatObservationData(item, 'outerPortTideLevel');
            //     },
            //     className : function(item) {
            //         // ヘッダーの場合はclassNameを返さない
            //         if (!item) {
            //             return;
            //         }
            //         return CommonColumn.getClassNameExcess(item, 'outerPortTideLevel');
            //     }
            }),
            CommonColumn.managerCd
        ]
    });
});
