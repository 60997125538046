/**
 * テンプレート選択画面用モジュール。
 * @module app/broadnotify/BroadnotifyTemplateSelectorDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/BroadnotifyTemplateSelectorDialog.html',
    'idis/store/IdisRest',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/ConfirmDialog',
    'idis/view/page/_PageBase',
    //'idis/store/withEmptyOption',
    //'app/model/MailTemplateDivs',
    // 以下、変数として受け取らないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/form/TextBox',
    'dijit/form/Select',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    './BroadnotifyTemplateSelectorGrid'
], function(module, declare, lang, template, IdisRest, DialogChain, ConfirmDialog, _PageBase) {
    /**
     * テンプレート選択画面のイベント
     * @class DeliveredMailGridPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/broadnotify/BroadnotifyTemplateSelectorDialog~BroadnotifyTemplateSelectorDialog# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--broadnotifyTemplateDialog',

        /**
         * データ格納用オブジェクト
         * @type {module:dstore/Store}
         */
        store: null,

        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'broadnotifyTemplateId',
                target: '/api/broadnotify/templates'
            });
            // ダイアログ連鎖を登録
            // 引数に与えたウィジェットのthis.ownを呼び出し、
            // ウィジェットが破棄された際にダイアログ連鎖が破棄されるようになる
            this.chain = DialogChain.get(this);
        },

        // DOMノードを生成するためのメソッド
        buildRendering: function() {
            this.inherited(arguments);
        },

        startup: function() {
            this.inherited(arguments);

            // グリッドを初期化する
            this.initGrid();
        },

        initDialog: function() {

            this.updateGridQuery(this.form.get('value'));
        },

        /**
         * グリッドの検索条件を指定された値で更新する。
         * @param {Object} value name属性と値のマッピング
         */
        updateGridQuery: function(value) {
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();
            // 件名
            if (value.searchSubject) {
                filter = filter.eq('searchSubject', value.searchSubject);
            }
            // 種別
            if (value.notifyType) {
                filter = filter.eq('notifyType', value.notifyType);
            }
            // 管理者
            if (value.admin) {
                filter = filter.eq('admin', value.admin);
            }
            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);
            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.templateGrid.set('collection', collection);
        },

        /**
         * 検索ボタンが押されたときに呼び出される。
         * テンプレートHTML内のFormウィジェットに対するdata-dojo-attach-eventでこのイベントを紐付けている。
         * また、同Formウィジェットに対しdata-dojo-attach-pointを指定し、this.formからアクセス出来るようにしている。
         */
        onClick: function() {
            try {
                if (this.form.isValid()) {
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery(this.form.get('value'));
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * グリッドを初期化する。
         */
        initGrid: function() {
            // 全件表示用のcollectionをグリッドにセットする
            this.templateGrid.set('collection', this.store.filter());
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.templateGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                this.select(evt.item);
            }));

        },

        select: function(object) {
            
            this._broadnotifyTemplateId = object.broadnotifyTemplateId;
            if(this._broadnotifyTemplateId !== null){
                this.emit('select', {data: object});
            } else {
                // 0件選択時
                var confirmDialog = new ConfirmDialog({
                    title: '確認',
                    content: 'テンプレートが選択されていません。',
                    hideOnOK: false,
                    hideOnCancel: true,
                    showCancel: false,
                    // ダイアログでOKボタンが押された場合の動作
                    onOK: function() {
                        // ダイアログを非表示にする
                        this.hide();
                    }
                });

                // 確認ダイアログを表示
                confirmDialog.show();
            }
        }
    });
});

