/**
 * 利用者更新用ダイアログモジュール。
 * @module app/broadnotify/TerminalDetailDialog
 */
define([
    'module',
    'leaflet',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/text!./templates/TerminalDetailDialog.html',
    './_TerminalDialogBase',
    'idis/view/dialog/InfoDialog',
    'idis/model/UserInfo',
    'idis/store/IdisRest',
    'idis/view/Loader',
    'idis/service/Requester',
    'app/config',
    'idis/service/GeoService',
    'app/model/Region',
    // 以下、変数で受けないモジュール
    'app/view/form/BroadnotifyUserMultiSelector'
], function(module, leaflet, declare, lang, domStyle, template, _TerminalDialogBase, InfoDialog,
    UserInfo, IdisRest, Loader, Requester) {

    return declare(module.id.replace(/\//g, '.'), _TerminalDialogBase,
    {
        // テンプレート文字列
        templateString: template,

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        // バージョン
        _version: null,

        /**
         * フォームを初期化する。
         */
        initForm: function (object) {
            this._terminalId = object.broadnotifyTerminalId;
            var self = this;
            Loader.wait(Requester.get('/api/broadnotify/terminal/' + this._terminalId)
            ).then(function (data) {
                var item = data;
                self.terminalUserId.set('value', item.terminalUserId);
            });

        },

        /**
         * ユーザ情報を更新する。
         */
        onSubmit: function() {
            try {
                console.debug('[更新]ボタンがクリックされました。');

                var value = this.form.get('value');
                // フォームのバリデーションを実施
                if (!this.form.validate()) {
                    return false;
                }

                // 固有のバリデーションを実施
                if (!this.validate(value)) {
                    return false;
                }
                if(value.terminalUserId === '' || !this.checkUser(value)) {
                    if (!this.infoDialog) {
                        this.infoDialog = new InfoDialog({
                            title : 'エラー',
                            content : 'ユーザが正しく選択されていません'
                        });
                    }
                    this.infoDialog.show();
                    this.infoDialog = null;
                    return false;
                }

                value.terminalId = this._terminalId;

                this.emit('update', {value: value});
                } catch (e) {
                console.error(e);
            }
            return false;
        },

        checkUser:function(value){
            var result = false;
            this.terminalUserId.model.store.get(value.terminalUserId).then(
                lang.hitch(this, function(item){
                    // item.typeが'MUser'の場合はツリーの子要素であるためユーザとして選択可能にする
                    if(item.type === 'MUser') {
                        result = true;
                    } else {
                        result = false;
                    }
                }
            ));
            return result;
        },

        onDelete:function(){
            console.debug('[削除]ボタンがクリックされました。');
            var value = this.form.get('value');
            value.terminalId = this._terminalId;
            this.emit('remove', {value: value});
        },

        /**
         * ダイアログを初期化する。（更新時）
         */
        initDialog: function(object) {
            this.reset();

            this.initForm(object);
        },
    });
});
