/**
 * お知らせ詳細ダイアログ内用。
 * @module app/view/NoticeDetailPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/text!./templates/NoticeDetailPage.html',
    'idis/view/page/_PageBase',
    './_LinkInsertMixin',
    // 以下、変数から参照されないモジュール
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/SimpleTextarea',
    'dijit/form/ValidationTextBox',
    'idis/view/form/AclButton',
    'idis/view/form/CheckGroup',
    'idis/view/form/DateTimeInput',
    './form/NoticeTypeSelect'
], function(module, declare, template, _PageBase, _LinkInsertMixin) {
    /**
     * お知らせ詳細ダイアログ内の画面。
     * @class NoticeDetailPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), [_PageBase, _LinkInsertMixin],
        /** @lends module:idis/view/page/NoticeDetailPage~NoticeDetailPage# */ {
        // テンプレート文字列
        templateString: template,

        /***
         * 詳細フォームがsubmitされた際に呼ばれる。
         */
        onSubmit: function() {
            try {
                if (this.form.validate()) {
                    // 入力チェック成功時
                    var value = this.form.get('value');
                    // 公開フラグはチェック状態（CheckGroupの配列長が1）かどうかで判断
                    value.pubFlg = !!value.pubFlg.length;
                    // 実際の更新処理はNoticePage側でupdateイベントを監視しているメソッドが実施
                    this.emit('update', {value: value});
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /***
         * 削除ボタンが押された際に呼ばれる。
         */
        onDeleteClick: function() {
            // 実際の削除処理はNoticePage側でdeleteイベントを監視しているメソッドが実施
            this.emit('delete', {noticeId: this.form.get('value').noticeId});
        }
    });
});

