/**
 * レイヤー情報を単に出力する詳細画面パーツ。
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'dojo/text!./templates/TrafficRegulationDetail.html',
    'idis/control/Router',
    'idis/store/IdisRest',
    'idis/view/_IdisWidgetBase',
    // 以下、変数として受け取らないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    './TrafficRegulationDetailGrid'
], function(module, declare, lang, locale, template, Router, IdisRest, _IdisWidgetBase) {
    return declare(module.id.replace(/\//g, '.'), _IdisWidgetBase, {

        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--traffic',

        /**
         * データ格納用オブジェクト
         * @type {module:dstore/Store}
         */
        store: null,

        /**
         * グリッドを初期化する。
         */
        initGrid: function() {

            // 全件表示用のcollectionをグリッドにセットする
            // （テンプレートHTML内のdata-dojo-attach-point="grid"でグリッドをthis.gridに紐付け済み）
            this.grid.set('collection', this.store.filter());

            /**
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.grid.on('detailButtonClick', lang.hitch(this, function(evt) {
                // helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
                this.showDetailPage(evt.item);
            }));
            */

        },

        /**
         * 詳細ボタンが押されたときに呼び出される。
         * 詳細画面に遷移する。
         */
        showDetailPage: function(object) {
            Router.moveTo('traffic/detail', {
                id: object.trafficRegulationId
            });
        },

        // DOMを構築する
        buildRendering: function() {
            this.inherited(arguments);
            var layerId = this.item.id;
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'trafficRegulationId',
                target: '/api/traffic/layers/' + layerId
            });
        },

        startup: function() {
            this.inherited(arguments);
            // グリッドを初期化する
            this.initGrid();
        },

        // resizeメソッドを定義すると外側のresize時に合わせて呼ばれる
        resize: function(changeSize, resultSize) {
            // リサイズ時は中のBorderContainerに追随させる
            // （ウィジェット自体とBorderContainerで大きさが同一なので、サイズはそのまま渡す）
            this.borderContainer.resize(changeSize, resultSize);
        }
    });
});
