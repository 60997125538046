/**
 * 安否状況確認メール再送ダイアログ用モジュール。
 * @module app/safety/mail/SafetyResendMailDialog
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/text!./templates/SafetyResendMailDialog.html', // テンプレート文字列
    'dojo/_base/lang',
    'dstore/Memory',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/view/page/_PageBase', // 共通基底クラス
    'idis/store/IdisRest',
    // 以下、変数から参照されないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/Button',
    'dijit/form/TextBox',
    'dijit/form/Select',
    'dijit/form/CheckBox',
    'idis/view/form/WordCountTextarea',
    './SafetyTempInsertGrid'
], function(module, declare, template, lang, Memory, Requester, DialogChain, _PageBase, IdisRest) {
    /**
     * 安否状況確認メール再送ダイアログ。
     * @class SafetyResendMailDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/SafetyResendMailDialog~SafetyResendMailDialog# */
        {
            // テンプレート文字列
            templateString: template,

            store: null,

            qcatStore: null,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--safety-mailsend',

            // 質問カテゴリコード
            questionCategory: null,

            // テンプレートコード
            templateCd: null,


            /**
             * 変数の初期化。
             */
            constructor: function(){
                // 安否確認メールテンプレートデータ格納用オブジェクト
                this.store = new IdisRest({
                    idProperty: 'distributionTmplId',
                    target: '/api/safety/temps'
                });

                // 質問格納用オブジェクト
                this.qcatStore = new Memory({
                    'data': []
                });

                // サーバサイドへのリクエストを発行
                Requester.get('/api/safety/questions').then(lang.hitch(this, function(data) {
                    // 成功時
                    Object.keys(data).forEach(lang.hitch(this, function(key) {
                        // カテゴリコードと質問（回答が紐づいている）の連想配列を設定する
                        this.qcatStore.addSync({id: data[key].code,
                                                questions: data[key].questions});
                    }));
                }), lang.hitch(this, function(err) {
                    // リクエスト失敗時
                    console.error(err);
                    this.chain.info('質問カテゴリの取得に失敗しました。', 'エラー');
                }));

                this.chain = DialogChain.get(this);
            },

            /**
             * DOMノードを生成するためのメソッド
             */
            buildRendering: function() {
                this.inherited(arguments);
                this.own(this.templateInsertDialog);
                this.initTemplateProvGrid();
            },

            /**
             * 再送ボタンが押下された際の処理。
             */
            onSubmit: function() {
                try {
                    if (this.form.validate()) {
                        var value = this.form.get('value');
                        // 実際の登録処理はPage側でregisterイベントを監視しているメソッドが実施
                        this.emit('register', {value: value});
                    }
                } catch (e) {
                    console.error(e);
                }
                return false;
            },

            /**
             * テンプレートの挿入ボタンが押下された際の処理。
             */
            tempInsertButtonClick: function() {
                //テンプレートの挿入ダイアログの表示
                this.templateInsertDialog.show();
            },

            /**
             * テンプレートダイアログ内グリッドの初期化。
             */
            initTemplateProvGrid: function() {
                this.grid.set('collection', this.store.filter());
                this.grid.on('insertButtonClick', lang.hitch(this, function(evt) {
                    // helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
                    this.onTemplateInsert(evt.item);
                }));
            },

            /**
             * @param item メールテンプレート
             * テンプレートの情報を、当ダイアログのform情報に設定する。
             */
            onTemplateInsert: function(item) {
                // テンプレートコードを設定
                this.templateCd = item.distributionTmplCd;
                // 質問カテゴリコードを設定
                this.questionCategory = item.categoryCd;
                // タイトルと本文をダイアログのform情報に設定する。
                this.form.set('value', {
                   title: item.distributionTitle,
                   text: item.distributionBody
                });
                // 質問と回答のformへの設定は別の関数にて実施
                this.setQandA();

                // テンプレートの挿入ダイアログを閉じる
                this.templateInsertDialog.hide();
            },

            /**
             * 質問と回答をformに設定する。
             */
            setQandA: function() {

                // 一旦クリア
                this.distQ01.set('value', '');
                this.distA01.set('value', '');
                this.distQ02.set('value', '');
                this.distA02.set('value', '');
                this.distQ03.set('value', '');
                this.distA03.set('value', '');

                // 質問カテゴリコードに合致する質問群を配列として取得
                var qstore = this.qcatStore.getSync(this.questionCategory);
                var qlist = qstore.questions;
                // 質問とそれに紐づく回答を設定する
                qlist.forEach(lang.hitch(this, function(data) {
                    // 質問の番号で設定する箇所を選ぶ
                    switch(data.no) {
                    case 1 :
                        this.distQ01.set('value', data.distributionQContents);
                        this.distA01.set('value', this.getJoinedAnswer(data.answers));
                        break;
                    case 2 :
                        this.distQ02.set('value', data.distributionQContents);
                        this.distA02.set('value', this.getJoinedAnswer(data.answers));
                        break;
                    case 3 :
                        this.distQ03.set('value', data.distributionQContents);
                        this.distA03.set('value', this.getJoinedAnswer(data.answers));
                        break;
                    default: break;
                    }
                }));
            },

            /**
             * @param answers 質問に対する回答群(連想配列)
             * @returns joinedAnswer カンマ区切りされた回答
             * 回答をformに設定する。
             *
             */
            getJoinedAnswer: function(answers) {
                var joinedAnswer = [];
                // 回答内容を配列に格納
                answers.forEach(function(ans) {
                    // 「番号．内容」の形に整形
                    //TODO distributionAContents -> distributionAContents
                    var answer = ans.no + '.' + ans.distributionAContents;
                    // 整形後の情報を配列に設定
                    joinedAnswer.push(answer);
                });
                // カンマ区切りの文字列にして変換
                return joinedAnswer.join(', ');
            },

            /**
             * キャンセルボタンが押下された際の処理。
             */
            cancelButtonClick: function() {
                // 前の画面に戻る
                this.leave();
            }

            /**
             * ウィンドウの幅に合わせてBorderContainerをリサイズし、タブ内のレイアウトを整理する。
             * (startup同様、画面描画時に呼ばれる)
             * @param changeSize
             * @param resultSize
             */
            // resize: function(changeSize, resultSize) {
            //     // 中のContentPaneも追随してリサイズするため、レイアウト崩れを防止できる。
            //     this.borderContainer.resize(changeSize, resultSize);
            // }

        });
});
