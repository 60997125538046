/**
 * <雨量情報情報一覧画面>
 *
 * @module app/observation/rain/RainfallObservationPageGrid
 */
define(['module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'idis/util/DateUtils',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'app/view/grid/CommonColumn',
    './RainfallObservationCommonColumn',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer'
], function(module, declare, lang, DateUtils, IdisGrid, helper, CommonColumn, RainCommonColum) {
    /**
     * <クラスの説明>
     *
     * @class <ウィジェット名>
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'),IdisGrid,
        /** @lends module:app/observation/rain/RainfallObservationPageGrid~RainfallObservationPageGrid# */
        {
		columns: [
			helper.column('regionName', '地域'),
			helper.column('municipalityName', '市町'),
            CommonColumn.observatoryName,
			helper.buttonColumn('detail', '詳細'),
			RainCommonColum.rainfall10Min,
			RainCommonColum.rainfall60Min,
			RainCommonColum.rainfall24Hour,
			RainCommonColum.rainfallCumulative,
            CommonColumn.managerCd
			// {field: 'rainfallEffective90Min',
			// 	label: '半減期1.5時間\n実効雨量[mm]',
			// 	formatter: lang.hitch(this, function(item){
			// 		if (item || item === 0) {
			// 			return item.toFixed(1);
			// 		} else {
			// 			return '-';
			// 		}
			// 	})
			// 	},
			// 	{field: 'rainfallEffective72Hour',
			// 		label: '半減期72時間\n実効雨量[mm]',
			// 		formatter: lang.hitch(this, function(item){
			// 			if (item || item === 0) {
			// 				return item.toFixed(1);
			// 			} else {
			// 				return '-';
			// 			}
			// 		})
			// 	},
			// {		field: 'startTimestamp',
			// 	label: '降雨開始時刻',
			// 	formatter: lang.hitch(this, function(item){
			// 		if (!item) {
			// 			return '-';
			// 		}else{
			// 			return DateUtils.format(item);
			// 		}
			// 	})
			// }
		]
	});
});
