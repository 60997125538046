/**
 * 市民への情報配信履歴 一覧画面グリッド用モジュール
 */

define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'dojo/dom-construct',
    'dojo/on',
    'app/util/DateFormatter',
    'idis/util/DateUtils',
    'dojo/dom-construct',
    'idis/view/form/Button',
    // 以下、変数として受け取らないモジュール
    'dojox/lang/functional/array'
], function(module, declare, lang, locale, IdisGrid, helper, 
    domConstruct, on, DateFormatter, DateUtils, domCon, Button) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--citizenInfoHistory',

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            // 編集ダイアログボタン
            {field: 'detail', label:'詳細', sortable: false,
            renderCell: function(value){
                var gridNode = this.grid.domNode;
                var button = new Button({
                    label: '詳細',
                    onClick: function() {
                        on.emit(gridNode, 'detailButtonClick', {value: value});
                    }
                });
                console.log(value);
                
                var content = button.domNode;
                return content;

                // 作成者、または、管理者のみ詳細ボタンを表示させる
                /* var content = null;
                if(value.crtUserId === UserInfo._userInfo.userId || UserInfo._userInfo.roleCd === 'R01001') {
                    content = button.domNode;
                } else {
                    var comment = domCon.create('div');
                    comment.textContent = '-';
                    content = comment;
                }
                return content; */
            }},

            // 配信日時
            helper.column('pubStartTimestamp', '配信日時', {
                formatter: function(value) {
                    return value ? locale.format(new Date(value)) : 'エラー';
                },
                sortable: true
            }),

            // 配信組織
            {field: 'transmitOrganizationName', label: '配信組織', sortable: false},
            // 配信内容
            helper.column('contents', '配信内容', {
                formatter: function(value) {
                    // 500文字以上は詳細で確認してもらう。
                    var text = value.length >= 500 ?
                        [value.substr( 0, 500 ) + '...'].join() : value;
                    return text;
                },
                sortable: false
            }),

            // 以下配信先
            {
                field: 'emergencyRadioFlg', label: '防災行政無線', sortable: false,
                children: [
                    helper.column('citizenInfoHistoryAreas','配信区', {
                        sortable: false,
                        formatter: lang.hitch(this, function(value){
                                if(value.length === 24) {
                                    return '全区';
                                }else if ( value.length >0 && value.length < 24 ) {
                                    return value.length + '区';
                                }else {
                                    return '-';
                                }
                            })
                        })
                ]
            },
            {field: 'emergencyMailFlg', label: '緊急速報メール（4社）', sortable: false,
            formatter: function(value){
                return value === '1' ? '〇' : '-';
            }},
            {field: 'registerMailFlg', label: '登録制メール', sortable: false,
            children: [
                {field: 'osakaNetFlg', label: 'おおさか防災ネット', sortable: false,
                formatter: function(value){
                    return value === '1' ? '〇' : '-';
                }},
                {field: 'considerationFacilityFlg', label: '要配慮者施設・地下街施設', sortable: false,
                formatter: function(value){
                    return value === '1' ? '〇' : '-';
                }}
            ]},
            {field: 'twitterFlg', label: 'Twitter', sortable: false,
            formatter: function(value){
                return value === '1' ? '〇' : '-';
            }},
            {field: 'lineFlg', label: 'LINE', sortable: false,
            formatter: function(value){
                return value === '1' ? '〇' : '-';
            }},
            {field: 'yahooFlg', label: 'Yahoo!防災速報アプリ', sortable: false,
            formatter: function(value){
                return value === '1' ? '〇' : '-';
            }},
            {field: 'cableTv', label: 'ケーブルテレビ', sortable: false,
            children: [
                {field: 'jcom', label: 'J:com', sortable: false,
                children: [
                    {field: 'jcomSubtitlesFlg', label: '字幕', sortable: false,
                    formatter: function(value){
                        return value === '1' ? '〇' : '-';
                    }},
                    {field: 'jcomInformationFlg', label: '防災情報サービス', sortable: false,
                    formatter: function(value){
                        return value === '1' ? '〇' : '-';
                    }}
                ]},
                {field: 'baycom', label: 'Baycom', sortable: false,
                children: [
                    {field: 'baycomSubtitlesFlg', label: '字幕', sortable: false,
                    formatter: function(value){
                        return value === '1' ? '〇' : '-';
                    }},
                    {field: 'baycomInformationFlg', label: '防災情報サービス', sortable: false,
                    formatter: function(value){
                        return value === '1' ? '〇' : '-';
                    }}
                ]}
            ]},
            {field: 'languageSupportCenterFlg', label: '多言語支援センターホームページ', sortable: false,
            formatter: function(value){
                return value === '1' ? '〇' : '-';
            }},
            {field: 'disInformationFlg', label: '防災情報システム', sortable: false,
            formatter: function(value){
                return value === '1' ? '〇' : '-';
            }},
            {field: 'staffGatheringFlg', label: '職員参集システム', sortable: false,
            formatter: function(value){
                return value === '1' ? '〇' : '-';
            }}
        ]
    });
});
