/**
 * 親管理番号選択ダイアログ画面用モジュール。
 * @module app/damage/ParentDamageSelectorDialog
 */
define([
	'module',
	'dojo/_base/declare',
	'dojo/_base/lang',
    'dojo/topic',
    'dojo/text!./templates/TimelineDisasterSelectorDialog.html',
    'app/model/DisasterInfo',
    'idis/view/dialog/InfoDialog',
	'idis/store/IdisRest',
    'idis/view/page/_PageBase',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/Button',
	'app/timeline/TimelineDisasterSelectorGrid'
], function(module, declare, lang, topic, template, DisasterInfo, InfoDialog,
		IdisRest, WidgetBase) {

    /**
     * 親管理番号選択ダイアログ
     * @class ParentDamageSelectorDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), WidgetBase,
        /** @lends app/view/Header~Header# */ {

        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'timeline-disasterSelector',

        /**
         * データ格納用オブジェクト
         * @type {module:dstore/Store}
         */
        store: null,

        /**
         * コンストラクタ
         */
        constructor: function() {
            this.inherited(arguments);
            // データ格納用オブジェクト
            this.store = new IdisRest({
				idProperty: 'disasterId',
	            target: '/api/timeline/disaster'
            });
        },

        /**
         * DOM生成
         */
        buildRendering: function() {
            this.inherited(arguments);
            // グリッドを初期化
            this.initGrid();
        },

        /**
         * 画面生成完了
         */
        startup: function() {
            this.inherited(arguments);
        },

        /**
         * 画面を再表示
         */
        refresh: function() {
            this.container.resize();
            this.item = null;
            this.grid.refresh();
        },

        /**
         * グリッド初期化
         */
        initGrid: function() {
            var filter = new this.store.Filter();
			// 災害ID
			// var disasterId = DisasterInfo.getDisasterId();
            // filter = filter.eq('disasterId', disasterId);
			// 子被害も表示対象に含める
			// filter = filter.eq('childrenFlg', '1');
            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);

            this.grid.set('collection', collection);

            // グリッドの行選択イベント
            this.grid.on('dgrid-select', lang.hitch(this, function(evt) {
                // 選択された行データを退避
                this.item = evt.rows[0].data;
            }));
        },

        /**
         * 災害変更実行
         */
        onSubmit: function() {
            if (!this.item) {
                InfoDialog.show('入力チェック', '災害が選択されていません');
                return false;
            }
            this.emit('update', {
                data: this.item
            });
        }
    });

});
