/**
 * 定時集計(各区・局) 詳細 閲覧画面用モジュール。
 * @module app/scheduledReport/ScheduledReportMunicDetailViewPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/text!./templates/ScheduledReportMunicDetailViewPage.html',
    'idis/control/Router',
    'app/scheduledReport/ScheduledReportMunicDetailPage',
    'app/model/DisasterInfo',
    // 以下、変数として受け取らないモジュール
], function (module, declare, template, Router, ScheduledReportMunicDetailPage, DisasterInfo) {

    /**
     * 定時集計(各区・局) 詳細 閲覧画面。
     * @class ScheduledReportMunicDetailViewPage
     * @extends module:app/scheduledReport/ScheduledReportMunicDetailPage#~ScheduledReportMunicDetailPage
     */
    return declare(module.id.replace(/\//g, '.'), ScheduledReportMunicDetailPage,
        /** @lends module:app/scheduledReport/ScheduledReportMunicDetailViewPage# */ {
            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--scheduledreport',

            // DOMノードを生成するためのメソッド
            buildRendering: function () {
                this.inherited(arguments);
            },

            // ページ初期表示にmodelがnullだとエラーが出るためmodelを生成
            constructor: function () {
            },

            startup: function () {
                this.inherited(arguments);
            },

            onListPageLinkClick: function (evt) {
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();

                // 定時状況一覧へ遷移
                Router.moveTo('scheduledReport/view');
            },
            onDamageReportPageLinkClick: function (evt) {
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                Router.moveTo('report/view');
            },
            /**
             * 定時集計一覧を表示
             */
            onMunicPageLinkClick: function (evt) {
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                // 被害一覧画面へ遷移
                this.leave();
            },


            /**
             * 定時集計 被害一覧を表示
             */
            onDamageListPageLinkClick: function (evt) {
                // 災害IDをDisasterInfoから取得
                this._disasterId = DisasterInfo.getDisasterId();
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                // 被害一覧画面へ遷移
                Router.moveTo('scheduledReport/damage/view', {
                    disasterId: DisasterInfo.getDisasterId(),
                    admNum: this._admNum,
                    scheduledReportId: this._scheduledReportId,
                    municipalityCd: this._municipalityCd,
                    isMunic: true,
                });
            },
        });
});

