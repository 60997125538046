/**
 * 安否確認機能 安否確認メール管理画面用グリッド。
 * @module app/safety/SafetyAdminGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'    
], function(module, declare, lang, locale, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid,
        /** @lends module:idis/view/page/ProvideTemplatePage~ProvideTemplatePage# */ {

        /**
         * 各列の定義
         * @type {Object[]}
         */
        collection: this.store,
        columns: [
            helper.buttonColumn('overview', '概況'),
            helper.column('distributeTimeStamp','配信日時', {
                formatter: function(value) {
                    if (typeof value !== 'undefined' && value !== null) {
                        // valueがnullかundefinedではない場合フォーマットを変える
                        var date = locale.parse(value, {
                            datePattern: 'yyyy-MM-dd',
                            timePattern: 'HH:mm:ss'
                        });
                        return locale.format(date, {
                            datePattern: 'yyyy/MM/dd',
                            timePattern: 'HH:mm:ss'
                        });
                    } else {
                        return '';
                    }
                }
            }),
            helper.column('distributeWay','配信区分',
                {formatMap: {'0': '通常','1': '緊急'}}),
            helper.column('groupName', 'グループ名'),
            helper.column('title', '件名'),
            helper.column('bodyText', '本文', {
                formatter: lang.hitch(this, function(item){
                    if (!item) {
                        // 値を持たないヘッダ行に対しては処理を実施しない。
                        return item;
                    }
                    if (item.indexOf('¥n') !== -1) {
                        // 改行コード(¥n)を含む場合は、<br>に変換
                        return item.replace(/¥n/g, '<br>');                        
                    }
                    // 改行コード(\n)を含む場合は、<br>に変換
                    // 改行コードを含んでいない場合は処理を実施せず空振り
                    return item.replace(/\\n/g, '<br>');
                })
            }),
            helper.buttonColumn('delete', '削除')
        ]        
    });
});

