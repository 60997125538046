/**
 * 安否確認メール詳細画面用モジュール。
 *
 * @module app/safety/mail/SafetyMailDetailPage
 */
define(['module',
        'dojo/_base/declare',
        'dojo/_base/lang',
        'dojo/dom-style',
        'dojo/text!./templates/SafetyMailDetailPage.html',
        'dojo/date/locale',
        'dijit/form/Select',
        'idis/view/page/_PageBase',
        'idis/view/dialog/InfoDialog',
        'idis/view/dialog/DialogChain',
        'idis/control/Router',
        'idis/control/Locator',
        'idis/service/Requester',
        'idis/view/Loader',
        // 以下、変数で受けないモジュール
        'dijit/layout/BorderContainer',
        'dijit/layout/ContentPane',
        'dijit/form/Form',
        'dijit/form/TextBox',
        'dijit/form/SimpleTextarea',
        'app/safety/auth/view/form/AclButton',
        'idis/view/form/Button',
        'app/safety/mail/SafetyResendMailDialog'],
        function(module, declare, lang, domStyle, template, locale, Select, _PageBase,
            InfoDialog, DialogChain, Router, Locator, Requester, Loader) {

    /**
     * 安否確認メール詳細画面
     *
     * @class SafetyMailListPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/safety/mail/SafetyMailDetailDialog# */
    {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--safety',

        /**
         * 変数の初期化。
         */
        constructor: function(){
            this.staffId = Locator.getQuery().ecd;
            this.divisionCd = Locator.getQuery().dcd;
            this.safetyMailId = Locator.getQuery().mid;
            this.groupCd = Locator.getQuery().gcd;

            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
            // メールアドレスの登録ステータスが異常か否か(trueならば異常)
            this.isInvalidAddress01 = false;
            this.isInvalidAddress02 = false;
        },

        /**
         * DOMノードを生成するためのメソッド
         */
        buildRendering: function() {
            this.inherited(arguments);
            this.own(this.safetyResendMailDialog);
            this.initPage();
            this.initResendDialog();
        },

        /**
         * 再送ダイアログにてボタンが押された際の挙動
         */
        initResendDialog :function(){
            // 登録ダイアログの最初の子要素が登録画面
            var dialog = this.safetyResendMailDialog;
            var page = dialog.getChildren()[0];

            // 登録画面のregisterイベントを受け取る
            page.on('register', lang.hitch(this, function() {
                // 再送処理
                this.chain.confirm('再送します。よろしいですか？', lang.hitch(this, function(chain) {
                    var url = '/api/safety/mails/' + this.safetyMailId + '/' + this.staffId;
                    Loader.wait(Requester.post(url).then(lang.hitch(this, function(data) {
                        // 再送により更新された項目を画面に反映
                        this.replyStatus.set('value', data.replyStatus);
                        this.sendStatus.set('value', data.sendStatus);
                        this.distTimeNum.innerText = data.distTimeNum;
                        this.replyTimestamp.innerText = data.replyTimestamp ? data.replyTimestamp : '';
                        this.confirmTimestamp.innerText = data.confirmTimestamp ? data.confirmTimestamp : '';
                        // 成功時（POST結果はグリッドが自動的に反映）
                        // 登録ダイアログを閉じる
                        dialog.hide();
                        // 完了ダイアログの表示
                        chain.infoComplete();
                    }) , function(error) {
                        // エラー時
                        // エラーログの出力
                        console.error('安否確認メール再送処理 エラー');
                        // エラー内容のダイアログ出力
                        chain.infoError(error);
                    }));
                }));
            }));
        },

        /**
         * 更新ボタンが押された際の挙動
         */
        onUpdateButton: function() {
            // 状態セレクトボックスとメモの相関チェックを実施
            if(!this.validate()) {
                return false;
            }

            // 送信データを生成
            var sendData = {
                'isConfirmed': this.replyStatus.value,
                'memo': this.memo.get('value')
            };

            // 送信データに回答を追加
            this.item.questions.forEach(lang.hitch(this, function(question, idx) {
                var num = '0' + (idx + 1);
                var formName = 'distA' + num;
                var nodeName = formName + 'Select';
                if (this[nodeName]) {
                    sendData[formName] = this[nodeName].get('value');
                }
            }));

            // APIアクセスのためのURL
            var url = '/api/safety/mails/' + this.safetyMailId +  '/' + this.staffId;

            // debugger;
            // 既存の配信詳細情報を最新化するため、PUTメソッドを発行する
            var promise = Requester.put(url, {data: sendData}).then(lang.hitch(this, function() {
                this.chain.infoComplete(
                    function() {
	                    this.leave();
	                }
                );
            }), lang.hitch(this, function(error) {
                // this.chain.info.show('失敗', 'サーバーエラーが発生' + '<br>' + error.response.text);
                // エラーダイアログを表示する
                this.chain.infoError(error);
            }));
            Loader.wait(promise);
        },

        /**
         * 再送ボタンが押された際に再送ダイアログを表示する
         */
        showResendDialog: function(){
            var dialog = this.safetyResendMailDialog;
            var page = dialog.getChildren()[0];
            var hasValidMailAddress = false;
            //職員情報は詳細画面より取得
            page.name.set('value',this.name.innerHTML);
            //メールアドレスのセレクトボックス生成
            //既存の情報があれば破棄
            if(this.mailAddress){
                this.mailAddress.destroyRecursive();
            }

            var options = [];
            //メールアドレスが登録されていて、かつ登録ステータスが異常でなければ、セレクトボックスに値を追加
            if (this.item.emailAddress01 && this.item.emailAddressStatus01 === '02') {
                options.push({
                    label: 'メールアドレス１',
                    value: '01'
                });
                hasValidMailAddress = true;
            }
            page.mailAddress.innerHTML = '';
            var mailSelectBox = new Select({
                name: 'mailAddress',
                options: options
            }).placeAt('mailAddressNode');
            mailSelectBox.startup();

            // 質問、回答をダイアログに設定する
            this.setQandAs(page);
            //配信情報は詳細画面にないので、配信IDをキーにDBから取得する
            var promise = Requester.get('/api/safety/mails/forms/' + this.safetyMailId).then(function(data){
                // 取得成功時
                // タイトルと本文を設定する
                page.title.set('value', data.title);
                page.bodyText.set('value', data.bodyText);
            }, function(error) {
                // 取得エラー時
                // エラーログを出力する
                console.log(error);
                // エラーダイアログを表示する
                this.chain.infoError(error);
            });
            Loader.wait(promise);

            dialog.show();

            if (hasValidMailAddress) {
                page.sendButton.set('disabled', false);
            } else {
                InfoDialog.show('エラー', '有効なメールアドレスがないため、再送できません。');
                page.sendButton.set('disabled', true);
            }
        },

        /**
         * @param page ダイアログの情報
         * 質問と回答を設定する。
         */
        setQandAs: function(page) {
            // 詳細画面側に質問が入力されていなければ-(ハイフン)を入力
            var q1 = !this.distQ01.innerHTML ? '-' : this.distQ01.innerHTML;
            var q2 = !this.distQ02.innerHTML ? '-' : this.distQ02.innerHTML;
            var q3 = !this.distQ03.innerHTML ? '-' : this.distQ03.innerHTML;

            // 質問を設定
            page.distQ01.set('value', q1);
            page.distQ02.set('value', q2);
            page.distQ03.set('value', q3);
            // 回答を設定
            page.distA01.set('value', '-');
            page.distA02.set('value', '-');
            page.distA03.set('value', '-');
            this.item.questions.forEach(lang.hitch(this, function(question, idx) {
                var answers = '';
                question.selections.forEach(lang.hitch(this, function(selection) {
                    if (answers) {
                        answers += ', ';
                    }
                    answers += selection.no + ':' + selection.content;
                }));
                page['distA0' + (idx + 1)].set('value', answers);

            }));
        },

        /**
         * @param evt ボタンクリックイベント
         * 管理画面パンくずリストのボタンを押下したときの挙動
         */
        onSafetyStatusPageLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('safety/mail');
        },

        /**
         * @param evt ボタンクリックイベント
         * 一覧画面パンくずリストのボタンを押下したときの挙動
         */
        onSafetyMailOverviewPageLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('safety/statusOverview', {
                // mid : this.safetyMailId,
                // gcd : this.groupCd,
                // dcd : this.divisionCd
                mid : this.safetyMailId,
                dcd : this.divisionCd
            });
        },

        /**
         * 更新ボタン押下時に実行される入力チェック
         */
        validate: function() {
            // formの情報
            var formData = this.form.get('value');
            // 返信ステータス
            var status = formData.replyStatus;
            // メモ
            // var memo = formData.memo;
            var memo = this.memo.get('value');

            // 返信ステータスを未返信にした場合、メモに記載がなければエラーとする
            if (status === '01' && !memo) {
                this.chain.info('状態に未返信を設定する場合は、メモを入力してください。', 'エラー');
                return false;
            }

            return true;
        },

        /**
         * DBから値を取得し、詳細画面の項目を設定する
         */
        initPage: function() {
            var self = this;

            var url = '/api/safety/mails/list/';
            url += this.safetyMailId + '/';
            url += this.divisionCd + '/';
            url += this.staffId;

            // テーブルを照会し詳細画面に表示する情報を取得する
            Requester.get(url).then(function(data){
                self.item = data;

                // 取得成功時
                // 取得したdata内の要素
                for(var key in data) {
                    // formの画面項目のidと一致する要素のみ処理する
                    if (document.getElementById(key)) {
                        // 詳細画面の画面項目をidで取得し、data内の要素を設定する。要素がundefinedなら-(ハイフン)を設定。
                        document.getElementById(key).innerHTML = !data[key] ? '-' : data[key];
                    }
                }

                // 部署は、「部局（親のdivision）／課（子のdivision）」の形式で設定する
                var dept = data.department + '/' + data.divisionName;
                self.dept.innerHTML = dept;

                // メールの登録状況を設定する
                // self.setMailStatus(data.emailAddressStatus01, self.emailAddressStatus01, self.isInvalidAddress01);
                // self.setMailStatus(data.emailAddressStatus02, self.emailAddressStatus02, self.isInvalidAddress02);

                // 状態セレクトボックスの値を設定する
                self.replyStatus.set('value',data.replyStatus);
                self.sendStatus.set('value',data.sendStatus);

                // セレクトボックス内の色を値にあわせて変更する
                self.statusColorChange();

                // 回答は可変のためここでのプルダウンを生成
                data.questions.forEach(function(question, idx) {

                    var options = [{
                        label: '&nbsp;',
                        value: null
                    }];
                    question.selections.forEach(function(selection) {
                        var option = {
                            label: selection.content,
                            value: selection.no
                        };
                        options.push(option);
                    });

                    var num = '0' + (idx + 1);
                    var nodeName = 'distA' + num + 'Select';
                    var selectBox  = new Select({
                        name: 'distA' + num,
                        options: options
                    }).placeAt(nodeName);
                    if (question.answer) {
                        selectBox.set('value', question.answer);
                    }
                    selectBox.startup();
                    self[nodeName] = selectBox;
                });

                self.memo.set('value', data.memo);

                // 呼び出し回数は「○回」の形式で設定する
                self.distTimeNum.innerHTML = data.distTimeNum + '回';
                
                self.group.innerHTML = data.chargeName;

                if (typeof data.distTimestamp !== 'undefined' && data.distTimestamp !== null) {
                    // distTimestampがnullかundefinedではない場合フォーマットを変える
                    var dist = locale.parse(data.distTimestamp, {
                        datePattern: 'yyyy-MM-dd',
                        timePattern: 'HH:mm:ss'
                    });
                    this.distTimestamp.innerText = locale.format(dist, {
                        datePattern: 'yyyy/MM/dd',
                        timePattern: 'HH:mm:ss'
                    });
                } else {
                    this.distTimestamp.innerText = '-';
                }
                
                if (typeof data.replyTimestamp !== 'undefined' && data.replyTimestamp !== null) {
                    // distTimestampがnullかundefinedではない場合フォーマットを変える
                    var reply = locale.parse(data.replyTimestamp, {
                        datePattern: 'yyyy-MM-dd',
                        timePattern: 'HH:mm:ss'
                    });
                    this.replyTimestamp.innerText = locale.format(reply, {
                        datePattern: 'yyyy/MM/dd',
                        timePattern: 'HH:mm:ss'
                    });
                } else {
                    this.replyTimestamp.innerText = '-';
                }
                
                if (typeof data.confirmTimestamp !== 'undefined' && data.confirmTimestamp !== null) {
                    // distTimestampがnullかundefinedではない場合フォーマットを変える
                    var confirm = locale.parse(data.confirmTimestamp, {
                        datePattern: 'yyyy-MM-dd',
                        timePattern: 'HH:mm:ss'
                    });
                    this.confirmTimestamp.innerText = locale.format(confirm, {
                        datePattern: 'yyyy/MM/dd',
                        timePattern: 'HH:mm:ss'
                    });
                } else {
                    this.confirmTimestamp.innerText = '-';
                }
            }, function(error) {
                // 取得エラー時
                // エラーログを出力する
                console.log(error);
                // エラーダイアログを表示する
                self.chain.infoError(error);
            });
        },

        /**
         * @param itemMailStatus テーブルから取得した登録ステータス
         * @param pageMailStatus 詳細画面上での登録ステータスのノード
         * @param invalidAddressFlg メールの登録ステータスが異常か否か
         * メール登録ステータスの画面への設定を共通化
         */
        // setMailStatus: function(itemMailStatus, pageMailStatus, invalidAddressFlg) {
        //     switch(itemMailStatus){
        //     case '01':
        //         domStyle.set(pageMailStatus, 'backgroundColor', '#FEFFCD');
        //         pageMailStatus.innerHTML = '本登録待ち';
        //         break;
        //     case '02':
        //         domStyle.set(pageMailStatus, 'backgroundColor', '#D6F3CE');
        //         pageMailStatus.innerHTML = '正常';
        //         break;
        //     case '03':
        //         domStyle.set(pageMailStatus, 'backgroundColor', '#FBD4CF');
        //         pageMailStatus.innerHTML = '異常';
        //         invalidAddressFlg = true;
        //         break;
        //     }
        // },

        /**
         * 状態欄の値に応じてセレクトボックス色づけ
         */
        statusColorChange: function(){
            switch(this.replyStatus.value){
                //未返信
                case '01':
                    this.replyStatus.domNode.style.setProperty('background-color','#FBD4CF');
                    break;
                //確認済
                case '02':
                    this.replyStatus.domNode.style.setProperty('background-color','#CBE4F8');
                    break;
                //返信有
                case '03':
                    this.replyStatus.domNode.style.setProperty('background-color','#D6F3CE');
                    break;
            }
            switch(this.sendStatus.value){
                //送信失敗
                case '01':
                    this.sendStatus.domNode.style.setProperty('background-color','#FBD4CF');
                    break;
                //未確認
                case '02':
                    this.sendStatus.domNode.style.setProperty('background-color','#D5D5D5');
                    break;
                //送信済
                case '03':
                    this.sendStatus.domNode.style.setProperty('background-color','#D6F3CE');
                    break;
            }
        }
    });
});
