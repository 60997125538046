/**
 * 定時集計 詳細 閲覧画面用モジュール。
 * @module app/scheduledReport/ScheduledReportViewPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/text!./templates/ScheduledReportViewPage.html',
    'idis/control/Router',
    'app/scheduledReport/ScheduledReportPage',
    'app/model/DisasterInfo',
    'app/config',
    // 以下、変数として受け取らないモジュール
], function (module, declare, template, Router, ScheduledReportPage, DisasterInfo, config) {

    /**
     * 定時集計 詳細 閲覧ページ
     * @class ScheduledReportViewPage
     * @extends module:app/scheduledReport/ScheduledReportPage#~ScheduledReportPage
     */
    return declare(module.id.replace(/\//g, '.'), ScheduledReportPage,
        /** @lends module:app/scheduledReport/ScheduledReportViewPage# */ {
            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--scheduledreport',

            // DOMノードを生成するためのメソッド
            buildRendering: function () {
                this.inherited(arguments);
            },

            // ページ初期表示にmodelがnullだとエラーが出るためmodelを生成
            constructor: function () {
            },

            startup: function () {
                this.inherited(arguments);
            },
            /**
             * 定時集計一覧を表示
             */
            onScheduledReportListPageLinkClick: function (evt) {
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                // 被害一覧画面へ遷移
                Router.moveTo('scheduledReport/view');
            },
            onDamageReportPageLinkClick: function (evt) {
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                // 被害一覧画面へ遷移
                Router.moveTo('report/view');
            },

            /**
             * 定時集計 被害一覧を表示
             */
            onDamageListPageLinkClick: function (evt) {
                // 災害IDをDisasterInfoから取得
                this._disasterId = DisasterInfo.getDisasterId();
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                // 被害一覧画面へ遷移
                Router.moveTo('scheduledReport/damage/view', {
                    disasterId: DisasterInfo.getDisasterId(),
                    scheduledReportId: this._scheduledReportId,
                    municipalityCd: config.municInfo.cityMunicCd
                });
            },
        });
});

