define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/dom-class',
    'dojo/dom-construct',
    'dojo/dom-style',
    'dojo/query',
    'dojo/_base/lang',
    'dojo/on',
    'dojo/json',
    'dojo/text!./templates/DrawPanel.html',
    'dojo/topic',
    'dojox/lang/functional/object',
    'leaflet',
    'idis/view/draw/DrawFeatureGroup',
    'idis/view/draw/HistoricalMap',
    'idis/view/draw/kmlConverter',
    'idis/view/draw/KmlToLayer',
    'idis/view/draw/CustomDivIcon',
    'idis/view/draw/CustomIcon',
    'idis/view/draw/_DrawUtil',
    'idis/view/_IdisWidgetBase',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/IdisDialog',
    './DrawEditDialog',
    './DrawDialogFileImportPage',
    './DrawDialogFileSavePage',
    './DialogRadius',
    './DrawRadius',
    //'idis/consts/QUERY',
    //'idis/control/Locator',
    // 以下、変数で受けないモジュール
    'idis/view/draw/leaflet.draw.custom',
    'idis/view/form/ColorSelector',
    'idis/view/page/_PageBase',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'idis/view/form/ColorSelector',
    'idis/view/form/OpacitySlider',
    //'leaflet-draw-drag',
    //'leaflet-path-drag',
    'leaflet-plugins/layer/vector/KML',
    'dijit/form/DropDownButton',
    'dijit/TooltipDialog',
    'dijit/ColorPalette',
    'dijit/form/DropDownButton',
    'dijit/form/HorizontalSlider',
    'leaflet-measure-path'
], function(module, array, declare, domClass, domCon, domStyle, query, lang, on, json, template, topic,
    df, leaflet, DrawFeatureGroup, HistoricalMap, kmlConverter, CustomKML, CustomDivIcon, CustomIcon,
    DrawUtil, _IdisWidgetBase, DialogChain, IdisDialog, DrawEditDialog, DrawDialogFileImport, DrawDialogFileSave,
    DialogRadius, DrawRadius) {

    var content = declare(_IdisWidgetBase, {
        templateString: template,
        baseClass: 'drawPanel-Container',
        widgetsInTemplate: true
    });

    var container = declare(module.id.replace(/\//g, '.'), [IdisDialog], {
        // 非モーダルとして扱う
        noUnderlay: true,

        // Activeな作図モード
        _ActiveMode: null,
        // ActiveなDropDown
        _ActiveSubItem: null,

        // ２次編集
        /**
         * Active(Mapに追加されている)なLayerの識別子一覧
         * @type {number[]}
         */
        _activeLayerIds: null,
        // Layerの管理コンテナ
        _layerControls: null,

        // 編集ダイアログ
        // icon用
        _editDialogIcon: null,
        // line用
        _editDialogPolyline: null,
        // circle, CircleMarker, rectangle, polygon, freehand_polygon用
        _editDialogPolygon: null,
        // note用
        _editDialogNote: null,

        // import / exportダイアログ
        _importDialog: null,
        _exportDialog: null,

        // Pub / Sub
        // 編集用Dialog
        EDIT_ICON_ID  : '/app/draw/DrawPanel::IconEditer',
        EDIT_LINE_ID  : '/app/draw/DrawPanel::LineEditer',
        EDIT_DIAG_ID  : '/app/draw/DrawPanel::DiagEditer',
        EDIT_NOTE_ID  : '/app/draw/DrawPanel::NoteEditer',
        RESET_MODE_ID : '/app/draw/DrawPanel::resetMode',
        // ファイル操作系Dialog
        IMPORT_FILE   : '/app/draw/DrawPanel::drawLoadFromImportDialog',
        RQST_SAVE     : '/app/draw/DrawPanel::drawRequestFromSaveDialog',
        RESP_SAVE     : '/app/draw/DrawPanel::drawResponseToSaveDialog',
        // MainMap以外
        HISTORY_SAVE  : '/app/draw/DrawPanel::drawAccessToHistoricalMap',
        JSONIZE_RQST  : '/app/draw/DrawPanel::drawnDataJsonizeRequest',
        JSONIZE_DONE  : '/app/draw/DrawPanel::drawnDataJsonizeResponse',
        DISABLE_DRAW  : '/app/draw/DrawPanel::hideAndDisableDraw',
        DRAW_BY_JSON  : '/app/draw/DrawPanel::drawGeoJSONToLayer',
        ADD_LAYER     : '/app/draw/DrawPanel::addLayer',
        REMOVE_LAYER  : '/app/draw/DrawPanel::removeLayer',
        REMOVE_ALL    : '/app/draw/DrawPanel::removeAllLayers',
        ROAD_REG_REQ  : '/app/draw/DrawPanel::roadRegisterProcessStart',
        ROAD_REG_RES  : '/app/draw/DrawPanel::roadRegisterProcessComplete',
        // イベント削除
        RM_EDIT_EVT   : '/app/draw/DrawPanel::removeEditEvent',
        RM_IMPT_EVT   : '/app/draw/DrawPanel::removeImportEvent',
        RM_EXPT_EVT   : '/app/draw/DrawPanel::removeExportEvent',
        // 半径描画
        RADIUS_DIALOG : '/app/draw/DrawPanel::drawRadius',

        // 各種編集ダイアログを呼び出すためのイベント
        _callEditDialogEvts: null,
        // 削除確認ダイアログを呼び出すためのイベント
        _deleteConfirmDialogEvts: null,
        // 二次編集イベントの管理
        _secondControlAreaEvts: null,
        // leafletのeditアイコンにつけたイベント
        _leafletIconEvts: null,

        // 現在編集中のレイヤー
        _editingLayer: null,

        // 表示切替のbutton
        _dispControlList :{
            'main' : {
                position : ['50px', '80px'],
                buttonList   : []
            },
            'damage' : {
                position : ['465px', '150px'],
                buttonList   : [
                    'drawPanel-importButton',
                    'drawPanel-saveButton'
                ]
            },
            'traffic' : {
                position : ['495px', '262px'],
                buttonList   : [
                    'drawPanel-importButton',
                    'drawPanel-saveButton'
                ]
            }
        },

        constructor: function(options) {
            lang.mixin(this, options);
            this.title = '作図・レイヤー管理';
            this.inherited(arguments);
            this.inner = new content();
            this.chain = DialogChain.get(this);
            this.own(this.inner);
            this._activeLayerIds = [];
            this._layerControls = {};
            // 広域印刷ウィジェットで作図中オブジェクトを利用するため、そこからアクセスできるようにマップに追加しておく
            this.map.drawControls = this._layerControls;

            this._leafletIconEvts = [];
            this._callEditDialogEvts = [];
            this._deleteConfirmDialogEvts = [];
        },

        // override
        postCreate: function() {
            this.inherited(arguments);
            this.set('content', this.inner);

            // 表示
            this.getSelectedButton();
            this.showTargetHelp();

            // 初期イベント、作図用Objectの生成
            this.setDrawObjects();
            this.setDrawEvents();
        },

        /*---------- 表示制御系 ----------*/

        // 表示位置/buttonの指定
        show: function(){
            this.inherited(arguments);
            var dispPos = this._dispControlList[this.dispType].position;
            var disBttn = this._dispControlList[this.dispType].buttonList;
            domStyle.set(query('.drawPanel-NonModal')[0], {
                left: dispPos[0],
                top : dispPos[1]
            });
            disBttn.forEach(function(btn){
                domStyle.set(btn, {
                    display: 'none'
                });
            });
        },

        //DrawPanelのイベント切り替えボタンのイベントセット
        getSelectedButton: function() {
            on(this.inner.domNode, 'button:click', lang.hitch(this, function(evt) {
                this.setTargetFunc(evt.target.id);
            }));
        },

        //ヘルプ表示
        showTargetHelp: function() {
            on(this.inner.domNode, 'button:mouseover', lang.hitch(this, function(evt) {
                this.inner.helpShowArea.style.fontWeight = 'bold';
                this.inner.helpShowArea.innerHTML = evt.target.value;
            }));
        },

        //line, DiagramのDropDown制御
        controllSubItems: function(target) {
            var diagDD = this.inner.drawPanelDiagramDropDown;
            var lineDD = this.inner.drawPanelLineDropDown;
            if (query('#' + target)[0].classList[0].indexOf('Sub') === -1) {
                if (target === 'drawPanel-lineButton') {
                    if (this._ActiveSubItem !== null) {
                        if (this._ActiveSubItem === diagDD) {
                            diagDD.style.display = 'none';
                            lineDD.style.display = '';
                            this._ActiveSubItem = lineDD;
                        } else {
                            lineDD.style.display = 'none';
                            this._ActiveSubItem = null;
                        }
                    } else {
                        lineDD.style.display = '';
                        this._ActiveSubItem = lineDD;
                    }
                } else if (target === 'drawPanel-diagramButton') {
                    if (this._ActiveSubItem !== null) {
                        if (this._ActiveSubItem === lineDD) {
                            lineDD.style.display = 'none';
                            diagDD.style.display = '';
                            this._ActiveSubItem = diagDD;
                        } else {
                            diagDD.style.display = 'none';
                            this._ActiveSubItem = null;
                        }
                    } else {
                        diagDD.style.display = '';
                        this._ActiveSubItem = diagDD;
                    }
                } else {
                    if (this._ActiveSubItem !== null) {
                        this._ActiveSubItem.style.display = 'none';
                        this._ActiveSubItem = null;
                    }
                }
            }
        },

        //ActiveModeのcss付与
        toggleActiveModeCss: function(selectedId) {
            var attachClass = 'is-selected';
            // 現在Activeなものがあれば、Removeする
            if (selectedId === 'drawPanel-lineButton' ||
                selectedId === 'drawPanel-diagramButton' ||
                selectedId === 'drawPanel-undoButton' ||
                selectedId === 'drawPanel-redoButton' ||
                selectedId === 'drawPanel-importButton' ||
                selectedId === 'drawPanel-saveButton'
            ) {
                if (this._ActiveMode !== null) {
                    domClass.remove(this._ActiveMode, attachClass);
                }
                this._ActiveMode = null;
                return;
            }
            // どのボタンもActiveでない場合
            if (this._ActiveMode === null) {
                domClass.add(selectedId, attachClass);
            // 同じボタンが押された場合
            } else if (this._ActiveMode === selectedId) {
                domClass.remove(this._ActiveMode, attachClass);
            // どれかがAcitveで、かつ別のボタンが押された場合
            } else {
                domClass.remove(this._ActiveMode, attachClass);
                domClass.add(selectedId, attachClass);
            }
        },

        /*---------- 機能制御系 ----------*/

        // 作図機能の切り替え
        setTargetFunc: function(selectedId) {
            this.controllSubItems(selectedId);
            this.removeHandles();
            this.toggleActiveModeCss(selectedId);

            [   // 編集ダイアログ呼び出し
                this._callEditDialogEvts,
                // 削除ダイアログ呼び出し
                this._deleteConfirmDialogEvts,
                // 編集時のleafletアイコンにつけたイベント
                this._leafletIconEvts

            ].forEach(function(evtsArr, idx){
                evtsArr.forEach(function(target){
                    if (idx === 2) {
                        target.off('dragend');
                    } else {
                        target.off();
                    }
                });
            });

            // 計測はイベントではないため、表示を切り替えるだけでよい。
            this.measureControl(false);

            // dblClickを有効化
            this.map.doubleClickZoom.enable();

            // 編集モードに入っているものがあったら無効にする
            if (this._editingLayer) {
                this._editingLayer.editing.disable();
            }

            //同じボタンが押されたら作図モードから抜ける
            if (this._ActiveMode === selectedId) {
                this._ActiveMode = null;
                return;
            }

            // 作図モード呼び出し
            this.switchMode(selectedId);
            this._ActiveMode = selectedId;
        },

        // ActiveなLayerがあるか
        _hasActiveLayer: function(mode){
            if (this._activeLayerIds.length === 0) {
                // TODO ボタンのcssを戻したい。
                this.chain.info('作図レイヤーを選択してください。', 'エラー');
                return false;
            } else {
                if (mode === 'draw') {
                    // 作図中はdblClickを無効化
                    this.map.doubleClickZoom.disable();
                }
                return true;
            }
        },

        //押されたボタンの作図機能をOnにする。
        switchMode: function(selectedId) {
            switch (selectedId) {
                case 'drawPanel-pointButton':
                    if (this._hasActiveLayer('draw')) {
                        this.mapObjects.marker.enable();
                    }
                    break;
                case 'drawPanel-lineButton':
                case 'drawPanel-diagramButton':
                    break;
                case 'drawPanel-noteButton':
                    if (this._hasActiveLayer('draw')) {
                        this.mapObjects.note.enable();
                    }
                    break;

                case 'drawPanel-polyLineButton':
                    if (this._hasActiveLayer('draw')) {
                        this.mapObjects.polyline.enable();
                    }
                    break;
                case 'drawPanel-arrowLineButton':
                    if (this._hasActiveLayer('draw')) {
                        this.mapObjects.arrow.enable();
                    }
                    break;
                case 'drawPanel-freeHandLineButton':
                    if (this._hasActiveLayer('draw')) {
                        this.mapObjects.freeHandPolyline.enable();
                    }
                    break;
                case 'drawPanel-bothRoadClosedButton':
                    if (this._hasActiveLayer('draw')) {
                        this.mapObjects.bothArrow.enable();
                    }
                    break;
                case 'drawPanel-oneRoadClosedButton':
                    if (this._hasActiveLayer('draw')) {
                        this.mapObjects.arrow.enable();
                    }
                    break;
                case 'drawPanel-circleButton':
                    if (this._hasActiveLayer('draw')) {
                        this.mapObjects.circle.enable();
                    }
                    break;
                case 'drawPanel-radiusButton':
                    if (this._hasActiveLayer('draw')) {
                        this.mapObjects.radius.enable();
                    }
                    break;
                case 'drawPanel-squareButton':
                    if (this._hasActiveLayer('draw')) {
                        this.mapObjects.rectangle.enable();
                    }
                    break;
                case 'drawPanel-polygoneButton':
                    if (this._hasActiveLayer('draw')) {
                        this.mapObjects.polygon.enable();
                    }
                    break;
                case 'drawPanel-freeHandPolygoneButton':
                    if (this._hasActiveLayer('draw')) {
                        this.mapObjects.freeHandPolygon.enable();
                    }
                    break;
                // Util
                case 'drawPanel-undoButton':
                    if (this._hasActiveLayer()) {
                        if (this.map instanceof HistoricalMap) {
                            this.map.undo();
                        }
                    }
                    break;
                case 'drawPanel-redoButton':
                    if (this._hasActiveLayer()) {
                        if (this.map instanceof HistoricalMap) {
                            this.map.redo();
                        }
                    }
                    break;
                case 'drawPanel-editButton':
                    if (this._hasActiveLayer('draw')) {
                        // ActiveLayerすべての作図Objにイベントを付与する。
                        this._activeLayerIds.forEach(lang.hitch(this, function(id){
                            console.log('edit for Layer-ID = ' + id + ' is Active');

                            // シングルクリックで図形の形を編集
                            var clickObjEvt = this._layerControls[id].featureGroup.on('click',
                            lang.hitch(this, function(e) {
                                // クリックでopenされてしまうpopupを閉じる
                                // TODO: 最初から"一時的に"出ないようにできないか
                                e.layer.closePopup();

                                // 編集中のものがあったらその編集モードをOFF
                                if (this._editingLayer) {
                                    this._editingLayer.editing.disable();
                                }
                                // クリックしたものについて編集モードをON
                                e.layer.editing.enable();
                                this._editingLayer = e.layer;
                            }));

                            // ダブルクリックで図形のプロパティを編集
                            var doubleclickObjEvt = this._layerControls[id].featureGroup.on('dblclick contextmenu',
                            lang.hitch(this, function(e) {
                                // 編集モードに入っていたら解除
                                if (this._editingLayer) {
                                    this._editingLayer.editing.disable();
                                }

                                this._showEditDialog(
                                    e.layer,
                                    e.layer.options.drawType,
                                    'edit'
                                );
                            }));
                            this._callEditDialogEvts.push(clickObjEvt, doubleclickObjEvt);
                        }));
                    }
                    break;
                case 'drawPanel-deleteButton':
                    if (this._hasActiveLayer('draw')) {
                        // ActiveLayerすべての作図Objにイベントを付与する。
                        this._activeLayerIds.forEach(lang.hitch(this, function(id){
                            console.log('remove for Layer-ID = ' + id + ' is Active');
                            var deleteObjEvt = this._layerControls[id].featureGroup.on('click',
                            lang.hitch(this, function(e) {
                                this.chain.confirm('削除しますか？', function(chain) {
                                    this._layerControls[id].featureGroup.removeLayer(e.layer);
                                    if (e.layer.options.drawType === 'arrow' && e.layer.options.arrowHead) {
                                        if(e.layer.options.bothArrow === true) {
                                            for(var i = 0; i <= e.layer.options.arrowHead.length -1; i++) {
                                                this.map.removeLayer(e.layer.options.arrowHead[i]);
                                            }
                                        } else {
                                            this.map.removeLayer(e.layer.options.arrowHead);
                                        }
                                    }
                                    // FetureGroupの状態をHistoryに伝える。
                                    this.map.pushState();
                                    chain.hide();
                                });
                            }));
                            this._deleteConfirmDialogEvts.push(deleteObjEvt);
                        }));
                    }
                    break;
                case 'drawPanel-measureButton':
                    if (this._hasActiveLayer()) {
                        // ActiveLayerすべての作図Objにイベントを付与する。
                        this.measureControl(true);
                    }
                    break;
                case 'drawPanel-importButton':
                    if (this._importDialog === null) {
                        this._importDialog = new DrawDialogFileImport();
                    }
                    this._importDialog.show();
                    break;
                case 'drawPanel-saveButton':
                    // this._activeLayerIdsにLayerIDが入っていれば対象
                    if (this._activeLayerIds.length) {
                        // ActiveなLayerに作図Objが入っていれば処理を続ける
                        var countFeatures = 0;
                        this._activeLayerIds.forEach(lang.hitch(this, function(layerId){
                            countFeatures += this._layerControls[layerId].featureGroup.getLayers().length;
                        }));
                        if (countFeatures === 0) {
                            this.chain.info('出力対象レイヤーに作図データが含まれていません。', 'エラー');
                            return;
                        }
                        if (this._exportDialog === null) {
                            this._exportDialog = new DrawDialogFileSave();
                        }
                        this._exportDialog.show();
                    } else {
                        this.chain.info('出力対象レイヤーを選択してください。', 'エラー');
                    }
                    break;
                default:
                    this.chain.infoError('error could not found such a function');
            }
        },

        // 計測稼動切り替え
        measureControl: function(flg){
            this._activeLayerIds.forEach(lang.hitch(this, function(id){
                this._layerControls[id].featureGroup.eachLayer(function(layer){
                    if (layer.options.drawType !== 'DivIcon' &&
                        layer.options.drawType !== 'marker'  &&
                        layer.options.drawType !== 'Icon'    &&
                        layer.options.drawType !== undefined
                    ){
                        if (flg) {
                            layer.showMeasurements({'minPixelDistance':1000});
                        } else {
                            layer.hideMeasurements();
                        }
                    }
             });
          }));
        },

        // 二次編集用
        importFile: function(fileData){
            if (!!fileData){
                var fileName = fileData.fileName;
                var extension = fileData.extension;
                var filePath  = fileData.filePath;

                // 新規LayerId取得
                var keys = Object.keys(this._layerControls);
                // 最後のID +1
                var newLayerId = Number(keys[keys.length-1]) + 1;
                // 新規control生成
                this._layerControls[newLayerId] = this.createLayerControl();

                var newFeatureGroup = this._layerControls[newLayerId].featureGroup;
                switch (extension) {
                    case 'KML':
                        // controlのfeatureGroupに読み込んだ作図Objectを追加する
                        var customKML = new leaflet.CustomKML(filePath, {async: true});
                        customKML.on('loaded', lang.hitch(this, function(e){
                            e.target.eachLayer(lang.hitch(this, function(layer){
                                newFeatureGroup.addLayer(layer);

                                // 図形の追加自身を地図の履歴に残す
                                this.map.pushState();

                                // 図形を移動したりしたときに履歴に残す
                                // edit: 線,面の操作
                                // dragend: マーカー,付箋の移動
                                layer.on('edit dragend', lang.hitch(this, function() {
                                    this.map.pushState();
                                }));
                            }));
                        }));
                        break;

                    case 'GeoJSON':
                        // controlのfeatureGroupに読み込んだ作図Objectを追加する
                        leaflet.tanJson(json.parse(fileData.json)).eachLayer(lang.hitch(this, function(layer){
                            if (layer.options.drawType === 'arrow') {
                                layer.editing.latlngs[0].length = 1;
                            }
                            newFeatureGroup.addLayer(layer);

                            // 図形の追加自身を地図の履歴に残す
                            this.map.pushState();

                            // 図形を移動したりしたときに履歴に残す
                            // edit: 線,面の操作
                            // dragend: マーカー,付箋の移動
                            layer.on('edit dragend', lang.hitch(this, function() {
                                this.map.pushState();
                            }));

                        }));

                        // ArrowならArrowHeadをNoteならImageを展開する。
                        newFeatureGroup.eachLayer(function(layer){
                            if (layer.options.drawType === 'arrow') {
                                layer.setArrowHead(layer.options);
                            }
                            if (layer.options.drawType === 'DivIcon') {
                                layer.setNoteStyle({'fileList' : layer.options.fileList});
                            }
                        });
                        break;

                    case 'zip': //shapefile
                        DrawUtil._multiToSingle(leaflet.tanJson(fileData.json)).forEach(function(layer){
                            layer.addTo(newFeatureGroup);
                        });
                        break;
                }

                // 読込作図エリアの生成
                var newControlArea = null;
                newControlArea = domCon.toDom(
                    '<div data-idis-draw-layerid="' + newLayerId + '" class="drawPanelSecondaryEditContent">'+
                        '<div class="secondlyEditlayerName">'+ fileName + '</div>'+
                        '<div class="secondlyEdit-deleteButton"></div>'+
                        '<div data-idis-draw-switch-shown="true" class="secondlyEditNode">表示中</div>'+
                    '</div>');
                domCon.place(newControlArea, this.inner.drawPanelSecondaryEditControlArea, 'last');

                // すべてのLayerをdisableにする。
                var layerControlNode = this.inner.drawPanelSecondaryEditControlArea.children;
                DrawUtil._toArray(layerControlNode).forEach(function(secondlyEditNode){
                    domClass.remove(secondlyEditNode, 'isSelected');
                    DrawUtil._toArray(secondlyEditNode.children).forEach(function(secondlyEditParts){
                        // 表示/非表示の文言を切り替える
                        if(secondlyEditParts.className === 'secondlyEditNode') {
                            secondlyEditParts.dataset.idisDrawSwitchShown = 'false';
                            secondlyEditParts.textContent = '非表示';
                        }
                    });
                });

                // Mapから表示を取り除く
                this._activeLayerIds.forEach(lang.hitch(this, function(layerId){

                    // 矢印の場合
                    // FIXME 個別対応をやめる.  オブジェクトが増えたときのパフォーマンス
                    this._layerControls[layerId].featureGroup.eachLayer(lang.hitch(this, function(layer){
                        if (layer.options.drawType === 'arrow' && layer.options.arrowHead) {
                            this.map.removeLayer(layer.options.arrowHead);
                        }
                    }));
                    this.map.removeLayer(this._layerControls[layerId].featureGroup);
                }));
                this._activeLayerIds = [];

                // 読み込まれた時点ではその人だけをActiveLayerとする
                this._activeLayerIds.push(Number(newControlArea.dataset.idisDrawLayerid));
                domClass.add(newControlArea, 'isSelected');
                DrawUtil._toArray(newControlArea.children).forEach(function(parts){
                    // 表示/非表示の文言を切り替える
                    if(parts.className === 'secondlyEditNode') {
                        parts.dataset.idisDrawSwitchShown = 'true';
                        parts.textContent = '表示中';
                    }
                });
                console.log('import success and this Layer has ID = '+ this._activeLayerIds[0]);
            } else {
                this.chain.info('データが取得できません。', 'エラー');
            }
        },

        // ファイル保存
        saveFile: function(fileType){
            // ActiveLayerの作図データをtanjson化する
            var tanJsonList = [];
            this._activeLayerIds.forEach(lang.hitch(this, function(layerId){
                tanJsonList.push(this._layerControls[layerId].featureGroup.toTanJSON());
            }));

            var mergedTanJson = DrawUtil._mergeGeoJSON(tanJsonList);

            if (fileType === 'GeoJSON') {
                return mergedTanJson;
            } else { //(fileType === 'KML')
                return kmlConverter.geojsonToKML(mergedTanJson);
            }
        },

        // 二次編集用LayerControlAreaのイベントハンドリング
        setSecondlyEditArea: function(controlSwitchArea){
            var secondlyControlAreaEvt = controlSwitchArea.addEventListener('click',
            lang.hitch(this, function(e){
                // 作図Objectをすべて削除する。
                if (e.target.className === 'deleteAllObjects' ||
                    e.target.className === 'deleteAllObjects map-Control-button-white') {
                    var defaultLayer = this._layerControls[0];
                    var defaultFeatureGroup = defaultLayer.featureGroup;
                    if (defaultFeatureGroup.getLayers().length !== 0){
                        this.chain.confirm('地図上の作図データを全て削除します。よろしいですか？', function(chain) {
                            defaultLayer.edit.disable();
                            // FIXME 個別対応をやめる. オブジェクトが増えたときのパフォーマンス
                            defaultFeatureGroup.eachLayer(lang.hitch(this, function(layer){
                                defaultFeatureGroup.removeLayer(layer);
                                if(layer.options.drawType === 'arrow') {
                                    if(layer.options.bothArrow === true) {
                                        for(var i = 0; i <= layer.options.arrowHead.length -1; i++) {
                                            this.map.removeLayer(layer.options.arrowHead[i]);
                                        }
                                    } else {
                                        this.map.removeLayer(layer.options.arrowHead);
                                    }
                                }
                                /*if (layer.options.drawType === 'arrow' && layer.options.arrowHead) {
                                    this.map.removeLayer(layer.options.arrowHead);
                                }*/
                            }));
                            this.map.pushState();
                            chain.hide();
                        });
                    }
                } else if (e.target.dataset.idisDrawSwitchShown) {
                    var layerId = Number(e.target.parentNode.dataset.idisDrawLayerid);
                    var layerIndex = this._activeLayerIds.indexOf(layerId);
                    // 作図対象Layerに入れる
                    if (layerIndex === -1 ) {
                        this._activeLayerIds.push(layerId);
                        domClass.add(e.target.parentNode, 'isSelected');
                        e.target.dataset.idisDrawSwitchShown = 'true';
                        e.target.textContent = '表示中';
                        this.map.addLayer(this._layerControls[layerId].featureGroup);

                        // 矢印の場合
                        // FIXME 個別対応をやめる.  オブジェクトが増えたときのパフォーマンス
                        this._layerControls[layerId].featureGroup.eachLayer(lang.hitch(this, function(layer){
                            if (layer.options.drawType === 'arrow' && layer.options.arrowHead) {
                                layer.setArrowHead(layer.options);
                            }
                        }));

                    // 作図対象Layerからはずす
                    } else {
                        this._activeLayerIds.splice(layerIndex, 1);
                        domClass.remove(e.target.parentNode, 'isSelected');
                        e.target.dataset.idisDrawSwitchShown = 'false';
                        e.target.textContent = '非表示';
                        this._layerControls[layerId].edit.disable();

                        // 矢印の場合
                        // FIXME 個別対応をやめる.  オブジェクトが増えたときのパフォーマンス
                        this._layerControls[layerId].featureGroup.eachLayer(lang.hitch(this, function(layer){
                            if(layer.options.drawType === 'arrow') {
                                if(layer.options.bothArrow === true) {
                                    for(var i = 0; i <= layer.options.arrowHead.length -1; i++) {
                                        this.map.removeLayer(layer.options.arrowHead[i]);
                                    }
                                } else {
                                    this.map.removeLayer(layer.options.arrowHead);
                                }
                            }
                            /*if (layer.options.drawType === 'arrow' && layer.options.arrowHead) {
                                this.map.removeLayer(layer.options.arrowHead);
                            }*/
                        }));

                        this.map.removeLayer(this._layerControls[layerId].featureGroup);
                    }
                    // LayerIDを昇順にソートする
                    this._activeLayerIds.sort(function(a,b){return a<b?-1:a>b?1:0;});
                    console.log('_activeLayerIds : ' + this._activeLayerIds + ' is active now');

                // deleteボタンがクリックされたら
                } else if (!e.target.dataset.idisDrawLayerid) {
                    this.chain.confirm('削除しますか？', lang.hitch(this,function(chain) {
                        var targetContent = e.target.parentNode;
                        var targetLayerId = Number(targetContent.dataset.idisDrawLayerid);
                        // modeをdisableにする
                        this._layerControls[targetLayerId].edit.disable();

                        // 矢印の場合
                        // FIXME 個別対応をやめる. オブジェクトが増えたときのパフォーマンス
                        this._layerControls[targetLayerId].featureGroup.eachLayer(lang.hitch(this, function(layer){
                            if (layer.options.drawType === 'arrow' && layer.options.arrowHead) {
                                this.map.removeLayer(layer.options.arrowHead);
                            }
                        }));

                        // map上から作図データを削除、Controlを削除
                        this.map.removeLayer(this._layerControls[targetLayerId].featureGroup);
                        this.map.removeControl(this._layerControls[targetLayerId].drawControl);
                        // drawPanelで管理している作図Layerを削除
                        this._layerControls[targetLayerId] = null;
                        delete this._layerControls[targetLayerId];
                        // ActiveLayerIDのListからも削除
                        var activeLayerListIdx = this._activeLayerIds.indexOf(targetLayerId);
                        if (activeLayerListIdx !== -1) {
                            this._activeLayerIds.splice(activeLayerListIdx, 1);
                        }
                        // domを削除
                        controlSwitchArea.removeChild(targetContent);
                        console.log('layer-ID : ' + targetLayerId + ' is delete succecfully');
                        chain.hide();
                    }));
                }
            }));
            this._secondControlAreaEvts = secondlyControlAreaEvt;
        },

        // 半径から円を描画する
        radiusCircle: function(value) {
            // 描画するレイヤーＩＤを取得
            var layerId = 0;
            if(this._activeLayerIds.length === 1) {
                layerId = this._activeLayerIds;
            }

            var newFeatureGroup = this._layerControls[layerId].featureGroup;

            // 描画用のJSONを作成
            var json = [];
            if(value.edit === false) {
                // 緯度経度を取得
                var newLatlng = this.mapObjects.radius.latlng;
                // 半径
                var radius = value.radius;
                // 単位：キロメートル
                if(value.unit === '1') {
                    radius = radius * 1000;
                }

                json = [{
                    type :'FeatureCollection',
                    features:[{
                        type: 'Feature',
                        properties: {
                            _markerType: 'CircleMarker',
                            _radius: Number(radius)
                        },
                        geometry:{
                            type: 'Point',
                            coordinates: [newLatlng.lng, newLatlng.lat]
                        }
                    }]
                }];
            } else {
                var addList = [];
                var editLatlng = value.latlng;

                newFeatureGroup.eachLayer(lang.hitch(this, function(layer) {
                    var properties = layer.options;
                    var addData = [];
                    var regexp = /circle/ig;

                    if(regexp.test(properties.drawType)) {
                        // 緯度経度が一致する円のみJSONを作成
                        if(editLatlng.lat === layer._latlng.lat && editLatlng.lng === layer._latlng.lng) {
                            json = [{
                                type: 'FeatureCollection',
                                features: [{
                                    type: 'Feature',
                                    properties: {
                                        _color: properties.color,
                                        _weight: properties.weight,
                                        _opacity: properties.opacity,
                                        _fillColor: properties.fillColor,
                                        _fillOpacity: properties.fillOpacity,
                                        _markerType: 'CircleMarker',
                                        _radius: Number(properties.radius),
                                        fileList: properties.fileList,
                                        name: properties.title,
                                        description: properties.description
                                    },
                                    geometry: {
                                        type: 'Point',
                                        coordinates: [layer._latlng.lng, layer._latlng.lat]
                                    }
                                }]
                            }];
                            addData = {
                                unit: properties.unit,
                                drawType: properties.drawType
                            };
                            addList.push(addData);
                        }
                    }
                }));

                // 緯度経度が一致する円のみ削除
                var deleteLayer = this._layerControls[layerId];
                var deleteFeatureGroup = deleteLayer.featureGroup;
                deleteLayer.edit.disable();
                deleteFeatureGroup.eachLayer(lang.hitch(this, function(layer){
                    var regexp = /circle/ig;
                    if(regexp.test(layer.options.drawType)) {
                        if(editLatlng.lat === layer._latlng.lat && editLatlng.lng === layer._latlng.lng) {
                            deleteFeatureGroup.removeLayer(layer);
                        }
                    }
                }));
                this.map.pushState();
            }

            leaflet.tanJson(json).eachLayer(lang.hitch(this, function(layer){
                newFeatureGroup.addLayer(layer);

                // 図形の追加自身を地図の履歴に残す
                this.map.pushState();

                // 図形を移動したりしたときに履歴に残す
                // edit: 線,面の操作
                // dragend: マーカー,付箋の移動
                layer.on('edit dragend', lang.hitch(this, function() {
                    this.map.pushState();
                }));

                if(value.edit === false) {
                    if(value.unit === '1') {
                        radius = radius / 1000;
                    }
                    layer.radius = radius;
                    layer.unit = value.unit;
                    //ダイアログを呼び出す。
                    this._showEditDialog(layer, 'circleRadius', 'new');
                } else {
                    // 単位の追加、drawTypeを元に戻す
                    layer.options.unit = addList[0].unit;
                    layer.options.drawType = addList[0].drawType;
                }
            }));
        },

        // 編集ダイアログを表示（新規/編集共通）
        _showEditDialog: function(element, drawType, mode) {
            if (mode === 'new') {
                element.options.drawType = drawType;
            }

            var self = this;

            // 作図種別に応じて表示する編集ダイアログを決定し、表示
            switch (drawType) {
                case 'marker':
                case 'Icon':
                    if (self._editDialogIcon === null) {
                        self._editDialogIcon = new DrawEditDialog({
                            type : 'icon'
                        });
                    }
                    self._editDialogIcon.show(mode, element);
                    break;
                case 'polyline':
                case 'freehand_polyline':
                case 'arrow':
                    if (self._editDialogPolyline === null) {
                        self._editDialogPolyline = new DrawEditDialog({
                            type : 'polyline'
                        });
                    }
                    self._editDialogPolyline.show(mode, element);
                    break;
                case 'circle':
                case 'CircleMarker':
                case 'rectangle':
                case 'polygon':
                case 'freehand_polygon':
                    if (self._editDialogPolygon === null) {
                        self._editDialogPolygon = new DrawEditDialog({
                            type : 'polygon'
                        });
                    }
                    self._editDialogPolygon.show(mode, element);
                    break;
                case 'circleRadius':
                    if (self._editDialogPolygon === null) {
                        self._editDialogPolygon = new DrawEditDialog({
                            type : 'polygonRadius'
                        });
                    }
                    self._editDialogPolygon.show(mode, element);
                    break;
                case 'DivIcon':
                    if (self._editDialogNote === null) {
                        self._editDialogNote = new DrawEditDialog({
                            type : 'note'
                        });
                    }
                    self._editDialogNote.show(mode, element);
                    break;
                default:
                    console.log('not Found  @DrawPanel._showEditDialog');
                    return;
            }
        },

        /*---------- 削除除去系 ----------*/

        // すべてのModeをOffにする
        removeHandles: function() {
            //Draw
            df.forIn(this.mapObjects, function(handle) {
                handle.disable();
            });

            // Util
            this._activeLayerIds.forEach(lang.hitch(this, function(id){
                this._layerControls[id].edit.disable();
            }));
        },

        // ActiveModeを取消し、表示を初期化 (二次編集は対象外)
        disableAllMode: function() {
            this.removeHandles();
            this.inner.drawPanelDiagramDropDown.style.display = 'none';
            this.inner.drawPanelLineDropDown.style.display = 'none';
            if (this._ActiveMode !== null) {
                domClass.remove(this._ActiveMode, 'is-selected');
            }
            this._ActiveSubItem = null;
            this._ActiveMode = null;
        },

        // 閉じるはDisableにする
        hide: function(){
            this.inherited(arguments);
            this.disableAllMode();
        },

        // ページ遷移ですべてのイベントとDomを破棄
        destroy: function(){
            this.inherited(arguments);

            // イベント・リスナーを片付ける
            this._activeLayerIds.forEach(lang.hitch(this, function(id){
                this._layerControls[id].edit.off();
            }));

            // 作図モードから抜ける
            this.removeHandles();

            // 二次編集のイベント破棄
            if (!!this._secondControlAreaEvts){
                this._secondControlAreaEvts.remove();
            }

            // 自分の子供たちの処理
            var deleteSet = {
                //key  : [deleteTargetDialog, PubUrl]
                'icon' : [this._editDialogIcon, this.RM_EDIT_EVT],
                'line' : [this._editDialogPolyline, this.RM_EDIT_EVT],
                'diag' : [this._editDialogPolygon, this.RM_EDIT_EVT],
                'note' : [this._editDialogNote, this.RM_EDIT_EVT],
                'impt' : [this._importDialog, this.RM_IMPT_EVT],
                'expt' : [this._exportDialog, this.RM_EXPT_EVT]
            };

            // Dialogが生成されていれば子供が持っているイベントを破棄してからdestroy
            var dsKeys = Object.keys(deleteSet);
            for (var i = 0; i < dsKeys.length; i++) {
                var tgt = deleteSet[dsKeys[i]];
                if (!!tgt[0]) {
                    topic.publish(tgt[1]);
                    tgt[0].destroy();
                    console.log(dsKeys[i] + ' is destroied!');
                }
            }
        },

        /*---------- 初期化 ----------*/

        // 作図対象Layerを生成する
        createLayerControl: function() {
            // Contorlに必要なObjectを管理
            var controlObject = {};

            controlObject.featureGroup = new DrawFeatureGroup();

            controlObject.drawControl = new leaflet.Control.Draw({
                draw: false,
                edit: {
                    featureGroup: controlObject.featureGroup,
                    edit: false,
                    remove: false
                }
            });
            controlObject.edit = new leaflet.EditToolbar.Edit(this.map, controlObject.drawControl.options.edit);

            // mapに作図データを入れる
            this.map.addLayer(controlObject.featureGroup);
            // mapにControlを渡す
            this.map.addControl(controlObject.drawControl);

            return controlObject;
        },

        // 初期化
        setDrawObjects: function() {

            // IE対応: 作図中のMarkerの肥大化を止める
            leaflet.Browser.touch = false;

            var drawPolylineOptions = {
    //            draggable: true,
                showMeasurements: true,
                shapeOptions: {
                    opacity: 1,
                    fill: false,
                    bothArrow: false
                }
            };
            // 両端矢印用オプション
            var drawPolylineBothArrowOptions = {
                showMeasurements: true,
                shapeOptions: {
                    opacity: 1,
                    fill: false,
                    bothArrow: true
                }
            };
            var drawPolygonOptions = {
    //            draggable: true,
                showMeasurements: true,
                shapeOptions: {
                    opacity: 1,
                    fill: true
                }
            };

            this.mapObjects = {
                marker: new leaflet.Draw.CustomMarker(this.map),
                polyline: new leaflet.Draw.Polyline(this.map, drawPolylineOptions),
                arrow: new leaflet.Draw.Arrow(this.map, drawPolylineOptions),
                bothArrow: new leaflet.Draw.Arrow(this.map, drawPolylineBothArrowOptions),
                circle: new leaflet.Draw.Circle(this.map, drawPolygonOptions),
                radius: new DrawRadius(this.map, drawPolygonOptions),
                note: new leaflet.Draw.Note(this.map),
                rectangle: new leaflet.Draw.Rectangle(this.map, drawPolygonOptions),
                polygon: new leaflet.Draw.Polygon(this.map, drawPolygonOptions),
                freeHandPolyline: new leaflet.Draw.FreeHandPolyline(this.map, drawPolylineOptions),
                freeHandPolygon: new leaflet.Draw.FreeHandPolygon(this.map, drawPolygonOptions)
            };

            // 初期作図Layerは id = 0
            var newLayerId = 0;
            this._activeLayerIds.push(newLayerId);
            // 作図対象レイヤーの管理に入れる
            this._layerControls[newLayerId] = this.createLayerControl();
        },

        /**
         * 新規作図追加対象のレイヤー集合を取得する。
         * @returns {LayerGroup} 新規作図追加対象のレイヤー集合
         */
        getLayerGroupForNew: function() {
            // Activeなレイヤーが無い場合はnullを返す
            if (!this._activeLayerIds.length) {
                return null;
            }
            // 複数LayerがActiveだった場合はIDがもっとも若いLayerに追加する
            return this._layerControls[this._activeLayerIds[0]].featureGroup;
        },

        setDrawEvents: function(){

            //新規作図完了のイベント取得
            this.own(
                this.map.on('draw:created', lang.hitch(this, function(e) {
                    // どのLayerもActiveでない場合はここまで辿り着かせない。
                    var layerGroup = this.getLayerGroupForNew();
                    // 個別Optionを反映させるため、新規にインスタンスを立てて、無理やり入れる。
                    // TODO leaflet.drawを拡張してあげるのが良いのかも・・まったく別物になると思うが。
                    if (e.layerType === 'marker') {
                        var customIcon = new CustomIcon();
                        e.layer.options.icon = customIcon;
                    } else if (e.layerType === 'DivIcon') {
                        var customNote = new CustomDivIcon();
                        e.layer.options.icon = customNote;
                    }

                    layerGroup.addLayer(e.layer);

                    // 図形を移動したりしたときに履歴に残す
                    // edit: 線,面の操作
                    // dragend: マーカー,付箋の移動
                    e.layer.on('edit dragend', lang.hitch(this, function() {
                        this.map.pushState();
                    }));

                    //ダイアログを呼び出す。
                    this._showEditDialog(e.layer, e.layerType, 'new');
                }))
            );

            // 線,フリーハンドポリゴンの線分の途中を動かしたときに発動するイベント
            // これも地図の履歴に残す
            this.own(
                this.map.on('draw:editvertex', lang.hitch(this, function() {
                    this.map.pushState();
                }))
            );

            // 作図したものの外をクリックした場合、作図モードに入っているものがあったら抜ける
            this.own(
                this.map.on('click', lang.hitch(this, function() {
                    if (this._editingLayer) {
                        this._editingLayer.editing.disable();
                    }
                }))
            );


            // 二次編集コントロールエリアのイベント
            this.setSecondlyEditArea(this.inner.drawPanelSecondaryEditControlArea);

            // 作図編集ダイアログでの編集終了時の処理
            this.own(
                topic.subscribe(this.RESET_MODE_ID, lang.hitch(this, function(arg) {
                    // 「キャンセル」の場合、作図オブジェクトを削除
                    // "rerun"じゃなかったら作図Objectが入る
                    if (arg !== 'rerun') {
                        this.getLayerGroupForNew().removeLayer(arg);
                        // 矢印の場合は、矢印ヘッドを地図から削除
                        if (arg.options.drawType === 'arrow') {
                            if(arg.options.bothArrow === true) {
                                for(var i = 0; i <= arg.options.arrowHead.length -1; i++) {
                                    this.map.removeLayer(arg.options.arrowHead[i]);
                                }
                            } else {
                                this.map.removeLayer(arg.options.arrowHead);
                            }

                        }
                    }
                    // 再度同じ作図モードに入る
                    this.switchMode(this._ActiveMode);
                }))
            );

            // this.map/_mapが見えない人たちがHistoricalMapにアクセスできるようにする
            this.own(
                topic.subscribe(this.HISTORY_SAVE, lang.hitch(this, function() {
                    if (this.map instanceof HistoricalMap) {
                        this.map.pushState();
                    }
                }))
            );

            // 作図モードをすべてOffにして、DrawPanelを閉じる
            this.own(
                topic.subscribe(this.DISABLE_DRAW, lang.hitch(this, function() {
                    this.hide();
                }))
            );

            // tanjsonを新規作図レイヤーに入れる
            // 呼び元はDrawPanel以外なのでSubで受け取る
            this.own(
                topic.subscribe(this.DRAW_BY_JSON, lang.hitch(this, function(tanjson){
                    leaflet.tanJson(tanjson).eachLayer(lang.hitch(this, function(layer){
                        if(layer.options.drawType === 'arrow') {
                            var latlngs = layer.editing.latlngs[0].shift();
                            layer.editing.latlngs[0].length = 1;
                            layer._latlngs = latlngs;
                            layer.addTo(this._layerControls[0].featureGroup);
                            if (layer.options.drawType === 'arrow') {
                                layer.setArrowHead(layer.options);
                            }
                        } else {
                            layer.addTo(this._layerControls[0].featureGroup);
                        }
                    }));
                }))
            );

            // ActiveLayerのJSON化
            // 呼び元はDrawPanel以外なのでPubして結果を返す
            this.own(
                topic.subscribe(this.JSONIZE_RQST, lang.hitch(this, function(){
                    var tanjson = this._layerControls[0].featureGroup.toTanJSON();
                    topic.publish(this.JSONIZE_DONE, tanjson);
                }))
            );

            // subscribeでsaveDialogからリクエストを受け取る
            this.own(
                topic.subscribe(this.RQST_SAVE, lang.hitch(this, function(fileType){
                    // serverへ保存するときはgeojson指定
                    if (!fileType) {fileType='GeoJSON';}
                    var resOjb = this.saveFile(fileType);
                    // saveDialog に返却
                    topic.publish(this.RESP_SAVE, resOjb);
                }))
            );

            // subscribeでimportDialogからリクエストを受け取る
            this.own(
                topic.subscribe(this.IMPORT_FILE, lang.hitch(this, function(fileData){
                    // ファイルの中身だけを受け取る
                    this.importFile(fileData);
                }))
            );

            // TrafficRegulationDetailPageからの道路レイヤー追加リクエスト
            this.own(
                topic.subscribe(this.ADD_LAYER, lang.hitch(this, function(layer) {
                    layer.addTo(this._layerControls[0].featureGroup);
                }))
            );

            // TrafficRegulationDetailPageからの道路レイヤー削除リクエスト
            this.own(
                topic.subscribe(this.REMOVE_LAYER, lang.hitch(this, function(layer) {
                    var removeLayer;

                    this._layerControls[0].featureGroup.eachLayer(function(drawLayer) {
                        // geometryとpropertiesが同一だったらレイヤーを削除する
                        if (layer.getLatLngs().toString() === drawLayer.getLatLngs().toString() ||
                            layer.feature.properties === drawLayer.feature.properties) {
                            removeLayer = drawLayer;
                        }
                    });

                    if (removeLayer) {
                        this._layerControls[0].featureGroup.removeLayer(removeLayer);
                    }
                }))
            );

            // 全てのレイヤを削除するリクエスト
            this.own(
                topic.subscribe(this.REMOVE_ALL, lang.hitch(this, function() {
                    this._layerControls[0].featureGroup.eachLayer(function(drawLayer) {
                        this._layerControls[0].featureGroup.removeLayer(drawLayer);
                    }, this);
                }))
            );

            // Trafficの登録処理：PolylineをJSON化して返却し、作図の管理対象から削除する。
            this.own(
                topic.subscribe(this.ROAD_REG_REQ, lang.hitch(this, function() {
                    var polylineJSONArr = [];
                    // fing polyline feature and merge then, delete from draw's featureGruop
                    this._layerControls[0].featureGroup.eachLayer(lang.hitch(this, function(layer) {
                        if (layer.options.drawType &&
                            layer.options.drawType.toUpperCase().indexOf('POLYLINE') !== -1) {
                                polylineJSONArr.push(layer.toTanJSONFromOne());
                                this._layerControls[0].featureGroup.removeLayer(layer);
                        }
                    }));
                    topic.publish(this.ROAD_REG_RES, DrawUtil._mergeGeoJSON(polylineJSONArr, 'feature'));
                }))
            );

            // 半径から円を描画する
            this.own(
                topic.subscribe(this.RADIUS_DIALOG, lang.hitch(this, function(radius) {
                    this.radiusCircle(radius);
                }))
            );
        }
    });

    return container;

});
