define([
    'module',
    'dojo/_base/declare',
    'dojo/date/locale',
    'dgrid/Selection',
    'dgrid/Selector',
    'dgrid/Keyboard',
    'dstore/Memory',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function(module, declare, locale, Selection, Selector, Keyboard, Memory, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), [IdisGrid, Selection, Selector, Keyboard], {

        selectionMode: 'none',
        allowSelectAll: true,

        // SelectorのallowSelectAllオプションがエラーとなるので、
        // 仮の値としてメモリーストアをおく
        constructor: function() {
            this.collection = new Memory();
        },

        // IdisGrid拡張：行自体にCSSクラスを追加する場合に指定する
        // 規制解除日時が入力されている場合
        rowClassName: function(item) {
            return item && item.regEndTimestamp ? 'is-regEnd' : '';
        },

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            // チェックボックス
            {
                field: 'checkbox',
                label: 'checkbox',
                selector: 'checkbox'
            },
            // ボタン
            helper.buttonColumn('detail', '詳細'),

            helper.column('regionCd', '振興局', {
                formatter: function(value, item) {
                    return item.region.regionName + '';
                }
            }),
            helper.column('regReasonCd', '規制要因', {
                formatter: function(value) {
                    var REG_REASON = {
                        '01': '災害',
                        '02': '工事',
                        '03': '事前',
                        '99': 'その他'
                    };
                    return REG_REASON[value] || '';
                }
            }),
            helper.column('roadNum', '路線番号', {
            }),
            helper.column('roadName', '路線名', {
                formatter: function(value, item) {
                    if (item.roadName) {
                        return item.roadName;
                    } else {
                        return '';
                    }
                }
            }),
            helper.column('regStartPointName', '規制区間', {
                formatter: function(value, item) {
                    return (item.regStartPointName || '') + '<br>〜<br>' + (item.regEndPointName || '');
                }
            }),
            helper.column('seqNum', '報番号', {
                formatter: function(value, item) {
                    if (item.seqNum && item.seqNum !== '') {
                        return '第' + item.seqNum + '報';
                    } else {
                        return '';
                    }
                }
            }),
            helper.column('regLength', '延長', {
                formatter: function(value, item) {
                    if (item.regLength && item.regLength !== '') {
                        return item.regLength + ' km';
                    } else {
                        return '';
                    }
                }
            }),
            helper.column('regContentCd', '規制内容', {
                formatter: function(value, item) {
                    switch (item.regContentCd) {
                        case '01':
                            return '全面通行止め';
                        case '02':
                            return '車両通行止め';
                        case '03':
                            return '片側交互通行止め';
                        case '04':
                            return 'チェーン規制';
                        case '99':
                            // return item.regContentComment;
                            return 'その他';
                        default:
                            return '';
                    }
                }
            }),
            helper.column('regStartTimestamp', '規制日時', {
                formatter: function(value, item) {
                    var startDateStr = '';
                    if (item.regStartTimestamp) {
                        var startDate = new Date(item.regStartTimestamp);
                        startDateStr = locale.format(startDate);
                    }
                    var planedEndDateStr = '';
                    switch (item.regPlanedEndCd) {
                        case '1': // 日時指定
                            if (item.regPlanedEndTimestamp) {
                                var plandEndDate = new Date(item.regPlanedEndTimestamp);
                                switch (item.regPlanedEndTimestampCd) {
                                    case '1': // 確定
                                        planedEndDateStr = locale.format(plandEndDate) + '<br>（確定）';
                                        break;
                                    case '2': // 予定
                                        planedEndDateStr = locale.format(plandEndDate) + '<br>（予定）';
                                        break;
                                    default:
                                        planedEndDateStr = locale.format(plandEndDate);
                                        break;
                                }
                            }
                            break;
                        case '2':
                            planedEndDateStr = '未定';
                            break;
                        case '3':
                            planedEndDateStr = '当面の間';
                            break;
                        case '9': // その他
                            planedEndDateStr = item.regPlanedEndComment;
                            break;
                        default:
                            planedEndDateStr = '';
                            break;
                    }
                    var endDateStr = '';
                    if (item.regEndTimestamp) {
                        var endDate = new Date(item.regEndTimestamp);
                        endDateStr = locale.format(endDate) + '<br>（規制解除済み）';
                    }
                    if (endDateStr) {
                        return startDateStr + '<br>〜<br>' + endDateStr;
                    } else {
                        return startDateStr + '<br>〜<br>' + planedEndDateStr;
                    }
                }
            }),
            helper.column('bypassFlg', '迂回路', {
                formatter: function(value, item) {
                    switch (item.bypassFlg) {
                        case '0':
                            return 'なし';
                        case '1':
                            return item.bypassComment;
                        case '2':
                            return '確認中';
                        default:
                            return '';
                    }
                }
            }),
            helper.column('regContentComment', '規制理由', {
            }),
            helper.column('releaseFlg', '公開', {
                formatter: function(value, item) {
                    switch (item.releaseFlg) {
                        case '0':
                            return '非公開';
                        case '1':
                            return '公開';
                        default:
                            return '';
                    }
                }
            }),
            helper.column('crtTimestamp', '登録日時', {
                formatter: function(value, item) {
                    if (item.crtTimestamp && item.crtTimestamp !== '') {
                        var crtTimestampStr = new Date(item.crtTimestamp);
                        return locale.format(crtTimestampStr);
                    } else {
                        return '';
                    }
                }
            })
        ]
    });
});
