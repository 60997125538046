/**
 * メールテンプレート管理画面用モジュール。
 * @module app/broadnotify/BroadnotifyTemplateGridPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/BroadnotifyTemplateGridPage.html',
    'idis/control/Router',
    'idis/model/UserInfo',
    'idis/store/IdisRest',
    'idis/view/Loader',
    'idis/view/dialog/DialogChain',
    'idis/view/page/_PageBase',
    // 以下、変数として受け取らないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    './BroadnotifyTemplateGrid',
    './BroadnotifyTemplateDetailDialog',
    './BroadnotifyTemplateRegisterDialog'
], function(module, declare, lang, template, Router, UserInfo, IdisRest, Loader, 
        DialogChain, _PageBase) {
    /**
     * 検索条件格納用オブジェクト
     * @type {Object}
     */
     var filterStore = {};
    /**
     * メールテンプレート管理画面のイベント
     * @class DeliveredBroadnotifyGridPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/broadnotify/BroadnotifyTemplateGridPage~BroadnotifyTemplateGridPage# */ {

        // テンプレート文字列
        templateString: template,
        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--broadnotifytemplate',

        /**
         * データ格納用オブジェクト
         * @type {module:dstore/Store}
         */
        store: null,

        /**
         * 詳細画面の処理対象となるデータのID
         */
        _notifyTemplateId: null,

        /**
         * コンストラクタ
         */
        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'broadnotifyTemplateId',
                target: '/api/broadnotify/templates'
            });
            // ダイアログ連鎖を登録
            // 引数に与えたウィジェットのthis.ownを呼び出し、
            // ウィジェットが破棄された際にダイアログ連鎖が破棄されるようになる
            this.chain = DialogChain.get(this);
        },

        /**
         * 画面のDOM要素を組み立てる際に呼ばれる
         */
        buildRendering: function() {
            this.inherited(arguments);
            // 自分が削除された時は関連ダイアログも削除する
            this.own(this.detailDialog);
            this.own(this.registerDialog);
        },

        startup: function() {
            this.inherited(arguments);
            // グリッドを初期化する
            this.initGrid();
            // 新規登録画面内のフォームがsubmitされた際の動作を設定
            this.initRegisterDialog();
            // 詳細画面内のボタンから戻った際の動作を設定
            this.initDetailDialog();
        },

        /**
         * グリッドの検索条件を指定された値で更新する。
         */
        updateGridQuery: function() {
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();
            var value = this.form.get('value');
            // 件名
            if (value.searchSubject) {
                filter = filter.eq('searchSubject', value.searchSubject);
            }
            // 種別
            if (value.notifyType) {
                filter = filter.eq('notifyType', value.notifyType);
            }
            // 管理者
            if (value.admin) {
                filter = filter.eq('admin', value.admin);
            }
            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);
            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.grid.set('collection', collection);
            //検索条件の保管
            this.setFilterStore();
        },

        /**
         * 検索ボタンが押されたときに呼び出される。
         */
        onSubmit: function() {
            try {
                if (this.form.validate()) {
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery(this.form.get('value'));
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * テンプレート詳細画面からの戻りイベントの登録
         */
        initDetailDialog: function() {
            
            var dialog = this.detailDialog;
            // ダイアログの最初の子要素が帳票出力画面
            var page = this.detailDialog.getChildren()[0];

            var self = this;
            // 更新ボタンが押された場合のイベント
            page.on('update', lang.hitch(this, function(event) {
                console.debug('updateイベント');

                // 更新確認ダイアログを表示
                this.chain.confirmPut(function(chain) {
                    // フォームの値を取得
                    var formValues = event.value;
                    // フォーム内容をGridに反映→サーバー側更新処理
                    var promise = this.store.put(formValues);
                    // 更新処理と共にローディング表示
                    Loader.wait(promise).then(function() {
                        // 成功時
                        self.onSubmit();
                        // 詳細ダイアログを閉じる
                        dialog.hide();
                        // 完了ダイアログを表示
                        chain.infoComplete();
                    }, function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));
            
            // 削除ボタンが押された場合のイベント
            page.on('delete', lang.hitch(this, function() {
                console.debug('deleteイベント');

                // 削除確認ダイアログを表示
                this.chain.confirmDel(function(chain) {
                    // ダイアログに表示していたIDを取得
                    var broadnotifyTemplateId = this._broadnotifyTemplateId;
                    // Gridから対象データを削除→サーバー側更新処理
                    var promise = this.store.remove(broadnotifyTemplateId);
                    // 更新処理と共にローディング表示
                    Loader.wait(promise).then(function() {
                        // 成功時
                        self.onSubmit();
                        // 詳細ダイアログを閉じる
                        dialog.hide();
                        // 完了ダイアログを表示
                        chain.infoComplete();
                    }, function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));

        },
        
        /**
         * 詳細ダイアログを表示する。
         * @param {Object} item 参照する行のデータ
         */
        showDetailDialog: function(item) {
            // ダイアログの最初の子要素が詳細画面
            var page = this.detailDialog.getChildren()[0];
            // 画面上のフォームをリセット
            // ダイアログ内画面のテンプレートHTMLでdata-dojo-attach-point="form"指定済み
            page.form.reset();

            // 選択されていた詳細データを渡してダイアログを初期化
            // ダイアログ側の処理で再検索を行う
            page.initDialog(item);

            // 詳細画面に表示するIDを記録
            this._broadnotifyTemplateId = item.broadnotifyTemplateId;
            
            // 詳細ダイアログを表示
            this.detailDialog.show();
        },

        // パンくずリストのボタンを押下したときの挙動
        onBroadnotifyListPageLinkClick : function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('broadnotify');
        },

        /**
         * 新規登録ダイアログからの戻りイベントの定義
         */
        initRegisterDialog: function() {
            // ダイアログの最初の子要素が帳票出力画面
            var dialog = this.registerDialog;
            var page = dialog.getChildren()[0];

            var self = this;
            // 登録ボタンが押された場合のイベント
            page.on('register', lang.hitch(this, function(event) {
                console.debug('registerイベント');
                // 追加確認ダイアログを表示
                this.chain.confirmAdd(function(chain) {
                    // OKが押された場合
                    // 追加処理と共にローディング表示
                    Loader.wait(this.store.add(event.value)).then(function() {
                        // 成功時
                        self.onSubmit();
                        // 登録ダイアログを閉じる
                        dialog.hide();
                        // 完了ダイアログを表示
                        chain.infoComplete();
                    }, function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));
        },

        /**
         * 新規登録ダイアログを表示する
         */
        showRegisterDialog: function() {
            console.debug('[新規登録]ボタンがクリックされました。');
            // ダイアログの最初の子要素が登録画面
            var page = this.registerDialog.getChildren()[0];
            // 画面上のフォームをリセット
            // ダイアログ内画面のテンプレートHTMLでdata-dojo-attach-point="form"指定済み
            page.form.reset();
            // 登録画面ダイアログを表示
            this.registerDialog.show();
        },

        /**
         * グリッドを初期化する。
         */
        initGrid: function() {
            // グリッドの詳細ボタンクリック時の動作を設定する
            this.grid.on('detailButtonClick', lang.hitch(this, function(evt) {
                // helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
                this.showDetailDialog(evt.item);
            }));
            // デフォルト検索値でグリッドを検索
            // 保管した検索条件をセットする
            this.setFilterData();
            this.updateGridQuery();

        },

        /**
         * 検索条件を保管する
         */
        setFilterStore: function() {
            //初期化する
            filterStore = {};
            var value = this.form.get('value');

            // タイトル
            if(value.searchSubject){
                filterStore.searchSubject = value.searchSubject;
            }
            // 通知種別
            if(value.notifyType){
                filterStore.notifyType = value.notifyType;
            }
            // 管理者
            if(value.admin){
                filterStore.admin = value.admin;
            }
        },
  
        /**
          * 保管した検索条件をフォームにセットする
          */
        setFilterData: function() {

            // タイトル
            if(filterStore.searchSubject){
                this.searchSubject.set('value', filterStore.searchSubject);
            }
            // 通知種別
            if(filterStore.notifyType){
                this.notifyType.set('value', filterStore.notifyType);
            }
            // 管理者
            if(filterStore.admin){
                this.admin.set('value', filterStore.admin);
            }           
        },

        /**
         * 画面の初期値を取得する
         */
        _getDefaultSearchValues: function() {

            // デフォルト検索値でグリッドを検索
            var defaultSearchValues = {
                    // 通知種別
                    NotifyType: '00'
                    //searchLanguage: 'ja'

            };
            return defaultSearchValues;
        }

    });
});

