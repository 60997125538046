/**
 * 観測情報を地図上で見せる画面
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/ObservationMapPage.html',
    'dojo/topic',
    'dojo/dom-style',
    'idis/view/page/_PageBase',
    'idis/control/Router',
    'idis/control/Locator',
    'idis/map/IdisMap',
    'idis/view/dialog/IdisDialog',
    'app/config',
    'app/consts/APP_QUERY',
    'app/map/legend/LegendPane',
    './DateTimeSelect',
    'idis/consts/QUERY',
    'idis/service/Requester',
    'idis/model/UserInfo',
    // 以下、変数で受けないモジュール,
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/form/Form',
    'dijit/form/Select',
    'app/map/LayerSelector'
], function(module, declare, lang, template, topic, domStyle, _PageBase, Router,
    Locator, IdisMap, IdisDialog, config, APP_QUERY, LegendPane, DateTimeSelect, QUERY, Requester, UserInfo) {

    return declare(module.id.replace(/\//g, '.'), _PageBase, {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--observationMap',

        // 雨量レイヤーID
        LAYER_ID: {
            rain10: 35,
            rain60: 36
        },

        buildRendering: function() {
            this.inherited(arguments);

            // 日付セレクトボックスを生成・設置する
            this.dateTimeSelect = new DateTimeSelect({
                mode: '10',
                type: 'rain'
            });
            this.dateTimeSelect.placeAt(this.dateTimeSelectNode, 'only');
        },

        startup: function() {
            this.inherited(arguments);

            // マップの初期化
            this.map = new IdisMap(this.id + '-map', {
                config: config.map,
                keyboard: false,        // コメント時に+/-が使用できないため
                touchExtend: false,     // IE対応
                drawControlTooltips: false,
                zoom: 9,
                minZoom: 5            // 日本全体が表示される程度の範囲が最小
            });
            // mapのdestroyはremoveでDojoと互換なのでownで消せる
            this.own(this.map);

            var municipalityCds = UserInfo.getMunicipalityCds();

            //地図の位置を変更
            Requester.get('/api/municipalities/' + municipalityCds[0], {
                headers: {'Content-Type': 'application/json; charset=utf-8'},
                handleAs: 'json',
                preventCache : true
            }).then(lang.hitch(this, function(municipality) {
                // マップを初期化
                if(municipality.latitude && municipality.longitude){
                    this.map.setView([municipality.latitude,municipality.longitude]);
                }
            }), lang.hitch(this, function(error) {
                console.log(error);
                this.chain.info('情報の取得に失敗しました。', 'エラー');
            }));
        },

        /**
         * DOM構築後に呼ばれる
         */
        postCreate: function() {
            this.inherited(arguments);
            // 観測日時ウィジェット構築前に、日時の変更を監視設定
            this.own(topic.subscribe(DateTimeSelect.TOPIC.CHANGE_DATE_TIME, lang.hitch(this, function(data) {
                // 日時が変更されたらURLを更新する
                // URLを更新することで、ObservatoryLayerのLocatorのchangeイベントが発火し、指定日時のレイヤー構築が行われる
                Locator.replaceState(APP_QUERY.DATETIME, data.date + '-' + data.time.replace(/:/g, '-'));
            })));

            // 地図ポップアップ内の「詳細」リンクに対応
            // SPA対応
            this.on('a:click', lang.hitch(this, function(e) {
                var href = e.target.href;
                // 現在のパスと異なる場合のみ実行
                // ページ指定になっていない場合はそのまま通す
                if (!Locator.isCurrentPath(href)) {
                    // ブラウザーの遷移処理をキャンセル
                    e.preventDefault();
                    // ページへ遷移
                    Locator.pushState(Locator.getQueryFrom(href), true);
                }
            }));
            this.initLayerSelector();
        },

        /**
         * レイヤーセレクタの初期化
         */
        initLayerSelector: function() {
            // 他画面からの遷移で指定がある場合、初期レイヤーをセット
            if (Locator.getQuery().layerId) {
                this.layerSelector.initialLayers = [Number(Locator.getQuery().layerId)];
            }
            // 岡山では観測概況の雨量のみラベル名変更
            this.layerSelector._layerMap[35].name = '雨量情報';
            this.layerSelector._layerMap[36].name = '雨量情報';
        },

        /**
         * 凡例ダイアログを表示する。
         */
        toggleLegendDialog: function() {
            if (!this._legendDialog) {
                // 初回呼び出し時にインスタンス生成
                this._legendDialog = new IdisDialog({
                    noUnderlay: true,
                    title: '凡例',
                    content: new LegendPane()
                });
                // 画面が破棄された際に連れて行く
                this.own(this._legendDialog);
            }
            if (this._legendDialog.open) {
                this._legendDialog.hide();
            } else {
                this._legendDialog.show();
            }
        },

        /**
         * 時間モードが変更された際に呼ばれる
         */
        onChangeModeSelect: function(value) {
            // 観測時間選択の表示を切り替える
            this.dateTimeSelect.changeMode(value);
            Locator.replaceState(APP_QUERY.MODE, value);
            // 時間モードによって、雨量レイヤーを変更
            this.toggleRainLayer(value);
        },

        /**
         * 雨量情報ボタン表示対象レイヤー切替え
         */
        toggleRainLayer: function(timeMode) {
            var hideLayerId;
            var showLayerId;
            if (timeMode==='10') {
                // 10分雨量を表示
                showLayerId = this.LAYER_ID.rain10;
                hideLayerId = this.LAYER_ID.rain60;
            } else {
                // 60分雨量を表示
                showLayerId = this.LAYER_ID.rain60;
                hideLayerId = this.LAYER_ID.rain10;
            }
            // 雨量情報選択ボタン押下による表示対象レイヤーを変更
            this.layerSelector.allowedLayers.splice(0, 1, showLayerId);
            // フォルダのID指定を基に、項目一覧を更新する
            this.layerSelector.updateLayerSelectorList();
            // 雨量情報が選択中の場合、表示レイヤーを切り替える
            if (Locator.getLayerQuery()[hideLayerId]) {
                // 排他制御により、選択解除レイヤーは非表示になる
                this.layerSelector.showLayer(showLayerId);
            }
        }
    });
});
