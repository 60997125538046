/**
 * 災害対策本部設置登録_配信確認ダイアログ
 * @module app/provide/ProvidePreventionRegisterDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/topic',
    'dojo/text!./templates/ProvidePreventionRegisterDialog.html',
    'idis/util/DateUtils',
    'idis/store/IdisRest',
    'idis/view/Loader',
    'app/model/DisasterInfo',
    'app/model/Municipality',
    './_ProvideDialogBase',
    'app/config',
    // 以下、変数から参照されないモジュール
    'dijit/form/Form',
    'idis/view/form/WordCountTextarea',
    'idis/view/form/Button',
    'idis/view/form/ReadOnlyInput',
    './form/DistributionTypeInput',
    'app/view/form/ManageRegionMunicipalitySelector'
], function(module, declare, lang, domStyle, topic, template,
    DateUtils, IdisRest, Loader, DisasterInfo, Municipality, _ProvideDialogBase, config) {
    /**
     * 災害対策本部設置登録_配信確認ダイアログ
     * @class ProvidePreventionRegisterDialog
     * @extends module:app/provide/_ProvideDialogBase~_ProvideDialogBase
     */
    return declare(module.id.replace(/\//g, '.'), _ProvideDialogBase,
        /** @lends module:app/provide/_ProvideDialogBase~_ProvideDialogBase# */ {
        // テンプレート文字列
        templateString: template,

        // 登録用ストア
        registerStore: null,

        // 設置解散日時
        _headquarterDatetime: null,

        //県の市町村コード
        MUNICIPALITY_CD_KUMAMOTO_PREF: config.municInfo.prefMunicCd,

        //県コード
        REGION_CD_KUMAMOTO_PREF: config.municInfo.prefCd,

        // ベースクラスのコンストラクタでStoreを生成。
        constructor: function() {
            // 登録用ストア
            this.registerStore = new IdisRest({
                target: '/api/lalert/send'
            });
            this._disasterId =  DisasterInfo.getDisasterId();
        },

        /**
         * 体制情報を元に本部種別を返す。
         * @param {Object} dpData 体制情報
         * @param {string} dpData.status 体制状況
         * @param {string} dpData.hqSetFlg 本部設置状況
         * @param {string} [dpData.lastStatus] 前回の体制状況
         * @returns {string} 本部種別文字列
         */
        getHeadquarterName: function(dpData) {
            // 解散時は前回の本部名をセット
            var name = dpData.status !== '999999' ? dpData.headquarterName : dpData.lastHqName;
            name = name==='解除' ? '防災体制' : name;
            return name;
        },

        /**
         * 体制情報を元に本部設置状況を返す。
         * @param {Object} dpData 体制情報
         * @param {string} dpData.status 体制状況
         * @param {string} dpData.hqSetFlg 本部設置状況
         * @returns {string} 本部設置状況
         */
        getHeadquarterStatusName: function(dpData) {
            // 体制解除または本部解散の場合は解散とする
            return (dpData.status === '999999' || dpData.hqSetFlg === '2') ? '解散' : '設置';
        },

        /**
         * 体制情報を元にテンプレートを返す。
         * @param {Object} obj 体制情報
         * @returns {string} 本部設置状況
         */
        getTemplate: function(obj) {
            // 県の場合、振興局CDが渡されているため、市町村CDに変換する。
            var muniCd = obj.areaCd===this.REGION_CD_KUMAMOTO_PREF ?
                this.MUNICIPALITY_CD_KUMAMOTO_PREF : obj.areaCd;
            var hqName = this.getHeadquarterName(obj);
            var hqStatus = this.getHeadquarterStatusName(obj);

            Municipality.store.get(muniCd).then(lang.hitch(this, function(item) {
                this.title.set('value', item.name + hqName + hqStatus + 'のお知らせ');
                this.contents.set('value', item.name + 'が、' + this._headquarterDatetime + 'に' +
                    hqName + 'を' + hqStatus + 'しました。');
            }));
        },

        // ベースクラスをオーバーライド
        // 画面から渡された値で初期表示設定を行う。
        initDialog: function(obj){
            // // 配信種別をセットする。
            // this.sendCategoryNode.innerHTML = this.getCategoryName(obj.sendCategory);
            // 市町村コードをセットする。
            this.municipalityCd.set('value', obj.areaCd);
            // 本部種別
            var hqTypeName = this.getHeadquarterName(obj);
            // 本部種別を設定
            this.headquarterTypeInput.set('value', hqTypeName);
            //本部名
            this.headquarterName.innerHTML = hqTypeName;

            // 最新の災害情報を取得
            // Municipality.store.get(obj.municipalityCd).then(lang.hitch(this, function(item) {
            //     // 市町村名と本部種別から対策本部名を設定
            //     this.headquarterNameInput.set('value', item.name + hqTypeName);
            // }));

            this.lalertSendHistId = obj.lalertSendHistId;

            // 更新種別の設定
            var distType = obj.distributionType;
            // 01（新規）で過去報が見つかった場合、02（更新）として扱う
            if (distType === '01' && obj.lalertSendHistId) {
                distType = '02';
            }
            // 更新と訂正を共通では分けていないため、ここでは統一する。
            var dt = distType === '12' ? '02' : distType;
            this.distributionTypeInput.set('value', dt);

            // 更新種別
            domStyle.set(this.errataDescriptionItemNode, 'display', distType === '02'||distType === '03' ?
                '' : 'none');
            // 設置状況をセットする。
            this.headquarterStatusNode.innerHTML = this.getHeadquarterStatusName(obj);
            // 設置解散日時をセットする。
            this._headquarterDatetime = DateUtils.format(new Date(obj.headquarterDatetime));
            this.headquarterDatetimeNode.innerHTML = this._headquarterDatetime;
            //公開先をセットする。
            this.channel.innerHTML = obj.channel;
            // テンプレートをセットする。
            this.getTemplate(obj);
            //キャンセルボタン
            this.own(this.cancelBtn.on('click', lang.hitch(this, function() {
                this.getParent().hide();
                return false;
            })));

        },

        // テンプレートHTML内でformに対し、data-dojo-attach-event="onSubmit:onSubmit"でこのメソッドを紐付け
        // 登録ボタンをtype="submit"にしているので、ボタン押下時にフォームがsubmitされて呼ばれる
        onSubmit: function() {
        // プロト用
        this._closeDialog();
        return false;
        //     // 訂正確認画面ダイアログを表示する。
        //     var form = this.regForm.get('value');
        //     form.subject = '災害対策本部設置状況発信';

        //     // 災害IDをformにセットする。
        //     form.disasterId = this._disasterId;

        //     // 配信情報種別をformにセットする。
        //     form.sendCategory = this.PREVENTION_CONTENT_TYPE;

        //     if (form.distributionType === '01') {
        //         // 新規の場合は『訂正・取消理由』欄を含めない
        //         delete form.errataDescription;
        //     } else {
        //         // 新規でない場合は配信履歴IDを渡す
        //         form.lalertSendHistId = this.lalertSendHistId;
        //     }

        //     // 現在の時刻を配信日時としてformにセットする。
        //     form.sendTimestamp = new Date();

        //     // bodyTextに空文字をセットする
        //     form.bodyText = ' ';

        //     // 設置状況をformにセットする。
        //     form.headquarterStatus = this.headquarterStatusNode.innerHTML;

        //     // 設置解散日時をセットする。
        //     form.headquarterDatetime = this._headquarterDatetime;

        //     this.chain._confirmFor('配信', lang.hitch(this,function(chain) {
        //         Loader.wait(this.registerStore.add(form)).then(lang.hitch(this,function() {
        //             // 完了ダイアログを表示
        //             chain.infoComplete(lang.hitch(this, function() {
        //                 // ダイアログを閉じる。
        //                 this._closeDialog();
        //                 topic.publish(module.id + '::added', '');
        //             }, function(err) {
        //                 // 失敗時にはエラーダイアログを表示
        //                 chain.infoError(err, function(){
        //                     // ダイアログを閉じる。
        //                     this._closeDialog();
        //                 });
        //                 console.debug(err);
        //             }));
        //         }));
        //     }));
        //     return false;
        }

    });
});
