/**
 * 緊急速報メール配信ダイアログ
 * @module app/provide/ProvideUrgentMailRegisterDialog
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'dojo/topic',
    'dojo/text!./templates/ProvideUrgentMailRegisterDialog.html',
    'app/provide/_ProvideDialogBase',
    'app/model/DisasterInfo',
    'idis/store/IdisRest',
    'idis/control/Router',
    'idis/view/Loader',
    'idis/view/dialog/InfoDialog',
    'idis/model/UserInfo',
    // 以下、変数から参照されないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/form/Form',
    'dijit/form/TextBox',
    'dijit/form/ValidationTextBox',
    'idis/view/form/WordCountTextarea',
    'idis/view/form/DateTimeInput',
    'idis/view/form/Button',
    './ProvideTempInsertGrid',
    'app/view/form/MunicipalityUsingUrgentMailSelector',
    'app/view/form/MunicipalityListMultiSelector',
    './form/ProvideContentTextarea'
], function(module, array, declare, lang, locale, topic, template, _ProvideDialogBase,
    DisasterInfo, IdisRest, Router, Loader, InfoDialog, UserInfo) {
    /**
     * 緊急速報メール配信ダイアログ
     * @class ProvideRegisterUrgentMailDialog
     * @extends module:app/provide_ProvideDialogBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), [_ProvideDialogBase],
        /** @lends module:app/provide/_ProvideDialogBase~_ProvideDialogBase# */ {
        // テンプレート文字列
        templateString: template,

        // 登録用ストア
        registerStore: null,

        // データ保管用
        _obj: null,

        // 避難状況が登録される市町
        _municipalityCd: null,

        // ベースクラスのコンストラクタでStoreを生成。
        constructor: function() {
            this.inherited(arguments);
            // 登録用ストア
            this.registerStore = new IdisRest({
                target: '/api/lalert/send'
            });
            // 管理対象市町リストの一つ目を設定する
            var municipalityCds = UserInfo.getMunicipalityCds();
            this._municipalityCd = municipalityCds[0];
        },

        buildRendering: function() {
            this.inherited(arguments);
            // TODO テンプレート機能は未実装
            // 04: お知らせのテンプレートに関する機能
            // this.own(this.templatePrefInsertDialog);
            // this.initPrefTemplateProvGrid();
        },

        // ベースクラスをオーバライド
        // 画面から渡された値で初期表示設定を行う。
        initDialog: function(obj) {
            this._obj = obj;
            // 配信種別をセットする。
            this.sendCategoryNode.innerHTML = this.getCategoryName(obj.sendCategory);

            // 発信元をセットする。
            this.municipalityUsingUrgentMailSelector._setValueAttr(this._municipalityCd);

            // 対象市町をセットする。
            var municipalityArray = [obj.municipalityCd];
            this.municipalityListMultiSelector._setValueAttr(municipalityArray);

            // 配信元・対象市町が存在する場合は対象市町を選択不可にして固定値としてセットする。
            if(obj.municipalityCd !== null) {
                this.municipalityUsingUrgentMailSelector._setValueAttr(obj.municipalityCd);
                this.municipalityUsingUrgentMailSelector.set('noAllButton', true);
                this.municipalityListMultiSelector.set('noAllButton', true);
            }

            // タイトルの初期値
            this.subjectInput.set('value', '緊急速報メール情報発信');
            // メッセージボディを作成して画面にセットする。
            this.bodyText._setValueAttr(this.createEvacOrderMailBody(obj));

        },

        // テンプレートHTML内内でformに対し、data-dojo-attach-event="onSubmit:onSubmit"でこのメソッドを紐付け
        // 登録ボタンをtype="submit"にしているので、ボタン押下時にフォームがsubmitされて呼ばれる
        onSubmit: function() {
            // 訂正確認画面ダイアログを表示する。
            var form = this.regForm.get('value');

            // 災害IDをformにセットする。
            form.disasterId = DisasterInfo.getDisasterId();

            // 対象市町をformにセットする。
            var tLalertSendTargetList = [];
            this.municipalityListMultiSelector.value.forEach(function(item){
                var target = {'targetMunicipalityCd' : item};
                tLalertSendTargetList.push(target);
            });
            form.tLalertSendTargetList = tLalertSendTargetList;


            // 配信情報種別をformにセットする。
            form.sendCategory = this.URGENT_MAIL_CONTENT_TYPE;

            // 現在の時刻を配信日時としてformにセットする。
            form.sendTimestamp = new Date();

            try{
                if (this.regForm.validate()){
                    // APIを呼んで新規登録
                    if(!form.tLalertSendTargetList[0]){
                        console.debug('対象市/行政区が選択されていません');
                        if (!this.infoDialog) {
                            this.infoDialog = new InfoDialog({
                                title : 'エラー',
                                content : '対象市/行政区が選択されていません'
                            });
                        }
                        this.infoDialog.show();
                        this.infoDialog = null;
                    } else if(!form.tLalertSendTargetList[0].targetMunicipalityCd) {
                        console.debug('対象市/行政区が選択されていません');
                        if (!this.infoDialog) {
                            this.infoDialog = new InfoDialog({
                                title : 'エラー',
                                content : '対象市/行政区が選択されていません'
                            });
                        }
                        this.infoDialog.show();
                        this.infoDialog = null;
                    } else if (this.isEmptyText(this.bodyText.get('value'))) {
                        console.debug('配信内容が入力されていません');
                        if (!this.infoDialog) {
                            this.infoDialog = new InfoDialog({
                                title : 'エラー',
                                content : '配信内容が入力されていません'
                            });
                        }
                        this.infoDialog.show();
                        this.infoDialog = null;
                    } else if (this.isEmptyText(this.subjectInput.get('value'))) {
                        console.debug('タイトルが入力されていません');
                        if (!this.infoDialog) {
                            this.infoDialog = new InfoDialog({
                                title : 'エラー',
                                content : 'タイトルが入力されていません'
                            });
                        }
                        this.infoDialog.show();
                        this.infoDialog = null;
                    } else if (!this.bodyTextValidate(this.bodyText.get('value'))) {
                        console.debug('配信内容に電話番号・URL・メールアドレスを含むことはできません');
                        if (!this.infoDialog) {
                            this.infoDialog = new InfoDialog({
                                title : 'エラー',
                                content : '配信内容に電話番号・URL・メールアドレスを含むことはできません'
                            });
                        }
                        this.infoDialog.show();
                        this.infoDialog = null;
                    } else if (!this.bodyTextValidate(this.subjectInput.get('value'))) {
                        console.debug('タイトルに電話番号・URL・メールアドレスを含むことはできません');
                        if (!this.infoDialog) {
                            this.infoDialog = new InfoDialog({
                                title : 'エラー',
                                content : 'タイトルに電話番号・URL・メールアドレスを含むことはできません'
                            });
                        }
                        this.infoDialog.show();
                        this.infoDialog = null;
                    } else if (this.countText(this.bodyText.get('value')) > 200) {
                        var message4body = '内容は200文字以内で入力してください。(現在' +
                        this.countText(this.bodyText.get('value')) + '文字)';
                        console.debug(message4body);
                        if (!this.infoDialog) {
                            this.infoDialog = new InfoDialog({
                                title : 'エラー',
                                content : message4body
                            });
                        }
                        this.infoDialog.show();
                        this.infoDialog = null;
                    } else if (this.countText(this.subjectInput.get('value')) > 15) {
                        var message4subject = 'タイトルは15文字以内で入力してください。(現在' +
                        this.countText(this.subjectInput.get('value')) + '文字)';
                        console.debug(message4subject);
                        if (!this.infoDialog) {
                            this.infoDialog = new InfoDialog({
                                title : 'エラー',
                                content : message4subject
                            });
                        }
                        this.infoDialog.show();
                        this.infoDialog = null;
                    } else {
                        this.chain._confirmFor('配信', lang.hitch(this,function(chain) {
                            Loader.wait(this.registerStore.add(form)).then(lang.hitch(this,function() {
                                // 完了ダイアログを表示
                                chain.infoComplete(lang.hitch(this, function() {
                                    // ダイアログを閉じる。
                                    this._closeDialog();
                                    // 避難状況登録から緊急速報メールを配信する場合には配信後に避難状況一覧に遷移
                                    if(this._obj.municipalityCd !== null) {
                                        Router.moveTo('evacorder', {
                                            municipalityCd: this._obj.municipalityCd
                                        });
                                        return;
                                    }
                                    // 配信情報一覧から緊急速報メールを配信する場合には
                                    // 配信後に配信情報一覧ページ(ProvideInfomationPage)のグリッドを更新する
                                    topic.publish(module.id + '::added', '');
                                }, function(err) {
                                    // 失敗時にはエラーダイアログを表示
                                    chain.infoError(err, function(){
                                        // ダイアログを閉じる。
                                        this._closeDialog();
                                    });
                                    console.debug(err);
                                }));
                            }));
                        }));
                    }
                }
            } catch(e) {
                console.error('入力チェックでエラーが発生しました。', e);
            }
            // デフォルトのonSubmitイベントをキャンセルする。
            return false;
        },

        /**
         * キャンセルをおして配信しないときの動作
         * 緊急速報メール送信APIは呼ばず, ダイアログを消し, 一覧ページに戻る
         */
        onCancel: function() {
            this._closeDialog();

            // 避難状況登録から緊急速報メールを配信する場合には配信後に避難状況一覧に遷移
            if(this._obj.municipalityCd !== null) {
                Router.moveTo('evacorder', {
                    municipalityCd: this._obj.municipalityCd
                });
                return;
            }
            // 配信情報一覧から緊急速報メールを配信する場合には
            // 配信後に配信情報一覧ページ(ProvideInfomationPage)のグリッドを更新する
            topic.publish(module.id + '::added', '');
        },

        // 配信内容にURL、電話番号、メールアドレスが含まれていないかをチェック
        bodyTextValidate: function(text) {
            //URL正規表現パターン
            var chkURL = /(https?|ftp)(:\/\/[-_.!~*\'()a-zA-Z0-9;\/?:\@&=+\$,%#]+)/;
            // メールアドレス正規表現パターン({1}@{2} のような形式)
            var chkMail = /([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+/;
            // 電話番号正規表現パターン1(最初１−５桁)
            var chkTel1 = /\d{1,5}-\d{1,4}-\d{4}/;
            // 電話番号正規表現パターン2(最初２桁)
            var chkTel2 = /0\d-\d{4}-\d{4}/;
            // 電話番号正規表現パターン3(携帯電話)
            var chkTel3 = /(050|070|080|090)-\d{4}-\d{4}/;
            // 電話番号正規表現パターン4(フリーダイヤル)
            var chkTel4 = /0120-\d{3}-\d{3}/;

            // URLが含まれているかチェック
            if (text.match(chkURL)) {
                console.debug('配信内容にURLが含まれています');
                return false;
            }
            // メールアドレスが含まれているかチェック
            else if (text.match(chkMail)) {
                console.debug('配信内容にメールアドレスが含まれています');
                return false;
            }
            // 電話番号が含まれているかチェック(4種類)
            else if (text.match(chkTel1) ||
                text.match(chkTel2) ||
                text.match(chkTel3) ||
                text.match(chkTel4)) {
                console.debug('配信内容に電話番号が含まれています');
                return false;
            } else {
                return true;
            }
        },

        // 文面が空白のみで作られているかどうかチェックする。
        isEmptyText: function(text) {
            if (!text)  {
                return false;
            }

            text = text.replace(/\s/g, '');
            if (text.length > 0) {
                return false;
            } else {
                return true;
            }
        },
        // 文字数をカウントする。ただし改行は二文字とカウントする。
        countText: function(text) {
            text = text.replace(/\n/g, '\n\n');
            return text.length;
        },

        createEvacOrderMailBody: function(data) {
            var str;
            var evacOrder = data.evacOrderData;
            var shelterList = data.shelterList;
            var districtList = data.districtList;
            if(evacOrder.evacOrderType !== null) {
                // 避難情報発令日時
                var evacTimestamp = locale.format(evacOrder.evacTimestamp, {
                    selector: 'date',
                    datePattern: 'yyyy/MM/dd HH:mm'
                });
                // 本文を生成する。
                str = evacTimestamp + 'に' + this.getAlertLevel(evacOrder.issueReasonType , evacOrder.evacOrderType) + 
                            this.getEvacuateSortName(evacOrder.evacOrderType) + 'を発令しました。\n\n';
            }

            // リストが0件でない場合は本文を生成する
            if (districtList.length) {
                str += '発令地区は以下の通りです。\n';
                // 区分単位でグループ化
                // var areaMap = {};
                // this.sortAreaList(evacOrder.areaList);
                array.forEach(districtList, function(area) {
                    str += area.districtFreeName + '\n';
                });
                str += '\n';
            }

            // 避難所名のリストが0件でない場合は本文を生成する。
            if(shelterList.length !==0) {
                // 避難所区分ごとに避難所名を格納するためのリスト。
                var edesignatedEvacShList = [];
                var designatedEvacShList = [];
                var welfareEvacShList = [];
                var edesignatedDesignatedEvacShList = [];
                var edesignatedWelfareEvacShList = [];
                var designatedWelfareEvacShList = [];
                var edesignatedDesignatedWelfareEvacShList = [];
                var otherEvacShList = [];

                // 避難所区分のない避難所だけかどうか
                var isOtherShTypeOnly = true;

                shelterList.forEach(function(shelter) {
                    if (shelter.edesignatedEvacShFlg === '1' && shelter.designatedEvacShFlg === '0' &&
                            shelter.welfareEvacShFlg === '0') {
                        edesignatedEvacShList.push(shelter.shelterName);
                    } else if (shelter.edesignatedEvacShFlg === '0' && shelter.designatedEvacShFlg === '1' &&
                                shelter.welfareEvacShFlg === '0') {
                        designatedEvacShList.push(shelter.shelterName);
                    } else if (shelter.edesignatedEvacShFlg === '0' && shelter.designatedEvacShFlg === '0' &&
                                shelter.welfareEvacShFlg === '1') {
                        welfareEvacShList.push(shelter.shelterName);
                    } else if (shelter.edesignatedEvacShFlg === '1' && shelter.designatedEvacShFlg === '1' &&
                                shelter.welfareEvacShFlg === '0') {
                        edesignatedDesignatedEvacShList.push(shelter.shelterName);
                    } else if (shelter.edesignatedEvacShFlg === '1' && shelter.designatedEvacShFlg === '0' &&
                                shelter.welfareEvacShFlg === '1') {
                        edesignatedWelfareEvacShList.push(shelter.shelterName);
                    } else if (shelter.edesignatedEvacShFlg === '0' && shelter.designatedEvacShFlg === '1' &&
                                shelter.welfareEvacShFlg === '1') {
                        designatedWelfareEvacShList.push(shelter.shelterName);
                    } else if (shelter.edesignatedEvacShFlg === '1' && shelter.designatedEvacShFlg === '1' &&
                                shelter.welfareEvacShFlg === '1') {
                        edesignatedDesignatedWelfareEvacShList.push(shelter.shelterName);
                    } else {
                        otherEvacShList.push(shelter.shelterName);
                    }
                });

                str += '開設された避難所は以下の通りです。\n';

                // それそれの避難所区分に避難所名があれば出力
                if (edesignatedEvacShList.length !== 0) {
                    str += '\n' + '[指定緊急避難場所]' + '\n';
                    edesignatedEvacShList.forEach(function(name) {
                        str += name + '\n';
                        isOtherShTypeOnly = false;
                    });
                }
                if (designatedEvacShList.length !== 0) {
                    str += '\n' + '[指定避難所]' + '\n';
                    designatedEvacShList.forEach(function(name) {
                        str += name + '\n';
                        isOtherShTypeOnly = false;
                    });
                }
                if (welfareEvacShList.length !== 0) {
                    str += '\n' + '[福祉避難所]' + '\n';
                    welfareEvacShList.forEach(function(name) {
                        str += name + '\n';
                        isOtherShTypeOnly = false;
                    });
                }
                if (edesignatedDesignatedEvacShList.length !== 0) {
                    str += '\n' + '[指定緊急避難場所・緊急避難所]' + '\n';
                    edesignatedDesignatedEvacShList.forEach(function(name) {
                        str += name + '\n';
                        isOtherShTypeOnly = false;
                    });
                }
                if (edesignatedWelfareEvacShList.length !== 0) {
                    str += '\n' + '[指定緊急避難場所・福祉避難所]' + '\n';
                    edesignatedWelfareEvacShList.forEach(function(name) {
                        str += name + '\n';
                        isOtherShTypeOnly = false;
                    });
                }
                if (designatedWelfareEvacShList.length !== 0) {
                    str += '\n' + '[指定避難所・福祉避難所]' + '\n';
                    designatedWelfareEvacShList.forEach(function(name) {
                        str += name + '\n';
                        isOtherShTypeOnly = false;
                    });
                }
                if (edesignatedDesignatedWelfareEvacShList.length !== 0) {
                    str += '\n' + '[指定緊急避難場所・指定避難所・福祉避難所]' + '\n';
                    edesignatedDesignatedWelfareEvacShList.forEach(function(name) {
                        str += name + '\n';
                        isOtherShTypeOnly = false;
                    });
                }
                if (otherEvacShList.length !== 0) {
                    // 避難所区分がnullのもだけであれば、避難所区分は表示しない
                    if (isOtherShTypeOnly) {
                        otherEvacShList.forEach(function(name) {
                            str += name + '\n';
                        });
                    } else {
                        str += '\n' + '[その他の避難所]' + '\n';
                        otherEvacShList.forEach(function(name) {
                        str += name + '\n';
                        });
                    }
                }

                // 最後のスペースは削除
                str = str.slice(0,-1);
                str += '\n\n';
            }
            // 発令者を本文に追加する。
            if(evacOrder.municipalityName !== null && evacOrder.organizationName !== null) {
                str += '(' + evacOrder.municipalityName +' '+ evacOrder.organizationName + ')';
            }

            return str;
        },

        onBodyTextChange: function(){
            setTimeout(lang.hitch(this, this.countBodyText), 10);
        },

        countBodyText: function(){
            var bodyText = this.regForm.get('value').bodyText;
            var textLength = this.countText(bodyText);
            this.textLength.innerHTML = textLength;
        }

        // TODO district（中分類オブジェクト）にorderが必要か検討する。
        // sortAreaList: function(array){
        //     array.sort(function(a,b){
        //         if(a.order < b.order){
        //             return -1;
        //         } else if(a.order > b.order){
        //             return 1;
        //         } else {
        //             return 0;
        //         }
        //     });
        // }

        // TODO テンプレート機能は未実装
        // // 05:緊急速報メールのテンプレート選択に関する機能
        // onTemplatePrefInsertButtonClick: function() {
        //     this.templatePrefInsertDialog.show();
        // },
        //
        // initPrefTemplateProvGrid: function() {
        //     this.prefGrid.set('collection', this.store.filter());
        //     this.prefGrid.on('insertButtonClick', lang.hitch(this, function(evt) {
        //         // helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
        //         this.onTemplatePrefInsert(evt.item);
        //     }));
        // },
        //
        // /**
        //  * リンク挿入内容確定時に呼ばれる。
        //  */
        // onTemplatePrefInsert: function(item) {
        //     this.templatePrefInsertDialog.hide();
        //     this.regForm.set('value',item);
        // }
    });
});
