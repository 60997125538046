/**
 * グループ管理詳細ダイアログ用。
 * @module app/view/SafetyGroupDetailDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/SafetyGroupDetailDialog.html',
    'idis/view/page/_PageBase',
    'idis/view/dialog/InfoDialog',
    // 以下、変数から参照されないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/form/Form',
    'dijit/form/SimpleTextarea',
    'dijit/form/TextBox',
    'dijit/form/ValidationTextBox',
    'idis/view/form/DateTimeInput',
    'app/safety/auth/view/form/AclButton',
    'idis/view/form/CheckGroup',
    'idis/view/form/RadioGroup'

], function(module, declare, lang, template, _PageBase, InfoDialog) {
    /**
     * グループ管理詳細ダイアログ内の画面。
     * @class SafetyGroupDetailDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), [_PageBase],
        /** @lends module:idis/view/page/SafetyGroupDetailDialog~SafetyGroupDetailDialog# */ {
        // テンプレート文字列
        templateString: template,

        /**
         * 更新ボタンが押された際の動き
         */
        onUpdateClick: function() {
            try {
                if(this.validate()) {
                    var value = this.form.get('value');
                    // 実際の登録処理はSafetyAdminPage側でupdateイベントを監視しているメソッドが実施
                    this.emit('update', {value: value});
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * 削除ボタンが押された際の動き
         */
        onDeleteClick: function() {
            try {
                var value = this.form.get('value');
                // 実際の登録処理はAdminPage側でdeleteイベントを監視しているメソッドが実施
                this.emit('delete', {groupCd: value.groupCd});
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * 入力チェック
         */
        validate: function() {
            var formData = this.form.get('value');

            // division
            if (formData.divisionCds === '') {
                InfoDialog.show('課が選択されていません');
                return false;
            }
            return true;
        }
    });
});
