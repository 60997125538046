define([
    // ここに書く
    'app/scheduledReport/ScheduledReportListPage',
    'app/scheduledReport/ScheduledReportListViewPage',
    'app/scheduledReport/ScheduledReportPage',
    'app/scheduledReport/ScheduledReportViewPage',
    'app/scheduledReport/ScheduledReportMunicListPage',
    'app/scheduledReport/ScheduledReportMunicListViewPage',
    'app/scheduledReport/ScheduledReportMunicDetailPage',
    'app/scheduledReport/ScheduledReportMunicDetailViewPage',
    'app/scheduledReport/ScheduledReportDamageListPage',
    'app/scheduledReport/ScheduledReportDamageListViewPage',
    'app/scheduledReport/ScheduledReportDamageDetailPage',
    'app/scheduledReport/ScheduledReportDamageDetailViewPage',
    // ここまで
], function () { });