/**
 * 課の新規登録・詳細ダイアログ用の基底モジュール。
 * @module app/organization/_SectDialogBase
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'idis/view/page/_PageBase',
    'idis/view/dialog/InfoDialog',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'dijit/form/TextBox',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    '../view/form/DeptSelector'
], function(module, declare, lang, domStyle, _PageBase, InfoDialog) {
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/organization/_SectDialogBase~_SectDialogBase# */
    {
        /**
         * 組織コード
         */
        _sectCd: null,

        /**
         * constructor
         */
        constructor: function() {
        },

        /**
         * フォームをリセットする。
         */
        reset: function() {
            this.form.reset();
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * 入力値の妥当性を検証する。
         */
        validate: function() {
            var data = this.form.get('value');

            // 自治体
            if (!data.municipalityCd || data.municipalityCd === ''){
                InfoDialog.show('入力チェック', '自治体が選択されていません');
                return false;
            }
            
            // 部コード
            if (!data.deptCd || data.deptCd === '') {
                InfoDialog.show('入力チェック', '部が選択されていません');
                return false;
            }

            // 課名
            if (!data.sectName || data.sectName === '') {
                InfoDialog.show('入力チェック', '課名が入力されていません');
                return false;
            }

            return true;
        }
    });
});
