/**
 * ユーザの新規登録・詳細ダイアログ用の基底モジュール。
 * @module app/broadnotify/_TerminalDialogBase
 */
define([
    'module',
    'leaflet',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'idis/model/UserInfo',
    'idis/view/page/_PageBase',
    'idis/view/dialog/InfoDialog',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/Textarea',
    'dijit/form/TextBox',
    'idis/view/form/AclButton',
    'idis/view/form/Button'
], function(module, leaflet, declare, lang, domStyle, UserInfo, _PageBase, InfoDialog) {

    return declare(module.id.replace(/\//g, '.'), _PageBase,
    {
        /**
         * ユーザID
         */
        _userId: null,

        /**
         * 住所
         */
         _address: null,

        /**
         * 緊急通知ユーザID
         */
        _terminalId: null,

        /**
         * 市町村ID
         */
        _municipalityCd: null,

        /**
         * constructor
         */
        constructor: function() {
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * フォームをリセットする。
         */
        reset: function() {
            this.form.reset();
        },

        /**
         * 入力値の妥当性を検証する。
         */
        validate: function() {
            var formData = this.form.get('value');

            // ユーザ
            if (!formData.terminalUserId || formData.terminalUserId.length === 0 ) {
                InfoDialog.show('入力チェック', 'ユーザが選択されていません');
                return false;
            }
            return true;
        },

        // 市町村セレクタの値が変更するときの動作
        onMunicipalityCdChanged: function() {
        	this._municipalityCd = this.municipalityCd.get('value');
        },
    });
});
