/**
 * Lアラート避難所情報詳細ダイアログ。
 * @module app/provide/ProvideShelterDetailDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/text!./templates/ProvideShelterDetailDialog.html',
    'app/provide/_ProvideDialogBase',
    'app/model/DisasterInfo',
    'dstore/Memory',
    // 以下、変数から参照されないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/form/Form',
    'dijit/form/ValidationTextBox',
    'dijit/form/SimpleTextarea',
    'idis/view/form/WordCountTextarea',
    'idis/view/form/DateTimeInput',
    'idis/view/form/Button',
    'app/view/form/MunicipalitySelector',
    'app/view/form/DisasterSelector',
    'app/provide/ProvideShelterGrid'
], function(module, declare, lang, array, template, _ProvideDialogBase,
    DisasterInfo, Memory) {
    /**
     * Lアラート避難所情報詳細ダイアログ。
     * @class ProvideShelterDetailDialog
     * @extends module:app/provide/_ProvideDialogBase~_ProvideDialogBase
     */
    return declare(module.id.replace(/\//g, '.'), [_ProvideDialogBase],
        /** @lends module:app/provide/_ProvideDialogBase~_ProvideDialogBase# */ {
        // テンプレート文字列
        templateString: template,

        // ダイアログを開く際に設定する。
        contentType: null,

        // 避難対象地区グリッド用のストア
        gridStore: null,

        // ダイアログで保持する市町村コード
        _municipalityCd: null,

        // ベースクラスのコンストラクタでStoreを生成。
        constructor: function() {
            this.inherited(arguments);
            this.shelterStore = new Memory();
        },

        buildRendering: function() {
            this.inherited(arguments);
        },

        resize: function(changeSize, resultSize) {
            this.borderContainer.resize(changeSize, resultSize);
        },

        // ベースクラスをオーバーライド
        // 画面から渡された値で初期表示設定を行う。
        initDialog: function(obj){
            // 配信種別をセットする。
            this.sendCategoryNode.innerHTML = this.getCategoryName(obj.sendCategory);
            // 市町村コードをセットする。
            this.municipalityCd._setValueAttr(obj.municipalityCd);

            // 補足情報
            this.complementaryInfo._setValueAttr(obj.complementaryInfo);

            // 災害名をセットする。
            this.disasterNameNode.set('value',obj.disasterId);
            // 総避難人数をセットする。
            this.evaqueeNumNode.innerHTML = obj.evaqueeNum + '(人)';
            // うち自主避難人数をセットする。
            this.evaqueeActNumNode.innerHTML = obj.evaqueeActNum + '(人)';
            // 総避難世帯数
            this.evacHouseholdNumNode.innerHTML = obj.evacHouseholdNum + '(世帯)';
            // うち自主避難世帯数
            this.evacHouseholdActNumNode.innerHTML = obj.evacHouseholdActNum + '(世帯)';
            // 対象地区に関する情報はグリッドで表示する。
            this.initGrid(obj);
        },

        // 避難対象地区グリッドを初期表示する。
        initGrid: function(obj) {
            this.shelterStore = new Memory({
                data: array.map(obj.items, function (item, i) {
                    return lang.mixin({id:i}, item);
                })
            });
            this.shelterGrid.set('collection', this.shelterStore);
        }
    });
});
