/**
 * 気象警報注意報発表電文文表示ダイアログ
 * @module app/weatherinfo/view/WeatherInfoDetailDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'dojo/text!./templates/WeatherInfoDetailDialog.html',
    'idis/error/InvalidArgumentException',
    'idis/view/page/_PageBase'
    // 'app/util/DateFormatter'
], function(module, declare, lang, locale, template, InvalidArgumentException, _PageBase) {
    /**
     * 情報文表示ダイアログ
     * @class WeatherInfoDetailDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/weatherinfo/view/WeatherInfoDetailDialog~WeatherInfoDetailDialog# */ {
        // テンプレート文字列
        templateString: template,

        constructor: function(options) {
            this.inherited(arguments);
            lang.mixin(this, options);
        	// 引数チェック
            if (!this.item) {
                throw new InvalidArgumentException(module.id + '::constructor: Property "item" must be specified.');
            }
        },

        // DOMを構築する
        buildRendering: function() {
            this.inherited(arguments);
            // コンテンツの中身を生成する
            this.setContent();
        },

        /**
         * コンテンツの中身を生成する
         */
        setContent: function() {
            //発表日時
            this.reportDateTime.innerHTML = this.formatDateTime(this.item.reportDatetime);
            // 発表文内容
            this.text.innerHTML = this.item.text.replace(/。/g, '。<br>');
            // 対象市町村
            this.areaNames.innerHTML = this.item.areaNames.join(', ');
        },

        /**
         * 'yyyy-MM-dd HH:mm:ss' を表示形式に変換
         */
        formatDateTime: function(datetime) {
            var reportDateTime = new Date(datetime.replace(/-/g, '/'));
                var dateLabel = locale.format(reportDateTime, {
                    selector: 'date',
                    datePattern: 'MM月dd日'
                });
                var timeLabel = locale.format(reportDateTime, {
                    selector: 'time',
                    timePattern: 'HH時mm分'
                });
            return dateLabel + '&nbsp;' + timeLabel;
        },

        /**
         * コンテンツのHTMLテキストを返す
         */
        getContent: function() {
        	// HTMLタグ間に改行コードが入り、セット先のダイアログモジュールで<br>に変換されるため外しておく
        	// テキスト内の改行はそのままにしたいため、「HTML終了タグ + 改行コード」にマッチする部分を
        	// 「HTML終了タグ」のみにする
        	// さらに、最初と最後の改行コードを外す
            return this.contentNode.innerHTML.replace(/>\r?\n/g, '>').replace(/^\r?\n/g, '').replace(/\r?\n$/g, '');
        }

    });
});
