/**
 * ユーザ情報取得用モジュール
 * @module app/auth/model/SafetyUserInfo
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/service/Requester'
], function(module, declare, lang, Requester) {
    /**
     * ユーザ情報を管理するオブジェクト
     */
    return new declare(null, {
        /**
         * ユーザ情報に対するリクエスト結果
         * @type {Promise<Object>}
         */
        _promise: null,

        /**
         * ユーザ情報
         * @type {Object}
         */
        _userInfo: null,

        /**
         * ユーザ情報をサーバーから取得し、成否をPromiseとして返す。
         * @returns {Promise}
         */
        load: function() {
            if (!this._promise) {
                this._userInfo = {};
                this._promise = Requester.get('/api/auth/safety/init').then(lang.hitch(this, function(userInfo) {
                    this._userInfo = userInfo;
                }));
            }
            return this._promise;
        },

        /**
         * 確認対象に対しアクセス件を持っているかどうかを判定する。
         * @param {Object} data 確認対象
         * @param {string} data.pubStatus 公開状態(0:未公開, 1:振興局, 2:事業課, 3:県土整備部, 4:全体)
         * @param {string} data.userId 登録者のユーザID
         * @param {string} data.deptCd 登録者の部コード
         * @param {string} data.pubSectCd 公開先課コード
         */
        hasAccess: function(data) {
            var logPrefix = module.id + '#isAllowed: ';
            var pubStatus = data.pubStatus;
            if (!data || !/^[0-4]$/.test(pubStatus)) {
                // 有効なpubStatusを持っていない場合はfalse
                console.warn(logPrefix + 'pubStatusが無効: ', data);
                return false;
            }
            // 全体公開と作成者本人のアクセスは常にtrue
            if (pubStatus === '4' || this.getId() === data.userId) {
                var message = (pubStatus === '4' ? '全体公開' : '登録者本人');
                console.debug(logPrefix + message + ' (access allowed, pubStatus=' + pubStatus + ')');
                return true;
            }
            var org = this.getOrganization();
            if (/^[123]$/.test(pubStatus) && org.deptCd && org.deptCd === data.deptCd) {
                // 公開範囲が振興局以上でユーザの部コードと同一ならtrue
                console.debug(logPrefix + '部コードが一致 (access allowed, pubStatus=' + pubStatus + ')');
                return true;
            } else if (/^[23]$/.test(pubStatus) && org.sectCd && org.sectCd === data.pubSectCd) {
                // 公開範囲が事業課以上でユーザの課コードと公開先課コードが同一ならtrue
                console.debug(logPrefix + '課コードが一致 (access allowed, pubStatus=' + pubStatus + ')');
                return true;
            } else if (pubStatus === '3' && org.deptCd === 'D02') {
                // 公開範囲が県土整備部以上でユーザの部コードが県土整備部(=D02)ならtrue
                console.debug(logPrefix + '統括部門ユーザ (access allowed, pubStatus=' + pubStatus + ')');
                return true;
            } else {
                console.debug(logPrefix + '権限なし (access denied, pubStatus=' + pubStatus + ')');
                return false;
            }
        },

        /**
         * 認証済みかどうかを返す。
         * @returns {boolean} 認証済みかどうか
         */
        isAuthed: function() {
            // ユーザIDを持っていれば認証済みとする
            return !!this.getId();
        },

        /**
         * ユーザの識別子を返す。
         * @returns {string} 識別子
         */
        getId: function() {
            return this._userInfo.userId;
        },

        /**
         * ユーザの所属情報を返す。
         * @returns {Object} 所属情報
         */
        getOrganization: function() {
            return {
                deptCd: this._userInfo.deptCd,
                sectCd: this._userInfo.sectCd,
                unitCd: this._userInfo.unitCd
            };
        },

        /**
         * ユーザの防災対象市町村情報を返す。
         * @returns {Object} 防災対象市町村情報
         */
        getMunicipalityCds: function() {
            return {
            	municipalityCds: this._userInfo.municipalityCds
            };
        },

        /**
         * ユーザの権限情報を返す。
         * @returns {Object<string,boolean>} 権限種別をキー、権限を値として持つ値オブジェクト
         */
        getAcl: function() {

            var chief = !!this._userInfo.chiefAdminFlg;
            var admin = chief || !!this._userInfo.systemAdminFlg;

            return {
                CHIEF_ADMIN: chief,
                ADMIN: admin,
                WRITE: admin || !this._userInfo.readOnlyFlg
            };
        },

        /**
         * 認可を返す。
         * @param {string} funcCd 機能コード
         * @returns {boolean} 認可があるかどうか
         */
        hasAuthz: function(funcCd) {
        	return this._userInfo.authzs && !!this._userInfo.authzs[funcCd];
        },

        /**
         * 役割コードを返す。
         * @returns {string} 役割コード
         */
        getRoleCd: function() {
        	return this._userInfo.roleCd;
        }
    })();
});
