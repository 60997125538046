/**
* ライフライン概況画面用モジュール。
* @module app/lifeline/LifelineAdminPage
*/
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/text!./templates/LifelineAdminPage.html',
    'dojo/topic',
    'idis/consts/USER_TYPE',
    'idis/control/Router',
    'idis/model/UserInfo',
    'idis/store/IdisRest',
    'idis/service/Requester',
    'idis/view/page/_PageBase',
    'idis/view/Loader',
    'app/model/DisasterInfo',
    // 以下、変数として受け取らないモジュール
    'dijit/layout/BorderContainer',
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/TextBox',
    'idis/view/form/DateInput',
    'idis/view/form/DateTimeInput',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'app/lifeline/LifelineAdminGrid'
], function(module, declare, lang, domStyle, template, topic,
    USER_TYPE, Router, UserInfo, IdisRest, Requester, _PageBase, Loader,
    DisasterInfo) {
    /**
     * ライフライン概況画面
     * @class lifelineAdminPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/view/page/LifelineAdminPage~LifelineAdminPage# */
        {
            // テンプレート文字列
            templateString: template,
            store: null,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--lifeline',

            constructor: function() {
                this.store = new IdisRest({
                    target: '/api/lifeline/admin'
                });
                // 災害IDをDisasterInfoから取得
                this._disasterId = DisasterInfo.getDisasterId();
                if(!this._disasterId) {
                    this._disasterId = 1;
                    console.warn('災害IDが設定されていません。');
                }
                console.debug('現在のログインユーザID：' + UserInfo.getId());
                console.debug('現在の災害ID：' + DisasterInfo.getDisasterId());
            },

            buildRendering: function() {
                this.inherited(arguments);
                this.setButtonStatus();
                this.initGrid();
            },

            setButtonStatus: function() {
                // ライフライン登録権限を持たない場合、新規登録ボタンを非表示
                if (!UserInfo.hasWriteAuthz('F05016')) {
                    domStyle.set(this.RegisterButton.domNode, 'display', 'none');
                }
            },

            initGrid: function() {
                // グリッドの詳細ボタンクリック時の動作を設定する
                // helper.buttonColumnでフィールド名に指定した'list'と'ButtonClick'の結合がボタンクリック時のイベント名
                this.grid.on('listButtonClick', lang.hitch(this, function(evt) {
                    this.onListButtonClick(evt.item);
                }));
                //表を初期条件で表示
                this.updateGridQuery();
            },

            /**
             * グリッドの検索条件を指定された値で更新する。
             * @param {Object} value name属性と値のマッピング
             */
            updateGridQuery: function() {
            	var value = this.form.get('value');
                // 入力値を元にグリッド用フィルターを作成
                var filter = new this.store.Filter();

                // 災害ID
                filter = filter.eq('disasterId', this._disasterId);

                //集計日時
                if (value.countTime) {
                    var countTime = new Date(value.countTime).getTime();
                    filter = filter.eq('countTime', countTime);
                }
                // すべての種別を表示
                if (value.showAllFlg.length === 0) {
                    // チェック無 本文登録がある種別のみ表示
                    filter = filter.eq('showAllFlg', false);
                } else {
                    filter = filter.eq('showAllFlg', true);
                }

                // filterに対応するcollectionを取得
                var collection = this.store.filter(filter);
                // collectionをグリッドにセットする（サーバーにリクエストされる）
                this.grid.set('collection', collection);
            },

            //一覧ボタンを押下したとき
            onListButtonClick: function(object) {
                console.debug('[一覧]ボタンがクリックされました。');
                // ライフライン情報一覧画面へ遷移
                Router.moveTo('lifeline', {
                    lifelineKindCd : object.lifelineKindCd
                });
            },

            //新規登録ボタンを押下したとき
            onRegisterButtonClick: function() {
                console.debug('[新規登録]ボタンがクリックされました。');
                // ライフライン新規登録画面へ遷移
                Router.moveTo('lifeline/register');
            },


            /**
             * 検索ボタンが押されたときに呼び出される。
             * テンプレートHTML内のFormウィジェットに対するdata-dojo-attach-eventでこのイベントを紐付けている。
             * また、同Formウィジェットに対しdata-dojo-attach-pointを指定し、this.formからアクセス出来るようにしている。
             */
            onSubmit: function() {
                try {
                    if (this.form.isValid()) {
                        // 入力値が正常ならグリッドの検索条件を更新
                        this.updateGridQuery();
                    }
                } catch (e) {
                    console.error(e);
                } finally {
                    return false;
                }
            }
        }
    );
});
