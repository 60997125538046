/**
 * 親管理番号選択ダイアログ用モジュール。
 * @module app/damage/ParentDamageSelectorDialog
 */
define([
  'module',
  'dojo/_base/declare',
  'dojo/_base/lang',
  'dojo/_base/array',
  'dojo/topic',
  'dojo/text!./templates/TimelineDisasterSelector.html',
  'app/model/DisasterInfo',
  'idis/view/dialog/InfoDialog',
  'idis/control/Locator',
  'idis/control/Router',
  'idis/store/IdisRest',
  'idis/view/_IdisWidgetBase',
  // 以下、変数として受け取らないモジュール
  'dijit/Dialog',
  './TimelineDisasterSelectorDialog'
], function(module, declare, lang, array, topic, template, DisasterInfo, InfoDialog,
  Locator, Router, IdisRest, WidgetBase) {

  /**
   * 親被害情報選択ダイアログ表示用ウィジェット
   * @class ParentDamageSelectorDialog
   * @extends module:idis/view/page/_PageBase~_PageBase
   */
  return declare(module.id.replace(/\//g, '.'), WidgetBase,
    /** @lends app/view/Header~Header# */ {

    // テンプレート文字列
    templateString: template,

    // ルートに付与されるCSS
    baseClass: 'timeline-disasterSelector',

    PARENT_DAMAGE_DIAPLAY: 'app/damage/DamageReportDetailPage::display',
    PARENT_DAMAGE_RELEASE: 'app/damage/DamageReportDetailPage::release',

    /**
     * データ格納用オブジェクト
     * @type {module:dstore/Store}
     */
    store: null,

    /**
     * コンストラクタ
     */
    constructor: function() {
      // データ格納用オブジェクト
      this.store = new IdisRest({
          idProperty: 'disasterId',
          target: '/api/timeline/disaster'
      });
    },

    /**
     * DOM生成
     */
    buildRendering: function() {
      this.inherited(arguments);
      this.parentDamageId.innerText = '未選択';
    },

    /**
     * 画面生成完了
     */
    startup: function() {
      this.inherited(arguments);
      // このウィジェット消滅時にダイアログも削除
      this.own(this.dialog);
    },

    /**
     * 災害変更ダイアログを表示する
     */
    showDialog: function() {
      // ダイアログを表示
      this.dialog.show();
      // ダイアログ中のページウィジェットを取得
      var page = this.dialog.getChildren()[0];
      // ダイアログ側でOKボタンが押された際のイベント
      page.on('update', lang.hitch(this, function(evt) {
        // 災害変更
        this.change(evt.data);
        // ダイアログを非表示にする
        this.dialog.hide();
        InfoDialog.show('災害名を選択しました');
      }));
      // ダイアログの画面を再表示する
      page.refresh();

    },

    /**
     * 選択されている親災害IDの選択を解除する。
     */
     release: function() {
        this.parentDamageId.innerText = '未選択';
        // 選択されている親災害IDを解除する。
        topic.publish(module.id + '::released', '');
     },

    /**
     * 災害を変更する
     */
    change: function(data) {
      // 変更された災害名を画面へセット
      this.parentDamageId.innerText = data.admNum;
      // 選択している親災害IDを設定する。
      topic.publish(module.id + '::selected', data);
    }
  });

});
