define([
    'module',
    'dojo',
    'dojo/_base/declare',
    'dojo/topic',
    'dojo/on',
    'dgrid/Selector',
    'idis/view/grid/IdisGrid'
], function (module, dojo, declare, topic, on, Selector, IdisGrid) {
    return declare(module.id.replace(/\//g, '.'), [IdisGrid, Selector], {

        /**
         * セレクションモード
         */
        selectionMode: 'single',

        collection: this.store,
        // ルート要素に付与されるCSS
        // baseClass: 'idis-Page idis-Page--spectee',

        rowClassName: function (item) {
            return item && item.newFlg ? 'is-new' : '';
        },
        renderRow: function (item) {
			var div = this.inherited(arguments);
			topic.publish(module.id + '::drawIcon', item);
			return div;
		},

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            {
                field: 'favorite', label: '保存',
                renderCell: function (item) {
                    var gridNode = this.grid.domNode;
                    // お気に入り登録（未登録）
                    var offActions = dojo.create('div');
                    var offImageSrc = '/images/spectee/non-favorite.png';
                    /* var offImageA = dojo.create('a', {
                        href: '#',
                        onclick: function() {
                            on.emit(gridNode, 'openFavoriteDialog', { item: item });
                        }
                    }); */
                    offActions.appendChild(dojo.create('img', {
                        src: offImageSrc,
                        width: '25'
                    }));
                    // offActions.appendChild(offImageA);
                    // お気に入り解除（登録済み）
                    var onActions = dojo.create('div');
                    var onImageSrc = '/images/spectee/favorite.png';
                    /* var onImageA = dojo.create('a', {
                        href: '#',
                        onclick: function() {
                            on.emit(gridNode, 'favoriteRemoveButtonClick', { item: item });
                        }
                    }); */
                    onActions.appendChild(dojo.create('img', {
                        src: onImageSrc,
                        width: '25'
                    }));
                    // onActions.appendChild(onImageA);

                    // お気に入り登録済みか判定し、ボタンの出し分け
                    var content = null;
                    content = item.favoriteFlg === false ? offActions : onActions;
                    return content;
                }
            },
            {
                field: 'updDate', label: '情報更新日時',
                // sortable: false,
                formatter: function (value) {
                    if (value) {
                        if (value.length > 17) {
                            value = value.substr(0, 16);
                        }
                        return value;
                    } else {
                        return '-';
                    }
                }
            },
            {
                field: 'locationInfo', label: '場所',
                sortable: false,
                formatter: function (value) {
                    var locationData = null;
                    if (value && value[0]) {
                        // Specteeから情報を取得する場合
                        locationData = value[0];
                    } else if (value && !value[0]) {
                        // お気に入り情報を取得する場合
                        locationData = value;
                    }
                    if (locationData) {
                        var address = '';
                        address = locationData.prefecture ? address + locationData.prefecture : address;
                        address = locationData.city ? address + locationData.city : address;
                        address = locationData.town ? address + locationData.town : address;
                        return address;
                    } else {
                        return '-';
                    }
                }
            },
            {
                field: 'title', label: '内容',
                sortable: false,
                renderCell: function (item) {
                    var gridNode = this.grid.domNode;
                    var actions = dojo.create('div');
                    var reportNo = '';
                    // [第〇報] + 内容
                    var explainSpan= dojo.create('span');
                    if (item.reportNo) {
                        reportNo = '[第' + item.reportNo + '報] ';
                    }
                    if (reportNo + item.title) {
                        explainSpan.innerHTML = reportNo + item.title + '　';
                        actions.appendChild(explainSpan);
                    } else {
                        explainSpan.innerHTML = '-';
                        actions.appendChild(explainSpan);
                    }

                    return actions;
                }               
            }
        ]
    });
});
