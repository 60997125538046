/**
 * 観測日時選択UI用モジュール。
 * @module app/observation/view/form/DateTimeSelect
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date',
    'dojo/date/locale',
    'dojo/dom-class',
    'dojo/dom-style',
    'dojo/text!./templates/DateTimeSelect.html',
    'dojo/topic',
    'idis/view/_IdisWidgetBase',
	'app/util/DateFormatter',
    // 以下、変数として受け取らないモジュール
    'idis/view/form/Button',
    'dijit/form/Select'
], function(module, array, declare, lang, date, locale, domClass, domStyle, template,
    topic, _IdisWidgetBase, DateFormatter) {

	/**
     * トピック一覧
     */
    var TOPIC = {
        CHANGE_DATE_TIME: module.id + '::changeDateTime',
        CLICK_LATEST: module.id + '::clickLatest'
    };


    /**
     * 観測日時選択セレクトボックスと時刻変更ボタン
     * 最新日時から10日前までの日付セレクトボックスと10分単位の時間セレクトボックス
     *
     * @class DateTimeSelect
     * @extends module:idis/view/_IdisWidgetBase~_IdisWidgetBase
     * @param {Object} kwArgs
     * @param {boolean} [kwArgs.to='2017-10-10 00:00:00'] 最新日時
     */
    var dateTimeSelect = declare(module.id.replace(/\//g, '.'), _IdisWidgetBase, {
        // テンプレート文字列
        templateString: template,

        // ルート要素のCSSクラス
        baseClass: 'observation-DateTimeSelect',

        /**
         * 最新日時
         * デフォルトは現在日時の直近10分刻み時刻
         * @type {Date}
         */
        latestDateTime: null,

        /**
         * 最過去日時
         * 表示できる最も古い日時
         * @type {Date}
         */
        oldestDateTime: null,

        /**
         * 時間モード
         * @type {string} 10: 10分 hourly: 正時
         */
		mode: '10',
		// 10分
		// 正時

        constructor: function(kwArgs) {
        	kwArgs = kwArgs || {};
        	// 観測時間の設定。10分刻みで丸める。
            if (!kwArgs.to) {
                // デフォルト値を設定（現在日時）
            	this.latestDateTime = this.roundTime(new Date());
            } else {
            	this.latestDateTime = this.roundTime(new Date(kwArgs.to));
            }

            // 最過去日時を設定
            var dateStr = locale.format(date.add(this.latestDateTime, 'day', -365), {
            	selector: 'date',
            	datePattern: 'yyyy/MM/dd'
            });
            this.oldestDateTime = new Date(dateStr + ' 00:00');
        },

        buildRendering: function() {
            this.inherited(arguments);

            // セレクトボックスのスタイル設定
            this.dateSelect.set('style', {width: '10em'});
            this.timeSelect.set('style', {width: '8em'});
            // 日付のセレクトボックスを生成する
            this.setDateSelect();
            // 時間のセレクトボックスを生成する
            this.setTimeSelect(this.latestDateTime);
        },

        /**
         * 指定された最新日時で日時のセレクトボックスを再構築する
         * @param {string} '2017-10-10 00:00:00'
         */
        rebuild: function(to) {
        	console.log(to);
        	this.latestDateTime = this.roundTime(new Date(to));
        	// 日付のセレクトボックスを再構築
        	this.setDateSelect();
        	// 時間のセレクトボックスを再構築
            this.setTimeSelect(this.latestDateTime);
        },

        /**
         * 直近の10分刻み時刻に丸める
         * 例えば、10時03分の場合は10時00分、10時13分の場合は10時10分に丸められる
         * @param {Date}
         * @returns {Date} 丸めた日時
         */
        roundTime: function(dt) {
        	// 分を取得し、10で割り切れなければ、そのあまりでminuteをマイナスする
        	var minutes = dt.getMinutes();
        	var mod = minutes % 10;
        	if (mod !== 0) {
        		return date.add(dt, 'minute', -mod);
        	}
        	return dt;
        },

        /**
         * 時刻を丸めた値を返す
         * @param {Date}
         * @param {string} '10'：10分モード 'hourly':正時モード
         * @return {string} 'HH:mm'
         */
        getRoundTimeValue: function(dt, mode) {
        	if (mode === 'hourly') {
        		var targetDate = date.add(dt, 'minute', -dt.getMinutes());
        		var timeValue = locale.format(targetDate, {
        			selector: 'time',
        			timePattern: 'HH:mm'
        		});
        		return timeValue;
        	} else {
        		return '';
        	}
        },

        /**
         * 日付のセレクトボックスを生成する
         */
        setDateSelect: function() {
        	// 最新日時から10日前までの日付を算出し、セレクトボックスを生成
            var currentDate = this.latestDateTime;
            var counter = 0;
            this._dateOptions = [];
            // 選択値。最後に追加されたアクティブなオプション時刻がデフォルト選択値。
            var defaultDate = null;

            // 1年前まで繰り返す
            while (counter < 366) {
            	// セレクトボックスの値
                var dateValue = locale.format(currentDate, {
                	selector: 'date',
                	datePattern: 'yyyy/MM/dd'
                });
                // セレクトボックスのラベル 「yyyy年MM月dd日」
                var dateLabel = DateFormatter.jpDate(currentDate, true);                this._dateOptions.push({ value: dateValue, label: dateLabel });
                defaultDate = dateValue;

                // 1日前に更新
                currentDate = date.add(currentDate, 'day', -1);
                // カウンターを増やす
            	counter++;
            }
            // セレクトボックスを設定
            this.dateSelect.set('options', this._dateOptions);
            this.dateSelect.startup();

            // デフォルト値の設定
        	this.timeSelect.set('value', defaultDate);
        },

        /**
         * 時間のセレクトボックスを生成する
         * @param {Date} 最新時刻。指定されていればその時間以降の選択をdisabledにする。
         */
        setTimeSelect: function(datetime) {

        	// ループの開始時刻（使いたいのは時刻なので日付は何でも良い。0時0分）
        	var currentDate = new Date(1970, 1, 1, 0, 0);
        	var counter = 0;
        	this._timeOptions = [];
        	// 選択値。最後に追加されたアクティブなオプション時刻がデフォルト選択値。
        	var defaultTime = null;
        	// 00時00分から23時50分まで生成するので、ループは144回
        	while (counter < 144) {
        		// セレクトボックスの値
        		var timeValue = locale.format(currentDate, {
        			selector: 'time',
        			timePattern: 'HH:mm'
        		});
        		// セレクトボックスのラベル
        		var timeLabel = locale.format(currentDate, {
        			selector: 'time',
        			// 24時間指定
        			timePattern: 'HH時mm分'
        		});

        		if (!datetime) {
        			// 最新観測日ではない場合
        			// if (this.mode === 'hourly') {
            		// 	// 正時モードの場合、正時以外は選択不可
            		// 	if (currentDate.getMinutes() !== 0) {
            		// 		this._timeOptions.push({ value: timeValue, label: timeLabel, disabled: true });
                    // 	} else {
                    // 		this._timeOptions.push({ value: timeValue, label: timeLabel, disabled: false });
                    // 		defaultTime = timeValue;
                    // 	}
            		// } else {
            		// 	// 10分モードの場合
            			this._timeOptions.push({ value: timeValue, label: timeLabel, disabled: false });
            			defaultTime = timeValue;
            		// }
        		} else {
        			// 最新観測日の場合は、時刻を越えている場合は選択不可
        			if (date.compare(datetime, currentDate, 'time') !== -1) {
        				// // さらに正時モードの場合は、正時以外は選択不可
        				// if (this.mode === 'hourly') {
        				// 	if (currentDate.getMinutes() !== 0) {
                		// 		this._timeOptions.push({ value: timeValue, label: timeLabel, disabled: true });
                        // 	} else {
                        // 		this._timeOptions.push({ value: timeValue, label: timeLabel, disabled: false });
                        // 		defaultTime = timeValue;
                        // 	}
        				// } else {
        					this._timeOptions.push({ value: timeValue, label: timeLabel, disabled: false });
        					defaultTime = timeValue;
        				// }
        			} else {
        				this._timeOptions.push({ value: timeValue, label: timeLabel, disabled: true });
        			}
        		}

        		// 10分後に更新
        		currentDate = date.add(currentDate, 'minute', 10);
                // カウンターを増やす
            	counter++;
        	}

        	// セレクトボックスを設定
            this.timeSelect.set('options', this._timeOptions);
            this.timeSelect.startup();

            // デフォルト値の設定
        	this.timeSelect.set('value', defaultTime);
        },

        /**
         * 日付の選択が切り替わった際に呼ばれる
         * 最新観測日か否かによって、時間のセレクトボックスの表示を切り替える
         * @param {string} 'yyyy/MM/dd'
         */
        onChangeDateSelect: function(value) {
        	// 選択日時
        	var selectedDate = new Date(value);
        	// 現在の時刻選択値（時間の判定だけに使うので日付は何でも良い）
        	var selectedTime = new Date('1970/01/01 ' + this.timeSelect.get('value'));

        	if (this.mode === '60') {
        		// 正時モードの場合
        		if (date.compare(selectedDate, this.latestDateTime, 'date') === 0) {
            		// 最新観測日の場合
            		// 最新観測時刻までのセレクトボックスを設定
            		array.forEach(this.timeSelect.options, lang.hitch(this, function(item, index){
            			// 時刻判定だけなので日付は何でも良い
            			var targetDate = new Date('1970/01/01 ' + item.value);
            			if (date.compare(this.latestDateTime, targetDate, 'time') !== -1) {
            				// この中でさらに正時のみアクティブ
            				if ((index % 6) === 0) {
            					this.timeSelect.options[index].disabled = false;
            				} else {
            					this.timeSelect.options[index].disabled = true;
            				}
            			} else {
            				this.timeSelect.options[index].disabled = true;
            			}
            		}));
            		// 時刻の選択値を更新
            		var targetTime = null;
            		if ( date.compare(this.latestDateTime, selectedTime, 'time') === -1) {
            			// 現在の選択時間が最新の観測時間外であれば、最新の観測時間を取得
            			targetTime = this.latestDateTime;
            		} else {
            			targetTime = selectedTime;
            		}
            		// さらに正時に丸めてセレクトボックスの選択値を更新
            		this.timeSelect.set('value', this.getRoundTimeValue(targetTime, this.mode));

            	} else {
            		// 最新観測日以外の場合は、全正時刻をアクティブにする
            		array.forEach(this.timeSelect.options, lang.hitch(this, function(item, index){
            			if ((index % 6) === 0) {
            				this.timeSelect.options[index].disabled = false;
            			} else {
            				this.timeSelect.options[index].disabled = true;
            			}
            		}));
            		// 時刻が正時以外の場合は、直前の正時を設定
                	if (selectedTime.getMinutes() !== 0) {
                		// セレクトボックスの選択値を更新
                		this.timeSelect.set('value', this.getRoundTimeValue(selectedTime, this.mode));
                	}
            	}
        	} else {
        		// 10分モードの場合
        		if (date.compare(selectedDate, this.latestDateTime, 'date') === 0) {
            		// 最新観測日の場合
            		// 最新観測時刻までのセレクトボックスを設定
            		array.forEach(this.timeSelect.options, lang.hitch(this, function(item, index){
            			// 時刻判定だけなので日付は何でも良い
            			var targetDate = new Date('1970/01/01 ' + item.value);
            			if (date.compare(this.latestDateTime, targetDate, 'time') !== -1) {
            				this.timeSelect.options[index].disabled = false;
            			} else {
            				this.timeSelect.options[index].disabled = true;
            			}
            		}));
            		// 時刻の選択値
            		if ( date.compare(this.latestDateTime, selectedTime, 'time') === -1) {
            			// 現在の選択時間が最新の観測時間外であれば、最新の観測時間を設定
            			var timeValue = locale.format(this.latestDateTime, {
                			selector: 'time',
                			timePattern: 'HH:mm'
                		});
            			this.timeSelect.set('value', timeValue);
            		}
            		// 現在の選択時間が最新の観測時間以内であれば現在の選択値をそのまま利用
            	} else {
            		// 最新観測日以外の場合は、全時刻をアクティブにする
            		// 時刻の選択値は変えない
            		array.forEach(this.timeSelect.options, lang.hitch(this, function(item, index){
            			this.timeSelect.options[index].disabled = false;
            		}));
            	}
        	}

        	// 描画
        	this.timeSelect.startup();

        	// 変更を通知
        	topic.publish(TOPIC.CHANGE_DATE_TIME, {
        		date: this.dateSelect.get('value'),
        		time: this.timeSelect.get('value') });
        },

        /**
         * 時間の選択が切り替わった際に呼ばれる
         * @param {string} 選択値 'HH:mm'
         */
        onChangeTimeSelect: function(/* value */) {
        	// 変更を通知
        	topic.publish(TOPIC.CHANGE_DATE_TIME, {
        		date: this.dateSelect.get('value'),
        		time: this.timeSelect.get('value') });
        },

        /**
         * >> ボタン（10分進む）ボタンがクリックされた際に呼ばれる
         */
        onTenMinutesForwardButtonClick: function() {
        	// 現在の選択日時を取得
        	var nowDate = new Date(this.dateSelect.get('value') + ' ' + this.timeSelect.get('value'));
        	// 次の10分の選択日時をを設定
        	var nextDate = date.add(nowDate, 'minute', 10);

        	// 最新観測時刻を超えない場合は、次に進む
        	if (date.compare(nextDate, this.latestDateTime) !== 1) {
        		var dateValue = locale.format(nextDate, {
                	selector: 'date',
                	datePattern: 'yyyy/MM/dd'
                });
        		var timeValue = locale.format(nextDate, {
        			selector: 'time',
        			timePattern: 'HH:mm'
        		});
        		// セレクトボックスの選択値を更新
        		this.dateSelect.set('value', dateValue);
        		this.timeSelect.set('value', timeValue);
        	}
        },

        /**
         * >> ボタン（1時間進む）ボタンがクリックされた際に呼ばれる
         */
        onSixtyMinutesForwardButtonClick: function() {
        	// 現在の選択日時を取得
        	var nowDate = new Date(this.dateSelect.get('value') + ' ' + this.timeSelect.get('value'));
        	// 次の1時間の選択日時をを設定
        	var nextDate = date.add(nowDate, 'hour', 1);

        	// 最新観測時刻を超えない場合は、次に進む
        	if (date.compare(nextDate, this.latestDateTime) !== 1) {
        		var dateValue = locale.format(nextDate, {
                	selector: 'date',
                	datePattern: 'yyyy/MM/dd'
                });
        		var timeValue = locale.format(nextDate, {
        			selector: 'time',
        			timePattern: 'HH:mm'
        		});
        		// セレクトボックスの選択値を更新
        		this.dateSelect.set('value', dateValue);
        		this.timeSelect.set('value', timeValue);
        	}
        },

        /**
         * >> ボタン（1時間進む）ボタンがクリックされた際に呼ばれる
         */
        on1HourForwardButtonClick: function() {
        	// 現在の選択日時を取得
        	var nowDate = new Date(this.dateSelect.get('value') + ' ' + this.timeSelect.get('value'));
        	// 次の1時間の選択日時をを設定
        	var nextDate = date.add(nowDate, 'hour', 1);

        	// 最新観測時刻を超えない場合は、次に進む
        	if (date.compare(nextDate, this.latestDateTime) !== 1) {
        		var dateValue = locale.format(nextDate, {
                	selector: 'date',
                	datePattern: 'yyyy/MM/dd'
                });
        		var timeValue = locale.format(nextDate, {
        			selector: 'time',
        			timePattern: 'HH:mm'
        		});
        		// セレクトボックスの選択値を更新
        		this.dateSelect.set('value', dateValue);
        		this.timeSelect.set('value', timeValue);
        	}
        },

        /**
         * >> ボタン（3時間進む）ボタンがクリックされた際に呼ばれる
         */
        on3HourForwardButtonClick: function() {
        	// 現在の選択日時を取得
        	var nowDate = new Date(this.dateSelect.get('value') + ' ' + this.timeSelect.get('value'));
        	// 次の60分の選択日時をを設定
        	var nextDate = date.add(nowDate, 'hour', 3);

        	// 最新観測時刻を超えない場合は、次に進む
        	if (date.compare(nextDate, this.latestDateTime) !== 1) {
        		var dateValue = locale.format(nextDate, {
                	selector: 'date',
                	datePattern: 'yyyy/MM/dd'
                });
        		var timeValue = locale.format(nextDate, {
        			selector: 'time',
        			timePattern: 'HH:mm'
        		});
        		// セレクトボックスの選択値を更新
        		this.dateSelect.set('value', dateValue);
        		this.timeSelect.set('value', timeValue);
        	}
        },

        /**
         * << ボタン（10分戻る）ボタンがクリックされた際に呼ばれる
         */
        onTenMinutesBackButtonClick: function() {
        	// 現在の選択日時を取得
        	var nowDate = new Date(this.dateSelect.get('value') + ' ' + this.timeSelect.get('value'));
        	// 前の10分の選択日時をを設定
        	var backDate = date.add(nowDate, 'minute', -10);

        	// 最過去観測時刻を越えない場合は、前に戻る
        	if (date.compare(backDate, this.oldestDateTime) !== -1) {
        		var dateValue = locale.format(backDate, {
                	selector: 'date',
                	datePattern: 'yyyy/MM/dd'
                });
        		var timeValue = locale.format(backDate, {
        			selector: 'time',
        			timePattern: 'HH:mm'
        		});
        		// セレクトボックスの選択値を更新
        		this.dateSelect.set('value', dateValue);
        		this.timeSelect.set('value', timeValue);
        	}
        },

        /**
         * << ボタン（1時間戻る）ボタンがクリックされた際に呼ばれる
         */
        onSixtyMinutesBackButtonClick: function() {
        	// 現在の選択日時を取得
        	var nowDate = new Date(this.dateSelect.get('value') + ' ' + this.timeSelect.get('value'));
        	// 前の1時間の選択日時をを設定
        	var backDate = date.add(nowDate, 'hour', -1);

        	// 最過去観測時刻を越えない場合は、前に戻る
        	if (date.compare(backDate, this.oldestDateTime) !== -1) {
        		var dateValue = locale.format(backDate, {
                	selector: 'date',
                	datePattern: 'yyyy/MM/dd'
                });
        		var timeValue = locale.format(backDate, {
        			selector: 'time',
        			timePattern: 'HH:mm'
        		});
        		// セレクトボックスの選択値を更新
        		this.dateSelect.set('value', dateValue);
        		this.timeSelect.set('value', timeValue);
        	}
        },

        /**
         * << ボタン（1時間戻る）ボタンがクリックされた際に呼ばれる
         */
        on1HourBackButtonClick: function() {
        	// 現在の選択日時を取得
        	var nowDate = new Date(this.dateSelect.get('value') + ' ' + this.timeSelect.get('value'));
        	// 前の1時間の選択日時をを設定
        	var backDate = date.add(nowDate, 'hour', -1);

        	// 最過去観測時刻を越えない場合は、前に戻る
        	if (date.compare(backDate, this.oldestDateTime) !== -1) {
        		var dateValue = locale.format(backDate, {
                	selector: 'date',
                	datePattern: 'yyyy/MM/dd'
                });
        		var timeValue = locale.format(backDate, {
        			selector: 'time',
        			timePattern: 'HH:mm'
        		});
        		// セレクトボックスの選択値を更新
        		this.dateSelect.set('value', dateValue);
        		this.timeSelect.set('value', timeValue);
        	}
        },

        /**
         * << ボタン（3時間戻る）ボタンがクリックされた際に呼ばれる
         */
        on3HourBackButtonClick: function() {
        	// 現在の選択日時を取得
        	var nowDate = new Date(this.dateSelect.get('value') + ' ' + this.timeSelect.get('value'));
        	// 前の1時間の選択日時をを設定
        	var backDate = date.add(nowDate, 'hour', -3);

        	// 最過去観測時刻を越えない場合は、前に戻る
        	if (date.compare(backDate, this.oldestDateTime) !== -1) {
        		var dateValue = locale.format(backDate, {
                	selector: 'date',
                	datePattern: 'yyyy/MM/dd'
                });
        		var timeValue = locale.format(backDate, {
        			selector: 'time',
        			timePattern: 'HH:mm'
        		});
        		// セレクトボックスの選択値を更新
        		this.dateSelect.set('value', dateValue);
        		this.timeSelect.set('value', timeValue);
        	}
        },

        /**
         * 時間モード切り替え
         */
        changeMode: function(mode) {
        	// 現在のモードと異なる場合のみ処理
        	if (this.mode !== mode) {
        		// TODO モード値の妥当性判定
        		this.mode = mode;

        		if (this.mode === 'hourly' || this.mode === '60') {
        			// 正時モードの場合
					if(this.mode==='hourly'){
						// 時間のセレクトボックスで正時以外のものをdisabledにする
						array.forEach(this.timeSelect.options, lang.hitch(this, function(item, index){
							if ((index % 6) !== 0) {
								this.timeSelect.options[index].disabled = true;
							}
						}));
						// 描画
						this.timeSelect.startup();

						// 現在の選択時間が正時ではない場合、選択時間から一番近い前の正時を設定する
						// 時刻判定だけなので日付は何でも良い
						var selectedDate = new Date('1970/01/01 ' + this.timeSelect.get('value'));
						if (selectedDate.getMinutes() !== 0) {
							// セレクトボックスの選択値を更新
							this.timeSelect.set('value', this.getRoundTimeValue(selectedDate, this.mode));
						}
					}

                	// 10分前進・後進、1時間前進・後進のボタンを非表示にする
                   	this.tenMinutesBackButton.set('style', {
                        display: 'none'
                    });
                   	this.tenMinutesForwardButton.set('style', {
                        display: 'none'
                    });
                	this.sixtyMinutesBackButton.set('style', {
                    display: 'none'
                    });
                   	this.sixtyMinutesForwardButton.set('style', {
                    display: 'none'
                    });

                	// 1時間前進・後進、3時間前進・後進のボタンを表示する
                	this.threeHourBackButton.set('style', {
                        display: ''
                    });
                    this.threeHourForwardButton.set('style', {
                            display: ''
                    });
                    this.oneHourBackButton.set('style', {
                            display: ''
                    });
                    this.oneHourForwardButton.set('style', {
                            display: ''
                    });


        		} else {
        			// 10分モードの場合

        			// 時間のセレクトボックスを設定
        			array.forEach(this.timeSelect.options, lang.hitch(this, function(item, index){
        				if (date.compare(new Date(this.dateSelect.get('value')), this.latestDateTime, 'date') === 0) {
        					// 観測日付が選択されている場合
        					if (date.compare(
        							this.latestDateTime, new Date('1970/01/01 ' + item.value), 'time') !== -1) {
            					this.timeSelect.options[index].disabled = false;
            				} else {
            					this.timeSelect.options[index].disabled = true;
            				}
        				} else {
        					// 観測日付以外は全てアクティブ
        					this.timeSelect.options[index].disabled = false;
        				}

            		}));
        			// 描画
        			this.timeSelect.startup();

				// if (this.mode === 'hourly') {
				// 	// 正時モードの場合
				// 	// 10分前進・後進、1時間前進・後進のボタンを非表示にする
				// 		this.tenMinutesBackButton.set('style', {
				// 		display: 'none'
				// 	});
				// 		this.tenMinutesForwardButton.set('style', {
				// 		display: 'none'
				// 	});
				// 	this.sixtyMinutesBackButton.set('style', {
				// 	display: 'none'
				// 	});
				// 		this.sixtyMinutesForwardButton.set('style', {
				// 	display: 'none'
				// 	});

				// 	// 1時間前進・後進、3時間前進・後進のボタンを表示する
				// 	this.threeHourBackButton.set('style', {
				// 		display: ''
				// 	});
				// 	this.threeHourForwardButton.set('style', {
				// 			display: ''
				// 	});
				// 	this.oneHourBackButton.set('style', {
				// 			display: ''
				// 	});
				// 	this.oneHourForwardButton.set('style', {
				// 			display: ''
				// 	});


				// } else {
                	// 1時間前進・後進、3時間前進・後進のボタンを非表示にする
                	this.threeHourBackButton.set('style', {
                    display: 'none'
                    });
                   	this.threeHourForwardButton.set('style', {
                        display: 'none'
                    });
                   	this.oneHourBackButton.set('style', {
                        display: 'none'
                    });
                   	this.oneHourForwardButton.set('style', {
                        display: 'none'
                    });

                	// 10分前進・後進、1時間前進・後進のボタンを表示する
                   	this.tenMinutesBackButton.set('style', {
                        display: ''
                    });
                   	this.tenMinutesForwardButton.set('style', {
                        display: ''
                    });
                   	this.sixtyMinutesBackButton.set('style', {
                        display: ''
                    });
                   	this.sixtyMinutesForwardButton.set('style', {
                        display: ''
                    });
        		}

        	}
        },

        /**
         * 最新ボタンがクリックされた際に呼ばれる
         */
        onLatestButtonClick: function() {
        	// 最新ボタンクリックを通知
        	topic.publish(TOPIC.CLICK_LATEST, {});
        }
    });

    // トピックを公開
    dateTimeSelect.TOPIC = TOPIC;

    return dateTimeSelect;
});
