/**
 * 防災ポータル情報配信登録用ダイアログモジュール。
 * @module app/prefsitesending/PrefSiteSendingRegisterDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/PrefSiteSendingRegisterDialog.html',
    'dojo/dom-style',
    'dijit/registry',
    'idis/model/UserInfo',
    './_PrefSiteSendingDialogBase',
    '../config'
    // 以下、変数で受けないモジュール
    // 変数で受けないモジュールなし
], function(module, declare, lang, template, domStyle, registry, UserInfo, 
        _PrefSiteSendingDialogBase, config) {
    /**
     * 防災ポータル情報配信登録用ダイアログ
     * @class PrefSiteSendingDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PrefSiteSendingDialogBase, {
        // テンプレート文字列
        templateString: template,

        // 添付ファイル
        attachFileList: [],

        constructor: function() {
            this.inherited(arguments);
        },

        buildRendering: function() {
            var obj = registry.byId('outerRegisterSelectorDialog');
            if (obj) {
                registry.remove('outerRegisterSelectorDialog');
            }
            obj = registry.byId('registerSelectorDialog');
            if (obj) {
                registry.remove('registerSelectorDialog');
            }
            this.inherited(arguments);
        },

        // DOM要素構築後に呼ばれる
        postCreate: function() {
            this.inherited(arguments);
            // レベルの選択肢によって表示優先度の表示を切り替える
            this.level.on('change', lang.hitch(this, function(value) {
                if (value === '5') {
                    domStyle.set(this.priorityLevel, 'display', 'inline-block');
                    //レベルが緊急の場合、表示優先度は空を設定し、任意のレベル設定を促す
                    this.priorityLevelSelector.set('value', '');
                } else {
                    domStyle.set(this.priorityLevel, 'display', 'none');
                    //レベルが緊急以外の場合、表示優先度は1を設定する
                    this.priorityLevelSelector.set('value', '1');
                }
            }));
        },

        /**
         * ダイアログの初期化
         */
        initDialog: function() {
            this.form.reset();
            this.clearPreview();
            this.municipalityCd.set('value', UserInfo.getMunicipalityCd() || config.municInfo.prefMunicCd);
        },

        /**
         * 新規登録
         */
        onSubmit: function() {
            try {
                console.debug('[登録]ボタンがクリックされました。');

                // フォームのバリデーションを実施
                if (!this.form.validate()) {
                    return false;
                }

                // 固有のバリデーションを実施
                if(!this.validate()) {
                    return false;
                }

                var value = this.form.get('value');
                value.attachFileList = this.attachFileList;

                this.emit('register', {value: value});
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * テンプレート選択ダイアログの表示
         */
        showTemplateDialog: function() {
            var selectorDialog = registry.byId('detailSelectorDialog');
            selectorDialog.registerObj = this;

            var innerDialog = this.dialog.getChildren()[0];
            innerDialog.resize();

            this.dialog.show();
        }
    });
});
