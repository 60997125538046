/**
 * 安否確認状況一覧用モジュール。
 *
 * @module app/safety/mail/SafetyMailListPage
 */
define(['module',
        'dojo/_base/declare',
        'dojo/_base/lang',
        'dojo/date/locale',
        'dojo/dom-style',
        'dojo/text!./templates/SafetyMailListPage.html',
        'idis/view/dialog/DialogChain',
        'idis/view/page/_PageBase',
        'idis/view/dialog/InfoDialog',
        'idis/control/Router',
        'idis/store/IdisRest',
        'idis/service/Requester',
        'idis/control/Locator',
        'idis/view/Loader',
        // 以下、変数で受けないモジュール
        'dijit/layout/BorderContainer',
        'dijit/layout/ContentPane',
        'dijit/form/Form',
        'dijit/form/Select',
        'dijit/form/TextBox',
        'app/safety/auth/view/form/AclButton',
        'idis/view/form/Button',
        'idis/view/form/DateTimeInput',
        'app/view/form/EmployeeDivisionSelector',
        'app/safety/mail/SafetyMailListGrid'],
        function(module, declare, lang, locale, domStyle, template, DialogChain, _PageBase, InfoDialog,
                 Router, IdisRest, Requester, Locator, Loader) {
    /**
     * 安否確認状況一覧ダイアログ
     *
     * @class SafetyMailListPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/safety/mail/SafetyMailListPage# */
    {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--safety',

        /**
         * グローバル変数の初期化を行うメソッド。
         */
        constructor: function() {
            this.distributionId = Locator.getQuery().mid;
            this.divisionCd = Locator.getQuery().dcd;
            this.groupCd = Locator.getQuery().gcd;
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'employeeCd',
                target: '/api/safety/mails/list/' + this.distributionId +
                        '/' + this.divisionCd
            });
            // 連鎖ダイアログを登録
            this.chain = DialogChain.get(this);
        },

        /**
         * DOMノードを生成するためのメソッド
         */
        buildRendering: function() {
            this.inherited(arguments);
            // 件名、本文、配信時刻を設定する
            this.initForm();
            this.initSafetyStatusListGrid();
        },

        /**
         * 一覧画面上部のコンテンツを生成するためのメソッド
         */
        initForm: function(){
            Requester.get('/api/safety/mails/forms/' + this.distributionId)
            .then(lang.hitch(this,function(data) {
                this.title.innerHTML = data.title;
                this.division.set('value',this.divisionCd);
                if (typeof data.distributeTimeStamp !== 'undefined' && data.distributeTimeStamp !== null) {
                    // distributeTimeStampがnullかundefinedではない場合フォーマットを変える
                    var date = locale.parse(data.distributeTimeStamp, {
                        datePattern: 'yyyy-MM-dd',
                        timePattern: 'HH:mm:ss'
                    });
                    this.distributeTimeStamp.innerText = locale.format(date, {
                        datePattern: 'yyyy/MM/dd',
                        timePattern: 'HH:mm:ss'
                    });
                } else {
                    this.distributeTimeStamp.innerText = '-';
                }
                this.groupName.innerHTML = data.groupName;

                // 回答カラムのラベルを書き換え
                var columns = this.SafetyStatusListGrid.columns;
                columns['0-10'].label = !data.distQ01 ? '' : data.distQ01;
                columns['0-11'].label = !data.distQ02 ? '' : data.distQ02;
                columns['0-12'].label = !data.distQ03 ? '' : data.distQ03;
                this.SafetyStatusListGrid.renderHeader();
            }));
        },

        /**
         * Gridの初期化を行うメソッド
         */
        initSafetyStatusListGrid: function() {
            this.SafetyStatusListGrid.set('collection', this.store);
            // グリッドの一覧ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.SafetyStatusListGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                // helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
                this.moveSafetyDetailPage(evt.item);
            }));
            this.SafetyStatusListGrid.on('confirmButtonClick', lang.hitch(this, function(evt) {
                // helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
                this.chain.confirm('安否確認状況を「確認済み」に更新します。よろしいですか？', function(chain) {
                    // 更新リクエストを実施
                    Loader.wait(Requester.put('/api/safety/mails/updatestatus/' +
                        evt.item.distributionDetailId)).then(lang.hitch(this, function() {
                        // 更新成功時は完了ダイアログを表示
                        chain.infoComplete(lang.hitch(this, function() {
                            chain.hide().then(lang.hitch(this, function() {
                                // 一覧を更新する
                                this.initSafetyStatusListGrid();
                            }));
                        }));
                    }, function(err) {
                        // 更新失敗時
                        chain.infoError(err);
                    }));
                },function(chain) {
                    chain.hide();
                });    
            }));
        },

        /**
         * 安否確認状況詳細画面への遷移を行うメソッド
         */
        moveSafetyDetailPage: function(item){
            // Gridの情報(item)が引数となっている
            var empCd = item.employeeCd;
            // メールのIDと職員のIDをURLに追加し、詳細ページへ遷移
            Router.moveTo('safety/statusDetail',
                {
                mid : this.distributionId,
                gcd : this.groupCd,
                dcd : this.divisionCd,
                ecd : empCd
                });
        },

        /**
         * 検索ボタンが押された際に呼ばれるメソッド
         */
        onSubmit: function() {
          try {
              if (this.form.validate()) {
                  // 入力値が正常ならグリッドの検索条件を更新
                  this.updateGridQuery(this.form.get('value'));
              }
          } catch (e) {
              console.error(e);
          }
          return false;
        },

        /**
         * @param evt ボタンクリックイベント
         * パンくずリスト（管理画面）が押された際に呼ばれるメソッド
         */
        onSafetyAdminPageLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            // 管理画面へ遷移
            Router.moveTo('safety/mail');
        },

        /**
         * @param evt ボタンクリックイベント
         * パンくずリスト（概況画面）が押された際に呼ばれるメソッド
         */
        onSafetyStatusPageLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            // 配信IDと課コードをURLに含んで概況画面へ遷移
            Router.moveTo('safety/statusOverview',{
                mid : this.distributionId,
                dcd : this.divisionCd
            });
        },

        /**
         * 帳票出力ボタンが押された際に呼ばれるメソッド
         */
        outputListExcel: function() {
            console.log('[帳票出力]ボタンがクリックされました。');

            var url = '/api/safety/mails/details.csv';
            url += '?distributionId=' + this.distributionId;
            url += '&divisionCd=' + this.divisionCd;

            // 確認ダイアログを表示
            this.chain.confirm('一覧に表示されているデータを出力します。<br>' +
                'よろしいですか？', function(chain) {
                // 確認ダイアログでOKを押した場合
                chain.hide();
                // URLを遷移する
                window.location.href=url;
            });
        },

        /**
         * @param value formのデータ
         * formから送られた検索条件に合致する安否情報をグリッドに表示する。
         */
        updateGridQuery: function(value) {
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();
            // 職員番号
            if (value.employeeCd) {
                filter = filter.eq('employeeCd',value.employeeCd);
            }
            // 名前
            if (value.name) {
                filter = filter.eq('name',value.name);
            }
            // 名前
            if (value.replyStatus) {
                filter = filter.eq('replyStatus',value.replyStatus);
            }
            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);
            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.SafetyStatusListGrid.set('collection', collection);
        }
    });
});
