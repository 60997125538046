/**
* ライフライン一覧画面用モジュール。
* @module app/lifeline/LifelineListPage
*/
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/text!./templates/LifelineListPage.html',
    'dojo/topic',
    'idis/consts/USER_TYPE',
    'idis/control/Router',
    'idis/control/Locator',
    'idis/model/UserInfo',
    'idis/store/IdisRest',
    'idis/service/Requester',
    'idis/view/page/_PageBase',
    'idis/view/Loader',
    'app/model/DisasterInfo',
    // 以下、変数として受け取らないモジュール
    'dijit/layout/BorderContainer',
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/TextBox',
    'idis/view/form/DateInput',
    'idis/view/form/DateTimeInput',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'app/lifeline/LifelineListGrid',
    'app/view/form/LifelineSelector'
], function(module, declare, lang, domStyle, template, topic,
    USER_TYPE, Router, Locator, UserInfo, IdisRest, Requester, _PageBase, Loader,
    DisasterInfo) {
    /**
     * ライフライン一覧画面
     * @class lifelineAdminPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/view/page/LifelineAdminPage~LifelineAdminPage# */
        {
            // テンプレート文字列
            templateString: template,
            store: null,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--lifeline',

            constructor: function() {
                this.store = new IdisRest({
                    target: '/api/lifeline'
                });
                // 災害IDをDisasterInfoから取得
                this._disasterId = DisasterInfo.getDisasterId();
                if(!this._disasterId) {
                    this._disasterId = 1;
                    console.warn('災害IDが設定されていません。');
                }
                console.debug('現在のログインユーザID：' + UserInfo.getId());
                console.debug('現在の災害ID：' + DisasterInfo.getDisasterId());
            },

            buildRendering: function() {
                this.inherited(arguments);
                // 概況画面で選択したライフライン種別をセット
                if (Locator.getQuery().lifelineKindCd) {
                    this.lifelineKindCd.set('value', Locator.getQuery().lifelineKindCd);
                }
                this.setButtonStatus();
                this.initGrid();
            },

            setButtonStatus: function() {
                // ライフライン登録権限を持たない場合、新規登録ボタンを非表示
                if (!UserInfo.hasWriteAuthz('F05016')) {
                    domStyle.set(this.RegisterButton.domNode, 'display', 'none');
                }
            },

            initGrid: function() {
                //表を初期条件で表示
                this.updateGridQuery();
                // グリッドの詳細ボタンクリック時の動作を設定する
                // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
                this.grid.on('detailButtonClick', lang.hitch(this, function(evt) {
                    this.onDetailButtonClick(evt.item);
                }));
            },

            /**
             * グリッドの検索条件を指定された値で更新する。
             * @param {Object} value name属性と値のマッピング
             */
            updateGridQuery: function() {
            	var value = this.form.get('value');
                // 入力値を元にグリッド用フィルターを作成
                var filter = new this.store.Filter();

                // 災害ID
                filter = filter.eq('disasterId', this._disasterId);
                // 区
                filter = filter.eq('municipalityCd', value.municipalityCd);
                // ライフライン種別
                filter = filter.eq('lifelineKindCd', value.lifelineKindCd);
                // キーワード
                filter = filter.eq('keyword', value.keyword);
                // 過去報
                if (value.activeFlg.length === 0) {
                    // チェック無＝最新報のみ表示する
                    filter = filter.eq('activeFlg', '1');
                } else {
                    // チェック有＝過去報を含める
                    filter = filter.eq('activeFlg', '0');
                }
                // 報告日時From
                if (value.reportDateTimeFrom) {
                    var reportDateTimeFrom = new Date(value.reportDateTimeFrom).getTime();
                    filter = filter.eq('reportDateTimeFrom', reportDateTimeFrom);
                }
                // 報告日時To
                if (value.reportDateTimeTo) {
                    var reportDateTimeTo = new Date(value.reportDateTimeTo).getTime();
                    filter = filter.eq('reportDateTimeTo', reportDateTimeTo);
                }
                // 更新日時From
                if (value.updateDateTimeFrom) {
                    var updateDateTimeFrom = new Date(value.updateDateTimeFrom).getTime();
                    filter = filter.eq('updateDateTimeFrom', updateDateTimeFrom);
                }
                // 更新日時To
                if (value.updateDateTimeTo) {
                    var updateDateTimeTo = new Date(value.updateDateTimeTo).getTime();
                    filter = filter.eq('updateDateTimeTo', updateDateTimeTo);
                }

                // filterに対応するcollectionを取得
                var collection = this.store.filter(filter);
                // collectionをグリッドにセットする（サーバーにリクエストされる）
                this.grid.set('collection', collection);
            },

            // パンくずリストのボタンを押下したときの挙動
            onLifelineAdminPageLinkClick : function(evt) {
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                Router.moveTo('lifeline/admin');
            },

            //詳細ボタンを押下したとき
            onDetailButtonClick: function(object) {
                // ライフライン詳細画面へ遷移
                console.debug(object);
                Router.moveTo('lifeline/detail', {
                    lifelineId : object.lifelineId
                });
            },

            //新規登録ボタンを押下したとき
            onRegisterButtonClick: function() {
                console.debug('[新規登録]ボタンがクリックされました。');
                // ライフライン新規登録画面へ遷移
                Router.moveTo('lifeline/register');
            },

            /**
             * 検索ボタンが押されたときに呼び出される。
             * テンプレートHTML内のFormウィジェットに対するdata-dojo-attach-eventでこのイベントを紐付けている。
             * また、同Formウィジェットに対しdata-dojo-attach-pointを指定し、this.formからアクセス出来るようにしている。
             */
            onSubmit: function() {
                try {
                    if (this.form.isValid()) {
                        // 入力値が正常ならグリッドの検索条件を更新
                        this.updateGridQuery();
                    }
                } catch (e) {
                    console.error(e);
                } finally {
                    return false;
                }
            }
        }
    );
});
