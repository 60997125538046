/**
 * 気象情報画面用モジュール。
 * @module app/weatherinfo/view/WeatherInfoPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-construct',
	'dojo/_base/array',
    'idis/control/Router',
    'dojo/text!./templates/WeatherInfoPage.html',
    './_WeatherInfoPageBase',
    'idis/store/IdisRest',
    'app/util/DateFormatter',
    'dojo/when',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/TextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/layout/TabContainer',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'idis/view/form/AclButton',
    './WeatherInfoGrid'
], function(module, declare, lang, construct, array, Router, template, _PageBase, IdisRest, DateFormatter, when) {
    /**
     * 気象情報画面
     * @class WeatherInfoPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/weatherinfo/view/WeatherInfoPage~WeatherInfoPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--weatherinfo',

        // ストア
        store: null,

        constructor: function() {
            this.inherited(arguments);
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'areaCode',
                target: '/api/WeatherInformations'
            });
        },

        /**
         * 細分区域を切り替える
         */
        switchDivision: function(evt) {
            evt.preventDefault();
            // 気象警報・注意報（地域）画面へ遷移
            Router.moveTo('weatherinfo/region');
        },

        onWeatherInfoTimeLineButtonClick: function() {
            console.debug('[時系列表示に切り替え]ボタンがクリックされました。');
            // 気象情報（時系列表示）画面へ遷移
            Router.moveTo('weatherinfo/timeline');
        }
    });
});