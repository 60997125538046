/**
 * お知らせ情報グリッド
 * @module app/provide/ProvideInformationGrid
 */
define([
    'module',
    'dojo/_base/lang',
    'dojo/_base/declare',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function(module, lang, declare, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        // IdisGrid拡張：行自体にCSSクラスを追加する場合に指定する
        rowClassName: function(item) {
            return item && !item.pubFlg ? 'is-private' : '';
        },
        /**
         * 各列の定義
         * @type {Object[]}
         */
        collection: this.store,
        columns: [
            helper.buttonColumn('detail', '詳細'),
            helper.column('sendCategory', '種別',
                {formatMap: {
                    '01': '避難勧告・指示',
                    '02': '開設避難所',
                    '03': '災害対策本部設置状況',
                    '04': 'お知らせ情報',
                    '05': '緊急速報メール情報'
                }}),
            helper.column('subject', 'タイトル'),
            helper.column('municipalityName', '市/行政区'),
            helper.column('sendTimestamp', '配信日時'),
            helper.column('distributionStatus', '運用種別',
                {formatMap: {
                    '01': '本番',
                    '02': '訓練',
                    '03': 'テスト'
                }}),
            helper.column('distributionType', '更新種別',
                {formatMap: {
                    '01': '新規',
                    '02': '更新・訂正',
                    '03': '取消'
                }}),
            // TODO 要確認Web側のEntity及びDTOはlAlertSendStatusなのに、
            // レスポンスでlalertとaが小文字になる。
            helper.column('sendStatus', '配信状況',
                {formatMap: {
                    '1': '完了',
                    '9': '失敗'
                }})
        ]
    });
});
