/**
* 防災ポータル情報配信テンプレート登録画面用モジュール。
* @module app/prefsitesending/PrefSiteSendingTemplatePage
*/
define([
    'module',
    'dojo/dom-style',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/PrefSiteSendingTemplatePage.html',
    'idis/control/Router',
    'idis/model/UserInfo',
    'idis/store/IdisRest',
    'idis/view/dialog/DialogChain',
    'idis/view/page/_PageBase',
    'idis/consts/ACL',
    'idis/view/Loader',
    // 以下、変数として受け取らないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/ValidationTextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/Button',
    'idis/view/form/AclButton',
    'app/view/form/MunicipalitySelector',
    './PrefSiteSendingTemplateGrid',
    './PrefSiteSendingTemplateRegisterDialog',
    './PrefSiteSendingTemplateDetailDialog'
], function(module, domStyle, declare, lang, template,
        Router, UserInfo, IdisRest, DialogChain, _PageBase, ACL, Loader) {
        /**
         * 防災ポータル情報配信テンプレート新規登録画面。
         * @class PrefSiteSendingTemplatePage
         * @extends module:idis/view/page/_PageBase~_PageBase
         */
        return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/prefsitesending/PrefSiteSendingTemplatePage~PrefSiteSendingTemplatePage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--prefsitesending',

        /**
         * データ格納用オブジェクト
         * @type {module:dstore/Store}
         */
        store: null,

        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'sendingTmplId',
                target: '/api/prefsitesending/template'
            });

            // 連鎖ダイアログを登録
            this.chain = DialogChain.get(this);

            // ベースクラスのコンストラクタを呼ぶ
            this.inherited(arguments);
        },

        buildRendering: function() {
            this.inherited(arguments);
            this.own(this.registerDialog);
            this.own(this.detailDialog);
        },

        startup: function() {
            this.inherited(arguments);
            this.initGrid();
            this.initDialog();
            this.setButtonStatus();
        },

        /**
         * グリッドの初期化
         */
        initGrid: function() {
            // グリッドの詳細ボタンクリック時の動作を設定する
            this.grid.on('detailButtonClick', lang.hitch(this, function(evt) {
                this.showDetailDialog(evt.item);
            }));

            this.updateGridQuery();
        },

        /**
         * ダイアログの初期化
         */
        initDialog: function() {
        	this.initRegisterDialog();
        	this.initDetailDialog();
        },

        /**
         * ボタンの表示非表示
         */
        setButtonStatus: function(){
            var role = UserInfo.getRoleCd();
            if (role === ACL.OTHER_USER) { // TODO 要確認。とりあえずその他ユーザを除外。
                domStyle.set(this.registerButton.domNode, 'display', 'none');
            }
        },

        /**
         * 新規登録ダイアログの初期化
         */
        initRegisterDialog: function() {
            var dialog = this.registerDialog;
            var page = dialog.getChildren()[0];

            // 登録画面のregisterイベントを受ける
            page.on('register', lang.hitch(this,function(evt) {
                console.debug('registerイベント');

                // 追加確認ダイアログを表示
                this.chain.confirmAdd(function(chain) {
                    // OKが押された場合
                    var sendData = evt.value;
                    console.log(sendData);
                    // 追加処理と共にローディング表示
                    Loader.wait(this.store.add(sendData)).then(function() {
                        // 成功時（POST結果はグリッドが自動的に反映）
                        // 登録ダイアログを閉じる
                        dialog.hide();
                        // 完了ダイアログを表示
                        chain.infoComplete();
                    }, function(err) {
                        // 失敗時
                        if(err.response.data) {
                            chain.infoError(err);
                        }
                        chain.infoError(err);
                    });
                });
            }));
        },

        /**
         * 詳細ダイアログの初期化
         */
        initDetailDialog: function() {
            var dialog = this.detailDialog;
            var page = dialog.getChildren()[0];

            // 詳細画面のremoveイベントを受ける
            page.on('remove', lang.hitch(this, function(evt) {
                console.debug('removeイベント');
                var sendData = evt.value;
                this.chain.confirmDel(function(chain) {
                    var promise = this.store.remove(
                        sendData.sendingTmplId + 
                        '?version=' + sendData.version);
                    Loader.wait(promise).then(lang.hitch(this, function() {
                        // 詳細ダイアログを閉じる
                        dialog.hide();
                        // 完了ダイアログを表示
                        chain.infoComplete();
                        // 一覧を更新する
                        this.updateGridQuery();
                    }), function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));

            // 詳細画面のupdateイベントを受ける
            page.on('update', lang.hitch(this, function(evt) {
                console.debug('updateイベント');

                // 更新確認ダイアログを表示
                this.chain.confirmPut(function(chain) {
                    var sendData = evt.value;

                    // 更新処理と共にローディング表示
                    Loader.wait(this.store.put(sendData)).then(function() {
                        // 成功時（POST結果はグリッドが自動的に反映）
                        // 更新ダイアログを閉じる
                        dialog.hide();
                        // 完了ダイアログを表示
                        chain.infoComplete();
                    }, function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));
        },

        /**
         * グリットの更新
         */
         updateGridQuery: function() {
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();
            var value = this.form.get('value');

            // 市町村
            if (value.municipalityCd && value.municipalityCd !== '') {
                filter = filter.eq('municipalityCd', value.municipalityCd);
            }

            // キーワード
            if (value.keyword && value.keyword !== '') {
                filter = filter.eq('searchText', value.keyword);
            }

            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);

            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.grid.set('collection', collection);
        },

        /**
         * テンプレートの検索
         */
        onSubmit: function() {
            console.debug('[検索]ボタンがクリックされました。');
            try {
                if (this.form.isValid()) {
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery();
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * 新規登録ダイアログの表示
         */
        showRegisterDialog: function() {
            this.innerRegisterDialog.initDialog(this);
            this.registerDialog.show();
        },

        /**
         * 詳細ダイアログの表示
         */
        showDetailDialog: function(object) {
            this.innerDetailDialog.initDialog(object);
            this.detailDialog.show();
        },

        /**
         * 配信情報一覧画面へ遷移する。
         */
        onPrefSiteSendingPageLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('prefsitesending');
        }
    });
});
