define([
      {
         id:'01',
         name:['＞詳細'],
         url:['sourceUrl']
      },
      {
         id:'03',
         name:['＞詳細'],
         url:['sourceUrl']
      },
      {
         id:'24',
         // name:['＞地図を確認','＞詳細'],
         // url:['/?l=402-0','sourceUrl']
         name:['＞詳細'],
         url:['sourceUrl']
      },
      {
         id:'25',
         name:['＞詳細'],
         url:['sourceUrl']
      },
      {
         id:'26',
         //name:['＞地図を確認','＞詳細'],
         //url:['/?l=401-0','sourceUrl']
         name:['＞詳細'],
         url:['sourceUrl']
     },
     // {
     //    id:'27',
     //    name:['＞詳細'],
     //    url:['sourceUrl']
     // },
     {
        id:'28',
        name:['＞詳細'],
        url:['sourceUrl']
    },
    {
       id:'32',
       name:['＞詳細'],
       url:['sourceUrl']
    },
    {
       id:'33',
       name:['＞詳細'],
       url:['sourceUrl']
    },
    {
       id:'41',
       name:['＞詳細'],
       url:['sourceUrl']
    },
    {
       id:'42',
       name:['＞詳細'],
       url:['sourceUrl']
    }
    ]
);
