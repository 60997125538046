/**
 * 土砂災害危険度情報一覧画面用モジュール。
 * @module app/sedimentrisk/SedimentRiskObservationPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/SedimentRiskObservationPage.html',
    'idis/view/page/_PageBase',
    'dojo/dom',
    'dijit/registry',
    'dijit/Dialog',
    'dstore/Memory',
    'dstore/Rest',
    'idis/control/Router',
    'idis/consts/ACL',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'idis/store/IdisRest',
    'idis/service/Requester',
    // 以下、変数で受けないモジュール
    'app/view/form/RegionMuni4ObservatorySelector',
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/TextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/layout/TabContainer',
    'idis/view/form/Button',
    'idis/view/form/AclButton',
    'idis/view/form/DateTimeInput',
    './SedimentRiskObservationGrid'
], function(module, declare, lang, template, _PageBase, dom, registry,
		Dialog, Memory, Rest, Router, ACL, IdisGrid, helper, IdisRest, Requester) {
    /**
     * 土砂災害危険度情報一覧画面
     * @class SedimentRiskObservationPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    	/** @lends module:app/sedimentrisk/SedimentRiskObservationPage~SedimentRiskObservationPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--sedimentriskobs',

        // ストア
        store: null,

        // 変更フラグ
        // DateTimeInput生成時にDateTextBoxとTimeTextBoxに値がセットされてイベントが発火されてしまうため、
        // changeFlgが2以下の時にはイベントが発火されないように制御するためのフラグ。
        changeFlg: 0,

        // グリッド
        grid: null,

        //コンストラクタ
        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'dosyaAreaDataId',
                target: '/api/sedimentRisk'
            });
        },

        // DOMノードを生成するためのメソッド
        buildRendering: function() {
            this.inherited(arguments);
            this.initForm();
        },

        initForm: function() {
            // 初期時刻の取得・Gridの初期化
            this.initDateTime();
            // 自分が削除された時は関連ダイアログも削除する
            this.initDateTimeFunction();
        },

        updateGridQuery: function(value) {
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();
            // 地域/市町村セレクタ
            switch (this.areaSelector.getAreaType()) {
                case 'REGION':          //地域を選択
                    filter = filter.eq('regionCd', value.areaCd);
                    break;
                case 'MUNICIPALITY':    // 市町村を選択
                    filter = filter.eq('municipalityCd', value.areaCd);
                    break;
            }

            if (value.date){
                var date = new Date(value.date).getTime();
                filter = filter.eq('date', date);
            }
            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);
            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.grid.set('collection', collection);
        },

        // 観測日時の初期時刻を表示するためのメソッド
        // Gridの初期化も同時に行う
        initDateTime: function(){
            var date = this.date;
            var self = this;
            Requester.get('/api/sedimentRisk/latestDataTime').then(function(data) {
                date._setValueAttr(data);
            }).then(function(){
                self.initGrid();
            });
        },

        /**
         * グリッドを初期化する。
         */
        initGrid: function() {
            var filter = new this.store.Filter();
            var date = new Date(this.date._getValueAttr()).getTime();
            filter = filter.eq('date', date);
            var collection = this.store.filter(filter);
            this.grid.set('collection', collection);
        },

        /**
         * [観測日時]の変更にあわせて表の更新を行うイベントリスナーを登録する。
         */
        initDateTimeFunction: function(){
        	// DateTimeInputの生成時にそれぞれ一度ずつ呼ばれてしまうため、三回目以降に呼ばれたときにrequestが送られるようにしている。
            this.own(this.date._date.on('change',
                lang.hitch(this,function(){
                    console.debug('[観測日時]ボックスの日付が変更されました。');
                    this.changeFlg++;
                    this.onSubmit();
                })
            ));
            this.own(this.date._time.on('change',
                lang.hitch(this,function(){
                    console.debug('[観測日時]ボックスの時間が変更されました。');
                    this.changeFlg++;
                    this.onSubmit();
                })
            ));
        },

        onSubmit: function() {
                try {
                    if (this.form.isValid() && this.changeFlg >= 2) {
                        // 入力値が正常ならグリッドの検索条件を更新
                        this.updateGridQuery(this.form.get('value'));
                    }
                } catch (e) {
                    console.error(e);
                } finally {
                    return false;
                }
        },

        filterByType: function(value) {
            alert(value);
        },

        onHourBackButtonClick: function() {
            console.debug('[1時間前]ボタンがクリックされました。');
            var nowDateTime = this.date._getValueAttr();
            var newDate = nowDateTime.getHours() - 1;
            var newDateTime = nowDateTime.setHours(newDate);
            this.date._setValueAttr(newDateTime);
        },

        on10MinutesBackButtonClick: function() {
            console.debug('[10分前]ボタンがクリックされました。');
            var nowDateTime = this.date._getValueAttr();
            var newMinutes = nowDateTime.getMinutes() - 10;
            var newDateTime = nowDateTime.setMinutes(newMinutes);
            this.date._setValueAttr(newDateTime);
        },

        on10MinutesFeedButtonClick: function() {
            console.debug('[10分後]ボタンがクリックされました。');
            var nowDateTime = this.date._getValueAttr();
            var newMinutes = nowDateTime.getMinutes() + 10;
            var newDateTime = nowDateTime.setMinutes(newMinutes);
            this.date._setValueAttr(newDateTime);
        },

        onHourFeedButtonClick: function() {
            console.debug('[1時間後]ボタンがクリックされました。');
            var nowDateTime = this.date._getValueAttr();
            var newDate = nowDateTime.getHours() + 1;
            var newDateTime = nowDateTime.setHours(newDate);
            this.date._setValueAttr(newDateTime);
        },
        onRegionChange: function(){
            console.debug('[地域]セレクトボックスが変更されました。');
            this.onSubmit();
        },

        onLatestTimeButtonClick: function() {
            console.debug('[最新]ボタンがクリックされました。');
            var date = this.date;
            var self = this;
            // 非同期通信によりdateの値がnullになることを回避
            Requester.get('/api/sedimentRisk/latestDataTime').then(function(data) {
                date._setValueAttr(data);
            }).then(function(){
                self.onSubmit();
            });
        }

    });
});
