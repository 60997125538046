define([
	'module',
	'dojo/_base/declare',
	'dojo/_base/lang',
	'idis/view/grid/IdisGrid',
	'idis/view/grid/helper'
], function (module, declare, lang, IdisGrid, helper) {
	return declare(module.id.replace(/\//g, '.'), IdisGrid, {

		// ルート要素に付与されるCSS
		baseClass: 'idis-Page idis-Page--facility-master',


		/**
		 * 各列の定義
		 * @type {Object[]}
		 */
		columns: [
			helper.buttonColumn('detail', '詳細'),
			helper.column('facilityId', '施設ID'),
			helper.column('facilityName', '施設名称'),
			helper.column('address', '住所'),
			helper.column('facilityBldCd', '建物コード'),
			helper.column('facilityAdmOrgName', '管理所属', { sortable: false }),
		]
	});
});
