/**
 * 組織管理画面用モジュール
 * @module app/organization/OrganizationAdminPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/json',
    'dojo/text!./templates/OrganizationAdminPage.html',
    'idis/view/page/_PageBase',
    'dojo/Deferred',
    'dojo/dom',
    // 'dojo/dom-class',
    'dijit/registry',
    'dijit/Dialog',
    'dstore/Memory',
    'dstore/Rest',
    'idis/consts/ACL',
    'idis/control/Router',
    'idis/view/grid/IdisGrid',
    'idis/store/IdisRest',
    'idis/util/FilesUtils',
    'idis/view/dialog/InfoDialog',
    'idis/view/Loader',
    'idis/view/dialog/DialogChain',
    'idis/consts/USER_TYPE',
    'idis/model/UserInfo',
    '../config',
    'app/model/Region',
    'dojo/dom-style',
    // 以下変数で受けないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/TextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/layout/TabContainer',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/RadioGroup',
    'idis/view/form/WordCountTextarea',
    'app/view/form/MunicipalitySelector',
    'app/view/form/ManageRegionMunicipalitySelector',
    'app/view/form/RegionSelector',
    'app/view/form/MunicRegionOrganizationSelector',
    './DeptGrid',
    './SectGrid',
    './UnitGrid',
    './DeptRegisterDialog',
    './DeptDetailDialog',
    './SectRegisterDialog',
    './SectDetailDialog',
    './UnitRegisterDialog',
    './UnitDetailDialog',
    './OrganizationFileUploadDialog',
    './OrganizationFormDialog'
], function(module,
    declare, lang, json,
    template, _PageBase,
    Deferred,
    dom,
    // domClass,
    registry,
        Dialog, Memory, Rest, ACL, Router, IdisGrid, IdisRest, FilesUtils,
        InfoDialog,
        Loader,
        DialogChain, USER_TYPE, UserInfo, config, Region, domStyle) {
    /**
     * 組織管理画面
     * @class OrganizationAdminPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/organization/OrganizationAdminPage~OrganizationAdminPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--organization',

        /**
         * 首長ストア
         */
        mayorStore: null,

        /**
         * 罹災証明担当ストア
         */
        victimCertContactStore: null,

        /**
         * 部ストア
         */
        deptStore: null,

        /**
         * 組織ストア
         */
        sectStore: null,

        /**
         * 係ストア
         */
        unitStore: null,

        /**
         * 市町コード
         */
        _adminDistCd: null,

        /**
         * 部コード
         */
        _deptCd: null,

        /**
         * 組織コード
         */
        _sectCd: null,

        /**
         * 係コード
         */
        _unitCd: null,

        /**
         * constructor
         */
        constructor: function() {
            // データ格納用オブジェクト
            this.mayorStore = new IdisRest({
                idProperty: 'adminDistCd',
                target: '/api/organization/mayors'
            });

            this.victimCertContactStore = new IdisRest({
                idProperty: 'formContactCd',
                target: '/api/organization/victimCertContact'
            });

            this.deptStore = new IdisRest({
                idProperty: 'deptCd',
                target: '/api/organization/depts',
                sortParam: 'sort',
                rangeStartParam: 'offset',
                rangeCountParam: 'count'
            });

            this.sectStore = new IdisRest({
                idProperty: 'sectCd',
                target: '/api/organization/sects',
                sortParam: 'sort',
                rangeStartParam: 'offset',
                rangeCountParam: 'count'
            });

            this.unitStore = new IdisRest({
                idProperty: 'unitCd',
                target: '/api/organization/units',
                sortParam: 'sort',
                rangeStartParam: 'offset',
                rangeCountParam: 'count'
            });

            // this.deptStore = CacheStore.getInstance(this.deptStoreUrl);
            // this.sectStore = CacheStore.getInstance(this.sectStoreUrl);
            // this.unitStore = CacheStore.getInstance(this.unitStoreUrl);

            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
            // 自分が削除された時は関連ダイアログも削除する
            this.own(this.deptRegisterDialog);
            this.own(this.deptDetailDialog);
            this.own(this.sectRegisterDialog);
            this.own(this.sectDetailDialog);
            this.own(this.unitRegisterDialog);
            this.own(this.unitDetailDialog);
            // this.own(this.changeMayorDialog);
            this.own(this.organizationFileUploadDialog);
            this.own(this.organizationFormDialog);
        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);

            this.initGrid();
            this.initPage();
        },

        /**
         * グリッドを初期化する。
         */
        initGrid: function() {
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.deptGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                this.showDeptDetailDialog(evt.item);
            }));

            this.sectGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                this.showSectDetailDialog(evt.item);
            }));

            this.unitGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                this.showUnitDetailDialog(evt.item);
            }));

            this.updateDeptGridQuery();
            this.updateSectGridQuery();
            this.updateUnitGridQuery();
        },

        /**
         * 組織管理画面を初期化する。
         */
        initPage: function() {
            console.log('init Page');
            // 部新規登録ダイアログを初期化
            this.initDeptRegisterDialog();

            // 部詳細ダイアログを初期化
            this.initDeptDetailDialog();

            // 課新規登録ダイアログを初期化
            this.initSectRegisterDialog();

            // 課詳細ダイアログを初期化
            this.initSectDetailDialog();

            // 係新規登録ダイアログを初期化
            this.initUnitRegisterDialog();

            // 係詳細ダイアログを初期化
            this.initUnitDetailDialog();

            // 首長変更ダイアログを初期化
            // this.initChangeMayorDialog();

            // 罹災証明担当変更ダイアログを初期化
            // this.initChangeVictimCertContactDialog();

            // 組織ファイルアップロードダイアログを初期化
            this.initOrganizationFileUploadDialog();

            // 振興局ユーザの場合のみ、自治体ダイアログのクリアボタンを非表示にする
            if(UserInfo.getUserType() === USER_TYPE.REGION){
                domStyle.set(this.deptMunicSelector.clearButton.domNode, 'display', 'none');
                domStyle.set(this.municipalityCdForSect.clearButton.domNode, 'display', 'none');
                domStyle.set(this.municipalityCdForUnit.clearButton.domNode, 'display', 'none');
            }

             // 市町村ユーザの場合のみ、自治体ダイアログ用選択ボタンを非表示にする
            if (UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd &&
                    UserInfo.getUserType() === USER_TYPE.MUNICIPALITY){
                this.deptMunicSelector.set('noAllButton', true);
                this.municipalityCdForSect.set('noAllButton', true);
                this.municipalityCdForUnit.set('noAllButton', true);
            }

            // 部選択ダイアログの「＋」「－」ボタンを非表示にする
            domStyle.set(this.organizationSelectorForSect.expandAllButton.domNode, 'visibility', 'hidden');
            domStyle.set(this.organizationSelectorForSect.collapseAllButton.domNode, 'visibility', 'hidden');

            this.organizationSelectorForSect.restoreRefresh();
            this.organizationSelectorForUnit.restoreRefresh();

            // ユーザーの自治体に応じて組織セレクタの表示を変更する
            this.onMunicipalityCdForSectChanged(this.municipalityCdForSect);
            this.onMunicipalityCdForUnitChanged(this.municipalityCdForUnit);
        },

        /**
         * 部新規登録ダイアログを初期化する。
         */
        initDeptRegisterDialog: function() {
            var dialog = this.deptRegisterDialog;
            var page = dialog.getChildren()[0];

            // 部新規登録ダイアログのdeptRegisterイベントを受け取る
            page.on('register', lang.hitch(this, function(evt) {
                console.debug('Dept registerイベント');
                // 追加確認ダイアログを表示
                this.chain.confirmAdd(function(chain) {
                    // OKが押された場合
                    // 追加処理と共にローディング表示
                    Loader.wait(this.deptStore.add(evt.value)).then(lang.hitch(this, function() {
                        // 成功時（POST結果はグリッドが自動的に反映）
                        // 登録ダイアログを閉じる
                        dialog.hide();
                        // 完了ダイアログを表示
                        chain.infoComplete();
                        // 部・部/課セレクタのリフレッシュ
                        this.refreshSelectorForSectAndUnit();
                        // 再度一覧を検索する
                        this.updateDeptGridQuery();
                    }), function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));
        },

        /**
         * 部詳細ダイアログを初期化する。
         */
        initDeptDetailDialog: function() {
            var dialog = this.deptDetailDialog;
            var page = dialog.getChildren()[0];

            page.on('remove', lang.hitch(this, function(evt) {
                console.debug('removeイベント');
                this.chain.confirmDel(function(chain) {
                    var promise = this.deptStore.remove(evt.value.deptCd);
                    Loader.wait(promise).then(lang.hitch(this, function() {
                        // 登録フォーム・ダイアログを閉じる
                        dialog.hide();
                        chain.infoComplete();
                        // 部・部/課セレクタのリフレッシュ
                        this.refreshSelectorForSectAndUnit();
                        // 一覧を取得しなおす
                        this.updateDeptGridQuery();
                        this.updateSectGridQuery();
                        this.updateUnitGridQuery();
                    }), function (err) {
                        if (err.response.data) {
                            var jsonData = json.parse(err.response.data);
                            // 削除対象組織に所属するユーザが存在する場合はDBエラー（E2001）
                            if (jsonData.code && jsonData.code === 'E2001') {
                                chain.info('対象組織に所属するユーザが存在するため、削除できません。', 'エラー');
                                return;
                            }
                        }
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));

            // 部詳細ダイアログのupdateイベントを受け取る
            page.on('update', lang.hitch(this, function(evt) {
                console.debug('Dept updateイベント');
                // 追加確認ダイアログを表示
                this.chain.confirmPut(function(chain) {
                    var form = evt.value;
                    console.debug(form);
                    // 追加処理と共にローディング表示
                    Loader.wait(this.deptStore.put(form)).then(lang.hitch(this, function() {
                        // 成功時（POST結果はグリッドが自動的に反映）
                        // 登録ダイアログを閉じる
                        dialog.hide();
                        // 完了ダイアログを表示
                        chain.infoComplete();
                        // 部・部/課セレクタのリフレッシュ
                        this.refreshSelectorForSectAndUnit();
                        // 再度一覧を検索する
                        this.updateDeptGridQuery();
                    }), function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));
        },

        /**
         * 課新規登録ダイアログを初期化する。
         */
        initSectRegisterDialog: function() {
            var dialog = this.sectRegisterDialog;
            var page = dialog.getChildren()[0];

            // 課新規登録ダイアログのorganizationRegisterイベントを受け取る
            page.on('register', lang.hitch(this, function(evt) {
                console.debug('Sect registerイベント');
                // 追加確認ダイアログを表示
                this.chain.confirmAdd(function(chain) {
                    // OKが押された場合
                    // 追加処理と共にローディング表示
                    Loader.wait(this.sectStore.add(evt.value)).then(lang.hitch(this, function() {
                        // 成功時（POST結果はグリッドが自動的に反映）
                        // 登録ダイアログを閉じる
                        dialog.hide();
                        // 完了ダイアログを表示
                        chain.infoComplete();
                        // 部・部/課セレクタのリフレッシュ
                        this.refreshSelectorForSectAndUnit();
                        // 再度一覧を検索する
                        this.updateSectGridQuery();
                    }), function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));
        },

        /**
         * 課詳細ダイアログを初期化する。
         */
        initSectDetailDialog: function() {
            var dialog = this.sectDetailDialog;
            var page = dialog.getChildren()[0];

            page.on('remove', lang.hitch(this, function(evt) {
                console.debug('removeイベント');
                this.chain.confirmDel(function(chain) {
                    var promise = this.sectStore.remove(evt.value.sectCd);
                    Loader.wait(promise).then(lang.hitch(this, function() {
                        // 登録フォーム・ダイアログを閉じる
                        dialog.hide();
                        chain.infoComplete();
                        // 部・部/課セレクタのリフレッシュ
                        this.refreshSelectorForSectAndUnit();
                        // 一覧を取得しなおす
                        this.updateSectGridQuery();
                        this.updateUnitGridQuery();
                    }), function (err) {
                        if (err.response.data) {
                            var jsonData = json.parse(err.response.data);
                            // 削除対象組織に所属するユーザが存在する場合はDBエラー（E2001）
                            if (jsonData.code && jsonData.code === 'E2001') {
                                chain.info('対象組織に所属するユーザが存在するため、削除できません。', 'エラー');
                                return;
                            }
                        }
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));

            // 課詳細ダイアログのupdateイベントを受け取る
            page.on('update', lang.hitch(this, function(evt) {
                console.debug('Sect updateイベント');
                // 追加確認ダイアログを表示
                this.chain.confirmPut(function(chain) {
                    var form = evt.value;
                    console.debug(form);
                    // 追加処理と共にローディング表示
                    Loader.wait(this.sectStore.put(form)).then(lang.hitch(this, function() {
                        // 成功時（POST結果はグリッドが自動的に反映）
                        // 登録ダイアログを閉じる
                        dialog.hide();
                        // 完了ダイアログを表示
                        chain.infoComplete();
                        // 部・部/課セレクタのリフレッシュ
                        this.refreshSelectorForSectAndUnit();
                        // 再度一覧を検索する
                        this.updateSectGridQuery();
                    }), function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));
        },

        /**
         * 係新規登録ダイアログを初期化する。
         */
        initUnitRegisterDialog: function() {
            var dialog = this.unitRegisterDialog;
            var page = dialog.getChildren()[0];

            // 係新規登録ダイアログのunitRegisterイベントを受け取る
            page.on('register', lang.hitch(this, function(evt) {
                console.debug('Unit registerイベント');
                // 追加確認ダイアログを表示
                this.chain.confirmAdd(function(chain) {
                    // OKが押された場合
                    // 追加処理と共にローディング表示
                    Loader.wait(this.unitStore.add(evt.value)).then(lang.hitch(this, function() {
                        // 成功時（POST結果はグリッドが自動的に反映）
                        // 登録ダイアログを閉じる
                        dialog.hide();
                        // 完了ダイアログを表示
                        chain.infoComplete();
                        // 部・部/課セレクタのリフレッシュ
                        this.refreshSelectorForSectAndUnit();
                        // 再度一覧を検索する
                        this.updateUnitGridQuery();
                    }), function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));
        },

        /**
         * 係詳細ダイアログを初期化する。
         */
        initUnitDetailDialog: function() {
            var dialog = this.unitDetailDialog;
            var page = dialog.getChildren()[0];

            page.on('remove', lang.hitch(this, function(evt) {
                console.debug('removeイベント');
                this.chain.confirmDel(function(chain) {
                    var promise = this.unitStore.remove(evt.value.unitCd);
                    Loader.wait(promise).then(lang.hitch(this, function() {
                        // 登録フォーム・ダイアログを閉じる
                        dialog.hide();
                        chain.infoComplete();
                        // 部・部/課セレクタのリフレッシュ
                        this.refreshSelectorForSectAndUnit();
                        // 一覧を取得しなおす
                        this.updateUnitGridQuery();
                    }), function (err) {
                        if (err.response.data) {
                            var jsonData = json.parse(err.response.data);
                            // 削除対象組織に所属するユーザが存在する場合はDBエラー（E2001）
                            if (jsonData.code && jsonData.code === 'E2001') {
                                chain.info('対象組織に所属するユーザが存在するため、削除できません。', 'エラー');
                                return;
                            }
                        }
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));

            // 係詳細ダイアログのupdateイベントを受け取る
            page.on('update', lang.hitch(this, function(evt) {
                console.debug('Unit updateイベント');
                // 追加確認ダイアログを表示
                this.chain.confirmPut(function(chain) {
                    var form = evt.value;
                    console.debug(form);
                    // 追加処理と共にローディング表示
                    Loader.wait(this.unitStore.put(form)).then(lang.hitch(this, function() {
                        // 成功時（POST結果はグリッドが自動的に反映）
                        // 登録ダイアログを閉じる
                        dialog.hide();
                        // 完了ダイアログを表示
                        chain.infoComplete();
                        // 部・部/課セレクタのリフレッシュ
                        this.refreshSelectorForSectAndUnit();
                        // 再度一覧を検索する
                        this.updateUnitGridQuery();
                    }), function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));
        },

        /**
         * 首長変更ダイアログを初期化する。
         */
        // initChangeMayorDialog: function() {
        //     var dialog = this.changeMayorDialog;
        //     var page = dialog.getChildren()[0];

        //     // 首長変更ダイアログのupdateイベントを受け取る
        //     page.on('update', lang.hitch(this, function(evt) {
        //         console.debug('Mayor updateイベント');
        //         // 追加確認ダイアログを表示
        //         this.chain.confirmPut(function(chain) {
        //             var form = evt.value;
        //             console.debug(form);
        //             // 追加処理と共にローディング表示
        //             Loader.wait(this.mayorStore.put(form)).then(function() {
        //                 // 成功時（POST結果はグリッドが自動的に反映）
        //                 // 登録ダイアログを閉じる
        //                 dialog.hide();
        //                 // 完了ダイアログを表示
        //                 chain.infoComplete();
        //             }, function(err) {
        //                 // 失敗時
        //                 chain.infoError(err);
        //             });
        //         });
        //     }));
        // },

        /**
         * 罹災証明担当変更ダイアログを初期化する。
         */
        // initChangeVictimCertContactDialog: function() {
        //     var dialog = this.changeVictimCertContactDialog;
        //     var page = dialog.getChildren()[0];

        //     // 罹災証明担当変更ダイアログのupdateイベントを受け取る
        //     page.on('update', lang.hitch(this, function(evt) {
        //         console.debug('VictimCertContact updateイベント');
        //         // 追加確認ダイアログを表示
        //         this.chain.confirmPut(function(chain) {
        //             var form = evt.value;
        //             console.debug(form);
        //             // 追加処理と共にローディング表示
        //             Loader.wait(this.victimCertContactStore.put(form)).then(function() {
        //                 // 成功時（POST結果はグリッドが自動的に反映）
        //                 // 登録ダイアログを閉じる
        //                 dialog.hide();
        //                 // 完了ダイアログを表示
        //                 chain.infoComplete();
        //             }, function(err) {
        //                 // 失敗時
        //                 chain.infoError(err);
        //             });
        //         });
        //     }));
        // },

        initOrganizationFileUploadDialog: function() {
            // 詳細ダイアログの最初の子要素が詳細画面
            var dialog = this.organizationFileUploadDialog;
            var page = dialog.getChildren()[0];
            // 更新ボタンが押された際の動作
            page.on('upload', lang.hitch(this, function() {
                dialog.hide();
            }));
        },

        /**
         * 係を検索する。
         */
        searchUnit: function() {
            console.debug('[係管理]タブの[検索]ボタンがクリックされました。');
            try {
                if (this.unitForm.isValid()) {
                    // 入力値が正常なら係グリッドの検索条件を更新
                    this.updateUnitGridQuery();
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * 課を検索する。
         */
        searchSect: function() {
            console.debug('[課管理]タブの[検索]ボタンがクリックされました。');
            try {
                if (this.sectForm.isValid()) {
                    // 入力値が正常なら組織グリッドの検索条件を更新
                    this.updateSectGridQuery();
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * 部を検索する。
         */
        searchDept: function() {
            console.debug('[部管理]タブの[検索]ボタンがクリックされました。');
            try {
                if (this.deptForm.isValid()) {
                    // 入力値が正常なら組織グリッドの検索条件を更新
                    this.updateDeptGridQuery();
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * 部新規登録ダイアログを表示する。
         */
        showDeptRegisterDialog: function() {
            console.debug('[部情報管理]タブの[新規登録]ボタンがクリックされました。');
            this.innerDeptRegisterDialog.initDialog();
            this.deptRegisterDialog.show();
        },

        /**
         * 部詳細ダイアログを表示する。
         */
        showDeptDetailDialog: function(object) {
            console.debug('[部情報管理]タブの[詳細]ボタンがクリックされました。');
            this._deptCd = object.deptCd;
            this.innerDeptDetailDialog.initDialog(object);
            this.deptDetailDialog.show();
        },

        /**
         * 課新規登録ダイアログを表示する。
         */
        showSectRegisterDialog: function() {
            console.debug('[課情報管理]タブの[新規登録]ボタンがクリックされました。');
            this.innerSectRegisterDialog.initDialog();
            this.sectRegisterDialog.show();
        },

        /**
         * 課詳細ダイアログを表示する。
         */
        showSectDetailDialog: function(object) {
            console.debug('[課情報管理]タブの[詳細]ボタンがクリックされました。');
            this._sectCd = object.sectCd;
            this.innerSectDetailDialog.initDialog(object);
            this.sectDetailDialog.show();
        },

        /**
         * 係新規登録ダイアロを表示する。
         */
        showUnitRegisterDialog: function() {
            console.debug('[係情報管理]タブの[新規登録]ボタンがクリックされました。');
            this.innerUnitRegisterDialog.initDialog();
            this.unitRegisterDialog.show();
        },

        /**
         * 係詳細ダイアログを表示する。
         */
        showUnitDetailDialog: function(object) {
            console.debug('[係情報管理]タブの[詳細]ボタンがクリックされました。');
            this._unitCd = object.unitCd;
            this.innerUnitDetailDialog.initDialog(object);
            this.unitDetailDialog.show();
        },


        showOrganizationFileUploadDialog: function() {
            this.organizationFileUploadDialog.show();
        },

        /**
         * 部グリットを更新する。
         */
        updateDeptGridQuery: function() {
            var filter = new this.deptStore.Filter();
            var value = this.deptForm.get('value');

           /* // 市町村コードによる検索
            if (value.municipalityCd && value.municipalityCd !== '') {
                filter = filter.eq('municipalityCd', value.municipalityCd);
            }
            // 振興局コードによる検索
            if (value.regionCd && value.regionCd !== '') {
                filter = filter.eq('regionCd', value.regionCd);
            }
            // filterに対応するcollectionを取得
            var collection = this.deptStore.filter(filter);

            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.deptGrid.set('collection', collection);*/

            // 道・振興局・市町村の判定
            this.isRegion(value.municipalityCd).then(lang.hitch(this, function(isRegion){
                if(isRegion){
                    filter = filter.eq('regionCd', value.municipalityCd);
                } else if(value.municipalityCd && value.municipalityCd !== config.municInfo.prefCd){
                    filter = filter.eq('municipalityCd', value.municipalityCd);
                } else if (value.municipalityCd && value.municipalityCd !== config.municInfo.prefMunicCd) {
                    filter = filter.eq('municipalityCd', config.municInfo.prefMunicCd);
                }
                // filterに対応するcollectionを取得
                var collection = this.deptStore.filter(filter);
                // collectionをグリッドにセットする（サーバーにリクエストされる）
                this.deptGrid.set('collection', collection);
            }));
        },

        /**
         * 課グリットを更新する。
         */
        updateSectGridQuery: function() {
            var filter = new this.sectStore.Filter();
            var value = this.sectForm.get('value');

            // 部コードによる検索
            if (value.deptCdForSect &&
                value.deptCdForSect !== '') {
                filter = filter.eq('organization', value.deptCdForSect);
            }
            /*// 市町村コードによる検索
            if (value.municipalityCd && value.municipalityCd !== '') {
                filter = filter.eq('municipalityCd', value.municipalityCd);
            }
            // 振興局コードによる検索
            if (value.regionCd && value.regionCd !== '') {
                filter = filter.eq('regionCd', value.regionCd);
            }

            // filterに対応するcollectionを取得
            var collection = this.sectStore.filter(filter);

            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.sectGrid.set('collection', collection);*/

            // 道・振興局・市町村の判定
            this.isRegion(value.municipalityCd).then(lang.hitch(this, function(isRegion){
                if(isRegion){
                    filter = filter.eq('regionCd', value.municipalityCd);
                } else if(value.municipalityCd && value.municipalityCd !== config.municInfo.prefCd){
                    filter = filter.eq('municipalityCd', value.municipalityCd);
                }else if (value.municipalityCd && value.municipalityCd !== config.municInfo.prefMunicCd) {
                    filter = filter.eq('municipalityCd', config.municInfo.prefMunicCd);
                }
                // filterに対応するcollectionを取得
                var collection = this.sectStore.filter(filter);
                // collectionをグリッドにセットする（サーバーにリクエストされる）
                this.sectGrid.set('collection', collection);
            }));
        },

        /**
         * 係グリットを更新する。
         */
        updateUnitGridQuery: function() {
            var filter = new this.unitStore.Filter();
            var value = this.unitForm.get('value');

            // 部コードによる検索
            if (value.deptCdForUnit &&
                value.deptCdForUnit !== '') {
                filter = filter.eq('organization', value.deptCdForUnit);
            }
            /*// 市町村コードによる検索
            if (value.municipalityCd && value.municipalityCd !== '') {
                filter = filter.eq('municipalityCd', value.municipalityCd);
            }
            // 振興局コードによる検索
            if (value.regionCd && value.regionCd !== '') {
                filter = filter.eq('regionCd', value.regionCd);
            }

            // filterに対応するcollectionを取得
            var collection = this.unitStore.filter(filter);

            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.unitGrid.set('collection', collection);*/

            // 道・振興局・市町村の判定
            this.isRegion(value.municipalityCd).then(lang.hitch(this, function(isRegion){
                if(isRegion){
                    filter = filter.eq('regionCd', value.municipalityCd);
                } else if(value.municipalityCd && value.municipalityCd !== config.municInfo.prefCd){
                    filter = filter.eq('municipalityCd', value.municipalityCd);
                }else if (value.municipalityCd && value.municipalityCd !== config.municInfo.prefMunicCd) {
                    filter = filter.eq('municipalityCd', config.municInfo.prefMunicCd);
                }
                // filterに対応するcollectionを取得
                var collection = this.unitStore.filter(filter);
                // collectionをグリッドにセットする（サーバーにリクエストされる）
                this.unitGrid.set('collection', collection);
            }));
        },

        // 選択したコードがregionCdなのかmunicipalityCdなのか確かめる。
        // regionCdの場合はtrueが帰る
        isRegion: function(cd){
            return Region.store.get(cd).then(lang.hitch(this, function(region){
                return region ? true : false;
            }));
        },


        /*//組織情報の帳票出力
        outputOrganizationListExcel : function() {
            var data4xoblosDownload = {
                fileName: 'OrganizationInfoList.xlsx',
                reqidType: 'ADIS_M_ORGANIZATION_EX',
                paramList: []
            };
            var promise = this.download(data4xoblosDownload).then(lang.hitch(this, function() {
                this.infoDialog = new InfoDialog({
                    title : 'ダウンロード完了',
                    content : 'ダウンロードが完了しました。'
                });
                this.infoDialog.show();
                Router.moveTo('organization');

            }), lang.hitch(this, function(error) {
                console.error(error);
                this.infoDialog = new InfoDialog({
                    title : 'エラー',
                    content : 'エラーが発生しました。管理者にお問い合わせください。'
                });
                this.infoDialog.show();
            }));

            Loader.wait(promise);
        },

        // 帳票のダウンロード
        download: function(data) {
            var deferred = new Deferred();

            var xhr = new XMLHttpRequest();
            xhr.open('POST', '/api/xoblos/download', true);
            xhr.responseType = 'arraybuffer';
            xhr.setRequestHeader( 'Content-Type', 'application/json' );
            xhr.onload = function() {

                // エラー時は処理を止める
                if(xhr.status !== 200){
                    deferred.reject();
                    return;
                }
                // ファイル名をレスポンスヘッダーから取り出す
                var contentDisposition = this.getResponseHeader('content-disposition');
                var fileName = contentDisposition.replace(/^.*"(.*)"$/, '$1');

                // バイナリデータを取得
                var arrayBuffer = this.response;
                var blob = new Blob([arrayBuffer], {type: 'application/octet-stream'});

                // IE10
                if(window.navigator.msSaveOrOpenBlob){
                    window.navigator.msSaveOrOpenBlob(blob, fileName);
                } else {
                    // 擬似的にAタグを作成
                    var link = document.createElement('a');
                    link.style = 'display: none';
                    document.body.appendChild(link);

                    // AタグのURLにバイナリデータをセット
                    var url = window.URL.createObjectURL(blob);
                    link.href = url;

                    // ファイル名をセット
                    link.download = fileName;

                    // 擬似的にリンクをクリック
                    link.click();
                    // 参照を解放
                    window.URL.revokeObjectURL(url);
                    link.remove();
                }

                deferred.resolve();

            };
            // xhr.send(JSON.stringify(data));
            console.log(data);
            // xhr.send(data);

            return deferred.promise;
        },*/

        // 市町村セレクタの値が変更するときの動作
        onMunicipalityCdForSectChanged: function(municipalityCd) {
            this.setButtonStatusForSect();
            this.isRegion(municipalityCd.value).then(lang.hitch(this, function(isRegion){
                if(isRegion){
                    this.organizationSelectorForSect.setRegionCd(municipalityCd.value);
                    this.organizationSelectorForSect.setMunicipalityCd(null);
                } else if(municipalityCd.value && municipalityCd.value !== config.municInfo.prefCd){
                    this.organizationSelectorForSect.setMunicipalityCd(municipalityCd.value);
                    this.organizationSelectorForSect.setRegionCd(null);
                } else if (municipalityCd.value && municipalityCd.value === config.municInfo.prefCd) {
                    this.organizationSelectorForSect.setMunicipalityCd(config.municInfo.prefMunicCd);
                    this.organizationSelectorForSect.setRegionCd(null);
                }
            }));
        },

        // 振興局セレクタの値が変更するときの動作
        /*onRegionCdForSectChanged: function(regionCd) {
            this.organizationSelectorForSect.setRegionCd(regionCd.value);
        },*/

        // 市町村セレクタの値が変更するときの動作
        onMunicipalityCdForUnitChanged: function(municipalityCd) {
            this.setButtonStatusForUnit();
            this.isRegion(municipalityCd.value).then(lang.hitch(this, function(isRegion){
                if(isRegion){
                    this.organizationSelectorForUnit.setRegionCd(municipalityCd.value);
                    this.organizationSelectorForUnit.setMunicipalityCd(null);
                } else if(municipalityCd.value && municipalityCd.value !== config.municInfo.prefCd){
                    this.organizationSelectorForUnit.setMunicipalityCd(municipalityCd.value);
                    this.organizationSelectorForUnit.setRegionCd(null);
                } else if (municipalityCd.value && municipalityCd.value === config.municInfo.prefCd) {
                    this.organizationSelectorForUnit.setMunicipalityCd(config.municInfo.prefMunicCd);
                    this.organizationSelectorForUnit.setRegionCd(null);
                }
            }));
        },

        // 組織セレクタ（課）の活性・非活性判定
        setButtonStatusForSect: function () {
            if (this.municipalityCdForSect.value === '') {
                this.organizationSelectorForSect.set('disabled', true);
                this.organizationSelectorForSect.set('value', '');
            } else {
                this.organizationSelectorForSect.set('disabled', false);
            }
        },

        // 組織セレクタ(係)の活性・非活性判定
        setButtonStatusForUnit: function () {
            if (this.municipalityCdForUnit.value === '') {
                this.organizationSelectorForUnit.set('disabled', true);
                this.organizationSelectorForUnit.set('value', '');
            } else {
                this.organizationSelectorForUnit.set('disabled', false);
            }
        },

        // 振興局セレクタの値が変更するときの動作
        /*onRegionCdForUnitChanged: function(regionCd) {
            this.organizationSelectorForUnit.setRegionCd(regionCd.value);
        },*/

        // 組織情報出力ダイアログを表示
        outputOrganizationListExcel: function() {
            this.innerOrganizationFormDialog.initDialog();
            this.organizationFormDialog.show();
        },
        // 部・部/課セレクタのリフレッシュ
        refreshSelectorForSectAndUnit: function() {
            // リフレッシュ処理を動かすためフラグをtrueにセット
            this.organizationSelectorForSect._municipalityCdChanged = true;
            this.organizationSelectorForUnit._municipalityCdChanged = true;
            this.organizationSelectorForSect.restoreRefresh();
            this.organizationSelectorForUnit.restoreRefresh();
        }
    });
});
