/**
* 組織情報管理 ファイル読み込みダイアログ用モジュール。
* @module app/organization/OrganizationFileUploadDialog
*/
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/OrganizationFileUploadDialog.html',
    'dojo/request/iframe',
    'idis/control/Router',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/InfoDialog',
    'idis/view/page/_PageBase',
    'idis/view/Loader',
    'app/model/Disaster',
    // 以下、変数で受けないモジュール
    'dojox/form/Uploader',
    'dijit/form/Form',
    'idis/view/form/Button',
    'app/common/ErrorMessageDialogForXoblos'
], function(module, declare, lang, template, iframe, Router, Requester, DialogChain, InfoDialog,
    _PageBase, Loader, Disaster) {
    /**
    * 組織情報管理ファイルアップロードダイアログ
    * @class OrganizationFileUploadDialog
    * @extends module:idis/view/page/_PageBase~_PageBase
    */
    return declare(module.id.replace(/\//g, '.'), _PageBase, {
        // テンプレート文字列
        templateString: template,

        baseClass: 'idis-Page--safety-dialog',

        _fileId: null,

        infoDialog : null,

        ORGANIZATION_REQID : 'ADIS_M_ORGANIZATION_IN',
        ORGANIZATION_PATHWEB : 'in/xoblos/',
        ORGANIZATION_ERRPATHWEB : 'contents/xoblos/err/',
        ORGANIZATION_REGAPI : '/api/organization/dataimport',

        constructor: function() {
            // 連鎖ダイアログを登録
            this.chain = DialogChain.get(this);
        },

        /**
        * ファイル読み込み実行
        */
        onSubmit: function() {
            if (!this.validate()) {
                return false;
            }

            var reqParams = {
                reqid : this.ORGANIZATION_REQID,
                pathweb : this.ORGANIZATION_PATHWEB,
                errpathweb : this.ORGANIZATION_ERRPATHWEB,
                regapi : this.ORGANIZATION_REGAPI
            };

            Disaster.getCurrent().then(lang.hitch(this, function(disaster) {

                var disid = disaster.disasterId;
                // ファイルをサーバーにアップロードするAPIのURL
                var url = '/api/xoblos/uploadReport' +
                        '?reqid=' + reqParams.reqid +
                        '&disid=' + disid +
                        '&path=' + reqParams.pathweb;

                iframe.post(url, {
                    form: this.form.id,
                    handleAs: 'json'
                }).then(lang.hitch(this, function(ret) {
                    // アップロードしたファイルパスとエラーメッセージjson、
                    // エラー箇所を強調表示したファイルの出力先を指定してxoBlosをコールする。
                    var promise = Requester.post('/api/xoblos/upload',{
                        data: {
                            reqidType: reqParams.reqid,
                            disid: disid,
            			    filename: ret.fileName
            			}
		         }).then(lang.hitch(this, function(data) {
                        // ResultCode=0以外（xoBlosエラー）の場合にはエラーダイアログを表示する。
                        if (data !== null){
                            var err = new Error('xoblos failed');
                            err.data = data;
                            throw err;
                        } 
                    })).then(lang.hitch(this, function(error) {
                        this.uploadError(ret, error);
                    })
                    ).then(lang.hitch(this, function() {
                        // 成功した場合は受付ダイアログを表示する。
                        this.chain.infoAccept(function() {
                            // 確認ダイアログを閉じる
                            this.chain.hide();
                            // 親のダイアログを閉じる。
                            this.getParent().hide();
                            Router.moveTo('organization');
                            this.emit('upload');
                        });
                    }), lang.hitch(this, function(error) {
                        this.uploadError(ret, error);    
                    }));
                    // ローダーの表示
                    Loader.wait(promise);
                }), lang.hitch(this, this.iframeError)
                );
            }));
        },

        iframeError: function() {
            this.chain.infoError(function() {
                this.chain.hide();
                // 親のダイアログを閉じる。
                this.getParent().hide();
                Router.moveTo('organization');
            });
        },

        uploadError: function(ret, error) {
            // 失敗の場合、引数にデータがあれば、xoBlos用のエラーダイアログを表示する。
            if(error && error.data) {
                // エラーダイアログに結果コードを渡す
                Disaster.getCurrent().then(lang.hitch(this, function(disaster) {
                    var disid = disaster.disasterId;
                    var errPage = this.errorMessageDialogForXoblos.getChildren()[0];
                    errPage.initErrorMessageDialogForXoblos(
                        error.data.ResultCode,
                        this.ORGANIZATION_ERRPATHWEB + this.ORGANIZATION_REQID + '/' +
                             disid + '/' + ret.fileName,
                        ret.fileName);
                    this.errorMessageDialogForXoblos.show();
                    this.attachFile.reset();
                }));
            } else {
                // 引数にエラーがなければ、通常のエラーダイアログを表示する。
                this.chain.infoError(function() {
                    this.chain.hide();
                    // 親のダイアログを閉じる。
                    this.getParent().hide();
                    Router.moveTo('organization');
                });
            }
        },

        /**
        * 入力チェック
        */
        validate: function() {
            //ファイルが空の場合は処理を中断
            if(this.attachFile.getFileList().length === 0) {
                InfoDialog.show('入力チェック', 'ファイルが選択されていません');
                return false;
            }
            return true;
        },

        /**
        *報告書読み込みで呼ばれる
        **/
        loadFile: function() {
            //ファイルが空の場合は処理を中断
            if(this.attachFile._files.length === 0) {
                return;
            }

            console.debug('report change');

            // エクセルファイル以外の場合はエラー
            var file = this.attachFile._files[0];
            if(file.name.indexOf('.xls') === -1 && file.name.indexOf('.xlsx') === -1 &&
            file.name.indexOf('.xlsm') === -1) {
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : 'エクセルファイルを選択してください。'
                    });
                }
                this.infoDialog.show();
                this.infoDialog = null;
                this.attachFile.reset();
                return;
            }

            var form = new FormData();
            form.append('report', this.attachFile._files[0]);

            // ファイル名をyyyymmddhhssに設定。このファイル名はimport用APIをｊコールするときにも使う。
            var now = new Date();
            this._fileId = now.getFullYear() + this.padZero(now.getMonth() + 1) +
            this.padZero(now.getDate()) + this.padZero(now.getHours()) +
            this.padZero(now.getMinutes()) + this.padZero(now.getSeconds());

            // ファイルがonloadされたときにサーバーに一時保存する
            var promise = iframe.post('/api/upload/uploadFile?fileId=' +
            this._fileId , {
                form: this.form.id,
                handleAs: 'json'
            });
            //ローダーの表示
            Loader.wait(promise);
        },

        // 日付をゼロパディング
        padZero : function(num){
            var result;
            if (num < 10) {
                result = '0' + num;
            } else {
                result = '' + num;
            }
            return result;
        }
    });
});